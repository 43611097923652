import type { BundleConfig } from "@prodoctivity/types";
import { BankCustomerSvgIcon } from "../../../svg/Bundle/BankCustomerSvgIcon";
import { SimpleBillTemplateSvgIcon } from "../../../svg/Bundle/SimpleBillTemplateSvgIcon";
import { shouldNever } from "@prodoctivity/shared";
import { TrainingSvgIcon } from "../../../svg/Bundle/TrainingSvgIcon";
import { CooperativaSvgIcon } from "../../../svg/Bundle/CooperativaSvgIcon";

type Props = {
  bundleIdentifier: BundleConfig["identifier"];
};
export const BundleIcon = ({ bundleIdentifier }: Props) => {
  switch (bundleIdentifier) {
    case "bank-customers-document-collection":
      return <BankCustomerSvgIcon />;
    case "simple-bill-template":
      return <SimpleBillTemplateSvgIcon />;
    case "training-templates":
      return <TrainingSvgIcon />;
    case "cooperativa-templates":
      return <CooperativaSvgIcon />;
    default:
      shouldNever(bundleIdentifier);
      throw new Error(`Cannot happen. Invalid bundle "${bundleIdentifier}"`);
  }
};
