import { Box, DynamicIconButton, TapArea, Text, isIconSelected } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { EditDashboardConfig } from "../../svg/EditDashboardConfig";
import { DashboardConfigRowItem } from "./DashboardConfigRowItem";
import { DashboardConfigSidebarList } from "./DashboardConfigSidebarList";
import { ListOfWidgetsPanel } from "./ListOfWidgetsPanel";
import { useUserDashboardConfig } from "./hooks";

const DashboardPage: FunctionComponent = () => {
  const {
    convertDashboardColumnsForImplementation,
    getMyProfileIsError,
    getMyProfileIsLoading,
    isThereAnySidebar,
    editionMode,
    setSidebarToShow,
    sidebarToShow,
    sideBarsArray,
    setOfSidebarsPerConfig,
    saveDashboardConfig,
    currentDashboardConfig,
    handleColumnAmount,
    handleDeleteRow,
    handleAddRowItem,
    newRowId,
    setNewRowId,
    newColumnId,
    setNewColumnId,
    handleMoveColumnItemVertically,
    openListOfWidgets,
    setOpenListOfWidgets,
    listOfWidgets,
    handleAddColumnItem,
    handleAddSidebarItem,
    colors,
    resources,
    mainContentEllipsisOptions,
    mainContentFunctionsBundle,
    sidebarContentEllipsisOptions,
    sidebarContentFunctionsBundle,
    handleCancelEditionMode,
    setEditionMode,
    colorIconOnHover,
    setColorIconOnHover,
    allowEditionMode,
    dashboardViewMode,
    setDashboardViewMode,
    rowsToDisplay,
    handleOpenListOfWidgets,
    dashboardPaddingRight,
    isMobile,
  } = useUserDashboardConfig();

  if (getMyProfileIsError) {
    return null;
  }

  return (
    <Box width={"100%"} height={"100%"} color={colors.neutral100} display="flex" direction="row">
      <>
        {isThereAnySidebar && allowEditionMode !== 0 && (
          <Box paddingRight={10}>
            <DashboardConfigSidebarList
              allowEditionMode={allowEditionMode}
              editionMode={editionMode}
              handleOpenListOfWidgets={handleOpenListOfWidgets}
              setOfSidebarsPerConfig={setOfSidebarsPerConfig}
              setSidebarToShow={setSidebarToShow}
              sideBarsArray={sideBarsArray}
              sidebarContentEllipsisOptions={sidebarContentEllipsisOptions}
              sidebarContentFunctionsBundle={sidebarContentFunctionsBundle}
              sidebarToShow={sidebarToShow}
            />
          </Box>
        )}
      </>
      <Box
        display="flex"
        direction="column"
        width={"100%"}
        paddingRight={isMobile ? 4 : 10}
        paddingLeft={dashboardPaddingRight}
        overflow="scrollY"
        position="relative"
      >
        <Box
          width={"100%"}
          minHeight={allowEditionMode ? undefined : 76}
          display="flex"
          justifyContent="start"
          marginTop={1}
          paddingTop={5}
          paddingBottom={4}
          paddingX={2}
          height={"fit-content"}
        >
          {getMyProfileIsLoading ? (
            <Box></Box>
          ) : (
            <Box display="flex" justifyContent="between" alignItems="center" width={"100%"}>
              <Box width={"100%"} display="flex" direction="column" justifyContent="center" gap={1}>
                <Text weight="bold">
                  {currentDashboardConfig ? currentDashboardConfig.label : ""}
                </Text>
                {!allowEditionMode ? null : (
                  <Text weight="normal" color={colors.neutral900}>
                    {resources.dashboard.youAreEditing}
                  </Text>
                )}
              </Box>
              <Box display="flex" gap={6} height={"100%"} alignItems="center">
                {allowEditionMode ? (
                  <>
                    <Box
                      width={180}
                      height={40}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      paddingX={4}
                      paddingY={2}
                      borderRadius={4}
                      borderColor={colors.neutral50}
                    >
                      <TapArea
                        onTap={() => {
                          handleCancelEditionMode();
                          setOpenListOfWidgets({
                            isListOfWidgetsOpen: false,
                            rowIndex: null,
                            colIndex: null,
                            addToSidebar: false,
                          });
                        }}
                      >
                        <Text size="200" align="center" color={colors.primary}>
                          {resources.cancel}
                        </Text>
                      </TapArea>
                    </Box>
                    <Box
                      width={180}
                      height={40}
                      color={colors.primary}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      paddingX={4}
                      paddingY={2}
                      borderRadius={4}
                    >
                      <TapArea onTap={saveDashboardConfig}>
                        <Text size="200" align="center" color={colors.white}>
                          {resources.saveChanges}
                        </Text>
                      </TapArea>
                    </Box>
                  </>
                ) : allowEditionMode === 0 ? (
                  <>
                    {sideBarsArray.length &&
                    sideBarsArray[0].elementType === "placeholder" ? null : dashboardViewMode ===
                      "main" ? (
                      <Box
                        width={"fit-content"}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <DynamicIconButton
                          buttonName={"dashboardContentView"}
                          handleFunction={() => setDashboardViewMode("sidebar")}
                          statefulCondition={undefined}
                          selected={undefined}
                        ></DynamicIconButton>
                      </Box>
                    ) : (
                      <Box
                        width={"fit-content"}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <DynamicIconButton
                          buttonName={"dashboardSidebarView"}
                          handleFunction={() => setDashboardViewMode("main")}
                          statefulCondition={undefined}
                          selected={undefined}
                        ></DynamicIconButton>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box
                    width={40}
                    height={32}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TapArea
                      onTap={() => {
                        setEditionMode(true);
                        setColorIconOnHover(false);
                      }}
                      onMouseEnter={() => {
                        setColorIconOnHover(true);
                      }}
                      onMouseLeave={() => {
                        setColorIconOnHover(false);
                      }}
                    >
                      <EditDashboardConfig
                        color={isIconSelected(colors, colorIconOnHover, false)}
                      />
                    </TapArea>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
        {rowsToDisplay.map((row, index) => {
          return (
            <DashboardConfigRowItem
              row={row}
              index={index}
              convertDashboardColumnsForImplementation={convertDashboardColumnsForImplementation}
              handleAddRowItem={handleAddRowItem}
              newRowId={newRowId}
              setNewRowId={setNewRowId}
              newColumnId={newColumnId}
              setNewColumnId={setNewColumnId}
              handleColumnAmount={handleColumnAmount}
              handleDeleteRow={handleDeleteRow}
              handleMoveColumnItemVertically={handleMoveColumnItemVertically}
              mainContentEllipsisOptions={mainContentEllipsisOptions}
              mainContentFunctionsBundle={mainContentFunctionsBundle}
              allowEditionMode={allowEditionMode}
              handleOpenListOfWidgets={handleOpenListOfWidgets}
              openListOfWidgets={openListOfWidgets}
              key={index}
            />
          );
        })}
      </Box>

      {openListOfWidgets.isListOfWidgetsOpen && (
        <ListOfWidgetsPanel
          setOpenListOfWidgets={setOpenListOfWidgets}
          handleAddColumnItem={handleAddColumnItem}
          handleAddSidebarItem={handleAddSidebarItem}
          listOfWidgets={listOfWidgets}
          colIndex={openListOfWidgets.colIndex}
          rowIndex={openListOfWidgets.rowIndex}
          isListOfWidgetsOpen={openListOfWidgets.isListOfWidgetsOpen}
          addToSidebar={openListOfWidgets.addToSidebar}
        />
      )}
    </Box>
  );
};

export default DashboardPage;
