import {
  Box,
  ComboBox,
  ContextStateUpdater,
  Divider,
  DocumentViewer,
  DocumentViewerEvents,
  FormControllerProvider,
  Heading,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
  usePubSub,
} from "@prodoctivity/design-system";
import { AudioVideoPlayer } from "@prodoctivity/design-system/components/DocumentViewer/AudioVideoPlayer";
import { IndexingForm } from "@prodoctivity/prodoctivity-form-v5/src/components";
import type { MimeType, ParametersObject } from "@prodoctivity/shared/src/index-types";
import type {
  DocumentTypeInfo,
  DocumentTypeResume,
  HttpExecuteDataLinkRequest,
  HttpExecuteDataLinkResponse,
} from "@prodoctivity/types";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import {
  LookupDocument,
  LookupDocumentButton,
  StateProps,
} from "../../../components/LookupDocument/LookupDocument";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { TemplateCardSkeleton } from "../../../loading";
import { XIconSvg } from "../../../svg/XIconSvg";
import { usePageHeight } from "../DashboardPageWrapper";
import { useIndexingFormWrapper } from "./hooks";
type Props = {
  documentTypes: Array<DocumentTypeResume>;
  documentTypeSelected?: DocumentTypeInfo;
  files: Array<{
    file: File;
    type: MimeType | undefined;
  }>;
  mimeType: MimeType;
  disabled: boolean;
  loadingForm: boolean;
  onFormValuesChange: (values: ParametersObject) => void;
  onDocumentTypeSelect: (documentType: DocumentTypeResume) => void;
  executeDataLink: (
    dataLinkId: string,
    dataLinkConfigVersionId: string,
    inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
  ) => Promise<HttpExecuteDataLinkResponse["payload"]>;
  setDocumentWithSameIdentifier: (documentVersionId?: string) => void;
  context: ParametersObject;
  wordToPdfHookRef?: {
    pdfDataUri: string | undefined;
    setWordDataUri: (wordDataUri?: string | undefined) => void;
  };
  documentLoadingState: StateProps;
  extractedContext: ParametersObject | undefined;
  setDocumentSearchOpen: (n: boolean) => void;
  isDocumentSearchOpen: boolean;
  setDocumentLoadingState: (n: StateProps) => void;
};
export const Indexing: FunctionComponent<Props> = ({
  documentTypes: allDocumentTypes,
  documentTypeSelected,
  files,
  mimeType,
  disabled,
  loadingForm,
  onDocumentTypeSelect,
  executeDataLink,
  onFormValuesChange,
  setDocumentWithSameIdentifier,
  context,
  wordToPdfHookRef,
  documentLoadingState,
  setDocumentSearchOpen,
  isDocumentSearchOpen,
  setDocumentLoadingState,
  extractedContext,
}) => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();

  const documentTypes = useMemo(() => {
    return allDocumentTypes.filter((documentType) =>
      documentType.acceptedMimeTypeList.includes(mimeType)
    );
  }, [allDocumentTypes, mimeType]);
  const { breakpoint } = useDesignBreakpoint();
  const isSmallOrMediumBreakpoint = breakpoint === "medium" || breakpoint === "small";

  const fileBinaries = useMemo(() => files.map((f) => f.file), [files]);
  const onSelect = useCallback(
    (documentTypeVersionId: string) => {
      const index = documentTypes.findIndex(
        (d) => d.documentTypeVersionId === documentTypeVersionId
      );
      if (index >= 0) {
        onDocumentTypeSelect(documentTypes[index]);
      }
    },
    [documentTypes, onDocumentTypeSelect]
  );

  const [isFullScreen, setIsFullScreen] = useState(false);

  const isVideoFormat = useMemo(
    () => fileBinaries.map((f) => f.type).includes("video/mp4"),
    [fileBinaries]
  );
  const isAudioFormat = useMemo(
    () => fileBinaries.map((f) => f.type).includes("audio/mpeg"),
    [fileBinaries]
  );

  usePageHeight(true);

  const { eventManager } = usePubSub<DocumentViewerEvents>();

  const documentViewerNode = useMemo(() => {
    return (
      <DocumentViewer
        readOnly={false}
        height="100%"
        src={fileBinaries}
        mimeType={mimeType}
        strategy="normal"
        resources={resources}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        wordToPdfHookRef={wordToPdfHookRef}
        eventManager={eventManager}
        moment={moment}
      />
    );
  }, [eventManager, fileBinaries, isFullScreen, mimeType, moment, resources, wordToPdfHookRef]);

  const audioFormat = isAudioFormat ? "audio/mpeg" : undefined;

  return (
    <Box display="flex" direction="column" flex="grow">
      <Box
        height={"100%"}
        display="flex"
        direction={isSmallOrMediumBreakpoint ? "column" : "row"}
        minWidth={360}
        flex="grow"
      >
        <Box
          width={isSmallOrMediumBreakpoint ? "99%" : 430}
          display="flex"
          direction="column"
          flex="grow"
          gap={2}
        >
          <Box paddingX={3} marginBottom={1} minHeight={60} display="flex" direction="column">
            <Box column={5} display="flex">
              <Heading color={colors.black600} size="400">
                {resources.documentTypes.documentType}:
              </Heading>
            </Box>
            <Box column={breakpoint === "small" ? 8 : 12} marginTop={1}>
              <ComboBox
                id="documentTypeVersionId"
                label=""
                disabled={documentTypes.length <= 0 || loadingForm || disabled}
                onSelect={(e) => onSelect(e.item.value)}
                size="md"
                placeholder={documentTypeSelected?.name}
                selectedOption={
                  documentTypeSelected && documentTypeSelected.documentTypeVersionId
                    ? {
                        label: documentTypeSelected.name,
                        subtext: undefined,
                        value: documentTypeSelected.documentTypeVersionId,
                      }
                    : undefined
                }
                options={documentTypes.map((d) => {
                  return {
                    label: d.name,
                    subtext: undefined,
                    value: d.documentTypeVersionId || "",
                  };
                })}
                accessibilityClearButtonLabel=""
                noResultText=""
                errorMessage={!documentTypeSelected ? resources.required : undefined}
              />
            </Box>
          </Box>
          <Box margin={1} />
          <Divider direction="horizontal" />
          <Box
            marginTop={2}
            overflow="auto"
            width={"100%"}
            position="relative"
            display="flex"
            direction="column"
            flex="grow"
          >
            {documentTypeSelected && <LookupDocumentButton onchange={setDocumentSearchOpen} />}
            {(loadingForm || documentLoadingState.isLoading) && <TemplateCardSkeleton />}
            {!loadingForm && documentTypeSelected && !documentLoadingState.isLoading && (
              <FormControllerProvider
                contextDefinition={documentTypeSelected.contextDefinition}
                wizardDefinition={documentTypeSelected.wizardDefinition}
                dataLinkMappings={documentTypeSelected.dataLinkMappings}
                moment={moment}
                executeDataLink={executeDataLink}
                initialContext={extractedContext || context}
              >
                <IndexingFormWrapper
                  documentTypeSelected={documentTypeSelected}
                  disabled={disabled}
                  executeDataLink={executeDataLink}
                  setDocumentWithSameIdentifier={setDocumentWithSameIdentifier}
                  onFormValuesChange={onFormValuesChange}
                />
              </FormControllerProvider>
            )}
          </Box>
        </Box>
        <Box
          column={isSmallOrMediumBreakpoint ? 12 : 9}
          height={breakpoint === "small" ? 350 : breakpoint === "medium" ? 100 : undefined}
        >
          <LookupDocument
            onChange={setDocumentLoadingState}
            isDocumentSearchOpen={isDocumentSearchOpen}
            setDocumentSearchOpen={setDocumentSearchOpen}
          />

          {isVideoFormat || isAudioFormat ? (
            <AudioVideoPlayer src={fileBinaries} format={audioFormat} strategy="normal" />
          ) : breakpoint === "small" ? (
            <Box width={"100%"} height={350}>
              {documentViewerNode}
            </Box>
          ) : (
            documentViewerNode
          )}
        </Box>
      </Box>
    </Box>
  );
};

type IndexingFormProps = {
  documentTypeSelected: DocumentTypeInfo;
  disabled: boolean;
  setDocumentWithSameIdentifier: Props["setDocumentWithSameIdentifier"];
  executeDataLink: Props["executeDataLink"];
  onFormValuesChange(context: ParametersObject): void;
};
const IndexingFormWrapper: FunctionComponent<IndexingFormProps> = ({
  documentTypeSelected,
  disabled,
  setDocumentWithSameIdentifier,
  executeDataLink,
  onFormValuesChange,
}) => {
  const { colors } = useColors();

  const {
    i18n,
    resources,
    moment,

    isLoading,
    identifierConfig,
    showUseData,
    setShowUseData,
    onClickUseDocumentData,
  } = useIndexingFormWrapper({
    documentTypeSelected,
    setDocumentWithSameIdentifier,
  });

  return (
    <Box>
      {showUseData && identifierConfig && (
        <Box>
          <Box
            color={colors.neutral300}
            paddingY={2}
            paddingLeft={4}
            paddingRight={3}
            display="flex"
            justifyContent="between"
          >
            <TapArea onTap={onClickUseDocumentData}>
              <Box display="flex">
                <Text color={colors.secondary}>
                  {resources.formDesigner.useDocumentData.replace(
                    "{{identifierConfig}}",
                    identifierConfig
                  )}
                </Text>
              </Box>
            </TapArea>
            <Box height={15} width={15}>
              <TapArea onTap={() => setShowUseData(false)}>
                <XIconSvg height={15} width={15} />
              </TapArea>
            </Box>
          </Box>
          <Divider />
        </Box>
      )}
      <FormWatcher onChange={onFormValuesChange} />
      <IndexingForm
        readonly={disabled || isLoading}
        contextDefinition={documentTypeSelected.contextDefinition}
        wizardDefinition={documentTypeSelected.wizardDefinition}
        dataLinkMappings={documentTypeSelected.dataLinkMappings}
        executeDataLink={executeDataLink}
        i18n={i18n}
        moment={moment}
        resources={resources}
      />
    </Box>
  );
};

const FormWatcher: FunctionComponent<{ onChange(context: ParametersObject): void }> = ({
  onChange,
}) => {
  const handler = useCallback(
    (arg: { data: ParametersObject }) => {
      onChange(arg.data);
    },
    [onChange]
  );

  return <ContextStateUpdater onUpdate={handler} />;
};
