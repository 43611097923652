import React, { FunctionComponent, useMemo, useRef, useState } from "react";
import { Box, BoxWithRef } from "../Box";
import { Layer, Popover } from "../layout";

import { groupBy } from "@prodoctivity/shared";
import { FourDirections } from "gestalt";
import { ChevronSvgIcon } from "../../svg/ChevronSvgIcon";
import { SearchSvgIcon } from "../../svg/SearchSvgIcon";
import { useColors } from "../ColorSchemeProvider";
import { Icon } from "../Icon";
import { TapArea } from "../TapArea";
import { Text } from "../Text";
import { TextField } from "../TextField";

export type PopoverComboboxOption = {
  groupLabel?: string;
  strLabel: string;
  label: string | React.ReactNode;
  value: string;
};
type Props = {
  getI18n(key: string): string;
  selectedValue?: string;
  options: Array<PopoverComboboxOption>;
  onSelect: (item: PopoverComboboxOption) => void;
  placeholder: string | undefined;
  direction: FourDirections | undefined;
  displayOption: "strLabel" | "label";
  onClear: (() => void) | undefined;
  onFilterChange: (newFilter: string) => void;
  filter: string;
};

export const PopoverCombobox: FunctionComponent<Props> = ({
  getI18n,
  selectedValue,
  onSelect,
  options,
  placeholder,
  displayOption,
  direction,
  onClear,
  filter,
  onFilterChange,
}) => {
  const { colors } = useColors();
  const [open, setOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState(false);
  const anchorRef = useRef(null);
  const searchFieldRef = useRef<HTMLInputElement>(null);

  const handleImageTap = (item: PopoverComboboxOption) => {
    onSelect(item);
    openSearch(false);
  };

  const openSearch = (value: boolean) => {
    if (!value) {
      return setSearchFilter(false);
    }
    setSearchFilter(true);
    setTimeout(() => {
      searchFieldRef.current?.focus();
    }, 0);
  };

  const selectedItem = useMemo(() => {
    return options.find((opt) => opt.value === selectedValue);
  }, [options, selectedValue]);

  const itemsGrouped = useMemo(() => {
    return groupBy(options, (opt) => opt.groupLabel);
  }, [options]);

  const selectedDisplayOption =
    displayOption === "label" ? selectedItem?.label : selectedItem?.strLabel;

  return (
    <Box height="100%" width="100%">
      <BoxWithRef ref={anchorRef}>
        {!searchFilter ? (
          <TapArea
            onTap={() => {
              setOpen(true);
              onFilterChange("");
              openSearch(true);
            }}
          >
            <Box
              color={colors.white}
              display="flex"
              justifyContent="between"
              paddingX={3}
              paddingY={1}
              borderRadius={12}
              borderStyle="lg"
              alignItems="center"
              width={"100%"}
            >
              {typeof selectedDisplayOption === "string" ? (
                <Text align="center" weight="bold">
                  {selectedDisplayOption}
                </Text>
              ) : (
                selectedDisplayOption || (
                  <Text color={colors.neutral900}>{getI18n("assignedTo")}</Text>
                )
              )}
              {selectedValue !== "" ? (
                <Box display="flex" alignItems="center">
                  <TapArea
                    onTap={onClear}
                    children={
                      <Box paddingX={2}>
                        <Icon
                          icon={"close"}
                          size={"sm"}
                          color={colors.black900}
                          accessibilityLabel={getI18n("clear")}
                        />
                      </Box>
                    }
                  />
                </Box>
              ) : (
                <ChevronSvgIcon direction="down" />
              )}
            </Box>
          </TapArea>
        ) : (
          <Box
            marginEnd={4}
            borderStyle="lg"
            borderRadius={12}
            paddingX={3}
            color={colors.white}
            height={46}
            display={"flex"}
            alignItems={"center"}
            direction={"row"}
          >
            <Box flex="grow">
              <TextField
                autoComplete="off"
                onFocus={() => setOpen(true)}
                id={"document-collection-search"}
                onChange={({ value }) => {
                  setOpen(true);
                  onFilterChange(value);
                }}
                ref={searchFieldRef}
                mode="unstyled"
                placeholder={placeholder ? placeholder : getI18n("search")}
                value={filter}
                onBlur={() => openSearch(false)}
              />
            </Box>

            <Box>
              <SearchSvgIcon />
            </Box>
          </Box>
        )}
      </BoxWithRef>

      {open && (
        <Layer>
          <Popover
            accessibilityLabel="Icon"
            anchor={anchorRef.current}
            idealDirection={direction ? direction : "down"}
            onDismiss={() => {
              setOpen(false);
              openSearch(false);
            }}
            positionRelativeToAnchor={false}
            size="xl"
            // showDismissButton={true}
            color="white"
            role="menu"
          >
            <Box maxWidth={400} color={colors.white}>
              <Box flex="grow" marginEnd={4} marginStart={4} marginBottom={2}></Box>
              <Box height={300} overflow="auto">
                <Box marginEnd={1} marginStart={1}>
                  {Array.from(itemsGrouped.keys())
                    .filter((group) => {
                      return filter && group
                        ? group.toLowerCase().includes(filter.toLowerCase())
                        : true;
                    })
                    .map((group) => (
                      <Box display="flex" key={group || "no-group"} direction="column" gap={2}>
                        <Box marginTop={2} marginStart={2}>
                          <Text size="200">{group}</Text>
                        </Box>
                        <Box display="flex" direction="column" gap={4}>
                          {(itemsGrouped.get(group) || [])
                            .filter((opt) =>
                              filter
                                ? (filter && group
                                    ? group.toLowerCase().includes(filter.toLowerCase())
                                    : true) ||
                                  opt.strLabel.toLowerCase().includes(filter?.toLocaleLowerCase())
                                : true
                            )
                            .map((item) => (
                              <TapArea
                                key={item.value}
                                onTap={() => {
                                  handleImageTap(item);
                                  openSearch(false);
                                }}
                              >
                                <Box
                                  marginStart={3}
                                  alignItems="center"
                                  hoverColor={colors.neutral400}
                                >
                                  {typeof item.label === "string" ? (
                                    <Text align="center" weight="bold">
                                      {item.label}
                                    </Text>
                                  ) : (
                                    item.label
                                  )}
                                </Box>
                              </TapArea>
                            ))}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </Popover>
        </Layer>
      )}
    </Box>
  );
};
