import { Box, Checkbox, Text } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import type { TemplateSubscriptionType } from "@prodoctivity/shared/src/index-types";
import { TemplatesInitialState } from "../../components/Subscriptions/hooks";

type Props = {
  currentTemplatesSubscriptions: TemplatesInitialState;
  handleCheckBoxOnChange: (
    checkBoxCase: string,
    templateId: string,
    subscriptionType: TemplateSubscriptionType
  ) => void;
  isMutating: boolean;
  uniqueTemplateId: string;
};

export const TemplatesInternalRows: FunctionComponent<Props> = ({
  currentTemplatesSubscriptions,
  handleCheckBoxOnChange,
  isMutating,
  uniqueTemplateId,
}) => {
  return (
    <>
      {currentTemplatesSubscriptions
        .filter((subscription) => subscription.templateId === uniqueTemplateId)
        .map((subscription, i) => {
          return (
            <Box
              key={i}
              display="flex"
              alignItems="center"
              width={"100%"}
              height={48}
              marginStart={-6}
              marginEnd={-6}
            >
              <Box width={"58.5%"}>
                <Text></Text>
              </Box>
              <Box width={"20%"}>
                <Text size="200">{subscription.subscriptionType.toUpperCase()}</Text>
              </Box>
              <Box width={"14%"} paddingX={1}>
                <Checkbox
                  id={`subscriptionCheckbox_${
                    subscription.templateId
                  }_${subscription.sendToWeb.toString()}`}
                  checked={subscription.sendToWeb}
                  onChange={() =>
                    handleCheckBoxOnChange(
                      "sendToWeb",
                      subscription.templateId,
                      subscription.subscriptionType
                    )
                  }
                  disabled={isMutating}
                />
              </Box>
              <Box width={"6%"} paddingX={1}>
                <Checkbox
                  id={`subscriptionCheckbox_${
                    subscription.templateId
                  }_${subscription.sendToMobile.toString()}`}
                  checked={subscription.sendToMobile}
                  onChange={() =>
                    handleCheckBoxOnChange(
                      "sendToMobile",
                      subscription.templateId,
                      subscription.subscriptionType
                    )
                  }
                  disabled={isMutating}
                />
              </Box>
            </Box>
          );
        })}
    </>
  );
};
