"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFilterBuilder = void 0;
var shared_1 = require("@prodoctivity/shared");
var react_1 = require("react");
function appliesForWhereFunnel(field) {
    return field && (field.type === "record" || (field.type === "field" && field.isChild));
}
function createContextField(dataType, minValue) {
    return {
        name: "",
        humanName: "",
        fullPath: "",
        properties: {
            dataType: dataType,
            inputType: "TextBox",
            label: "",
            sampleValue: [],
            humanName: "",
            description: "",
            instructions: "",
            minValue: minValue,
        },
    };
}
function getField(fieldList, fullPath) {
    var found;
    fieldList.some(function (f) {
        if (f.fullPath === fullPath) {
            found = f;
            return true;
        }
        else if (f.type === "record") {
            var child = getField(f.fields || [], fullPath);
            if (child) {
                found = f;
                return true;
            }
            else {
                var tryRecordChild = getField(f.records || [], fullPath);
                if (tryRecordChild) {
                    found = f;
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        else {
            return false;
        }
    });
    return found;
}
function selectableFieldList(fieldList, summaryList, sourceSummary, level) {
    var r = [];
    if (summaryList[sourceSummary] === undefined) {
        return r;
    }
    var summary = (0, shared_1.valueOrThrow)(summaryList[sourceSummary]);
    fieldList.forEach(function (field) {
        if (field.type === "field") {
            if (summary.dataTypeList(field.type, field.dataType, field.isChild, field.minimumOccurrences, field.maximumOccurrences)) {
                if (r === undefined) {
                    console.log("undefined");
                }
                r.push({
                    field: __assign(__assign({}, field), { humanName: (0, shared_1.range)(level)
                            .map(function (_e) { return " |-- "; })
                            .join("") + field.humanName }),
                    canSelectRecord: false,
                });
            }
        }
        else if (field.type === "record") {
            var canSelectRecords = summary.canSelectRecords && summary.canSelectRecords();
            var newRecord = Object.assign({}, field);
            var newFieldsAll = selectableFieldList((field.fields || []).concat(field.records || []), summaryList, sourceSummary, level + 1);
            newRecord.fields = newFieldsAll.map(function (item) { return item.field; }).filter(function (i) { return i.type === "field"; });
            newRecord.records = newFieldsAll.map(function (item) { return item.field; }).filter(function (i) { return i.type === "record"; });
            r.push({
                field: __assign(__assign({}, newRecord), { humanName: (0, shared_1.range)(level)
                        .map(function (_e) { return " |-- "; })
                        .join("") + newRecord.humanName }),
                canSelectRecord: canSelectRecords,
            });
        }
    });
    return r;
}
function emptyExpression() {
    return {
        expressionList: [],
        isNegative: false,
        operator: "equals",
        sourceField: "",
        sourceSummary: undefined,
        targetValue: "",
        where: undefined,
    };
}
// function refreshSvgLinks(svg: any) {
//   if (svg) {
//     if (!svg.children.length) {
//       const line1: any = document.createElementNS("http://www.w3.org/2000/svg", "line");
//       svg.append(line1);
//       const line2: any = document.createElementNS("http://www.w3.org/2000/svg", "line");
//       svg.append(line2);
//     }
//     const ancestor = svg.parentElement.parentElement.parentElement;
//     const parentRect = svg.parentElement.getBoundingClientRect();
//     const ancestorRect = ancestor.getBoundingClientRect();
//     svg.style.height = `${ancestorRect.height - (ancestorRect.y - parentRect.y)}px`;
//     const differenceBetweenContainers = parentRect.y - ancestorRect.y;
//     const differenceToHalf = ancestorRect.height / 2 - differenceBetweenContainers;
//     const isPastHalf = parentRect.y > ancestorRect.y + ancestorRect.height / 2; // differenceToHalf < 0;
//     if (isPastHalf) {
//       svg.style.marginTop = differenceToHalf;
//       svg.style.height = parentRect.height / 2 + differenceToHalf * -1;
//     }
//     const l1 = svg.children[0];
//     const l2 = svg.children[1];
//     //To do the problem is the % of l1y1
//     const l1y1 = isPastHalf ? "0px" : parentRect.height / 2;
//     const l1x1 = 25;
//     const l1x2 = "100%";
//     const l2y2 = isPastHalf ? 0 : differenceToHalf;
//     l1.setAttribute("x1", l1x1);
//     l1.setAttribute("y1", `${l1y1}`);
//     l1.setAttribute("x2", `${l1x2}`);
//     l1.setAttribute("y2", `${l1y1}`);
//     l1.setAttribute("stroke", "black");
//     l2.setAttribute("x1", l1x1);
//     l2.setAttribute("y1", l1y1);
//     l2.setAttribute("x2", l1x1);
//     l2.setAttribute("y2", l2y2);
//     l2.setAttribute("stroke", "black");
//   }
// }
function getSvgProps() {
    var offset = 100 / 24;
    return {
        style: {
            marginLeft: "-".concat(offset, "%"),
            position: "absolute",
            width: "".concat(offset, "%"),
            zIndex: 0,
        },
    };
}
function renderFieldOption(field, renderRecords, canSelectRecords, padding) {
    if (field.type === "field") {
        return { label: field.humanName, value: field.fullPath };
    }
    else if (field.type === "record") {
        var children_1 = [];
        if (renderRecords) {
            children_1.push({ label: field.humanName, value: field.fullPath, disabled: !canSelectRecords });
        }
        (field.fields || [])
            .map(function (f) { return renderFieldOption(f, renderRecords, canSelectRecords, padding); })
            .flatMap(function (item) {
            if (Array.isArray(item)) {
                return item.map(function (child) { return ({ label: "  ".concat(child.label), value: child.value }); });
            }
            return { label: "  ".concat(item.label), value: item.value };
        })
            .forEach(function (f) { return children_1.push(f); });
        (field.records || [])
            .map(function (r) { return renderFieldOption(r, renderRecords, canSelectRecords, padding + 9); })
            .flatMap(function (item) {
            if (Array.isArray(item)) {
                return item.map(function (child) { return ({ label: "  ".concat(child.label), value: child.value }); });
            }
            return { label: "  ".concat(item.label), value: item.value };
        })
            .forEach(function (r) { return children_1.push(r); });
        return children_1;
    }
    else {
        throw new Error("Unknown field type \"".concat(field.type, "\""));
    }
}
function summaryOptions(linearizedFields, summaryList, t) {
    return Object.keys(summaryList)
        .reduce(function (arr, k) {
        var summary = summaryList[k];
        if (summary &&
            linearizedFields.some(function (l) {
                return summary.dataTypeList(l.type, l.dataType, l.isChild, l.minimumOccurrences, l.maximumOccurrences);
            })) {
            arr.push(summary);
        }
        return arr;
    }, [])
        .map(function (s) {
        return { key: s.name, label: t(s.name) };
    });
}
var useFilterBuilder = function (_a) {
    /* ---------------------------- #Common Hooks area---------------------------- */
    var i18n = _a.translateFn, fieldList = _a.fieldList, expressionProp = _a.expression, expressionIndex = _a.expressionIndex, onExpressionChanged = _a.onExpressionChanged, moment = _a.moment, colors = _a.colors, resources = _a.resources, parentExpressionOperator = _a.parentExpressionOperator, contextDefinition = _a.contextDefinition;
    var selectOperatorProps = {
        showArrow: false,
        style: {
            width: "100%",
        },
    };
    var setInitialStateFromProps = (0, react_1.useCallback)(function () {
        // private subExpressionModalContext: ExpressionBuilderContext;
        var where = expressionProp.where;
        var initialState = {
            id: (0, shared_1.createUuid)(),
            highlighted: false,
            isDeleted: false,
            isSelected: false,
            showFunnel: where ? true : null,
        };
        return __assign({}, initialState);
    }, [expressionProp]);
    var _b = (0, react_1.useState)(setInitialStateFromProps), state = _b[0], setState = _b[1];
    var toInitializedExpression = (0, react_1.useCallback)(function (expressionProp) {
        var expressionList = expressionProp.expressionList, isNegative = expressionProp.isNegative, sourceSummary = expressionProp.sourceSummary, sourceField = expressionProp.sourceField, operator = expressionProp.operator, targetValue = expressionProp.targetValue, where = expressionProp.where;
        var expression = {
            expressionList: expressionList || [],
            isNegative: isNegative,
            operator: operator,
            sourceField: sourceField || "",
            sourceSummary: sourceSummary === undefined ? "valueOf" : sourceSummary,
            targetValue: typeof targetValue !== "undefined" ? targetValue : "",
            where: where || undefined,
        };
        if ((where === null || where === void 0 ? void 0 : where.sourceSummary) && state.showFunnel === null) {
            setState(function (prev) { return (__assign(__assign({}, prev), { showFunnel: true })); });
        }
        return expression;
    }, [state.showFunnel]);
    //  componentWillUnmount() {
    // if (this.subExpressionModalContext) {
    //   this.subExpressionModalContext.destroy();
    // }
    // }
    //check
    //  componentDidMount() {
    // const save = this.props.onExpressionChanged(
    //   toInitializedExpression(this.state),
    //   this.props.expressionIndex
    // );
    // }
    //  componentDidUpdate() {
    // const svg = this.svg;
    // refreshSvgLinks(svg);
    // }
    // let svg: SVGSVGElement | null = null;
    var expression = (0, react_1.useMemo)(function () { return toInitializedExpression(expressionProp); }, [expressionProp, toInitializedExpression]);
    var objExpression = (0, react_1.useMemo)(function () { return new shared_1.Expression(expression); }, [expression]);
    var operator = (0, react_1.useMemo)(function () { return objExpression.operatorList[expression.operator]; }, [objExpression, expression.operator]);
    var hasMultiple = (0, react_1.useMemo)(function () { return operator && !!operator.reductor; }, [operator]);
    var linearizedFields = (0, react_1.useMemo)(function () { return (0, shared_1.linearizeFields)(fieldList); }, [fieldList]);
    var options = (0, react_1.useMemo)(function () { return summaryOptions(linearizedFields, objExpression.summaryList, i18n); }, [i18n, linearizedFields, objExpression.summaryList]);
    var currentField = (0, react_1.useMemo)(function () {
        return expression.sourceField
            ? linearizedFields.find(function (i) { return i.fullPath === expression.sourceField; })
            : undefined;
    }, [expression.sourceField, linearizedFields]);
    var contextFieldValue = (0, react_1.useMemo)(function () {
        var _a;
        var matchingField = (0, shared_1.getContextField)(contextDefinition, expression.sourceField || "");
        if (expression.sourceSummary) {
            var summary = (0, shared_1.valueOrThrow)(objExpression.summaryList[expression.sourceSummary]);
            var dataType = void 0;
            var minValue = undefined;
            switch (summary.name) {
                case "countOf":
                    dataType = "Numeric";
                    minValue = 0;
                    break;
                case "sumOf":
                    dataType = ((_a = matchingField === null || matchingField === void 0 ? void 0 : matchingField.properties) === null || _a === void 0 ? void 0 : _a.dataType) === "Currency" ? "Currency" : "Numeric";
                    break;
                case "averageOf":
                    dataType = "Currency";
                    break;
                default:
                    dataType = undefined;
            }
            if (dataType !== undefined) {
                return {
                    value: createContextField(dataType, minValue),
                    disableInput: false,
                };
            }
        }
        if (matchingField) {
            return {
                value: matchingField,
                disableInput: false,
            };
        }
        var defaultContextField = {
            value: {
                name: "",
                humanName: "",
                fullPath: "",
                properties: {
                    dataType: "Alphanumeric",
                    inputType: "Default",
                    label: "",
                    sampleValue: [],
                    humanName: "",
                    description: "",
                    instructions: "",
                },
            },
            disableInput: true,
        };
        return defaultContextField;
    }, [
        contextDefinition,
        expression.sourceField,
        expression.sourceSummary,
        objExpression.summaryList,
    ]);
    var dataElementInputTargetValue = (0, react_1.useMemo)(function () {
        var result = shared_1.ContextValueCatchUndefined$Schema.parse(expression.targetValue);
        var filteredResult = Array.isArray(result) ? undefined : result;
        return filteredResult;
    }, [expression.targetValue]);
    var typeValue = (0, react_1.useMemo)(function () {
        var parsedTargetValue = (0, shared_1.formValueToValueType)(moment, contextFieldValue.value.properties, dataElementInputTargetValue !== undefined ? [dataElementInputTargetValue] : []);
        return parsedTargetValue;
    }, [contextFieldValue.value.properties, dataElementInputTargetValue, moment]);
    var funnelClick = (0, react_1.useCallback)(function () {
        setState(function (prev) { return (__assign(__assign({}, prev), { showFunnel: prev.showFunnel === null ? true : !prev.showFunnel })); });
        var updatedExpression = __assign(__assign({}, expression), { where: expression.where });
        onExpressionChanged(updatedExpression, expressionIndex);
    }, [expression, expressionIndex, onExpressionChanged]);
    var handleWhereChanged = (0, react_1.useCallback)(function (updatedWhere, index) {
        var updatedExpression = __assign(__assign({}, expression), { where: updatedWhere });
        onExpressionChanged(updatedExpression, index);
    }, [expression, onExpressionChanged]);
    var makeChangeHandler = (0, react_1.useCallback)(function (expressionArgument, i) {
        var arr = (expression.expressionList || []).slice(0);
        if (typeof expressionArgument === "undefined") {
            arr.splice(i, 1);
            if (arr.length === 1) {
                if (arr[0].expressionList && arr[0].expressionList.length === 0) {
                    arr[0].expressionList = [];
                }
                var updatedExpression = __assign({}, arr[0]);
                onExpressionChanged(updatedExpression, expressionIndex);
            }
            else {
                var updatedExpression = __assign(__assign({}, expression), { expressionList: arr });
                onExpressionChanged(updatedExpression, expressionIndex);
            }
        }
        else {
            arr.splice(i, 1, expressionArgument);
            var updatedExpression = __assign(__assign({}, expression), { expressionList: arr });
            onExpressionChanged(updatedExpression, expressionIndex);
        }
    }, [expression, expressionIndex, onExpressionChanged]);
    var highlightExpression = (0, react_1.useCallback)(function () {
        setState(function (prev) { return (__assign(__assign({}, prev), { highlighted: true })); });
        setTimeout(function () {
            setState(function (prev) { return (__assign(__assign({}, prev), { highlighted: false })); });
        }, 1200);
    }, []);
    var valueChanged = (0, react_1.useCallback)(function (newValue) {
        var updatedExpression = __assign(__assign({}, expression), { targetValue: newValue.value });
        onExpressionChanged(updatedExpression, expressionIndex);
    }, [expression, expressionIndex, onExpressionChanged]);
    var inputSwitchChanged = (0, react_1.useCallback)(function (args) {
        var value = args.value;
        var operator = value ? "and" : "or";
        var updatedExpression = __assign(__assign({}, expression), { operator: operator });
        onExpressionChanged(updatedExpression, expressionIndex);
    }, [expression, expressionIndex, onExpressionChanged]);
    // const inputSwitchChanged = (args: { event: unknown; value: boolean }) {
    //   const { value } = args;
    //   setState(
    //     {
    //       operator: value ? "and" : "or",
    //     },
    //
    //   );
    // }
    var inputMultipleNegativeChanged = (0, react_1.useCallback)(function (e) {
        var value = e;
        var updatedExpression = __assign(__assign({}, expression), { isNegative: value });
        onExpressionChanged(updatedExpression, expressionIndex);
    }, [expression, expressionIndex, onExpressionChanged]);
    var inputSelectChanged = (0, react_1.useCallback)(function (value, name) {
        var _a;
        var updatedExpression = __assign(__assign({}, expression), (_a = {}, _a[name] = name === "isNegative" ? (value === "1" ? true : false) : value, _a));
        onExpressionChanged(updatedExpression, expressionIndex);
    }, [expression, expressionIndex, onExpressionChanged]);
    var inputSelectSourceChanged = (0, react_1.useCallback)(function (value) {
        return inputSelectChanged(value, "sourceField");
    }, [inputSelectChanged]);
    var inputSelectSourceClear = (0, react_1.useCallback)(function () {
        var newExp = new shared_1.Expression();
        var updatedExpression = __assign(__assign({}, newExp), { sourceSummary: expression.sourceSummary });
        onExpressionChanged(updatedExpression, expressionIndex);
    }, [expression, expressionIndex, onExpressionChanged]);
    var inputSelectOperatorChanged = (0, react_1.useCallback)(function (args) {
        var value = args.value;
        return inputSelectChanged(value, "operator");
    }, [inputSelectChanged]);
    var inputSelectNegativeChanged = (0, react_1.useCallback)(function (args) {
        var value = args.value;
        return inputSelectChanged(value, "isNegative");
    }, [inputSelectChanged]);
    var inputSelectSourceSummaryChanged = (0, react_1.useCallback)(function (value) {
        return inputSelectChanged(value, "sourceSummary");
    }, [inputSelectChanged]);
    var convertToMultiple = (0, react_1.useCallback)(function (value) {
        var updatedExpression = __assign(__assign({}, expression), { expressionList: [expression, emptyExpression()], operator: value, targetValue: undefined, isNegative: false });
        onExpressionChanged(updatedExpression, expressionIndex);
    }, [expression, expressionIndex, onExpressionChanged]);
    var addNewExpression = (0, react_1.useCallback)(function () {
        var updatedExpression = __assign(__assign({}, expression), { expressionList: __spreadArray(__spreadArray([], (expression.expressionList || []), true), [emptyExpression()], false), targetValue: undefined });
        onExpressionChanged(updatedExpression, expressionIndex);
    }, [expression, expressionIndex, onExpressionChanged]);
    var negateThis = (0, react_1.useCallback)(function () {
        var updatedExpression = __assign(__assign({}, expression), { isNegative: !expression.isNegative });
        onExpressionChanged(updatedExpression, expressionIndex);
    }, [expression, expressionIndex, onExpressionChanged]);
    var deleteExpression = (0, react_1.useCallback)(function () {
        onExpressionChanged(undefined, expressionIndex);
    }, [expressionIndex, onExpressionChanged]);
    var deleteThis = (0, react_1.useCallback)(function () {
        deleteExpression();
    }, [deleteExpression]);
    var inputControlResourcesProp = (0, react_1.useMemo)(function () {
        return __assign(__assign({}, resources), { clickUploadImage: i18n("clickUploadImage"), contextValidationErrors: {
                "cannot-send-calculated-values": i18n("contextValidationErrors.cannot-send-calculated-values"),
                "error-evaluating-dependency": i18n("contextValidationErrors.error-evaluating-dependency"),
                "field-is-required": i18n("contextValidationErrors.field-is-required"),
                "field-max-instances-not-met": i18n("contextValidationErrors.field-max-instances-not-met"),
                "field-max-value-not-met": i18n("contextValidationErrors.field-max-value-not-met"),
                "field-min-instances-not-met": i18n("contextValidationErrors.field-min-instances-not-met"),
                "field-min-value-not-met": i18n("contextValidationErrors.field-min-value-not-met"),
                "invalid-input-mask-value": i18n("contextValidationErrors.invalid-input-mask-value"),
                "logical-cannot-be-list": i18n("contextValidationErrors.logical-cannot-be-list"),
                "record-is-not-a-list": i18n("contextValidationErrors.record-is-not-a-list"),
                "record-max-instances-not-met": i18n("contextValidationErrors.record-max-instances-not-met"),
                "record-min-instances-not-met": i18n("contextValidationErrors.record-min-instances-not-met"),
                "string-value-exceeds-max-length": i18n("contextValidationErrors.string-value-exceeds-max-length"),
                "string-value-exceeds-max-line-count": i18n("contextValidationErrors.string-value-exceeds-max-line-count"),
                "value-list-not-present": i18n("contextValidationErrors.value-list-not-present"),
                "value-must-be-boolean-type": i18n("contextValidationErrors.value-must-be-boolean-type"),
                "value-must-be-date-type": i18n("contextValidationErrors.value-must-be-date-type"),
                "value-must-be-integer": i18n("contextValidationErrors.value-must-be-integer"),
                "value-must-be-number-type": i18n("contextValidationErrors.value-must-be-number-type"),
                "value-must-be-string-type": i18n("contextValidationErrors.value-must-be-string-type"),
                "value-must-be-time-type": i18n("contextValidationErrors.value-must-be-time-type"),
                "value-must-be-valid-data-uri": i18n("contextValidationErrors.value-must-be-valid-data-uri"),
                "value-must-be-valid-date-time": i18n("contextValidationErrors.value-must-be-valid-date-time"),
                "value-must-be-valid-time-time": i18n("contextValidationErrors.value-must-be-valid-time-time"),
                "value-out-of-value-list": i18n("contextValidationErrors.value-out-of-value-list"),
            }, dragDropFile: i18n("dragDropFile") });
    }, [resources, i18n]);
    var selectListOperatorOptions = (0, react_1.useMemo)(function () {
        if (parentExpressionOperator) {
            var optionsLeft = parentExpressionOperator === "and"
                ? [{ label: i18n("or"), value: "or" }]
                : [{ label: i18n("dataTypeValues.and"), value: "and" }];
            return optionsLeft;
        }
        else {
            return [
                { label: i18n("dataTypeValues.and"), value: "and" },
                { label: i18n("or"), value: "or" },
            ];
        }
    }, [i18n, parentExpressionOperator]);
    return {
        colors: colors,
        typeValue: typeValue,
        dataElementInputTargetValue: dataElementInputTargetValue,
        selectOperatorProps: selectOperatorProps,
        appliesForWhereFunnel: appliesForWhereFunnel,
        contextFieldValue: contextFieldValue,
        selectableFieldList: selectableFieldList,
        getField: getField,
        toInitializedExpression: toInitializedExpression,
        getSvgProps: getSvgProps,
        renderFieldOption: renderFieldOption,
        summaryOptions: summaryOptions,
        moment: moment,
        i18n: i18n,
        hasMultiple: hasMultiple,
        options: options,
        currentField: currentField,
        makeChangeHandler: makeChangeHandler,
        highlightExpression: highlightExpression,
        valueChanged: valueChanged,
        inputSwitchChanged: inputSwitchChanged,
        inputMultipleNegativeChanged: inputMultipleNegativeChanged,
        inputSelectChanged: inputSelectChanged,
        inputSelectSourceChanged: inputSelectSourceChanged,
        inputSelectOperatorChanged: inputSelectOperatorChanged,
        inputSelectNegativeChanged: inputSelectNegativeChanged,
        inputSelectSourceSummaryChanged: inputSelectSourceSummaryChanged,
        convertToMultiple: convertToMultiple,
        addNewExpression: addNewExpression,
        deleteThis: deleteThis,
        negateThis: negateThis,
        deleteExpression: deleteExpression,
        funnelClick: funnelClick,
        handleWhereChanged: handleWhereChanged,
        state: state,
        resources: resources,
        objExpression: objExpression,
        expression: expression,
        onExpressionChanged: onExpressionChanged,
        emptyExpression: emptyExpression,
        inputControlResourcesProp: inputControlResourcesProp,
        selectListOperatorOptions: selectListOperatorOptions,
        inputSelectSourceClear: inputSelectSourceClear,
    };
};
exports.useFilterBuilder = useFilterBuilder;
