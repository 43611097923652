import {
  AccordionExpandable,
  AccordionExpandableProps,
  Box,
  DataElementInputProps,
  DatePickerProps,
  DesignBreakpointType,
  Heading,
  Text,
  truncateString,
  useColors,
  useFormController,
} from "@prodoctivity/design-system";
import {
  evaluateContextValue,
  evaluateRecordInstanceList,
  formValueToValueType,
  isCalculatedField,
  isTextLineContextField,
  shouldNever,
} from "@prodoctivity/shared";
import type {
  ContextField,
  ContextRecord,
  DataElement,
  InputControlValueType,
  ParametersObject,
  RecordContextStack,
  TemplateContextDefinition,
  TemplateContextRecordHolder,
  TemplateWizardDefinition,
  TemplateWizardFieldIsRecord,
} from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { FormConfiguration, SequenceBehavior, WebServiceConnector } from "../../_lib/types";
import { noop } from "../../_lib/utils";
import { getWizardFieldSections, toValueItems } from "../utils";

import type momentType from "moment";
import { Topic } from "../../Topic";
import { contextToFormDefinition } from "../../_lib/context-to-FormDefinition";
import { wizardDefinitionToLayout } from "../../_lib/wizardDefinition-to-layout";
import { ProDoctivityFormItem } from "./Item";
import { formatValue } from "./Item/Item";

export function contextToExcerpt(
  moment: typeof momentType,
  i18n: (k: string) => string,
  holder: TemplateContextRecordHolder,
  wizardRecord: TemplateWizardFieldIsRecord,
  maxLength: number,
  context: ParametersObject,
  recordContextStack: RecordContextStack
): string[] {
  const data =
    recordContextStack.length === 0
      ? context
      : recordContextStack[recordContextStack.length - 1].value;

  const calculatedFields = holder.fields.filter(
    (fld) =>
      (fld.properties.dataType === "Currency" || fld.properties.dataType === "Numeric") &&
      !!fld.properties.isCalculated
  );

  const result: string[] = [];

  let currentValue = "";
  for (const field of wizardRecord.fields) {
    if (field.isRecord) {
      continue;
    }
    if (calculatedFields.some((fld) => fld.fullPath === field.key)) {
      continue;
    }

    const contextField = holder.fields.find((fld) => fld.fullPath === field.key);
    if (!contextField) {
      continue;
    }

    const { value: val } = evaluateContextValue(data, field.key, recordContextStack);

    if (
      val === undefined ||
      typeof val === "function" ||
      (typeof val === "object" && !Array.isArray(val))
    ) {
      continue;
    }

    const thisValue = `${currentValue ? " - " : ""}${contextField.humanName}: ${formatValue(
      moment,
      i18n,
      contextField.properties,
      val
    )}`;

    if (currentValue.length + thisValue.length > maxLength) {
      currentValue = truncateString(currentValue + thisValue, maxLength) || "";
      break;
    } else {
      currentValue += thisValue;
    }
  }

  result.push(currentValue);

  currentValue = "";
  for (const field of wizardRecord.fields) {
    if (!field.isRecord) {
      continue;
    }
    const contextRecord = holder.records.find((rec) => rec.fullPath === field.key);
    if (!contextRecord) {
      continue;
    }

    const list = data[contextRecord.name];

    if (!Array.isArray(list)) {
      continue;
    }

    const thisValue = `${currentValue ? " - " : ""}${contextRecord.humanName}: [${formatValue(
      moment,
      i18n,
      {
        dataType: "Numeric",
        inputType: "TextBox",
        description: "",
        humanName: contextRecord.humanName,
        instructions: "",
        label: contextRecord.properties.label,
      },
      list.length
    )}]`;
    if (currentValue.length + thisValue.length > maxLength) {
      currentValue = truncateString(currentValue + thisValue, maxLength) || "";
      break;
    } else {
      currentValue += thisValue;
    }
  }

  for (const field of wizardRecord.fields) {
    if (field.isRecord) {
      continue;
    }
    if (!calculatedFields.some((fld) => fld.fullPath === field.key)) {
      continue;
    }

    const contextField = holder.fields.find((fld) => fld.fullPath === field.key);
    if (!contextField) {
      continue;
    }

    if (
      (contextField.properties.dataType === "Currency" ||
        contextField.properties.dataType === "Numeric") &&
      !!contextField.properties.isCalculated
    ) {
      const { value: val } = evaluateContextValue(data, field.key, recordContextStack);

      if (
        val === undefined ||
        typeof val === "function" ||
        (typeof val === "object" && !Array.isArray(val))
      ) {
        continue;
      }

      const thisValue = `${currentValue ? " - " : ""}${contextField.humanName}: ${formatValue(
        moment,
        i18n,
        contextField.properties,
        val
      )}`;

      if (currentValue.length + thisValue.length > maxLength) {
        break;
      } else {
        currentValue += thisValue;
      }
    } else {
      continue;
    }

    if (currentValue) {
      result.push(currentValue);
    }
  }

  return result;
}

type Props = {
  recordPrefix: string;
  recordInstanceFullPath: string;
  wizardDefinition: TemplateWizardDefinition;
  contextDefinition: TemplateContextDefinition;
  layoutTopics: Set<string>;
  onNavigate: (to: string) => void;
  // groupLayouts: GroupLayouts;
  disabledElements: string[];
  sequenceBehavior: SequenceBehavior;
  sequenceBehaviorEnumLock: boolean;
  readOnly: boolean;
  getDataElement: (
    fullPath: string
  ) => { contextField: ContextField; dataElement: DataElement } | undefined;
  i18n: (key: string) => string;
  connectors: WebServiceConnector[];
  defaultColumns?: string;
  componentBreakpoint: DesignBreakpointType;
  moment: typeof momentType;
  recordContextStack: RecordContextStack;
  resources: DatePickerProps["resources"] &
    DataElementInputProps["resources"] & { collapse: string; expand: string };
};

type State = {
  activeItem: {
    name: string;
  };
  columns: string;
};

export const FormSummary: FunctionComponent<Props> = ({
  recordPrefix,
  recordInstanceFullPath,
  wizardDefinition,
  contextDefinition,
  layoutTopics,
  onNavigate,
  i18n,
  connectors,
  moment,
  componentBreakpoint,
  resources,
  recordContextStack,
  defaultColumns,
  getDataElement,
  disabledElements,
  sequenceBehaviorEnumLock,
  sequenceBehavior,
  readOnly,
}) => {
  const { colors } = useColors();
  const { wizardFields } = useMemo(() => {
    const formDefinition = contextToFormDefinition(contextDefinition);
    const formLayout = wizardDefinitionToLayout(wizardDefinition, contextDefinition, undefined);
    const { fields: wizardFields } = getWizardFieldSections(wizardDefinition);
    const groupsMap = new Map(formDefinition.groups.map((group) => [group.fullPath, group]));

    const formConfiguration: FormConfiguration = {
      formLayout,
      formConnectors: [],
    };

    return {
      // groupLayouts: formLayout.groupLayouts,
      formDefinition,
      formLayout,
      formConfiguration,
      wizardFields,
      groupsMap,
    };
  }, [contextDefinition, wizardDefinition]);

  const [{ activeItem, columns }, setState] = useState<State>({
    activeItem: {
      name: "",
    },
    columns: defaultColumns ?? "3",
  });

  const {
    getContextValueAsString: getContextValue,
    context,
    updateContextRecordData,
  } = useFormController();

  const isElementDisabled = useCallback(
    (name: string, dataElement?: DataElement): boolean => {
      return (
        disabledElements.includes(name) ||
        readOnly ||
        (!!dataElement &&
          isTextLineContextField(dataElement) &&
          !!dataElement.sequenceId &&
          sequenceBehaviorEnumLock)
      );
    },
    [disabledElements, readOnly, sequenceBehaviorEnumLock]
  );

  const items = useMemo(() => {
    const formValues = recordContextStack.length
      ? recordContextStack[recordContextStack.length - 1].value
      : context;

    const groupValues = formValues;

    const valueItems = toValueItems(
      wizardDefinition,
      getDataElement,
      groupValues,
      formValues,
      moment,
      isElementDisabled
    );

    return valueItems;
  }, [context, getDataElement, isElementDisabled, moment, recordContextStack, wizardDefinition]);

  const onEditChange = useCallback(
    (elementName: string) => {
      const result: DataElementInputProps["onChange"] = (typeValue) => {
        const foundItem = items.find((item) => {
          return item.type === "dataElement" && item.contextField.name === elementName;
        });

        if (foundItem && foundItem.type === "dataElement") {
          const instanceFullPath = `${recordInstanceFullPath ? recordInstanceFullPath + "/" : ""}${
            foundItem.contextField.name
          }[${0}]`;
          updateContextRecordData(instanceFullPath, typeValue, foundItem.contextField.name);
        }
      };

      return result;
    },
    [items, recordInstanceFullPath, updateContextRecordData]
  );

  const onEditClick = useCallback(
    (name: string) => {
      if (activeItem.name !== "") return;

      const found = items.find((item) => {
        return item.name === name;
      });
      if (!found || found.type !== "dataElement") {
        return;
      }
      const { disabled } = found;

      if (disabled) {
        return;
      }

      if (isCalculatedField(found.contextField.properties)) {
        return;
      }

      setState((prev) => ({ ...prev, activeItem: { name } }));
    },
    [activeItem.name, items]
  );

  const onEditSave = useCallback(() => {
    const found = items.find((item) => item.name === activeItem.name);
    if (!found || found.type !== "dataElement") {
      return;
    }
    // const value = found.typeValue;
    updateContextRecordData(recordInstanceFullPath, found.typeValue, found.instanceFullPath);
    // onChange({
    //   name: activeItem.name,
    //   value,
    //   errors: [],
    // });

    setState((prev) => ({
      ...prev,
      activeItem: {
        name: "",
      },
    }));
  }, [activeItem.name, items, recordInstanceFullPath, updateContextRecordData]);

  const onEditCancel = useCallback(() => {
    setState((prev) => ({
      ...prev,
      activeItem: {
        name: "",
      },
    }));
  }, []);

  const containerStyle = useMemo(() => {
    return componentBreakpoint === "small"
      ? ({
          display: "flex",

          flexDirection: "column",
        } as const)
      : ({
          display: "flex",
          flexWrap: "wrap",
        } as const);
  }, [componentBreakpoint]);

  let recordCount = 0;

  return (
    <div style={containerStyle}>
      {/* <div
        style={{
          position: "absolute",
          right: "0",
          top: "5px",
        }}
      >
        <ColumnBtn
          onClick={this.onColumnsClick}
          i18n={i18n}
          defaultColumns={this.props.defaultColumns}
        />
      </div> */}
      {items.map((layoutItem, index) => {
        if (layoutItem.type === "topic")
          return (
            <div
              key={index}
              style={{
                width: "100%",
                height: "40px",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              <Topic
                isDesignMode={false}
                topicId={`${index}`}
                topicName={layoutItem.name}
                layoutTopics={layoutTopics}
                paddingX={0}
                i18n={i18n}
                enableTopicEditing={noop}
                removeTopic={noop}
                onSubmitEditTopic={noop}
              />
            </div>
          );
        if (layoutItem.type === "group") {
          const contextWizardRecord = wizardFields.find(
            (x) => x.isRecord === true && layoutItem.name === x.key
          );
          const contextRecord = contextDefinition.records.find(
            (key) => key.fullPath === layoutItem.name
          );

          if (!contextWizardRecord || !contextRecord || !contextWizardRecord.isRecord) {
            return null;
          }

          recordCount += 1;

          return (
            <Box
              key={`${layoutItem.name}_${index}`}
              paddingLeft={2}
              display="flex"
              gap={2}
              direction="column"
              flex="grow"
              width="100%"
            >
              <Box margin={1} />
              <Heading size="400" color={colors.black900}>
                <Text weight="bold">{contextRecord.properties.label}:</Text>
              </Heading>

              <SummaryRecordLayoutItem
                recordPrefix={recordPrefix}
                recordCount={recordCount}
                contextWizardRecord={contextWizardRecord}
                contextRecord={contextRecord}
                recordContextStack={recordContextStack}
                componentBreakpoint={componentBreakpoint}
                disabledElements={disabledElements}
                getDataElement={getDataElement}
                i18n={i18n}
                layoutTopics={layoutTopics}
                moment={moment}
                onNavigate={onNavigate}
                parentInstanceFullPath={recordInstanceFullPath}
                readOnly={readOnly}
                resources={resources}
                sequenceBehavior={sequenceBehavior}
                defaultColumns={defaultColumns}
              />
              <Box margin={1} />
            </Box>
          );
        }
        if (layoutItem.type === "dataElement") {
          const keySuffix = `${layoutItem.contextField.name}`;
          const instanceFullPath = recordInstanceFullPath
            ? `${recordInstanceFullPath}/${keySuffix}`
            : keySuffix;

          const val = evaluateContextValue(context, instanceFullPath, recordContextStack);

          const typeValue: InputControlValueType =
            val.value !== undefined
              ? formValueToValueType(
                  moment,
                  layoutItem.contextField.properties,
                  Array.isArray(val.value) ? val.value : [val.value]
                )
              : {
                  dataType: layoutItem.contextField.properties.dataType,
                  value: undefined,
                };

          return (
            <ProDoctivityFormItem
              key={index}
              instanceFullPath={instanceFullPath}
              typeValue={typeValue}
              editable={
                activeItem.name === "" &&
                !layoutItem.disabled &&
                !isCalculatedField(layoutItem.contextField.properties)
              }
              editing={layoutItem.name === activeItem.name}
              columns={columns}
              contextField={layoutItem.contextField}
              onChange={onEditChange(layoutItem.contextField.name)}
              onClick={onEditClick}
              onSave={onEditSave}
              onEditExit={onEditCancel}
              onErrors={noop}
              i18n={i18n}
              onEdit={onNavigate}
              connectors={connectors}
              moment={moment}
              componentBreakpoint={componentBreakpoint}
              getContextValue={getContextValue}
              onBlur={noop}
              recordContextStack={recordContextStack}
              resources={resources}
            />
          );
        }
        shouldNever(layoutItem);
        throw new Error(`Unimplemented or not provided layout item type .`);
      })}
    </div>
  );
};

const SummaryRecordLayoutItem: FunctionComponent<{
  recordPrefix: string;
  recordCount: number;
  contextWizardRecord: TemplateWizardFieldIsRecord;
  contextRecord: ContextRecord;
  recordContextStack: RecordContextStack;
  parentInstanceFullPath: string;

  layoutTopics: Set<string>;
  getDataElement: (fullPath: string) =>
    | {
        contextField: ContextField;
        dataElement: DataElement;
      }
    | undefined;
  disabledElements: string[];
  readOnly: boolean;
  i18n: (key: string) => string;
  onNavigate: (to: string) => void;
  defaultColumns?: string | undefined;
  moment: typeof momentType;
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    collapse: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
    expand: string;
  };
  sequenceBehavior: SequenceBehavior;
  componentBreakpoint: "small" | "medium" | "large" | "hd";
}> = ({
  recordPrefix,
  recordCount,
  contextWizardRecord,
  contextRecord,
  recordContextStack,
  parentInstanceFullPath,
  componentBreakpoint,
  disabledElements,
  getDataElement,
  i18n,
  layoutTopics,
  moment,
  onNavigate,
  readOnly,
  resources,
  sequenceBehavior,
}) => {
  const { context } = useFormController();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const recordInstances = evaluateRecordInstanceList(
    parentInstanceFullPath,
    contextRecord.name,
    context
  );

  const items = useMemo(() => {
    return (recordInstances || []).map((instance, idx) => {
      const prefix = recordPrefix ? `${recordPrefix}.${recordCount}.${idx + 1}` : `${idx + 1}`;
      const keySuffix = `${contextRecord.name}[${idx}]`;
      const key = parentInstanceFullPath ? `${parentInstanceFullPath}/${keySuffix}` : keySuffix;

      const item: AccordionExpandableProps["items"][number] = {
        title: `#${recordPrefix ? `${recordPrefix}.` : ""}${idx + 1}`,
        summary: contextToExcerpt(moment, i18n, contextRecord, contextWizardRecord, 150, context, [
          ...recordContextStack,
          { name: contextRecord.name, value: instance },
        ]),
        children: (
          <SummaryRecordInstance
            recordPrefix={prefix}
            key={key}
            instanceFullPath={key}
            parentRecordContextStack={recordContextStack}
            instance={instance}
            contextRecord={contextRecord}
            wizardFieldRecord={contextWizardRecord}
            componentBreakpoint={componentBreakpoint}
            disabledElements={disabledElements}
            getDataElement={getDataElement}
            i18n={i18n}
            layoutTopics={layoutTopics}
            moment={moment}
            onNavigate={onNavigate}
            readOnly={readOnly}
            resources={resources}
            sequenceBehavior={sequenceBehavior}
          />
        ),
      };

      return item;
    });
  }, [
    componentBreakpoint,
    context,
    contextRecord,
    contextWizardRecord,
    disabledElements,
    getDataElement,
    i18n,
    layoutTopics,
    moment,
    onNavigate,
    parentInstanceFullPath,
    readOnly,
    recordContextStack,
    recordCount,
    recordInstances,
    recordPrefix,
    resources,
    sequenceBehavior,
  ]);

  return (
    <Box flex="grow">
      <AccordionExpandable
        accessibilityCollapseLabel={resources.collapse}
        accessibilityExpandLabel={resources.expand}
        id={`expandable_${recordContextStack}`}
        expandedIndex={expandedIndex}
        onExpandedChange={setExpandedIndex}
        items={items}
      />
    </Box>
  );
};

const SummaryRecordInstance: FunctionComponent<{
  recordPrefix: string;
  instanceFullPath: string;
  instance: ParametersObject;
  parentRecordContextStack: RecordContextStack;
  contextRecord: ContextRecord;
  wizardFieldRecord: TemplateWizardFieldIsRecord;

  layoutTopics: Set<string>;
  getDataElement: (fullPath: string) =>
    | {
        contextField: ContextField;
        dataElement: DataElement;
      }
    | undefined;
  disabledElements: string[];
  readOnly: boolean;
  i18n: (key: string) => string;
  onNavigate: (to: string) => void;
  defaultColumns?: string | undefined;
  moment: typeof momentType;
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    collapse: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
    expand: string;
  };
  sequenceBehavior: SequenceBehavior;
  componentBreakpoint: "small" | "medium" | "large" | "hd";
}> = ({
  recordPrefix,
  instanceFullPath,
  instance,
  parentRecordContextStack,
  contextRecord,
  wizardFieldRecord,
  sequenceBehavior,

  layoutTopics,
  getDataElement,
  disabledElements,
  readOnly,
  i18n,
  onNavigate,
  defaultColumns,
  moment,
  resources,
  componentBreakpoint,
}) => {
  const recordContextStack = useMemo(() => {
    return [
      ...parentRecordContextStack,
      {
        name: contextRecord.name,
        value: instance,
      },
    ];
  }, [contextRecord.name, instance, parentRecordContextStack]);

  const { recordContextDefinition, recordWizardDefinition } = useMemo(() => {
    const cd: TemplateContextDefinition = contextRecord;

    const wd: TemplateWizardDefinition = {
      defaultPageName: "page",
      defaultSectionName: wizardFieldRecord.label,
      dependencies: [],
      inferredDependencies: [],
      pages: [
        {
          key: "page",
          label: "page",
          properties: {},
          description: "",
          sections: [
            {
              key: wizardFieldRecord.key,
              properties: {},
              label: wizardFieldRecord.label,
              description: "",
              fields: wizardFieldRecord.fields,
            },
          ],
        },
      ],
    };

    return { recordContextDefinition: cd, recordWizardDefinition: wd };
  }, [contextRecord, wizardFieldRecord.fields, wizardFieldRecord.key, wizardFieldRecord.label]);

  return (
    <Box>
      <FormSummary
        recordPrefix={recordPrefix}
        recordInstanceFullPath={instanceFullPath}
        wizardDefinition={recordWizardDefinition}
        contextDefinition={recordContextDefinition}
        layoutTopics={layoutTopics}
        getDataElement={getDataElement}
        disabledElements={disabledElements}
        sequenceBehaviorEnumLock={sequenceBehavior === "Lock"}
        readOnly={readOnly}
        i18n={i18n}
        onNavigate={onNavigate}
        connectors={[]}
        defaultColumns={defaultColumns}
        componentBreakpoint={componentBreakpoint}
        moment={moment}
        recordContextStack={recordContextStack}
        resources={resources}
        sequenceBehavior={sequenceBehavior}
      />
    </Box>
  );
};
