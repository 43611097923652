import { Box, Button, Skeleton, Text, Tooltip } from "@prodoctivity/design-system";
import {
  DataElement$Schema,
  DataElementListSeparator,
  DateWithCatch$Schema,
  StringOrStringArrayWithCatch$Schema,
  isClassDataElement,
  isValueListContextField,
  parseDataURI,
  removeUndefinedKeys,
  sanitizeFieldName,
  toDictionaryDataType,
} from "@prodoctivity/shared";
import type { DataElement, DictionaryList } from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { NumberOrNumberArrayWithCatch$Schema, deepCopy } from "@prodoctivity/shared";
import { useMutation } from "@tanstack/react-query";
import { BreadCrumbEntry } from "../../../../components/BreadCrumb";

import { Page } from "../../../../components/Layout/Page";
import { NotificationMessage } from "../../../../components/NotificationMessage";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../../../hooks/useOrganizationQuery";
import { useServices } from "../../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../../link-templates";
import { useCacheManager } from "../../../../utils";
import { Item } from "../../DocumentType/Steps/DocumentKeyStep";
import { DataDictionaryTabs } from "./DataDictionaryTabs";
import { GeneralTab } from "./GeneralTab";
import { InputTab } from "./InputTab";
import { OutputTab } from "./OutputTab";

type DataElementConfigurationProps = {
  isUpdating: boolean;
  contextFieldItem?:
    | {
        dataElement: DataElement;
        itemToReplace: Item;
      }
    | undefined;
  onSave?: (dataElement: DataElement, oldItem?: Item | undefined) => void;
  showAddDataElementModule?: boolean;
  editContextField?: boolean;
  rightItems?: Item[];
  onCancel(): void;
  isPanelHidden: boolean;
};

export const DataElementConfiguration: FunctionComponent<DataElementConfigurationProps> = ({
  isUpdating,
  contextFieldItem,
  onSave,
  showAddDataElementModule,
  editContextField,
  rightItems,
  onCancel,
  isPanelHidden,
}) => {
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const location = useLocation();
  const previousConfiguration = location.state as DataElement;
  const { dataElementName } = useParams();
  const {
    getDataElement,
    saveDataElement,
    updateDataElement,
    getPaginatedOrganizationDictionaryLists,
  } = useServices();
  const dataElementParseResult = DataElement$Schema.safeParse(previousConfiguration);

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const getDataElementDetails = useCallback(async () => {
    if (dataElementName) {
      const res = await getDataElement(dataElementName);
      return res;
    } else {
      return {
        dataElement: undefined,
        classChildCount: undefined,
      };
    }
  }, [dataElementName, getDataElement]);

  const { data, isLoading, isError } = useOrganizationQuery(
    `/dataElements/${dataElementName}`,
    getDataElementDetails,
    {
      refetchOnMount: "always",
      refetchOnWindowFocus: false,
    }
  );

  const classChildCount: number = useMemo(() => {
    if (data && data.dataElement && data.classChildCount) {
      return data.classChildCount;
    } else return 0;
  }, [data]);

  const { clearQueryCache } = useCacheManager();

  const {
    mutate,
    reset,
    isLoading: isMutating,
  } = useMutation(isUpdating ? updateDataElement : saveDataElement, {
    onSuccess() {
      clearQueryCache();
      setToastMessage(resources.dataDictionary.savedSuccessfully);
      setShowSuccessToast(true);
      setTimeout(() => {
        setShowSuccessToast(false);
      }, 4500);
      organizationNavigate(`/settings/data-dictionary/data-elements/`);
    },
    onError(error: {
      name: string;
      message: string;
      response: {
        data: {
          errors: Array<{ errorCode: string; message: string }>;
        };
      };
    }) {
      const errorType = error.response.data.errors[0].message;
      switch (errorType) {
        case "invalid-name":
          setToastMessage(resources.dataDictionary.unableToSaveElementNameError);
          break;
        case "invalid-name-exceeds-allow-chars":
          setToastMessage(resources.dataDictionary.unableToSaveElementErrorExceedChar);
          break;
        case "exists":
          setToastMessage(resources.dataDictionary.dataElementExists);
          break;
        case "invalid-data-element-tag":
          setToastMessage(resources.dataDictionary.unableToSaveElementTagError);
          break;
        default:
          setToastMessage(resources.dataDictionary.unableToSaveElement);
      }
      setShowErrorToast(true);
      setTimeout(() => {
        setShowErrorToast(false);
      }, 4500);
    },
    onSettled() {
      reset();
    },
  });

  const getDictionaryLists = useCallback(async () => {
    let pageNumber = 0;
    const requestedPageLength = "100";
    let pageLength = 100;

    const result: DictionaryList<string>[] = [];
    do {
      const currentPage = await getPaginatedOrganizationDictionaryLists(
        pageNumber,
        requestedPageLength
      );
      (currentPage.dictionaryLists || []).forEach((dl) => result.push(dl));
      pageLength = currentPage.pageLength;
      pageNumber += 1;
    } while (pageLength >= parseInt(requestedPageLength));

    result.sort((a, b) => a.listSchema.name.localeCompare(b.listSchema.name));

    const lists = result.reduce((acc: Record<string, DictionaryList<string>>, next) => {
      if (!acc[next.listSchema.name]) {
        acc[next.listSchema.name] = next;
      }
      return acc;
    }, {});

    return Object.values(lists);
  }, [getPaginatedOrganizationDictionaryLists]);

  const { data: dictionaryLists, isLoading: loadingDictionaryLists } = useOrganizationQuery(
    `/dictionary-lists`,
    getDictionaryLists,
    {}
  );

  const handleSaveClicked = useCallback(
    (dataElement: DataElement) => {
      const currentDataElement = deepCopy(dataElement);

      if (showAddDataElementModule && (currentDataElement.maxOccurs ?? 0) > 1) {
        currentDataElement.output = {
          ...currentDataElement.output,
          outputFormat: currentDataElement.output?.outputFormat || "",
          dataElementListSeparator: DataElementListSeparator.Comma,
        };
      }
      if (currentDataElement.sampleValue === undefined || currentDataElement.sampleValue === "") {
        setToastMessage(resources.dataDictionary.noSampleValue);
        setShowErrorToast(true);
        setTimeout(() => {
          setShowErrorToast(false);
        }, 4500);
      } else if (
        (currentDataElement.maxOccurs ?? 0) > 1 &&
        !currentDataElement.output?.dataElementListSeparator
      ) {
        setToastMessage(resources.dataDictionary.noListSeparator);
        setShowErrorToast(true);
        setTimeout(() => {
          setShowErrorToast(false);
        }, 4500);
      } else if (showAddDataElementModule && onSave) {
        if (contextFieldItem) {
          onSave(
            currentDataElement,
            contextFieldItem.itemToReplace ? contextFieldItem.itemToReplace : undefined
          );
        } else {
          onSave(currentDataElement, undefined);
        }
      } else {
        mutate({
          dataElementName: dataElementName ?? currentDataElement.name.trim(),
          dataElement: removeUndefinedKeys(currentDataElement, true),
        });
      }
    },
    [
      resources.dataDictionary.noSampleValue,
      resources.dataDictionary.noListSeparator,
      mutate,
      dataElementName,
      onSave,
      showAddDataElementModule,
      contextFieldItem,
    ]
  );

  const contextFieldLabelAlreadyTaken = useCallback(
    (currentDataElement: DataElement) => {
      if (rightItems && currentDataElement) {
        const labelAlreadyExists = (
          sourceItemList: Item[],
          itemToFind: string,
          isSubItem?: boolean
        ): {
          coincidenceFound: boolean;
          isSubItem?: boolean;
        } => {
          for (const i of sourceItemList) {
            if (i.items && i.items.length > 0) {
              const foundInSubItems = labelAlreadyExists(i.items, itemToFind, true);
              if (foundInSubItems.isSubItem) {
                if (editContextField) {
                  if (itemToFind === contextFieldItem?.itemToReplace.label) {
                    return { coincidenceFound: false };
                  }
                }
                return {
                  coincidenceFound: true,
                };
              }
            } else {
              if (i.label === itemToFind && isSubItem) {
                return {
                  coincidenceFound: true,
                  isSubItem: true,
                };
              } else if (i.fullPath === itemToFind && !isSubItem) {
                if (editContextField) {
                  if (itemToFind === contextFieldItem?.itemToReplace.fullPath) {
                    return { coincidenceFound: false };
                  }
                }
                return {
                  coincidenceFound: true,
                };
              }
            }
          }

          return { coincidenceFound: false };
        };

        const coincidenceFoundWithInRecord = labelAlreadyExists(
          rightItems,
          sanitizeFieldName(currentDataElement.name, false)
        );

        if (coincidenceFoundWithInRecord.coincidenceFound) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    [
      rightItems,
      editContextField,
      contextFieldItem?.itemToReplace.fullPath,
      contextFieldItem?.itemToReplace.label,
    ]
  );

  const dataElement: DataElement = useMemo(() => {
    return data && data.dataElement
      ? data.dataElement
      : contextFieldItem
      ? contextFieldItem.dataElement
      : dataElementParseResult.success
      ? !isUpdating
        ? {
            ...dataElementParseResult.data,
            name: `${resources.copyOf} ${previousConfiguration.name}`,
          }
        : dataElementParseResult.data
      : {
          name: "",
          humanName: "",
          alternativeQuestions: [],
          dataType: "Alphanumeric",
          defaultValue: [],
          description: "",
          inputType: "TextBox",
          label: "",
          instructions: "",
          unique: false,
          dictionaryDataType: "Alphanumeric",
          cultureLanguageName: "es",
          sectionName: "",
          isReferenceField: false,
          readOnly: false,
        };
  }, [
    contextFieldItem,
    data,
    dataElementParseResult,
    isUpdating,
    previousConfiguration,
    resources.copyOf,
  ]);
  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.dataDictionary.dictionary,
        url: organizationLinkTemplates.dataDictionary(),
      },
      {
        type: "url",
        name: resources.dataDictionary.dataElements,
        url: organizationLinkTemplates.dataElements(),
      },
      { type: "text", name: dataElement.humanName || resources.new },
    ];
  }, [
    dataElement.humanName,
    resources.dataDictionary.dataElements,
    resources.dataDictionary.dictionary,
    resources.home,
    resources.new,
    resources.settings,
  ]);

  if (isLoading || loadingDictionaryLists || !dictionaryLists) {
    return (
      <Box display="flex" padding={4}>
        <Box>
          <Skeleton show={true} />
        </Box>
      </Box>
    );
  }

  if (isError) {
    return <Box>{resources.errorOccurred}</Box>;
  }

  return (
    <>
      {isPanelHidden ? (
        <Page breadCrumbEntries={breadCrumbEntries}>
          <Box display="flex" direction="column">
            <DataElementConfigurationDisplay
              dataElement={dataElement}
              showAddDataElementModule={!!showAddDataElementModule}
              isUpdating={isUpdating}
              contextFieldLabelAlreadyTaken={contextFieldLabelAlreadyTaken}
              isMutating={isMutating}
              dictionaryLists={dictionaryLists}
              onSave={handleSaveClicked}
              onCancelClicked={onCancel}
              classChildCount={classChildCount}
            />
            <Notification
              showSuccessToast={showSuccessToast}
              toastMessage={toastMessage}
              setShowSuccessToast={setShowSuccessToast}
              showErrorToast={showErrorToast}
              setShowErrorToast={setShowErrorToast}
            />
          </Box>
        </Page>
      ) : (
        <Box display="flex" direction="column">
          <DataElementConfigurationDisplay
            dataElement={dataElement}
            showAddDataElementModule={!!showAddDataElementModule}
            isUpdating={isUpdating}
            contextFieldLabelAlreadyTaken={contextFieldLabelAlreadyTaken}
            isMutating={isMutating}
            dictionaryLists={dictionaryLists}
            onSave={handleSaveClicked}
            onCancelClicked={onCancel}
            classChildCount={classChildCount}
          />
          <Notification
            showSuccessToast={showSuccessToast}
            toastMessage={toastMessage}
            setShowSuccessToast={setShowSuccessToast}
            showErrorToast={showErrorToast}
            setShowErrorToast={setShowErrorToast}
          />
        </Box>
      )}
    </>
  );
};

const Notification = ({
  showSuccessToast,
  toastMessage,
  setShowSuccessToast,
  showErrorToast,
  setShowErrorToast,
}: {
  showSuccessToast: boolean;
  toastMessage: string;
  setShowSuccessToast: (n: boolean) => void;
  showErrorToast: boolean;
  setShowErrorToast: (n: boolean) => void;
}) => (
  <>
    {showSuccessToast ? (
      <NotificationMessage
        type={"success"}
        message={toastMessage}
        position="bottom-left"
        handleDismiss={() => setShowSuccessToast(false)}
      />
    ) : null}

    {showErrorToast ? (
      <NotificationMessage
        type={"error"}
        message={toastMessage}
        position="bottom-left"
        handleDismiss={() => setShowErrorToast(false)}
      />
    ) : null}
  </>
);

type DataElementConfigurationDisplayProps = {
  dataElement: DataElement;
  showAddDataElementModule: boolean;
  isUpdating: boolean;
  isMutating: boolean;
  dictionaryLists: DictionaryList<string>[];
  contextFieldLabelAlreadyTaken: (currentDataElement: DataElement) => boolean;
  onSave(dataElement: DataElement): void;
  onCancelClicked(): void;
  classChildCount: number | undefined;
};

const DataElementConfigurationDisplay: FunctionComponent<DataElementConfigurationDisplayProps> = ({
  dataElement,
  showAddDataElementModule,
  isUpdating,
  isMutating,
  dictionaryLists,
  classChildCount,
  contextFieldLabelAlreadyTaken,
  onSave,
  onCancelClicked,
}) => {
  const { resources } = useAppTranslation();

  const [currentDataElement, setCurrentDataElement] = useState<DataElement>(dataElement);
  const [elementIsClassDataElement, setElementIsClassDataElement] = useState<boolean>(
    dataElement.classDataElementId ? true : false
  );
  const { clearQueryCache } = useCacheManager();

  const onSaveClicked = useCallback(() => {
    onSave(currentDataElement);
    clearQueryCache();
  }, [currentDataElement, onSave, clearQueryCache]);

  const { isSaveButtonDisabled, validationMessage } = useMemo(() => {
    if (!currentDataElement?.name) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.dataDictionary.noName,
      };
    }

    if (
      elementIsClassDataElement &&
      (!currentDataElement.classDataElementId || !currentDataElement.classDataElementTag)
    ) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.contextValidationErrors["field-is-required"],
      };
    }

    if (
      (Array.isArray(currentDataElement.sampleValue) && !currentDataElement.sampleValue.length) ||
      currentDataElement.sampleValue === undefined ||
      currentDataElement.sampleValue === ""
    ) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.dataDictionary.noSampleValue,
      };
    }

    if (isMutating) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.loading,
      };
    }

    if (contextFieldLabelAlreadyTaken(currentDataElement)) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.contextFieldAlreadyExists,
      };
    }

    return { isSaveButtonDisabled: false, validationMessage: "" };
  }, [
    currentDataElement,
    elementIsClassDataElement,
    isMutating,
    contextFieldLabelAlreadyTaken,
    resources.dataDictionary.noName,
    resources.dataDictionary.noSampleValue,
    resources.contextValidationErrors,
    resources.loading,
    resources.contextFieldAlreadyExists,
  ]);

  const handlePropertyChange = useCallback(
    (dataElement: DataElement) => {
      let newState: DataElement;
      switch (dataElement.dataType) {
        case "Logical": {
          newState = {
            description: dataElement.description,
            instructions: dataElement.instructions,
            label: dataElement.label,
            humanName: dataElement.humanName,
            dataType: "Logical",
            defaultValue:
              typeof dataElement.defaultValue === "boolean" ? dataElement.defaultValue : undefined,
            sampleValue: !!dataElement.sampleValue,
            invisible: dataElement.invisible,
            inputType: ["Checkbox", "Switch"].includes(dataElement.inputType)
              ? dataElement.inputType
              : "Checkbox",
            minOccurs:
              dataElement.minOccurs !== undefined ? (dataElement.minOccurs > 0 ? 1 : 0) : undefined,
            maxOccurs:
              dataElement.maxOccurs !== undefined ? (dataElement.maxOccurs > 0 ? 1 : 0) : undefined,
            name: dataElement.name,
            dictionaryDataType: toDictionaryDataType(
              dataElement.dataType,
              undefined,
              undefined,
              undefined
            ),
            unique: dataElement.unique,
            cultureLanguageName: dataElement.cultureLanguageName ?? "es",
            sectionName: dataElement.sectionName,
            isReferenceField: dataElement.isReferenceField,
            readOnly: dataElement.readOnly,
            ...(isClassDataElement(dataElement)
              ? {
                  classDataElementId: dataElement.classDataElementId,
                  classDataElementTag: dataElement.classDataElementTag,
                }
              : {
                  classDataElementId: undefined,
                }),
            alternativeQuestions: dataElement.alternativeQuestions,
            controlSize: dataElement.controlSize ? dataElement.controlSize : "None",
            input: undefined,
            output: {
              ...dataElement.output,
              outputFormat: dataElement.output?.outputFormat || "",
            },
          };
          break;
        }
        case "Alphanumeric": {
          if (dataElement.dictionaryListName || isValueListContextField(dataElement)) {
            if (
              ![
                "Dropdown",
                "Checkbox",
                "Radio",
                "CheckBoxList",
                "RadioButtonList",
                "DropDownList",
              ].includes(dataElement.inputType)
            ) {
              dataElement.inputType = "Dropdown";
            }
          } else {
            if (!["TextBox", "TextArea"].includes(dataElement.inputType)) {
              dataElement.inputType = "TextBox";
            }
          }

          switch (dataElement.inputType) {
            case "TextArea": {
              newState = {
                dataType: "Alphanumeric",
                alternativeQuestions: dataElement.alternativeQuestions,
                controlSize: dataElement.controlSize ? dataElement.controlSize : "None",
                description: dataElement.description,
                instructions: dataElement.instructions,
                label: dataElement.label,
                ...(isClassDataElement(dataElement)
                  ? {
                      classDataElementId: dataElement.classDataElementId,
                      classDataElementTag: dataElement.classDataElementTag,
                    }
                  : {
                      classDataElementId: undefined,
                    }),
                humanName: dataElement.humanName,
                defaultValue: StringOrStringArrayWithCatch$Schema.parse(dataElement.defaultValue),
                sampleValue: StringOrStringArrayWithCatch$Schema.parse(dataElement.sampleValue),
                invisible: dataElement.invisible,
                inputType: "TextArea",
                minOccurs: dataElement.minOccurs,
                maxOccurs: dataElement.maxOccurs,
                maxLength: dataElement.maxLength,
                lineCount: dataElement.lineCount,
                name: dataElement.name,
                dictionaryDataType: toDictionaryDataType(
                  dataElement.dataType,
                  dataElement.dictionaryListName,
                  undefined,
                  undefined
                ),
                unique: dataElement.unique,
                cultureLanguageName: dataElement.cultureLanguageName ?? "es",
                sectionName: dataElement.sectionName,
                isReferenceField: dataElement.isReferenceField,
                readOnly: dataElement.readOnly,
                input: dataElement.input,
                output: {
                  ...dataElement.output,
                  outputFormat: dataElement.output?.outputFormat || "",
                },
              };
              break;
            }
            case "CheckBoxList":
            case "Checkbox":
            case "RadioButtonList":
            case "Radio":
            case "DropDownList":
            case "Dropdown": {
              newState = {
                dataType: "Alphanumeric",
                alternativeQuestions: dataElement.alternativeQuestions,
                controlSize: dataElement.controlSize ? dataElement.controlSize : "None",
                description: dataElement.description,
                instructions: dataElement.instructions,
                ...(isClassDataElement(dataElement)
                  ? {
                      classDataElementId: dataElement.classDataElementId,
                      classDataElementTag: dataElement.classDataElementTag,
                    }
                  : {
                      classDataElementId: undefined,
                    }),
                label: dataElement.label,
                humanName: dataElement.humanName,
                defaultValue: StringOrStringArrayWithCatch$Schema.parse(dataElement.defaultValue),
                sampleValue: StringOrStringArrayWithCatch$Schema.parse(dataElement.sampleValue),
                invisible: dataElement.invisible,
                minOccurs: dataElement.minOccurs,
                maxOccurs: dataElement.maxOccurs,
                inputType: dataElement.inputType,
                listHasLabels: dataElement.listHasLabels,
                parentListSource: dataElement.parentListSource,
                valueList: dataElement.valueList,
                name: dataElement.name,
                dictionaryDataType: toDictionaryDataType(
                  dataElement.dataType,
                  dataElement.dictionaryListName,
                  isValueListContextField(dataElement) ? dataElement.dictionaryListId : undefined,
                  isValueListContextField(dataElement) ? dataElement.valueList : undefined
                ),
                dictionaryListName: dataElement.dictionaryListName,
                dictionaryListId: dataElement.dictionaryListId,
                unique: dataElement.unique,
                cultureLanguageName: dataElement.cultureLanguageName ?? "es",
                sectionName: dataElement.sectionName,
                isReferenceField: dataElement.isReferenceField,
                readOnly: dataElement.readOnly,
                input: dataElement.input,
                output: {
                  ...dataElement.output,
                  outputFormat: dataElement.output?.outputFormat || "",
                },
              };
              break;
            }
            case "TextBox":
            default: {
              newState = {
                dataType: "Alphanumeric",
                alternativeQuestions: dataElement.alternativeQuestions,
                ...(isClassDataElement(dataElement)
                  ? {
                      classDataElementId: dataElement.classDataElementId,
                      classDataElementTag: dataElement.classDataElementTag,
                    }
                  : {
                      classDataElementId: undefined,
                    }),
                controlSize: dataElement.controlSize ? dataElement.controlSize : "None",
                description: dataElement.description,
                instructions: dataElement.instructions,
                label: dataElement.label,
                humanName: dataElement.humanName,
                defaultValue: StringOrStringArrayWithCatch$Schema.parse(dataElement.defaultValue),
                sampleValue: StringOrStringArrayWithCatch$Schema.parse(dataElement.sampleValue),
                invisible: dataElement.invisible,
                inputType: "TextBox",
                minOccurs: dataElement.minOccurs,
                maxOccurs: dataElement.maxOccurs,
                maxLength: dataElement.maxLength,
                autoCompleteValues: dataElement.autoCompleteValues,
                inputMask: dataElement.inputMask,
                inputMaskStoreFixedChars: dataElement.inputMaskStoreFixedChars,
                name: dataElement.name,
                dictionaryDataType: toDictionaryDataType(
                  dataElement.dataType,
                  dataElement.dictionaryListName,
                  undefined,
                  undefined
                ),
                unique: dataElement.unique,
                cultureLanguageName: dataElement.cultureLanguageName ?? "es",
                sectionName: dataElement.sectionName,
                isReferenceField: dataElement.isReferenceField,
                readOnly: dataElement.readOnly,
                input: dataElement.input,
                output: {
                  ...dataElement.output,
                  outputFormat: dataElement.output?.outputFormat || "",
                },
              };
              break;
            }
          }
          break;
        }
        case "Numeric":
        case "Currency": {
          if (dataElement.isCalculated) {
            newState = {
              dataType: dataElement.dataType,
              alternativeQuestions: dataElement.alternativeQuestions,
              controlSize: dataElement.controlSize ? dataElement.controlSize : "None",
              description: dataElement.description,
              instructions: dataElement.instructions,
              label: dataElement.label,
              humanName: dataElement.humanName,
              defaultValue: NumberOrNumberArrayWithCatch$Schema.parse(dataElement.defaultValue),
              sampleValue: NumberOrNumberArrayWithCatch$Schema.parse(dataElement.sampleValue),
              invisible: dataElement.invisible,
              minOccurs: dataElement.minOccurs,
              maxOccurs: dataElement.maxOccurs,
              inputType: ["TextBox"].includes(dataElement.inputType)
                ? dataElement.inputType
                : "TextBox",
              minValue: dataElement.minValue,
              maxValue: dataElement.maxValue,
              ...(isClassDataElement(dataElement)
                ? {
                    classDataElementId: dataElement.classDataElementId,
                    classDataElementTag: dataElement.classDataElementTag,
                  }
                : {
                    classDataElementId: undefined,
                  }),
              autoCompleteValues: dataElement.autoCompleteValues,
              lineCount: dataElement.lineCount,
              maxLength: dataElement.maxLength,
              isSystemDate: dataElement.isSystemDate,
              isCalculated: dataElement.isCalculated,
              isCalculatedAdvancedMode: dataElement.isCalculatedAdvancedMode,
              calculatedField: dataElement.calculatedField,
              name: dataElement.name,
              dictionaryDataType: dataElement.dictionaryDataType,
              unique: dataElement.unique,
              cultureLanguageName: dataElement.cultureLanguageName ?? "es",
              sectionName: dataElement.sectionName,
              isReferenceField: dataElement.isReferenceField,
              readOnly: dataElement.readOnly,
              input: dataElement.input,
              output: {
                ...dataElement.output,
                outputFormat: dataElement.output?.outputFormat || "",
              },
            };
          } else {
            newState = {
              dataType: dataElement.dataType,
              alternativeQuestions: dataElement.alternativeQuestions,
              controlSize: dataElement.controlSize ? dataElement.controlSize : "None",
              description: dataElement.description,
              instructions: dataElement.instructions,
              label: dataElement.label,
              humanName: dataElement.humanName,
              defaultValue: NumberOrNumberArrayWithCatch$Schema.parse(dataElement.defaultValue),
              sampleValue: NumberOrNumberArrayWithCatch$Schema.parse(dataElement.sampleValue),
              invisible: dataElement.invisible,
              minOccurs: dataElement.minOccurs,
              maxOccurs: dataElement.maxOccurs,
              inputType: ["TextBox"].includes(dataElement.inputType)
                ? dataElement.inputType
                : "TextBox",
              minValue: dataElement.minValue,
              maxValue: dataElement.maxValue,
              autoCompleteValues: dataElement.autoCompleteValues,
              lineCount: dataElement.lineCount,
              maxLength: dataElement.maxLength,
              isSystemDate: dataElement.isSystemDate,
              isCalculated: false,
              ...(isClassDataElement(dataElement)
                ? {
                    classDataElementId: dataElement.classDataElementId,
                    classDataElementTag: dataElement.classDataElementTag,
                  }
                : {
                    classDataElementId: undefined,
                  }),
              name: dataElement.name,
              dictionaryDataType: dataElement.dictionaryDataType,
              unique: dataElement.unique,
              cultureLanguageName: dataElement.cultureLanguageName ?? "es",
              sectionName: dataElement.sectionName,
              isReferenceField: dataElement.isReferenceField,
              readOnly: dataElement.readOnly,
              input: dataElement.input,
              output: {
                ...dataElement.output,
                outputFormat: dataElement.output?.outputFormat || "",
              },
            };
          }
          break;
        }
        case "Date":
        case "DateTime": {
          newState = {
            dataType: dataElement.dataType,
            alternativeQuestions: dataElement.alternativeQuestions,
            controlSize: dataElement.controlSize ? dataElement.controlSize : "None",
            description: dataElement.description,
            instructions: dataElement.instructions,
            label: dataElement.label,
            humanName: dataElement.humanName,
            defaultValue: DateWithCatch$Schema.parse(dataElement.defaultValue),
            sampleValue: DateWithCatch$Schema.parse(dataElement.sampleValue),
            invisible: dataElement.invisible,
            minOccurs: dataElement.minOccurs,
            maxOccurs: dataElement.maxOccurs,
            isSystemDate: !!dataElement.isSystemDate,
            minValue: dataElement.minValue,
            maxValue: dataElement.maxValue,
            dateMinMaxType: dataElement.dateMinMaxType,
            inputType: ["DateTimePicker"].includes(dataElement.inputType)
              ? dataElement.inputType
              : "DateTimePicker",
            name: dataElement.name,
            dictionaryDataType: dataElement.dictionaryDataType,
            unique: dataElement.unique,
            cultureLanguageName: dataElement.cultureLanguageName ?? "es",
            sectionName: dataElement.sectionName,
            isReferenceField: dataElement.isReferenceField,
            readOnly: dataElement.readOnly,
            input: dataElement.input,
            ...(isClassDataElement(dataElement)
              ? {
                  classDataElementId: dataElement.classDataElementId,
                  classDataElementTag: dataElement.classDataElementTag,
                }
              : {
                  classDataElementId: undefined,
                }),
            output: {
              ...dataElement.output,
              outputFormat: dataElement.output?.outputFormat || "",
            },
          };
          break;
        }
        case "Time": {
          newState = {
            dataType: dataElement.dataType,
            alternativeQuestions: dataElement.alternativeQuestions,
            controlSize: dataElement.controlSize ? dataElement.controlSize : "None",
            description: dataElement.description,
            instructions: dataElement.instructions,
            label: dataElement.label,
            humanName: dataElement.humanName,
            defaultValue: dataElement.defaultValue,
            sampleValue: dataElement.sampleValue,
            invisible: dataElement.invisible,
            minOccurs: dataElement.minOccurs,
            maxOccurs: dataElement.maxOccurs,
            isSystemDate: !!dataElement.isSystemDate,
            minValue: dataElement.minValue,
            maxValue: dataElement.maxValue,
            inputType: ["TimePicker"].includes(dataElement.inputType)
              ? dataElement.inputType
              : "TimePicker",

            name: dataElement.name,
            dictionaryDataType: dataElement.dictionaryDataType,
            unique: dataElement.unique,
            cultureLanguageName: dataElement.cultureLanguageName ?? "es",
            sectionName: dataElement.sectionName,
            isReferenceField: dataElement.isReferenceField,
            readOnly: dataElement.readOnly,
            ...(isClassDataElement(dataElement)
              ? {
                  classDataElementId: dataElement.classDataElementId,
                  classDataElementTag: dataElement.classDataElementTag,
                }
              : {
                  classDataElementId: undefined,
                }),
            input: dataElement.input,
            output: {
              ...dataElement.output,
              outputFormat: dataElement.output?.outputFormat || "",
            },
          };
          break;
        }
        case "Image": {
          newState = {
            dataType: dataElement.dataType,
            alternativeQuestions: dataElement.alternativeQuestions,
            controlSize: dataElement.controlSize ? dataElement.controlSize : "None",
            description: dataElement.description,
            instructions: dataElement.instructions,
            label: dataElement.label,
            humanName: dataElement.humanName,
            ...(isClassDataElement(dataElement)
              ? {
                  classDataElementId: dataElement.classDataElementId,
                  classDataElementTag: dataElement.classDataElementTag,
                }
              : {
                  classDataElementId: undefined,
                }),
            defaultValue:
              typeof dataElement.defaultValue === "string" &&
              parseDataURI(dataElement.defaultValue) !== undefined
                ? dataElement.defaultValue
                : undefined,
            sampleValue:
              typeof dataElement.sampleValue === "string" &&
              parseDataURI(dataElement.sampleValue) !== undefined
                ? dataElement.sampleValue
                : undefined,
            invisible: dataElement.invisible,
            minOccurs: dataElement.minOccurs,
            maxOccurs: dataElement.maxOccurs,
            inputType: ["ImageUpload"].includes(dataElement.inputType)
              ? dataElement.inputType
              : "ImageUpload",

            name: dataElement.name,
            dictionaryDataType: dataElement.dictionaryDataType,
            unique: dataElement.unique,
            cultureLanguageName: dataElement.cultureLanguageName ?? "es",
            sectionName: dataElement.sectionName,
            isReferenceField: dataElement.isReferenceField,
            readOnly: dataElement.readOnly,
            input: undefined,
            output: undefined,
          };

          break;
        }
      }

      setCurrentDataElement(newState);
    },
    [setCurrentDataElement]
  );

  const hasSampleValue = useMemo(() => {
    if (currentDataElement.sampleValue === undefined) {
      return false;
    }

    if (currentDataElement.dataType === "Alphanumeric" || currentDataElement.dataType === "Image") {
      if (Array.isArray(currentDataElement.sampleValue)) {
        if (
          currentDataElement.sampleValue.length === 1 &&
          currentDataElement.sampleValue[0] === ""
        ) {
          return false;
        }
        return !!currentDataElement.sampleValue.length;
      }
      return !!currentDataElement.sampleValue;
    }

    return true;
  }, [currentDataElement.dataType, currentDataElement.sampleValue]);

  const [openTabsArray, setOpenTabsArray] = useState<Array<"general" | "input" | "output">>([
    "general",
    ...(!hasSampleValue ? ["input" as const] : []),
  ]);

  const setOpenTab = useCallback(
    (tab: "general" | "input" | "output", value: boolean) => {
      if (value) {
        if (!openTabsArray.includes(tab)) {
          setOpenTabsArray([...openTabsArray, tab]);
        }
      } else {
        setOpenTabsArray(openTabsArray.filter((item) => item !== tab));
      }
    },
    [openTabsArray]
  );

  const openTabs = useMemo(() => {
    const result = new Set<"general" | "input" | "output">(openTabsArray);

    return result;
  }, [openTabsArray]);

  const dataElementLabel = useMemo(() => {
    const humanName =
      currentDataElement.humanName.length > 20
        ? currentDataElement.humanName.substring(0, 20)
        : currentDataElement.humanName;
    return `${humanName} - ${resources.properties}`;
  }, [currentDataElement.humanName, resources.properties]);

  const summaryExample = [
    resources.dataDictionary.dataElementFundamentalProperties,
    resources.dataDictionary.chooseInputType,
    resources.dataDictionary.manageHowDataIsShown,
  ];

  return (
    <Box display="flex" direction="column" flex="shrink" maxWidth={"100%"}>
      <Box
        display="flex"
        flex="grow"
        marginTop={!showAddDataElementModule ? undefined : 6}
        marginStart={12}
      >
        <Text weight="bold" size="400" title={currentDataElement.humanName}>
          {dataElementLabel}
        </Text>
      </Box>

      <Box marginTop={2} marginStart={6} marginEnd={6} padding={5}>
        {/* Image Data Type doesn't have an output tab */}
        <DataDictionaryTabs
          openTabs={openTabs}
          setOpenTab={setOpenTab}
          tabSummary={summaryExample}
          tabs={
            currentDataElement &&
            currentDataElement.dataType !== "Image" &&
            !showAddDataElementModule
              ? [
                  {
                    title: resources.general,
                    content: (
                      <GeneralTab
                        element={currentDataElement}
                        onChange={handlePropertyChange}
                        isUpdating={isUpdating}
                        dictionaryLists={dictionaryLists}
                        showAddDataElementModule={
                          showAddDataElementModule ? showAddDataElementModule : undefined
                        }
                        classChildCount={classChildCount}
                        elementIsClassDataElement={elementIsClassDataElement}
                        setElementIsClassDataElement={setElementIsClassDataElement}
                      />
                    ),
                  },
                  {
                    title: resources.input,
                    content: (
                      <InputTab
                        element={currentDataElement}
                        onChange={handlePropertyChange}
                        hasSampleValue={hasSampleValue}
                      />
                    ),
                  },
                  {
                    title: resources.output,
                    content: (
                      <OutputTab element={currentDataElement} onChange={handlePropertyChange} />
                    ),
                  },
                ]
              : [
                  {
                    title: resources.general,
                    content: (
                      <GeneralTab
                        element={currentDataElement}
                        onChange={handlePropertyChange}
                        isUpdating={isUpdating}
                        dictionaryLists={dictionaryLists}
                        showAddDataElementModule={
                          showAddDataElementModule ? showAddDataElementModule : undefined
                        }
                        classChildCount={classChildCount}
                        elementIsClassDataElement={elementIsClassDataElement}
                        setElementIsClassDataElement={setElementIsClassDataElement}
                      />
                    ),
                  },
                  {
                    title: resources.input,
                    content: (
                      <InputTab
                        element={currentDataElement}
                        onChange={handlePropertyChange}
                        hasSampleValue={hasSampleValue}
                      />
                    ),
                  },
                ]
          }
        />
        <Box display="flex" justifyContent="end" paddingY={4} gap={4}>
          <Box>
            <Button
              color={"transparent"}
              onClick={() => {
                onCancelClicked();
              }}
              text={resources.cancel}
            />
          </Box>
          <Box>
            <Tooltip text={validationMessage} doNotShowIf={() => !validationMessage}>
              <Button
                color={"blue"}
                onClick={onSaveClicked}
                disabled={isSaveButtonDisabled}
                text={isUpdating ? resources.saveChanges : resources.create}
              />
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
