import {
  Box,
  BoxWithRef,
  DynamicIconButton,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import type { DashboardConfigColumn, DashboardConfigRow } from "@prodoctivity/types";
import { Dispatch, FunctionComponent, SetStateAction, useEffect, useRef } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { InsertAfterIcon } from "../../svg/InsertAfterIcon";
import { InsertBeforeIcon } from "../../svg/InsertBeforeIcon";
import { DashboardConfigColumns } from "./DashboardConfigColumns";
import { DashboardElementImplementation } from "./hooks";

type Props = {
  row: DashboardConfigRow;
  index: number;
  newRowId: number | null;
  setNewRowId: Dispatch<SetStateAction<number | null>>;
  newColumnId: number | null;
  setNewColumnId: Dispatch<SetStateAction<number | null>>;
  convertDashboardColumnsForImplementation: (
    columns: DashboardConfigColumn[]
  ) => DashboardElementImplementation[][];
  handleAddRowItem: (insertRowOn: "top" | "bottom", index: number) => void;
  handleColumnAmount: (columnType: "singleColumn" | "doubleColumn", index: number) => void;
  handleDeleteRow: (index: number) => void;
  openListOfWidgets: {
    isListOfWidgetsOpen: boolean;
    rowIndex: number | null;
    colIndex: number | null;
    addToSidebar: boolean;
  };
  allowEditionMode: number | boolean;
  handleMoveColumnItemVertically: (
    move: "up" | "down",
    rowIndex: number,
    colIndex: number,
    itemIndex: number
  ) => void;
  mainContentFunctionsBundle: ((parametersObject?: any) => void)[];
  mainContentEllipsisOptions: string[];
  handleOpenListOfWidgets: ({
    rowIndex,
    colIndex,
    addToSidebar,
  }: {
    rowIndex: number | null;
    colIndex: number | null;
    addToSidebar: boolean;
  }) => void;
};

export const DashboardConfigRowItem: FunctionComponent<Props> = ({
  row,
  index,
  newRowId,
  setNewRowId,
  newColumnId,
  setNewColumnId,
  convertDashboardColumnsForImplementation,
  handleAddRowItem,
  handleColumnAmount,
  handleDeleteRow,
  openListOfWidgets,
  allowEditionMode,
  handleMoveColumnItemVertically,
  mainContentFunctionsBundle,
  mainContentEllipsisOptions,
  handleOpenListOfWidgets,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const flexDirection =
    allowEditionMode === 0 ? "column" : row.columnCount === 1 ? "column" : "row";
  const arrayOfRows = convertDashboardColumnsForImplementation(row.columns);
  const rowRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (index === newRowId && rowRef.current) {
      rowRef.current.scrollIntoView({ behavior: "smooth" });
      setNewRowId(null);
    }
  }, [index, newRowId, setNewRowId]);

  return (
    <BoxWithRef key={`Row#${index}`} ref={rowRef}>
      <Box display="flex" width={"100%"} direction="column" height={"fit-content"}>
        {!allowEditionMode ? null : (
          <Box
            width={"100%"}
            height={40}
            display="flex"
            justifyContent="between"
            paddingX={2}
            marginBottom={2}
          >
            <Box>
              <Box display="flex" justifyContent="center" gap={4}>
                <Box
                  display="flex"
                  paddingY={2}
                  paddingLeft={6}
                  rounding={2}
                  borderStyle="lg"
                  width={"100%"}
                  minWidth={220}
                  height={40}
                  borderColor={colors.primary}
                  color={colors.white}
                  borderRadius={6}
                  alignItems="center"
                >
                  <TapArea
                    onTap={() => {
                      handleAddRowItem("top", index);
                    }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="between">
                      <Box minWidth={"fit-content"}>
                        <Text size="300" weight="bold" color={colors.primary}>
                          {resources.dashboard.insertRowBefore}
                        </Text>
                      </Box>
                      <InsertBeforeIcon></InsertBeforeIcon>
                    </Box>
                  </TapArea>
                </Box>
                <Box
                  display="flex"
                  paddingY={2}
                  paddingLeft={6}
                  rounding={2}
                  borderStyle="lg"
                  width={"100%"}
                  minWidth={220}
                  height={40}
                  borderColor={colors.primary}
                  color={colors.white}
                  borderRadius={6}
                  alignItems="center"
                >
                  <TapArea
                    onTap={() => {
                      handleAddRowItem("bottom", index);
                    }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="between">
                      <Box minWidth={"fit-content"}>
                        <Text size="300" weight="bold" color={colors.primary}>
                          {resources.dashboard.insertRowAfter}
                        </Text>
                      </Box>
                      <InsertAfterIcon></InsertAfterIcon>
                    </Box>
                  </TapArea>
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Box width={"fit-content"} display="flex" alignItems="center" justifyContent="center">
                <DynamicIconButton
                  buttonName={"doubleColumn"}
                  handleFunction={() => {
                    handleColumnAmount("doubleColumn", index);
                  }}
                  statefulCondition={row.columnCount === 2}
                  selected={undefined}
                ></DynamicIconButton>
              </Box>
              <Box width={"fit-content"} display="flex" alignItems="center" justifyContent="center">
                <DynamicIconButton
                  buttonName={"singleColumn"}
                  handleFunction={() => {
                    handleColumnAmount("singleColumn", index);
                  }}
                  statefulCondition={row.columnCount === 1}
                  selected={undefined}
                ></DynamicIconButton>
              </Box>
              <Box width={"fit-content"} display="flex" alignItems="center" justifyContent="center">
                <DynamicIconButton
                  buttonName={"delete"}
                  handleFunction={() => {
                    handleDeleteRow(index);
                  }}
                  statefulCondition={undefined}
                  selected={undefined}
                ></DynamicIconButton>
              </Box>
            </Box>
          </Box>
        )}

        <Box
          width={"100%"}
          display="flex"
          direction={flexDirection}
          height={"fit-content"}
          gap={flexDirection === "column" ? 0 : 8}
        >
          {arrayOfRows.map((column, colIndex) => {
            return (
              <DashboardConfigColumns
                allowEditionMode={allowEditionMode}
                colIndex={colIndex}
                column={column}
                handleMoveColumnItemVertically={handleMoveColumnItemVertically}
                handleOpenListOfWidgets={handleOpenListOfWidgets}
                index={index}
                mainContentEllipsisOptions={mainContentEllipsisOptions}
                mainContentFunctionsBundle={mainContentFunctionsBundle}
                newColumnId={newColumnId}
                openListOfWidgets={openListOfWidgets}
                row={row}
                setNewColumnId={setNewColumnId}
                key={`Column#${colIndex}`}
              />
            );
          })}
        </Box>
      </Box>
    </BoxWithRef>
  );
};
