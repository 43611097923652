import {
  Box,
  BoxWithRef,
  Image,
  TapArea,
  Text,
  VerticalEllipseMenuSvg,
  useColors,
} from "@prodoctivity/design-system";
import { useCallback, useRef, useState } from "react";

import type { DocumentSummary } from "@prodoctivity/types";
import NoThumbnailImg from "../../assets/NoThumbnailImg.png";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { DocSvg } from "../../svg/DocSvg";
import { MenuSvg } from "../../svg/MenuSvg";
import { FancyDateTime } from "../Display/FancyDateTime";
import { DocumentBookmarkTags } from "../DocumentBookmarkTags/DocumentBookmarkTags";
import { OrganizationNavLink } from "../OrganizationLink";

import {
  CascadeOptionsPopover,
  CascadeOptionsPopoverItem,
} from "../CascadeOptionsPopover/CascadeOptionsPopover";
import { PublicProfile } from "../Profile/Public/PublicProfile";

type Props = {
  document: DocumentSummary;
  onChange: (id: string) => void;
};

export function DocumentCard({
  document: { documentTypeName: rawName, name: rawDescription, documentId, createdAt, updatedBy },
  onChange,
}: Props) {
  const { colors } = useColors();
  const organizationNavigate = useOrganizationNavigate();
  const { baseUrl, token } = useServices();
  const { resources } = useAppTranslation();
  const [displayDefaultImg, setDisplayDefaultImg] = useState<boolean>(false);
  const id = parseInt(documentId);

  const anchorRefItems = useRef<Array<HTMLDivElement | null>>([]);
  const CascadeOptionsPopoverList: CascadeOptionsPopoverItem[] = [
    {
      childIcon: <MenuSvg width={40} height={40} />,
      childLabel: resources.view,
      childHoverLabel: resources.view,
      treeItem: false,
      treeContent: undefined,
      key: "view",
    },
  ];
  const handleCascadeOptionsPopoverClick = useCallback(
    (key: string) => {
      switch (key) {
        case "view":
          organizationNavigate(organizationLinkTemplates.documentId(documentId, "data"));
          break;
        default:
          break;
      }
    },
    [documentId, organizationNavigate]
  );

  return (
    <Box display="flex" position="relative">
      <Box display="flex" direction="column" color={colors.white} width={"100%"}>
        <Box display="flex" alignItems="center" position="relative" borderRadius={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="start"
            position="relative"
            minWidth={120}
            height={120}
          >
            <TapArea>
              <Box
                position="absolute"
                paddingX={3}
                paddingY={3}
                right={true}
                width={120}
                height={120}
                overflow="hidden"
                onClickCapture={() => onChange(documentId)}
              >
                {!displayDefaultImg ? (
                  <img
                    key={documentId}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "top",
                    }}
                    src={`${baseUrl}/app${organizationLinkTemplates.documentThumbnail(
                      documentId,
                      token || ""
                    )}`}
                    onError={() => setDisplayDefaultImg(true)}
                    alt={rawDescription}
                  />
                ) : (
                  <Image
                    color="transparent"
                    alt="landingPage_S1_illustration"
                    naturalWidth={120}
                    naturalHeight={120}
                    src={NoThumbnailImg}
                    fit="contain"
                  />
                )}
              </Box>
            </TapArea>
          </Box>

          <Box
            display="flex"
            top={true}
            left={true}
            position="absolute"
            width={40}
            height={40}
            marginStart={3}
            marginTop={3}
          >
            <DocSvg />
          </Box>

          <Box width={"100%"} display="flex" direction="column" paddingX={3} paddingY={3}>
            <OrganizationNavLink to={organizationLinkTemplates.documentId(documentId, "data")}>
              <Box display="flex" direction="column" marginBottom={4}>
                <Text weight="bold" size="200" ellipsisLength={25}>
                  {rawName}
                </Text>
                <Text ellipsisLength={25} size="200">
                  {rawDescription}
                </Text>
              </Box>
            </OrganizationNavLink>
            <Box
              display="flex"
              direction="column"
              width={"100%"}
              justifyContent="start"
              alignItems="start"
            >
              <PublicProfile username={updatedBy} size="100" sizeImage={32} />
              <Box marginEnd={-4} marginTop={1}>
                <Text ellipsisLength={25} size="100" color={colors.neutral700}>
                  <FancyDateTime value={createdAt} />
                </Text>
              </Box>
            </Box>
          </Box>
          <Box marginEnd={5} />
          <BoxWithRef
            ref={(ref) => {
              anchorRefItems.current[id] = ref;
            }}
            position="absolute"
            display="flex"
            direction="column"
            right={true}
          >
            <Box display="flex" justifyContent="center">
              <DocumentBookmarkTags
                documentId={documentId}
                viewMode={{ type: "countListPopoverMenu" }}
              />
            </Box>

            <CascadeOptionsPopover
              icon={<VerticalEllipseMenuSvg />}
              label={undefined}
              hoverLabel={undefined}
              items={CascadeOptionsPopoverList}
              searchFilter={false}
              onClick={handleCascadeOptionsPopoverClick}
              childPopoverActionOnClose={undefined}
            />
          </BoxWithRef>
        </Box>
      </Box>
    </Box>
  );
}
