import type {
  DocumentCollectionDocumentView,
  EvaluateValueMapFilter,
  ParametersObject,
} from "@prodoctivity/shared/src/index-types";
import { useCallback, useMemo, useRef } from "react";

import { mapEvaluateValToContextVal } from "@prodoctivity/shared";
import { useDocumentTypeInfoWithLatestVersion } from "../../../components/Display/hooks";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../link-templates";

export const useDocumentTreeInstance = (
  document: DocumentCollectionDocumentView,
  documentCollectionResponseContext: ParametersObject,
  currentPath: string[],
  documentName: string,
  documentSelected: string,
  documentSelectedState: (value: string) => void
) => {
  const { data } = useDocumentTypeInfoWithLatestVersion(
    document.documentTypeId,
    !!document.documentTypeId
  );
  const documentAnchorRef = useRef<HTMLDivElement | null>(null);

  const documentTypeInfo = data ? data.documentType : undefined;

  const organizationNavigate = useOrganizationNavigate();

  const generateDocument = useCallback(
    (valueMap: Record<string, EvaluateValueMapFilter>) => {
      const context: ParametersObject = {};

      const getValueMapContext = () => {
        for (const key in valueMap) {
          if (valueMap.hasOwnProperty(key)) {
            const contextVal = mapEvaluateValToContextVal(valueMap[key].type, valueMap[key].value);

            if (contextVal !== undefined) {
              context[key] = contextVal;
            }
          }
        }

        return context;
      };

      const valueMapContext = getValueMapContext();

      if (documentTypeInfo?.templateVersionId) {
        organizationNavigate(
          organizationLinkTemplates.templateVersionGeneratePage(
            documentTypeInfo.templateVersionId.toString(),
            undefined
          ),
          {
            state: {
              generationContext: valueMapContext,
              parentDocumentVersionId: undefined,
            },
          }
        );
      }
    },
    [organizationNavigate, documentTypeInfo]
  );

  const indexDocument = useCallback(() => {
    if (document && document.documentTypeId) {
      organizationNavigate(organizationLinkTemplates.createDocument(), {
        state: {
          generationContext: documentCollectionResponseContext,
          documentTypeInfo: documentTypeInfo,
        },
      });
    }
  }, [document, organizationNavigate, documentCollectionResponseContext, documentTypeInfo]);

  const changeDocumentSelection = useCallback(() => {
    const result = currentPath.toString() + documentName;
    documentSelectedState(result);
  }, [currentPath, documentName, documentSelectedState]);

  const isCurrentPathEqualToDocumentSelected = useMemo(() => {
    return currentPath.toString() + documentName === documentSelected.toString();
  }, [currentPath, documentSelected, documentName]);
  return {
    documentTypeInfo,
    generateDocument,
    indexDocument,
    documentAnchorRef,
    changeDocumentSelection,
    isCurrentPathEqualToDocumentSelected,
    documentSelected,
  };
};
