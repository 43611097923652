import { Box, Skeleton, TapArea, Text, useColors } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useContext, useEffect } from "react";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useServices } from "../../hooks/useServices";
import { DashboardItemContext } from "../../pages/Dashboard/DashboardConfigColumnElement";
import { EmptyTasksSvg } from "../../svg/Tasks/EmptyTasksSvg";
import { OrganizationNavLink } from "../OrganizationLink";
import { TaskList } from "./TaskList";
import { useHomeTasks } from "./hooks";

export const HomeTasks: FunctionComponent = () => {
  const { colors } = useColors();
  const { workflowTaskList, isLoading, refetch } = useHomeTasks();
  const { resources } = useAppTranslation();

  const dashboardItemContext = useContext(DashboardItemContext);

  useEffect(() => {
    const eventEmitter = dashboardItemContext.eventEmitter;
    if (eventEmitter) {
      const handler = () => {
        refetch();
      };
      eventEmitter.on("refresh", handler);
      return () => {
        eventEmitter.off("refresh", handler);
      };
    }
  }, [dashboardItemContext, refetch]);

  return (
    <Box minWidth={"fit-content"} display="flex" flex="grow">
      <Box display="flex" direction="column" flex="grow">
        {isLoading ? (
          <>
            <Skeleton width="60%" height={20} />
            <Skeleton width="50%" height={20} />
            <Skeleton width="80%" height={25} />
            <Skeleton width="100%" height={35} />
            <br />
            <Skeleton width="60%" height={20} />
            <Skeleton width="50%" height={20} />
            <Skeleton width="80%" height={25} />
            <Skeleton width="100%" height={35} />
            <br />
            <Skeleton width="60%" height={20} />
            <Skeleton width="50%" height={20} />
            <Skeleton width="80%" height={25} />
            <Skeleton width="100%" height={35} />
          </>
        ) : workflowTaskList && workflowTaskList.length > 0 ? (
          <Box display="flex" direction="column">
            <TaskList tasks={workflowTaskList} />
            <Box alignSelf="center" marginTop={6}>
              <OrganizationNavLink to="/tasks">
                <Text size="200" color={colors.primary}>
                  {resources.activities.viewAllEntries} ({workflowTaskList.length})
                </Text>
              </OrganizationNavLink>
            </Box>
          </Box>
        ) : (
          <TaskEmptyState />
        )}
      </Box>
    </Box>
  );
};
const TaskEmptyState = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();

  const handleNavigate = useCallback(() => {
    organizationNavigate("/tasks");
  }, [organizationNavigate]);

  const { user } = useServices();
  const isAdmin = user && user.permissions && user.permissions.indexOf("organization-admin") >= 0;

  return (
    <Box marginTop={4} display="flex" alignItems="center" direction="column">
      <EmptyTasksSvg />
      <Box margin={5} />
      <Text weight="bold">{resources.homePage.taskIsEmpty}</Text>
      <Box height={5} />
      <Text color={colors.black900} align="center">
        {resources.homePage.noActiveTask}
      </Text>
      {isAdmin && (
        <>
          <Text color={colors.black900} align="center">
            {resources.homePage.butOthersMightHaveTasksToDo}
          </Text>
          <TapArea onTap={handleNavigate}>
            <Text color={colors.primary} align="center">
              {resources.homePage.viewTaskHistory}
            </Text>
          </TapArea>
        </>
      )}
    </Box>
  );
};
