import { Box, Text, useColors } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { NewGadgetIllustration } from "../../svg/NewGadgetIllustration";

const boxStyle = {
  __style: {
    cursor: "pointer",
    border: `dashed 2px #3366CC`,
  },
};

// type Props = {

// };

export const ColumnItemPlaceholder: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  return (
    <Box
      height={"100%"}
      minHeight={500}
      display="flex"
      color={colors.primaryHover0}
      width={"100%"}
      dangerouslySetInlineStyle={boxStyle}
      justifyContent="center"
      alignItems="center"
    >
      <Box display="flex" direction="column" justifyContent="center" alignItems="center" gap={4}>
        <NewGadgetIllustration />
        <Box display="flex" direction="column" justifyContent="center">
          <Text weight="bold" align="center">
            {resources.dashboard.addGadget}
          </Text>
          <Box display="flex" gap={1}>
            <Text weight="normal">Click here to </Text>
            <Text weight="normal" color={colors.primary}>
              {" "}
              {resources.dashboard.openWidgetList}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
