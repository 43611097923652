import { Box, ComboBox, Skeleton, Text } from "@prodoctivity/design-system";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { RoleManagementState, useUserTab } from "../hooks";

import type { HttpListAllOrganizationUsersResponse } from "@prodoctivity/types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { SelectedItemTag } from "../../Users/Actions/RolesTab";

type UsersTabProps = {
  formState: RoleManagementState;
  isLoading: boolean;
  users: HttpListAllOrganizationUsersResponse["payload"]["users"];
  setFormState: Dispatch<SetStateAction<RoleManagementState>>;
};

export const UsersTab: FunctionComponent<UsersTabProps> = ({
  formState,
  isLoading,
  users,
  setFormState,
}) => {
  const { resources } = useAppTranslation();
  const {
    filteredUsers,
    selectedUsers,
    onSelectUser,
    onRemoveUser,

    filter,
    setFilter,
  } = useUserTab(formState, users, setFormState);

  return (
    <Skeleton height={400} show={isLoading}>
      <Box display="flex" direction="column" paddingTop={4}>
        <Box display="flex" direction="column" flex="grow" width={"100%"} paddingX={4} gap={4}>
          <Box display="flex" direction="column" flex="shrink" gap={1}>
            <Box minWidth={170} display="flex">
              <Text>{resources.rolesManagement.availableUsers}</Text>
            </Box>
            <Box flex="grow">
              <ComboBox
                id={"availableUsers"}
                label=""
                disabled={isLoading}
                inputValue={filter || ""}
                placeholder={resources.rolesManagement.selectAUserToInclude}
                onClear={() => setFilter(undefined)}
                onChange={(e) => setFilter(e.value)}
                onSelect={(e) => onSelectUser(e.item.value)}
                options={filteredUsers}
              />
            </Box>
          </Box>
          <Box display="flex" direction="column" flex="shrink" gap={1}>
            <Box minWidth={170} width={150} display="flex">
              <Text>{resources.rolesManagement.roleMembers}</Text>
            </Box>
            <Box display="flex" flex="grow">
              <Box
                width={"100%"}
                height={"fit-content"}
                display="flex"
                padding={2}
                borderRadius={4}
                alignContent="start"
                gap={2}
                wrap={true}
              >
                {selectedUsers.map((user) => (
                  <SelectedItemTag
                    key={user.value}
                    label={user.label}
                    value={user.value}
                    onRemove={() => onRemoveUser(user.value)}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Form End */}
      </Box>
    </Skeleton>
  );
};
