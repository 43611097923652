import {
  Box,
  Button,
  Checkbox,
  Divider,
  Label,
  Skeleton,
  Switch,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import type {
  DocumentCollectionTask,
  TemplateContextStateHeader,
  WithStringId,
} from "@prodoctivity/types";
import { FunctionComponent, useState } from "react";
import { useCombinedTemplateInfo, useTemplateInfo } from "../../hooks";

import { shouldNever } from "@prodoctivity/shared";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../link-templates";
import { EmptyTasksSvg } from "../../svg/Tasks/EmptyTasksSvg";
import { RoundIndicator } from "../../svg/Tasks/RoundIndicator";
import { OrganizationUserLookup } from "../Display/OrganizationUserLookup";
import { Page } from "../Layout/Page";
import { Pagination } from "../Layout/Pagination";
import { PublicProfile } from "../Profile/Public/PublicProfile";
import { SmallRefreshButton } from "../SmallRefreshButton";
import { getIconByType } from "./TaskList";
import { useAllHomeTasks } from "./hooks";

export const AllHomeTasks: FunctionComponent = () => {
  const {
    tasks,
    rowsPerPage,
    currentPage,
    nextPage,
    previousPage,
    setPageLength,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    totalRowCount,
    pageLengthOptions,
    breadCrumbEntries,
    refetch,
    selectedTaskIds,
    toggleSelect,
    toggleAll,
    allTasksChecked,
    selectedUser,
    handleSelectUser,
    handleReassignTasks,
    adminMode,
    toggleAdminMode,
    displayAdminSwitch,
  } = useAllHomeTasks();
  const { resources } = useAppTranslation();
  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box paddingX={8} paddingY={8}>
        <Box marginStart={3} display="flex" alignItems="center">
          <Box display="flex" flex="grow">
            <Text size="400" weight="bold">
              {resources.allTasks}
            </Text>
          </Box>
          <Box display="flex" alignItems="center">
            {displayAdminSwitch && (
              <Box display="flex" marginEnd={4} alignItems="center">
                <Box display="flex" paddingY={3} marginEnd={4}>
                  <Label htmlFor="toggleAdminView">{resources.adminMode}</Label>
                </Box>
                <Box paddingY={3}>
                  <Switch
                    id="toggleAdminView"
                    onChange={({ value }) => {
                      toggleAdminMode(value);
                    }}
                    switched={adminMode}
                  />
                </Box>
              </Box>
            )}
            <SmallRefreshButton
              accessibilityLabel={resources.refresh}
              refetch={refetch}
              size={30}
            />
          </Box>
        </Box>

        <Box display="flex" direction="row" flex="grow">
          <Box display="flex" direction="row" flex="grow" />
          <Box display="flex" direction="row" flex="shrink">
            <Pagination<typeof rowsPerPage>
              id="task_list_pagination_top"
              rowsLabel={`${resources.tasks}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={tasks?.length || 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={pageLengthOptions}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          direction="column"
          flex="grow"
          rounding={1}
          width="100%"
          borderStyle="sm"
        >
          <HomeTaskItems
            tasks={tasks}
            selectedTaskIds={selectedTaskIds}
            onChecked={toggleSelect}
            allTasksChecked={allTasksChecked}
            toggleAll={toggleAll}
            selectedUser={selectedUser}
            handleSelectUser={handleSelectUser}
            handleReassignTasks={handleReassignTasks}
            isAdmin={!!displayAdminSwitch && adminMode}
          />
        </Box>
        <Box display="flex" direction="row" flex="grow">
          <Box display="flex" direction="row" flex="grow" />
          <Box display="flex" direction="row" flex="shrink">
            <Pagination<typeof rowsPerPage>
              id="task_list_pagination_bottom"
              rowsLabel={`${resources.tasks}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={tasks?.length || 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={pageLengthOptions}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

const HomeTaskItems: FunctionComponent<{
  tasks: WithStringId<TemplateContextStateHeader<number> | DocumentCollectionTask<number>>[];
  selectedTaskIds: string[];
  onChecked: (id: string, checked: boolean) => void;
  allTasksChecked: boolean;
  toggleAll: (ids: string[], checked: boolean) => void;
  handleSelectUser: (userId?: string) => void;
  selectedUser?: string;
  handleReassignTasks: () => void;
  isAdmin: boolean;
}> = ({
  tasks,
  selectedTaskIds,
  onChecked,
  toggleAll,
  allTasksChecked,
  selectedUser,
  handleSelectUser,
  handleReassignTasks,
  isAdmin,
}) => {
  const { colors } = useColors();
  const [activeTaskId, setActiveTaskId] = useState("");
  const { resources } = useAppTranslation();
  if (!tasks || tasks.length === 0) {
    return (
      <Box padding={5} borderStyle="sm" display="flex" alignItems="center" direction="column">
        <EmptyTasksSvg width={350} height={252} />
        <Box paddingY={3}>
          <Text size="200" color={colors.neutral900} align="center">
            <Text size="200" weight="bold" color={colors.neutral900} align="center">
              {resources.homePage.taskIsEmpty}
            </Text>
            {resources.homePage.noActiveTask}
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {isAdmin ? (
        <Box padding={4} display="flex" alignItems="center">
          <Box marginStart={5}>
            <Checkbox
              size="sm"
              id="check-all-tasks"
              checked={allTasksChecked}
              onChange={({ checked }) => {
                toggleAll(
                  tasks.map((t) => t.id),
                  checked
                );
              }}
            />
          </Box>
          <Box marginStart={4} paddingX={2} flex="shrink">
            <Text size="200">{resources.assignTo}:</Text>
          </Box>
          <Box width={300} paddingX={1}>
            <OrganizationUserLookup
              id="assign_tasks_user_lookup"
              label={""}
              accessibilityClearButtonLabel={resources.clear}
              noResultText={resources.noUsers}
              placeholder={`${resources.user}...`}
              size="md"
              onSelect={(element) => {
                handleSelectUser(element.item.value);
              }}
              onClear={() => {
                handleSelectUser(undefined);
              }}
              selectedUsername={selectedUser}
            />
          </Box>
          <Box marginStart={8}>
            <Button
              color={"gray"}
              onClick={() => {
                handleReassignTasks();
                toggleAll(selectedTaskIds, false);
              }}
              disabled={!selectedUser}
              text={resources.assign}
            />
          </Box>
          {selectedTaskIds.length > 0 ? (
            <Box marginStart={4} paddingX={2} flex="shrink">
              <Text size="200" color={colors.primary}>
                ({selectedTaskIds.length}) {resources.selectedTasks}
              </Text>
            </Box>
          ) : null}
        </Box>
      ) : null}
      {tasks.map((t, i) => {
        switch (t.taskType) {
          case "template-context-state":
            return t.contextType === "combined-template" ? (
              <TaskCombinedItem
                key={t.id}
                task={t}
                isActive={t.id === activeTaskId}
                setActive={(id: string) => setActiveTaskId(id)}
                checked={selectedTaskIds.includes(t.id)}
                onChecked={onChecked}
                isAdmin={isAdmin}
                showDivider={i > 0 ? true : isAdmin}
              />
            ) : (
              <TaskSingleItem
                key={t.id}
                task={t}
                isActive={t.id === activeTaskId}
                setActive={(id: string) => setActiveTaskId(id)}
                checked={selectedTaskIds.includes(t.id)}
                onChecked={onChecked}
                isAdmin={isAdmin}
                showDivider={i > 0 ? true : isAdmin}
              />
            );
          case "document-collection-task":
            return (
              <TaskItem
                key={t.id}
                taskId={t.id}
                taskTitle={t.instanceName}
                taskSubTitle={t.configName}
                expirationDate={t.expirationDate}
                username={t.username}
                iconType="document-qa"
                isActive={t.id === activeTaskId}
                setActive={(id: string) => setActiveTaskId(id)}
                checked={selectedTaskIds.includes(t.id)}
                onChecked={onChecked}
                isAdmin={isAdmin}
                showDivider={i > 0 ? true : isAdmin}
              />
            );
          default:
            shouldNever(t);
            throw new Error("never");
        }
      })}
    </>
  );
};

const TaskSingleItem: FunctionComponent<{
  task: WithStringId<TemplateContextStateHeader<number>>;
  isActive: boolean;
  setActive: (id: string) => void;
  checked: boolean;
  onChecked: (id: string, checked: boolean) => void;
  isAdmin: boolean;
  showDivider: boolean;
}> = ({ task, isActive, setActive, checked, onChecked, isAdmin, showDivider }) => {
  const { resources } = useAppTranslation();
  const { data: template, isLoading } = useTemplateInfo(
    task.contextType === "template" ? task.templateVersionId : ""
  );
  return isLoading ? (
    <Skeleton show>{resources.loading}</Skeleton>
  ) : (
    <TaskItem
      taskId={task.id}
      taskTitle={template?.info?.informationDefinition.name || ""}
      expirationDate={task.expirationDate}
      username={task.username}
      iconType="document-creation"
      isActive={isActive}
      setActive={setActive}
      checked={checked}
      onChecked={onChecked}
      isAdmin={isAdmin}
      showDivider={showDivider}
    />
  );
};

const TaskCombinedItem: FunctionComponent<{
  task: WithStringId<TemplateContextStateHeader<number>>;
  isActive: boolean;
  setActive: (id: string) => void;
  checked: boolean;
  onChecked: (id: string, checked: boolean) => void;
  isAdmin: boolean;
  showDivider: boolean;
}> = ({ task, isActive, setActive, checked, onChecked, isAdmin, showDivider }) => {
  const { resources } = useAppTranslation();
  const { data: combinedTemplateResponse, isLoading } = useCombinedTemplateInfo(
    task.contextType === "combined-template" ? task.combinedTemplateId : ""
  );

  return isLoading ? (
    <Skeleton show>{resources.loading}</Skeleton>
  ) : (
    <TaskItem
      taskId={task.id}
      taskTitle={combinedTemplateResponse?.combinedTemplate?.name || ""}
      expirationDate={task.expirationDate}
      username={task.username}
      iconType="document-qa"
      isActive={isActive}
      setActive={setActive}
      checked={checked}
      onChecked={onChecked}
      isAdmin={isAdmin}
      showDivider={showDivider}
    />
  );
};

const TaskItem: FunctionComponent<{
  taskId: string;
  taskTitle: string;
  taskSubTitle?: string;
  username?: string;
  expirationDate: number;
  iconType: string;
  isActive: boolean;
  setActive: (id: string) => void;
  checked: boolean;
  onChecked: (id: string, checked: boolean) => void;
  isAdmin: boolean;
  showDivider: boolean;
}> = ({
  taskId,
  taskTitle,
  taskSubTitle,
  expirationDate,
  username,
  iconType,
  isActive,
  setActive,
  checked,
  onChecked,
  isAdmin,
  showDivider,
}) => {
  const { colors } = useColors();
  const organizationNavigate = useOrganizationNavigate();
  const { resources, moment } = useAppTranslation();

  return (
    <TapArea
      key={taskId}
      onMouseEnter={() => {
        setActive(taskId);
      }}
      onMouseLeave={() => setActive("")}
      role="button"
    >
      {showDivider ? <Divider /> : null}
      <Box display="flex" position="relative">
        <Box
          color={isActive ? colors.primary100 : colors.white}
          display="flex"
          direction="row"
          flex="grow"
          alignItems="center"
          paddingX={2}
          paddingY={2}
          gap={6}
        >
          <Box display="flex" flex="shrink">
            <Checkbox
              size="sm"
              id={taskId}
              checked={checked}
              onChange={({ checked }) => {
                onChecked(taskId, checked);
              }}
            />
          </Box>
          <Box display="flex" flex="shrink">
            <Box>{getIconByType(iconType)}</Box>
          </Box>
          <Box display="flex" flex="grow" gap={1} justifyContent={"start"}>
            <Box display="flex" direction="column" width={"100%"} gap={1}>
              <Text size="200" weight="normal">
                {taskTitle}
              </Text>
              {taskSubTitle && (
                <Text size="100" weight="normal">
                  {taskSubTitle}
                </Text>
              )}
              {username && (
                <Text size="100" weight="normal" color={colors.subtle}>
                  {resources.assignedTo}:{" "}
                  <Box display="inlineBlock">
                    <PublicProfile size="100" username={username} />
                  </Box>
                </Text>
              )}
              <Box display="flex" alignItems="center" gap={1}>
                {expirationDate > Date.now() ? (
                  <RoundIndicator color="green" />
                ) : (
                  <RoundIndicator color="red" />
                )}
                <Text size="100" weight="normal" color={colors.subtle}>
                  {expirationDate > Date.now() ? resources.expires : resources.expired}{" "}
                  {moment(expirationDate).fromNow()}
                </Text>
              </Box>
            </Box>
          </Box>
          {!isAdmin ? (
            <Box display="flex">
              <Box>
                <Button
                  onClick={() => {
                    organizationNavigate(organizationLinkTemplates.taskDetail(taskId));
                  }}
                  color="gray"
                  text={resources.open}
                  iconEnd="directional-arrow-right"
                />
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </TapArea>
  );
};
