import { TextArea as GestaltTextArea, TextAreaProps } from "gestalt";
import { Ref, forwardRef } from "react";

export type Props = TextAreaProps & {
  maxLength?: { characterCount: number; errorAccessibilityLabel: string };
};

export const TextArea = forwardRef((props: Props, ref?: Ref<HTMLTextAreaElement>) => {
  return <GestaltTextArea ref={ref} {...props} />;
});
