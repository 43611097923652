import type {
  DateTime,
  FieldFilterDataType,
  HttpGetDeletedDocumentListRequest,
  HttpGetDocumentsRequest,
  HttpGetDocumentsWithListsRequest,
  HttpSearchRequest,
  ListableDocumentType,
  TemplateId,
  TemplateVersionId,
} from "@prodoctivity/types";

import type { DocumentViewerPanelKeys } from "@prodoctivity/design-system";
import { DocumentCollectionInstanceListFilter } from "./components/DocumentCollection/hooks";

export const organizationLinkTemplates = {
  signup() {
    return `/sign-up`;
  },
  logout() {
    return "/logout";
  },
  home() {
    return `/`;
  },
  account() {
    return `/account`;
  },
  profile() {
    return `/account/profile`;
  },
  userSubscriptions() {
    return `/account/subscriptions`;
  },
  upgradePlan() {
    return `/account/upgrade-plan`;
  },
  paymentDetails(amountOfSubscriptions: string, subscriptionPaymentMethods: string) {
    return `/account/payment?amount=${amountOfSubscriptions}&methodsofpayment=${subscriptionPaymentMethods}`;
  },
  activities(templateVersionId: TemplateVersionId) {
    return `/activities/${encodeURIComponent(templateVersionId)}`;
  },
  activityList() {
    return `/activities`;
  },
  browseDeletedDocuments(filter: BrowseDeletedDocumentsFilter) {
    return `/document-recovery?${buildDeletedDocumentsFilterQueryString(filter)}`;
  },
  browseDocuments(filter: BrowseDocumentsFilter) {
    return `/documents?${buildDocumentsFilterQueryString(filter)}`;
  },
  browseDocumentsWithLabels(filter: BrowseDocumentLabelsFilter) {
    return `/documents-labels?${buildBrowseDocumentLabelsFilterQueryString(filter)}`;
  },
  createDocument() {
    return `/documents/new`;
  },
  generateDocument() {
    return `/templates?action=generate`;
  },
  scanNewDocument() {
    return `/documents/scan`;
  },
  dataDictionary() {
    return `/settings/data-dictionary/data-elements`;
  },
  dataElementDetailsByName(dataElementName: string) {
    return `/settings/data-dictionary/data-elements/${encodeURIComponent(dataElementName)}`;
  },
  dataElements() {
    return `/settings/data-dictionary/data-elements`;
  },
  dataLists() {
    return `/settings/data-dictionary/data-lists`;
  },
  fluency() {
    return `/settings/fluency`;
  },
  deferredDistributionList() {
    return `/settings/deferred-distributions`;
  },
  graphicMarkList() {
    return `/settings/graphic-mark`;
  },
  newGraphicMark() {
    return `/settings/graphic-mark/new`;
  },
  editGraphicMark(graphicMarkId: string) {
    return `/settings/graphic-mark/${graphicMarkId}`;
  },
  documentGenerationStatusList() {
    return `/generations`;
  },
  documentGenerationStatus(token: string) {
    return `/generations/${token}`;
  },
  documentId(documentId: string, filter: DocumentViewerPanelKeys | undefined) {
    if (filter) {
      return `/documents/${documentId}?${buildDocumentViewerPageFilterQueryString(filter)}`;
    } else {
      return `/documents/${documentId}`;
    }
  },
  documentIdAndVersion(
    documentId: string,
    documentVersionId: string,
    filter: DocumentViewerPanelKeys | undefined
  ) {
    if (filter) {
      return `/documents/${documentId}/versions/${documentVersionId}?${buildDocumentViewerPageFilterQueryString(
        filter
      )}`;
    } else {
      return `/documents/${documentId}/versions/${documentVersionId}`;
    }
  },
  documentIdAndVersionAndPanel(documentId: string, documentVersionId: string, properties?: string) {
    if (!properties) {
      return `/documents/${documentId}/versions/${documentVersionId}`;
    }
    return `/documents/${documentId}/versions/${documentVersionId}?view=${properties}`;
  },
  documentThumbnail(documentId: string, token: string) {
    return `/documents/${documentId}/thumbnail?token=${encodeURIComponent(token)}`;
  },
  identities() {
    return `/settings/data-dictionary/identities`;
  },
  loginAndReturnTo(to: string) {
    return `/login?returnTo=${encodeURIComponent(to)}`;
  },
  manageDictionaryLists() {
    return `/settings/data-dictionary/data-lists`;
  },
  manageDistributions() {
    return `/settings/distributions`;
  },
  manageDocumentGroups() {
    return `/settings/document-groups`;
  },
  manageDocumentTypes() {
    return `/settings/document-types`;
  },
  manageDocumentCollections() {
    return `/settings/document-collection-config`;
  },
  newDocumentCollection() {
    return `/settings/document-collection-config/new`;
  },
  editDocumentCollection(documentCollectionId: string) {
    return `/settings/document-collection-config/${documentCollectionId}`;
  },
  editDocumentGroups(documentGroupsId: string) {
    return `/settings/document-groups/${documentGroupsId}`;
  },
  manageDocumentTypeCopy(documentTypeId: ListableDocumentType["documentTypeId"]) {
    return `/settings/document-types/new/${encodeURIComponent(documentTypeId)}`;
  },
  manageDocumentTypeEdit(documentTypeId: ListableDocumentType["documentTypeId"]) {
    return `/settings/document-types/${encodeURIComponent(documentTypeId)}`;
  },
  manageDocumentTypeNew() {
    return `/settings/document-types/new`;
  },
  manageFragments() {
    return `/settings/fluency/fragments`;
  },
  manageUsers() {
    return `/settings/users`;
  },
  addUser() {
    return `/settings/users/new`;
  },
  manageRoles() {
    return `/settings/roles`;
  },
  newRole() {
    return `/settings/roles/new`;
  },
  editRole(roleId: string, tab: string | undefined) {
    return `/settings/roles/${roleId}${tab ? `?tab=${tab}` : ""}`;
  },
  editUser(userId: string, tab: string | undefined) {
    return `/settings/users/${encodeURIComponent(userId)}/profile${tab ? `?tab=${tab}` : ""}`;
  },

  settingsTemplates() {
    return `/settings/fluency/templates`;
  },
  manageCombinedTemplates() {
    return `/settings/fluency/combined-templates`;
  },
  newCombinedTemplates() {
    return `/settings/fluency/combined-templates/new`;
  },
  editCombinedTemplates(combinedTemplateId: string) {
    return `/settings/fluency/combined-templates/${combinedTemplateId}`;
  },
  combinedTemplateDetails(combinedTemplateId: string) {
    return `/combined-templates/${combinedTemplateId}/details`;
  },
  generateCombinedTemplate(combinedTemplateId: string, stateId: string | undefined) {
    return `/combined-templates/${combinedTemplateId}/generate${
      stateId ? `?stateId=${encodeURIComponent(stateId)}` : ""
    }`;
  },
  notificationsPage() {
    return `/notifications`;
  },
  search(filter: SearchFilter) {
    return `/search?${buildSearchQueryString(filter)}`;
  },
  settings() {
    return `/settings`;
  },
  settingsBundle() {
    return `/settings/bundles`;
  },
  bundleConfigView(idenfifier: string) {
    return `/settings/bundles/${idenfifier}`;
  },
  taskDetail(taskId: string) {
    return `/tasks/${encodeURIComponent(taskId)}`;
  },
  tasks() {
    return `/tasks`;
  },
  templateGeneratePage(templateId: TemplateId) {
    // HACK: Add this route
    return `/templates/${encodeURIComponent(templateId)}/generate`;
  },
  templateList(generate: boolean, nameFilter?: string) {
    return `/templates${generate ? "?action=generate" : ""}${
      nameFilter ? `${generate ? "" : "?"}filter=${encodeURIComponent(nameFilter)}` : ""
    }`;
  },
  templateVersionDetailPage(templateVersionId: TemplateVersionId) {
    return `/templates/versions/${encodeURIComponent(templateVersionId)}/details`;
  },
  templateVersionEditPage(templateVersionId: TemplateVersionId) {
    return `/templates/versions/${encodeURIComponent(templateVersionId)}/edit`;
  },
  templateVersionGeneratePage(templateVersionId: TemplateVersionId, stateId: string | undefined) {
    return `/templates/versions/${encodeURIComponent(templateVersionId)}/generate${
      stateId ? `?stateId=${encodeURIComponent(stateId)}` : ""
    }`;
  },
  viewDocuments() {
    return `/documents`;
  },
  viewMyLists() {
    return `/documents-labels`;
  },
  documentCollectionList(paramsFilter: DocumentCollectionInstanceListFilter) {
    return `/document-collections?${buildDocumentCollectionsFilterQueryString(paramsFilter)}`;
  },
  documentCollection(documentCollectionId: string, documentId?: string) {
    if (!documentId) {
      return `/document-collections/${documentCollectionId}`;
    }
    return `/document-collections/${documentCollectionId}?documentId=${documentId}`;
  },
  terms() {
    return `/terms`;
  },
  privacy() {
    return `/privacy`;
  },
  security() {
    return `/security`;
  },
  sendDocumentAttachmentPage(documentId: string, documentVersionId: string) {
    return `/documents/${documentId}/${documentVersionId}/email`;
  },
  contact() {
    return `/contact`;
  },
  documentation() {
    return `/docs/api`;
  },
  distributions() {
    return `/settings/distributions`;
  },
  login() {
    return `/login`;
  },
  leaveOrganization() {
    return `/leave-organization`;
  },
  loginInWithSoo() {
    return `/login-sso`;
  },
  documentRecovery() {
    return `/document-recovery`;
  },
};

export type BrowseDocumentsFilter = {
  documentTypeIdList: string[] | undefined;
  updatedBy: string | undefined;
  dateFrom: DateTime | undefined;
  dateTo: DateTime | undefined;
  sortField: "documentType" | "name" | "updatedBy" | "updatedAt" | undefined;
  sortDirection: "ASC" | "DESC" | undefined;
  pageNumber: number;
  rowsPerPage: HttpGetDocumentsRequest["queryParameters"]["rowsPerPage"];
};

export type BrowseDocumentLabelsFilter = {
  documentTypeIdList: string[] | undefined;
  documentLists: string[] | undefined;
  includeFavorites: boolean | undefined;
  dateStart: DateTime | undefined;
  dateEnd: DateTime | undefined;
  sortField: "documentType" | "name" | "updatedAt" | undefined;
  sortDirection: "ASC" | "DESC" | undefined;
  pageNumber: number;
  rowsPerPage: HttpGetDocumentsWithListsRequest["queryParameters"]["rowsPerPage"];
};

export type BrowseDeletedDocumentsFilter = {
  pageNumber: number;
  rowsPerPage: HttpGetDeletedDocumentListRequest["queryParameters"]["rowsPerPage"];
  sortField: "documentType" | "name" | "deletedBy" | "deletedAt" | undefined;
  sortDirection: "ASC" | "DESC" | undefined;
  documentTypeIdList: string[] | undefined;
  updatedBy: string | undefined;
  dateStart: DateTime | undefined;
  dateEnd: DateTime | undefined;
};

export type DocumentSearchFilter = {
  documentTypesSelected: string[];
  fields: {
    fld: string;
    op: string;
    val: string;
    dataType: string;
  }[];
  query: string;
  searchCriteria: string;
  docTypesSelected: string;
  pageNumber: string;
  rowsPerPage: string;
};

export function advancedFieldsForFilter(params: URLSearchParams) {
  const paramFields = [];
  const documentTypesSelected = [];
  const amountOfFields = params.get("searchCriteria");
  const amountOfDocTypes = params.get("docTypesSelected");
  const pageNumber = params.get("pageNumber");
  const rowsPerPage = params.get("rowsPerPage");
  const query = params.get("q");
  const totalFields = amountOfFields !== null ? parseInt(amountOfFields) : 0;
  const totalDocTypes = amountOfDocTypes !== null ? parseInt(amountOfDocTypes) : 0;

  for (let i = 0; i < totalFields; i++) {
    const dynamicFldString = `fields[${i}][fld]`;
    const dynamicOperatorString = `fields[${i}][op]`;
    const dynamicValueString = `fields[${i}][val]`;
    const dynamicDataTypeString = `fields[${i}][dataType]`;

    const fld = params.get(dynamicFldString);
    const op = params.get(dynamicOperatorString);
    let value = params.get(dynamicValueString);
    const dataType = params.get(dynamicDataTypeString);

    if (dataType === "Alphanumeric" && (op === "starts-with" || op === "ends-with")) {
      value = value !== null ? value.replace(/\*/g, "") : "";
    }

    const paramField = {
      fld: fld !== null ? fld : "",
      op: op !== null ? op : "",
      val: value !== null ? value : "",
      dataType: dataType !== null ? dataType : "",
    };

    if (paramField !== null) {
      paramFields.push(paramField);
    }
  }

  for (let i = 0; i < totalDocTypes; i++) {
    const dynamicDocTypeString = `documentTypeIdList[${i}]`;
    const paramDocTypes = params.get(dynamicDocTypeString);

    if (paramDocTypes !== null) {
      documentTypesSelected.push(paramDocTypes);
    }
  }
  return {
    documentTypesSelected: documentTypesSelected,
    fields: paramFields,
    query: query !== null ? query : "",
    searchCriteria: amountOfFields !== null ? amountOfFields : "0",
    docTypesSelected: amountOfDocTypes !== null ? amountOfDocTypes : "0",
    pageNumber: pageNumber !== null ? pageNumber : "0",
    rowsPerPage: rowsPerPage !== null ? rowsPerPage : "15",
  };
}

function buildDocumentsFilterQueryString(filter: BrowseDocumentsFilter) {
  let result = "";
  if (filter.dateFrom) {
    result += `${result.length ? "&" : ""}dateStart=${encodeURIComponent(
      new Date(filter.dateFrom).toISOString()
    )}`;
  }
  if (filter.dateTo) {
    result += `${result.length ? "&" : ""}dateEnd=${encodeURIComponent(
      new Date(filter.dateTo).toISOString()
    )}`;
  }
  if (filter.documentTypeIdList && filter.documentTypeIdList.length) {
    result += `${result.length ? "&" : ""}documentTypeIdList=${filter.documentTypeIdList
      .map(encodeURIComponent)
      .join(",")}`;
  }
  if (filter.pageNumber !== undefined) {
    result += `${result.length ? "&" : ""}pageNumber=${encodeURIComponent(filter.pageNumber)}`;
  }
  if (filter.rowsPerPage) {
    result += `${result.length ? "&" : ""}rowsPerPage=${encodeURIComponent(filter.rowsPerPage)}`;
  }
  if (filter.updatedBy) {
    result += `${result.length ? "&" : ""}updatedBy=${encodeURIComponent(filter.updatedBy)}`;
  }
  if (filter.sortField !== undefined) {
    result += `${result.length ? "&" : ""}sortField=${encodeURIComponent(filter.sortField)}`;
    if (filter.sortDirection) {
      result += `${result.length ? "&" : ""}sortDirection=${encodeURIComponent(
        filter.sortDirection
      )}`;
    }
  }

  return result;
}

function buildDocumentViewerPageFilterQueryString(filter: DocumentViewerPanelKeys) {
  let result = "";
  if (filter) {
    result += `${result.length ? "&" : ""}defaultPanel=${encodeURIComponent(filter)}`;
  }

  return result;
}

function buildBrowseDocumentLabelsFilterQueryString(filter: BrowseDocumentLabelsFilter) {
  let result = "";
  if (filter.dateStart) {
    result += `${result.length ? "&" : ""}dateStart=${encodeURIComponent(
      new Date(filter.dateStart).toISOString()
    )}`;
  }
  if (filter.dateEnd) {
    result += `${result.length ? "&" : ""}dateEnd=${encodeURIComponent(
      new Date(filter.dateEnd).toISOString()
    )}`;
  }
  if (filter.documentTypeIdList && filter.documentTypeIdList.length) {
    result += `${result.length ? "&" : ""}documentTypeIdList=${filter.documentTypeIdList
      .map(encodeURIComponent)
      .join(",")}`;
  }
  if (filter.pageNumber !== undefined) {
    result += `${result.length ? "&" : ""}pageNumber=${encodeURIComponent(filter.pageNumber)}`;
  }
  if (filter.rowsPerPage) {
    result += `${result.length ? "&" : ""}rowsPerPage=${encodeURIComponent(filter.rowsPerPage)}`;
  }
  if (filter.includeFavorites) {
    result += `${result.length ? "&" : ""}includeFavorites=true`;
  }
  if (filter.documentLists && filter.documentLists.length) {
    result += `${result.length ? "&" : ""}documentLists=${filter.documentLists
      .map(encodeURIComponent)
      .join(",")}`;
  }
  if (filter.sortField !== undefined) {
    result += `${result.length ? "&" : ""}sortField=${encodeURIComponent(filter.sortField)}`;
    if (filter.sortDirection) {
      result += `${result.length ? "&" : ""}sortDirection=${encodeURIComponent(
        filter.sortDirection
      )}`;
    }
  }

  return result;
}

function buildDeletedDocumentsFilterQueryString(filter: BrowseDeletedDocumentsFilter) {
  let result = "";
  if (filter.dateStart) {
    result += `${result.length ? "&" : ""}dateStart=${encodeURIComponent(
      new Date(filter.dateStart).toISOString()
    )}`;
  }
  if (filter.dateEnd) {
    result += `${result.length ? "&" : ""}dateEnd=${encodeURIComponent(
      new Date(filter.dateEnd).toISOString()
    )}`;
  }
  if (filter.documentTypeIdList && filter.documentTypeIdList.length) {
    result += `${result.length ? "&" : ""}documentTypeIdList=${filter.documentTypeIdList
      .map(encodeURIComponent)
      .join(",")}`;
  }
  if (filter.pageNumber !== undefined) {
    result += `${result.length ? "&" : ""}pageNumber=${encodeURIComponent(filter.pageNumber)}`;
  }
  if (filter.rowsPerPage) {
    result += `${result.length ? "&" : ""}rowsPerPage=${encodeURIComponent(filter.rowsPerPage)}`;
  }
  if (filter.updatedBy) {
    result += `${result.length ? "&" : ""}updatedBy=${encodeURIComponent(filter.updatedBy)}`;
  }
  if (filter.sortField !== undefined) {
    result += `${result.length ? "&" : ""}sortField=${encodeURIComponent(filter.sortField)}`;
    if (filter.sortDirection) {
      result += `${result.length ? "&" : ""}sortDirection=${encodeURIComponent(
        filter.sortDirection
      )}`;
    }
  }

  return result;
}

function buildDocumentCollectionsFilterQueryString(
  paramsFilter: DocumentCollectionInstanceListFilter
) {
  let result = "";
  if (paramsFilter.filter) {
    result += `${result.length ? "&" : ""}filter=${encodeURIComponent(paramsFilter.filter)}`;
  }
  if (paramsFilter.assignedTo) {
    result += `${result.length ? "&" : ""}assignedTo=${encodeURIComponent(
      paramsFilter.assignedTo
    )}`;
  }
  if (paramsFilter.pageNumber) {
    result += `${result.length ? "&" : ""}pageNumber=${encodeURIComponent(
      paramsFilter.pageNumber
    )}`;
  }
  if (paramsFilter.rowsPerPage) {
    result += `${result.length ? "&" : ""}rowsPerPage=${encodeURIComponent(
      paramsFilter.rowsPerPage
    )}`;
  }

  return result;
}

export type SearchFieldOperator =
  | "eq"
  | "lt"
  | "lte"
  | "gt"
  | "gte"
  | "neq"
  | "contains"
  | "ends-with"
  | "starts-with";

export type DocumentSearchCriteriaField = {
  fld: string;
  op: SearchFieldOperator | undefined;
  val: string | number | boolean;
  dataType: FieldFilterDataType;
};

export type SearchSortField =
  | "documentTypeId"
  | "name"
  | "updatedAt"
  | "documentTypeName"
  | "createdBy"
  | undefined;

export type SearchSortDirection = "asc" | "desc";

export type SearchFilter = {
  q: string;
  pageNumber: number;
  rowsPerPage: HttpSearchRequest["queryParameters"]["rowsPerPage"];
} & (
  | {
      filterType: "all" | "collections" | "tasks" | "annotations";
    }
  | {
      filterType: "document";
      documentTypeIdList: string[] | undefined;
      fields: DocumentSearchCriteriaField[];

      fromDate: string | undefined;
      toDate: string | undefined;
      sortField: SearchSortField;
      sortDirection: SearchSortDirection | undefined;
      searchCriteria: string;
      docTypesSelected: string;
      includeApproximateResults: boolean;
    }
);

function buildSearchQueryString(filter: SearchFilter) {
  let result = "";
  if (filter.q) {
    result += `${result.length ? "&" : ""}q=${encodeURIComponent(filter.q)}`;
  }

  if (filter.filterType && filter.filterType !== "all") {
    if (filter.filterType) {
      result += `${result.length ? "&" : ""}entityType=${encodeURIComponent(filter.filterType)}`;
    }
    if (filter.filterType === "document") {
      if (filter.documentTypeIdList && filter.documentTypeIdList.length) {
        filter.documentTypeIdList.forEach((docTypeId, index) => {
          result += `${result.length ? "&" : ""}documentTypeIdList[${index}]=${encodeURIComponent(
            docTypeId
          )}`;
        });
      }

      if (filter.docTypesSelected) {
        result += `${result.length ? "&" : ""}docTypesSelected=${encodeURIComponent(
          filter.docTypesSelected
        )}`;
      }

      if (filter.fields.length > 0) {
        result += filter.fields
          .map((item, index) => {
            if (item.val === undefined) return null;
            let value = item.val.toString();
            const dataType = item.dataType;
            const equalityOperator = item.op;
            if (
              dataType === "Alphanumeric" &&
              (equalityOperator === "starts-with" || equalityOperator === "ends-with")
            ) {
              value = value.replace(/\*/g, "");
            }

            return `${result.length ? "&" : ""}fields[${index}][fld]=${encodeURIComponent(
              item.fld
            )}&fields[${index}][val]=${value}&fields[${index}][op]=${encodeURIComponent(
              equalityOperator ? equalityOperator : "eq"
            )}&fields[${index}][dataType]=${dataType}`;
          })
          .join("");
      } else {
        result += "";
      }

      if (filter.searchCriteria) {
        result += `${result.length ? "&" : ""}searchCriteria=${encodeURIComponent(
          filter.searchCriteria
        )}`;
      }

      if (filter.fromDate) {
        result += `${result.length ? "&" : ""}fromDate=${encodeURIComponent(filter.fromDate)}`;
      }

      if (filter.toDate) {
        result += `${result.length ? "&" : ""}toDate=${encodeURIComponent(filter.toDate)}`;
      }
      if (filter.sortField !== undefined) {
        result += `${result.length ? "&" : ""}sortField=${encodeURIComponent(filter.sortField)}`;
        if (filter.sortDirection) {
          result += `${result.length ? "&" : ""}sortDirection=${encodeURIComponent(
            filter.sortDirection
          )}`;
        }
      }

      if (filter.includeApproximateResults !== undefined) {
        result += `${result.length ? "&" : ""}approximate=${
          filter.includeApproximateResults ? "true" : "false"
        }`;
      }
    }
  }

  if (filter.pageNumber) {
    result += `${result.length ? "&" : ""}pageNumber=${encodeURIComponent(filter.pageNumber)}`;
  }
  if (filter.rowsPerPage) {
    result += `${result.length ? "&" : ""}rowsPerPage=${encodeURIComponent(filter.rowsPerPage)}`;
  }

  return result;
}
