import {
  Box,
  DeviceTypeProvider,
  Divider,
  Layer,
  PopoverCombobox,
  PopoverComboboxOption,
  StringTemplateBuilder,
  StringTemplateBuilderEventManager,
  Table,
  Text,
  VariablePartSheet,
  VariablePartSheetCurrentPart,
  popupZIndex,
  useColors,
} from "@prodoctivity/design-system";
import type {
  DataType,
  DocumentCollectionIcon,
  StringTemplateParameterValue,
  StringTemplatePart,
} from "@prodoctivity/shared/src/index-types";
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useMemo, useState } from "react";
import { DocumentCollectionState, useIconOptions } from "../hooks";

import type { DocumentTypeInfo } from "@prodoctivity/types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { ArrowSvg } from "../../../../svg/ArrowSvg";
import { RenderDocumentCollectionIcon } from "../../../DocumentCollectionInstance/RenderDocumentCollectionIcon";
import { nameConfigContextToString } from "./utils";

type Props = {
  formState: DocumentCollectionState;
  masterDocumentType: DocumentTypeInfo;
  isLoading: boolean;
  eventManager: StringTemplateBuilderEventManager;
  masterSourceFields: Array<{ label: string; value: string; dataType: DataType }>;
  setFormState: Dispatch<SetStateAction<DocumentCollectionState>>;
};

export const DisplaySettingsStep: FunctionComponent<Props> = ({
  formState,
  masterSourceFields,
  masterDocumentType,
  eventManager,
  setFormState,
}) => {
  const { colors } = useColors();
  const [showVariablePartSheet, setShowVariablePartSheet] = useState(false);
  const { resources, moment } = useAppTranslation();
  const [filter, setFilter] = useState("");
  const onFilterChange = useCallback((newFilter: string) => {
    setFilter(newFilter);
  }, []);
  const [clickedPart, setClickedPart] = useState<VariablePartSheetCurrentPart>();
  const setClickedPartValue = useCallback(
    (value: VariablePartSheetCurrentPart) => {
      setClickedPart(value);
    },
    [setClickedPart]
  );

  const [currentVariable, rawSetCurrentVariable] = useState<
    StringTemplateParameterValue | undefined
  >();

  const rawSetCurrentVariableValue = useCallback(
    (value: StringTemplateParameterValue | undefined) => {
      rawSetCurrentVariable(value);
    },
    []
  );
  const initialState = useMemo(() => ({ parts: formState.nameConfig }), [formState.nameConfig]);
  const handlePartClicked = useCallback(
    (args: {
      lineIndex: number;
      partIndex: number;
      parameterKey: string;
      part: StringTemplatePart;
    }) => {
      setClickedPartValue({
        lineIndex: args.lineIndex,
        parameterKey: args.parameterKey,
        partIndex: args.partIndex,
        part: args.part,
      });
      setShowVariablePartSheet(true);
    },
    [setClickedPartValue]
  );
  const handleVariableAdded = useCallback(
    (_args: { lineIndex: number; partIndex: number; name: string; dataType: DataType }) => {
      setShowVariablePartSheet(false);
    },
    []
  );

  const getFieldLabel = useCallback(
    (fldName: string) => {
      const found = masterSourceFields.find((fld) => fld.value === fldName);
      if (found) {
        return found.label;
      }
      return fldName;
    },
    [masterSourceFields]
  );
  const getFieldDataType: (
    source: StringTemplateParameterValue["source"],
    name: string
  ) => DataType = useCallback(
    (_source, name) => {
      const found = masterSourceFields.find((fld) => fld.value === name);
      if (found) {
        return found.dataType;
      }
      return "Alphanumeric";
    },
    [masterSourceFields]
  );

  const variableList: StringTemplateParameterValue[] = useMemo(() => {
    return masterSourceFields.map((field) => {
      return {
        source: "context",
        value: field.value,
      };
    });
  }, [masterSourceFields]);

  const { iconOptions: icons } = useIconOptions(resources);
  const iconOptions = useMemo(() => {
    return icons.reduce((arr: PopoverComboboxOption[], curr) => {
      curr.icons.forEach((opt) => {
        arr.push({
          groupLabel: curr.groupLabel,
          value: opt.value,
          label: (
            <Box display="flex" gap={3}>
              <RenderDocumentCollectionIcon iconKey={opt.value as DocumentCollectionIcon} />
              <Text>{opt.label}</Text>
            </Box>
          ),
          strLabel: opt.label,
        });
      });
      return arr;
    }, []);
  }, [icons]);

  const getNewVariableData = useCallback(() => {
    const found = variableList[0];
    if (found) {
      return {
        name: found.value,
        dataType: getFieldDataType(found.source, found.value),
      };
    } else {
      throw new Error("Cannot happen");
    }
  }, [getFieldDataType, variableList]);

  return (
    <Box
      paddingY={8}
      paddingX={6}
      marginTop={3}
      color={colors.white}
      borderStyle="lg"
      borderRadius={4}
      display="flex"
      justifyContent="between"
    >
      <Box width={"48%"} borderStyle="lg" borderRadius={6}>
        <Box color={colors.neutral200} paddingY={3} paddingX={5}>
          <Text color={colors.subtle} weight="bold">
            {resources.generalProperties}
          </Text>
        </Box>
        <Divider />
        <Box padding={7}>
          <Box display="flex" gap={3} direction="column">
            <Box minHeight={65}>
              <Box marginBottom={3} width="100%">
                <Text color={colors.neutral900}>
                  {resources.documentCollection.collectionValues}
                </Text>
              </Box>
              <Box width="100%">
                <StringTemplateBuilder
                  eventManager={eventManager}
                  inline={true}
                  onChange={(e) => setFormState((prev) => ({ ...prev, nameConfig: e.parts }))}
                  initialState={initialState}
                  onPartClicked={handlePartClicked}
                  onVariableAdded={handleVariableAdded}
                  getNewVariableData={getNewVariableData}
                  getFieldLabel={getFieldLabel}
                  warningLabel={resources.documentCollection.YouHaveRepeatedVariables}
                />
                {!formState.nameConfig.filter((p) => p.type === "variable").length && (
                  <Text size="100" color={colors.error}>
                    {resources.documentCollection.documentCollectionVariablesRequired}
                  </Text>
                )}
                <DeviceTypeProvider deviceType="mobile">
                  {showVariablePartSheet && (
                    <Layer zIndex={popupZIndex}>
                      <VariablePartSheet
                        onDismiss={() => {
                          setShowVariablePartSheet(false);
                        }}
                        getFieldLabel={getFieldLabel}
                        getFieldDataType={getFieldDataType}
                        resources={resources}
                        moment={moment}
                        variableList={variableList}
                        currentPart={clickedPart}
                        showVariableDetails={false}
                        allowAddNew={false}
                        eventManager={eventManager}
                        currentVariable={currentVariable}
                        rawSetCurrentVariableValue={rawSetCurrentVariableValue}
                      />
                    </Layer>
                  )}
                </DeviceTypeProvider>
              </Box>
            </Box>
            <Box minHeight={65}>
              <Box marginBottom={3} width="100%">
                <Text color={colors.neutral900}>{resources.imageType}</Text>
              </Box>
              <Box width="100%">
                <PopoverCombobox
                  filter={filter}
                  onFilterChange={onFilterChange}
                  getI18n={(k) => {
                    const m: Record<string, string> = resources as any;
                    return m[k] || k;
                  }}
                  selectedValue={formState.iconKey}
                  onSelect={(item) =>
                    setFormState((prev) => ({
                      ...prev,
                      iconKey: item.value as DocumentCollectionIcon,
                    }))
                  }
                  options={iconOptions}
                  direction={undefined}
                  placeholder={undefined}
                  onClear={undefined}
                  displayOption="label"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box width={"48%"} borderStyle="lg" borderRadius={6}>
        <Box color={colors.neutral200} paddingY={3} paddingX={5}>
          <Text color={colors.subtle} weight="bold">
            {formState.name} ({resources.preview})
          </Text>
        </Box>
        <Divider />
        <Box padding={7}>
          <Table accessibilityLabel={resources.preview}>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Box display="flex" justifyContent="center">
                    <RenderDocumentCollectionIcon iconKey={formState.iconKey} />
                  </Box>
                </Table.Cell>
                <Table.Cell>
                  <Box display="flex" alignItems="center" gap={5}>
                    <ArrowSvg direction="left" />
                    {resources.imageType}
                  </Box>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Box display="flex" justifyContent="center">
                    <Text>{formState.name}</Text>
                  </Box>
                </Table.Cell>
                <Table.Cell>
                  <Box display="flex" alignItems="center" gap={5}>
                    <ArrowSvg direction="left" />
                    {resources.name}
                  </Box>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Box display="flex" justifyContent="center">
                    {nameConfigContextToString(
                      moment,
                      formState.nameConfig,
                      masterDocumentType.contextDefinition
                    )}
                  </Box>
                </Table.Cell>
                <Table.Cell>
                  <Box display="flex" alignItems="center" gap={5}>
                    <ArrowSvg direction="left" />
                    {resources.documentCollection.collectionValues}
                  </Box>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};
