import {
  DatePickerProps,
  ProDoctivityColorBundle,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import {
  mapFieldToTemplateFieldRecordUI,
  mapRecordToTemplateFieldRecordUI,
  sortFields,
} from "@prodoctivity/fluency-components";
import {
  enumerateDependencyItemFields,
  getContextField,
  getContextRecord,
} from "@prodoctivity/shared";
import type {
  ContextField,
  ContextRecord,
  TemplateContextRecordHolder,
  TemplateDependency,
  TemplatePage,
  TemplateWizardDefinition,
} from "@prodoctivity/shared/src/index-types";
import type { TemplateFieldRecordUI } from "@prodoctivity/types";
import type momentType from "moment";
import { useCallback, useMemo, useState } from "react";

export type DependencyProps = {
  i18n: (key: string) => string;
  formDependencies: TemplateDependency[];
  onDependencyUpdate: (dependencies: TemplateDependency[]) => void;
  addNewDependency?: () => void;
  removeSelectedDependency?: (index: number) => void;
  moment: typeof momentType;
  colors: ProDoctivityColorBundle;
  contextDefinition: TemplateContextRecordHolder;
  wizardDefinition: TemplateWizardDefinition;
  resources: DatePickerProps["resources"] & {
    dataTypeValues: {
      and: string;
      none: string;
    };
    clear: string;
    contains: string;
    custom: string;
    endsWith: string;
    equals: string;
    filterBuilder: {
      addAnExpressionHere: string;
      addDependency: string;
      alwaysFalse: string;
      alwaysTrue: string;
      averageOf: string;
      clickHereToTurnToNegativeAnd: string;
      clickHereToTurnToNegativeOr: string;
      clickHereToTurnToPositiveAnd: string;
      clickHereToTurnToPositiveOr: string;
      clickToFilterFunnel: string;
      condition: string;
      countOf: string;
      createDependenciesDescription: string;
      dependencyName: string;
      dependencyNotCreated: string;
      dependencySelection: string;
      exclude: string;
      falseF: string;
      helpMode: string;
      ifThisExpressionIs: string;
      include: string;
      invalidExpression: string;
      is: string;
      isNot: string;
      maximumOf: string;
      minimumOf: string;
      negativeF: string;
      not: string;
      positiveF: string;
      sumOf: string;
      theSelectedTextInTheDocument: string;
      then: string;
      trueF: string;
      turnYourExpression: string;
      valueOfLabel: string;
      whereCaps: string;
    };
    greaterThan: string;
    greaterThanOrEquals: string;
    lessThan: string;
    lessThanOrEquals: string;
    notEquals: string;
    or: string;
    remove: string;
    startsWith: string;
    summary: string;
  };
};

export type DependencyState = {
  conditionText: string;
  fields: TemplateFieldRecordUI[];
};

export const useDependencies = ({
  i18n,
  formDependencies,
  onDependencyUpdate,
  addNewDependency,
  removeSelectedDependency,
  moment,
  colors,
  contextDefinition,
  wizardDefinition,
  resources,
}: DependencyProps) => {
  /* ---------------------------- #Common Hooks area---------------------------- */

  const { breakpoint } = useDesignBreakpoint();

  const fields = useMemo(() => {
    if (!contextDefinition) return [];

    const fieldUI: TemplateFieldRecordUI[] = contextDefinition.fields.map((field) => {
      const mappedField = mapFieldToTemplateFieldRecordUI(field, false);
      return mappedField;
    });
    const recordUI: TemplateFieldRecordUI[] = contextDefinition.records.map((record) => {
      const mappedRecord = mapRecordToTemplateFieldRecordUI(record, false);
      return mappedRecord;
    });
    const mergedTemplateFieldRecordUI = sortFields(fieldUI.concat(recordUI));

    return mergedTemplateFieldRecordUI;
  }, [contextDefinition]);

  // replaces the constructor from class component
  const setInitialStateFromProps: () => DependencyState = useCallback(() => {
    const initialState: DependencyState = {
      conditionText: "",
      fields: [],
    };

    // Generate list for all Markers in the form
    const result: DependencyState = {
      ...initialState,
      fields: fields,
    };
    return result;
  }, [fields]);

  const [state, setState] = useState<DependencyState>(setInitialStateFromProps);

  const onSingleDependencyUpdate = useCallback(
    (index: number) => {
      return (dependency: TemplateDependency) => {
        const dependencies = formDependencies.slice(0);
        dependencies[index] = dependency;

        if (onDependencyUpdate) {
          onDependencyUpdate(dependencies);
        }
      };
    },
    [onDependencyUpdate, formDependencies]
  );

  const plainFieldList = wizardDefinition?.pages.reduce((acc: string[], page: TemplatePage) => {
    enumerateDependencyItemFields(contextDefinition, wizardDefinition, {
      type: "page",
      key: page.key,
      action: "hide",
    }).forEach((el) => {
      acc.push(el);
    });
    return acc;
  }, []);

  const recordList: ContextRecord[] = useMemo(() => {
    return plainFieldList
      ? plainFieldList
          .map((field) => getContextRecord(contextDefinition, field))
          .filter((record): record is ContextRecord => record !== undefined)
      : [];
  }, [contextDefinition, plainFieldList]);

  const fieldList: ContextField[] = useMemo(() => {
    return plainFieldList
      ? plainFieldList
          .map((field) => getContextField(contextDefinition, field))
          .filter((field): field is ContextField => field !== undefined)
      : [];
  }, [contextDefinition, plainFieldList]);

  const mixedList = useMemo(() => {
    if (fieldList.length === 0) return recordList;
    if (recordList.length === 0) return fieldList;

    const mergeLists = (
      records: ContextRecord[],
      fields: ContextField[]
    ): (ContextRecord | ContextField)[] => {
      const mergedList = [];
      let recordIndex = 0,
        fieldIndex = 0;

      while (recordIndex < records.length && fieldIndex < fields.length) {
        if (fields[fieldIndex].fullPath.startsWith(records[recordIndex].name)) {
          mergedList.push(records[recordIndex++]);
        } else {
          mergedList.push(fields[fieldIndex++]);
        }
      }

      // Checks if there are any remaining fields or records (records that for some reason didn't have inner fields) and add them to the array starting from the position we were left off
      return [...mergedList, ...records.slice(recordIndex), ...fields.slice(fieldIndex)];
    };

    return mergeLists(recordList, fieldList);
  }, [fieldList, recordList]);

  return {
    breakpoint,
    onSingleDependencyUpdate,
    setInitialStateFromProps,
    state,
    setState,
    i18n,
    colors,
    removeSelectedDependency,
    moment,
    addNewDependency,
    resources,
    mixedList,
    recordList,
  };
};
