"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceSelectableFields = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var design_system_1 = require("@prodoctivity/design-system");
var react_1 = require("react");
var SourceSelectableFields = function (props) {
    var objExpression = props.objExpression, state = props.state, inputSelectSourceChanged = props.inputSelectSourceChanged, inputSelectSourceClear = props.inputSelectSourceClear, selectableFieldList = props.selectableFieldList, parentProps = props.parentProps, renderFieldOption = props.renderFieldOption, expression = props.expression, i18n = props.translateFn;
    var selectOptions = (0, react_1.useMemo)(function () {
        if (!expression.sourceSummary) {
            return [];
        }
        var selectableList = selectableFieldList(parentProps.fieldList, objExpression.summaryList, expression.sourceSummary, 0)
            .map(function (item) {
            var field = item.field, canSelectRecord = item.canSelectRecord;
            return renderFieldOption(field, true, canSelectRecord, 0);
        })
            .flat();
        return selectableList.map(function (item) {
            return __assign(__assign({}, item), { subtext: item.disabled !== undefined ? i18n("records") : undefined });
        });
    }, [
        expression.sourceSummary,
        i18n,
        objExpression.summaryList,
        parentProps.fieldList,
        renderFieldOption,
        selectableFieldList,
    ]);
    return expression.sourceSummary ? ((0, jsx_runtime_1.jsx)(design_system_1.Box, { width: "fit-content", display: "flex", gap: 4, direction: "column", children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { maxWidth: 300, width: "100%", children: (0, jsx_runtime_1.jsx)(design_system_1.CustomComboBox, { direction: "down", options: selectOptions.filter(function (sourceField) { return sourceField.disabled === undefined || !sourceField.disabled; }), getI18n: i18n, placeholder: "", onSelect: inputSelectSourceChanged, onClear: inputSelectSourceClear, selectedValue: selectOptions.find(function (sourceField) { return sourceField.value === expression.sourceField; }) }, "".concat(state.id, "-source-summary")) }) })) : null;
};
exports.SourceSelectableFields = SourceSelectableFields;
