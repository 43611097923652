import {
  Accordion,
  Box,
  ColorConfig,
  Divider,
  Icon,
  IconButton,
  IconType,
  Image,
  OverlayPanel,
  TapArea,
  Text,
  dropDownZIndex,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import {
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { SettingsPageContext, SettingsPageCtx } from "../context";

import { useLocation } from "react-router-dom";
import setting_svg from "../assets/setting.svg";
import { OrganizationLink } from "../components/OrganizationLink";
import { useAppTranslation } from "../hooks/useAppTranslation";
import { useServices } from "../hooks/useServices";
import { useSettings } from "../hooks/useSettings";
import { organizationLinkTemplates } from "../link-templates";
import { BundleSvgIcon } from "../svg/BundleSvgIcon";
import { CombinedTemplateSvgIcon } from "../svg/CombinedTemplateSvgIcon";
import { DataDictionaryMenuIcon } from "../svg/DataDictionaryMenuIcon";
import { DocumentaryStructureMenuIcon } from "../svg/DocumentaryStructureMenuIcon";
import { FragmentMenuIcon } from "../svg/FragementMenuIcon";
import { GraphicMarkMenuIcon } from "../svg/GrpahicMarkMenuIcon";
import { IntegrationsMenuIcon } from "../svg/IntegrationsMenuIcon";
import { SigningMenuIcon } from "../svg/SigningSvgIcon";
import { TemplatesMenuIcon } from "../svg/TemplatesMenuIcon";
import { UserManagementMenuIcon } from "../svg/UserManagementMenuIcon";
import { UserMenuIcon } from "../svg/UserMenuIcon";
import { usePlanSetting } from "../hooks/usePlanSetting";
const showFeatureInDevelopment = false;

function includeIf<T>(criteria: (arg: T) => boolean, element: T): T[] {
  if (criteria(element)) {
    return [element];
  }
  return [];
}

type OptionProps = {
  type: "item";
  name: string;
  routeRef: string;
  navigateTo: string;
  icon?: IconType;
  svgIcon?: string | FunctionComponent | React.FunctionComponent<{ color?: ColorConfig }>;
  color?: ColorConfig;
};

export type SideAccordionOptions = (
  | OptionProps
  | {
      type: "menu";
      name: string;
      svgIcon?: string | FunctionComponent | React.FunctionComponent<{ color?: ColorConfig }>;
      subOptions?: OptionProps[];
      color?: ColorConfig;
    }
)[];

export const SettingsPageProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { user } = useServices();

  const { breakpoint } = useDesignBreakpoint();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { disableSignUp } = useSettings();

  const upgradePlanSetting = usePlanSetting();

  const options: SideAccordionOptions = useMemo(() => {
    if (!user) {
      return [];
    }

    const isAdmin = user.permissions.indexOf("organization-admin") >= 0;
    const isAdminCriteria = () => isAdmin;
    const enableSignUp = () =>
      !disableSignUp && isAdmin && upgradePlanSetting?.upgradePlanBehavior !== "blocked";
    const dataDictionaryMenu: OptionProps[] = [
      {
        type: "item",
        name: resources.dataDictionary.dataElements,
        routeRef: "/data-elements",
        navigateTo: organizationLinkTemplates.dataElements(),
      },
      {
        type: "item",
        name: resources.dataDictionary.dataLists,
        routeRef: "/data-lists",
        navigateTo: organizationLinkTemplates.manageDictionaryLists(),
      },
    ];

    if (showFeatureInDevelopment) {
      dataDictionaryMenu.push({
        type: "item",
        name: resources.dataDictionary.identities,
        routeRef: "/settings/data-dictionary/identities",
        navigateTo: organizationLinkTemplates.identities(),
      });
    }

    return [
      {
        type: "menu",
        name: resources.account,
        svgIcon: UserMenuIcon,
        color: colors.primary,
        subOptions: [
          {
            type: "item",
            name: resources.myProfile,
            routeRef: "/account/profile",
            navigateTo: organizationLinkTemplates.profile(),
          },
          {
            type: "item",
            name: resources.subscriptions.subscriptionsLabel,
            routeRef: "/account/subscriptions",
            navigateTo: organizationLinkTemplates.userSubscriptions(),
          },
          ...includeIf<OptionProps>(enableSignUp, {
            type: "item",
            name: resources.upgradePlan,
            routeRef: "account/upgrade-plan",
            navigateTo: organizationLinkTemplates.upgradePlan(),
          }),
        ],
      },

      ...includeIf<SideAccordionOptions[0]>(isAdminCriteria, {
        type: "menu",
        name: resources.dataDictionary.dictionary,
        svgIcon: DataDictionaryMenuIcon,
        color: colors.primary,
        subOptions: dataDictionaryMenu,
      }),

      {
        type: "menu",
        name: `${resources.fluency}`,
        svgIcon: IntegrationsMenuIcon,
        color: colors.primary,
        subOptions: [
          ...includeIf<OptionProps>(isAdminCriteria, {
            type: "item",
            name: resources.templates,
            routeRef: "/templates",
            navigateTo: organizationLinkTemplates.settingsTemplates(),
            svgIcon: TemplatesMenuIcon,
            color: colors.primary,
          }),
          ...includeIf<OptionProps>(isAdminCriteria, {
            type: "item",
            name: resources.fragments,
            routeRef: "/fragments",
            navigateTo: organizationLinkTemplates.manageFragments(),
            svgIcon: FragmentMenuIcon,
            color: colors.primary,
          }),
          ...includeIf<OptionProps>(isAdminCriteria, {
            type: "item",
            name: resources.combinedTemplates.combinedTemplates,
            routeRef: "/combined-templates",
            navigateTo: organizationLinkTemplates.manageCombinedTemplates(),
            svgIcon: (props: { color?: ColorConfig }) => (
              <CombinedTemplateSvgIcon {...props} width={30} height={32} />
            ),
            color: colors.primary,
          }),
        ],
      },

      ...includeIf<SideAccordionOptions[0]>(isAdminCriteria, {
        type: "menu",
        name: `${resources.documentaryStructure}`,
        svgIcon: DocumentaryStructureMenuIcon,
        color: colors.primary,
        subOptions: [
          {
            type: "item",
            name: resources.documentGroups,
            routeRef: "/document-groups",
            navigateTo: organizationLinkTemplates.manageDocumentGroups(),
          },
          {
            type: "item",
            name: resources.documentTypes.documentTypes,
            routeRef: "/document-types",
            navigateTo: organizationLinkTemplates.manageDocumentTypes(),
          },
          {
            type: "item",
            name: resources.documentTypes.documentCollection,
            routeRef: "/document-collection-config",
            navigateTo: organizationLinkTemplates.manageDocumentCollections(),
          },
          // {
          //   type: "item",
          //   name: resources.documentTypes.typesOfNotes,
          //   routeRef: "/note-types",
          //   navigateTo: "",
          // },
        ],
      }),
      ...includeIf<SideAccordionOptions[0]>(isAdminCriteria, {
        type: "menu",
        name: resources.security,
        svgIcon: UserManagementMenuIcon,
        color: colors.primary,
        subOptions: [
          {
            type: "item",
            name: resources.users,
            routeRef: "/users",
            navigateTo: organizationLinkTemplates.manageUsers(),
          },
          {
            type: "item",
            name: resources.rolesManagement.roles,
            routeRef: "/roles",
            navigateTo: organizationLinkTemplates.manageRoles(),
          },
        ],
      }),
      ...includeIf<SideAccordionOptions[0]>(isAdminCriteria, {
        type: "menu",
        name: resources.signing,
        svgIcon: SigningMenuIcon,
        color: colors.primary,
        subOptions: [
          {
            type: "item",
            name: resources.graphicMarks,
            routeRef: "/graphic-mark",
            navigateTo: organizationLinkTemplates.graphicMarkList(),
            svgIcon: GraphicMarkMenuIcon,
            color: colors.primary,
          },
        ],
      }),
      ...includeIf<SideAccordionOptions[0]>(() => showFeatureInDevelopment, {
        type: "menu",
        name: resources.licenseManagement,
        subOptions: [],
      }),
      ...includeIf<SideAccordionOptions[0]>(() => showFeatureInDevelopment, {
        type: "menu",
        name: resources.generalSettings,
        svgIcon: setting_svg,
        subOptions: [],
      }),
      ...includeIf<SideAccordionOptions[0]>(isAdminCriteria, {
        type: "item",
        name: resources.bundle.bundles,
        routeRef: "/bundles",
        navigateTo: organizationLinkTemplates.settingsBundle(),
        svgIcon: ({ color }: { color?: ColorConfig }) => (
          <BundleSvgIcon color={color} width={40} height={40} />
        ),
        color: colors.primary,
      }),
      ...includeIf<SideAccordionOptions[0]>(isAdminCriteria, {
        type: "menu",
        name: resources.integrations,
        svgIcon: IntegrationsMenuIcon,
        color: colors.primary,
        subOptions: [
          {
            icon: "screwdriver-wrench",
            type: "item",
            name: resources.distributions,
            routeRef: "/distributions",
            navigateTo: organizationLinkTemplates.distributions(),
          },
          {
            icon: "list",
            type: "item",
            name: resources.deferred.deferredDistributionList,
            routeRef: "/deferred-distributions",
            navigateTo: organizationLinkTemplates.deferredDistributionList(),
          },
        ],
      }),
    ];
  }, [
    user,
    resources.dataDictionary.dataElements,
    resources.dataDictionary.dataLists,
    resources.dataDictionary.dictionary,
    resources.dataDictionary.identities,
    resources.account,
    resources.myProfile,
    resources.subscriptions.subscriptionsLabel,
    resources.upgradePlan,
    resources.fluency,
    resources.templates,
    resources.fragments,
    resources.combinedTemplates.combinedTemplates,
    resources.documentaryStructure,
    resources.documentGroups,
    resources.documentTypes.documentTypes,
    resources.documentTypes.documentCollection,
    resources.security,
    resources.users,
    resources.rolesManagement.roles,
    resources.signing,
    resources.graphicMarks,
    resources.licenseManagement,
    resources.generalSettings,
    resources.bundle.bundles,
    resources.integrations,
    resources.distributions,
    resources.deferred.deferredDistributionList,
    colors.primary,
    disableSignUp,
    upgradePlanSetting?.upgradePlanBehavior,
  ]);

  const [isOpen, setIsOpen] = useState(true);

  const value: SettingsPageCtx = useMemo(() => {
    if (breakpoint === "hd") {
      return {
        isOpen,
        leftSideBar: (
          <Box display="flex" flex="grow" direction="column" width={isOpen ? "100%" : 34}>
            <Box display="flex" height={"100%"}>
              <SideAccordion
                title={resources.settings}
                options={options}
                mode="desktop"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
              {isOpen && <Divider direction="vertical" />}
            </Box>
          </Box>
        ),
        topComponent: undefined,
      };
    } else {
      return {
        isOpen,
        leftSideBar: (
          <SideAccordionResponsive
            options={options}
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={setShowMobileMenu}
          />
        ),
        topComponent: (
          <Box position="fixed" paddingTop={2} right={true} paddingRight={2} display="flex">
            <Box>
              <TapArea onTap={() => setShowMobileMenu && setShowMobileMenu(true)}>
                <Icon
                  color={colors.neutral900}
                  accessibilityLabel={resources.settings}
                  icon="gear"
                  size={4}
                />
              </TapArea>
            </Box>
          </Box>
        ),
      };
    }
  }, [breakpoint, colors.neutral900, options, resources.settings, showMobileMenu, isOpen]);

  return <SettingsPageContext.Provider value={value}>{children}</SettingsPageContext.Provider>;
};

export const SideAccordion = ({
  options,
  title,
  setShowMobileMenu,
  mode,
  isOpen,
  setIsOpen,
}: {
  options: SideAccordionOptions;
  title?: string;
  setShowMobileMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  mode: "desktop" | "mobile";
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { pathname } = useLocation();
  const [isChildActive, setIsChildActive] = useState("");
  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  if (!isOpen) {
    return (
      <Box
        display="flex"
        flex="grow"
        direction="column"
        width={"auto"}
        paddingY={4}
        paddingX={2}
        color={colors.neutral200}
        hoverColor={colors.primaryHover0}
      >
        <Box display="flex" flex="shrink">
          <IconButton
            onClick={toggleOpen}
            accessibilityLabel={resources.open}
            icon="chevron-double-right"
            size={"sm"}
            color={colors.primary}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" direction="column" width={"100%"} flex="grow">
      <Box
        display="flex"
        direction="row"
        flex="shrink"
        paddingX={4}
        paddingY={2}
        color={colors.neutral300}
      >
        {title && (
          <Box display="flex" flex="grow">
            <Text weight="normal" size="200" color={colors.neutral900}>
              {title.toUpperCase()}
            </Text>
          </Box>
        )}
        {mode === "desktop" && (
          <Box
            display="flex"
            flex="shrink"
            alignItems="center"
            paddingX={1}
            hoverColor={colors.primaryHover0}
          >
            <IconButton
              onClick={toggleOpen}
              accessibilityLabel={isOpen ? resources.close : resources.open}
              icon="close"
              size={"sm"}
              color={colors.primary}
            />
          </Box>
        )}
      </Box>
      <Divider direction="horizontal" />
      {options.map((opt, idx) => {
        if (opt.type === "item") {
          const menuItemSelected = pathname.includes(opt.routeRef);
          return (
            <Box
              key={`${opt.navigateTo}_${idx}`}
              onClickCapture={() => setShowMobileMenu && setShowMobileMenu(false)}
              hoverColor={colors.primaryHover0}
            >
              {menuItemSelected ? <Box height={1} color={colors.neutral500}></Box> : null}
              <TabLink
                key={opt.name}
                current={pathname.includes(opt.routeRef)}
                icon={opt.icon}
                svgIcon={opt.svgIcon}
                to={opt.navigateTo}
                color={opt.color}
                parentKey={opt.name}
              >
                <Text color={menuItemSelected ? colors.black600 : colors.subtle}>{opt.name}</Text>
              </TabLink>
              {menuItemSelected ? <Box height={1} color={colors.neutral500}></Box> : null}
            </Box>
          );
        }

        if (!opt.subOptions || !opt.subOptions.length) {
          return null;
        }

        const accordionSelected = opt.subOptions
          ? opt.subOptions.findIndex((subOpt) => pathname.includes(subOpt.routeRef)) >= 0
          : false;

        const SvgIcon = opt.svgIcon;
        const iconColor = opt.color;
        return (
          <Box
            display="flex"
            direction="column"
            flex="shrink"
            key={`${opt.name}_${idx}`}
            width={"auto"}
          >
            {accordionSelected ? <Box height={1} color={colors.neutral500}></Box> : null}
            <Accordion
              key={opt.name}
              TitleComponent={
                <Box paddingX={5}>
                  <Box display="flex" alignItems="center">
                    {opt.svgIcon && (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width={45}
                        height={40}
                      >
                        {typeof SvgIcon === "function" ? (
                          <SvgIcon color={accordionSelected ? iconColor : colors.neutral700} />
                        ) : (
                          <Image
                            alt="tabLinkIcon"
                            src={SvgIcon ? SvgIcon : ""}
                            naturalHeight={24}
                            naturalWidth={27}
                            color="transparent"
                          />
                        )}
                      </Box>
                    )}
                    <Box marginStart={3}>
                      <Text
                        weight={accordionSelected ? "bold" : "normal"}
                        color={accordionSelected ? colors.primary : colors.subtle}
                      >
                        {opt.name}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              }
              initialValue={accordionSelected}
              childIsActive={isChildActive === opt.name ? true : false} // state === currentAcc.opt.name ? true : false
            >
              <Box
                display="flex"
                direction="column"
                onClickCapture={() => setShowMobileMenu && setShowMobileMenu(false)}
                flex="grow"
              >
                {(opt.subOptions || []).map((subOpt) => {
                  const menuItemSelected = pathname.includes(subOpt.routeRef);
                  return (
                    <OrganizationLink to={subOpt.navigateTo}>
                      <Box
                        key={subOpt.name}
                        display="flex"
                        direction="row"
                        flex="grow"
                        hoverColor={colors.primaryHover0}
                      >
                        <Box
                          display="flex"
                          flex="grow"
                          color={pathname.includes(subOpt.routeRef) ? colors.neutral300 : undefined}
                        >
                          <TabLink
                            current={pathname.includes(subOpt.routeRef)}
                            icon={subOpt.icon}
                            svgIcon={subOpt.svgIcon}
                            to={subOpt.navigateTo}
                            color={menuItemSelected ? subOpt.color : colors.neutral700}
                            isChild={true}
                            handleChildStatus={setIsChildActive}
                            parentKey={opt.name}
                          >
                            <Box marginStart={12}></Box>
                            <Box marginStart={12}></Box>
                            <Box marginStart={12}></Box>
                            <Text>{subOpt.name}</Text>
                          </TabLink>
                        </Box>
                      </Box>
                    </OrganizationLink>
                  );
                })}
              </Box>
            </Accordion>
            {accordionSelected ? <Box height={1} color={colors.neutral500}></Box> : null}
          </Box>
        );
      })}
    </Box>
  );
};

function TabLink({
  icon,
  to,
  current,
  children,
  svgIcon,
  isChild,
  handleChildStatus,
  parentKey,
}: {
  children?: ReactNode;
  to: string;
  current?: boolean;
  icon?: IconType;
  svgIcon?: string | React.FunctionComponent<{ color?: ColorConfig }>;
  color?: ColorConfig;
  isChild?: boolean;
  handleChildStatus?: (arg: string) => void;
  parentKey: string;
}) {
  const { colors } = useColors();
  const SvgIcon = svgIcon;
  const iconColor = current ? colors.primary : colors.neutral700;
  useLayoutEffect(() => {
    if (current && handleChildStatus) {
      handleChildStatus(parentKey);
    }
  }, [current, handleChildStatus, parentKey]);

  return (
    <OrganizationLink to={to}>
      <Box maxHeight={56} display="flex" paddingY={6}>
        <Box display="flex" alignItems="center" flex="grow">
          {current && <Box height={50} width={5} color={colors.primary} />}
          <Box
            paddingLeft={isChild ? 8 : 5}
            marginStart={isChild ? (current ? 10 : 11) : current ? 0 : 1}
          >
            <Box display="flex" alignItems="center" gap={4}>
              {SvgIcon && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={40}
                  height={40}
                  marginStart={-1}
                >
                  {typeof SvgIcon === "function" ? (
                    <SvgIcon color={iconColor} />
                  ) : (
                    <Image
                      alt="tabLinkIcon"
                      src={SvgIcon}
                      naturalHeight={24}
                      naturalWidth={27}
                      color="transparent"
                    />
                  )}
                </Box>
              )}
              {icon && (
                <Icon
                  size="xs"
                  accessibilityLabel="profile"
                  color={current ? colors.primary : colors.subtle}
                  icon={icon}
                />
              )}
              <Text
                weight={current ? "bold" : "normal"}
                color={current ? colors.primary : colors.subtle}
              >
                {children}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </OrganizationLink>
  );
}

const SideAccordionResponsive: FunctionComponent<{
  options: SideAccordionOptions;
  setShowMobileMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  showMobileMenu?: boolean;
}> = ({ options, setShowMobileMenu, showMobileMenu }) => {
  const { resources } = useAppTranslation();

  const handleSideAccordionItemClick = () => {
    setShowMobileMenu && setShowMobileMenu(false);
  };

  return (
    <Box width={"50%"} zIndex={dropDownZIndex} display="flex">
      {showMobileMenu && setShowMobileMenu && (
        <OverlayPanel
          accessibilityLabel={resources.actions}
          heading=""
          onDismiss={handleSideAccordionItemClick}
          size="lg"
        >
          <Box>
            <SideAccordion
              title={resources.settings}
              options={options}
              setShowMobileMenu={setShowMobileMenu}
              mode="mobile"
              isOpen={showMobileMenu}
              setIsOpen={setShowMobileMenu}
            />
          </Box>
        </OverlayPanel>
      )}
    </Box>
  );
};
