import {
  Box,
  ContextStateUpdater,
  DocumentViewer,
  FormControllerProvider,
  ProgressBar,
  Spinner,
  calculateColor,
  useColors,
} from "@prodoctivity/design-system";
import type {
  HttpExecuteDataLinkRequest,
  TemplateContextHeader,
  TemplateVersionId,
} from "@prodoctivity/types";
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BreadCrumbEntry } from "../../components/BreadCrumb";

import { blobToBase64 } from "@prodoctivity/design-system";
import { GenerationWizardBoundary } from "@prodoctivity/prodoctivity-form-v5";
import { ParametersObject$Schema } from "@prodoctivity/shared";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Page } from "../../components/Layout/Page";
import { LookupDocument } from "../../components/LookupDocument/LookupDocument";
import { useDocumentLookupState } from "../../components/LookupDocument/hook";
import { NotificationMessage } from "../../components/NotificationMessage";
import { OrganizationNavigate } from "../../components/OrganizationNavigate";
import { isProduction } from "../../config";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { Services } from "../../services";
import { PageNotFound } from "../StandAloneViewer/PageNotFound";

type State = {
  step: "form" | "summary" | "document-viewer";
  status: "idle" | "generating";
  document?: Blob;
  template?: Awaited<ReturnType<Services["fetchTemplateInfo"]>>["info"];
  errors?: Array<{ errorType: string; message: string }>;
};

function GenerationWizardPage() {
  const { templateVersionId } = useParams();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const stateId = params.get("stateId");
  const { state: previousConfiguration } = useLocation();
  const { resources } = useAppTranslation();

  if (!templateVersionId) {
    return <PageNotFound message={resources.pageNotFound.templateNotFound} />;
  }

  if (stateId) {
    return (
      <GenerationWizardWithStateId
        previousConfiguration={previousConfiguration}
        templateVersionId={templateVersionId}
        stateId={stateId}
      />
    );
  } else {
    return (
      <GenerationWizardWithoutState contextInfo={{ contextType: "template", templateVersionId }} />
    );
  }
}

export const GenerationWizardWithoutState: FunctionComponent<{
  contextInfo: TemplateContextHeader;
}> = ({ contextInfo }) => {
  const skipMutationRef = useRef<boolean>(false);
  const { resources } = useAppTranslation();
  const { createTemplateContextState } = useServices();
  const organizationNavigate = useOrganizationNavigate();
  const { state } = useLocation();
  const createTemplateContext = useCallback(
    (contextInfo: TemplateContextHeader) => {
      return createTemplateContextState(contextInfo, {});
    },
    [createTemplateContextState]
  );
  const urlToRedirect = useCallback(
    (stateId: string) => {
      return contextInfo.contextType === "template"
        ? organizationLinkTemplates.templateVersionGeneratePage(
            contextInfo.templateVersionId,
            stateId
          )
        : organizationLinkTemplates.generateCombinedTemplate(
            contextInfo.combinedTemplateId,
            stateId
          );
    },
    [contextInfo]
  );

  const { mutate, data, isLoading, isError } = useMutation(createTemplateContext, {
    onSuccess: (data) => {
      organizationNavigate(urlToRedirect(data.id), { state: state, replace: true });
    },
  });

  useEffect(() => {
    if (skipMutationRef.current) return;
    skipMutationRef.current = true;
    mutate(contextInfo);
  }, [mutate, contextInfo]);

  if (isLoading || !data) {
    return <Spinner show={true} />;
  }

  if (isError) {
    return <PageNotFound message={resources.pageNotFound.templateNotFound} />;
  }

  return <OrganizationNavigate to={urlToRedirect(data.id)} replace={true} />;
};

const GenerationWizardWithStateId: FunctionComponent<{
  templateVersionId: TemplateVersionId | undefined;
  stateId: string;
  previousConfiguration: any;
}> = ({ templateVersionId, stateId, previousConfiguration }) => {
  const { getTemplateContextState } = useServices();

  const { resources } = useAppTranslation();

  const getTemplateContext = useCallback(() => {
    return getTemplateContextState(stateId);
  }, [getTemplateContextState, stateId]);
  const { isLoading, data } = useOrganizationQuery(
    `template-generation/${templateVersionId}/${stateId}`,
    getTemplateContext,
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );

  if (isLoading) {
    return <Spinner show={true} />;
  }

  if (!data || data.contextType !== "template" || data.templateVersionId !== templateVersionId) {
    return <PageNotFound message={resources.pageNotFound.templateNotFound} />;
  }

  return (
    <GenerationWizardWithPayload
      templateVersionId={templateVersionId}
      stateId={stateId}
      context={data.context}
      previousConfiguration={previousConfiguration}
    />
  );
};

const GenerationWizardWithPayload: FunctionComponent<{
  templateVersionId: TemplateVersionId;
  stateId: string;
  context: ParametersObject;
  previousConfiguration: any;
}> = ({ templateVersionId, stateId, context, previousConfiguration }) => {
  const {
    fetchTemplateInfo,
    generateDocumentsSync,
    executeDataLink,
    saveTemplateContextState,
    deleteTemplateContextState,
    getTemplateContextSampleRequest,
    getWordAsPdf,
  } = useServices();

  const { t } = useTranslation();
  const { resources, moment } = useAppTranslation();
  const { colors } = useColors();
  const organizationNavigate = useOrganizationNavigate();
  const updateState = useCallback(
    (ev: { data: ParametersObject }) => {
      return saveTemplateContextState(stateId, ev.data);
    },
    [saveTemplateContextState, stateId]
  );
  const { mutate: updateStateMutate } = useMutation({
    mutationFn: updateState,
  });

  const [progress, setProgress] = useState(0);

  const onChange = useCallback(
    (args: { data: ParametersObject; progress: number }) => {
      updateStateMutate({ data: args.data });
      setProgress(args.progress);
    },
    [updateStateMutate]
  );

  const generationContext = useMemo(() => {
    return previousConfiguration && previousConfiguration.generationContext;
  }, [previousConfiguration]);

  const initialContext = useMemo(() => {
    if (!generationContext || typeof generationContext !== "object") {
      return context;
    }
    const parseResult = ParametersObject$Schema.safeParse(generationContext);

    if (!parseResult.success) {
      return context;
    }

    const parsedGenerationContext = parseResult.data;
    return { ...context, ...parsedGenerationContext };
  }, [context, generationContext]);

  const i18n = useCallback(
    (key: string) => {
      const value = t(key);
      if (!isProduction && typeof value === "undefined") {
        console.error(`Key "${key}" not defined in i18n resource files`);
        return key;
      }

      return value;
    },
    [t]
  );
  const [state, setState] = useState<State>({
    step: "form",
    status: "idle",
  });

  const {
    extractedContext,
    documentLoadingState,
    setDocumentSearchOpen,
    setDocumentLoadingState,
    isDocumentSearchOpen,
  } = useDocumentLookupState(state.template?.contextDefinition.fields);
  const handleFetchTemplateInfo = useCallback(async () => {
    if (!templateVersionId) {
      organizationNavigate("/");
      return Promise.resolve(undefined);
    }
    const response = await fetchTemplateInfo(templateVersionId);
    setState((curr) => ({ ...curr, template: response.info }));
    return response;
  }, [fetchTemplateInfo, templateVersionId, organizationNavigate]);

  const onCancel = useCallback(() => {
    organizationNavigate(organizationLinkTemplates.home());
  }, [organizationNavigate]);

  const execDataLink = useCallback(
    (
      dataLinkId: string,
      dataLinkConfigVersionId: string,
      inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
    ) => {
      return executeDataLink(dataLinkId, dataLinkConfigVersionId, inputParameters);
    },
    [executeDataLink]
  );

  const onFinish = useCallback(() => {
    return deleteTemplateContextState(stateId);
  }, [deleteTemplateContextState, stateId]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const generateDocument = useCallback(
    (
      formValues?: any,
      isPreview = false
    ): Promise<
      | {
          success: boolean;
          blob?: Blob;
          documentId: string | undefined;
          documentVersionId: string | undefined;
        }
      | { success: false; errors: any[] }
    > => {
      if (!templateVersionId || !formValues) {
        return new Promise<{
          success: boolean;
          blob?: Blob;
          documentId: string | undefined;
          documentVersionId: string | undefined;
        }>((resolve) =>
          resolve({
            success: false,
            blob: new Blob(),
            documentId: undefined,
            documentVersionId: undefined,
          })
        );
      }

      setState((curr) => ({ ...curr, document: undefined, status: "generating", errors: [] }));
      return generateDocumentsSync({
        isPreview: isPreview,
        distributions: [],
        templates: [
          {
            templateVersionId: templateVersionId,
            key: templateVersionId,
          },
        ],
        contexts: [
          {
            key: templateVersionId,
            data: formValues,
            documents: [
              {
                templateKey: templateVersionId,
                documentKey: templateVersionId,
                distributions: [],
                plugins: [],
                parentDocumentVersionId:
                  generationContext && previousConfiguration
                    ? previousConfiguration.parentDocumentVersionId
                    : undefined,
              },
            ],
          },
        ],
      })
        .then(({ docs, documentId, documentVersionId }) => {
          const documentKey = Object.keys(docs)[0];

          if (documentId) {
            organizationNavigate(organizationLinkTemplates.documentId(documentId, "data"));
            return {
              success: true,
              blob: docs[documentKey],
              documentId,
              documentVersionId,
            };
          } else {
            setState((curr) => ({
              ...curr,
              status: "idle",
              step: "document-viewer",
              document: docs[documentKey],
            }));
            return {
              success: true,
              blob: docs[documentKey],
              documentId,
              documentVersionId,
            };
          }
        })
        .catch((ex) => {
          setState((curr) => ({
            ...curr,
            status: "idle",
            step: "summary",
            errors: ex.response?.data?.errors,
          }));
          return {
            success: false,
            errors: ex.response?.data?.errors,
          };
        });
    },
    [
      generateDocumentsSync,
      generationContext,
      previousConfiguration,
      templateVersionId,
      organizationNavigate,
    ]
  );

  const handleDismiss = useCallback((index: number) => {
    setState((prev) => {
      if (prev.errors && prev.errors[index] !== undefined) {
        const leftErrors = [...prev.errors];
        return { ...prev, errors: leftErrors.splice(index, 1) };
      }
      return prev;
    });
  }, []);

  const getTemplateContextSample = useCallback(async () => {
    if (!templateVersionId) {
      return {};
    }
    const response = await getTemplateContextSampleRequest([templateVersionId]);
    return response.requestSample.contexts[0].data;
  }, [getTemplateContextSampleRequest, templateVersionId]);

  useEffect(() => {
    handleFetchTemplateInfo();
  }, [handleFetchTemplateInfo]);
  const templateName = state.template?.informationDefinition?.name;

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: "/" },
      {
        type: "url",
        name: resources.generate,
        url: organizationLinkTemplates.templateList(true),
      },
      { type: "text", name: templateName ?? "" },
    ];
  }, [resources.generate, resources.home, templateName]);

  const fetchWordAsPdf = useCallback(async () => {
    if (!state.document) {
      return { pdfDataUri: undefined };
    }
    const base64 = await blobToBase64(state.document);
    return await getWordAsPdf(base64, undefined);
  }, [state.document, getWordAsPdf]);

  const { data: pdfResponse } = useOrganizationQuery(
    `generate-template-get-word-as-pdf/${state.document ? templateVersionId : undefined}`,
    fetchWordAsPdf
  );

  const documentContext = useMemo(() => {
    if (extractedContext) {
      return extractedContext;
    } else {
      return initialContext;
    }
  }, [extractedContext, initialContext]);

  return (
    <Page
      breadCrumbEntries={breadCrumbEntries}
      breadCrumbExtraComponent={
        <Box width={200}>
          <ProgressBar
            percentage={progress}
            height={30}
            backgroundColor={calculateColor(colors.neutral500)}
            progressColor={calculateColor(colors.primary)}
            labelColor={calculateColor(colors.secondary)}
          />
        </Box>
      }
    >
      <LookupDocument
        onChange={setDocumentLoadingState}
        setDocumentSearchOpen={setDocumentSearchOpen}
        isDocumentSearchOpen={isDocumentSearchOpen}
      />
      {state.template && !documentLoadingState.isLoading && (
        <FormControllerProvider
          contextDefinition={state.template.contextDefinition}
          wizardDefinition={state.template.wizardDefinition}
          dataLinkMappings={state.template.dataLinkMappings}
          executeDataLink={execDataLink}
          initialContext={documentContext}
          moment={moment}
        >
          <ContextStateUpdater onUpdate={onChange} />
          <GenerationWizardBoundary
            generating={state.status === "generating"}
            generateOnFinish={true}
            templateVersionId={templateVersionId}
            dataLinkMappings={state.template.dataLinkMappings || []}
            DocumentViewerComponentImplementation={DocumentViewer}
            executeDataLink={execDataLink}
            generateDocument={generateDocument}
            documentBinary={pdfResponse?.pdfDataUri}
            navigate={organizationNavigate}
            connectors={[]}
            connectorDataLinks={[]}
            dataLinks={[]}
            i18n={i18n}
            onFireConnector={() =>
              new Promise((resolve) =>
                resolve({
                  formValues: null,
                  formErrors: null,
                  groupValues: null,
                })
              )
            }
            moment={moment}
            onFinish={onFinish}
            onCancel={onCancel}
            cancelLabel={undefined}
            resources={resources}
            getTemplateContextSample={getTemplateContextSample}
            onSearch={setDocumentSearchOpen}
          />
        </FormControllerProvider>
      )}
      {(state.errors || []).map((error, i) => (
        <NotificationMessage
          key={i}
          type="error"
          message={error.message}
          position="bottom-left"
          handleDismiss={() => handleDismiss(i)}
        />
      ))}
    </Page>
  );
};

export default GenerationWizardPage;
