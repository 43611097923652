import { Box, Text, useColors } from "../";
import { useEffect, useState } from "react";

type Props = {
  readOnly?: boolean;
  height?: string;
  selected: number;
  onSelectThumbnail: (index: number) => void;
  images: Array<Blob | string>;
  onDelete: (index: number) => void;
  onMove: (index: number, direction: "up" | "bottom") => void;
  onRotate: (index: number) => void;
};

type IconProps = {
  onClick?: () => void;
};

const style = { __style: { cursor: "pointer" } };
export function SidePanel({
  readOnly,
  height,
  selected,
  onSelectThumbnail,
  images,
  onDelete,
  onMove,
  onRotate,
}: Props) {
  const { colors } = useColors();
  const [thumbnailSize, setSize] = useState<Array<{ width: number; height: number }>>([]);

  const calculateThumbnailSize = (
    imageBlob: Blob | string,
    maxSize: number
  ): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        let thumbWidth = width;
        let thumbHeight = height;

        if (width > height && width > maxSize) {
          thumbWidth = maxSize;
          thumbHeight = Math.floor((height / width) * maxSize);
        } else if (height > width && height > maxSize) {
          thumbHeight = maxSize;
          thumbWidth = Math.floor((width / height) * maxSize);
        }

        resolve({ width: thumbWidth, height: thumbHeight });
      };
      img.onerror = reject;
      img.src = typeof imageBlob === "string" ? imageBlob : URL.createObjectURL(imageBlob);
    });
  };

  useEffect(() => {
    images.forEach((image, i) => {
      calculateThumbnailSize(image, 150).then((size) => {
        const sizes = thumbnailSize;
        sizes[i] = size;
        setSize(sizes);
      });
    });
  }, [images, thumbnailSize]);

  return (
    <Box overflow="auto" height={height} width={230} paddingX={5} color={colors.neutral200}>
      <Box
        borderStyle="lg"
        borderRadius={4}
        height={30}
        alignItems="center"
        marginTop={1}
        marginBottom={3}
        color={colors.neutral300}
        display="flex"
        justifyContent="center"
      >
        <ThumbnailSVGIcon />
      </Box>
      {images.map((image, i) => {
        return (
          <Box marginTop={8} key={i}>
            <Box
              dangerouslySetInlineStyle={style}
              width={"100%"}
              display="flex"
              justifyContent="center"
            >
              <img
                style={{
                  border: selected === i ? "2px #3183c8 solid" : "",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={typeof image === "string" ? image : URL.createObjectURL(image)}
                alt={""}
                color={""}
                onClick={() => onSelectThumbnail(i)}
              />
            </Box>
            <Box marginTop={4}>
              <Text align="center">{i + 1}</Text>
            </Box>
            {!readOnly && (
              <Box
                paddingX={5}
                marginTop={1}
                dangerouslySetInlineStyle={style}
                display="flex"
                justifyContent="between"
              >
                <UpSVGIcon onClick={() => onMove(i, "up")} />
                <DownSVGIcon onClick={() => onMove(i, "bottom")} />
                <RotateSVGIcon onClick={() => onRotate(i)} />
                <DeleteSVGIcon onClick={() => onDelete(i)} />
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

const ThumbnailSVGIcon = () => {
  return (
    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        style={{ fill: "#abb0c4" }}
        d="M16.5,5.66H3.81A1.81,1.81,0,0,0,2,7.47V20.15A1.82,1.82,0,0,0,3.81,22H16.5a1.82,1.82,0,0,0,1.81-1.82V7.47A1.81,1.81,0,0,0,16.5,5.66ZM3.81,20.15V7.47H16.5V20.15Zm6.34-5.43H5.62V9.28h4.53ZM12,16.53h2.71v1.81H5.62V16.53H12Zm0-7.25h2.71v1.81H12Zm0,3.63h2.71v1.81H12ZM22,3.85V12H20.19V3.85H8.41V2H20.19A1.82,1.82,0,0,1,22,3.85Z"
      ></path>
    </svg>
  );
};

const UpSVGIcon = ({ onClick }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10.5L12 3L6 10.5H10.5V21H13.5V10.5H18Z"
        fill="#868E96"
      />
    </svg>
  );
};
const DownSVGIcon = ({ onClick }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 13.5L12 21L6 13.5H10.5V3H13.5V13.5H18Z"
        fill="#868E96"
      />
    </svg>
  );
};
const RotateSVGIcon = ({ onClick }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="cls-1"
        style={{ fill: "#abb0c4" }}
        d="M22,7.83,18.67,12,15.33,7.83h2.43a5,5,0,0,0-4.93-4.16V2a6.64,6.64,0,0,1,6.61,5.83ZM14,12.2s0,0,0,.05V20.5A1.5,1.5,0,0,1,12.5,22h-9A1.5,1.5,0,0,1,2,20.5V8.5A1.5,1.5,0,0,1,3.5,7H8.8a.65.65,0,0,1,.19,0l.07,0a.86.86,0,0,1,.22.15l4.5,4.5a.66.66,0,0,1,.15.22s0,.05,0,.07A.65.65,0,0,1,14,12.2ZM12,20V13H8.75A.76.76,0,0,1,8,12.25V9H4V20Z"
      ></path>
    </svg>
  );
};
const DeleteSVGIcon = ({ onClick }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="cls-1"
        style={{ fill: "#abb0c4" }}
        d="M18,6H17V4a2,2,0,0,0-2-2H9A2,2,0,0,0,7,4V6H3V8H5V20a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V8h2V6ZM9,4h6V6H9ZM7,20V8H17V20Zm6-10h2v8H13ZM9,10h2v8H9Z"
      ></path>
    </svg>
  );
};
