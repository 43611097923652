import {
  Box,
  BoxWithRef,
  Checkbox,
  DataElementInput,
  Label,
  NumberField,
  SelectList,
  Switch,
  Text,
  TextField,
  useColors,
  useComponentBreakpoint,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { isValueListContextField, sanitizeFieldName, shouldNever } from "@prodoctivity/shared";
import type {
  ContextField,
  ControlSize,
  DataElement,
  DictionaryDataType,
  InputControlValueType,
  DictionaryList,
  InputType,
} from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";

import { IconButton } from "@prodoctivity/design-system/components/Icon";
import { NumberOrNumberArrayWithCatch$Schema } from "@prodoctivity/shared";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../../../hooks/useOrganizationQuery";
import { useServices } from "../../../../hooks/useServices";

type InputTabProps = {
  element: DataElement;
  onChange: (dataElement: DataElement) => void;
  hasSampleValue: boolean;
};

const availableInputOptions = (dictionaryDataType: DictionaryDataType): InputType[] => {
  let options: InputType[] = [];
  switch (dictionaryDataType) {
    case "Alphanumeric": {
      options = ["TextBox", "TextArea"];
      break;
    }
    case "Boolean": {
      options = ["Checkbox", "Switch"];
      break;
    }
    case "Date": {
      options = ["DateTimePicker"];
      break;
    }
    case "DateTime": {
      options = ["DateTimePicker"];
      break;
    }
    case "DecimalNumber": {
      options = ["TextBox"];
      break;
    }
    case "ElectronicSignature": {
      options = ["Signature"];
      break;
    }
    case "Image": {
      options = ["ImageUpload"];
      break;
    }
    case "IntegerNumber": {
      options = ["TextBox"];
      break;
    }
    case "List": {
      options = ["Dropdown", "Checkbox", "Radio"];
      break;
    }
    case "Time": {
      options = ["TimePicker"];
      break;
    }
  }
  return options;
};

export const InputTab: FunctionComponent<InputTabProps> = ({
  element,
  onChange,
  hasSampleValue,
}) => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();

  const [isAllowMultipleOpen, setIsAllowMultipleOpen] = useState(
    (element && element.maxOccurs !== undefined && element.maxOccurs >= 2) ||
      (element && element.minOccurs !== undefined && element.minOccurs >= 2)
  );

  const setIsUnlimited = useCallback(
    (val: boolean) => {
      if (element.dataType !== "Logical") {
        onChange({ ...element, maxOccurs: val ? 9999 : element.minOccurs ? element.minOccurs : 1 });
      }
    },
    [onChange, element]
  );

  const isUnlimited = useMemo(() => {
    return !element.maxOccurs || element.maxOccurs >= 9999;
  }, [element]);

  const { getInputMasks, getPaginatedOrganizationDictionaryLists: getOrganizationDictionaryLists } =
    useServices();

  const getInputMaskList = useCallback(() => {
    const inputMaskList = getInputMasks();
    return inputMaskList;
  }, [getInputMasks]);

  const getDictionaryLists = useCallback(async () => {
    let pageNumber = 0;
    const requestedPageLength = "100";
    let pageLength = 100;

    const result: DictionaryList<string>[] = [];
    do {
      const currentPage = await getOrganizationDictionaryLists(pageNumber, requestedPageLength);
      (currentPage.dictionaryLists || []).forEach((dl) => result.push(dl));
      pageLength = currentPage.pageLength;
      pageNumber += 1;
    } while (pageLength >= parseInt(requestedPageLength));

    result.sort((a, b) => a.listSchema.name.localeCompare(b.listSchema.name));

    const lists = result.reduce((acc: Record<string, DictionaryList<string>>, next) => {
      if (!acc[next.listSchema.name]) {
        acc[next.listSchema.name] = next;
      }
      return acc;
    }, {});

    return Object.values(lists);
  }, [getOrganizationDictionaryLists]);

  const { data: inputMaskData } = useOrganizationQuery(`/input-masks/`, getInputMaskList);
  const { data: dictionaryLists } = useOrganizationQuery(`/dictionary-lists`, getDictionaryLists);

  const contextField: ContextField = useMemo(() => {
    const name = sanitizeFieldName(element.humanName, true);
    const result: ContextField = {
      name,
      fullPath: name,
      humanName: element.humanName,
      properties: { ...element },
    };

    const properties = result.properties;

    if (isValueListContextField(properties)) {
      const foundList = (dictionaryLists || []).find(
        (l) => l.listSchema.name === element.dictionaryListName
      );

      if (foundList) {
        properties.valueList = foundList.items.map((item) => {
          return {
            label: item.label,
            value: item.key,
          };
        });
      }
    }

    return result;
  }, [dictionaryLists, element]);

  const setInputMask = useCallback(
    (value: string | undefined) => {
      if (
        element.dataType === "Alphanumeric" &&
        (element.inputType === "TextBox" || element.inputType === "Default")
      ) {
        onChange({
          ...element,
          inputMask: value,
        });
      }
    },
    [element, onChange]
  );

  const clearInputMask = useCallback(() => {
    setInputMask(undefined);
  }, [setInputMask]);

  const onInputMaskListChange = useCallback(
    (args: { value: string }) => {
      const { value } = args;
      if (
        element.dataType === "Alphanumeric" &&
        (element.inputType === "TextBox" || element.inputType === "Default")
      ) {
        onChange({
          ...element,
          inputMask: value,
        });
      }
    },
    [element, onChange]
  );

  const { inputMaskList, inputMask } = useMemo(() => {
    if (
      element.dataType === "Alphanumeric" &&
      (element.inputType === "TextBox" || element.inputType === "Default")
    ) {
      const result = (inputMaskData && inputMaskData.inputMasks ? inputMaskData.inputMasks : [])
        .filter((im) => {
          return im.dataType === element.dataType;
        })
        .map((im) => {
          return {
            label: im.description,
            value: im.pattern,
          };
        });

      const found = result.find((item) => item.value === element.inputMask);
      if (found) {
        return {
          inputMaskList: result,
          inputMask: element.inputMask,
        };
      } else {
        return {
          inputMaskList: [{ label: resources.custom, value: element.inputMask || "" }, ...result],
          inputMask: element.inputMask || "",
        };
      }
    } else {
      return {
        inputMaskList: [],
        inputMask: "",
      };
    }
  }, [element, inputMaskData, resources.custom]);

  const defaultTypeValue: InputControlValueType = useMemo(() => {
    const dataType = element.dataType;
    switch (dataType) {
      case "Alphanumeric": {
        return {
          dataType: element.dataType,
          value: element.defaultValue,
        };
      }
      case "Currency":
      case "Numeric": {
        return {
          dataType: element.dataType,
          value: NumberOrNumberArrayWithCatch$Schema.parse(element.defaultValue),
        };
      }
      case "Logical": {
        return {
          dataType: element.dataType,
          value: typeof element.defaultValue === "boolean" ? element.defaultValue : undefined,
        };
      }
      case "Date":
      case "DateTime": {
        return {
          dataType: element.dataType,
          value: element.defaultValue,
        };
      }
      case "Time": {
        return {
          dataType: element.dataType,
          value: element.defaultValue,
        };
      }
      case "Image": {
        return {
          dataType: element.dataType,
          value: typeof element.defaultValue === "string" ? element.defaultValue : undefined,
        };
      }
      default:
        shouldNever(dataType);
        throw new Error("Cannot happen");
    }
  }, [element.dataType, element.defaultValue]);

  const sampleTypeValue: InputControlValueType = useMemo(() => {
    const dataType = element.dataType;
    switch (dataType) {
      case "Alphanumeric": {
        return {
          dataType: element.dataType,
          value: element.sampleValue,
        };
      }
      case "Currency":
      case "Numeric": {
        return {
          dataType: element.dataType,
          value: element.sampleValue,
        };
      }
      case "Logical": {
        return {
          dataType: element.dataType,
          value: element.sampleValue,
        };
      }
      case "Date":
      case "DateTime": {
        return {
          dataType: element.dataType,
          value: element.sampleValue,
        };
      }
      case "Time": {
        return {
          dataType: element.dataType,
          value: element.sampleValue,
        };
      }
      case "Image": {
        return {
          dataType: element.dataType,
          value: element.sampleValue,
        };
      }
      default:
        shouldNever(dataType);
        throw new Error("Cannot happen");
    }
  }, [element.dataType, element.sampleValue]);

  const ControlSizeOptions = useMemo(() => {
    const controlSizeValues = [
      resources.dataTypeValues.none,
      resources.small,
      resources.normal,
      resources.large,
    ];

    return controlSizeValues.map((value) => {
      let translatedValue = "None";

      switch (value) {
        case resources.small:
          translatedValue = "Small";
          break;
        case resources.normal:
          translatedValue = "Normal";
          break;
        case resources.large:
          translatedValue = "Large";
          break;
      }

      return { label: value, value: translatedValue };
    });
  }, [resources.dataTypeValues.none, resources.large, resources.normal, resources.small]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const { width: containerWidth } = useComponentBreakpoint(containerRef, "small");
  const flexBasis = ((containerWidth || 300) - 100) / 2;
  const { breakpoint } = useDesignBreakpoint();
  return (
    <BoxWithRef ref={containerRef} display="flex" direction="column" paddingY={4} paddingX={4}>
      {/* Form Start */}
      <Box
        display="flex"
        direction={breakpoint === "small" ? "column" : "row"}
        maxWidth={containerWidth}
        wrap={true}
        gap={4}
      >
        {/* Default Control Start */}
        <Box display="flex" direction="column" flexBasis={flexBasis}>
          <Box
            color={colors.neutral200}
            paddingX={4}
            paddingY={3}
            display="flex"
            direction="row"
            justifyContent="between"
          >
            <Box display="flex" alignItems="center" direction="row">
              <Box>
                <Text weight="bold">{resources.dataDictionary.defaultControl}</Text>
              </Box>
            </Box>
          </Box>
          <Box margin={4}>
            <SelectList
              onChange={({ value }) => {
                onChange({ ...element, inputType: value as any });
              }}
              id={"defaultControlList"}
              options={availableInputOptions(element.dictionaryDataType).map((value) => {
                return { label: value, value: value };
              })}
              value={element.inputType}
            />
          </Box>
        </Box>
        {/* Default Control End */}

        {/* Control Size Start */}
        <Box display="flex" direction="column" flexBasis={flexBasis}>
          <Box
            color={colors.neutral200}
            paddingX={4}
            paddingY={3}
            display="flex"
            direction="row"
            justifyContent="between"
          >
            <Box display="flex" alignItems="center" direction="row">
              <Box>
                <Text weight="bold">{resources.controlSize}</Text>
              </Box>
            </Box>
          </Box>
          <Box margin={4}>
            <SelectList
              onChange={({ value }) => {
                onChange({
                  ...element,
                  controlSize: value as ControlSize,
                });
              }}
              id={"ControlSize"}
              options={ControlSizeOptions}
              value={element.controlSize}
            />
          </Box>
        </Box>
        {/* Control Size End */}

        {/* Example Start */}
        <Box display="flex" direction="column" flexBasis={2 * flexBasis}>
          <Box
            color={colors.neutral200}
            paddingX={4}
            paddingY={3}
            display="flex"
            direction="row"
            justifyContent="between"
          >
            <Box display="flex" alignItems="center" direction="row">
              <Box>
                <Text weight="bold">{resources.dataDictionary.sampleValue}</Text>
              </Box>
            </Box>
          </Box>
          <Box
            paddingX={4}
            paddingY={2}
            width={
              element.controlSize === "Large"
                ? "100%"
                : element.controlSize === "Normal"
                ? "66%"
                : "33%"
            }
          >
            <DataElementInput
              componentBreakpoint={"small"}
              contextField={contextField}
              purpose="real-time"
              error={hasSampleValue ? undefined : resources.required}
              onChange={(typeValue) => {
                onChange({
                  ...element,
                  sampleValue: typeValue.value,
                } as any);
              }}
              typeValue={sampleTypeValue}
              moment={moment}
              getContextValueAsString={() => undefined}
              instanceFullPath={`${contextField.fullPath}sampleValue[0]`}
              resources={resources}
              disabled={false}
            />
          </Box>
        </Box>
        {/* Example End */}

        {/* Default Value Start */}
        <Box display="flex" direction="column" flexBasis={2 * flexBasis} paddingY={1}>
          <Box
            color={colors.neutral200}
            paddingX={4}
            paddingY={3}
            display="flex"
            direction="row"
            justifyContent="between"
          >
            <Box display="flex" alignItems="center" direction="row">
              <Box>
                <Text weight="bold">{resources.dataDictionary.defaultValue}</Text>
              </Box>
            </Box>
          </Box>
          <Box
            paddingX={4}
            paddingY={2}
            width={
              element.controlSize === "Large"
                ? "100%"
                : element.controlSize === "Normal"
                ? "66%"
                : "33%"
            }
          >
            <DataElementInput
              componentBreakpoint={"small"}
              contextField={contextField}
              error={hasSampleValue ? undefined : resources.required}
              onChange={(typeValue) => {
                onChange({
                  ...element,
                  defaultValue: typeValue.value,
                } as any);
              }}
              typeValue={defaultTypeValue}
              moment={moment}
              getContextValueAsString={() => undefined}
              instanceFullPath={`${contextField.fullPath}defaultValue[0]`}
              resources={resources}
              disabled={false}
            />
          </Box>
        </Box>
        {/* Default Value End */}

        {/* Multiple Instances Start */}
        {element && element.dataType !== "Logical" && element.dataType !== "Image" ? (
          <Box display="flex" direction="column" flex="shrink" flexBasis={flexBasis}>
            <Box
              color={colors.neutral200}
              marginTop={4}
              paddingX={6}
              paddingY={2}
              display="flex"
              direction="row"
              justifyContent="between"
              gap={4}
            >
              <Box display="flex" alignItems="center" direction="row">
                <Box>
                  <Text weight="bold">{resources.dataDictionary.allowsMultipleInstances}</Text>
                </Box>
              </Box>
              <Switch
                id="allowMultipleSwitch"
                onChange={({ value }) => {
                  if (value) {
                    onChange({
                      ...element,
                      minOccurs:
                        !element.minOccurs || element.minOccurs <= 0 ? 1 : element.minOccurs,
                      maxOccurs:
                        !element.maxOccurs || element.maxOccurs <= 1 ? 9999 : element.maxOccurs,
                    });

                    setIsAllowMultipleOpen(true);
                  } else {
                    onChange({
                      ...element,
                      minOccurs: 0,
                      maxOccurs: 1,
                    });

                    setIsAllowMultipleOpen(false);
                  }
                }}
                switched={isAllowMultipleOpen}
              />
            </Box>
            {isAllowMultipleOpen && (
              <Box display="flex" direction="row">
                <Box margin={4} width="50%">
                  <Box marginBottom={2}>
                    <Label htmlFor="minOccurs">
                      <Text weight="bold" size="200">
                        {resources.minimum}
                      </Text>
                    </Label>
                  </Box>
                  <NumberField
                    id="minOccurs"
                    onChange={({ value }) => {
                      if (element) {
                        onChange({ ...element, minOccurs: value });
                      }
                    }}
                    onBlur={({ value }) => {
                      if (element) {
                        let newValue = value;
                        let maxOccurs = element.maxOccurs;

                        if (newValue && element.maxOccurs && newValue > element.maxOccurs) {
                          maxOccurs = newValue;
                        }

                        if (element.minOccurs !== undefined && element.minOccurs > 9999) {
                          newValue = 9999;
                          maxOccurs = newValue;
                        }
                        onChange({ ...element, minOccurs: newValue, maxOccurs });
                      }
                    }}
                    placeholder="#"
                    value={element.minOccurs}
                    min={0}
                    max={9999}
                  />
                </Box>
                <Box margin={4} width="50%">
                  <Box marginBottom={2} display="flex" justifyContent="between">
                    <Label htmlFor="maxOccurs">
                      <Box>
                        <Text weight="bold" size="200">
                          {resources.maximum}
                        </Text>
                      </Box>
                    </Label>
                    <Checkbox
                      id="isUnlimitedCheck"
                      onChange={({ checked }) => {
                        setIsUnlimited(checked);
                      }}
                      label={resources.unlimited}
                      size="sm"
                      checked={isUnlimited}
                    />
                  </Box>
                  {isUnlimited ? (
                    <TextField
                      id="unlimitedTextField"
                      onChange={console.log}
                      value="Unlimited"
                      disabled
                    />
                  ) : (
                    <NumberField
                      id="maxOccurs"
                      onChange={({ value }) => {
                        if (element) {
                          onChange({ ...element, maxOccurs: value });
                        }
                      }}
                      value={element.maxOccurs}
                      placeholder="#"
                      onBlur={({ value }) => {
                        if (element) {
                          let newValue = value;
                          if (isUnlimited) {
                            newValue = 9999;
                          } else {
                            if (
                              !newValue ||
                              (newValue && element.minOccurs && newValue < element.minOccurs)
                            ) {
                              if (element.minOccurs === 1) {
                                newValue = 2;
                              } else {
                                newValue = element.minOccurs;
                              }
                            }
                          }
                          onChange({ ...element, maxOccurs: newValue });
                        }
                      }}
                      min={0}
                      max={9999}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        ) : null}

        {/* Multiple Instances End */}

        {/* Capture Mask Start */}
        {element && element.dataType === "Alphanumeric" && element.inputType === "TextBox" ? (
          <Box marginTop={4} display="flex" direction="column" flexBasis={flexBasis}>
            <Box
              color={colors.neutral200}
              paddingX={4}
              paddingY={3}
              display="flex"
              direction="row"
              justifyContent="between"
            >
              <Box flex="grow">
                <Text weight="bold">{resources.dataDictionary.inputMask}</Text>
              </Box>
              <Box flex="shrink">
                <IconButton
                  accessibilityLabel={resources.clear}
                  color={colors.black600}
                  onClick={clearInputMask}
                  icon="close"
                />
              </Box>
            </Box>
            <Box margin={4} display="flex" gap={2}>
              <Box width={"50%"}>
                <Box marginBottom={2} display="flex" justifyContent="between">
                  <Label htmlFor="inputMask">
                    <Text weight="bold" size="200">
                      {resources.dataDictionary.maskValue}
                    </Text>
                  </Label>
                </Box>
                <TextField
                  id="inputMask"
                  onChange={({ value }) => {
                    setInputMask(value);
                  }}
                  placeholder={resources.dataDictionary.inputMask}
                  value={
                    element.dataType === "Alphanumeric" &&
                    (element.inputType === "TextBox" || element.inputType === "Default")
                      ? element.inputMask || ""
                      : ""
                  }
                />
              </Box>
              <Box width={"50%"}>
                <Box marginBottom={2}>
                  <Checkbox
                    id="keepAllMasksCharsCheck"
                    onChange={({ checked }) => {
                      if (
                        element.dataType === "Alphanumeric" &&
                        (element.inputType === "TextBox" || element.inputType === "Default")
                      ) {
                        onChange({
                          ...element,
                          inputMaskStoreFixedChars: checked,
                        });
                      }
                    }}
                    label={resources.dataDictionary.keepAllChars}
                    size="sm"
                    checked={
                      element.dataType === "Alphanumeric" &&
                      (element.inputType === "TextBox" || element.inputType === "Default") &&
                      !!element.inputMaskStoreFixedChars
                    }
                  />
                </Box>
                <SelectList
                  id="inputMaskList"
                  size="lg"
                  onChange={onInputMaskListChange}
                  options={inputMaskList}
                  value={inputMask}
                />
              </Box>
            </Box>
          </Box>
        ) : null}

        {/* Capture Mask End */}

        {/* Character Length Start */}
        {element &&
        element.dataType === "Alphanumeric" &&
        !(element.inputType === "TextBox" && element.inputMask) ? (
          <Box marginTop={4} display="flex" direction="column" flexBasis={flexBasis}>
            <Box color={colors.neutral200} paddingX={6} paddingY={3} display="flex" direction="row">
              <Box>
                <Text weight="bold">{resources.dataDictionary.characterLength}</Text>
              </Box>
            </Box>
            <Box display="flex" direction="row">
              <Box margin={4} width="50%">
                <Box marginBottom={2}>
                  <Label htmlFor="maxLength">
                    <Text weight="bold" size="200">
                      {resources.dataDictionary.maxLength}
                    </Text>
                  </Label>
                </Box>
                <NumberField
                  id="maxLength"
                  onChange={({ value }) => {
                    if (
                      element.dataType === "Alphanumeric" &&
                      (element.inputType === "Default" ||
                        element.inputType === "TextBox" ||
                        element.inputType === "TextArea")
                    ) {
                      onChange({ ...element, maxLength: value });
                    }
                  }}
                  value={
                    element.dataType === "Alphanumeric" &&
                    (element.inputType === "Default" ||
                      element.inputType === "TextBox" ||
                      element.inputType === "TextArea")
                      ? element.maxLength
                      : 100
                  }
                  placeholder=""
                  onBlur={({ value }) => {
                    if (
                      element.dataType === "Alphanumeric" &&
                      (element.inputType === "Default" ||
                        element.inputType === "TextBox" ||
                        element.inputType === "TextArea")
                    ) {
                      let newValue = value;
                      if (element.maxLength !== undefined && element.maxLength < 0) {
                        newValue = 1;
                      }
                      if (element.maxLength !== undefined && element.maxLength > 9999) {
                        newValue = 9999;
                      }
                      onChange({ ...element, maxLength: newValue });
                    }
                  }}
                  min={0}
                  max={9999}
                />
              </Box>

              <Box margin={4} width="50%">
                {element && element.inputType === "TextArea" ? (
                  <>
                    <Box marginBottom={2}>
                      <Label htmlFor="numberOfLines">
                        <Text weight="bold" size="200">
                          {resources.dataDictionary.maxLines}
                        </Text>
                      </Label>
                    </Box>
                    <NumberField
                      id="numberOfLines"
                      onChange={({ value }) => {
                        if (
                          element.dataType === "Alphanumeric" &&
                          element.inputType === "TextArea"
                        ) {
                          onChange({ ...element, lineCount: value });
                        }
                      }}
                      value={
                        element &&
                        element.input &&
                        element.dataType === "Alphanumeric" &&
                        element.inputType === "TextArea"
                          ? element.lineCount
                          : undefined
                      }
                      onBlur={({ value }) => {
                        if (
                          element &&
                          element.input &&
                          element.dataType === "Alphanumeric" &&
                          element.inputType === "TextArea"
                        ) {
                          let newValue = value;
                          if (element.lineCount !== undefined && element.lineCount < 0) {
                            newValue = undefined;
                          }
                          if (element.lineCount !== undefined && element.lineCount > 9999) {
                            newValue = 9999;
                          }
                          onChange({
                            ...element,
                            lineCount: newValue,
                          });
                        }
                      }}
                      min={0}
                      max={9999}
                    />{" "}
                  </>
                ) : null}
              </Box>
            </Box>
          </Box>
        ) : null}
        {/* Character Length End */}
      </Box>

      {/* Form End */}
    </BoxWithRef>
  );
};
