import { Box, Button, OverlayPanel, Text, useColors } from "@prodoctivity/design-system";
import type {
  LogicalExpression,
  TemplateDependency,
  TemplateWizardDefinition,
} from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback, useMemo } from "react";
import { StateFieldRelevance, useFieldRelevance } from "../hooks";

import { Dependencies } from "@prodoctivity/prodoctivity-form-v5/src/components/Dependencies";
import { deepCopy } from "@prodoctivity/shared";

export const FieldRelevanceSheet: FunctionComponent<StateFieldRelevance> = ({
  contextDefinition,
  wizardDefinition,
  updateTemplateWizardDefinition,
  onCloseSheet,
}) => {
  const { colors } = useColors();
  const { resources, moment, state } = useFieldRelevance({
    contextDefinition: contextDefinition,
    formDefinition: undefined,
    wizardDefinition: wizardDefinition,
    updateTemplateWizardDefinition: updateTemplateWizardDefinition,
    onCloseSheet: onCloseSheet,
  });

  const tI18n = useCallback(
    (k: string) => {
      const d: Record<string, unknown> = resources;
      const spl = k.split(".");
      const lastKey = spl[spl.length - 1];
      const container = spl.slice(0, spl.length - 1).reduce((x: any, next) => {
        return x[next];
      }, d);
      const r: string = container[lastKey] || lastKey;

      return r;
    },
    [resources]
  );

  const defaultLogicalExpression: LogicalExpression = useMemo(() => {
    return {
      isNegative: false,
      operatorType: "equals",
      source: wizardDefinition?.pages[0].sections[0].fields[0].label ?? " ",
      target: "",
      summaryFunction: "valueOf",
    };
  }, [wizardDefinition?.pages]);

  const defaultWizardDefinition = useMemo(() => {
    return {
      defaultPageName: "",
      defaultSectionName: "",
      pages: [],
      dependencies: [],
      inferredDependencies: [],
    };
  }, []);

  const addNewDependency = useCallback(() => {
    const newWizardDefinition: TemplateWizardDefinition = wizardDefinition
      ? { ...wizardDefinition }
      : defaultWizardDefinition;
    if (!newWizardDefinition.dependencies) {
      newWizardDefinition.dependencies = [];
    }

    newWizardDefinition.dependencies.push({
      name: "",
      expression: defaultLogicalExpression,
      items: [],
    });

    updateTemplateWizardDefinition(newWizardDefinition);
  }, [
    wizardDefinition,
    defaultLogicalExpression,
    defaultWizardDefinition,
    updateTemplateWizardDefinition,
  ]);

  const updateWizardWithDependencies = useCallback(
    (newDependencies: TemplateDependency[]) => {
      const newWizardDefinition: TemplateWizardDefinition = wizardDefinition
        ? { ...wizardDefinition }
        : defaultWizardDefinition;

      newWizardDefinition.dependencies = newDependencies;

      updateTemplateWizardDefinition(newWizardDefinition);
    },
    [wizardDefinition, defaultWizardDefinition, updateTemplateWizardDefinition]
  );

  const removeSelectedDependency = useCallback(
    (index: number) => {
      const newWizardDefinition = { ...wizardDefinition } as TemplateWizardDefinition;
      if (newWizardDefinition === undefined || newWizardDefinition.dependencies === undefined) {
        return;
      }

      if (index < 0 || index >= newWizardDefinition.dependencies.length) {
        console.warn("Invalid index provided");
        return;
      }

      newWizardDefinition.dependencies.splice(index, 1);

      updateTemplateWizardDefinition(newWizardDefinition);
    },
    [wizardDefinition, updateTemplateWizardDefinition]
  );

  const resetDependencies = useCallback(() => {
    const newWizardDefinition = deepCopy(wizardDefinition);
    if (
      newWizardDefinition === undefined ||
      newWizardDefinition.inferredDependencies === undefined ||
      !newWizardDefinition.inferredDependencies.length
    ) {
      return;
    }

    newWizardDefinition.dependencies = newWizardDefinition.inferredDependencies;

    updateTemplateWizardDefinition(newWizardDefinition);
  }, [updateTemplateWizardDefinition, wizardDefinition]);

  return (
    <OverlayPanel
      heading={resources.formDesigner.fieldRelevance}
      onDismiss={onCloseSheet}
      size="lg"
      accessibilityLabel={resources.formDesigner.fieldRelevance}
    >
      <Box display="flex" direction="column">
        <Box display="flex" direction="column" gap={4}>
          <Box display="flex" direction="column" width={"100%"}>
            <Text size="300" weight="bold">
              {resources.formDesigner.fieldRelevanceSetting.whatAreCondition}
            </Text>
          </Box>
          <Box
            display="flex"
            direction="row"
            alignItems="start"
            justifyContent="between"
            gap={4}
            width={"100%"}
          >
            <Box display="flex" direction="column" width={"100%"}>
              <Text size="300" color={colors.neutral900}>
                {resources.formDesigner.fieldRelevanceSetting.description}
              </Text>
            </Box>
            <Box display="flex" direction="column" gap={4}>
              <Box display="flex" direction="column" justifyContent="end" minWidth={180} gap={6}>
                {/* <Button onClick={addNewDependency} text={resources.addNew} iconEnd="add" /> */}
                <Button onClick={addNewDependency} text={resources.addNew} iconEnd="add-circle" />
              </Box>
              <Box display="flex" justifyContent="between" alignItems="center" minWidth={180}>
                <Button onClick={resetDependencies} text={resources.reset} color="gray" fullWidth />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box marginTop={5}>
          {wizardDefinition && state.formDefinition && (
            <>
              <Dependencies
                i18n={tI18n}
                formDependencies={wizardDefinition.dependencies}
                onDependencyUpdate={function (dependencies: TemplateDependency[]): void {
                  updateWizardWithDependencies(dependencies);
                }}
                addNewDependency={addNewDependency}
                removeSelectedDependency={removeSelectedDependency}
                moment={moment}
                colors={colors}
                contextDefinition={contextDefinition}
                wizardDefinition={wizardDefinition}
                resources={resources}
              />
            </>
          )}
        </Box>
      </Box>
    </OverlayPanel>
  );
};
