import { ColorConfig, Text, type TextSize } from "@prodoctivity/design-system";
import { FunctionComponent, useMemo } from "react";

import type { DateTime } from "@prodoctivity/types";
import { useAppTranslation } from "../../hooks/useAppTranslation";

type Props = {
  value: DateTime;
  size?: TextSize;
  showTime?: boolean;
  onlyTime?: boolean;
  ignoreFromNow?: boolean;
  color?: ColorConfig;
  weight?: "bold" | "normal" | undefined;
};

export const FancyDateTime: FunctionComponent<Props> = ({
  value,
  size = "300",
  showTime,
  onlyTime,
  ignoreFromNow,
  color,
  weight,
}) => {
  const { resources, moment } = useAppTranslation();
  const { title, text } = useMemo(() => {
    const date = new Date(value);
    const title = showTime ? date.toLocaleString() : date.toLocaleDateString();
    const text = showTime
      ? moment(date).format(onlyTime ? resources._timeFormat : resources._dateTimeFormatShort)
      : ignoreFromNow
      ? moment(date).format(resources._dateFormatShort)
      : moment(date).fromNow();

    return { title, text };
  }, [
    value,
    showTime,
    moment,
    resources._dateTimeFormatShort,
    resources._timeFormat,
    resources._dateFormatShort,
    onlyTime,
    ignoreFromNow,
  ]);
  return (
    <Text weight={weight} color={color} size={size} title={title}>
      {text}
    </Text>
  );
};
