import {
  Box,
  Divider,
  Icon,
  SearchField,
  Skeleton,
  Table,
  TapArea,
  Text,
  TextProps,
  Tooltip,
  TriStateSwitch,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { formatCurrency, formatNumber, range } from "@prodoctivity/shared";
import type { DataElement, DataElementFilter } from "@prodoctivity/shared/src/index-types";
import type {
  HttpGetPaginatedOrganizationDataElementsRequest,
  HttpGetPaginatedOrganizationDataElementsResponse,
} from "@prodoctivity/types";
import { FunctionComponent, SetStateAction, useCallback, useMemo, useState } from "react";
import { useCopyToClipboard } from "usehooks-ts";
import { BreadCrumbEntry } from "../../../../components/BreadCrumb";
import { Page } from "../../../../components/Layout/Page";
import { Pagination } from "../../../../components/Layout/Pagination";
import { SearchComponentWrapper } from "../../../../components/SearchComponentWrapper";
import { usePaginatedDataEndpoint } from "../../../../components/hooks";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../../link-templates";
import { useHasClipboard } from "../../../../utils";

import { FancyDateTime } from "../../../../components/Display/FancyDateTime";
import { NotificationMessage } from "../../../../components/NotificationMessage";
import { ClipboardSvgIcon } from "../../../../svg/ClipboardSvgIcon";

export function mapDataTypeToLabel(
  resources: ReturnType<typeof useAppTranslation>["resources"],
  dataType: DataElement["dataType"],
  dictionaryListName?: string
) {
  switch (dataType) {
    case "Numeric":
      return resources.dataTypeValues.integernumber;
    case "Alphanumeric":
      return dictionaryListName
        ? resources.dataTypeValues.list
        : resources.dataTypeValues.alphanumeric;
    case "Currency":
      return resources.dataTypeValues.decimalnumber;
    case "Date":
      return resources.dataTypeValues.date;
    case "Time":
      return resources.dataTypeValues.time;
    case "DateTime":
      return resources.dataTypeValues.datetime;
    case "Logical":
      return resources.dataTypeValues.boolean;
    case "Image":
      return resources.dataTypeValues.image;
    default:
      return resources.dataTypeValues.alphanumeric;
  }
}

const PAGINATION_OPTIONS: HttpGetPaginatedOrganizationDataElementsRequest["queryParameters"]["rowsPerPage"][] =
  ["15", "30", "100"];

export const DataElementListPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const {
    currentPage,
    isLoading,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    paginatedData,
    previousPage,
    refetch,
    rowsPerPage,
    setFilter,
    filter,
    setPageLength,
    totalRowCount,
  } = usePaginatedDataEndpoint<
    HttpGetPaginatedOrganizationDataElementsResponse["payload"],
    HttpGetPaginatedOrganizationDataElementsRequest["queryParameters"]["rowsPerPage"],
    DataElementFilter
  >(
    "15",
    { dataElementName: undefined, resultsAreClassDataElements: undefined },
    (services, currentPage, rowsPerPage, filter) => {
      return services.getPaginatedOrganizationDataElements(
        currentPage,
        rowsPerPage,
        filter.dataElementName,
        filter.resultsAreClassDataElements
      );
    },
    "data_elements_list_page"
  );

  const [_value, copyToClipboard] = useCopyToClipboard();
  const { data: hasClipboard } = useHasClipboard();

  const { breakpoint } = useDesignBreakpoint();
  const dataIsLoading = isLoading;
  const changeFilter = useCallback(
    (filter: string) => {
      setFilter((prev) => {
        return { ...prev, dataElementName: filter };
      });
    },
    [setFilter]
  );

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.dataDictionary.dictionary,
        url: organizationLinkTemplates.dataDictionary(),
      },
      { type: "text", name: resources.dataDictionary.dataElements },
    ];
  }, [
    resources.dataDictionary.dataElements,
    resources.dataDictionary.dictionary,
    resources.home,
    resources.settings,
  ]);

  const addNew = useCallback(() => {
    organizationNavigate("/settings/data-dictionary/data-elements/new");
  }, [organizationNavigate]);

  const [showNotification, setShowNotification] = useState(false);

  const handleCopyToClipBoard = useCallback(() => {
    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  }, [setShowNotification]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column" marginStart={undefined}>
        <SearchComponentWrapper
          addButtonLabel={resources.add}
          addButtonText={resources.add}
          buttonAction={addNew}
          componentHeaderText={resources.dataDictionary.dataElements}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="data_elements_filter"
          searchFieldLabel={resources.dataDictionary.searchElementsPlaceholder}
          setFilter={changeFilter}
          omitSearch={true}
        />
        <Box display="flex" paddingX={6} gap={2} alignItems="center">
          <Box display="flex" gap={2} direction="row" alignItems="center">
            <TriStateSwitch
              value={filter.resultsAreClassDataElements}
              onChange={(v) => {
                setFilter((prev) => {
                  return { ...prev, resultsAreClassDataElements: v };
                });
              }}
              onLabel={""}
              offLabel={""}
              offColor={colors.neutral500}
              undefinedLabel={resources.all}
              width={60}
            />
          </Box>
          <Text>
            {filter.resultsAreClassDataElements === undefined
              ? "Show all data elements"
              : filter.resultsAreClassDataElements === true
              ? "Show only class data elements"
              : "Show only child data elements"}
          </Text>
        </Box>
        <Box paddingX={2} paddingY={5} display="flex" direction="column">
          <Pagination<typeof rowsPerPage>
            id="data_elements_list_pagination_top"
            rowsLabel={`${resources.dataDictionary.dataElements}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.dataElements.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={PAGINATION_OPTIONS}
            totalRowCount={totalRowCount}
            extraComponent={
              <SearchField
                marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                marginEnd={2}
                accessibilityLabel={resources.dataDictionary.searchElementsPlaceholder}
                id="data_elements_filter"
                onChange={({ value }) => {
                  setFilter((prev) => {
                    return { ...prev, dataElementName: value };
                  });
                }}
              />
            }
          />
          <Divider direction="horizontal" />
        </Box>
        <Box
          marginStart={6}
          marginEnd={6}
          padding={2}
          borderStyle={breakpoint === "small" ? undefined : "raisedTopShadow"}
          borderRadius={breakpoint === "small" ? undefined : 4}
          width={undefined}
        >
          <Box
            display={breakpoint === "small" ? "flex" : undefined}
            wrap={true}
            width={breakpoint === "small" ? 350 : undefined}
          >
            {breakpoint === "small" ? (
              dataIsLoading ? (
                range(7).map((_, idx) => (
                  <Box
                    key={idx}
                    width={300}
                    height={200}
                    marginBottom={2}
                    color={colors.neutral300}
                  ></Box>
                ))
              ) : paginatedData && paginatedData.dataElements.length > 0 ? (
                paginatedData.dataElements.map((dataElement, index) => {
                  return (
                    <DataElementCard
                      key={`${dataElement.name}_${index}`}
                      dataElement={dataElement}
                      hasClipboard={hasClipboard}
                      copyToClipboard={copyToClipboard}
                      showNotification={showNotification}
                      setShowNotification={setShowNotification}
                      handleCopyToClipBoard={handleCopyToClipBoard}
                    />
                  );
                })
              ) : (
                <Table.Row key="no-elements">
                  <Table.Cell colSpan={5}>
                    <Box
                      borderRadius={4}
                      borderStyle="sm"
                      height={56}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      color={colors.neutral200}
                    >
                      <Text size="300">{resources.dataDictionary.noElements}</Text>
                    </Box>
                  </Table.Cell>
                </Table.Row>
              )
            ) : (
              <Table accessibilityLabel={resources.dataDictionary.dataElements}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Box paddingX={4}>
                        <Text size="200" weight="bold">
                          {resources.name.toUpperCase()}
                        </Text>
                      </Box>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Box>
                        <Text size="200" weight="bold">
                          {resources.dataType.toUpperCase()}
                        </Text>
                      </Box>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Box>
                        <Text size="200" weight="bold">
                          {resources.dataDictionary.sampleValue.toUpperCase()}
                        </Text>
                      </Box>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Box>
                        <Text size="200" weight="bold">
                          {resources.required.toUpperCase()}
                        </Text>
                      </Box>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Box>
                        <Text size="200" weight="bold">
                          {resources.unique.toUpperCase()}
                        </Text>
                      </Box>
                    </Table.HeaderCell>
                    {filter.resultsAreClassDataElements && (
                      <Table.HeaderCell>
                        <Box>
                          <Text size="200" weight="bold">
                            {resources.classDataElement.toUpperCase()}
                          </Text>
                        </Box>
                      </Table.HeaderCell>
                    )}
                    <Table.HeaderCell>
                      <Box>
                        <Text size="200" weight="bold">
                          {resources.actions.toUpperCase()}
                        </Text>
                      </Box>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {dataIsLoading ? (
                    range(7).map((_, idx) => <RowSkeleton key={idx} />)
                  ) : paginatedData && paginatedData.dataElements.length > 0 ? (
                    paginatedData.dataElements.map((dataElement, index) => {
                      return (
                        <Table.Row key={`${dataElement.name}_${index}`}>
                          <Table.Cell>
                            <Box marginStart={4} display={"flex"} justifyContent={"between"}>
                              <Box>
                                <Text
                                  ellipsisLength={32}
                                  weight="bold"
                                >{`${dataElement.humanName}`}</Text>
                              </Box>
                              <Box width={1} borderStyle={"sm"} borderRadius={4} />
                            </Box>
                          </Table.Cell>
                          <Table.Cell>
                            <Box>
                              <Text>{`${mapDataTypeToLabel(
                                resources,
                                dataElement.dataType,
                                dataElement.dictionaryListName
                              )}`}</Text>
                            </Box>
                          </Table.Cell>
                          <Table.Cell>
                            <Box maxWidth={200}>
                              <DataElementSampleValue dataElement={dataElement} />
                            </Box>
                          </Table.Cell>
                          <Table.Cell>
                            <Box display="flex">
                              {dataElement &&
                              dataElement.minOccurs &&
                              dataElement.minOccurs >= 1 ? (
                                <Icon
                                  accessibilityLabel={resources.valid}
                                  icon="check"
                                  color={colors.success}
                                />
                              ) : (
                                <Icon
                                  accessibilityLabel={resources.invalid}
                                  icon="close"
                                  color={colors.error}
                                />
                              )}
                            </Box>
                          </Table.Cell>
                          <Table.Cell>
                            <Box display="flex">
                              {dataElement.unique ? (
                                <Icon
                                  accessibilityLabel="isRequired"
                                  icon="check"
                                  color={colors.success}
                                />
                              ) : (
                                <Icon
                                  accessibilityLabel="isRequired"
                                  icon="close"
                                  color={colors.error}
                                />
                              )}
                            </Box>
                          </Table.Cell>
                          {filter.resultsAreClassDataElements && (
                            <Table.Cell>
                              <Text> {dataElement.classChildCount} </Text>
                            </Table.Cell>
                          )}

                          <Table.Cell>
                            <Box display="flex">
                              <Box display="flex" gap={6}>
                                <TapArea
                                  onTap={() =>
                                    organizationNavigate(
                                      `/settings/data-dictionary/data-elements/new`,
                                      {
                                        state: dataElement,
                                      }
                                    )
                                  }
                                >
                                  <Tooltip text={resources.duplicate}>
                                    <Icon
                                      accessibilityLabel={resources.duplicate}
                                      icon="clone"
                                      size={"sm"}
                                      color={colors.primary}
                                    />
                                  </Tooltip>
                                </TapArea>
                                <TapArea
                                  onTap={() =>
                                    organizationNavigate(
                                      `/settings/data-dictionary/data-elements/${dataElement.name}`
                                    )
                                  }
                                >
                                  <Tooltip text={resources.edit}>
                                    <Icon
                                      accessibilityLabel="Edit"
                                      icon="pencil"
                                      size={"sm"}
                                      color={colors.primary}
                                    />
                                  </Tooltip>
                                </TapArea>
                                {hasClipboard && (
                                  <TapArea onTap={() => copyToClipboard(dataElement.id)}>
                                    <Tooltip text={resources.copyIdToClipboard}>
                                      <ClipboardSvgIcon
                                        color={colors.primary}
                                        width={24}
                                        height={24}
                                        onClick={() => handleCopyToClipBoard()}
                                      />
                                      {showNotification && (
                                        <NotificationMessage
                                          type="success"
                                          position="bottom-left"
                                          message={`${resources.dataDictionary.copyDataElementId}`}
                                          handleDismiss={() => {
                                            setShowNotification(false);
                                          }}
                                          delay={3000}
                                        />
                                      )}
                                    </Tooltip>
                                  </TapArea>
                                )}
                              </Box>
                            </Box>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })
                  ) : (
                    <Table.Row key="no-elements">
                      <Table.Cell colSpan={5}>
                        <Box
                          borderRadius={4}
                          borderStyle="sm"
                          height={56}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          color={colors.neutral200}
                        >
                          <Text size="300">{resources.dataDictionary.noElements}</Text>
                        </Box>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            )}
          </Box>
        </Box>
        <Box margin={1} />
        <Box paddingX={4} display="flex" direction="row" flex="grow">
          {breakpoint !== "small" && <Box margin={1} flex="grow" />}
          <Box
            flex={breakpoint === "small" ? "grow" : "shrink"}
            paddingX={breakpoint === "small" ? 4 : undefined}
          >
            <Pagination<typeof rowsPerPage>
              id="data_elements_list_pagination_bottom"
              rowsLabel={`${resources.dataDictionary.dataElements}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={paginatedData ? paginatedData.dataElements.length : 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={PAGINATION_OPTIONS}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

const RowSkeleton: FunctionComponent = () => {
  return (
    <Table.Row>
      <Table.Cell>
        <Box marginStart={4} display={"flex"} justifyContent={"between"}>
          <Skeleton height={50} width={"100%"} />
          <Box width={1} borderStyle={"sm"} borderRadius={4} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box>
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box display="flex" justifyContent="center">
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box display="flex" justifyContent="center">
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>
    </Table.Row>
  );
};

const sampleProps: TextProps = {
  ellipsisLength: 100,
};

const DataElementSampleValue: FunctionComponent<{ dataElement: DataElement }> = ({
  dataElement,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  if (
    dataElement.sampleValue === undefined ||
    dataElement.sampleValue === null ||
    (Array.isArray(dataElement.sampleValue) && !dataElement.sampleValue.length)
  ) {
    return <Text color={colors.error} {...sampleProps}>{`[${resources.noValue}]`}</Text>;
  }

  if (
    (dataElement.dataType === "Alphanumeric" ||
      dataElement.dataType === "Currency" ||
      dataElement.dataType === "Numeric") &&
    !Array.isArray(dataElement.sampleValue)
  ) {
    return <Text color={colors.secondary} {...sampleProps}>{`[${resources.noValue}]`}</Text>;
  }

  switch (dataElement.dataType) {
    case "Alphanumeric": {
      const sampleValue = Array.isArray(dataElement.sampleValue)
        ? dataElement.sampleValue
        : [dataElement.sampleValue];
      const [first] = sampleValue;
      return <Text {...sampleProps} overflow="ellipsis">{`${first}`}</Text>;
    }
    case "Currency": {
      const sampleValue = Array.isArray(dataElement.sampleValue)
        ? dataElement.sampleValue
        : [dataElement.sampleValue];
      const [first] = sampleValue;
      return <Text {...sampleProps} overflow="ellipsis">{`${formatCurrency(first)}`}</Text>;
    }
    case "Numeric": {
      const sampleValue = Array.isArray(dataElement.sampleValue)
        ? dataElement.sampleValue
        : [dataElement.sampleValue];
      const [first] = sampleValue;
      return <Text {...sampleProps} overflow="ellipsis">{`${formatNumber(first)}`}</Text>;
    }
    case "Date": {
      const dateValue = new Date(dataElement.sampleValue);
      return <FancyDateTime value={dateValue.getTime()} showTime={false} ignoreFromNow={true} />;
    }
    case "DateTime": {
      const dateTimeValue = new Date(dataElement.sampleValue);
      return <FancyDateTime value={dateTimeValue.getTime()} showTime={true} />;
    }
    case "Time": {
      const timeValue = new Date(dataElement.sampleValue);
      return <FancyDateTime value={timeValue.getTime()} showTime={true} />;
    }
    case "Logical": {
      const booleanValue = !!dataElement.sampleValue;
      return (
        <Box display="flex" paddingLeft={4}>
          {booleanValue ? (
            <Icon icon="check" color={colors.success} accessibilityLabel={resources.success} />
          ) : (
            <Icon icon="close" color={colors.error} accessibilityLabel={resources.success} />
          )}
        </Box>
      );
    }
    case "Image": {
      return <></>;
    }
  }
};

export default DataElementListPage;

interface PropsDataElementCard {
  dataElement: DataElement & { id: string };
  hasClipboard: boolean | undefined;
  copyToClipboard: (text: string) => Promise<boolean>;
  showNotification: boolean;
  setShowNotification: (value: SetStateAction<boolean>) => void;
  handleCopyToClipBoard: () => void;
}

const DataElementCard: React.FC<PropsDataElementCard> = ({
  dataElement,
  hasClipboard,
  copyToClipboard,
  showNotification,
  setShowNotification,
  handleCopyToClipBoard,
}) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const organizationNavigate = useOrganizationNavigate();
  return (
    <Box
      display="flex"
      direction="column"
      gap={4}
      borderStyle="sm"
      width={"100%"}
      height={"auto"}
      padding={4}
    >
      <Box display="flex" direction="column">
        <Text size="200" weight="bold">
          {resources.name.toUpperCase()}
        </Text>
        <Box marginBottom={2}>
          <Text overflow="ellipsis">{dataElement.humanName}</Text>
        </Box>
        <Box display="flex" direction="row" flex="grow" gap={4}>
          <Box display="flex" direction="column" flex="grow">
            <Text size="200" weight="bold">
              {resources.dataType.toUpperCase()}
            </Text>
            <Box marginBottom={2}>
              <Text>{`${mapDataTypeToLabel(
                resources,
                dataElement.dataType,
                dataElement.dictionaryListName
              )}`}</Text>
            </Box>
          </Box>
          <Box display="flex" direction="column">
            <Text size="200" weight="bold">
              {resources.unique.toUpperCase()}
            </Text>
            <Box marginStart={3} marginTop={1} display="flex">
              {dataElement && dataElement.minOccurs && dataElement.minOccurs >= 1 ? (
                <Icon accessibilityLabel={resources.valid} icon="check" color={colors.success} />
              ) : (
                <Icon accessibilityLabel={resources.invalid} icon="close" color={colors.error} />
              )}
            </Box>
          </Box>
          <Box display="flex" direction="column">
            <Text size="200" weight="bold">
              {resources.actions.toUpperCase()}
            </Text>
            <Box marginTop={1} display="flex" direction="row">
              <Box display="flex">
                <Box display="flex" gap={6}>
                  <TapArea
                    onTap={() =>
                      organizationNavigate(`/settings/data-dictionary/data-elements/new`, {
                        state: dataElement,
                      })
                    }
                  >
                    <Tooltip text={resources.duplicate}>
                      <Icon
                        accessibilityLabel={resources.duplicate}
                        icon="clone"
                        size={"sm"}
                        color={colors.primary}
                      />
                    </Tooltip>
                  </TapArea>
                  <TapArea
                    onTap={() =>
                      organizationNavigate(
                        `/settings/data-dictionary/data-elements/${dataElement.name}`
                      )
                    }
                  >
                    <Tooltip text={resources.edit}>
                      <Icon
                        accessibilityLabel="Edit"
                        icon="pencil"
                        size={"sm"}
                        color={colors.primary}
                      />
                    </Tooltip>
                  </TapArea>
                  {hasClipboard && (
                    <TapArea onTap={() => copyToClipboard(dataElement.id)}>
                      <Tooltip text={resources.copyIdToClipboard}>
                        <ClipboardSvgIcon
                          color={colors.primary}
                          width={24}
                          height={24}
                          onClick={() => handleCopyToClipBoard()}
                        />
                        {showNotification && (
                          <NotificationMessage
                            type="success"
                            position="bottom-left"
                            message={`${resources.dataDictionary.copyDataElementId}`}
                            handleDismiss={() => {
                              setShowNotification(false);
                            }}
                            delay={3000}
                          />
                        )}
                      </Tooltip>
                    </TapArea>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" direction="column" flex="shrink" gap={3}>
          <Box display="flex" direction="column" width={"100%"}>
            <Text size="200" weight="bold">
              {resources.dataDictionary.sampleValue.toUpperCase()}
            </Text>
            <Box marginTop={1}>
              <DataElementSampleValue dataElement={dataElement} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
