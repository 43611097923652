import {
  Box,
  ComboBox,
  ComboBoxTags,
  modalZIndex,
  Switch,
  Text,
  TextField,
  useColors,
  useDesignBreakpoint,
  WhatsThis,
} from "@prodoctivity/design-system";

import { DocumentTypeNameValidationRegex } from "@prodoctivity/shared";
import type { DocumentGroup, OrganizationRole } from "@prodoctivity/types";
import { FunctionComponent, useMemo } from "react";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { DocumentTypeState, imagesElement } from "../hooks";

const MaxDocumentTypeNameLength = 200;

export type Option = { value: string; subtext?: string; label: string };
type Props = {
  isNew: boolean;
  isLoading: boolean;
  documentGroups?: Array<DocumentGroup>;
  roles?: Array<OrganizationRole>;
  formState: DocumentTypeState;
  acceptedFormats: Option[];
  onChange: (
    field:
      | "name"
      | "autoExtract"
      | "acceptedFormats"
      | "expirationWarningDays"
      | "documentGroupId"
      | "error"
      | "active",
    value: string | number | boolean | Array<Option> | undefined
  ) => void;
  setFormState?: (value: React.SetStateAction<DocumentTypeState>) => void;
  showAutoExtract?: boolean;
};

export const fileMimeTypes = [
  {
    label: "Word",
    value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    subtext: "Microsoft Word OpenXML (.docx)",
  },
  {
    label: "PDF",
    value: "application/pdf",
    subtext:
      "PDF 1.0 and 2.0 ISO Specifications (32000-1:2008, 32000-2:2017); PDF/A 1, 2, 3 ISO Specifications (19005-1, 19005-2, 19005-3); XFDF ISO Specification (19444-1:2016)",
  },
  {
    label: "Excel",
    value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    subtext: "Microsoft Excel OpenXML (.xlsx)",
  },
  {
    label: "PowerPoint",
    value: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    subtext: "Microsoft PowerPoint OpenXML (.pptx)",
  },
  {
    label: "MP3",
    value: "audio/mpeg",
    subtext: "MPEG-1 Audio Layer III o MPEG-2 Audio Layer III",
  },
  {
    label: "MP4",
    value: "video/mp4",
    subtext: "MPEG-4 Part 14 extends over ISO Base Media File Format (MPEG-4 Part 12)",
  },

  { label: "PNG", value: "image/png", subtext: undefined },
  { label: "JPEG", value: "image/jpeg", subtext: undefined },
  //{ label: "GIF", value: "image/gif", subtext: undefined },
  { label: "TIFF", value: "image/tiff", subtext: undefined },
  { label: "BMP", value: "image/bmp", subtext: undefined },
  { label: "Binary", value: "application/octet-stream", subtext: undefined },
  { label: "ZIP", value: "application/zip", subtext: undefined },
  { label: "CSV", value: "text/csv", subtext: undefined },
  { label: "XML", value: "application/xml", subtext: undefined },
];
const otherFileMimeTypes = fileMimeTypes.filter((mime) => {
  return (
    mime.value !== "image/jpeg" &&
    // mime.value !== "image/gif" &&
    mime.value !== "image/tiff" &&
    mime.value !== "image/bmp" &&
    mime.value !== "image/png"
  );
});

otherFileMimeTypes.push(imagesElement);

export const DocumentTypeStep: FunctionComponent<Props> = ({
  isLoading,
  documentGroups,
  formState,
  acceptedFormats,
  onChange,
  setFormState,
  showAutoExtract,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();

  const documentGroupOptions = useMemo(() => {
    return (documentGroups || []).map((d) => {
      return { label: d.name, value: d.id };
    });
  }, [documentGroups]);

  const selectedOption = documentGroupOptions.find((d) => d.value === formState.documentGroupId);

  return (
    <Box display="flex" alignItems="center" width={"100%"} direction="column" flex="grow">
      <Box width={"50%"} display="flex" justifyContent="start">
        <Text weight="bold" color={colors.black900} size="400">
          {resources.documentTypes.documentType}
        </Text>
      </Box>
      <Box
        paddingY={8}
        paddingX={6}
        marginTop={3}
        color={colors.white}
        borderStyle="lg"
        borderRadius={4}
        direction="column"
        width={breakpoint === "medium" || breakpoint === "small" ? "90%" : "50%"}
      >
        <Box display="flex" direction="column" flex="shrink" minHeight={65}>
          <Box marginBottom={3} width="100%">
            <Text weight="bold" color={colors.black600}>
              {resources.name}
            </Text>
          </Box>
          <Box width="100%">
            <TextField
              placeholder={resources.name}
              id="name"
              value={formState.name || ""}
              disabled={isLoading}
              maxLength={{
                characterCount: MaxDocumentTypeNameLength,
                errorAccessibilityLabel: resources.maximumCharactersAllowed,
              }}
              onChange={(e) => {
                const inputValue = e.value;

                if (inputValue === "" || DocumentTypeNameValidationRegex.test(inputValue)) {
                  onChange("name", inputValue);
                } else {
                  setFormState &&
                    setFormState((prev) => {
                      return {
                        ...prev,
                        toastMessage: {
                          type: "error",
                          message: resources.specialCharsNotAllowed,
                        },
                      };
                    });
                }
              }}
              errorMessage={formState.errors?.name}
            />
          </Box>
        </Box>
        <Box display="flex" direction="column" flex="shrink" marginTop={8} minHeight={65}>
          <Box display="flex" direction="column" flex="shrink" marginBottom={3} width="100%">
            <Text weight="bold" color={colors.black600}>
              {resources.allowedFormats}
            </Text>
          </Box>
          <Box display="flex" direction="column" flex="shrink" width="100%">
            <ComboBoxTags
              id={`document_type_formats`}
              placeholder={"Formats"}
              label=""
              disabled={isLoading}
              accessibilityClearButtonLabel={resources.clear}
              noResultText="There's no more"
              onSelect={(items) => {
                onChange("acceptedFormats", items);
              }}
              size="lg"
              options={otherFileMimeTypes}
              selected={acceptedFormats}
              errorMessage={formState.errors?.["acceptedFormats"]}
            />
          </Box>
        </Box>
        <Box display="flex" direction="column" flex="shrink" marginTop={8} minHeight={65}>
          <Box marginBottom={3} width="100%">
            <Text weight="bold" color={colors.black600}>
              {resources.documentGroup}
            </Text>
          </Box>
          <Box display="flex" direction="column" flex="shrink" width="100%">
            <Box width="100%">
              <ComboBox
                id="documentGroupId"
                label=""
                onChange={(e) => {
                  onChange("documentGroupId", e.value);
                }}
                accessibilityClearButtonLabel={resources.clear}
                noResultText={resources.dataDictionary.noItems}
                onSelect={(e) => {
                  onChange("documentGroupId", e.item.value);
                }}
                size="lg"
                options={documentGroupOptions}
                selectedOption={selectedOption}
                placeholder={selectedOption ? selectedOption.label : ""}
              />
            </Box>
          </Box>
        </Box>
        {showAutoExtract && (
          <Box display="flex" direction="column" flex="shrink" marginTop={8} minHeight={65}>
            <Box marginBottom={3} width="100%" display="flex">
              <Text weight="bold" color={colors.black600}>
                {resources.autoExtract}
              </Text>
              <Box zIndex={modalZIndex} display="flex">
                <WhatsThis
                  color={colors.neutral900}
                  title={resources.autoExtractDesc}
                  clickContent={
                    <Box>
                      <Box marginBottom={5}>
                        <Text size="300" weight="bold">
                          {resources.autoExtractDesc}
                        </Text>
                      </Box>
                    </Box>
                  }
                  size="sm"
                  colorBundle={colors}
                />
              </Box>
            </Box>
            <Box display="flex" direction="column" flex="shrink" width="100%">
              <Switch
                disabled={isLoading}
                switched={formState.autoExtract}
                onChange={(e) => onChange("autoExtract", e.value)}
                id="autoExtract"
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
