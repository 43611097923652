import { useDesignBreakpoint, usePubSub } from "@prodoctivity/design-system";
import {
  DocumentPermissionType$Schema,
  MimeType$Schema,
  areArraysEqual,
  areObjectsEqual,
  getDocumentTypePermissionList,
  validateToDataTypeAndValue,
  formValueToValueType,
} from "@prodoctivity/shared";
import type {
  DataElement,
  DocumentPermissionType,
  ExpirationBehavior,
  MimeType,
  StringTemplatePart,
  TemplateContextDefinition,
  TemplateWizardDefinition,
} from "@prodoctivity/shared/src/index-types";
import type {
  DocumentTypeInfo,
  HttpGetDocumentTypeResponse,
  SaveDocumentType,
  SecurityField,
} from "@prodoctivity/types";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { TemplateEditWizardEvent } from "../../../components/TemplateEditWizard/hooks";
import { useDocumentGroupList, useGetAllOrganizationRoles } from "../../../hooks";
import { Option, fileMimeTypes } from "./Steps/DocumentTypeStep";

import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { isProduction } from "../../../config";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useServices } from "../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../link-templates";

export type DocumentTypeState = {
  originalDocumentType?: DocumentTypeInfo;
  name?: string;
  acceptedFormats: Array<Option>;
  documentGroupId?: string;
  autoExtract: boolean | undefined;
  expirationBehavior: ExpirationBehavior | undefined;
  errors?: { [key: string]: string };
  contextDefinition: TemplateContextDefinition;
  wizardDefinition?: TemplateWizardDefinition;
  nameConfig: StringTemplatePart[];
  permissions: Array<{ roleId: string; permissions: Array<DocumentPermissionType> }>;
  securityFields: SecurityField[];
  identifierConfig: StringTemplatePart[] | undefined;
  identifierCollisionForcesNewVersion: boolean;
  showUpdateDocsModal: boolean;
  expirationWarningDays: number | undefined;
  toastMessage?: {
    type: "error" | "success" | "warn";
    message: string;
  };
};

const STEPS = {
  DocumentType: 0,
  DocumentKeys: 1,
  FormConfiguration: 2,
  Permissions: 3,
  SecurityFields: 4,
};

const permissionsList = getDocumentTypePermissionList().map((perm) => ({
  id: perm,
  name: perm,
}));

export const imagesElement = {
  label: "Images",
  value: "image/*",
  subtext: " JPEG, TIFF, BMP, PNG",
};

export function filterImageMimeTypes(currentAcceptedFormats: Option[], resourcesImages: string) {
  let hasImages = false;
  const filtered = (currentAcceptedFormats || []).filter((item) => {
    if (item.value.indexOf("image/") === 0 && item.value !== "image/*") {
      hasImages = true;
      return false;
    }
    return true;
  });
  return [
    ...filtered,
    ...(hasImages
      ? [
          {
            ...imagesElement,
            label: resourcesImages,
          },
        ]
      : []),
  ];
}

export const useDocumentType = () => {
  const { resources, moment } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const organizationNavigate = useOrganizationNavigate();
  const { data: groupList } = useDocumentGroupList(false);
  const { data: organizationRoles } = useGetAllOrganizationRoles();
  const { documentTypeId } = useParams();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const clone = params.get("clone");
  const isNew = !documentTypeId || clone === "true";
  const { eventManager } = usePubSub<TemplateEditWizardEvent>();

  const { t } = useTranslation();

  const i18n = useCallback(
    (key: string) => {
      const value = t(key);
      if (!isProduction && typeof value === "undefined") {
        console.error(`Key "${key}" not defined in i18n resource files`);
        return key;
      }

      return value;
    },
    [t]
  );

  const {
    getPaginatedOrganizationDataElements: getAllOrganizationDataElements,
    getDocumentTypeInfo,
    saveDocumentType,
    fetchOrganizationAdminInfo,
  } = useServices();
  const getDataElements = useCallback(async () => {
    let pageNumber = 0;
    const requestedPageLength = "100";
    let pageLength = 100;

    const result: DataElement[] = [];
    do {
      const currentPage = await getAllOrganizationDataElements(pageNumber, requestedPageLength);
      (currentPage.dataElements || []).forEach((de) => {
        const { id: _id, ...dataElement } = de;
        result.push(dataElement);
      });
      pageLength = currentPage.pageLength;
      pageNumber += 1;
    } while (pageLength >= parseInt(requestedPageLength));

    result.sort((a, b) => a.name.localeCompare(b.name));

    const elementsMap = result.reduce((acc: Record<string, DataElement>, next) => {
      if (!acc[next.name]) {
        acc[next.name] = next;
      }
      return acc;
    }, {});

    return Object.values(elementsMap);
  }, [getAllOrganizationDataElements]);

  const [selectedTabIndex, setTabIndex] = useState<number>(0);

  const [formState, setFormState] = useState<DocumentTypeState>({
    originalDocumentType: undefined,
    name: undefined,
    acceptedFormats: [fileMimeTypes[0], fileMimeTypes[1]],
    documentGroupId: undefined,
    autoExtract: false,
    nameConfig: [],
    contextDefinition: {
      fields: [],
      records: [],
    },
    wizardDefinition: undefined,
    permissions: [],
    securityFields: [],
    expirationBehavior: undefined,
    identifierCollisionForcesNewVersion: false,
    showUpdateDocsModal: false,
    identifierConfig: undefined,
    expirationWarningDays: undefined,
  });

  const acceptedFormats = useMemo(() => {
    return filterImageMimeTypes(formState.acceptedFormats, resources.images);
  }, [formState.acceptedFormats, resources.images]);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: `${resources.documentTypes.documentTypes}`,
        url: organizationLinkTemplates.manageDocumentTypes(),
      },
      { type: "text", name: formState.name || "" },
    ];
  }, [formState.name, resources.documentTypes.documentTypes, resources.home, resources.settings]);

  const updateNameConfig = useCallback((nameConfig: StringTemplatePart[]) => {
    setFormState((prev) => {
      return {
        ...prev,
        nameConfig,
      };
    });
  }, []);

  const setIdentifierConfig = useCallback((identifierConfig: StringTemplatePart[]) => {
    setFormState((prev) => {
      const contextDefinitionTemp = prev.contextDefinition;
      contextDefinitionTemp.fields = contextDefinitionTemp.fields.map((field) => {
        field.properties.isUnique =
          identifierConfig.findIndex((i) => i.type === "variable" && i.name === field.name) >= 0;
        return field;
      });
      return {
        ...prev,
        identifierConfig: identifierConfig,
        identifierCollisionForcesNewVersion:
          identifierConfig.length === 0 ? false : prev.identifierCollisionForcesNewVersion,
        contextDefinition: contextDefinitionTemp,
      };
    });
  }, []);

  const setIdentifierCollisionForcesNewVersion = useCallback((value: boolean) => {
    setFormState((prev) => {
      return {
        ...prev,
        identifierCollisionForcesNewVersion: value,
      };
    });
  }, []);

  const { data: dataElements } = useOrganizationQuery(`/dataElements`, getDataElements, {
    staleTime: 10 * 60 * 1000,
    refetchOnMount: "always",
    refetchOnWindowFocus: true,
  });

  const { data: organizationData } = useOrganizationQuery(
    `organization-admin-info`,
    fetchOrganizationAdminInfo,
    {
      refetchOnMount: "always",
      onSuccess: (data) => {
        if (data.securityFieldConfig) {
          const documentTypeSecurityFields =
            data.securityFieldConfig.documentTypes.find((d) => d.documentTypeId === documentTypeId)
              ?.fields || [];
          setFormState((prev) => ({ ...prev, securityFields: documentTypeSecurityFields }));
        }
      },
    }
  );

  const lastStep = useMemo(() => {
    if (organizationData && organizationData?.featureFlags?.allowSecurityFields) {
      return STEPS.SecurityFields;
    }
    return STEPS.Permissions;
  }, [organizationData]);

  const fetchDocumentTypeInfo = useCallback(async () => {
    if (!documentTypeId) {
      return {
        documentType: undefined,
      };
    }

    return await getDocumentTypeInfo(documentTypeId);
  }, [documentTypeId, getDocumentTypeInfo]);

  const { isLoading } = useOrganizationQuery(
    `document-type-details/${documentTypeId}`,
    fetchDocumentTypeInfo,
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: "always",
      onSuccess: useCallback(
        (data: HttpGetDocumentTypeResponse["payload"]) => {
          const documentType = data.documentType;
          if (documentType) {
            setFormState((prevState) => ({
              ...prevState,
              originalDocumentType: documentType,
              name:
                clone === "true" ? `${resources.copyOf} ${documentType.name}` : documentType.name,
              expirationBehavior: documentType.expirationBehavior
                ? documentType.expirationBehavior
                : undefined,
              acceptedFormats: documentType.acceptedMimeTypes.map((a) => {
                return (
                  fileMimeTypes.find((f) => f.value === a) || {
                    label: a,
                    value: a,
                    subtext: undefined,
                  }
                );
              }),
              documentGroupId: documentType.documentGroupId,
              autoExtract: documentType.autoExtract,
              nameConfig: documentType.nameConfig,
              contextDefinition: documentType.contextDefinition,
              wizardDefinition: documentType.wizardDefinition,
              permissions: documentType.permissions || [],
              identifierConfig: documentType.identifierConfig,
              identifierCollisionForcesNewVersion:
                !!documentType.identifierCollisionForcesNewVersion,
              expirationWarningDays: documentType.expirationWarningDays || undefined,
            }));
          }
        },
        [clone, resources]
      ),
    }
  );

  const onPrev = useCallback(() => {
    if (selectedTabIndex === STEPS.DocumentType) {
      return;
    }

    setTabIndex(selectedTabIndex - 1);
  }, [selectedTabIndex]);

  const fetchPostDocumentType = useCallback(
    async (updateDocumentInstances: boolean) => {
      if (formState.documentGroupId && formState.name && formState.wizardDefinition) {
        const documentTypeSent: SaveDocumentType = {
          name: formState.name,
          documentGroupId: formState.documentGroupId,
          autoExtract: formState.autoExtract,
          contextDefinition: formState.contextDefinition,
          wizardDefinition: formState.wizardDefinition,
          useFullTextIndex: false,
          expirationBehavior: formState.expirationBehavior,
          nameConfig: formState.nameConfig,
          parentDocumentTypeVersionId: isNew
            ? undefined
            : formState.originalDocumentType?.documentTypeVersionId,
          acceptedMimeTypeList: filterImageMimeTypes(acceptedFormats, resources.images)
            .map((o) => {
              if (o.value === imagesElement.value) {
                return [
                  // "image/gif",
                  "image/jpeg",
                  "image/tiff",
                  "image/bmp",
                  "image/png",
                ] as MimeType[];
              } else {
                return o.value as MimeType;
              }
            })
            .flat()
            .filter((mimeTypeStr) => {
              const testParse = MimeType$Schema.safeParse(mimeTypeStr);
              return testParse.success;
            }),
          permissions: formState.permissions,
          securityFields: formState.securityFields,
          identifierConfig: formState.identifierConfig,
          identifierCollisionForcesNewVersion: formState.identifierCollisionForcesNewVersion,
          updateDocumentInstances,
          expirationWarningDays: formState.expirationWarningDays || undefined,
        };

        return await saveDocumentType(documentTypeSent);
      }
    },
    [formState, acceptedFormats, isNew, saveDocumentType, resources.images]
  );

  const {
    mutate: mutateSaveDocumentType,
    isLoading: isSaving,
    isSuccess: isSaved,
  } = useMutation(fetchPostDocumentType, {
    onSuccess() {
      setFormState((prev) => ({
        ...prev,
        showUpdateDocsModal: false,
        toastMessage: { type: "success", message: resources.documentTypes.documentTypeSaved },
      }));
      setTimeout(() => {
        organizationNavigate(organizationLinkTemplates.manageDocumentTypes());
      }, 3000);
    },
    onError(ex: { response: { data: { errors: Array<{ message: string }> } } }) {
      const errorMessage =
        ex.response?.data?.errors?.length > 0
          ? ex.response.data.errors[0].message
          : "An error occurred while saving the document type.";

      setFormState((prev) => ({
        ...prev,
        showUpdateDocsModal: false,
        toastMessage: {
          type: "error",
          message: "Could not save document type: " + errorMessage,
        },
      }));
    },
  });

  const onNext = useCallback(() => {
    const errors: { [key: string]: string } = {};
    const emptyErrors: { [key: string]: string } = {};
    if (selectedTabIndex === lastStep) {
      if (
        formState.originalDocumentType &&
        (!areArraysEqual(
          formState.identifierConfig || [],
          formState.originalDocumentType.identifierConfig || []
        ) ||
          !areArraysEqual(formState.nameConfig, formState.originalDocumentType.nameConfig))
      ) {
        setFormState((prev) => ({ ...prev, showUpdateDocsModal: true }));
      } else {
        mutateSaveDocumentType(false);
      }
      return;
    } else if (selectedTabIndex === STEPS.DocumentType) {
      if (!formState.name) {
        errors["name"] = "Required!";
      }
      if (acceptedFormats.length <= 0) {
        errors["acceptedFormats"] = resources.chooseOneItem;
      }
      if (!formState.documentGroupId) {
        errors["documentGroupId"] = "Should select a document group";
      }
      if (formState.expirationWarningDays && formState.expirationWarningDays > 999) {
        errors["expirationWarningDays"] = "Should select a value between 0 and 999";
      }
    } else if (selectedTabIndex === STEPS.DocumentKeys) {
      if (
        (formState.contextDefinition.fields || []).length < 1 &&
        (formState.contextDefinition.records || []).length === 0
      ) {
        errors["documentKeyStep"] = resources.documentTypes.notEnoughElementsSelected;
      }
    }

    setFormState({ ...formState, errors: errors });
    if (!areObjectsEqual(errors, emptyErrors)) {
      return;
    }

    setTabIndex(selectedTabIndex + 1);
  }, [
    selectedTabIndex,
    lastStep,
    formState,
    acceptedFormats,
    mutateSaveDocumentType,
    resources.chooseOneItem,
    resources.documentTypes.notEnoughElementsSelected,
  ]);

  const onPermissionsChange = useCallback(
    (roleId: string, permissionName: string, active: boolean) => {
      const permissionTypeName = DocumentPermissionType$Schema.safeParse(permissionName);
      if (!permissionTypeName.success) {
        return;
      }
      const permissions = formState.permissions;

      let permissionIndex = permissions.findIndex((p) => p.roleId === roleId);
      if (permissionIndex < 0) {
        permissions.push({
          roleId,
          permissions: [],
        });
        permissionIndex = permissions.findIndex((p) => p.roleId === roleId);
      }
      permissions[permissionIndex].permissions = (
        permissions[permissionIndex].permissions || []
      ).filter((p) => p !== permissionName);
      if (active) {
        permissions[permissionIndex].permissions.push(permissionTypeName.data);
      }

      setFormState((prevState) => ({ ...prevState, permissions: permissions }));
    },
    [formState.permissions]
  );

  const onChange = useCallback(
    (
      field:
        | "name"
        | "acceptedFormats"
        | "expirationWarningDays"
        | "autoExtract"
        | "documentGroupId"
        | "error"
        | "active",
      value: string | number | Array<Option> | boolean | undefined
    ) => {
      setFormState((prev) => {
        return { ...prev, [field]: value };
      });
    },
    []
  );

  const onContextDefinitionChange = useCallback((contextDefinition: TemplateContextDefinition) => {
    setFormState((prevState) => ({ ...prevState, contextDefinition }));
  }, []);

  const onWizardDefinitionChange = useCallback((wizardDefinition: TemplateWizardDefinition) => {
    setFormState((prev) => ({ ...prev, wizardDefinition: wizardDefinition }));
  }, []);

  const addNewSecurityField = useCallback(() => {
    setFormState((prev) => {
      const securityFields = prev.securityFields;
      if (prev.contextDefinition.fields.length > 0) {
        const field = prev.contextDefinition.fields[0];
        const fieldValue = validateToDataTypeAndValue(
          moment,
          field.properties.sampleValue || null,
          field.properties.dataType
        );
        if (fieldValue) {
          const newSecurityField: SecurityField = {
            fieldName: field.name,
            ...fieldValue,
            roleIdList: [],
          };
          securityFields.push(newSecurityField);
        }
      }
      return { ...prev, securityFields };
    });
  }, [moment]);

  const removeSecurityKeyword = useCallback((indexRemoved?: number) => {
    if (indexRemoved !== undefined) {
      setFormState((prev) => {
        const securityFields = prev.securityFields.filter((_r, i) => indexRemoved !== i);
        return { ...prev, securityFields };
      });
    }
  }, []);

  const setShowUpdateDocsModal = useCallback(
    (showUpdateDocsModal: boolean) => setFormState((prev) => ({ ...prev, showUpdateDocsModal })),
    []
  );

  return {
    i18n,
    resources,
    eventManager,
    breakpoint,
    breadCrumbEntries,
    organizationNavigate,

    permissionsList,
    dataElements,
    groupList,
    organizationRoles,
    organizationData,

    isNew,
    STEPS,
    lastStep,
    isLoading: isLoading || isSaving || isSaved,
    formState,
    acceptedFormats,
    setFormState,
    selectedTabIndex,

    onPrev,
    onNext,
    setShowUpdateDocsModal,
    mutateSaveDocumentType,
    updateNameConfig,
    setIdentifierConfig: setIdentifierConfig,
    setIdentifierCollisionForcesNewVersion: setIdentifierCollisionForcesNewVersion,
    onPermissionsChange,
    onChange,
    onContextDefinitionChange,
    onWizardDefinitionChange,

    addNewSecurityField,
    removeSecurityKeyword,
  };
};

export const useSecurityFieldForm = ({
  currentIndex,
  roles,
  formState,
  setFormState,
}: {
  currentIndex: number;
  roles: Array<{ id: string; name: string }>;
  formState: DocumentTypeState;
  setFormState: Dispatch<SetStateAction<DocumentTypeState>>;
}) => {
  const { moment, resources } = useAppTranslation();
  const [open, setOpen] = useState<boolean>(
    formState.securityFields[currentIndex].roleIdList.length === 0
  );

  const [filterRoles, setFilterRoles] = useState<string | undefined>();

  const fields = useMemo(() => {
    return formState.contextDefinition.fields
      .filter((f) => f.properties.dataType !== "Image")
      .map((f) => ({
        label: f.properties.label,
        value: f.name,
      }));
  }, [formState]);

  const dataElementInfo = useMemo(() => {
    const contextField = formState.contextDefinition.fields.find(
      (f) => formState.securityFields[currentIndex].fieldName === f.name
    );
    if (!contextField) {
      return undefined;
    }
    const val = formState.securityFields[currentIndex].value;
    return {
      contextField,
      typeValue: formValueToValueType(
        moment,
        contextField.properties,
        val === null
          ? []
          : Array.isArray(val)
          ? val.reduce((acc: Array<string | number>, next) => {
              if (typeof next !== "object") {
                acc.push(next);
              }
              return acc;
            }, [])
          : [val]
      ),
    };
  }, [currentIndex, formState, moment]);

  const accordionTitle = useMemo(() => {
    const securityField = formState.securityFields[currentIndex];
    if (!securityField || !dataElementInfo) {
      return "";
    }
    try {
      let value = securityField.value;

      if (
        securityField.type === "Date" ||
        securityField.type === "Time" ||
        securityField.type === "DateTime"
      ) {
        const dateValue = new Date(
          Array.isArray(securityField.value) ? securityField.value[0] : securityField.value
        );
        value =
          securityField.type === "Date"
            ? dateValue.toLocaleDateString()
            : securityField.type === "DateTime"
            ? dateValue.toLocaleString()
            : dateValue.toLocaleTimeString();
      }

      return `${
        dataElementInfo.contextField.properties.label
      } ${resources.equals.toLocaleLowerCase()} ${value}`;
    } catch (ex) {
      console.error(ex);
      return dataElementInfo.contextField.properties.label;
    }
  }, [dataElementInfo, formState, currentIndex, resources]);

  const filteredRoles = useMemo(() => {
    return roles
      .filter(
        (r) =>
          !formState.securityFields[currentIndex].roleIdList.includes(r.id) &&
          r.name.toLocaleLowerCase().includes((filterRoles || "").toLocaleLowerCase())
      )
      .map((r) => ({ label: r.name, value: r.id }));
  }, [roles, filterRoles, formState, currentIndex]);

  const selectedRoles = useMemo(() => {
    return roles
      .filter((r) => formState.securityFields[currentIndex].roleIdList.includes(r.id))
      .map((r) => ({ label: r.name, value: r.id }));
  }, [roles, formState, currentIndex]);

  const onSelectRole = useCallback(
    (roleId: string) => {
      setFormState((prev) => {
        const securityFields = [...prev.securityFields];

        if (
          securityFields[currentIndex] &&
          !securityFields[currentIndex].roleIdList.includes(roleId)
        ) {
          securityFields[currentIndex].roleIdList.push(roleId);
        }

        return { ...prev, securityFields };
      });
      setFilterRoles(undefined);
    },
    [currentIndex, setFormState]
  );

  const onRemoveRole = useCallback(
    (roleId: string) => {
      setFormState((prev) => {
        const securityFields = [...prev.securityFields];

        if (securityFields[currentIndex]) {
          securityFields[currentIndex].roleIdList = securityFields[currentIndex].roleIdList.filter(
            (id) => id !== roleId
          );
        }

        return { ...prev, securityFields };
      });
      setFilterRoles(undefined);
    },
    [currentIndex, setFormState]
  );

  return {
    open,
    setOpen,

    fields,
    moment,
    accordionTitle,
    dataElementInfo,

    filterRoles,
    setFilterRoles,
    filteredRoles,
    selectedRoles,
    onSelectRole,
    onRemoveRole,
  };
};
