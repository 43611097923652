import {
  Box,
  Button,
  Callout,
  ChevronSvgIcon,
  ComboBox,
  Divider,
  DocumentViewer,
  Spinner,
  TapArea,
  Text,
  Tooltip,
  isIconSelected,
  modalZIndex,
  useColors,
  useComponentBreakpoint,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import type {
  MimeType,
  ParametersObject,
  TemplateWizardDefinition,
} from "@prodoctivity/shared/src/index-types";
import type {
  DocumentTypeInfo,
  EcmDocument,
  HttpExecuteDataLinkRequest,
  HttpExecuteDataLinkResponse,
} from "@prodoctivity/types";
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useMemo, useState } from "react";

import type { DocumentViewerPanelKeys } from "@prodoctivity/design-system";
import { AudioVideoPlayer, BoxWithRef } from "@prodoctivity/design-system";
import { ProDoctivityFormWrapper } from "@prodoctivity/prodoctivity-form-v5";
import { MimeTypes } from "@prodoctivity/shared";
import { MutableRefObject } from "react";
import { z } from "zod";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../link-templates";
import { SendViaEmailPage } from "../../pages/StandAloneViewer/SendViaEmailPage";
import { DocumentCollectionSVG } from "../../svg/DocumentCollectionSVG";
import { DocumentGenerationSVG } from "../../svg/DocumentGenerationSVG";
import { DocumentInfoSVG } from "../../svg/DocumentInfoSVG";
import { DocumentNoResultsSVG } from "../../svg/DocumentNoResultsSVG";
import { DocumentPropertiesSVG } from "../../svg/DocumentPropertiesSVG";
import { DocumentVersionSVG } from "../../svg/DocumentVersionSVG";
import { EditIconsSvg } from "../../svg/EditIconsSvg";
import { HistoryIconsSvg } from "../../svg/HistoryIconsSvg";
import { ListSvg } from "../../svg/ListSvg";
import { downloadZipFile } from "../../utils";
import { DocumentBookmarkTags } from "../DocumentBookmarkTags/DocumentBookmarkTags";
import { ViewerDocumentCollection } from "../Documents/ViewerDocumentCollection";
import { ViewerDocumentGenerationToken } from "../Documents/ViewerDocumentGenerationToken";
import { ViewerDocumentHistory } from "../Documents/ViewerDocumentHistory";
import { ViewerDocumentProperties } from "../Documents/ViewerDocumentProperties";
import { ViewerDocumentRecordActionLog } from "../Documents/ViewerDocumentRecordActionLog";
import { ViewerDocumentShared } from "../Documents/ViewerDocumentShared";
import { ViewerDocumentVersions } from "../Documents/ViewerDocumentVersions";
import { ViewerSignatureComponent } from "../Documents/ViewerSignatureWidget";
import { LookupDocument, LookupDocumentButton, StateProps } from "../LookupDocument/LookupDocument";
import { NotificationMessage } from "../NotificationMessage";
import { OrganizationNavigate } from "../OrganizationNavigate";
import { useDocumentWordToPdf } from "../hooks";
import { DocumentViewerCloudIcon } from "./DocumentViewerCloudIcon";
import { useDocumentViewerWrapper } from "./hooks";

type Props = {
  src: Array<Blob | string>;
  selectedPanel: DocumentViewerPanelKeys;
  setSelectedPanel(panel: DocumentViewerPanelKeys): void;
  evictCacheEntries?: () => void;
  readOnly?: boolean;
  contentType?: MimeType;
  height?: string;
  ecmDocument?: EcmDocument;
  documentTypeInfo?: DocumentTypeInfo;
  wizardDefinition?: TemplateWizardDefinition;
  onDocumentDelete?: () => void;
};

export const DocumentViewerWrapperFilter$Schema = z.strictObject({
  panel: z
    .enum([
      "none",
      "data",
      "update",
      "change",
      "generations",
      "history",
      "versions",
      "info",
      "collections",
      "share",
      "exportViaEmail",
      "myLists",
    ])
    .optional()
    .default("none"),
});

export function DocumentViewerWrapper({
  src,
  selectedPanel,
  setSelectedPanel,
  evictCacheEntries,
  readOnly,
  contentType = MimeTypes.MsWordDocument,
  ecmDocument,
  documentTypeInfo,
  onDocumentDelete,
}: Props) {
  const { colors } = useColors();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const {
    resources,
    moment,
    state,
    setState,
    onFormValuesChange,
    eventManager,
    formContainerRef,
    isPanelHidden,
    srcMapped,
    setIsPanelHidden,
    canViewDocument,
    canEditDocument,
    isLastVersion,
    canDeleteDocument,
    mutateDeleteDocument,
    isRestoring,
    generateDocument,
    execDataLink,
    isLoading,
    onSelect,
    data,
    documentTypeData,
    documentTypeOptions,
    selectedDocumentTypeOption,
    setSelectedDocumentTypeOptions,
    handleFileUpload,
    onFilesChange,
    clickFileInput,
    goToLastVersion,
    fileInputRef,
    acceptedFormats,
    handleDocumentPrintAndDownload,
    setIsPrintScreenVisible,
    isPrintScreenVisible,
    isUpdating,
    documentLoadingState,
    setDocumentLoadingState,
    isDocumentSearchOpen,
    setDocumentSearchOpen,
  } = useDocumentViewerWrapper(
    src,
    contentType,
    setSelectedPanel,
    evictCacheEntries,
    ecmDocument,
    onDocumentDelete
  );

  const wordToPdfHookRef = useDocumentWordToPdf(
    ecmDocument?.documentVersionId || "",
    ecmDocument?.documentVersionId
  );

  const isVideoFormat = srcMapped.map((f) => f.type).includes("video/mp4");
  const isAudioFormat = srcMapped.map((f) => f.type).includes("audio/mpeg");
  const audioFormat = isAudioFormat ? "audio/mpeg" : undefined;

  const { breakpoint } = useDesignBreakpoint();
  const noResults = useCallback(() => {
    return (
      <Box display="flex" direction="column" flex="grow" alignItems="center">
        <DocumentNoResultsSVG width={300} height={300} />
        <Text size="400" color={colors.neutral700} weight="bold">
          {resources.noResultsFound}
        </Text>
      </Box>
    );
  }, [colors.neutral700, resources.noResultsFound]);

  const renderDocumentGenerationToken = useCallback(() => {
    return {
      tooltip: resources.generationDocuments,
      icon: (
        <Box
          minWidth={48}
          minHeight={48}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DocumentGenerationSVG
            width={25}
            height={25}
            color={selectedPanel === "generations" ? colors.primary : undefined}
          />
        </Box>
      ),
      view:
        ecmDocument && ecmDocument.generationToken ? (
          <ViewerDocumentGenerationToken document={ecmDocument} />
        ) : (
          noResults()
        ),
      title: (
        <Text weight="bold" size="200" color={colors.primary}>
          {resources.generationDocuments}
        </Text>
      ),
    };
  }, [colors.primary, ecmDocument, noResults, resources.generationDocuments, selectedPanel]);

  const renderDocumentProperties = useCallback(() => {
    return {
      tooltip: resources.properties,
      icon: (
        <Box
          minWidth={48}
          minHeight={48}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DocumentPropertiesSVG
            width={25}
            height={25}
            color={selectedPanel === "info" ? colors.primary : undefined}
          />
        </Box>
      ),
      view: ecmDocument ? (
        <ViewerDocumentProperties
          document={ecmDocument}
          contentType={contentType}
          canDeleteDocument={canDeleteDocument}
          deleteDocument={mutateDeleteDocument}
        />
      ) : (
        noResults()
      ),
      title: (
        <Text weight="bold" size="200" color={colors.primary}>
          {resources.properties}
        </Text>
      ),
    };
  }, [
    canDeleteDocument,
    colors.primary,
    contentType,
    ecmDocument,
    mutateDeleteDocument,
    noResults,
    resources.properties,
    selectedPanel,
  ]);

  const renderDocumentVersions = useCallback(() => {
    return {
      tooltip: resources.versions,
      icon: (
        <Box
          minWidth={48}
          minHeight={48}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DocumentVersionSVG
            width={25}
            height={25}
            color={selectedPanel === "versions" ? colors.primary : undefined}
          />
        </Box>
      ),
      view: ecmDocument ? (
        <Box>
          {!isLastVersion && (
            <Callout
              type="info"
              iconAccessibilityLabel={resources.restoreToThisVersion}
              message={resources.restoreToThisVersionDesc}
              primaryAction={{
                accessibilityLabel: resources.restoreToThisVersion,
                role: "button",
                label: resources.restoreToThisVersion,
                onClick: () =>
                  eventManager.publish({
                    type: "restore-document-version",
                    value: {},
                  }),
                disabled: isRestoring,
              }}
              secondaryAction={{
                accessibilityLabel: resources.goToLastVersion,
                role: "button",
                label: resources.goToLastVersion,
                onClick: goToLastVersion,
              }}
            />
          )}
          <ViewerDocumentVersions document={ecmDocument} />
        </Box>
      ) : (
        noResults()
      ),
      title: (
        <TapArea>
          <Text weight="bold" size="200" color={colors.primary}>
            {resources.versions}
          </Text>
        </TapArea>
      ),
    };
  }, [
    colors.primary,
    goToLastVersion,
    isLastVersion,
    noResults,
    ecmDocument,
    isRestoring,
    resources.goToLastVersion,
    resources.restoreToThisVersion,
    resources.restoreToThisVersionDesc,
    resources.versions,
    selectedPanel,
    eventManager,
  ]);

  const renderDocumentCollections = useCallback(() => {
    return {
      tooltip: resources.collections,
      icon: (
        <Box
          minWidth={48}
          minHeight={48}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DocumentCollectionSVG
            width={25}
            height={25}
            color={selectedPanel === "collections" ? colors.primary : undefined}
          />
        </Box>
      ),
      view:
        (ecmDocument?.documentCollectionList || []).length > 0 ? (
          <Box>
            <Box display="flex" direction="column">
              {ecmDocument?.documentCollectionList.map((collection) => (
                <Box key={collection.instanceId}>
                  <ViewerDocumentCollection
                    instanceId={collection.instanceId}
                    configId={collection.configId}
                    documentId={ecmDocument.documentId}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          noResults()
        ),
      title: (
        <Text weight="bold" size="200" color={colors.primary}>
          {resources.collections}
          {ecmDocument &&
            ecmDocument.documentCollectionList &&
            ecmDocument.documentCollectionList.length > 1 &&
            ` (${ecmDocument.documentCollectionList.length}) `}
        </Text>
      ),
    };
  }, [colors.primary, ecmDocument, noResults, resources.collections, selectedPanel]);

  const renderShareMenuOption = useCallback(() => {
    return {
      tooltip: resources.sendViaEmailPage.shareDocument,
      icon: <></>,
      title: (
        <Text weight="bold" size="200" color={colors.primary}>
          {resources.sendViaEmailPage.shareDocument}
        </Text>
      ),
      view: ecmDocument ? <SendViaEmailPage document={ecmDocument} /> : noResults(),
    };
  }, [resources, ecmDocument, colors, noResults]);

  const renderDocumentUpdate = useCallback(() => {
    return {
      tooltip: resources.updateDocument,
      icon: (
        <Box
          minWidth={48}
          minHeight={48}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <EditIconsSvg
            width={25}
            height={25}
            color={selectedPanel === "update" ? colors.primary : colors.subtle}
          />
        </Box>
      ),
      view: (
        <Box>
          {ecmDocument?.templateId && ecmDocument?.generationToken ? (
            <Callout
              type="recommendation"
              iconAccessibilityLabel={resources.generate}
              message={resources.generatedByProDoctivity}
              primaryAction={{
                accessibilityLabel: resources.generate,
                role: "button",
                label: resources.generate,
                onClick: generateDocument,
              }}
              secondaryAction={{
                accessibilityLabel: resources.uploadFiles,
                role: "button",
                label: resources.uploadFiles,
                onClick: clickFileInput,
              }}
            />
          ) : (
            <Callout
              type="recommendation"
              iconAccessibilityLabel={resources.updateDocumentBinaries}
              message={resources.updateDocumentBinaries}
              primaryAction={{
                accessibilityLabel: resources.uploadFiles,
                role: "button",
                label: resources.uploadFiles,
                onClick: clickFileInput,
              }}
            />
          )}
          {ecmDocument?.templateId && ecmDocument?.generationToken ? null : (
            <Box column={12} alignItems="center" padding={2}>
              <ComboBox
                id="documentTypeVersionId"
                label=""
                disabled={(documentTypeData?.documentTypes || []).length <= 0}
                onSelect={(e) => {
                  const items = {
                    label: e.item.label,
                    subtext: undefined,
                    value: e.item.value,
                  };

                  onSelect(e.item.value);
                  setSelectedDocumentTypeOptions(items);
                }}
                size="md"
                placeholder={data?.documentType?.name || ecmDocument?.documentTypeName}
                selectedOption={selectedDocumentTypeOption}
                options={documentTypeOptions}
                accessibilityClearButtonLabel={resources.clear}
                errorMessage={!data ? resources.required : undefined}
              />
            </Box>
          )}
          <LookupDocument
            onChange={setDocumentLoadingState}
            isDocumentSearchOpen={isDocumentSearchOpen}
            setDocumentSearchOpen={setDocumentSearchOpen}
          />
          <LookupDocumentButton onchange={setDocumentSearchOpen} />
          <GeneralInfo
            formContainerRef={formContainerRef}
            documentTypeInfo={documentTypeInfo}
            document={ecmDocument}
            isEdit={true}
            onFormValuesChange={onFormValuesChange}
            documentTypeSelected={data}
            executeDataLink={execDataLink}
            isLoading={isLoading}
            context={documentLoadingState}
          />
          <Box display="flex" direction="column" gap={1}>
            <Box padding={2}>
              <Divider />
            </Box>
            <Box paddingX={2} width={"100%"} display="flex" justifyContent="end">
              <Button
                accessibilityLabel={resources.cancel}
                onClick={() => setSelectedPanel("none")}
                color={"white"}
                text={resources.cancel}
              />
              <Box margin={2} />
              <Button
                accessibilityLabel={resources.save}
                disabled={isUpdating}
                onClick={() => eventManager.publish({ type: "save-with-payload", value: {} })}
                text={resources.save}
                color={"blue"}
              />
            </Box>
          </Box>
        </Box>
      ),
      title: (
        <Text weight="bold" size="200" color={colors.primary}>
          {resources.updateDocument}
        </Text>
      ),
    };
  }, [
    clickFileInput,
    colors.primary,
    colors.subtle,
    data,
    ecmDocument,
    documentLoadingState,
    documentTypeData?.documentTypes,
    documentTypeInfo,
    documentTypeOptions,
    execDataLink,
    formContainerRef,
    generateDocument,
    isDocumentSearchOpen,
    isLoading,
    isUpdating,
    onFormValuesChange,
    onSelect,
    resources.cancel,
    resources.clear,
    resources.generate,
    resources.generatedByProDoctivity,
    resources.required,
    resources.save,
    resources.updateDocument,
    resources.updateDocumentBinaries,
    resources.uploadFiles,
    selectedDocumentTypeOption,
    selectedPanel,
    setDocumentLoadingState,
    setDocumentSearchOpen,
    setSelectedDocumentTypeOptions,
    setSelectedPanel,
    eventManager,
  ]);

  const renderDocumentHistory = useCallback(() => {
    return {
      tooltip: resources.changes,
      icon: (
        <Tooltip text={resources.changes}>
          <Box marginStart={2}>
            <Box
              minWidth={48}
              minHeight={48}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <TapArea>
                <Box
                  minWidth={"100%"}
                  minHeight={"100%"}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  paddingX={1}
                >
                  <HistoryIconsSvg
                    color={selectedPanel === "change" ? colors.primary : undefined}
                  />
                </Box>
              </TapArea>
            </Box>
          </Box>
        </Tooltip>
      ),
      view: ecmDocument ? (
        <ViewerDocumentHistory document={ecmDocument} documentTypeInfo={documentTypeInfo} />
      ) : (
        noResults()
      ),
      title: (
        <TapArea>
          <Text weight="bold" size="200" color={colors.primary}>
            {resources.changes}
          </Text>
        </TapArea>
      ),
    };
  }, [colors.primary, ecmDocument, documentTypeInfo, noResults, resources.changes, selectedPanel]);

  const renderDocumentChange = useCallback(() => {
    return {
      tooltip: resources.history,
      icon: <></>,
      view: ecmDocument ? <ViewerDocumentRecordActionLog document={ecmDocument} /> : noResults(),
      title: (
        <TapArea>
          <Text weight="bold" size="200" color={colors.primary}>
            {resources.history}
          </Text>
        </TapArea>
      ),
    };
  }, [colors.primary, ecmDocument, noResults, resources]);

  const renderMyLists = useCallback(() => {
    return {
      tooltip: resources.myLists,
      icon: (
        <Box
          minWidth={48}
          minHeight={48}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ListSvg
            width={25}
            height={25}
            color={selectedPanel === "myLists" ? colors.primary : undefined}
          />
        </Box>
      ),
      view: ecmDocument ? (
        <DocumentBookmarkTags documentId={ecmDocument.documentId} viewMode={undefined} />
      ) : (
        noResults()
      ),
      title: (
        <Text weight="bold" size="200" color={colors.primary}>
          {resources.myLists}
        </Text>
      ),
    };
  }, [colors.primary, ecmDocument, noResults, resources.myLists, selectedPanel]);

  const renderDocumentShared = useCallback(() => {
    return {
      tooltip: resources.actions,
      icon: <></>,
      view: ecmDocument ? (
        <ViewerDocumentShared
          setSelectedPanel={setSelectedPanel}
          setIsPrintScreenVisible={setIsPrintScreenVisible}
          ecmDocument={ecmDocument}
          deleteDocument={mutateDeleteDocument}
          isRestoring={isRestoring}
        />
      ) : (
        noResults()
      ),
      title: (
        <TapArea>
          <Text weight="bold" size="200" color={colors.primary}>
            {resources.actions}
          </Text>
        </TapArea>
      ),
    };
  }, [
    colors.primary,
    ecmDocument,
    isRestoring,
    mutateDeleteDocument,
    noResults,
    resources.actions,
    setIsPrintScreenVisible,
    setSelectedPanel,
  ]);

  const renderSignDocument = useCallback(() => {
    return {
      tooltip: resources.signatures,
      icon: (
        <DocumentInfoSVG
          width={50}
          height={30}
          color={selectedPanel === "sign" ? colors.primary : undefined}
        />
      ),
      title: (
        <Text weight="bold" size="200" color={colors.primary}>
          {resources.signatures}
        </Text>
      ),
      view: (
        <ViewerSignatureComponent
          document={ecmDocument}
          eventManager={eventManager}
          goToLastVersionCallback={goToLastVersion}
          defaultOpenTab="signature"
        />
      ),
    };
  }, [
    resources.signatures,
    selectedPanel,
    colors.primary,
    ecmDocument,
    eventManager,
    goToLastVersion,
  ]);

  const documentValuesToDisplay: Record<
    DocumentViewerPanelKeys,
    | {
        tooltip: string;
        icon: JSX.Element;
        view: JSX.Element;
        title: JSX.Element;
      }
    | undefined
  > = useMemo(() => {
    return {
      none: undefined,
      data: {
        tooltip: resources.info,
        icon: (
          <Box
            minWidth={48}
            minHeight={48}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <DocumentInfoSVG
              width={25}
              height={25}
              color={selectedPanel === "data" ? colors.primary : undefined}
            />
          </Box>
        ),
        title: (
          <Box>
            <Text weight="bold" size="200" color={colors.primary}>
              {ecmDocument?.documentTypeName}
            </Text>
          </Box>
        ),
        view: (
          <Box padding={4}>
            <GeneralInfo
              formContainerRef={formContainerRef}
              documentTypeInfo={documentTypeInfo}
              document={ecmDocument}
              isEdit={false}
              executeDataLink={execDataLink}
              context={undefined}
            />
          </Box>
        ),
      },
      generations: renderDocumentGenerationToken(),
      info: renderDocumentProperties(),
      versions: renderDocumentVersions(),
      collections:
        ecmDocument && ecmDocument.documentCollectionList.length
          ? renderDocumentCollections()
          : undefined,
      change: renderDocumentHistory(),
      update: canEditDocument ? renderDocumentUpdate() : undefined,
      exportViaEmail: renderShareMenuOption(),
      share: renderDocumentShared(),
      sign: renderSignDocument(),
      history: renderDocumentChange(),
      myLists: renderMyLists(),
    };
  }, [
    canEditDocument,
    colors.primary,
    documentTypeInfo,
    ecmDocument,
    execDataLink,
    formContainerRef,
    renderDocumentChange,
    renderDocumentCollections,
    renderDocumentGenerationToken,
    renderDocumentHistory,
    renderDocumentProperties,
    renderDocumentShared,
    renderDocumentUpdate,
    renderDocumentVersions,
    renderMyLists,
    renderShareMenuOption,
    renderSignDocument,
    resources.info,
    selectedPanel,
  ]);

  const panelInfo = useMemo(() => {
    return documentValuesToDisplay[selectedPanel];
  }, [documentValuesToDisplay, selectedPanel]);

  const isPanelVisible = useCallback(
    (val: DocumentViewerPanelKeys): boolean => {
      switch (val) {
        case "collections":
          return !!ecmDocument && !!ecmDocument.documentCollectionList.length;
        case "generations":
          return !!ecmDocument && !!ecmDocument.generationToken;
        default:
          return true;
      }
    },
    [ecmDocument]
  );

  const customIcon = useMemo(() => {
    return (
      <DocumentViewerCloudIcon
        document={ecmDocument}
        panelInfoValue={selectedPanel}
        setPanelInfoValue={setSelectedPanel}
        setIsPanelHidden={setIsPanelHidden}
        isIconSelected={isIconSelected}
        isPanelVisible={isPanelVisible}
      />
    );
  }, [ecmDocument, isPanelVisible, selectedPanel, setIsPanelHidden, setSelectedPanel]);

  if (ecmDocument && !canViewDocument) {
    return <OrganizationNavigate to={organizationLinkTemplates.home()} />;
  }

  return (
    <Box display="flex" direction="column" flex="grow" height="100%">
      <input
        name="importInput"
        type="file"
        accept={acceptedFormats}
        multiple={true}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />

      <Box maxHeight={"100vh"} height={"100%"} display="flex" color={colors.neutral200} column={12}>
        {state.toastMessage && (
          <NotificationMessage
            type={state.toastMessage.type}
            message={state.toastMessage.message}
            position="bottom-left"
            handleDismiss={() => setState((prev) => ({ ...prev, toastMessage: undefined }))}
          />
        )}
        <Box
          display="flex"
          direction="column"
          width={breakpoint === "small" && !isPanelHidden ? 0 : "100%"}
          flex="grow"
        >
          {isVideoFormat || isAudioFormat ? (
            <AudioVideoPlayer
              src={srcMapped}
              strategy="normal"
              customIcon={customIcon}
              format={audioFormat}
            />
          ) : (
            <DocumentViewer
              src={state.mimeTypeSelected ? state.files.map((f) => f.file) : srcMapped}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              height={"100%"}
              mimeType={state.mimeTypeSelected ? (state.mimeTypeSelected as MimeType) : contentType}
              readOnly={readOnly}
              strategy="ecm-document"
              customIcon={isFullScreen ? <></> : customIcon}
              isPanelOpen={selectedPanel}
              onFilesChange={onFilesChange}
              isPrintScreenVisible={isPrintScreenVisible}
              setIsPrintScreenVisible={setIsPrintScreenVisible}
              handleDocumentPrintAndDownload={handleDocumentPrintAndDownload}
              resources={resources}
              binaries={ecmDocument ? ecmDocument.binaries : []}
              downloadZipFile={downloadZipFile}
              name={ecmDocument ? ecmDocument.name : ""}
              sendViaEmail={() => {
                setIsPanelHidden(false);
                setSelectedPanel("exportViaEmail");
              }}
              signDocument={() => {
                setIsPanelHidden(false);
                setSelectedPanel("sign");
              }}
              myLists={() => {
                setIsPanelHidden(false);
                setSelectedPanel("myLists");
              }}
              canDeleteDocument={canDeleteDocument}
              deleteDocument={mutateDeleteDocument}
              wordToPdfHookRef={wordToPdfHookRef}
              eventManager={eventManager}
              moment={moment}
            />
          )}
        </Box>
        {isPanelHidden || panelInfo === undefined || selectedPanel === "none" ? null : (
          <Box
            display="flex"
            height="100%"
            flex="grow"
            maxWidth={breakpoint === "small" ? undefined : 500}
            minWidth={breakpoint === "small" ? 320 : 400}
            direction="row"
            column={4}
            color={colors.neutral200}
            zIndex={breakpoint === "small" ? modalZIndex : undefined}
          >
            <Box height={"100%"} width={1} color={colors.neutral600}></Box>
            <Box maxWidth={500} width={"100%"}>
              <Box height={"100%"} overflow="auto" minHeight={60} display="flex" alignItems="start">
                <Box display="flex" direction="column" flex="grow" height={"100%"} width={"100%"}>
                  <Box
                    display="flex"
                    justifyContent="start"
                    alignItems="start"
                    color={colors.neutral200}
                    height={30}
                  >
                    <Box display="flex" flex="shrink">
                      <TapArea
                        onTap={() => {
                          setIsPanelHidden(!isPanelHidden);
                          setSelectedPanel("none");
                        }}
                      >
                        <ChevronSvgIcon
                          color={colors.primary}
                          direction={isPanelHidden ? "left" : "right"}
                        />
                      </TapArea>
                    </Box>
                    <Box>
                      <Box margin={2} />
                      {panelInfo.title}
                    </Box>
                  </Box>
                  <Box display="flex" direction="column" flex="grow">
                    {panelInfo.view}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const GeneralInfo: FunctionComponent<{
  formContainerRef: MutableRefObject<HTMLDivElement | null>;
  documentTypeInfo: DocumentTypeInfo | undefined;
  document: EcmDocument | undefined;
  isEdit?: boolean;
  documentTypeSelected?:
    | {
        documentType: DocumentTypeInfo;
      }
    | {
        documentType: undefined;
      }
    | undefined;
  totalPage?: Dispatch<SetStateAction<number>>;
  onFormValuesChange?: (values: ParametersObject) => void;
  executeDataLink: (
    dataLinkId: string,
    dataLinkConfigVersionId: string,
    inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
  ) => Promise<HttpExecuteDataLinkResponse["payload"]>;
  isLoading?: boolean;
  context: StateProps | undefined;
}> = ({
  formContainerRef,
  documentTypeInfo,
  document,
  isEdit,
  onFormValuesChange,
  documentTypeSelected,
  executeDataLink,
  isLoading,
  context,
}) => {
  const { resources, moment } = useAppTranslation();
  const { componentBreakpoint } = useComponentBreakpoint(formContainerRef, "large");

  const documentData = useMemo(() => {
    return document ? document.data : undefined;
  }, [document]);

  if (!documentTypeInfo || !document) {
    return <Spinner show={true} accessibilityLabel={resources.loading} />;
  }

  const dataLinkMappings = documentTypeSelected?.documentType?.dataLinkMappings
    ? documentTypeSelected?.documentType?.dataLinkMappings
    : [];

  const contextDefinition = !documentTypeSelected?.documentType?.contextDefinition
    ? documentTypeInfo?.contextDefinition
    : documentTypeSelected?.documentType?.contextDefinition;

  const wizardDefinition = !documentTypeSelected?.documentType?.wizardDefinition
    ? documentTypeInfo?.wizardDefinition
    : documentTypeSelected?.documentType?.wizardDefinition;

  const data = context?.context ? context?.context : documentData;
  return (
    <BoxWithRef ref={formContainerRef} width={"100%"}>
      {isLoading || context?.isLoading ? (
        <Spinner show={true} accessibilityLabel={resources.loading} />
      ) : (
        <ProDoctivityFormWrapper
          key={`doc_${document?.documentId}`}
          contextDefinition={contextDefinition}
          wizardDefinition={wizardDefinition}
          initialFormValues={data}
          summaryMode={!isEdit}
          onFormValuesChange={onFormValuesChange}
          defaultColumns={"1"}
          readOnly={!isEdit}
          componentBreakpoint={componentBreakpoint || "lg"}
          moment={moment}
          dataLinkMappings={dataLinkMappings}
          executeDataLink={executeDataLink}
          paginate={false}
          resources={resources}
        />
      )}
    </BoxWithRef>
  );
};
