import {
  GlobalPermissionType$Schema,
  getCollectionsPermissionsList,
  getDocumentTypePermissionList,
} from "@prodoctivity/shared";
import type {
  CollectionPermissionType,
  DocumentPermissionType,
  GlobalPermissionType,
  SearchByGroupsFilter,
} from "@prodoctivity/shared/src/index-types";
import type {
  CollectionRolePermission,
  DocumentTypeRolePermission,
  EmptyObject,
  HttpGetDocumentCollectionConfigListResponse,
  HttpGetDocumentTypeListRequest,
  HttpListAllOrganizationUsersResponse,
  ListableDocumentType,
  ListableTemplateWithCombined,
  OrganizationPermissionPart,
  TemplateRolePermission,
} from "@prodoctivity/types";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";
import { useDebounceValue } from "usehooks-ts";
import { z } from "zod";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { ToastMessageType } from "../../../components/NotificationMessage";
import { useAllDocumentTypes, usePaginatedDataEndpoint } from "../../../components/hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useServices } from "../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../link-templates";
import { useDocumentType } from "../DocumentType/hooks";

type ExpandedElementList = {
  docTypesExpanded: boolean;
  templatesExpanded: boolean;
  collectionsTypesExpanded: boolean;
};

export const useRoleManagementList = (refetch: () => void) => {
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const [toastMessage, setToastMessage] = useState<ToastMessageType | undefined>(undefined);
  const { deleteOrganizationRole } = useServices();

  const { mutate: mutateDeleteRole, isLoading: isMutating } = useMutation(deleteOrganizationRole, {
    onSuccess: useCallback(
      (data: EmptyObject) => {
        if (data) {
          setToastMessage({ type: "success", message: resources.success });
          setTimeout(() => setToastMessage(undefined), 5000);
          refetch();
        }
      },
      [refetch, resources.success]
    ),
    onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
      if (error.response.data.errors.length > 0) {
        setToastMessage({ type: "error", message: error.response.data.errors[0].message });
        setTimeout(() => setToastMessage(undefined), 5000);
      }
    },
  });

  return {
    resources,
    organizationNavigate,

    toastMessage,
    setToastMessage,

    mutateDeleteRole,
    isMutating,
  };
};

export type RoleManagementState = {
  id?: string;
  name?: string;
  allowOnlyOwnedDocuments: boolean;
  globalPermissions: GlobalPermissionType[];
  users: Array<string>;
  documentTypePermissions: DocumentTypeRolePermission[];
  templatePermissions: TemplateRolePermission[];
  collectionTypesPermissions: CollectionRolePermission[];
  errors?: { [key: string]: string };
  toastMessage?: ToastMessageType;
};

const RoleTabType$Schema = z
  .enum(["properties", "users", "special", "permissions", "security-fields"])
  .catch("properties")
  .default("properties");

export const useRoleManagement = (isUpdating: boolean) => {
  const { roleId } = useParams();
  const { search } = useLocation();

  const {
    fetchOrganizationAdminInfo,
    getOrganizationRole,
    createOrganizationRole,
    updateOrganizationRole,
    listAllOrganizationUsers,
  } = useServices();

  const currentTab = useMemo(() => {
    const params = new URLSearchParams(search);
    const current = params.get("tab");
    return RoleTabType$Schema.parse(current);
  }, [search]);

  const { groupList } = useDocumentType();
  const [groupListFilter, setGroupListFilter] = useState<SearchByGroupsFilter>({
    documentTypeGroup: undefined,
    documentGroupId: undefined,
  });
  const onSetGroupListFilter = useCallback((value: SearchByGroupsFilter) => {
    setGroupListFilter(value);
  }, []);

  const [docTypesFilter, setDocTypesFilter] = useDebounceValue("", 500);
  const [templatesFilter, setTemplatesFilter] = useDebounceValue("", 500);
  const [collectionsFilter, setCollectionsFilter] = useDebounceValue("", 500);

  const onDocumentTypesFilterChanged: ({ value }: { value: string }) => void = useCallback(
    ({ value }) => {
      setDocTypesFilter(value);
    },
    [setDocTypesFilter]
  );

  const onTemplatesFilterChanged: ({ value }: { value: string }) => void = useCallback(
    ({ value }) => {
      setTemplatesFilter(value);
    },
    [setTemplatesFilter]
  );
  const onCollectionFilterChanged: ({ value }: { value: string }) => void = useCallback(
    ({ value }) => {
      setCollectionsFilter(value);
    },
    [setCollectionsFilter]
  );

  const [formState, setFormState] = useState<RoleManagementState>({
    allowOnlyOwnedDocuments: false,
    users: [],
    globalPermissions: [],
    documentTypePermissions: [],
    templatePermissions: [],
    collectionTypesPermissions: [],
  });

  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();

  const breadcrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.rolesManagement.roles,
        url: organizationLinkTemplates.manageRoles(),
      },
      { type: "text", name: `${isUpdating ? resources.edit : resources.addNew}` },
    ];
  }, [isUpdating, resources]);

  const fetchRole = useCallback(async () => {
    if (!roleId) {
      return { role: undefined };
    }
    return await getOrganizationRole(roleId);
  }, [roleId, getOrganizationRole]);

  const { isLoading } = useOrganizationQuery(`roles/${roleId}`, fetchRole, {
    onSuccess: (data) => {
      if (data.role) {
        setFormState((prev) => ({
          ...prev,
          id: data.role.id,
          name: data.role.name,
          allowOnlyOwnedDocuments: data.role.allowOnlyOwnedDocuments || false,
          globalPermissions: data.role.globalPermissions || [],
          documentTypePermissions: data.role.documentTypePermissions || [],
          templatePermissions: data.role.templatePermissions || [],
          collectionTypesPermissions: data.role.collectionPermissions || [],
          users: data.role.users,
        }));
      }
    },
    refetchOnMount: true,
  });

  const { data: organizationInfoResponse } = useOrganizationQuery(
    `organization-admin-info`,
    fetchOrganizationAdminInfo
  );

  const { allDocumentTypes: documentTypesResponse } = useAllDocumentTypes();

  const isSaveButtonDisabled = useMemo(() => {
    if (!formState.name) {
      return true;
    }
    return false;
  }, [formState]);

  const fetchCreateOrUpdateRole = useCallback(
    async (
      params: { id?: string; name: string; users: Array<string> } & OrganizationPermissionPart
    ) => {
      const { id, ...payload } = params;
      if (!id) {
        return await createOrganizationRole(payload);
      }
      return await updateOrganizationRole({ roleId: id, ...payload });
    },
    [updateOrganizationRole, createOrganizationRole]
  );

  const {
    mutate: mutateRole,
    isLoading: isMutating,
    isSuccess,
  } = useMutation(fetchCreateOrUpdateRole, {
    onSuccess: (data) => {
      if (data.id) {
        setFormState((prev) => ({
          ...prev,
          toastMessage: { type: "success", message: resources.success },
        }));
        setTimeout(() => {
          organizationNavigate(organizationLinkTemplates.manageRoles());
        }, 2000);
      }
    },
    onError: (error: {
      response: { data: { errors: Array<{ message: string; errorCode: string }> } };
    }) => {
      if (error.response.data.errors.length > 0) {
        if (error.response.data.errors[0].errorCode === "role-exists") {
          setFormState((prev) => ({
            ...prev,
            toastMessage: {
              type: "error",
              message: resources.rolesManagement.roleWithNameAlreadyExists,
            },
          }));
        } else {
          setFormState((prev) => ({
            ...prev,
            toastMessage: { type: "error", message: error.response.data.errors[0].message },
          }));
        }

        setTimeout(() => {
          setFormState((prev) => ({
            ...prev,
            toastMessage: undefined,
          }));
        }, 3000);
      }
    },
  });

  const { data: organizationUsers } = useOrganizationQuery(
    `/organization-users`,
    listAllOrganizationUsers,
    {
      staleTime: 60 * 1000,
    }
  );

  const handleSaveChanges = useCallback(() => {
    if (isSaveButtonDisabled || !formState.name) {
      return { id: undefined };
    }

    mutateRole({
      id: formState.id,
      name: formState.name,
      users: formState.users,
      allowOnlyOwnedDocuments: formState.allowOnlyOwnedDocuments,
      globalPermissions: formState.globalPermissions.filter((permission) => {
        return GlobalPermissionType$Schema.safeParse(permission).success;
      }),
      documentTypePermissions: formState.documentTypePermissions,
      templatePermissions: formState.templatePermissions,
      collectionPermissions: formState.collectionTypesPermissions,
    });
  }, [formState, isSaveButtonDisabled, mutateRole]);

  const documentTypesPaginated = usePaginatedDataEndpoint<
    { documentTypes: ListableDocumentType[] },
    HttpGetDocumentTypeListRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "15",
    docTypesFilter || "",
    (services, currentPage, rowsPerPage, _filter) => {
      return services.getDocumentTypeList(
        currentPage,
        rowsPerPage,
        docTypesFilter,
        groupListFilter.documentTypeGroup
      );
    },
    `document_type_list_settings_page/${docTypesFilter}/${groupListFilter.documentTypeGroup}`
  );

  const templatesPaginated = usePaginatedDataEndpoint<
    { templates: ListableTemplateWithCombined[] },
    "15" | "30" | "100",
    string
  >(
    "15",
    templatesFilter || "",
    (services, currentPage, rowsPerPage, _filter) => {
      return services.fetchTemplateList(
        "templates",
        undefined,
        currentPage,
        rowsPerPage,
        templatesFilter,
        groupListFilter.documentGroupId
      );
    },
    `settings_template_list/${templatesFilter}/${groupListFilter.documentGroupId}`
  );

  const collectionsPaginated = usePaginatedDataEndpoint<
    {
      documentCollectionConfigList: HttpGetDocumentCollectionConfigListResponse["payload"]["documentCollectionConfigList"];
    },
    "15" | "30" | "50",
    string
  >(
    "15",
    collectionsFilter || "",
    (services, currentPage, rowsPerPage, _filter) => {
      return services.getDocumentCollectionConfigList(currentPage, rowsPerPage, collectionsFilter);
    },
    `settings_collection_list/${collectionsFilter}`
  );

  return {
    roleId,
    organizationInfoResponse,
    documentTypes: documentTypesResponse?.documentTypes || [],
    collectionsPaginated,
    onCollectionFilterChanged,
    currentTab: currentTab,
    breadCrumbEntries: breadcrumbEntries,
    resources,
    organizationNavigate,
    formState,
    setFormState,
    isLoading,
    isSaveButtonDisabled,
    handleSaveChanges,
    isMutating,
    isSuccess,
    organizationUsers,
    documentTypesPaginated,
    templatesPaginated,
    onDocumentTypesFilterChanged,
    onTemplatesFilterChanged,
    docTypesFilter,
    templatesFilter,
    collectionsFilter,
    groupList,
    groupListFilter,
    onSetGroupListFilter,
  };
};

export const useUserTab = (
  formState: RoleManagementState,
  users: HttpListAllOrganizationUsersResponse["payload"]["users"],
  setFormState: Dispatch<SetStateAction<RoleManagementState>>
) => {
  const [filter, setFilter] = useState<string | undefined>();
  const onSelectUser = useCallback(
    (value: string) => {
      setFormState((prev) => {
        const currentUsers = prev.users;

        if (!currentUsers.includes(value)) {
          currentUsers.push(value);
        }

        return { ...prev, users: currentUsers };
      });
      setFilter(undefined);
    },
    [setFormState]
  );

  const onRemoveUser = useCallback(
    (value: string) => {
      setFormState((prev) => {
        const currentUsers = prev.users.filter((username) => username !== value);

        return { ...prev, users: currentUsers };
      });
      setFilter(undefined);
    },
    [setFormState]
  );

  const filteredUsers = useMemo(() => {
    return users
      .filter(
        (u) =>
          !formState.users.includes(u.username) &&
          `${u.firstName} ${u.lastName}`
            .toLocaleLowerCase()
            .includes((filter || "").toLocaleLowerCase())
      )
      .map((u) => ({ label: `${u.firstName} ${u.lastName}`, value: u.username }));
  }, [users, filter, formState]);

  const selectedUsers = useMemo(() => {
    return users
      .filter((u) => formState.users.includes(u.username))
      .map((u) => ({ label: `${u.firstName} ${u.lastName}`, value: u.username }));
  }, [users, formState]);

  return {
    filteredUsers,
    selectedUsers,
    onSelectUser,
    onRemoveUser,
    filter,
    setFilter,
  };
};

export type SpecialPermission = {
  permissionKey: GlobalPermissionType;
  label: string;
  description: string;
};

export const useSpecialPermissionsTab = (
  resources: ReturnType<typeof useAppTranslation>["resources"],
  formState: RoleManagementState,
  setFormState: Dispatch<SetStateAction<RoleManagementState>>
) => {
  const [filter, setFilter] = useState<string | undefined>();
  const [expandedRow, setExpandedRow] = useState<GlobalPermissionType | undefined>(undefined);

  const specialPermissions = useMemo<Array<SpecialPermission>>(() => {
    return [
      {
        permissionKey: "organization-admin",
        label: resources.rolesManagement.organizationAdmin,
        description: resources.rolesManagement.organizationAdminDesc,
      },
      {
        permissionKey: "document-collection-task",
        label: resources.rolesManagement.documentCollectionTask,
        description: resources.rolesManagement.documentCollectionTaskDesc,
      },
      {
        permissionKey: "publish-template",
        label: resources.rolesManagement.publishTemplate,
        description: resources.rolesManagement.publishTemplateDesc,
      },
      {
        permissionKey: "delete-document-collection",
        label: resources.rolesManagement.deleteDocumentCollection,
        description: resources.rolesManagement.deleteDocumentCollectionDesc,
      },
      {
        permissionKey: "delete-document",
        label: resources.rolesManagement.deleteDocument,
        description: resources.rolesManagement.deleteDocumentDesc,
      },
    ];
  }, [resources]);

  const onSelectPermission = useCallback(
    (value: GlobalPermissionType) => {
      setFormState((prev) => {
        const currentPermissions = prev.globalPermissions;

        if (!currentPermissions.includes(value)) {
          currentPermissions.push(value);
        }

        return { ...prev, globalPermissions: currentPermissions };
      });
      setFilter(undefined);
    },
    [setFormState]
  );

  const onRemovePermission = useCallback(
    (value: string) => {
      setFormState((prev) => {
        const currentPermissions = prev.globalPermissions.filter(
          (permission) => permission !== value
        );

        return { ...prev, globalPermissions: currentPermissions };
      });
      setFilter(undefined);
      if (value === expandedRow) {
        setExpandedRow(undefined);
      }
    },
    [setFormState, expandedRow]
  );

  const filteredPermissions = useMemo(() => {
    return specialPermissions
      .filter(
        (u) =>
          !formState.globalPermissions.includes(u.permissionKey) &&
          u.label.toLocaleLowerCase().includes((filter || "").toLocaleLowerCase())
      )
      .map((p) => ({ label: p.label, value: p.permissionKey }));
  }, [specialPermissions, filter, formState]);

  const selectedPermissions = useMemo(() => {
    return specialPermissions.filter((u) => formState.globalPermissions.includes(u.permissionKey));
  }, [specialPermissions, formState]);

  return {
    filteredPermissions,
    selectedPermissions,
    onSelectPermission,
    onRemovePermission,

    filter,
    setFilter,

    expandedRow,
    setExpandedRow,
  };
};

export const useDocumentPermissionTab = (
  action: "allow" | "deny",
  formState: RoleManagementState,
  documentTypesPaginated: ReturnType<typeof useRoleManagement>["documentTypesPaginated"],
  templatesPaginated: ReturnType<typeof useRoleManagement>["templatesPaginated"],
  collectionsPaginated: ReturnType<typeof useRoleManagement>["collectionsPaginated"],
  setFormState: Dispatch<SetStateAction<RoleManagementState>>
) => {
  const [expandedList, setExpanedList] = useState<ExpandedElementList>({
    docTypesExpanded: false,
    templatesExpanded: false,
    collectionsTypesExpanded: false,
  });

  const changeListExpanedState = useCallback((value: ExpandedElementList) => {
    setExpanedList(value);
  }, []);
  const documentTypes = useMemo(() => {
    return (documentTypesPaginated.paginatedData?.documentTypes || []).map((dt) => ({
      id: dt.documentTypeId,
      name: dt.name,
    }));
  }, [documentTypesPaginated]);

  const templates = useMemo(() => {
    return (templatesPaginated.paginatedData?.templates || []).reduce(
      (arr: { id: string; name: string }[], template) => {
        if (template.type === "template") {
          arr.push({
            id: template.templateId,
            name: template.name,
          });
        }
        return arr;
      },
      []
    );
  }, [templatesPaginated]);

  const documentTypePermissions = useMemo(() => {
    return formState.documentTypePermissions.map((dt) => ({
      targetId: dt.documentTypeId,
      action: dt.action,
      permissionType: dt.permissionType,
    }));
  }, [formState]);

  const templatesPermissions = useMemo(() => {
    return formState.templatePermissions.map((dt) => ({
      targetId: dt.templateId,
      action: dt.action,
      permissionType: dt.permissionType,
    }));
  }, [formState]);

  const collectionTarget = useMemo(() => {
    return (collectionsPaginated.paginatedData?.documentCollectionConfigList || []).map(
      (collection) => ({
        id: collection.id,
        name: collection.name,
      })
    );
  }, [collectionsPaginated.paginatedData?.documentCollectionConfigList]);

  const collectionsPermissions = useMemo(() => {
    return formState.collectionTypesPermissions.map((dt) => ({
      targetId: dt.collectionTypeId,
      action: dt.action,
      permissionType: dt.permissionType,
    }));
  }, [formState]);

  const onDocTypePermissionCheck = useCallback(
    (
      targetId: string,
      permissionKey: DocumentPermissionType,
      action: "allow" | "deny" | undefined
    ) => {
      setFormState((prev) => {
        const documentTypePermissions =
          action === undefined
            ? prev.documentTypePermissions.filter(
                (dt) => dt.documentTypeId !== targetId || dt.permissionType !== permissionKey
              )
            : prev.documentTypePermissions.map((dt) => {
                if (
                  dt.documentTypeId === targetId &&
                  dt.permissionType === permissionKey &&
                  dt.action !== action
                ) {
                  return { ...dt, action: action };
                }
                return dt;
              });
        const possibleNewPermission =
          prev.documentTypePermissions.findIndex(
            (dt) => dt.documentTypeId === targetId && dt.permissionType === permissionKey
          ) >= 0;
        if (!possibleNewPermission && action !== undefined) {
          documentTypePermissions.push({
            documentTypeId: targetId,
            action: action,
            permissionType: permissionKey,
          });
        }

        return { ...prev, documentTypePermissions };
      });
    },
    [setFormState]
  );
  const onTemplatePermissionCheck = useCallback(
    (
      targetId: string,
      permissionKey: DocumentPermissionType,
      action: "allow" | "deny" | undefined
    ) => {
      if (permissionKey === "view" || permissionKey === "edit") {
        setFormState((prev) => {
          const templatePermissions =
            action === undefined
              ? prev.templatePermissions.filter(
                  (dt) => dt.templateId !== targetId || dt.permissionType !== permissionKey
                )
              : prev.templatePermissions.map((dt) => {
                  if (
                    dt.templateId === targetId &&
                    dt.permissionType === permissionKey &&
                    dt.action !== action
                  ) {
                    return { ...dt, action: action };
                  }
                  return dt;
                });
          const possibleNewPermission =
            prev.templatePermissions.findIndex(
              (dt) => dt.templateId === targetId && dt.permissionType === permissionKey
            ) >= 0;

          if (!possibleNewPermission && action !== undefined) {
            templatePermissions.push({
              templateId: targetId,
              action: action,
              permissionType: permissionKey,
            });
          }

          return { ...prev, templatePermissions };
        });
      }
    },
    [setFormState]
  );

  const onCollectionCheck = useCallback(
    (
      targetId: string,
      permissionKey: CollectionPermissionType,
      action: "allow" | "deny" | undefined
    ) => {
      if (permissionKey === "view" || permissionKey === "assign") {
        setFormState((prev) => {
          const collectionTypesPermissions =
            action === undefined
              ? prev.collectionTypesPermissions.filter(
                  (dt) => dt.collectionTypeId !== targetId || dt.permissionType !== permissionKey
                )
              : prev.collectionTypesPermissions.map((dt) => {
                  if (
                    dt.collectionTypeId === targetId &&
                    dt.permissionType === permissionKey &&
                    dt.action !== action
                  ) {
                    return { ...dt, action: action };
                  }
                  return dt;
                });
          const possibleNewPermission =
            prev.collectionTypesPermissions.findIndex(
              (dt) => dt.collectionTypeId === targetId && dt.permissionType === permissionKey
            ) >= 0;

          if (!possibleNewPermission && action !== undefined) {
            collectionTypesPermissions.push({
              collectionTypeId: targetId,
              action: action,
              permissionType: permissionKey,
            });
          }

          return { ...prev, collectionTypesPermissions };
        });
      }
    },
    [setFormState]
  );

  const permissionsList = useMemo(() => {
    return getDocumentTypePermissionList();
  }, []);

  const collectionsPermissionsList = useMemo(() => {
    return getCollectionsPermissionsList();
  }, []);

  return {
    expanedList: expandedList,
    changeListExpanedState,
    documentTypePermissions,
    templatesPermissions,
    onDocTypePermissionCheck,
    onTemplatePermissionCheck,
    permissionsList,
    collectionsPermissionsList,
    documentTypes,
    templates,
    collectionTarget,
    collectionsPermissions,
    onCollectionCheck,
  };
};
