import type {
  DocumentViewerEvents,
  InstanceWebViewer,
  PubSubEventManagerCallback,
} from "@prodoctivity/design-system";
import {
  MimeTypesArray,
  getBase64FromBlob,
  mimeTypeToExtension,
  parseDataURI,
  shouldNever,
  stableStringify,
  toMimeType,
} from "@prodoctivity/shared";
import type { LogicalOperatorType, MimeType } from "@prodoctivity/shared/src/index-types";
import type {
  DocumentTypeResume,
  EcmDocument,
  HttpExecuteDataLinkRequest,
  HttpSaveDocumentRequest,
  IndexEntityResume,
  SearchFieldCondition,
} from "@prodoctivity/types";
import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SearchFieldOperator, SearchFilter, organizationLinkTemplates } from "../../link-templates";

import {
  ComboBoxItemType,
  DocumentViewerPanelKeys,
  usePubSub,
  useSubscribe,
} from "@prodoctivity/design-system";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { base64ToBlob } from "../../pages/GenerationStatus/GenerationStatus";
import { useGetDocumentVersionGenerationContext } from "../../pages/StandAloneViewer/hooks";
import { useCacheManager } from "../../utils";
import { useDocumentTypeVersionInfo } from "../Display/hooks";
import { useDocumentLookupState } from "../LookupDocument/hook";
import { useAllDocumentTypes } from "../hooks";
export type DocumentViewerSearchFilterState = {
  type: "document-viewer-state";
  searchFilter: SearchFilter;
  index: number;
};

function useSearchDocumentsForNavigation(filter: SearchFilter | undefined) {
  const { search } = useServices();
  const performSearch = useCallback(async () => {
    if (!filter) {
      const results: {
        results: (IndexEntityResume & {
          $score: number;
        })[];
      } = {
        results: [],
      };
      return results;
    }
    if (filter.filterType === "document") {
      const searchFieldConditions: SearchFieldCondition[] = filter.fields.map((fld) => {
        return {
          fld: fld.fld,
          op: searchFieldOperatorToLogicalOperatorType(fld.op),
          val: fld.val,
          dataType: fld.dataType,
        };
      });
      return search(
        searchFieldConditions,
        filter.pageNumber,
        filter.rowsPerPage,
        filter.q,
        filter.documentTypeIdList,
        undefined,
        undefined,
        filter.includeApproximateResults
      );
    } else {
      return {
        results: [],
      };
    }
  }, [filter, search]);

  return useOrganizationQuery(
    `search-documents-for-navigation/${stableStringify(filter)}`,
    performSearch,
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );
}

export function useDocumentViewerSearchResultsNavigator(searchFilter: SearchFilter, index: number) {
  const organizationNavigate = useOrganizationNavigate();

  const previousFilterState: DocumentViewerSearchFilterState | undefined = useMemo(() => {
    if (index === 0) {
      if (searchFilter.pageNumber === 0) {
        return undefined;
      }
      return {
        type: "document-viewer-state",
        searchFilter: { ...searchFilter, pageNumber: searchFilter.pageNumber - 1 },
        index: parseInt(searchFilter.rowsPerPage, 10) - 1,
      };
    }
    return {
      type: "document-viewer-state",
      searchFilter,
      index: index - 1,
    };
  }, [searchFilter, index]);

  const nextFilterState: DocumentViewerSearchFilterState | undefined = useMemo(() => {
    const isNextPage = index >= parseInt(searchFilter.rowsPerPage, 10) - 1;
    return {
      type: "document-viewer-state",
      searchFilter: {
        ...searchFilter,
        pageNumber: isNextPage ? searchFilter.pageNumber + 1 : searchFilter.pageNumber,
      },
      index: isNextPage ? 0 : index + 1,
    };
  }, [searchFilter, index]);

  const { data: previousData, isLoading: previousIsLoading } = useSearchDocumentsForNavigation(
    previousFilterState ? previousFilterState.searchFilter : undefined
  );
  const { data: nextData, isLoading: nextIsLoading } = useSearchDocumentsForNavigation(
    nextFilterState ? nextFilterState.searchFilter : undefined
  );

  const previousDocumentLink = useMemo(() => {
    if (
      previousIsLoading ||
      previousFilterState === undefined ||
      !previousData ||
      !previousData.results.length
    ) {
      return undefined;
    }

    const item = previousData.results[previousFilterState.index];

    if (!item) {
      return undefined;
    }

    return {
      to: organizationLinkTemplates.documentIdAndVersion(
        item.$documentId,
        item.$documentVersionId,
        undefined
      ),
      state: previousFilterState,
    };
  }, [previousData, previousFilterState, previousIsLoading]);

  const nextDocumentLink = useMemo(() => {
    if (nextIsLoading || !nextData || !nextData.results.length) {
      return undefined;
    }

    const item = nextData.results[nextFilterState.index];

    if (!item) {
      return undefined;
    }

    return {
      to: organizationLinkTemplates.documentIdAndVersion(
        item.$documentId,
        item.$documentVersionId,
        undefined
      ),
      state: nextFilterState,
    };
  }, [nextData, nextFilterState, nextIsLoading]);

  const onPreviousDocumentClick = useCallback(() => {
    if (previousDocumentLink) {
      organizationNavigate(previousDocumentLink.to, { state: previousDocumentLink.state });
    }
  }, [organizationNavigate, previousDocumentLink]);

  const onNextDocumentClick = useCallback(() => {
    if (nextDocumentLink) {
      organizationNavigate(nextDocumentLink.to, { state: nextDocumentLink.state });
    }
  }, [nextDocumentLink, organizationNavigate]);

  return {
    isPreviousDocumentDisabled: previousDocumentLink === undefined,
    isNextDocumentDisabled: nextDocumentLink === undefined,
    onPreviousDocumentClick,
    onNextDocumentClick,
  };
}

export function searchFieldOperatorToLogicalOperatorType(
  operator: SearchFieldOperator | undefined
): LogicalOperatorType {
  if (!operator) {
    return "equals";
  }
  switch (operator) {
    case "eq":
      return "equals";
    case "gt":
      return "greater-than";
    case "gte":
      return "greater-or-equals";
    case "lt":
      return "lesser-than";
    case "lte":
      return "lesser-or-equals";
    case "neq":
      return "not-equals";
    case "contains":
      return "contains";
    case "starts-with":
      return "starts-with";
    case "ends-with":
      return "ends-with";
    default:
      shouldNever(operator);
      return "equals";
  }
}

type DocumentViewerWrapperState = {
  toastMessage?: {
    type: "error" | "success" | "warn";
    message: string;
  };
  mimeTypeSelected?: MimeType;
  files: Array<{
    file: File;
    type: MimeType | undefined;
  }>;
};

export function useDocumentViewerWrapper(
  src: Array<Blob | string>,
  contentType: string,
  setSelectedPanel: (panel: DocumentViewerPanelKeys) => void,
  remove?: () => void,
  ecmDocument?: EcmDocument,
  onDeleteDocument?: () => void
) {
  const {
    user: thisUser,
    deleteDocument,
    restoreDocumentVersion,
    saveDocument,
    executeDataLink,
    registerDocumentView: documentView,
  } = useServices();
  const [state, setState] = useState<DocumentViewerWrapperState>({
    files: [],
  });
  const {
    extractedContext,
    documentLoadingState,
    setDocumentLoadingState,
    isDocumentSearchOpen,
    setDocumentSearchOpen,
  } = useDocumentLookupState(ecmDocument?.data);

  const [isPanelHidden, setPanelHidden] = useState(false);
  const organizationNavigate = useOrganizationNavigate();
  const [isPrintScreenVisible, setIsPrintScreenVisible] = useState<boolean>(false);
  const { documentId, documentVersionId } = useParams();

  const [selectedDocumentTypeOption, setSelectedDocumentTypeOptions] = useState<
    ComboBoxItemType | undefined
  >();
  const originalFormats = MimeTypesArray.map(mimeTypeToExtension).join(",");
  const [acceptedFormats, setAcceptedFormats] = useState(originalFormats);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const changePanelStatus = useCallback((status: boolean) => {
    setPanelHidden(status);
  }, []);

  const { resources, moment } = useAppTranslation();

  const onFormValuesChange = useCallback((values: ParametersObject) => {
    setFormPayload(values);
  }, []);

  const canEditDocument = useMemo(() => {
    return thisUser
      ? thisUser.permissions.indexOf("organization-admin") >= 0 ||
          ecmDocument?.allowedActions.includes("edit") === true
      : false;
  }, [thisUser, ecmDocument]);

  const canViewDocument = useMemo(() => {
    return thisUser
      ? thisUser.permissions.indexOf("organization-admin") >= 0 ||
          ecmDocument?.allowedActions.includes("view") === true
      : false;
  }, [thisUser, ecmDocument]);

  const canDeleteDocument = useMemo(() => {
    return thisUser
      ? thisUser.permissions.indexOf("delete-document") >= 0 ||
          thisUser.permissions.indexOf("organization-admin") >= 0 ||
          ecmDocument?.allowedActions.includes("delete") === true
      : false;
  }, [thisUser, ecmDocument]);

  const { allDocumentTypes: documentTypeData } = useAllDocumentTypes();

  const [documentTypeVersionId, setDocumentTypeVersionId] = useState<string | undefined>();

  const onSelect = useCallback((value: string) => {
    setDocumentTypeVersionId(value);
  }, []);

  const docType: DocumentTypeResume | undefined = useMemo(() => {
    if (!documentTypeData) {
      return undefined;
    }

    const versionId =
      documentTypeVersionId === undefined
        ? ecmDocument === undefined
          ? undefined
          : ecmDocument.documentTypeVersionId
        : documentTypeVersionId;

    if (!versionId) {
      return undefined;
    }

    const index = documentTypeData.documentTypes.findIndex(
      (d) =>
        d.documentTypeVersionId === versionId ||
        (ecmDocument !== undefined && ecmDocument.documentTypeId === d.documentTypeId)
    );

    if (index < 0) {
      return undefined;
    }

    return documentTypeData.documentTypes[index];
  }, [ecmDocument, documentTypeData, documentTypeVersionId]);

  const documentTypeOptions = useMemo(() => {
    return (documentTypeData?.documentTypes || []).map((d) => {
      return { label: d.name, subtext: undefined, value: d.documentTypeVersionId || "" };
    });
  }, [documentTypeData]);

  const { data, isLoading } = useDocumentTypeVersionInfo(
    docType?.documentTypeId,
    docType?.documentTypeVersionId,
    !!docType?.documentTypeVersionId
  );

  const isLastVersion = documentVersionId === ecmDocument?.lastDocumentVersionId;

  const execDataLink = useCallback(
    (
      dataLinkId: string,
      dataLinkConfigVersionId: string,
      inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
    ) => {
      return executeDataLink(dataLinkId, dataLinkConfigVersionId, inputParameters);
    },
    [executeDataLink]
  );

  // const reduceKeys = useCallback(
  //   (
  //     keys: {
  //       key: string;
  //       value: DataElementValue | FormValues[] | null;
  //     }[]
  //   ) => {
  //     return keys.reduce((obj: { [x: string]: DataElementValue | FormValues[] | null }, item) => {
  //       obj[item.key] = item.value;
  //       return obj;
  //     }, {});
  //   },
  //   []
  // );

  const [formPayload, setFormPayload] = useState<ParametersObject | undefined>();

  //TODO: @eburgos Check this
  const updateEcmDocumentFields = useCallback(
    (_ecmDocument: EcmDocument | undefined): ParametersObject => {
      // const value = { ...state.formValues };
      const initialValue = formPayload || ecmDocument?.data || extractedContext || {};

      return initialValue;

      //   return {};
      // }

      // const [fieldValue, groupValues] = [
      //   data?.documentType?.contextDefinition.fields,
      //   data?.documentType?.contextDefinition.records,
      // ].map((arr) => arr?.map((item) => item.name));

      // const keyAndValuePairs = Object.entries(value).map(([key, value]) => ({ key, value }));

      // const [commonKeysFieldValue, commonKeysGroupValues] = [fieldValue, groupValues].map(
      //   (values) => {
      //     const kv = keyAndValuePairs.filter((key) => values && values.includes(key.key));
      //     return kv.map((item) => {
      //       return {
      //         key: item.key,
      //         value: Array.isArray(item.value)
      //           ? item.value.reduce((acc: Array<string | number>, next) => {
      //               if (typeof next !== "object") {
      //                 acc.push(next);
      //               }

      //               return acc;
      //             }, [])
      //           : item.value,
      //       };
      //     });
      //   }
      // );

      // const resultingObjectFieldValue = reduceKeys(commonKeysFieldValue);
      // const resultingObjectGroupValues = reduceKeys(commonKeysGroupValues);

      // const isMatchingDocumentType =
      //   data?.documentType?.documentTypeId === ecmDocument.documentTypeId ||
      //   data?.documentType === undefined;

      // const isCommonKeysGroupValuesNotEmpty = commonKeysGroupValues.length > 0;

      // const updateDocumentType =
      // isCommonKeysGroupValuesNotEmpty
      //   ? {
      //       resultingObjectFieldValue,
      //       resultingObjectGroupValues,
      //     }
      //   :
      //   resultingObjectFieldValue;

      // const updateDocumentFields = isCommonKeysGroupValuesNotEmpty
      //   ? value
      //   : { ...state.formValues };

      // return isMatchingDocumentType ? updateDocumentFields : updateDocumentType;
    },
    [extractedContext, formPayload, ecmDocument?.data]
  );

  const { mutate, isLoading: isUpdating } = useMutation(saveDocument, {
    onSuccess(document) {
      if (!document) {
        return;
      }
      setState((prev) => ({
        ...prev,
        toastMessage: { type: "success", message: resources.success },
      }));

      if (remove) {
        remove();
      }

      setTimeout(() => {
        organizationNavigate(organizationLinkTemplates.documentId(document?.documentId, "none"));
        setSelectedPanel("data");
        setState((prev) => ({
          ...prev,
          toastMessage: undefined,
        }));
      }, 300);
    },
    onError(error: { response: { data: { errors: Array<{ message: string }> } } }) {
      setState((prev) => ({
        ...prev,
        toastMessage: {
          type: "error",
          message: error.response.data.errors[0].message || resources.errorOccurred,
        },
      }));
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          toastMessage: undefined,
        }));
      }, 5000);
    },
  });

  const handleFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        let mimeType = state.mimeTypeSelected;
        const files = event.target.files;
        const filesArrayRaw: Array<{
          file: File;
          type: MimeType | undefined;
        }> = [];

        for (let i = 0; i < files.length; i++) {
          const file = files.item(i);
          if (!mimeType) {
            mimeType = file?.type as MimeType;
          }
          if (file?.type === mimeType) {
            filesArrayRaw.push({
              file,
              type: mimeType,
            });
          }
        }

        const filesArray: Array<{
          file: File;
          type: MimeType | undefined;
        }> = filesArrayRaw.reduce(
          (
            acc: Array<{
              file: File;
              type: MimeType | undefined;
            }>,
            next
          ) => {
            if (next) {
              const mType = toMimeType(next?.type || "");
              if (typeof mType !== "undefined") {
                acc.push({
                  file: next.file,
                  type: mType,
                });
              }
            }

            return acc;
          },
          []
        );

        setAcceptedFormats(mimeType || acceptedFormats);
        setState((prev) => {
          return {
            ...prev,
            mimeTypeSelected: mimeType as MimeType,
            files: mimeType?.includes("image") ? [...state.files, ...filesArray] : [filesArray[0]],
          };
        });
        setSelectedPanel("update");
      }
    },
    [acceptedFormats, setSelectedPanel, state.files, state.mimeTypeSelected]
  );

  const onFilesChange = useCallback(
    (filesUpdated: Array<string | Blob>) => {
      const files = filesUpdated.reduce((acc: Blob[], next) => {
        if (typeof next !== "string") {
          acc.push(next);
        }

        return acc;
      }, []);
      setState({
        ...state,
        files: files.map((f) => {
          return {
            file: f,
            type: f.type,
          };
        }) as any,
        mimeTypeSelected: filesUpdated.length > 0 ? state.mimeTypeSelected : undefined,
      });

      setAcceptedFormats(filesUpdated.length > 0 ? acceptedFormats : originalFormats);
    },
    [acceptedFormats, originalFormats, state]
  );

  const clickFileInput = useCallback(() => {
    if (
      fileInputRef &&
      fileInputRef.current &&
      (!state.mimeTypeSelected || state.mimeTypeSelected?.includes(acceptedFormats))
    ) {
      fileInputRef.current.click();
    }
  }, [acceptedFormats, state.mimeTypeSelected]);

  const goToLastVersion = useCallback(() => {
    if (documentId) organizationNavigate(organizationLinkTemplates.documentId(documentId, "data"));
  }, [documentId, organizationNavigate]);

  const updateEcmDocument = useCallback(async () => {
    if (!ecmDocument || !data || isLoading || !data.documentType) {
      return;
    }

    let binaries = ecmDocument.binaries;

    if (state.mimeTypeSelected) {
      const promises = state.files.map((file) => getBase64FromBlob(file.file));
      const documents: Array<string> = await Promise.all(promises);
      binaries = documents;
    }

    const isMatchingDocumentType = data.documentType.documentTypeId === ecmDocument.documentTypeId;

    const document: HttpSaveDocumentRequest["payload"] = {
      documentTypeId: isMatchingDocumentType
        ? ecmDocument.documentTypeId
        : data?.documentType?.documentTypeId,
      contentType: state.mimeTypeSelected
        ? (state.mimeTypeSelected as MimeType)
        : (ecmDocument?.mimeType as MimeType),
      data: updateEcmDocumentFields(ecmDocument),
      documents: binaries.reduce((arr: string[], next) => {
        const p = parseDataURI(next);
        if (p) {
          arr.push(next);
        } else {
          arr.push(`data:${ecmDocument.mimeType};base64,${next}`);
        }
        return arr;
      }, []),
      originMethod: "Imported",
      parentDocumentVersionId: isMatchingDocumentType ? ecmDocument?.documentVersionId : undefined,
      originalDocumentId: isMatchingDocumentType ? undefined : ecmDocument.documentId,
      mustUpdateBinaries: state.mimeTypeSelected ? true : false,
    };

    mutate(document);
  }, [data, isLoading, mutate, state, updateEcmDocumentFields, ecmDocument]);

  const fetchDeleteDocument = useCallback(async () => {
    if (!ecmDocument || !canDeleteDocument) {
      return new Promise<void>((resolve) => {
        resolve();
      });
    }

    return await deleteDocument(ecmDocument.documentId, ecmDocument.documentVersionId);
  }, [ecmDocument, canDeleteDocument, deleteDocument]);

  const restoreDocumentCallback = useCallback(async () => {
    if (!ecmDocument) {
      return undefined;
    }
    return await restoreDocumentVersion(ecmDocument.documentId, ecmDocument.documentVersionId);
  }, [ecmDocument, restoreDocumentVersion]);

  const { mutate: mutateDeleteDocument } = useMutation(fetchDeleteDocument, {
    onSuccess: useCallback(() => {
      setState((prev) => ({
        ...prev,
        toastMessage: { type: "success", message: resources.successfullyDeleted },
      }));
      setTimeout(() => setState((prev) => ({ ...prev, toastMessage: undefined })), 5000);
      if (!onDeleteDocument) {
        organizationNavigate(organizationLinkTemplates.home());
        return;
      }
      onDeleteDocument();
    }, [onDeleteDocument, resources.successfullyDeleted, organizationNavigate]),
    onError: useCallback(
      (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
        setState((prev) => ({
          ...prev,
          toastMessage: {
            type: "error",
            message: error.response.data.errors[0].message || resources.errorOccurred,
          },
        }));
        setTimeout(() => {
          setState((prev) => ({
            ...prev,
            toastMessage: undefined,
          }));
        }, 5000);
      },
      [resources.errorOccurred]
    ),
  });

  const { clearQueryCache } = useCacheManager();

  const { mutate: mutateRestoreDocumentVersion, isLoading: isRestoring } = useMutation(
    restoreDocumentCallback,
    {
      onSuccess: useCallback(() => {
        setState((prev) => ({
          ...prev,
          toastMessage: { type: "success", message: resources.restored },
        }));
        setTimeout(() => setState((prev) => ({ ...prev, toastMessage: undefined })), 5000);
        if (documentId) {
          clearQueryCache();
          organizationNavigate(organizationLinkTemplates.documentId(documentId, "data"));
        }
      }, [documentId, organizationNavigate, resources.restored, clearQueryCache]),
      onError: useCallback(
        (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
          setState((prev) => ({
            ...prev,
            toastMessage: {
              type: "error",
              message: (error.response?.data?.errors || [])[0]?.message || resources.errorOccurred,
            },
          }));
          setTimeout(() => {
            setState((prev) => ({
              ...prev,
              toastMessage: undefined,
            }));
          }, 5000);
        },
        [resources.errorOccurred]
      ),
    }
  );

  const formContainerRef = useRef<HTMLDivElement | null>(null);

  const postAction = useCallback(() => {
    if (!ecmDocument) {
      return;
    }

    if (!documentId) {
      return;
    }

    return documentView(documentId, ecmDocument.documentVersionId);
  }, [ecmDocument, documentId, documentView]);

  useEffect(() => {
    const action = postAction;
    const initialSaveTimer = setTimeout(() => {
      action();
    }, 5000);

    return () => {
      clearTimeout(initialSaveTimer);
    };
  }, [postAction]);

  const srcMapped = useMemo(() => {
    return src.map((document) => {
      if (typeof document === "string") {
        return base64ToBlob(document, contentType);
      }
      return document;
    });
  }, [src, contentType]);

  const { data: documentVersionGenerationContext } = useGetDocumentVersionGenerationContext(
    ecmDocument?.documentId,
    ecmDocument?.documentVersionId,
    ecmDocument?.generationToken
  );

  const generateDocument = useCallback(() => {
    if (ecmDocument && ecmDocument.templateId) {
      organizationNavigate(organizationLinkTemplates.templateGeneratePage(ecmDocument.templateId), {
        state: {
          generationContext: documentVersionGenerationContext?.documentVersionGenerationContext,
          parentDocumentVersionId: ecmDocument.documentVersionId,
        },
      });
    }
  }, [ecmDocument, documentVersionGenerationContext, organizationNavigate]);

  const handleDocumentPrintAndDownload = useCallback(
    (
      documentFullyLoaded: boolean,
      instanceRef: MutableRefObject<InstanceWebViewer | undefined>
    ) => {
      if (isPrintScreenVisible && documentFullyLoaded && instanceRef.current) {
        instanceRef.current.print();
      }
    },
    [isPrintScreenVisible]
  );

  const { eventManager } = usePubSub<DocumentViewerEvents>();

  const { mutate: mutateUpdateDocument, isLoading: isUpdatingDocument } =
    useMutation(updateEcmDocument);

  const eventManagerCallback: PubSubEventManagerCallback<DocumentViewerEvents> = useCallback(
    (ev) => {
      switch (ev.type) {
        case "save-with-payload": {
          mutateUpdateDocument();
          break;
        }
        case "restore-document-version": {
          mutateRestoreDocumentVersion();
          break;
        }
        default:
          break;
      }
    },
    [mutateUpdateDocument, mutateRestoreDocumentVersion]
  );

  useSubscribe(eventManager, eventManagerCallback);

  return {
    resources,
    moment,
    state,
    setState,
    onFormValuesChange,
    isUpdating,
    formContainerRef,
    isPanelHidden,
    srcMapped,
    setIsPanelHidden: changePanelStatus,
    canViewDocument,
    canEditDocument,
    isLastVersion,
    canDeleteDocument,
    mutateDeleteDocument,
    mutateRestoreDocumentVersion,
    isRestoring,
    generateDocument,
    execDataLink,
    isLoading: isLoading || isUpdatingDocument,
    onSelect,
    documentTypeData,
    data,
    documentTypeOptions,
    selectedDocumentTypeOption,
    setSelectedDocumentTypeOptions,
    handleFileUpload,
    acceptedFormats,
    onFilesChange,
    clickFileInput,
    goToLastVersion,
    fileInputRef,
    handleDocumentPrintAndDownload,
    setIsPrintScreenVisible,
    isPrintScreenVisible,
    documentLoadingState,
    setDocumentLoadingState,
    isDocumentSearchOpen,
    setDocumentSearchOpen,
    extractedContext,
    eventManager,
  };
}
