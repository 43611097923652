import {
  Box,
  Button,
  Divider,
  Layer,
  Modal,
  SearchField,
  Table,
  TapArea,
  Text,
  TrashSvgIcon,
  modalZIndex,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useMemo } from "react";

import { BreadCrumbEntry } from "../../../components/BreadCrumb";

import { Page } from "../../../components/Layout/Page";
import { Pagination } from "../../../components/Layout/Pagination";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { SearchComponentWrapper } from "../../../components/SearchComponentWrapper";
import { usePaginatedDocumentCollectionList } from "../../../components/hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../link-templates";
import { EditFillSvgIcon } from "../../../svg/EditFillSvgIcon";
import { useDocumentCollectionList } from "./hooks";

const DocumentCollectionListPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();

  const {
    refetch,
    documentCollections,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    pageLengthOptions,
    previousPage,
    rowsPerPage,
    setFilter,
    setPageLength,
    totalRowCount,
  } = usePaginatedDocumentCollectionList();

  const { breakpoint } = useDesignBreakpoint();

  const {
    canDeleteDocumentCollection,
    deleteInfo,
    setDeleteInfo,
    toastMessage,
    setToastMessage,
    affectedEntitiesResponse,
    mutateDeleteCollection,
  } = useDocumentCollectionList({ resources, refetch });

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.documentaryStructure,
        url: organizationLinkTemplates.manageDocumentTypes(),
      },
      { type: "text", name: resources.documentTypes.documentCollection },
    ];
  }, [
    resources.documentTypes.documentCollection,
    resources.documentaryStructure,
    resources.home,
    resources.settings,
  ]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <SearchComponentWrapper
          componentHeaderText={resources.documentCollection.documentCollections}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="document-collection-search"
          searchFieldLabel={resources.documentCollection.filterByDocumentCollection}
          setFilter={setFilter}
          addButtonLabel={resources.add}
          addButtonText={resources.add}
          buttonAction={() =>
            organizationNavigate(organizationLinkTemplates.newDocumentCollection())
          }
          omitSearch={true}
        />
        <Box margin={5} display="flex" direction="column">
          <Pagination<typeof rowsPerPage>
            id="document_collection_list_pagination_top"
            rowsLabel={`${resources.documentTypes.documentCollection}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={documentCollections?.length || 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={pageLengthOptions}
            totalRowCount={totalRowCount}
            extraComponent={
              <SearchField
                marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                marginEnd={2}
                accessibilityLabel={resources.documentCollection.filterByDocumentCollection}
                id="document_collections_filter"
                onChange={({ value }) => {
                  setFilter(value);
                }}
              />
            }
          />
          <Divider direction="horizontal" />
        </Box>
        {affectedEntitiesResponse?.documentsAffected !== undefined && deleteInfo && (
          <Layer zIndex={modalZIndex}>
            <Modal
              accessibilityModalLabel={resources.confirmation}
              heading={
                <Box>
                  <Box marginBottom={4}>
                    <Text weight="bold" size="400" color={colors.secondary}>
                      {resources.delete_}
                    </Text>
                  </Box>
                  <Divider />
                </Box>
              }
              size="md"
              onDismiss={() => setDeleteInfo(undefined)}
              footer={
                <>
                  <Divider />
                  <Box marginTop={4} display="flex">
                    <Box>
                      <Button
                        color={"transparent"}
                        onClick={() => setDeleteInfo(undefined)}
                        text={resources.cancel}
                      />
                    </Box>
                    <Box marginStart="auto">
                      <Button
                        color={"red"}
                        onClick={() => {
                          mutateDeleteCollection(deleteInfo.id);
                        }}
                        text={resources.yesDelete}
                      />
                    </Box>
                  </Box>
                </>
              }
            >
              <Box display="flex" alignItems="center">
                <Text>
                  {resources.documentCollection.youAreAboutToDeleteTheCollection
                    .replace("{documentCollectionName}", deleteInfo.name)
                    .replace(
                      "{documentCollectionsCount}",
                      affectedEntitiesResponse.documentCollectionsAffected.toString()
                    )
                    .replace(
                      "{documentsCount}",
                      affectedEntitiesResponse.documentsAffected.toString()
                    )}
                </Text>
              </Box>
            </Modal>
          </Layer>
        )}

        <Box
          marginTop={8}
          marginStart={6}
          marginEnd={6}
          padding={5}
          color={colors.white}
          borderStyle="raisedTopShadow"
          borderRadius={4}
        >
          <Table accessibilityLabel={resources.documentTypes.documentTypes}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Box paddingX={4}>
                    <Text size="200" weight="bold">
                      {resources.name.toUpperCase()}
                    </Text>
                  </Box>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Box>
                    <Text size="200" weight="bold">
                      {resources.documentTypes.documentTypes.toUpperCase()}
                    </Text>
                  </Box>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Box>
                    <Text size="200" weight="bold">
                      {resources.actions.toUpperCase()}
                    </Text>
                  </Box>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(documentCollections || []).map((dc, i) => (
                <Table.Row hoverStyle="gray" key={i}>
                  <Table.Cell>
                    <Box marginStart={4} display={"flex"}>
                      <Box>
                        <Text size="200" weight="bold">
                          {dc.name}
                        </Text>
                      </Box>
                    </Box>
                  </Table.Cell>
                  <Table.Cell>
                    <Text size="200">{dc.documentTypeIdList.length}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Box display="flex">
                      <Box display="flex" gap={2} justifyContent="center">
                        <TapArea
                          onTap={() =>
                            organizationNavigate(
                              organizationLinkTemplates.editDocumentCollection(dc.id)
                            )
                          }
                        >
                          <Box paddingTop={1}>
                            <EditFillSvgIcon height={20} fillColor={colors.primary} />
                          </Box>
                        </TapArea>
                        {canDeleteDocumentCollection && (
                          <TapArea onTap={() => setDeleteInfo(dc)}>
                            <TrashSvgIcon height={23} color={colors.primary} />
                          </TapArea>
                        )}
                      </Box>
                    </Box>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Box>
        {toastMessage && (
          <NotificationMessage
            type={toastMessage.type}
            message={toastMessage.message}
            position="bottom-left"
            handleDismiss={() => setToastMessage(undefined)}
          />
        )}
        <Box marginTop={5} padding={2} display="flex" direction="column">
          <Box display="flex" flex="grow">
            <Box display="flex" flex="grow" />
            <Box display="flex" flex="shrink">
              <Pagination<typeof rowsPerPage>
                id="document_collection_list_pagination_bottom"
                rowsLabel={`${resources.documentCollection.documentCollections}:`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={documentCollections?.length || 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setPageLength}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={pageLengthOptions}
                totalRowCount={totalRowCount}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default DocumentCollectionListPage;
