import {
  Box,
  Checkbox,
  ChevronSvgIcon,
  Divider,
  Image,
  Spinner,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { FunctionComponent, useState } from "react";

import type { DataTypeAndValue } from "@prodoctivity/shared/src/index-types";
import type { DocumentTypeInfo, EcmDocument } from "@prodoctivity/types";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { ClearAllSvgIcon } from "../../svg/ClearAllSvg";
import { DateIconsSvg } from "../../svg/DateIconsSvg";
import { PublicAvatarImage } from "../Avatar/Avatar";
import { FancyDateTime } from "../Display/FancyDateTime";
import { Pagination } from "../Layout/Pagination";
import { PublicProfile } from "../Profile/Public/PublicProfile";
import { SmallRefreshButton } from "../SmallRefreshButton";
import { usePaginatedDocumentHistory } from "../hooks";

type Props = {
  document: EcmDocument;
  documentTypeInfo: DocumentTypeInfo | undefined;
};

export function ViewerDocumentHistory({ document, documentTypeInfo }: Props) {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const {
    refetch,
    currentPage,
    nextPage,
    pageLengthOptions,
    previousPage,
    rowsPerPage,
    setPageLength,
    historyList,
    totalRowCount,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    isLoading,
    handleCheckboxChange,
    sectionListState,
    setAllFieldsToFalse,
    getCheckedLabelsFromHistory,
    isHovered,
    setIsHovered,
  } = usePaginatedDocumentHistory(document.documentId || "", documentTypeInfo);
  const [show, setShow] = useState<{ [key: number]: boolean }>({});

  return (
    <Box padding={5} position="relative">
      <Box
        width={"100%"}
        display="flex"
        justifyContent="end"
        position="absolute"
        top={true}
        right={true}
        marginBottom={12}
      >
        <Box position="absolute" marginTop={-11} marginEnd={6}>
          <SmallRefreshButton refetch={() => refetch()} accessibilityLabel={resources.refresh} />
        </Box>
      </Box>
      {historyList.length > 0 && (
        <Box
          display="flex"
          justifyContent="between"
          gap={2}
          width={"100%"}
          borderStyle="sm"
          rounding={2}
          padding={2}
          color={colors.neutral400}
          overflow={sectionListState.length > 6 ? "scrollY" : undefined}
          height={sectionListState.length > 6 ? 140 : "auto"}
        >
          <Box>
            {sectionListState.map((i) => (
              <Checkbox
                key={i.label}
                checked={i.checked}
                onChange={(item) => {
                  handleCheckboxChange(i.label, item.checked);
                }}
                id={`checkAutoComplete-${i.label}`}
                label={`${i.label}`}
                name={`autocomplete-field-${i.label}`}
                size="sm"
              />
            ))}
          </Box>

          <Box>
            <TapArea
              onTap={setAllFieldsToFalse}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <ClearAllSvgIcon color={isHovered ? colors.primary : undefined} />
            </TapArea>
          </Box>
        </Box>
      )}
      <Box>
        {getCheckedLabelsFromHistory && getCheckedLabelsFromHistory.length > 0 && !isLoading ? (
          getCheckedLabelsFromHistory
            .slice()
            .reverse()
            .map((i, idx) => (
              <Box
                key={idx}
                borderStyle="sm"
                rounding={2}
                margin={2}
                onClickCapture={() =>
                  setShow((prevShow) => ({ ...prevShow, [idx]: !prevShow[idx] }))
                }
              >
                <TapArea>
                  <Box
                    display="flex"
                    width={"100%"}
                    justifyContent="between"
                    alignItems="center"
                    padding={2}
                    color={colors.neutral400}
                  >
                    <Box display="flex" justifyContent="between" width={"100%"} alignItems="center">
                      <Box display="flex" gap={2} alignItems="center" justifyContent="between">
                        <PublicAvatarImage size={32} username={i.username} />
                        <PublicProfile username={i.username} />
                      </Box>
                      <Box display="flex" gap={2} alignItems="center" justifyContent="between">
                        <DateIconsSvg />
                        <FancyDateTime value={i.createdAt} />
                      </Box>
                    </Box>
                    <ChevronSvgIcon color={colors.subtle} direction={show[idx] ? "up" : "down"} />
                  </Box>
                  {show[idx] && (
                    <Box overflow="auto" width={"100%"}>
                      {i.changes.map((f, index) => (
                        <Box display="flex" key={index} direction="column">
                          <Divider />
                          <Box
                            paddingX={2}
                            display="flex"
                            justifyContent="between"
                            alignItems="center"
                            color={colors.neutral300}
                          >
                            <Text ellipsisLength={45} size="200" weight="bold">
                              {f.fieldName}
                            </Text>
                          </Box>
                          <Divider />
                          <Box
                            display="flex"
                            justifyContent="between"
                            alignItems="center"
                            paddingX={2}
                            color={colors.white}
                            key={index}
                          >
                            <RenderDataByType dataTypeValue={f.oldValue} />
                            <ChevronSvgIcon color={colors.subtle} direction={"right"} />
                            <RenderDataByType dataTypeValue={f.newValue} />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  )}
                </TapArea>
              </Box>
            ))
        ) : isLoading ? (
          <Spinner accessibilityLabel={resources.loading} show={isLoading} />
        ) : (
          <Text align="center" size="400">
            {resources.noResultsFound}
          </Text>
        )}
      </Box>

      <Box padding={2} />
      <Box borderStyle="sm" paddingY={1} rounding={2}>
        <Pagination<typeof rowsPerPage>
          id="document_history_list_pagination_top"
          rowsLabel={``}
          currentPage={currentPage}
          nextPage={nextPage}
          previousPage={previousPage}
          pageLength={historyList?.length || 0}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setPageLength}
          isNextButtonDisabled={isNextButtonDisabled}
          isPreviousButtonDisabled={isPreviousButtonDisabled}
          pageLengthOptions={pageLengthOptions}
          totalRowCount={totalRowCount}
        />
      </Box>
    </Box>
  );
}

const RenderDataByType: FunctionComponent<{ dataTypeValue: DataTypeAndValue | undefined }> = ({
  dataTypeValue,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  if (dataTypeValue === undefined) {
    return (
      <Text size="200" color={colors.error}>
        [{resources.noValue}]
      </Text>
    );
  }
  if (Array.isArray(dataTypeValue.value)) {
    return (
      <Box>
        {dataTypeValue.value.map((item, idx) => {
          const dtv: DataTypeAndValue = {
            type: dataTypeValue.type,
            value: item,
          } as DataTypeAndValue;
          return <RenderDataByType key={idx} dataTypeValue={dtv} />;
        })}
      </Box>
    );
  }
  switch (dataTypeValue.type) {
    case "Alphanumeric":
      return (
        <Text ellipsisLength={45} size="200">
          {dataTypeValue.value}
        </Text>
      );
    case "DateTime":
    case "Time":
    case "Date":
      return <FancyDateTime value={dataTypeValue.value} />;
    case "Image":
      return (
        <Box height={100} width={100} padding={2}>
          <Image
            color="transparent"
            alt="transparent"
            naturalHeight={100}
            naturalWidth={100}
            src={dataTypeValue.value}
            role="presentation"
            fit="cover"
          />
        </Box>
      );
    case "Currency":
    case "Numeric":
    case "Logical":
      return (
        <Text ellipsisLength={45} size="200">
          {dataTypeValue.value}
        </Text>
      );
  }
};
