import {
  emptyFunction,
  formatCurrency,
  formatNumber,
  isValueListContextField,
  shouldNever,
} from "@prodoctivity/shared";
import type {
  ContextField,
  ContextPayloadValidationError,
  ControlSize,
  InputControlValueType,
} from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  DatePickerResources,
  FormItem,
  NumberField,
  SelectList,
  SelectListProps,
  Switch,
  Text,
  TextArea,
  TextField,
  TimerView,
  type DesignBreakpointType,
} from "../components";
import { Checkbox, CheckboxGroup } from "../components/Checkbox";

import type momentType from "moment";
import type { DateFieldProps } from "../components/DateField";
import { DateField } from "../components/DateField";
import { InputMaskField } from "../components/InputMaskField";
import { RadioButtonGroup } from "../components/RadioButton";
import { useFormController } from "../core";
import { DraggableSvgIcon } from "../svg/DraggableSvgIcon";
import { ImageInput } from "./ImageInput";

// const datePickerSelectLists: DatePickerProps["selectLists"] = ["year", "month"];

export type DataElementInputProps = {
  instanceFullPath: string;
  contextField: ContextField;
  typeValue: InputControlValueType;
  hideWrapper?: boolean;
  disabled?: boolean;
  error?: string;
  mode?: "default" | "unstyled" | "summary";
  onChange: (typeValue: InputControlValueType, label?: string) => void;
  onBlur?: (dataElementName: string) => void;
  getContextValueAsString(fullPath: string): string | undefined;
  getDynamicValue?(text: string): string;
  moment: typeof momentType;
  componentBreakpoint: DesignBreakpointType;
  resources: DatePickerResources & {
    clear: string;
    clickUploadImage: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
  };
  purpose?: "search" | "real-time" | "edit-template" | undefined;
};

function toDesignSystemControlSize(s: ControlSize) {
  switch (s) {
    case "Small":
      return "md";
    case "Large":
      return "lg";
    case "None":
    case "Normal":
    default:
      return "md";
  }
}

function toDesignSystemCheckboxSize(s: ControlSize) {
  switch (s) {
    case "Small":
      return "sm";
    case "Large":
      return "md";
    case "None":
    case "Normal":
    default:
      return "sm";
  }
}

export const DataElementInput: FunctionComponent<DataElementInputProps> = (props) => {
  const {
    instanceFullPath,
    onChange: rawOnChange,
    onBlur,
    getContextValueAsString,
    getDynamicValue,
    contextField,
    error: rawError,
    hideWrapper,
    typeValue,
    disabled,
    moment,
    componentBreakpoint,
    purpose,
    resources,
  } = props;

  const id = `${instanceFullPath}[0]`;

  const formController = useFormController();
  const properties = contextField.properties;

  const [touched, setTouched] = useState(false);

  const onChange: DataElementInputProps["onChange"] = useCallback(
    (args) => {
      rawOnChange(args);
      setTouched(true);
    },
    [rawOnChange]
  );

  const error = useMemo(() => {
    if (!touched) {
      return undefined;
    }

    return getDisplayedError(
      formController.currentSectionErrors,
      id,
      resources.contextValidationErrors,
      rawError
    );
  }, [
    formController.currentSectionErrors,
    id,
    rawError,
    resources.contextValidationErrors,
    touched,
  ]);

  const [isFocused, setIsFocused] = useState(false);
  const onLocalBlur = useCallback(() => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(contextField.name);
    }
    setTouched(true);
  }, [contextField.name, onBlur]);

  // /*
  //                       const inputDatePickerChanged = useCallback(
  //                         (args: { event: unknown; value: Date | null }) => {
  //                           if (onChange) {
  //                             if (typeValue.dataType !== "Date" && typeValue.dataType !== "DateTime") {
  //                               return;
  //                             }
  //                             onChange({
  //                               dataType: typeValue.dataType,
  //                               value: moment(args.value).toISOString(),
  //                             });
  //                           }
  //                         },
  //                         [onChange, typeValue.dataType]
  //                       );*/

  if (hideWrapper) {
    return (
      <RenderedControl
        instanceFullPath={id}
        contextField={contextField}
        typeValue={typeValue}
        error={error}
        disabled={!!disabled}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
        moment={moment}
        onBlur={onLocalBlur}
        getContextValueAsString={getContextValueAsString}
        onCommitChange={onChange}
        componentBreakpoint={componentBreakpoint}
        resources={resources}
        purpose={purpose}
      />
    );
  }
  // function renderDefinition(definition: string) {
  //     return (
  //         <span>
  //             {(definition || "").split("\n").map((d, i) => (
  //                 <div key={i}>{d}</div>
  //             ))}
  //         </span>
  //     );
  // }

  return (
    <FormItem
      id={id}
      focused={isFocused}
      properties={properties}
      purpose={purpose}
      getDynamicValue={getDynamicValue}
    >
      {purpose === "edit-template" ? (
        <Box display="flex" direction="row" width={"100%"}>
          <Box display="flex" direction="column">
            <Box display="flex" flex="grow" />
            <Box width={25} height={34}>
              <DraggableSvgIcon />
            </Box>
            <Box margin={1} />
          </Box>
          <Box display="block" width={"100%"}>
            <RenderedControl
              instanceFullPath={id}
              contextField={contextField}
              typeValue={typeValue}
              error={error}
              disabled={!!disabled}
              isFocused={isFocused}
              setIsFocused={setIsFocused}
              moment={moment}
              onBlur={onLocalBlur}
              getContextValueAsString={getContextValueAsString}
              onCommitChange={onChange}
              componentBreakpoint={componentBreakpoint}
              resources={resources}
              purpose={purpose}
            />
          </Box>
        </Box>
      ) : (
        <RenderedControl
          instanceFullPath={id}
          contextField={contextField}
          typeValue={typeValue}
          error={error}
          disabled={!!disabled}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          moment={moment}
          onBlur={onLocalBlur}
          getContextValueAsString={getContextValueAsString}
          onCommitChange={onChange}
          componentBreakpoint={componentBreakpoint}
          resources={resources}
          purpose={purpose}
        />
      )}
    </FormItem>
  );
};

export const RenderedControl: FunctionComponent<{
  instanceFullPath: string;
  contextField: ContextField;
  typeValue: InputControlValueType;
  disabled: boolean;
  error: string | undefined;
  isFocused: boolean;
  moment: typeof momentType;
  setIsFocused(v: boolean): void;
  onCommitChange: (typeValue: InputControlValueType, label?: string) => void;
  onBlur: () => void;
  getContextValueAsString(fullPath: string): string | undefined;
  componentBreakpoint: DesignBreakpointType;
  resources: DatePickerResources & {
    clear: string;
    clickUploadImage: string;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
  };
  purpose?: "search" | "real-time" | "edit-template" | undefined;
}> = ({
  instanceFullPath,
  contextField,
  disabled,
  typeValue,
  error,
  isFocused,
  moment,
  setIsFocused,
  onCommitChange,
  onBlur,
  getContextValueAsString,
  componentBreakpoint,
  purpose,
  resources,
}) => {
  const { properties } = contextField;

  const checkChanged = useCallback(
    (args: { event: any }) => {
      const { event } = args;
      const target = event.target;
      onCommitChange({
        dataType: "Logical",
        value: !!target.checked,
      });
    },
    [onCommitChange]
  );

  // const inputTimePickerChanged = useCallback(
  //   (args: { event: unknown; value: Date | null }) => {
  //     if (typeValue.dataType !== "Date" && typeValue.dataType !== "DateTime") {
  //       return;
  //     }
  //     if (args.value === null) {
  //       onCommitChange({ dataType: typeValue.dataType, value: undefined });
  //     } else if (args.value) {
  //       const formattedTime = moment(args.value).format();
  //       onCommitChange({ dataType: typeValue.dataType, value: formattedTime });
  //     }
  //   },
  //   [typeValue.dataType, onCommitChange, moment]
  // );

  const dateFieldChanged: DateFieldProps["onChange"] = useCallback(
    (args) => {
      if (typeValue.dataType !== "Date" && typeValue.dataType !== "DateTime") {
        return;
      }

      if (args.value === null || args.value === undefined) {
        onCommitChange({ dataType: typeValue.dataType, value: undefined });
      } else if (args.value) {
        let date = moment(args.value).toDate();
        if (typeValue.value === undefined || typeValue.dataType === "Date") {
          date = moment(date).startOf("day").toDate();
        }

        const jsTime = date.getTime();
        if (!isNaN(jsTime)) {
          onCommitChange({ dataType: typeValue.dataType, value: moment(date).format() });
        }
      }
    },
    [moment, onCommitChange, typeValue.dataType, typeValue.value]
  );

  const inputTimeEntryModifiedDateTime = useCallback(
    (value: number | undefined) => {
      if (
        typeValue.dataType !== "Date" &&
        typeValue.dataType !== "DateTime" &&
        typeValue.dataType !== "Time"
      ) {
        return;
      }

      onCommitChange({
        dataType: typeValue.dataType,
        value,
      });
    },
    [typeValue.dataType, onCommitChange]
  );

  const [tempValue, setTempValue] = useState<InputControlValueType>(typeValue);

  useEffect(() => {
    switch (typeValue.dataType) {
      case "Alphanumeric": {
        setTempValue({
          dataType: typeValue.dataType,
          value: typeValue.value,
        });
        break;
      }
      case "Numeric":
      case "Currency": {
        setTempValue({
          dataType: typeValue.dataType,
          value: typeValue.value,
        });
        break;
      }
      case "Logical": {
        setTempValue({
          dataType: typeValue.dataType,
          value: typeValue.value,
        });
        break;
      }
      case "Date":
      case "DateTime":
      case "Time": {
        setTempValue({
          dataType: typeValue.dataType,
          value: typeValue.value,
        });
        break;
      }
      case "Image": {
        setTempValue({
          dataType: typeValue.dataType,
          value: typeValue.value,
        });
        break;
      }
    }
  }, [typeValue.dataType, typeValue.value]);

  const inputChanged = useCallback(
    (args: { event: any }) => {
      const { event } = args;
      const target = event.target;

      event.preventDefault();
      event.stopPropagation();
      event.bubbles = false;

      switch (properties.dataType) {
        case "Currency":
        case "Numeric":
          {
            const thisValue: number | undefined = isNaN(target.valueAsNumber)
              ? undefined
              : target.valueAsNumber;

            if (
              thisValue !== undefined &&
              ((properties.minValue && thisValue < properties.minValue) ||
                (properties.maxValue && thisValue > properties.maxValue))
            ) {
              return;
            }

            setTempValue({
              dataType: properties.dataType,
              value: thisValue,
            });

            // if (purpose === "real-time") {
            onCommitChange({
              dataType: properties.dataType,
              value: thisValue,
            });
            // }
          }

          break;
        case "Alphanumeric": {
          const thisValue = `${target.value}`;
          if (purpose === "real-time") {
            onCommitChange({
              dataType: properties.dataType,
              value: thisValue,
            });
          } else {
            setTempValue({
              dataType: "Alphanumeric",
              value: thisValue,
            });
          }

          break;
        }
        default:
          break;
      }
    },
    [properties, purpose, onCommitChange]
  );

  // const maskedInputChanged = useCallback(
  //     (event: any) => {
  //         return inputChanged({ event });
  //     },
  //     [inputChanged]
  // );

  const inputSwitchChanged = useCallback(
    (args: { event: unknown; value: boolean }) => {
      setIsFocused(false);
      onCommitChange({ dataType: "Logical", value: args.value });
    },
    [onCommitChange, setIsFocused]
  );

  const selectChanged: SelectListProps["onChange"] = useCallback(
    (args) => {
      const target: any = args.event.currentTarget;
      const value =
        target.selectedOptions && target.selectedOptions[0] && target.selectedOptions[0].disabled
          ? undefined
          : target.value;
      setIsFocused(false);
      onCommitChange({
        dataType: "Alphanumeric",
        value: !value ? undefined : value,
      });
    },
    [onCommitChange, setIsFocused]
  );

  // const selectBooleanChanged = useCallback(
  //     (args: { event: unknown; value: string }) => {
  //         if (onChange) {
  //             onChange({
  //                 dataType: "Logical",
  //                 value: args.value === "true",
  //             });
  //         }
  //     },
  //     [onChange]
  // );

  const checkBoxGroupChanged = useCallback(
    (thisValue: string[]) => {
      setIsFocused(false);
      onCommitChange({ dataType: "Alphanumeric", value: thisValue });
    },
    [onCommitChange, setIsFocused]
  );

  const radioGroupChanged = useCallback(
    (thisValue: string) => {
      setIsFocused(false);
      onCommitChange({ dataType: "Alphanumeric", value: thisValue });
    },
    [onCommitChange, setIsFocused]
  );

  const imageChanged = useCallback(
    (thisValue: string) => {
      setIsFocused(false);
      onCommitChange({ dataType: "Image", value: thisValue });
    },
    [onCommitChange, setIsFocused]
  );

  const maxLengthConfig = useMemo(() => {
    if (properties.dataType !== "Alphanumeric") {
      return undefined;
    }
    if (properties.inputType !== "TextBox" && properties.inputType !== "Default") {
      return undefined;
    }

    return {
      characterCount: properties.maxLength ?? 399,
      errorAccessibilityLabel: `${properties.maxLength} max`,
    };
  }, [properties]);

  const valueListOptions = useMemo(() => {
    if (!isValueListContextField(properties)) {
      return [];
    }

    if (!properties.parentListSource) {
      return properties.valueList.map((item) => ({
        value: item.value,
        label: item.label,
      }));
    }

    const parentValue = getContextValueAsString(properties.parentListSource);

    return properties.valueList.reduce((acc: Array<{ value: string; label: string }>, item) => {
      if (item.parentValue === parentValue) {
        acc.push({ value: item.value, label: item.label });
      }
      return acc;
    }, []);
  }, [getContextValueAsString, properties]);

  const selectListOptions = useMemo(() => {
    return [
      {
        value: "",
        label: `<${resources.dataTypeValues.none}>`,
      },
      ...valueListOptions,
    ];
  }, [resources.dataTypeValues.none, valueListOptions]);

  switch (properties.dataType) {
    case "Alphanumeric":
      switch (properties.inputType) {
        case "CheckBoxList":
        case "Checkbox": {
          const val =
            typeValue.dataType === "Alphanumeric"
              ? Array.isArray(typeValue.value)
                ? typeValue.value.filter(Boolean)
                : typeValue.value
              : [];

          return (
            <CheckboxGroup
              id={instanceFullPath ?? ""}
              name={contextField.fullPath}
              disabled={disabled}
              options={valueListOptions}
              value={Array.isArray(val) ? val : val !== undefined ? [val] : []}
              onChange={checkBoxGroupChanged}
            />
          );
        }

        case "Default":
        case "TextBox": {
          const valList = tempValue.dataType === "Alphanumeric" ? tempValue.value || "" : "";
          const val = Array.isArray(valList) ? valList[0] || "" : valList;

          if (properties.inputMask) {
            return (
              <InputMaskField
                id={instanceFullPath ?? ""}
                inputMask={properties.inputMask}
                inputMaskStoreFixedChars={!!properties.inputMaskStoreFixedChars}
                disabled={disabled}
                onChange={inputChanged}
                errorMessage={error}
                onBlur={(args) => {
                  setIsFocused(false);
                  if (tempValue.dataType === "Alphanumeric") {
                    onCommitChange({ ...tempValue, value: args.value });
                  }
                  if (onBlur) {
                    onBlur();
                  }
                }}
                name={contextField.fullPath}
                value={val}
                onFocus={() => {
                  setIsFocused(true);
                }}
              />
            );
          }

          return (
            <TextField
              id={instanceFullPath ?? ""}
              disabled={disabled}
              onChange={inputChanged}
              errorMessage={error}
              mode={undefined}
              size={"lg"}
              value={val}
              name={contextField.fullPath}
              placeholder={properties.placeholder}
              onBlur={() => {
                setIsFocused(false);
                onCommitChange(tempValue);
                if (onBlur) {
                  onBlur();
                }
              }}
              onFocus={() => {
                setIsFocused(true);
              }}
              maxLength={isFocused ? maxLengthConfig : undefined}
            />
          );
        }
        case "TextArea": {
          const valList = tempValue.dataType === "Alphanumeric" ? tempValue.value || "" : "";
          const val = Array.isArray(valList) ? valList[0] || "" : valList;

          return (
            <TextArea
              id={instanceFullPath ?? ""}
              name={contextField.fullPath}
              onBlur={() => {
                setIsFocused(false);
                onCommitChange(tempValue);
                if (onBlur) onBlur();
              }}
              errorMessage={error}
              disabled={disabled}
              value={val}
              onChange={inputChanged}
              onFocus={() => {
                setIsFocused(true);
              }}
              maxLength={
                isFocused
                  ? {
                      characterCount: properties.maxLength ?? 999,
                      errorAccessibilityLabel: `${properties.maxLength} max`,
                    }
                  : undefined
              }
              rows={
                properties.lineCount !== undefined && properties.lineCount > 0
                  ? properties.lineCount
                  : undefined
              }
            />
          );
        }
        case "DropDownList":
        case "Dropdown": {
          const valList = typeValue.dataType === "Alphanumeric" ? typeValue.value || "" : "";
          const val = Array.isArray(valList) ? valList[0] || "" : valList;

          return (
            <Box width={purpose === "search" ? "100%" : undefined}>
              <SelectList
                id={instanceFullPath ?? ""}
                name={contextField.fullPath}
                disabled={disabled}
                errorMessage={error}
                onChange={selectChanged}
                placeholder={selectListOptions[0].label}
                labelDisplay="hidden"
                size={"lg"}
                value={val}
                options={selectListOptions}
              />
            </Box>
          );
        }

        case "RadioButtonList":
        case "Radio": {
          const valList = typeValue.dataType === "Alphanumeric" ? typeValue.value || "" : "";
          const val = Array.isArray(valList) ? valList[0] || "" : valList;

          return (
            <Box display="flex" direction="column">
              <Box margin={2} />
              <RadioButtonGroup
                id={instanceFullPath ?? ""}
                name={contextField.fullPath}
                disabled={disabled}
                options={valueListOptions}
                value={val}
                onChange={radioGroupChanged}
              />
            </Box>
          );
        }
        default:
          throw new Error();
      }

    case "Date":
      switch (properties.inputType) {
        case "DateTimePicker":
        case "Default": {
          const valList = typeValue.dataType === "Date" ? typeValue.value || undefined : undefined;
          const val = Array.isArray(valList) ? valList[0] || undefined : valList;
          const dateVal = val !== undefined ? new Date(val) : val;

          const minDate =
            properties.dateMinMaxType === "MinIsCurrentDate"
              ? properties.minValue !== undefined &&
                properties.minValue > moment().startOf("day").toDate().getTime()
                ? new Date(properties.minValue)
                : moment().startOf("day").toDate()
              : properties.minValue !== undefined
              ? new Date(properties.minValue)
              : undefined;

          const maxDate =
            properties.dateMinMaxType === "MaxIsCurrentDate"
              ? properties.maxValue !== undefined &&
                properties.maxValue < moment().endOf("day").toDate().getTime()
                ? new Date(properties.maxValue)
                : moment().endOf("day").toDate()
              : properties.maxValue !== undefined
              ? new Date(properties.maxValue)
              : undefined;

          return (
            <Box>
              <Box display="flex" gap={3} direction="column" overflow="visible">
                <DateField
                  resources={resources}
                  id={instanceFullPath}
                  placeholder={properties.dataType === "Date" ? resources._dateFormatShort : ""}
                  // errorMessage={error}
                  disabled={disabled}
                  value={dateVal ? dateVal : undefined}
                  onChange={dateFieldChanged}
                  onBlur={onBlur}
                  // mobileEnterKeyHint="next"
                  onClearInput={() =>
                    dateFieldChanged({
                      value: undefined,
                    })
                  }
                  rangeStartDate={
                    minDate
                      ? minDate
                      : properties.dateMinMaxType === "MinIsCurrentDate"
                      ? new Date()
                      : undefined
                  }
                  rangeEndDate={
                    maxDate
                      ? maxDate
                      : properties.dateMinMaxType === "MaxIsCurrentDate"
                      ? new Date()
                      : undefined
                  }
                  // disableRange={
                  //   properties.dateMinMaxType === "MinIsCurrentDate"
                  //     ? "disableFuture"
                  //     : properties.dateMinMaxType === "MaxIsCurrentDate"
                  //     ? "disablePast"
                  //     : undefined
                  // }
                  // minDate={minDate}
                  // maxDate={maxDate}
                  dataType={properties.dataType}
                />
                {/* <DatePicker
                  ref={inputElementRef}
                  id={instanceFullPath ?? ""}
                  errorMessage={error}
                  disabled={disabled}
                  selectLists={datePickerSelectLists}
                  // onBlur={()=> onblur}
                  value={dateVal}
                  onChange={inputTimePickerChanged}
                /> */}
              </Box>
            </Box>
          );
        }
        default: {
          shouldNever(properties);
          throw new Error();
        }
      }

    case "DateTime":
      switch (properties.inputType) {
        case "DateTimePicker":
        case "Default": {
          if (typeValue.dataType !== "Date" && typeValue.dataType !== "DateTime") {
            throw new Error(`Invalid dataType '${typeValue.dataType}'`);
          }
          const valList =
            typeValue.dataType === "DateTime" ? typeValue.value || undefined : undefined;
          const val = Array.isArray(valList) ? valList[0] || undefined : valList;
          const dateVal = val !== undefined ? new Date(val) : val;

          // const time = dateVal?.getTime();

          const minDate =
            properties.dateMinMaxType === "MinIsCurrentDate"
              ? properties.minValue !== undefined &&
                properties.minValue > moment().startOf("day").toDate().getTime()
                ? new Date(properties.minValue)
                : moment().startOf("day").toDate()
              : properties.minValue !== undefined
              ? new Date(properties.minValue)
              : undefined;

          const maxDate =
            properties.dateMinMaxType === "MaxIsCurrentDate"
              ? properties.maxValue !== undefined &&
                properties.maxValue < moment().endOf("day").toDate().getTime()
                ? new Date(properties.maxValue)
                : moment().endOf("day").toDate()
              : properties.maxValue !== undefined
              ? new Date(properties.maxValue)
              : undefined;

          return (
            <Box display="flex" gap={3} direction="row" flex="grow">
              <Box flex="grow">
                <DateField
                  resources={resources}
                  placeholder={resources._dateTimeFormatShort}
                  id={instanceFullPath}
                  // errorMessage={error}
                  disabled={disabled}
                  // mobileEnterKeyHint="next"
                  value={dateVal ? dateVal : undefined}
                  onChange={dateFieldChanged}
                  onBlur={onBlur}
                  onClearInput={() =>
                    dateFieldChanged({
                      value: undefined,
                    })
                  }
                  rangeStartDate={
                    minDate
                      ? minDate
                      : properties.dateMinMaxType === "MinIsCurrentDate"
                      ? new Date()
                      : undefined
                  }
                  rangeEndDate={
                    maxDate
                      ? maxDate
                      : properties.dateMinMaxType === "MaxIsCurrentDate"
                      ? new Date()
                      : undefined
                  }
                  // disableRange={
                  //   properties.dateMinMaxType === "MinIsCurrentDate"
                  //     ? "disableFuture"
                  //     : properties.dateMinMaxType === "MaxIsCurrentDate"
                  //     ? "disablePast"
                  //     : undefined
                  // }
                  // minDate={minDate}
                  // maxDate={maxDate}
                  dataType={properties.dataType}
                />
              </Box>
              {/* <DatePicker
                ref={inputElementRef}
                id={instanceFullPath ?? ""}
                errorMessage={error}
                disabled={disabled}
                selectLists={datePickerSelectLists}
                //onBlur={onBlur}
                value={dateVal}
                onChange={inputTimePickerChanged}
              /> */}

              <Box flex="shrink">
                <TimerView
                  onChange={inputTimeEntryModifiedDateTime}
                  onBlur={onBlur}
                  id={instanceFullPath}
                  disabled={disabled}
                  error={error}
                  typeValue={typeValue}
                  moment={moment}
                  contextFieldProperties={contextField.properties}
                  resources={resources}
                />
              </Box>
            </Box>
          );
        }

        default: {
          shouldNever(properties);
          throw new Error();
        }
      }

    case "Image": {
      const valList = typeValue.dataType === "Image" ? typeValue.value : undefined;
      const val = Array.isArray(valList) ? valList[0] || "" : valList;
      return (
        <ImageInput
          instanceFullPath={instanceFullPath}
          properties={properties}
          value={val}
          disabled={disabled}
          onChange={imageChanged}
          onError={emptyFunction}
          componentBreakpoint={componentBreakpoint}
          resources={resources}
        />
      );
    }

    case "Logical":
      switch (properties.inputType) {
        case "Default":
        case "Checkbox": {
          const val = typeValue.dataType === "Logical" ? typeValue.value || false : false;

          return (
            <Checkbox
              id={instanceFullPath ?? ""}
              disabled={disabled}
              errorMessage={error}
              checked={val}
              size={toDesignSystemCheckboxSize(properties.controlSize || "Small")}
              onChange={checkChanged}
            />
          );
        }

        case "Switch": {
          const val = typeValue.dataType === "Logical" ? typeValue.value || false : false;
          return (
            <Switch
              id={instanceFullPath ?? ""}
              name={contextField.fullPath}
              disabled={disabled}
              onChange={inputSwitchChanged}
              switched={val}
            />
          );
        }
        default:
          shouldNever(properties);
          throw new Error();
      }

    case "Numeric":
    case "Currency":
      switch (properties.inputType) {
        case "Default":
        case "TextBox": {
          const val =
            tempValue.dataType === "Numeric" || tempValue.dataType === "Currency"
              ? tempValue.value
              : undefined;
          const value = Array.isArray(val) ? val[0] : val;

          if (properties.isCalculated) {
            switch (properties.dataType) {
              case "Currency":
                return <Text>{value === undefined ? "" : formatCurrency(value)}</Text>;
              case "Numeric":
                return <Text>{value === undefined ? "" : formatNumber(value)}</Text>;
              default:
                shouldNever(properties);
            }
            return <Text></Text>;
          }

          return (
            <NumberField
              id={instanceFullPath ?? ""}
              disabled={disabled}
              errorMessage={error}
              onChange={inputChanged}
              min={properties.minValue}
              max={properties.maxValue}
              placeholder={properties.placeholder}
              size={toDesignSystemControlSize(properties.controlSize || "Small")}
              value={value}
              mobileEnterKeyHint={"next"}
              name={contextField.fullPath}
              onBlur={() => {
                setIsFocused(false);
                onCommitChange(tempValue);

                onBlur();
              }}
              onFocus={() => {
                setIsFocused(true);
              }}
              step={properties.dataType === "Numeric" ? 1 : undefined}
            />
          );
        }
        default:
          shouldNever(properties);
          throw new Error();
      }

    case "Time":
      switch (properties.inputType) {
        case "Default":
        case "TimePicker": {
          return (
            <TimerView
              onChange={inputTimeEntryModifiedDateTime}
              onBlur={onBlur}
              id={instanceFullPath}
              disabled={disabled}
              error={error}
              typeValue={typeValue}
              moment={moment}
              contextFieldProperties={contextField.properties}
              resources={resources}
            />
          );
        }
        default:
          shouldNever(properties);
          throw new Error();
      }
  }
};

export function getDisplayedError(
  currentSectionErrors: ContextPayloadValidationError[],
  instanceFullPath: string,
  contextValidationErrors: Record<string, string>,
  rawError: string | undefined
) {
  const contextErrors = currentSectionErrors.filter(
    (err) => err.instanceFullPath === instanceFullPath
  );

  if (contextErrors.length) {
    const errorMsg = contextErrors
      .map((err) => contextValidationErrors[err.errorCode] || err.message)
      .join(", ");
    if (rawError) {
      return errorMsg + ", " + rawError;
    } else {
      return errorMsg;
    }
  } else {
    return rawError;
  }
}
