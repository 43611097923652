import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const ImportEmptySvgIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 44,
  height = 51,
  color: rawColor,
  onClick,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      //stroke={color ? calculateColor(color) : undefined}
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 44 51`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g opacity="0.6">
        <path
          d="M12.0586 1.61721L38.1259 2.07221C39.4227 2.09135 40.6592 2.62284 41.5645 3.55032C42.4698 4.47779 42.9702 5.72568 42.9562 7.02075L42.3552 41.4524L12.0586 1.61721Z"
          fill={calculateColor(color)}
        />
        <path
          d="M42.3596 41.3262C42.1641 41.3228 41.978 41.2419 41.8421 41.1012C41.7063 40.9606 41.6319 40.7717 41.6353 40.5762L42.223 6.91165C42.2335 5.83881 41.8185 4.80549 41.0688 4.03798C40.3192 3.27048 39.2959 2.83133 38.2231 2.81671L12.7279 2.37169C12.6309 2.37 12.5353 2.34924 12.4464 2.31059C12.3575 2.27194 12.2771 2.21615 12.2098 2.14643C12.1425 2.0767 12.0895 1.9944 12.054 1.90421C12.0185 1.81403 12.0011 1.71773 12.0027 1.62081C12.0044 1.5239 12.0252 1.42826 12.0638 1.33937C12.1025 1.25048 12.1583 1.17007 12.228 1.10274C12.2977 1.03541 12.38 0.982467 12.4702 0.946941C12.5604 0.911416 12.6567 0.894003 12.7536 0.895694L38.2488 1.34072C39.7116 1.3662 41.1051 1.96873 42.1256 3.01702C43.1461 4.06532 43.711 5.47444 43.6972 6.93738L43.1086 40.661C43.0905 40.8459 43.0033 41.017 42.8644 41.1404C42.7256 41.2637 42.5453 41.3301 42.3596 41.3262V41.3262Z"
          fill={calculateColor(color)}
        />
        <path
          d="M39.2204 14.9717L38.7829 40.0397C38.7591 41.4007 38.1957 42.6965 37.2165 43.6421C36.2373 44.5877 34.9226 45.1056 33.5615 45.0818L13.6261 44.7339C12.2651 44.7101 10.9693 44.1467 10.0237 43.1675C9.0781 42.1883 8.56022 40.8736 8.58397 39.5125L8.85398 24.0437C8.93621 19.3331 8.9478 18.6692 9.04161 13.2947L9.09798 10.0653C9.10189 9.75847 9.13591 9.45271 9.19955 9.15248C9.24107 8.90718 9.30548 8.66631 9.39196 8.43303C9.4727 8.19489 9.57534 7.96474 9.69856 7.74555C10.1415 6.9087 10.8079 6.21115 11.6237 5.73052C12.4395 5.2499 13.3726 5.00503 14.3193 5.02318L29.92 5.29549L30.4559 5.85037C33.4688 8.98603 35.4103 10.9965 38.3522 14.0273L39.2204 14.9717Z"
          fill="#DFE0E1"
        />
        <path
          d="M30.2265 4.56012L14.3304 4.28265C13.2114 4.26754 12.1111 4.57109 11.1581 5.15784C10.2051 5.7446 9.43866 6.59035 8.94829 7.59635C8.84084 7.81543 8.74853 8.05019 8.65597 8.2995C8.57719 8.53497 8.51378 8.77531 8.46613 9.019C8.40141 9.35896 8.36673 9.70397 8.3625 10.05L8.08159 24.2341C8.33177 24.2866 9.56048 24.0406 9.56048 24.0406L9.30612 23.8596L9.56567 23.7436L9.83765 10.0749C9.81215 9.82958 9.81647 9.58206 9.85052 9.33776C9.88317 9.09088 9.94696 8.84913 10.0404 8.61827C10.1175 8.38085 10.2257 8.15467 10.3621 7.94561C10.7627 7.26083 11.34 6.69636 12.0336 6.3113C12.7273 5.92625 13.5117 5.73477 14.3047 5.75694L29.5963 6.02385L30.4375 6.89494L37.3197 14.0068L38.5226 15.2525L38.0915 39.9506C38.071 41.1238 37.5853 42.2409 36.7412 43.056C35.8971 43.8711 34.7638 44.3176 33.5905 44.2971L13.638 43.9488C12.4648 43.9283 11.3477 43.4426 10.5326 42.5985C9.71749 41.7544 9.27107 40.6211 9.29155 39.4479L9.56567 23.7436C9.32449 23.8578 9.0765 23.957 8.82311 24.0406C8.5826 24.1275 8.33388 24.1923 8.08159 24.2341L7.81726 39.4221C7.80374 40.1967 7.94291 40.9663 8.22682 41.6871C8.51073 42.4078 8.93383 43.0656 9.47195 43.6228C10.0101 44.1801 10.6527 44.6258 11.3631 44.9347C12.0735 45.2436 12.8378 45.4096 13.6123 45.4231L33.5931 45.7719C35.1573 45.7992 36.6683 45.204 37.7937 44.1172C38.9191 43.0304 39.5667 41.5411 39.594 39.9768L40.0347 14.7316L30.2265 4.56012Z"
          fill="#B3B3B3"
        />
        <path
          d="M28.2136 18.3462L15.2524 18.12C15.0569 18.1166 14.8708 18.0356 14.735 17.895C14.5991 17.7543 14.5247 17.5655 14.5281 17.37C14.5316 17.1745 14.6125 16.9883 14.7532 16.8525C14.8938 16.7167 15.0827 16.6423 15.2782 16.6457L28.2394 16.8719C28.4336 16.879 28.6176 16.9611 28.7526 17.101C28.8877 17.2408 28.9633 17.4275 28.9636 17.6219C28.9602 17.8174 28.8793 18.0036 28.7386 18.1394C28.598 18.2752 28.4091 18.3496 28.2136 18.3462V18.3462Z"
          fill="#B3B3B3"
        />
        <path
          d="M31.9376 34.9279L14.9653 34.6317C14.7698 34.6283 14.5837 34.5473 14.4478 34.4067C14.312 34.266 14.2376 34.0772 14.241 33.8817C14.2445 33.6862 14.3254 33.5 14.466 33.3642C14.6067 33.2284 14.7955 33.154 14.991 33.1574L31.9633 33.4537C32.1588 33.4571 32.345 33.538 32.4808 33.6787C32.6166 33.8193 32.691 34.0082 32.6876 34.2037C32.6842 34.3992 32.6032 34.5853 32.4626 34.7211C32.3219 34.857 32.1331 34.9313 31.9376 34.9279Z"
          fill="#B3B3B3"
        />
        <path
          d="M32.0821 26.674L15.1098 26.3778C14.9143 26.3744 14.7282 26.2934 14.5924 26.1528C14.4565 26.0121 14.3822 25.8233 14.3856 25.6278C14.389 25.4323 14.4699 25.2461 14.6106 25.1103C14.7512 24.9745 14.9401 24.9001 15.1356 24.9035L32.1078 25.1997C32.3033 25.2032 32.4895 25.2841 32.6253 25.4247C32.7611 25.5654 32.8355 25.7543 32.8321 25.9498C32.8287 26.1453 32.7478 26.3314 32.6071 26.4672C32.4665 26.6031 32.2776 26.6774 32.0821 26.674Z"
          fill="#B3B3B3"
        />
        <path
          d="M39.2087 15.5138L31.9837 15.3877C31.1573 15.3504 30.3778 14.993 29.8102 14.3913C29.2426 13.7895 28.9313 12.9905 28.9423 12.1633L29.0625 5.27734L30.5368 5.30308L30.4166 12.1891C30.413 12.6372 30.5852 13.0689 30.8963 13.3915C31.2073 13.7141 31.6325 13.9019 32.0804 13.9147L39.2318 14.0395L39.2087 15.5138Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.4"
          d="M0.564453 32.3945L1.44241 32.779C1.36403 32.7917 1.284 32.7903 1.20612 32.7748C1.1136 32.7941 1.01794 32.7924 0.926156 32.77C0.76308 32.6942 0.634114 32.5603 0.564453 32.3945V32.3945Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.29969 42.431C4.75369 42.431 3.79226 40.7519 4.57487 39.4186L8.54936 32.6475C9.32227 31.3308 11.2261 31.3308 11.999 32.6475L15.9735 39.4186C16.7561 40.7519 15.7947 42.431 14.2487 42.431L11.9986 42.431L11.9986 49.2161C11.9986 49.9187 10.9885 50.4883 10.2742 50.4883C9.55984 50.4883 8.54954 49.9187 8.54954 49.2161L8.54954 42.431L6.29969 42.431Z"
          fill={calculateColor(color)}
        />
      </g>
    </svg>
  );
};
