import {
  calculateDocumentCounts,
  deepCopy,
  mapEvaluateValToContextVal,
} from "@prodoctivity/shared";
import type {
  DocumentCollectionDocumentView,
  DocumentCollectionFolderSortType,
  DocumentCollectionFolderView,
  DocumentCollectionItemType,
  ParametersObject,
} from "@prodoctivity/shared/src/index-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { documentSort, findDocumentInFolders, folderSort } from "./utils";

import { useDesignBreakpoint } from "@prodoctivity/design-system";
import type { DocumentCollectionInstance } from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { useDebounceValue } from "usehooks-ts";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";

export const useDocumentCollectionCard = (
  documentCollectionInstance: {
    id: string;
  } & DocumentCollectionInstance<string>,
  refetch: () => void
) => {
  const { getDocumentCollectionConfig, updateDocumentCollectionAssigned } = useServices();
  const [toastMessage, setToastMessage] = useState<
    | {
        type: "error" | "success" | "warn";
        message: string;
      }
    | undefined
  >(undefined);

  const fetchDocumentCollectionConfig = useCallback(() => {
    return getDocumentCollectionConfig(documentCollectionInstance.documentCollectionConfigId);
  }, [documentCollectionInstance.documentCollectionConfigId, getDocumentCollectionConfig]);

  const { data, isLoading } = useOrganizationQuery(
    `document-collection-config/${documentCollectionInstance.documentCollectionConfigId}`,
    fetchDocumentCollectionConfig
  );

  const fetchUpdateAssigned = useCallback(
    (assignedTo?: string) => {
      return updateDocumentCollectionAssigned(documentCollectionInstance.id, assignedTo);
    },
    [documentCollectionInstance, updateDocumentCollectionAssigned]
  );

  const { mutate: mutateUpdateAssigned, isLoading: isMutating } = useMutation(fetchUpdateAssigned, {
    onSuccess: refetch,
    onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
      if (error.response.data.errors.length > 0) {
        setToastMessage({ type: "error", message: error.response.data.errors[0].message });
        setTimeout(() => setToastMessage(undefined), 5000);
      }
    },
  });

  return {
    documentCollectionConfig: data?.config,
    isLoading,
    mutateUpdateAssigned,
    isMutating,
    toastMessage,
    setToastMessage,
  };
};

export const useDocumentCollectionInstance = () => {
  const [collectionTabIndex, setCollectionTabIndex] = useState(0);
  const [documentSelected, setDocumentSelected] = useState("");
  const { breakpoint } = useDesignBreakpoint();

  const documentSelectedState = useCallback((value: string) => {
    setDocumentSelected(value);
  }, []);
  const [filterTextFieldEnabled, setFilterTextFieldEnabled] = useState(false);
  const [sortSelectedOptions, SetSortSelectedOptions] = useState<{
    [pathKey: string]: DocumentCollectionFolderSortType;
  }>({
    defaultSort: {
      direction: "desc",
      type: "document-date",
    },
  });

  const [documentsDisplay, setDocumentsDisplay] = useState({
    missingDocuments: true,
    expiredDocuments: true,
    expiringDocuments: true,
  });
  const [filterValue, setFilterValue] = useState("");
  const clearAllFilters = () => {
    setFilterValue("");
    setDocumentsDisplay({
      missingDocuments: false,
      expiredDocuments: false,
      expiringDocuments: false,
    });
    SetSortSelectedOptions({
      defaultSort: {
        direction: "desc",
        type: "document-date",
      },
    });
  };

  const { documentCollectionId } = useParams();
  const { search } = useLocation();
  const {
    user,
    getDocumentCollection,
    getDocumentCollectionConfig,
    getDocument,
    viewDocumentCollectionInstance,
  } = useServices();
  const [isPanelHidden, setPanelHidden] = useState(false);

  const hidePanel = useCallback(() => {
    setPanelHidden(true);
  }, []);

  const showPanel = useCallback(() => {
    setPanelHidden(false);
  }, []);

  const [toastMessage, setToastMessage] = useState<
    | {
        type: "error" | "success" | "warn";
        message: string;
      }
    | undefined
  >(undefined);

  const [currDocument, rawSetCurrDocument] = useState<DocumentCollectionDocumentView | undefined>(
    undefined
  );

  const setCurrDocument = useCallback(
    (docView: DocumentCollectionDocumentView | undefined) => {
      rawSetCurrDocument(docView);
      if (breakpoint === "small") {
        hidePanel();
      }
    },
    [breakpoint, hidePanel]
  );

  const [folderPath, setFolderPath] = useState<Array<string>>([]);

  const fetchDocumentCollection = useCallback(async () => {
    if (!documentCollectionId) return { collection: undefined, keys: [] };
    try {
      return await getDocumentCollection(documentCollectionId);
    } catch (err: any) {
      if (err.response.status === 404) {
        return { collection: undefined, keys: [] };
      }
      throw err;
    }
  }, [documentCollectionId, getDocumentCollection]);

  const {
    data: documentCollectionResponse,
    // error: documentCollectionError,
    isLoading,
    refetch,
  } = useOrganizationQuery(`document-collection/${documentCollectionId}`, fetchDocumentCollection);

  const [showSnapshot, setShowSnapshot] = useState<boolean>(false);

  const folderView = useMemo(() => {
    if (!documentCollectionResponse?.collection) {
      return undefined;
    }
    if (!showSnapshot || !documentCollectionResponse.collection.rootFolder.snapshot) {
      return documentCollectionResponse.collection.rootFolder;
    }
    return documentCollectionResponse.collection.rootFolder.snapshot.folderView;
  }, [showSnapshot, documentCollectionResponse]);

  const documentCollectionResponseContext = useMemo(() => {
    const contextValue: ParametersObject = {};

    if (documentCollectionResponse?.keys) {
      documentCollectionResponse.keys.forEach((keyItem) => {
        const contextVal = mapEvaluateValToContextVal(keyItem.type, keyItem.value);
        contextValue[keyItem.fieldName] = contextVal;
      });
    }
    return contextValue;
  }, [documentCollectionResponse]);

  const postAction = useCallback(() => {
    if (!documentCollectionId) {
      return;
    }
    return viewDocumentCollectionInstance(documentCollectionId);
  }, [documentCollectionId, viewDocumentCollectionInstance]);

  useEffect(() => {
    if (documentCollectionResponse && documentCollectionResponse.collection) {
      setFolderPath([documentCollectionResponse.collection.name]);
      setShowSnapshot(!!documentCollectionResponse.collection.rootFolder.snapshot);
    }

    const action = postAction;
    const initialSaveTimer = setTimeout(() => {
      action();
    }, 5000);

    return () => {
      clearTimeout(initialSaveTimer);
    };
  }, [documentCollectionResponse, postAction]);

  const organizationNavigate = useOrganizationNavigate();

  const defaultDocumentId = useMemo(() => {
    const params = new URLSearchParams(search);
    const documentId = params.get("documentId");
    if (documentId && documentCollectionResponse?.collection && folderView) {
      const documentFound = findDocumentInFolders(
        [
          {
            ...folderView,
            name: documentCollectionResponse.collection.name,
          },
        ],
        documentId,
        "documentId"
      );
      if (!documentFound.document) {
        organizationNavigate(
          organizationLinkTemplates.documentCollection(documentCollectionResponse.collection.id)
        );
        return;
      }
      setCurrDocument(documentFound.document);
      setFolderPath(documentFound.path);
    }
    return documentId;
  }, [documentCollectionResponse, organizationNavigate, search, folderView, setCurrDocument]);

  const fetchDocumentCollectionConfig = useCallback(async () => {
    if (!documentCollectionResponse || !documentCollectionResponse.collection) {
      return {
        config: undefined,
      };
    }

    return getDocumentCollectionConfig(
      documentCollectionResponse.collection.documentCollectionConfigId
    );
  }, [documentCollectionResponse, getDocumentCollectionConfig]);

  const {
    data: configResponse,
    // error: configError,
    isLoading: isLoadingConfig,
  } = useOrganizationQuery(
    `document-collection-config/${documentCollectionResponse?.collection?.documentCollectionConfigId}`,
    fetchDocumentCollectionConfig,
    {
      enabled: !isLoading,
    }
  );

  useEffect(() => {
    if (
      configResponse &&
      configResponse.config &&
      !defaultDocumentId &&
      folderView &&
      documentCollectionResponse?.collection
    ) {
      const documentFound = findDocumentInFolders(
        [
          {
            ...folderView,
            name: documentCollectionResponse.collection.name,
          },
        ],
        configResponse.config.masterDocumentTypeId,
        "documentTypeId"
      );
      setFolderPath(documentFound.path);
      setCurrDocument(documentFound.document);
    }
  }, [documentCollectionResponse, defaultDocumentId, folderView, configResponse, setCurrDocument]);

  const fetchDocument = useCallback(async () => {
    if (!currDocument || currDocument.type === "missing-document") return { document: undefined };
    return getDocument(currDocument.documentId, currDocument.documentVersionId);
  }, [currDocument, getDocument]);

  const documentQueryKey = useMemo(() => {
    if (!currDocument || currDocument.type === "missing-document") return "missing-document";
    return `documents/${currDocument.documentId}/${currDocument.documentVersionId}`;
  }, [currDocument]);

  const onDocumentError = useCallback((err: unknown) => {
    const message = (err as any).response?.data?.errors
      ? (err as any).response?.data?.errors[0].message
      : "";
    if (message) {
      setToastMessage({ type: "error", message: message });
    }
  }, []);

  const { data: documentResponse, isLoading: isLoadingDocument } = useOrganizationQuery(
    `${documentQueryKey}`,
    fetchDocument,
    {
      enabled: currDocument && currDocument.type === "document",
      onError: onDocumentError,
    }
  );

  //TODO: Add a query to get the users to notify
  // const { data: usersToNotify, isLoading: isLoadingUsersToNotify } = useOrganizationQuery(
  //   `users-to-notify/${documentCollectionResponse?.collection?.documentCollectionConfigId || ""}/`,
  //   getUsersToNotify,
  //   {
  //     staleTime: 60 * 1000,
  //     refetchInterval: 60 * 1000,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const globalDocumentsCount = useMemo(() => {
    if (!folderView) {
      return { documentCount: 0, issueCount: 0, toExpireCount: 0, expiredCount: 0 };
    }

    const rootFolder = deepCopy(folderView);

    calculateDocumentCounts(rootFolder, []);

    return {
      issueCount: rootFolder.counts.issueCount,
      expiredCount: rootFolder.counts.expiredCount,
      toExpireCount: rootFolder.counts.toExpireCount,
      documentCount: rootFolder.counts.documentCount,
    };
  }, [folderView]);

  const isComponentLoading = isLoading || isLoadingConfig;

  return {
    breakpoint,
    showSnapshot,
    setShowSnapshot,
    folderView,
    collectionTabIndex,
    setCollectionTabIndex,
    user,
    documentCollection: documentCollectionResponse?.collection,
    documentCollectionResponseContext,
    refetch,
    globalDocumentsCount,
    collectionConfig: configResponse?.config,
    isLoading,
    isLoadingConfig,
    setFolderPath,
    folderPath,
    currDocument,
    setCurrDocument,
    isLoadingDocument,
    ecmDocument: documentResponse?.document,
    toastMessage,
    setToastMessage,
    isPanelHidden,
    hidePanel,
    showPanel,
    filterValue,
    setFilterValue,
    setDocumentsDisplay,
    documentsDisplay,
    sortSelectedOptions,
    SetSortSelectedOptions,
    filterTextFieldEnabled,
    setFilterTextFieldEnabled,
    clearAllFilters,
    documentSelected,
    documentSelectedState,
    isComponentLoading,
  };
};

export const useFolderTreeInstance = (
  depth: number,
  folderPath: Array<string>,
  originalFolder: DocumentCollectionFolderView,
  selectedFolderPath: Array<string>,
  pathList: Array<string>,
  resources: ReturnType<typeof useAppTranslation>["resources"],
  onFolderSelect: (newFolderPath: Array<string>) => void
) => {
  const [showSnapshot, setShowSnapshot] = useState<boolean>(!!originalFolder.snapshot);

  const folderView = useMemo(() => {
    if (showSnapshot) {
      return originalFolder.snapshot?.folderView || originalFolder;
    }
    return originalFolder;
  }, [showSnapshot, originalFolder]);

  const folderSortOptions = useMemo((): Array<{
    label: string;
    value: DocumentCollectionFolderSortType["type"];
  }> => {
    return [
      {
        value: "document-date",
        label: resources.documentDate,
      },
      {
        value: "creation-date",
        label: resources.creationDate,
      },
      {
        value: "folder-name",
        label: resources.name,
      },
      {
        value: "update-date",
        label: resources.updateDate,
      },
    ];
  }, [resources]);
  const [openSortFilter, setOpenSortFilter] = useState(false);
  const [issuePopoverState, setIssuePopoverState] = useState(false);

  const [showChildren, setShowChildren] = useState(
    selectedFolderPath.toString().includes([...folderPath, folderView.name].toString()) ||
      pathList.some((p) => p === [...folderPath, folderView.name].toString()) ||
      depth <= 1
  );

  const currentPath = useMemo(() => {
    return [...folderPath, folderView.name];
  }, [folderView, folderPath]);
  const isFolderSelected = useMemo(() => {
    return selectedFolderPath.toString() === currentPath.toString();
  }, [selectedFolderPath, currentPath]);

  const paddingLeft = useMemo(() => depth * 13, [depth]);

  const documentsCount = useMemo(() => {
    const result = deepCopy(folderView);

    calculateDocumentCounts(result, []);
    return {
      issuesCount: result.counts.issueCount,
      expiredCount: result.counts.expiredCount,
      toExpireCount: result.counts.toExpireCount,
      documentCount: result.counts.documentCount,
    };
  }, [folderView]);

  const sortToFoldersAndDocuments = (
    sortOptions: DocumentCollectionFolderSortType,
    itemA: DocumentCollectionItemType,
    itemB: DocumentCollectionItemType
  ): number => {
    const getSortValue = (item: DocumentCollectionItemType, sortType: string) => {
      switch (sortType) {
        case "folder-name":
          return "name" in item ? item.name : "";
        case "document-date":
          return "documentDate" in item ? new Date(item.documentDate).getTime() : 0;
        case "creation-date":
          return "createdAt" in item ? new Date(item.createdAt).getTime() : 0;
        case "update-date":
          return "updatedAt" in item ? new Date(item.updatedAt).getTime() : 0;
        default:
          return "";
      }
    };

    const valueA = getSortValue(itemA, sortOptions.type);
    const valueB = getSortValue(itemB, sortOptions.type);

    let compareValue = 0;

    if (typeof valueA === "string" && typeof valueB === "string") {
      compareValue = valueA.localeCompare(valueB);
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      compareValue = valueA - valueB;
    }

    return sortOptions.direction === "desc" ? -compareValue : compareValue;
  };

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const selectCollectionElement = () => {
    onFolderSelect(currentPath);
  };

  const changeFolderState = () => {
    setShowChildren((prevOpen) => !prevOpen);
  };

  const closeFolder = useCallback(() => {
    setShowChildren(false);
  }, []);

  const changeSortFilterPopoverState = () => {
    setOpenSortFilter((prevOpen) => !prevOpen);
  };

  const changeIssueDocumentPopoverState = useCallback(() => {
    setIssuePopoverState((prevOpen) => !prevOpen);
  }, [setIssuePopoverState]);
  return {
    folderView,
    showSnapshot,
    setShowSnapshot,
    paddingLeft,
    showChildren,
    setShowChildren,
    closeFolder,
    documentsCount,
    currentPath,
    isFolderSelected,
    openSortFilter,
    setOpenSortFilter,
    folderSortOptions,
    sortToFoldersAndDocuments,
    anchorRef,
    selectCollectionElement,
    changeFolderState,
    changeSortFilterPopoverState,
    changeIssueDocumentPopoverState,
    issuePopoverState,
  };
};

export const useCollectionExplorer = (folder: DocumentCollectionFolderView) => {
  const [debouncedFilter, setFilter] = useDebounceValue("", 600);

  const filteredFolders = useMemo(() => {
    const filterLowerCase = debouncedFilter ? debouncedFilter.toLowerCase() : "";
    return folder.folders
      .sort((a, b) => folderSort(folder.currentSort, a, b))
      .filter((d) => d.name.toLowerCase().includes(filterLowerCase));
  }, [debouncedFilter, folder]);

  const filteredDocuments = useMemo(() => {
    const filterLowerCase = debouncedFilter ? debouncedFilter.toLowerCase() : "";
    return folder.documents
      .sort((a, b) => documentSort(folder.currentSort, a, b))
      .filter((d) => d.name.toLowerCase().includes(filterLowerCase));
  }, [debouncedFilter, folder]);

  return { filter: debouncedFilter, setFilter, filteredFolders, filteredDocuments };
};
