import {
  Box,
  BoxWithRef,
  Checkbox,
  DeleteDocumentModal,
  Layer,
  Popover,
  Table,
  TapArea,
  Text,
  Tooltip,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { SyntheticEvent, useCallback, useRef, useState } from "react";

import type { DeletedDocumentSummary } from "@prodoctivity/types";
import { FancyDateTime } from "../../components/Display/FancyDateTime";
import { PublicProfile } from "../../components/Profile/Public/PublicProfile";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { DeletedDocumentSvgIcon } from "../../svg/DeletedDocumentSvgIcon";
import { DocIconsSvg } from "../../svg/DocumentIconsSvg";
import { EllipseMenuSvg } from "../../svg/EllipseMenuSvg";
import { RestoreDeletedDocumentSvgIcon } from "../../svg/RestoreDeletedDocumentSvgIcon";
import { DeletedDocumentSmallList } from "./DeletedDocumentSmallList";

type Props = {
  document: DeletedDocumentSummary;
  selectToggle: boolean;
  selectAll: boolean;
  onCheckboxChange: (id: string, isChecked: boolean) => void;
  onDeleteDocument: (id: string) => void;
  onRestoreDocument: (id: string) => void;
  onSelectAll: (checked: boolean) => void;
  index: number;
  last: number;
};

export function DeletedDocumentCardList({
  index,
  document,
  selectToggle,
  selectAll,
  onSelectAll,
  onCheckboxChange,
  onDeleteDocument: handleDeleteDocument,
  onRestoreDocument: handleRestoreDocument,
  last,
}: Props) {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const anchorRefItems = useRef<Array<HTMLDivElement | null>>([]);
  const breakpointDuo = breakpoint === "small" || breakpoint === "medium";

  const [isOpenMenu, setIsOpenMenu] = useState<boolean[]>([false]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showIndividualDeleteModal, setShowIndividualDeleteModal] = useState<boolean>(false);

  const handleChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement, Event>) => {
      const { checked, id } = event.currentTarget;

      if (selectAll) onSelectAll(false);

      onCheckboxChange(id, checked);

      setIsCheckboxChecked(checked);
    },
    [onCheckboxChange, onSelectAll, selectAll]
  );

  const handleOpen = useCallback((id: number) => {
    setIsOpenMenu((prevOpenStates: boolean[]) => {
      const updatedStates = [...prevOpenStates];
      updatedStates[id] = !prevOpenStates[id];
      return updatedStates;
    });
  }, []);

  return (
    <>
      {breakpointDuo ? (
        <DeletedDocumentSmallList
          index={index}
          lastPosition={last - 1}
          document={document}
          selectToggle={selectToggle}
          selectAll={selectAll}
          onCheckboxChange={(id, isChecked) => onCheckboxChange(id, isChecked)}
          onDeleteDocument={(id) => handleDeleteDocument(id)}
          onRestoreDocument={handleRestoreDocument}
          onSelectAll={onSelectAll}
        />
      ) : (
        <Table.Row key={index} hoverStyle="gray">
          <Table.Cell>
            <Box display="flex" direction="row" alignItems="center">
              {selectToggle && (
                <Checkbox
                  id={document.documentId}
                  name={document.documentTypeName}
                  checked={selectAll ? true : isCheckboxChecked}
                  onChange={(e) => handleChange(e.event)}
                />
              )}
              <Box paddingX={2}>{<DocIconsSvg />}</Box>
              {document.name ? (
                <Text size="300">{document.documentTypeName}</Text>
              ) : (
                <Text size="300">...</Text>
              )}
            </Box>
          </Table.Cell>
          <Table.Cell>
            <Box width={"1%"} borderStyle={"sm"} borderRadius={4} height={25} />
          </Table.Cell>
          <Table.Cell>
            <Text size="300" title={document.name} ellipsisLength={65}>
              {document.name}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Box width={"1%"} borderStyle={"sm"} borderRadius={4} height={25} />
          </Table.Cell>
          <Table.Cell>
            <PublicProfile username={document.deletedBy} />
          </Table.Cell>
          <Table.Cell>
            <Box width={"1%"} borderStyle={"sm"} borderRadius={4} height={25} />
          </Table.Cell>
          {breakpoint === "hd" && (
            <Table.Cell>
              <FancyDateTime value={document.deletedAt} />
            </Table.Cell>
          )}
          <Table.Cell>
            <BoxWithRef ref={(ref) => (anchorRefItems.current[index] = ref)}>
              <TapArea
                onTap={() => {
                  handleOpen(index);
                }}
                accessibilityLabel={resources.open}
              >
                <EllipseMenuSvg />
                {isOpenMenu[index] === true && (
                  <Layer>
                    <Popover
                      anchor={anchorRefItems.current[index]}
                      onDismiss={() => setIsOpenMenu([false])}
                      idealDirection="down"
                      positionRelativeToAnchor={false}
                      size={"flexible"}
                      shouldFocus={true}
                    >
                      <Box display="flex" width={170} direction="column">
                        {" "}
                        <TapArea>
                          <Box
                            display="flex"
                            onClickCapture={() => handleRestoreDocument(document.documentId)}
                            width={"100%"}
                            height={"auto"}
                            padding={2}
                            hoverColor={colors.primaryHover0}
                          >
                            <Tooltip text={resources.restore}>
                              <Box
                                display="flex"
                                direction="row"
                                paddingY={2}
                                paddingLeft={2}
                                alignItems="center"
                              >
                                <RestoreDeletedDocumentSvgIcon width={25} color={colors.black600} />
                                <Box display="flex" alignItems="center" paddingX={2}>
                                  <Text size="300">{resources.restore}</Text>
                                </Box>
                              </Box>
                            </Tooltip>
                          </Box>
                        </TapArea>
                        <TapArea>
                          <Box
                            display="flex"
                            onClickCapture={() => setShowIndividualDeleteModal(true)}
                            width={"100%"}
                            height={"auto"}
                            padding={2}
                            hoverColor={colors.primaryHover0}
                          >
                            <Tooltip text={resources.delete_}>
                              <Box
                                display="flex"
                                direction="row"
                                paddingY={2}
                                paddingLeft={3}
                                alignItems="center"
                              >
                                <DeletedDocumentSvgIcon
                                  width={20}
                                  height={26}
                                  color={colors.black600}
                                />
                                <Box display="flex" alignItems="center" paddingX={2}>
                                  <Text size="300">{resources.delete_}</Text>
                                </Box>
                              </Box>
                            </Tooltip>
                          </Box>
                        </TapArea>
                      </Box>
                    </Popover>
                  </Layer>
                )}
              </TapArea>
            </BoxWithRef>
            <DeleteDocumentModal
              permanentDelete={true}
              show={showIndividualDeleteModal}
              onDismiss={() => setShowIndividualDeleteModal(false)}
              deleteDocument={() => {
                handleDeleteDocument(document.documentId);
                setShowIndividualDeleteModal(false);
              }}
              resources={resources}
            />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
}
