import "./ProDoctivityFormGridLayout.css";

import {
  Box,
  DataElementInput,
  DatePickerProps,
  DesignBreakpointType,
  FormController,
  MarkdownViewer,
  ProDoctivityColorBundle,
  emptyFormController,
  type DataElementInputProps,
} from "@prodoctivity/design-system";
import { isTextLineContextField, replaceActiveContent, shouldNever } from "@prodoctivity/shared";
import type {
  ContextField,
  DataElement,
  ParametersObject,
  RecordContextStack,
  TemplateContextDefinition,
  TemplateWizardDefinition,
} from "@prodoctivity/shared/src/index-types";
import type {
  FormDefinition,
  FormValues,
  GroupLayouts,
  GroupValues,
  ProDoctivityFormLayout,
  SequenceBehavior,
  ValidationErrors,
  WebServiceConnector,
} from "../_lib/types";
import { ValueItem, getWizardFieldSections, toValueItems } from "./utils";

import momentType from "moment";
import { Component } from "react";
import { GroupComponent } from "../Group";
import { Topic } from "../Topic";
import { noop } from "../_lib/utils";
import { wizardDefinitionToLayout } from "../_lib/wizardDefinition-to-layout";
import { FormSummary } from "./FormSummary";
import { ResponsiveReactGridLayout } from "./ResponsiveReactGridLayout";

const margin = [10, 10];
const rowHeight = 30;

const emptyArrayForIndexes: number[] = [];
const emptyContextStack: Array<{
  name: string;
  value: ParametersObject;
}> = [];

type Props = {
  formDefinition: FormDefinition;
  getDataElement: (
    fullPath: string
  ) => { contextField: ContextField; dataElement: DataElement } | undefined;
  layout: ProDoctivityFormLayout;
  showPins: boolean;
  sequenceBehaviour: SequenceBehavior;
  summaryMode: boolean;
  i18n: (key: string) => string;
  navigate: string;
  paginated?: boolean;
  onNavigate: (to: string) => void;
  onLayoutUpdated: () => void;
  updateLayout: any;
  contextDefinition: TemplateContextDefinition;
  layoutTopics: Set<string>;
  isDesignMode?: boolean;
  editingTopic?: boolean | string;
  onLayoutChange: any;
  onChooseAlternativeQuestion: any;
  enableTopicEditing?: () => void;
  onSubmitEditTopic?: any;
  removeTopic?: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => void;
  readOnly: boolean;
  onGroupLayoutChange?: (groupName: string, layout: ProDoctivityFormLayout) => void;
  groupLayouts: GroupLayouts;
  //paginated,
  connectors: WebServiceConnector[];
  formValues: FormValues;
  groupValues: GroupValues;
  disabledElements: string[];
  pinnedElements: string[];
  onPin: (dataElementName: string) => void;
  fireConnectors: (dataElementName: string, groupName?: string | undefined) => void;
  onFormErrorOccurred: (name: string, errors: ValidationErrors) => void;
  groupDataFromConnectors: any;
  onNoDataFound?: (message: string) => void;
  defaultColumns?: string;
  componentBreakpoint: DesignBreakpointType;
  wizardDefinition: TemplateWizardDefinition;
  purpose?: DataElementInputProps["purpose"];
  moment: typeof momentType;
  colors: ProDoctivityColorBundle;
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    collapse: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
    expand: string;
  };
};

export class ProDoctivityFormGridLayout extends Component<Props> {
  static displayName = "ProDoctivityFormGridLayout";

  static defaultProps = {
    sequenceBehaviour: "Lock",
    onNoDataFound: noop,
    navigate: null,
    paginated: false,
  };

  state = {
    inSummaryMode: false,
  };

  componentDidMount() {
    if (this.props.summaryMode) {
      this.setState({ inSummaryMode: true });
    }
  }

  changeGroupHeight = () => {
    this.props.layout
      .filter((l) => l.type === "group")
      .forEach((l) => {
        const element = document.getElementById(l.i);
        if (element) {
          element.style.height = "auto";
        }
      });
  };

  componentWillReceiveProps(props: Props) {
    this.setState({ formDefinition: props.formDefinition });
  }

  componentDidUpdate() {
    this.props.onLayoutUpdated();
  }

  getGroupDomNodes(groupName: string) {
    //const domNode = findDOMNode(this)
    //const reactGridItem = domNode?.querySelector(`[id="group__${groupName}"]`)
    const reactGridItem = document.getElementById(`group__${groupName}`);
    const contentNode = reactGridItem?.querySelector(".Group") as HTMLElement;

    return { reactGridItem, contentNode };
  }

  resizeGroupLayoutItemHeight(contentOffsetHeight: number, groupName: string) {
    this.props.updateLayout(({ layout }: { layout: ProDoctivityFormLayout }) => {
      // The formula for the offsetHeight is:
      // offsetHeight = h*rowHeight + marginY * (h - 1)
      const [, marginY] = margin;
      const newH = Math.ceil((contentOffsetHeight + marginY) / (rowHeight + marginY));

      const newLayout = layout.map((l) => {
        return l.type === "group" && l.name === groupName
          ? {
              ...l,
              h: newH,
            }
          : l;
      });
      return { layout: newLayout };
    });
  }

  onTableGroupModeChange = (groupName: string, value: any) => {
    this.changeItemGroupLayout("groupTableMode", value, groupName);
  };

  changeItemGroupLayout(propertyName: string, value: any, groupName: string) {
    //Used to change any property of ProDoctivityFormLayoutItem
    this.props.updateLayout(({ layout }: { layout: ProDoctivityFormLayout }) => {
      const newLayout = layout.map((l) => {
        return l.type === "group" && l.name === groupName
          ? {
              ...l,
              [propertyName]: value,
            }
          : l;
      });
      return { layout: newLayout };
    });
  }

  onFormDefinitionChange = (formDefinition: FormDefinition) => {
    const formDefinitions = this.props.formDefinition;
    formDefinitions.contextFields = formDefinition.contextFields;

    this.setState({ formDefinition: formDefinitions });
  };

  context: React.ContextType<typeof FormController> = emptyFormController;

  render() {
    const {
      layout,
      layoutTopics,
      isDesignMode,
      editingTopic,
      onLayoutChange,
      // onChooseAlternativeQuestion,
      enableTopicEditing,
      onSubmitEditTopic,
      removeTopic,
      readOnly,
      onGroupLayoutChange,
      groupLayouts,
      showPins,
      sequenceBehaviour,
      i18n,
      // navigate,
      onNavigate,
      paginated,
      connectors,
      formValues,
      groupValues,
      disabledElements,
      pinnedElements,
      fireConnectors,
      onFormErrorOccurred,
      groupDataFromConnectors,
      defaultColumns,
      componentBreakpoint,
      moment,
      contextDefinition,
      wizardDefinition,
      getDataElement,
      purpose,
      resources,
    } = this.props;

    const { getContextValueAsString: getContextValue } = this.context;

    const fullGroupLayouts = groupLayouts;

    const { inSummaryMode } = this.state;

    const hasGroups = paginated && layout.some((e) => e.type === "group");

    const { fields } = getWizardFieldSections(wizardDefinition);

    const isElementDisabled = (fullPath: string, dataElement: DataElement) => {
      return (
        disabledElements.includes(fullPath) ||
        readOnly ||
        (!!dataElement &&
          isTextLineContextField(dataElement) &&
          !!dataElement.sequenceId &&
          sequenceBehaviour === "Lock")
      );
    };

    const formLayout = wizardDefinitionToLayout(wizardDefinition, contextDefinition, undefined);

    const itemsByTopic = (() => {
      return toValueItems(
        wizardDefinition,
        getDataElement,
        groupValues,
        formValues,
        moment,
        isElementDisabled
      ).reduce((result: Array<{ topic: ValueItem; items: ValueItem[] }>, layoutItem) => {
        switch (layoutItem.type) {
          case "topic": {
            result.push({
              topic: layoutItem,
              items: [],
            });
            break;
          }
          case "dataElement":
          case "group":
            if (!result.length) {
              result.push({
                topic: {
                  type: "topic",
                  name: i18n("general"),
                  description: "",
                  disabled: false,
                },
                items: [],
              });
            }

            result[result.length - 1].items.push(layoutItem);
            break;
          default:
            shouldNever(layoutItem);
            throw new Error("Should not happen");
        }

        return result;
      }, []);
    })();

    const localGetDynamicValue = (text: string) => {
      return replaceActiveContent(this.context.context, [], text);
    };

    const localGetContextValue = (k: string) => {
      return getContextValue(k, []);
    };

    return inSummaryMode ? (
      <Box
        display="flex"
        direction="column"
        flex="grow"
        overflow="auto"
        paddingX={this.props.componentBreakpoint === "small" ? 2 : 0}
      >
        <FormSummary
          recordPrefix=""
          recordInstanceFullPath=""
          wizardDefinition={this.context.wizardDefinition}
          contextDefinition={this.context.contextDefinition}
          layoutTopics={layoutTopics}
          getDataElement={getDataElement}
          disabledElements={disabledElements}
          sequenceBehavior={sequenceBehaviour}
          sequenceBehaviorEnumLock={sequenceBehaviour === "Lock"}
          readOnly={readOnly}
          i18n={i18n}
          onNavigate={onNavigate}
          connectors={connectors}
          defaultColumns={defaultColumns}
          componentBreakpoint={componentBreakpoint}
          moment={moment}
          recordContextStack={emptyContextStack}
          resources={resources}
        />
      </Box>
    ) : (
      <Box paddingX={this.props.componentBreakpoint === "small" ? 2 : 0}>
        <ResponsiveReactGridLayout
          key={componentBreakpoint}
          className={"layout " + (hasGroups ? "PageScroll" : "")}
          layout={formLayout.layout}
          onLayoutChange={onLayoutChange}
          isDraggable={isDesignMode}
          isResizable={isDesignMode}
          cols={componentBreakpoint === "small" ? 4 : 12}
          rowHeight={rowHeight}
          margin={margin as [number, number]}
          draggableHandle=".draggable"
        >
          {itemsByTopic
            .map(({ topic, items }) => {
              if (topic.type !== "topic") {
                return null;
              }

              const id = topic.name;
              return (
                <div key={`topic__${topic.name}`} className={isDesignMode ? "draggable" : ""}>
                  <Box display="flex" direction="column">
                    <Topic
                      isBeingEdited={editingTopic === id || editingTopic === true ? true : false}
                      isDesignMode={isDesignMode}
                      topicId={id}
                      topicName={topic.name}
                      enableTopicEditing={enableTopicEditing || noop}
                      removeTopic={removeTopic || noop}
                      onSubmitEditTopic={onSubmitEditTopic}
                      layoutTopics={layoutTopics}
                      i18n={i18n}
                      paddingX={0}
                    />
                    {!!topic.description && <MarkdownViewer markdownText={topic.description} />}
                    <Box
                      color={this.props.colors.white}
                      padding={4}
                      display="flex"
                      wrap={true}
                      gap={2}
                      maxWidth={componentBreakpoint === "small" ? 370 : undefined}
                    >
                      {items
                        .map((layoutItem) => {
                          if (layoutItem.type === "topic") {
                            return null;
                          }

                          if (layoutItem.type === "group") {
                            const contextWizardRecord = fields.find(
                              (x) => x.isRecord === true && layoutItem.name === x.key
                            );

                            const contextRecord = contextDefinition.records.find(
                              (key) => key.name === layoutItem.name
                            );

                            const recordContents = groupValues[layoutItem.name];

                            const recordContextStack: RecordContextStack = [];

                            //TODO: @eburgos Fix this hard coding
                            // const groupTableMode = layoutItem.groupTableMode;
                            const isTableMode = false;
                            // componentBreakpoint === "small"
                            //   ? false
                            //   : navigate === layoutItem.name
                            //   ? false
                            //   : groupTableMode;

                            return (
                              <Box
                                display="flex"
                                key={layoutItem.name}
                                id={layoutItem.name}
                                width="calc(100% - 1px)"
                              >
                                {contextRecord &&
                                  contextWizardRecord &&
                                  contextWizardRecord.isRecord &&
                                  Array.isArray(recordContents) && (
                                    <GroupComponent
                                      templateWizardRecord={contextWizardRecord}
                                      contextRecord={contextRecord}
                                      getDataElement={getDataElement}
                                      instanceFullPath={""}
                                      isDesignMode={isDesignMode}
                                      readOnly={readOnly}
                                      onGroupLayoutChange={onGroupLayoutChange}
                                      groupLayouts={groupLayouts}
                                      fullGroupLayout={fullGroupLayouts[layoutItem.name]}
                                      layout={groupLayouts[layoutItem.name]}
                                      initialValue={recordContents}
                                      onTableGroupModeChange={this.onTableGroupModeChange}
                                      groupTableMode={isTableMode}
                                      onFormErrorOccurred={onFormErrorOccurred}
                                      i18n={i18n}
                                      fireConnectors={fireConnectors}
                                      formValues={formValues}
                                      onCurrentValues={this.context.updateContextRecordData}
                                      dataFromFiredConnectors={
                                        groupDataFromConnectors &&
                                        layoutItem.name === groupDataFromConnectors.groupName
                                          ? groupDataFromConnectors.data
                                          : null
                                      }
                                      generalFormValues={formValues}
                                      moment={moment}
                                      componentBreakpoint={componentBreakpoint}
                                      colors={this.props.colors}
                                      resources={resources}
                                      recordContextStack={recordContextStack}
                                      indexes={emptyArrayForIndexes}
                                    />
                                  )}
                              </Box>
                            );
                          } else if (layoutItem.type === "dataElement") {
                            const element = getDataElement(layoutItem.name);

                            if (!element) {
                              return null;
                            }

                            const field = layoutItem.wizardField;
                            const contextFieldProperties = layoutItem.contextField.properties;

                            const width = field.isRecord
                              ? "calc(100% - 1px)"
                              : contextFieldProperties
                              ? contextFieldProperties.controlSize === "Small"
                                ? "calc(33% - 2px)"
                                : contextFieldProperties.controlSize === "Normal"
                                ? "calc(66% - 2px)"
                                : "calc(100% - 2px)"
                              : "calc(100% - 2px)";

                            const { contextField, dataElement } = element;

                            const isPinned = pinnedElements.includes(layoutItem.name);
                            const disabled =
                              disabledElements.includes(layoutItem.name) ||
                              isDesignMode ||
                              readOnly ||
                              (showPins && isPinned) ||
                              (isTextLineContextField(dataElement) &&
                                !!dataElement.sequenceId &&
                                sequenceBehaviour === "Lock");

                            // const sequenceValue =
                            //   !!isTextLineContextField(dataElement) && dataElement.sequenceId
                            //     ? dataElement.sampleValue
                            //     : formValues[layoutItem.name];

                            // const value = !!formValues[layoutItem.name]
                            //   ? formValues[layoutItem.name]
                            //   : sequenceValue === undefined
                            //   ? null
                            //   : sequenceValue;

                            return (
                              <div
                                key={layoutItem.instanceFullPath}
                                className={`form-input-field ${
                                  isDesignMode ? "draggable" : "inputVisibility"
                                }`}
                                style={{ flex: "0 0 auto", width }}
                              >
                                {/* {contextField && field && (
                            <DictionaryInputControl
                              contextField={contextField}
                              field={field}
                              disabled={disabled}
                              typeValue={formValueToValueType(dataElement, formValues)}
                              // dataElement={dataElement}
                              // filterListByDocumentType={this.filterListByDocumentType}
                              // allowedListValues={this.props.formDefinition.allowedListValues}
                              // onChooseAlternativeQuestion={onChooseAlternativeQuestion}
                              // chosenQuestion={itemLayout.chosenQuestion}
                              onChange={(typeValue) =>
                                onChangeFormValue({ name: dataElement.name, value: typeValue.value })
                              }
                              val={value}
                              // onAutocompleteSearch={onAutocompleteSearch}
                              onBlur={fireConnectors}
                              // showPins={showPins}
                              // isPinned={isPinned}
                              // onPin={onPin}
                              //sequenceBehaviour={sequenceBehaviour}
                              // useSampleData={useSampleData && autoFill}
                              // onSampleDataUsed={this.markAutofillDone}
                              // i18n={i18n}
                              moment={moment}
                            />
                          )} */}
                                {contextField && field && (
                                  <DataElementInput
                                    componentBreakpoint={componentBreakpoint}
                                    instanceFullPath={layoutItem.instanceFullPath}
                                    contextField={contextField}
                                    disabled={disabled}
                                    typeValue={layoutItem.typeValue}
                                    // dataElement={dataElement}
                                    // filterListByDocumentType={this.filterListByDocumentType}
                                    // allowedListValues={this.props.formDefinition.allowedListValues}
                                    // onChooseAlternativeQuestion={onChooseAlternativeQuestion}
                                    // chosenQuestion={layoutItem.chosenQuestion}
                                    onChange={(typeValue) => {
                                      this.context.updateContextRecordData(
                                        "",
                                        typeValue,
                                        contextField.fullPath
                                      );
                                    }}
                                    // val={value}
                                    // onAutocompleteSearch={onAutocompleteSearch}
                                    onBlur={fireConnectors}
                                    getContextValueAsString={localGetContextValue}
                                    getDynamicValue={localGetDynamicValue}
                                    // showPins={showPins}
                                    // isPinned={isPinned}
                                    // onPin={onPin}
                                    //sequenceBehaviour={sequenceBehaviour}
                                    // useSampleData={useSampleData && autoFill}
                                    // onSampleDataUsed={this.markAutofillDone}
                                    // i18n={i18n}
                                    moment={moment}
                                    resources={resources}
                                    purpose={purpose}
                                  />
                                )}
                              </div>
                            );
                          } else {
                            shouldNever(layoutItem);
                            throw new Error(
                              `Unimplemented or not provided layout item type '${layoutItem}'.`
                            );
                          }
                        })
                        .reduce((acc: JSX.Element[], next) => {
                          if (next) {
                            acc.push(next);
                          }
                          return acc;
                        }, [])}
                    </Box>
                  </Box>
                </div>
              );
            })
            .reduce((acc: JSX.Element[], next) => {
              if (next) {
                acc.push(next);
              }
              return acc;
            }, [])}
        </ResponsiveReactGridLayout>
      </Box>
    );
  }
}

ProDoctivityFormGridLayout.contextType = FormController;
