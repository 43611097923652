import {
  Accordion,
  Box,
  BoxWithRef,
  Divider,
  Icon,
  IconType,
  Image,
  Mask,
  Skeleton,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import md5 from "md5";
import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useUserPublicProfile } from "../../hooks";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { useSettings } from "../../hooks/useSettings";
import { organizationLinkTemplates } from "../../link-templates";
import { DeletedDocumentSvgIcon } from "../../svg/DeletedDocumentSvgIcon";
import { ListSvg } from "../../svg/ListSvg";
import { OrganizationLink } from "../OrganizationLink";
import { usePlanSetting } from "../../hooks/usePlanSetting";

function defaultImageUrl(email: string) {
  return `https://www.gravatar.com/avatar/${md5(email.trim().toLowerCase())}.jpg?s=44&d=404`;
}

type Props = {
  name: string;
  email: string;
  imageDataURI?: string;
  forceOpen?: boolean;
  setShowMobileMenu: (value: boolean) => void;
};

function AvatarProfile({ name, email, imageDataURI, setShowMobileMenu }: Props) {
  const { colors } = useColors();
  const [open, setOpen] = useState(false);
  const { resources } = useAppTranslation();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const upgradePlanSetting = usePlanSetting();
  const { user } = useServices();
  const { disableSignUp } = useSettings();

  const isAdmin = useMemo(() => {
    return (user?.permissions || []).includes("organization-admin");
  }, [user]);

  const onClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  type MenuItem = {
    label: string;
    icon: IconType | JSX.Element;
    type: "link" | "raw-link" | "element";
    link: string;
    linkText?: string;
    element?: JSX.Element;
  };

  const topMenuItems: MenuItem[] = useMemo(() => {
    const pricingEntries = [
      ...(disableSignUp || !isAdmin || upgradePlanSetting?.upgradePlanBehavior === "blocked"
        ? ([] as const)
        : ([
            {
              label: resources.upgradePlan,
              icon: "crown",
              link: organizationLinkTemplates.upgradePlan(),
              type: "link",
              linkText: resources.upgradePlan,
            },
          ] as const)),
    ];

    return [
      {
        label: resources.myProfile,
        icon: "user-large",
        link: organizationLinkTemplates.account(),
        type: "link",
        linkText: resources.account,
      },
      ...pricingEntries,
      {
        label: resources.taskHistory,
        icon: "ballot-check",
        link: "/tasks",
        type: "link",
        linkText: resources.taskHistory,
      },
      {
        label: resources.recentSearches,
        icon: "clock-rotate-left",
        link: "/",
        type: "link",
        linkText: resources.recentSearches,
      },
      {
        label: resources.browseDocument.browseDocuments,
        icon: "doc-view",
        link: organizationLinkTemplates.viewDocuments(),
        type: "link",
        linkText: resources.browseDocument.browseDocuments,
      },
      {
        label: resources.myLists,
        icon: <ListSvg width={24} height={24} color={colors.neutral500} />,
        link: organizationLinkTemplates.viewMyLists(),
        type: "link",
        linkText: resources.myLists,
      },
      {
        label: resources.documentCollection.documentCollections,
        icon: "doc-filter",
        link: organizationLinkTemplates.documentCollectionList({
          pageNumber: 0,
          rowsPerPage: "15",
          filter: "",
          assignedTo: "",
        }),
        type: "link",
        linkText: resources.documentCollection.documentCollections,
      },
      {
        label: resources.documentRecovery,
        icon: <DeletedDocumentSvgIcon width={23} height={23} color={colors.neutral500} />,
        link: organizationLinkTemplates.documentRecovery(),
        type: "link",
        linkText: resources.documentRecovery,
      },
      {
        label: resources.generations,
        icon: "generated-doc",
        link: "/generations",
        type: "link",
        linkText: resources.generations,
      },
      {
        label: resources.language,
        icon: "timeline",
        type: "link",
        link: "/account",
        linkText: `${resources.language}: English`,
      },
      {
        icon: "alarm",
        label: "",
        type: "element",
        link: "",
        element: <Divider />,
      },
      ...(isAdmin
        ? [
            {
              label: resources.settings,
              icon: "screwdriver-wrench",
              type: "link",
              link: "/settings",
              linkText: resources.settings,
            } as const,
          ]
        : []),
      {
        label: resources.signOut,
        icon: "power-off",
        type: "raw-link",
        link: "/logout",
        linkText: resources.signOut,
      },
    ];
  }, [
    upgradePlanSetting?.upgradePlanBehavior,
    resources.upgradePlan,
    resources.myProfile,
    resources.account,
    resources.taskHistory,
    resources.recentSearches,
    resources.browseDocument.browseDocuments,
    resources.myLists,
    resources.documentCollection.documentCollections,
    resources.documentRecovery,
    resources.generations,
    resources.language,
    resources.settings,
    resources.signOut,
    colors.neutral500,
    isAdmin,
    disableSignUp,
  ]);

  return (
    <BoxWithRef
      ref={anchorRef}
      onClickCapture={onClick}
      aria-label="My Account"
      aria-roledescription="button"
      dangerouslySetInlineStyle={{
        __style: {
          cursor: "pointer",
        },
      }}
    >
      <Accordion
        TitleComponent={
          <Box marginBottom={open ? 4 : undefined}>
            <Box display="flex" direction="column">
              <Box
                display="flex"
                direction="row"
                alignItems="center"
                justifyContent="start"
                gap={5}
              >
                <Box width={40}>
                  <AvatarImage size={32} email={email} name={name} imageDataURI={imageDataURI} />
                </Box>
                <Box display="flex" direction="column">
                  <Text size="400">{name}</Text>
                  <Text size="200">{email}</Text>
                </Box>
              </Box>
            </Box>
          </Box>
        }
      >
        <Box>
          <Box display="flex" direction="column">
            <Divider />
            <Box padding={5}>
              <Box display="flex" gap={5} direction="column">
                {topMenuItems.map((item, index) => {
                  return item.type === "link" ? (
                    <OrganizationLink key={`${item.link}_${index}`} to={item.link}>
                      <Box
                        display="flex"
                        key={`${item.link}_${index}`}
                        direction="row"
                        alignItems="center"
                        gap={4}
                        justifyContent="start"
                      >
                        <Box width={"100%"}>
                          <Box
                            padding={2}
                            rounding={2}
                            datatype="menu-item-entry"
                            hoverColor={colors.primaryHover0}
                            onClickCapture={() => setShowMobileMenu(false)}
                          >
                            <Box
                              display="flex"
                              direction="row"
                              alignItems="center"
                              justifyContent="start"
                              gap={4}
                            >
                              <Box direction="column" marginEnd={4}>
                                {typeof item.icon === "string" ? (
                                  <Icon
                                    color={colors.neutral500}
                                    size="sm"
                                    icon={item.icon}
                                    accessibilityLabel={item.label}
                                  />
                                ) : (
                                  item.icon
                                )}
                              </Box>
                              <Box display="flex" direction="column">
                                <Text
                                  customClassName="text-bold-on-hover text-subtle-on-hover"
                                  color={colors.subtle}
                                >
                                  {item.linkText}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </OrganizationLink>
                  ) : item.type === "raw-link" ? (
                    <Link key={`${item.link}_${index}`} to={item.link}>
                      <Box
                        display="flex"
                        key={`${item.link}_${index}`}
                        direction="row"
                        alignItems="center"
                        gap={4}
                        justifyContent="start"
                      >
                        <Box width={"100%"}>
                          <Box
                            padding={2}
                            rounding={2}
                            datatype="menu-item-entry"
                            hoverColor={colors.primaryHover0}
                          >
                            <Box
                              display="flex"
                              direction="row"
                              alignItems="center"
                              justifyContent="start"
                            >
                              <Box direction="column" marginEnd={4}>
                                {typeof item.icon === "string" ? (
                                  <Icon
                                    color={colors.neutral500}
                                    size="sm"
                                    icon={item.icon}
                                    accessibilityLabel={item.label}
                                  />
                                ) : (
                                  item.icon
                                )}
                              </Box>
                              <Box display="flex" direction="column">
                                <Text
                                  customClassName="text-bold-on-hover text-subtle-on-hover"
                                  color={colors.subtle}
                                >
                                  {item.linkText}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Link>
                  ) : (
                    <Box
                      display="flex"
                      key={`${item.link}_${index}`}
                      direction="row"
                      alignItems="center"
                      gap={4}
                      justifyContent="start"
                    >
                      <Box width={"100%"}>{item.element}</Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Accordion>
    </BoxWithRef>
  );
}

type AvatarImageProps = {
  size?: 32 | 48 | 62;
  imageDataURI?: string;
  email: string;
  name: string;
};

export const AvatarImage: FunctionComponent<AvatarImageProps> = ({
  email,
  name,
  imageDataURI,
  size = 32,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const [withGravatar, setWithGravatar] = useState(true);

  return (
    <Mask rounding={"circle"} height={size} width={size}>
      {(withGravatar && email) || imageDataURI ? (
        <Image
          alt={resources.avatar}
          color="transparent"
          fit="cover"
          naturalHeight={size}
          naturalWidth={size}
          src={imageDataURI ? imageDataURI : defaultImageUrl(email)}
          onError={() => setWithGravatar(false)}
        />
      ) : (
        <Box
          color={colors.primary}
          height={"100%"}
          minWidth={size}
          maxWidth={size}
          direction="column"
          display="flex"
          justifyContent="center"
        >
          <Box
            margin={2}
            marginTop={3}
            display="flex"
            flex="grow"
            direction="row"
            justifyContent="center"
          >
            <Text size="200" weight="bold" inline={true} color={colors.secondary}>
              {name
                ?.split(" ")
                .filter((word) => word.trim())
                .map((word) => word.trim().charAt(0))
                .slice(0, 2)
                .join(" ")}
            </Text>
          </Box>
        </Box>
      )}
    </Mask>
  );
};

type PublicAvatarImageProps = {
  username: string;
  size?: 32 | 48 | 62;
};
export function PublicAvatarImage({ username, size = 48 }: PublicAvatarImageProps) {
  const { colors } = useColors();
  const { isLoading, data } = useUserPublicProfile(username);
  const { resources } = useAppTranslation();

  if (isLoading || !data) {
    return <Skeleton width={50} height={50} rounded={true} />;
  }
  if (!data.avatarImageDataURI) {
    return (
      <Mask rounding={"circle"} height={size} width={size}>
        <Box
          color={colors.primary}
          height={"100%"}
          minWidth={size}
          maxWidth={size}
          direction="column"
          display="flex"
          justifyContent="center"
        >
          <Box
            margin={2}
            marginTop={2}
            display="flex"
            flex="grow"
            direction="row"
            justifyContent="center"
          >
            <Text size="300" weight="bold" inline={true} color={colors.secondary}>
              {`${data.lastName ? `${data.firstName} ${data.lastName}` : data.firstName}`
                .split(" ")
                .filter((word) => word.trim())
                .map((word) => word.trim().charAt(0))
                .slice(0, 2)
                .join(" ")}
            </Text>
          </Box>
        </Box>
      </Mask>
    );
  }
  return (
    <Mask rounding={"circle"} height={size} width={size}>
      <Image
        alt={resources.avatar}
        color="transparent"
        fit="cover"
        naturalHeight={size}
        naturalWidth={size}
        src={data.avatarImageDataURI}
      />
    </Mask>
  );
}

export { AvatarProfile };
