import { Box, Divider, TapArea, Text, useColors } from "@prodoctivity/design-system";
import type { DashboardElementType } from "@prodoctivity/types";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { ClosePanelXIcon } from "../../svg/ClosePanelXIcon";
import { DashboardElementImplementation } from "./hooks";

type Props = {
  rowIndex: number | null;
  colIndex: number | null;
  addToSidebar: boolean;
  listOfWidgets: DashboardElementImplementation[];
  isListOfWidgetsOpen: boolean;
  setOpenListOfWidgets: Dispatch<
    SetStateAction<{
      isListOfWidgetsOpen: boolean;
      rowIndex: number | null;
      colIndex: number | null;
      addToSidebar: boolean;
    }>
  >;
  handleAddColumnItem: (
    elementType: DashboardElementType,
    rowIndex: number | null,
    colIndex: number | null
  ) => void;
  handleAddSidebarItem: (elementType: DashboardElementType) => void;
};

export const ListOfWidgetsPanel: FunctionComponent<Props> = ({
  // isListOfWidgetsOpen,
  setOpenListOfWidgets,
  rowIndex,
  colIndex,
  addToSidebar,
  listOfWidgets,
  handleAddColumnItem,
  handleAddSidebarItem,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  return (
    <Box display="flex" direction="column" maxWidth={350} paddingTop={1} width={"100%"}>
      <Box
        width={"100%"}
        minWidth={"fit-content"}
        height={"100%"}
        display="flex"
        color={colors.white}
      >
        <Divider size={1} direction="vertical"></Divider>
        <Box display="flex" direction="column" width={"100%"} height={"100%"} overflow="hidden">
          <Box
            width={"100%"}
            display="flex"
            justifyContent="between"
            alignItems="center"
            paddingLeft={6}
            paddingRight={3}
            paddingY={3}
          >
            <Text weight="bold">{resources.dashboard.listOfWidgets}</Text>

            <Box display="flex">
              <Box display="flex" gap={5} direction="row">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={40}
                  height={40}
                >
                  <TapArea
                    onTap={() => {
                      setOpenListOfWidgets({
                        isListOfWidgetsOpen: false,
                        rowIndex: null,
                        colIndex: null,
                        addToSidebar: false,
                      });
                    }}
                  >
                    <ClosePanelXIcon />
                  </TapArea>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Divider color={colors.primary} size={2} direction="horizontal" />
          </Box>

          <Box
            display="flex"
            direction="column"
            justifyContent="start"
            overflow="scrollY"
            height={"100%"}
            marginEnd={-12}
            paddingRight={12}
          >
            {listOfWidgets.map((widget, widgetIndex) => {
              return (
                <Box key={widgetIndex} width={"100%"} height={"100%"} maxHeight={124}>
                  <Box
                    display="flex"
                    direction="column"
                    justifyContent="between"
                    width={"100%"}
                    height={124}
                    overflow="hidden"
                  >
                    <Box
                      display="flex"
                      width={"100%"}
                      height={"100%"}
                      gap={3}
                      paddingLeft={6}
                      paddingRight={2}
                      paddingY={4}
                    >
                      <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="start"
                        position="relative"
                        minWidth={80}
                        minHeight={50}
                        direction="column"
                        gap={2}
                      >
                        <Box minWidth={80} minHeight={50} overflow="hidden">
                          <Box
                            width={"100%"}
                            height={"100%"}
                            maxWidth={80}
                            maxHeight={50}
                            overflow="hidden"
                            borderRadius={4}
                          >
                            <img
                              id={`thumbnail-image-for-${widget.contextDefinition.fields[0].humanName}`}
                              key={widget.contextDefinition.fields[0].fullPath}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                objectPosition: "top",
                              }}
                              src={widget.thumbnail}
                              alt={widget.contextDefinition.fields[0].humanName}
                            />
                          </Box>
                        </Box>
                        <Box
                          width={80}
                          height={30}
                          color={colors.primary}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius={4}
                        >
                          <TapArea
                            onTap={() => {
                              if (addToSidebar) {
                                handleAddSidebarItem(widget.elementType);
                              } else {
                                handleAddColumnItem(widget.elementType, rowIndex, colIndex);
                              }
                              setOpenListOfWidgets({
                                isListOfWidgetsOpen: false,
                                rowIndex: null,
                                colIndex: null,
                                addToSidebar: false,
                              });
                            }}
                          >
                            <Box width={"100%"} height={"100%"} paddingX={4} paddingY={1}>
                              <Text size="200" align="center" color={colors.white}>
                                {resources.add}
                              </Text>
                            </Box>
                          </TapArea>
                        </Box>
                      </Box>

                      <Box width={"100%"} display="flex" direction="column" gap={1} maxWidth={210}>
                        <Box display="flex" alignItems="center" gap={2}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width={32}
                            height={32}
                          >
                            {widget.icon}
                          </Box>
                          <Text
                            weight="bold"
                            size="200"
                            overflow="ellipsis"
                            title={widget.contextDefinition.fields[0].humanName}
                          >
                            {widget.contextDefinition.fields[0].humanName}
                          </Text>
                        </Box>

                        <Text weight="normal" size="200" color={colors.neutral900}>
                          {widget.contextDefinition.fields[0].properties.description}
                        </Text>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    dangerouslySetInlineStyle={{
                      __style: {
                        backgroundImage: `linear-gradient(to right, #DFE0E1 10%, rgba(255, 255, 255, 0) 70%)`,
                        backgroundPosition: `bottom`,
                        backgroundSize: `20px 5px`,
                        backgroundRepeat: `repeat-x`,
                        border: `none`,
                      },
                    }}
                    height={1}
                  ></Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
