import { isCalculatedField, shouldNever } from "@prodoctivity/shared";
import type {
  ContextField,
  ContextFieldProperties,
  DataType,
} from "@prodoctivity/shared/src/index-types";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  DocumentSearchFilter,
  SearchFieldOperator,
  organizationLinkTemplates,
} from "../../link-templates";

import { useDesignBreakpoint } from "@prodoctivity/design-system";
import type { HttpSearchSuggestionsResponse } from "@prodoctivity/types";
import { useLocation } from "react-router-dom";
import { useDebounceCallback } from "usehooks-ts";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { useAllDocumentTypes } from "../hooks";

export type OpenSearchOption = {
  icon: JSX.Element;
  title: string;
  id:
    | "all"
    | "collection"
    | "document"
    | "template"
    | "task"
    | "action-log"
    | "annotation"
    | "Settings"
    | "advancedSearch";
};

export interface SupportedDataTypes {
  label: string;
  value: SearchFieldOperator;
  dataTypeArray: DataType[];
}

export interface FilterOption {
  label: string;
  subtext?: string;
  value: string;
}

export interface context extends FieldCondition {
  fieldName: string;
  humanName: string;
  fullPath: string;
  properties: ContextFieldProperties;
}

export type FieldCondition = {
  id: string;
  field: ContextField;
  equalityOperator: SearchFieldOperator;
  value?: string | number | Date;
};

type OpenSearchState = {
  idSelected: OpenSearchOption["id"];
  isDocumentSearchOpen: boolean;
  isOpen: boolean;
  isSuggestionsOpen: boolean;
  queryValue: string;
};

export type OpenSearchMsg =
  | {
      type: "open-popup";
    }
  | {
      type: "close-popup";
    }
  | { type: "close-suggestions" }
  | {
      type: "toggle-document-search";
    }
  | {
      type: "toggle-advanced-search";
    }
  | {
      type: "toggle-collections-search";
    }
  | {
      type: "toggle-templates-search";
    }
  | {
      type: "set-selected-search";
      value: "document" | "collection";
    }
  | {
      id: string;
      type: "close-document-search";
    }
  | {
      type: "set-query-value";
      value: string;
    }
  | {
      type: "clear-query-value";
      value: string;
    };

function openSearchReducer(state: OpenSearchState, msg: OpenSearchMsg): OpenSearchState {
  switch (msg.type) {
    case "open-popup": {
      return {
        ...state,
        isOpen: true,
        isSuggestionsOpen: true,
        // idSelected: "document",
        // isDocumentSearchOpen: false,
      };
    }
    case "close-popup": {
      return {
        ...state,
        isOpen: false,
        isDocumentSearchOpen: false,
        isSuggestionsOpen: false,
      };
    }
    case "close-suggestions": {
      return {
        ...state,
        isSuggestionsOpen: false,
      };
    }
    case "toggle-document-search": {
      return {
        ...state,
        idSelected: "document",
        // isDocumentSearchOpen: true,
        isSuggestionsOpen: false,
      };
    }
    case "toggle-advanced-search": {
      return {
        ...state,
        idSelected: "document",
        isDocumentSearchOpen: true,
        isSuggestionsOpen: false,
      };
    }
    case "toggle-collections-search": {
      return {
        ...state,
        idSelected: "collection",
        isDocumentSearchOpen: false,
        isSuggestionsOpen: false,
      };
    }
    case "toggle-templates-search": {
      return {
        ...state,
        idSelected: "template",
        isDocumentSearchOpen: false,
        isSuggestionsOpen: false,
      };
    }
    case "set-selected-search": {
      return {
        ...state,
        idSelected: msg.value,
      };
    }
    case "close-document-search": {
      return { ...state, isDocumentSearchOpen: false };
    }
    case "set-query-value": {
      return { ...state, isOpen: true, queryValue: msg.value, isSuggestionsOpen: true };
    }
    case "clear-query-value": {
      return { ...state, isOpen: false, queryValue: msg.value };
    }
    default:
      shouldNever(msg);
      throw new Error("Cannot happen");
  }
}

function openSearchInitialState(): OpenSearchState {
  return {
    idSelected: window.location.pathname.includes("/document-collections")
      ? "collection"
      : "document",
    isOpen: false,
    isSuggestionsOpen: false,
    isDocumentSearchOpen: false,
    queryValue: "",
  };
}

export function useOpenSearch() {
  const [state, dispatch] = useReducer(openSearchReducer, undefined, openSearchInitialState);
  const { isOpen, isSuggestionsOpen, isDocumentSearchOpen, queryValue, idSelected } = state;
  const { breakpoint } = useDesignBreakpoint();
  const { resources } = useAppTranslation();
  const { searchSuggestions } = useServices();
  const [documentTypesSelected, setDocumentTypesSelected] = useState<Array<FilterOption>>([]);
  const [filterByFields, setFilters] = useState<FieldCondition[]>([]);
  const newSetFilters = useCallback((value: FieldCondition[]) => {
    setFilters(value);
  }, []);
  const textSearchNodeRef = useRef<HTMLInputElement | null>(null);
  const searchBarNodeRef = useRef<HTMLDivElement | null>(null);
  const { pathname, search } = useLocation();

  useLayoutEffect(() => {
    if (breakpoint === "small") {
      dispatch({
        type: "close-popup",
      });
    }
  }, [breakpoint]);

  useLayoutEffect(() => {
    if (textSearchNodeRef.current) {
      textSearchNodeRef.current.ariaAutoComplete = "off";
      textSearchNodeRef.current.autocomplete = "off";
      textSearchNodeRef.current.dataset["lpignore"] = "true";
      textSearchNodeRef.current.setAttribute("data-1p-ignore", "true");
      //textSearchNodeRef.current.dataset["1p-ignore"] = "true";
    }
  }, []);

  useEffect(() => {
    if (pathname.includes("/document-collections")) {
      dispatch({
        type: "set-selected-search",
        value: "collection",
      });
    } else {
      dispatch({
        type: "set-selected-search",
        value: "document",
      });
    }
  }, [pathname]);

  const { getDocumentCollectionInstanceList, fetchTemplateList } = useServices();
  const fetchDocumentCollectionInstanceList = useCallback(() => {
    return getDocumentCollectionInstanceList(0, "15", queryValue, undefined);
  }, [getDocumentCollectionInstanceList, queryValue]);

  const fetchTemplates = useCallback(() => {
    return fetchTemplateList("all", false, 0, "15", queryValue, undefined);
  }, [fetchTemplateList, queryValue]);

  const { data: documentCollectionList, isLoading: isLoadingDocumentCollections } =
    useOrganizationQuery(
      `document-collection-instance/page_${0}_${"15"}_${queryValue}`,
      fetchDocumentCollectionInstanceList,
      {
        enabled: idSelected === "collection",
        staleTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const { data: templateList, isLoading: isLoadingTemplates } = useOrganizationQuery(
    `template_list_suggestions/page_${0}_${"15"}_${queryValue}`,
    fetchTemplates,
    {
      enabled: idSelected === "template",
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const getQueryValue = useCallback(() => queryValue, [queryValue]);

  const debouncedQueryValueGetter = useDebounceCallback(getQueryValue, 500, {
    leading: true,
  });

  const { allDocumentTypes, isLoading: isLoadingDocTypes } = useAllDocumentTypes();
  const { getDocumentTypesWithCommonFields } = useServices();

  const closeSuggestions = useCallback(() => {
    dispatch({
      type: "close-suggestions",
    });
  }, []);

  const fields = useMemo(() => {
    return filterByFields.map((i) => ({
      name: i.field.name,
      dataType: i.field.properties.dataType,
    }));
  }, [filterByFields]);

  const clearFilter = useCallback(() => {
    dispatch({
      type: "set-query-value",
      value: "",
    });
    setTimeout(() => {
      if (textSearchNodeRef.current) {
        textSearchNodeRef.current.focus();
      }
    }, 50);
  }, [textSearchNodeRef]);

  const withCommonFieldsInfo = useCallback(async () => {
    if (fields && fields.length > 0) {
      return getDocumentTypesWithCommonFields(fields);
    }
    return Promise.resolve({
      documentTypesVersionId: [],
    });
  }, [fields, getDocumentTypesWithCommonFields]);

  const { data: documentTypesWithCommonFields } = useOrganizationQuery(
    `/document-types/with-common-fields/${filterByFields.map(fieldConditionToString).join("|")}`,
    withCommonFieldsInfo,
    {
      staleTime: 2 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  const memoizedParams = useMemo(() => {
    const params = new URLSearchParams(search);
    return params;
  }, [search]);

  const urlState = useMemo(() => {
    if (pathname.includes("/search")) {
      const query = memoizedParams.get("q");
      const result = {
        query: query !== null ? query : null,
      };
      return result;
    }
    return undefined;
  }, [memoizedParams, pathname]);

  const documentTypesVersionIdList = useMemo(() => {
    return (allDocumentTypes?.documentTypes || [])
      .filter((dt) => {
        if (documentTypesSelected.length === 0) {
          return true;
        }
        if (
          !documentTypesWithCommonFields ||
          documentTypesWithCommonFields.documentTypesVersionId.length <= 0
        ) {
          return true;
        }

        return documentTypesWithCommonFields.documentTypesVersionId.some(
          (commonDt) => commonDt === dt.documentTypeVersionId
        );
      })
      .map((dt) => {
        return {
          label: dt.name,
          value: dt.documentTypeId ?? "",
          subtext: undefined,
        };
      });
  }, [allDocumentTypes, documentTypesSelected.length, documentTypesWithCommonFields]);

  const handleExecuteSearchQuery: () => Promise<HttpSearchSuggestionsResponse["payload"]> =
    useCallback(async () => {
      if (!debouncedQueryValueGetter()) {
        return {
          pageLength: 0,
          pageNumber: 0,
          requestedPageLength: 0,
          results: [],
          totalRowCount: undefined,
        };
      }
      const status = await searchSuggestions([], debouncedQueryValueGetter(), []);

      return status;
    }, [searchSuggestions, debouncedQueryValueGetter]);

  const { data: searchResults, isLoading: isLoadingSearch } = useOrganizationQuery(
    `/search-suggestions/q=${encodeURIComponent(debouncedQueryValueGetter() || "")}`,
    handleExecuteSearchQuery,
    {
      enabled: idSelected === "document",
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
  const onQueryInputChanged = useCallback(async (args: { value: string }) => {
    if (args.value.length <= 500) {
      dispatch({
        type: "set-query-value",
        value: args.value,
      });
    }
  }, []);

  const organizationNavigate = useOrganizationNavigate();

  const buildSearchUrl = useCallback(
    (filter?: DocumentSearchFilter, index?: number, specificFilter?: string) => {
      if (idSelected === "collection") {
        dispatch({
          type: "close-popup",
        });

        return organizationNavigate(
          organizationLinkTemplates.documentCollectionList({
            pageNumber: 0,
            rowsPerPage: "15",
            filter: queryValue,
            assignedTo: "",
          }),
          {
            replace: false,
          }
        );
      }
      if (idSelected === "template") {
        dispatch({
          type: "close-popup",
        });

        return organizationNavigate(organizationLinkTemplates.templateList(false, queryValue), {
          replace: false,
        });
      }
      const queryParams: Array<string> = [];

      if (queryValue) {
        queryParams.push(`q=${encodeURIComponent(queryValue)}`);
      } else if (filter?.query && specificFilter !== "query") {
        queryParams.push(`q=${encodeURIComponent(filter.query)}`);
      }

      if (idSelected && idSelected !== "all") {
        queryParams.push(`entityType=${encodeURIComponent(idSelected)}`);
      }

      if (documentTypesSelected.length > 0) {
        documentTypesSelected.forEach((documentTypeId, index) => {
          queryParams.push(
            `documentTypeIdList[${index}]=${encodeURIComponent(documentTypeId.value)}`
          );
        });
      } else if (filter) {
        filter.documentTypesSelected.forEach((documentTypeId, index) => {
          queryParams.push(`documentTypeIdList[${index}]=${encodeURIComponent(documentTypeId)}`);
        });
      }

      const queryParamsUrl = queryParams.join("&");
      const ObjFieldQueryParams = {
        fieldQueryParams: "",
      };
      if (idSelected && idSelected === "document" && !filter) {
        ObjFieldQueryParams.fieldQueryParams = filterByFields
          .filter((f) => f.value !== "" && f.value !== undefined)
          .map((item, index) => {
            if (item.value === undefined) return null;

            let value = item.value.toString();
            const dataType = item.field.properties.dataType;
            const equalityOperator = item.equalityOperator;

            if (
              dataType === "Alphanumeric" &&
              (equalityOperator === "starts-with" || equalityOperator === "ends-with")
            ) {
              value = value.replace(/\*/g, "");
            }

            return `${index === 0 ? "&" : ""}fields[${index}][fld]=${encodeURIComponent(
              item.field.name
            )}&fields[${index}][val]=${value}&fields[${index}][op]=${encodeURIComponent(
              equalityOperator
            )}&fields[${index}][dataType]=${dataType}`;
          })
          .join("&");
      } else if (filter) {
        if (filter.fields.length > 0) {
          ObjFieldQueryParams.fieldQueryParams = filter.fields
            .filter((_f, indexSecondary) => indexSecondary !== index)
            .map((item, index) => {
              if (item.val === undefined) return null;

              let value = item.val.toString();
              const dataType = item.dataType;
              const equalityOperator = item.op;

              if (
                dataType === "Alphanumeric" &&
                (equalityOperator === "starts-with" || equalityOperator === "ends-with")
              ) {
                value = value.replace(/\*/g, "");
              }

              return `${index === 0 ? "&" : ""}fields[${index}][fld]=${encodeURIComponent(
                item.fld
              )}&fields[${index}][val]=${value}&fields[${index}][op]=${encodeURIComponent(
                equalityOperator
              )}&fields[${index}][dataType]=${dataType}`;
            })
            .join("&");
        } else {
          ObjFieldQueryParams.fieldQueryParams = "";
        }
      }
      const totalFieldsToFilterBy = () => {
        if (filterByFields.length > 0) {
          const totalFilters = filterByFields.filter(
            (f) => f.value !== "" && f.value !== undefined
          ).length;
          return totalFilters;
        } else if (filter) {
          const totalFilters = filter.fields.filter(
            (_f, indexSecondary) => indexSecondary !== index
          ).length;
          return totalFilters;
        } else {
          return 0;
        }
      };
      const documentTypesSelectedTotal = () => {
        if (documentTypesSelected.length > 0) {
          const docTypeTotal = documentTypesSelected.length;
          return docTypeTotal;
        } else if (filter && filter.documentTypesSelected.length > 0) {
          const docTypeTotal = filter.documentTypesSelected.length;
          return docTypeTotal;
        } else {
          return 0;
        }
      };

      const queryPageNumber = filter ? filter.pageNumber : "0";
      const queryRowsPerPage = filter ? filter.rowsPerPage : "15";
      const paginationQuery = `&pageNumber=${queryPageNumber}&rowsPerPage=${queryRowsPerPage}`;

      console.log(`/search?${queryParamsUrl + "&" + ObjFieldQueryParams.fieldQueryParams}`);

      dispatch({
        type: "close-popup",
      });

      const builtQuery = `/search?${
        queryParamsUrl +
        "&" +
        "docTypesSelected=" +
        documentTypesSelectedTotal() +
        ObjFieldQueryParams.fieldQueryParams +
        "&" +
        "searchCriteria=" +
        totalFieldsToFilterBy() +
        paginationQuery
      }`;

      organizationNavigate(builtQuery, {
        replace: false,
      });

      return builtQuery;
    },
    [documentTypesSelected, filterByFields, idSelected, organizationNavigate, queryValue]
  );
  const onKeyPress = useCallback(
    ({ event: { code } }: { event: { code: string } }) => {
      if ((code === "Enter" && queryValue) || (code === "NumpadEnter" && queryValue)) {
        buildSearchUrl();
      }
    },
    [buildSearchUrl, queryValue]
  );

  const componentId = "open";

  const openSearchPopup = useCallback(() => {
    dispatch({
      type: "open-popup",
    });
  }, []);

  const toggleSearchPopup = useCallback(() => {
    dispatch({
      type: isOpen ? "close-popup" : "open-popup",
    });
  }, [isOpen]);

  const handleOpenSearchOptionClick = (id: OpenSearchOption["id"]): void => {
    switch (id) {
      case "advancedSearch": {
        dispatch({
          type: "toggle-advanced-search",
        });
        break;
      }
      case "document": {
        dispatch({
          type: "toggle-document-search",
        });
        break;
      }
      case "collection": {
        dispatch({
          type: "toggle-collections-search",
        });
        break;
      }
      default: {
        dispatch({
          id,
          type: "close-document-search",
        });
        break;
      }
    }
  };

  const closePopup = useCallback(() => {
    dispatch({
      type: "close-popup",
    });
  }, []);

  return {
    isDocumentSearchOpen,
    breakpoint,
    documentTypesVersionIdList,
    onQueryInputChanged,
    componentId,
    isOpen,
    queryValue,
    resources,
    handleOpenSearchOptionClick,
    openSearchPopup,
    closePopup,
    toggleSearchPopup,
    idSelected,
    isLoadingSearch,
    isSuggestionsOpen,
    buildSearchUrl,
    setDocumentTypesSelected,
    documentTypesSelected,
    searchResults: isSuggestionsOpen ? (searchResults?.results || []).slice(0, 10) : [],
    documentCollectionList: isSuggestionsOpen
      ? (documentCollectionList?.documentCollections || []).slice(0, 10)
      : [],
    isLoadingDocumentCollections,
    templateList: isSuggestionsOpen ? (templateList?.templates || []).slice(0, 10) : [],
    isLoadingTemplates,
    closeSuggestions,
    newSetFilters,
    filterByFields,
    onKeyPress,
    isLoadingDocTypes,
    clearFilter,
    textSearchNodeRef,
    searchBarNodeRef,
    urlState,
    dispatch,
  };
}

export type FormOpenSearchProps = {
  documentTypesSelected: Array<FilterOption>;
  newSetFilters: (value: FieldCondition[]) => void;
  filterByFields: FieldCondition[];
};

export const useDocFormOpenSearch = ({
  documentTypesSelected,
  newSetFilters,
  filterByFields,
}: FormOpenSearchProps) => {
  const [isBoxOpenDocument, setBoxOpenDocument] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const { intersectionContextFields } = useServices();

  const resetSelectedValues = useCallback(() => {
    const resetFiltersField: FieldCondition[] = filterByFields.map((fieldValueReset) => {
      return {
        ...fieldValueReset,
        value: "",
        equalityOperator: "eq",
      };
    });
    newSetFilters(resetFiltersField);
  }, [filterByFields, newSetFilters]);

  const documentTypesID = documentTypesSelected.map((items) => items.value);

  const handleFetchFieldsInfo = useCallback(async () => {
    if (documentTypesSelected && documentTypesSelected.length > 0) {
      if (documentTypesID.length > 0) {
        const resp = await intersectionContextFields(documentTypesID);

        const fields: FieldCondition[] = resp.contextDefinition.fields
          .filter(
            (evaluatedField) =>
              !isCalculatedField(evaluatedField.properties) &&
              evaluatedField.properties.dataType !== "Image"
          )
          .map((f) => {
            return {
              id: f.humanName,
              field: f,
              equalityOperator: "eq",
            };
          });
        newSetFilters(fields);
        return resp;
      }
    }
    return {
      documentTypesVersionId: [],
      contextDefinition: {
        fields: [],
        records: [],
      },
    };
  }, [documentTypesSelected, documentTypesID, intersectionContextFields, newSetFilters]);

  const {
    data: intersectionContext,
    isLoading,
    isError,
  } = useOrganizationQuery(
    `/document-types/fetch-fields-info/${documentTypesID.join("-")}`,
    handleFetchFieldsInfo,
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const onFilterChange = useCallback(
    (
      id: string,
      name: "value" | "equalityOperator",
      value?: string | number | Date | null,
      dataType?:
        | "Alphanumeric"
        | "Numeric"
        | "Currency"
        | "Logical"
        | "Date"
        | "DateTime"
        | "Time"
        | "Image"
    ) => {
      const parseFieldValue = () => {
        let newValue = value;
        if (dataType === "Date" || dataType === "DateTime") {
          const convertToUnixTime = (dateStr: string | number | Date) => {
            const date = new Date(dateStr);
            return date.getTime();
          };

          const dateStr = value && value !== null ? value : new Date();
          newValue = convertToUnixTime(dateStr);
          return newValue;
        }
        return newValue;
      };

      const valueDesired = (): FieldCondition[] => {
        const fieldIndex = filterByFields.findIndex((f) => f.id === id);
        const newFilters: any = [...filterByFields];

        if (fieldIndex < 0) {
          return filterByFields;
        }
        newFilters[fieldIndex][name] = parseFieldValue();
        return newFilters;
      };
      newSetFilters(valueDesired());
    },
    [filterByFields, newSetFilters]
  );

  const selectedDocumentName = (intersectionContext?.contextDefinition.fields || []).map(
    (items) => ({
      label: items.properties.label,
      value: items.name,
      subtext: undefined,
    })
  );
  /*
  const handleComboBoxSelect = useCallback((event: { item: { label: string } }) => {
      setFieldName(event.item.label);
      setBoxOpenDocument(false);

      const field = (intersectionContext?.contextDefinition.fields || []).find(
        (f) => f.properties.label === event.item.label
      );

      if (!field) {
        return;
      }

      const addNewQueryRow: FieldCondition = {
        id: createUuid(),
        field: field,
        condition: "equals",
        value: undefined,
        operator: undefined,
      };

      const existingIndex = filterByFields.findIndex(
        (item) => item.field.name === addNewQueryRow.field.name
      );

      if (existingIndex !== -1) {
        return;
      }

      setFilters([...filterByFields, addNewQueryRow]);

  }, []);*/

  const removeQueryRow = (idOfTheQueryRow: string) => {
    newSetFilters(filterByFields.filter((items) => items.id !== idOfTheQueryRow));
  };

  return {
    intersectionContext,
    isLoading,
    selectedDocumentName,
    isBoxOpenDocument,
    onFilterChange,
    setBoxOpenDocument,
    removeQueryRow,
    resetSelectedValues,
    isError,
    toggleShowMore,
    showMore,
  };
};
function fieldConditionToString(value: FieldCondition): unknown {
  return `f=${value.field.fullPath}&op=${value.equalityOperator}&v=${value.value}`;
}
