import {
  Box,
  Divider,
  DynamicIconButton,
  PopoverMenu,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { buildEventEmitter } from "@prodoctivity/shared";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";
import type { DashboardElementConfig } from "@prodoctivity/types";
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useMemo } from "react";
import { ClosePanelXIcon } from "../../svg/ClosePanelXIcon";
import { DashboardItemContext, DashboardItemEvents } from "./DashboardConfigColumnElement";
import { DashboardElementImplementation } from "./hooks";

type Props = {
  allowEditionMode: number | boolean;
  handleOpenListOfWidgets: ({
    rowIndex,
    colIndex,
    addToSidebar,
  }: {
    rowIndex: number | null;
    colIndex: number | null;
    addToSidebar: boolean;
  }) => void;
  editionMode: boolean;
  setOfSidebarsPerConfig: DashboardElementImplementation[];
  setSidebarToShow: Dispatch<SetStateAction<number | null>>;
  sidebarToShow: number | null;
  sideBarsArray: DashboardElementConfig[];
  sidebarContentFunctionsBundle: ((parametersObject?: any) => void)[];
  sidebarContentEllipsisOptions: string[];
};

export const DashboardConfigSidebarList: FunctionComponent<Props> = ({
  sideBarsArray,
  sidebarToShow,
  allowEditionMode,
  handleOpenListOfWidgets,
  editionMode,
  setOfSidebarsPerConfig,
  setSidebarToShow,
  sidebarContentFunctionsBundle,
  sidebarContentEllipsisOptions,
}) => {
  const { colors } = useColors();

  const dashboardItemEventEmitter = buildEventEmitter<
    DashboardItemEvents,
    keyof DashboardItemEvents
  >();

  const itemContextValue = useMemo(() => {
    return {
      isSidebarComponent: true,
      eventEmitter: dashboardItemEventEmitter,
    };
  }, [dashboardItemEventEmitter]);

  const refresh = useCallback(() => {
    dashboardItemEventEmitter.emit("refresh", {});
  }, [dashboardItemEventEmitter]);

  return (
    <>
      {sideBarsArray.length === 1 &&
      sideBarsArray[0].elementType === "placeholder" &&
      !editionMode ? null : (
        <>
          {sidebarToShow === null
            ? sideBarsArray.map((_sidebarItem, index) => {
                return (
                  <Box key={index}>
                    <Box height="100%" display="flex" direction="column" color={colors.neutral100}>
                      <Box
                        direction="column"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flex="shrink"
                        color={colors.white}
                        marginTop={4}
                        height={54}
                        dangerouslySetInlineStyle={{
                          __style: {
                            borderTopRightRadius: "32px",
                            borderBottomRightRadius: "32px",
                            boxShadow:
                              "0px 2px 4px rgba(0, 0, 0, 0.10), 0px 1px 10px rgba(0, 0, 0, 0.04), 0px 4px 5px rgba(0, 0, 0, 0.02)",
                          },
                        }}
                      >
                        <Box
                          paddingX={4}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          direction="column"
                          width={60}
                        >
                          <Box height={40}>
                            <TapArea onTap={() => setSidebarToShow(index)}>
                              <Box
                                paddingX={4}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                direction="column"
                                width={60}
                              >
                                {setOfSidebarsPerConfig[index].icon}
                              </Box>
                            </TapArea>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })
            : null}
          {sidebarToShow !== null ? (
            <DashboardItemContext.Provider value={itemContextValue}>
              <SidebarElement
                element={setOfSidebarsPerConfig[sidebarToShow]}
                allowEditionMode={allowEditionMode}
                clearSidebar={() => setSidebarToShow(null)}
                sidebarContentFunctionsBundle={sidebarContentFunctionsBundle}
                sidebarContentEllipsisOptions={sidebarContentEllipsisOptions}
                sidebarToShow={sidebarToShow}
                handleOpenListOfWidgets={handleOpenListOfWidgets}
                refresh={refresh}
              />
            </DashboardItemContext.Provider>
          ) : null}
        </>
      )}
    </>
  );
};

const SidebarElement: FunctionComponent<{
  element: DashboardElementImplementation;
  allowEditionMode: number | boolean;
  clearSidebar(): void;
  sidebarContentFunctionsBundle: Array<(parameters?: ParametersObject) => void>;
  sidebarContentEllipsisOptions: string[];
  sidebarToShow: number | null;
  refresh(): void;

  handleOpenListOfWidgets: ({
    rowIndex,
    colIndex,
    addToSidebar,
  }: {
    rowIndex: number | null;
    colIndex: number | null;
    addToSidebar: boolean;
  }) => void;
}> = ({
  element,
  allowEditionMode,
  clearSidebar,
  refresh,
  sidebarContentFunctionsBundle,
  sidebarContentEllipsisOptions,
  handleOpenListOfWidgets,
  sidebarToShow,
}) => {
  const { colors } = useColors();
  const { Component } = element;

  const componentClick = useCallback(() => {
    return () => {
      if (element.elementType !== "placeholder") return;
      handleOpenListOfWidgets({
        rowIndex: null,
        colIndex: null,
        addToSidebar: true,
      });
    };
  }, [handleOpenListOfWidgets, element]);

  return (
    <Box
      height="100%"
      overflow="hidden"
      display="flex"
      direction="column"
      color={colors.white}
      minWidth={320}
      maxWidth={350}
      paddingTop={1}
    >
      {
        <Box width={"100%"} display="flex" flex="grow">
          <Box display="flex" direction="column" flex="grow" height={"100%"}>
            <Box display="flex" direction="column" width={"100%"} flex="shrink">
              <Box
                paddingLeft={6}
                paddingRight={3}
                display="flex"
                justifyContent="between"
                minHeight={76}
                alignItems="center"
                width={"100%"}
              >
                <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
                  {element.allowsRefresh ? (
                    <TapArea onTap={refresh}>{element.icon}</TapArea>
                  ) : (
                    element.icon
                  )}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={"fit-content"}
                    maxWidth={164}
                  >
                    <Text
                      overflow="ellipsis"
                      weight="bold"
                      size="300"
                      title={element.contextDefinition.fields[0].humanName}
                    >
                      {element.contextDefinition.fields[0].humanName}
                    </Text>
                  </Box>
                </Box>

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={"fit-content"}
                >
                  {!allowEditionMode ? (
                    <Box height={40} width={40}>
                      <TapArea onTap={clearSidebar}>
                        <Box display="flex" justifyContent="center" alignItems="center" width={40}>
                          <ClosePanelXIcon height={40} width={40} />
                        </Box>
                      </TapArea>
                    </Box>
                  ) : (
                    <Box display="flex">
                      <Box
                        width={"fit-content"}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        paddingRight={element.elementType === "placeholder" ? 1 : undefined}
                      >
                        <DynamicIconButton
                          buttonName={"collapseSidebar"}
                          handleFunction={clearSidebar}
                          statefulCondition={undefined}
                          selected={undefined}
                        ></DynamicIconButton>
                      </Box>

                      {element.elementType === "placeholder" ? null : (
                        <PopoverMenu
                          handleFunctionsBundle={sidebarContentFunctionsBundle}
                          menuLabels={sidebarContentEllipsisOptions}
                          collapsibleMenuIcon="verticalEllipsis"
                          menuHasIcons={false}
                          srcAreImages={undefined}
                          bundleNeedsParameters={true}
                          parametersSet={{
                            sidebarItemIndex: sidebarToShow,
                            rowIndex: null,
                            colIndex: null,
                            addToSidebar: true,
                          }}
                          parameterizedFunctionIndex={[0, 1, 2, 3, 4]}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box minHeight={2} width={"100%"} display="flex" direction="column" flex="shrink">
              <Divider color={colors.primary} size={2} direction="horizontal"></Divider>
            </Box>

            <Box
              display="flex"
              direction="column"
              flex="grow"
              paddingTop={4}
              paddingLeft={4}
              paddingBottom={4}
              onClickCapture={componentClick}
            >
              <Component />
            </Box>
          </Box>
          <Divider size={1} direction="vertical"></Divider>
        </Box>
      }
    </Box>
  );
};
