import {
  MimeTypes,
  OrganizationUserLanguage$Schema,
  fixContextPayload,
  httpRequest as rawHttpRequest,
} from "@prodoctivity/shared";
import type {
  BundleConfig,
  DeleteOrganizationAccountType,
  DocumentCollectionConfig,
  DocumentCollectionInstance,
  FromServerWsMessage,
  GetOrganizationCollectionStatsResponse,
  GraphicMark,
  OrganizationInitBundles,
  OrganizationPermissionPart,
  RestoreDocumentRequest,
  SaveDocumentType,
  SearchQueryRequest,
  TemplateContextHeader,
} from "@prodoctivity/types";
import type { AppUserAccountContext, OrganizationId } from "@prodoctivity/types/core";
import type {
  HttpActivateForgotPasswordOTPRequest,
  HttpActivateForgotPasswordOTPResponse,
  HttpActivateJoinOrganizationRequest,
  HttpActivateJoinOrganizationResponse,
  HttpAddUserToOrganizationRequest,
  HttpAddUserToOrganizationResponse,
  HttpAdminChangePasswordRequest,
  HttpAdminChangePasswordResponse,
  HttpAllGetOrganizationGraphicMarkRequest,
  HttpAllGetOrganizationGraphicMarkResponse,
  HttpAppGetTemplateContextSampleRequest,
  HttpAppSyncDocumentGenerationRequest,
  HttpAppSyncDocumentGenerationResponse,
  HttpApplyBundleRequest,
  HttpApplyBundleResponse,
  HttpChangePasswordRequest,
  HttpChangePasswordResponse,
  HttpContactHelpRequest,
  HttpContactHelpResponse,
  HttpCreateDictionaryListRequest,
  HttpCreateDictionaryListResponse,
  HttpCreateDocumentCollectionConfigRequest,
  HttpCreateDocumentCollectionConfigResponse,
  HttpCreateDocumentGroupRequest,
  HttpCreateDocumentGroupResponse,
  HttpCreateOrUpdateCombinedTemplateRequest,
  HttpCreateOrUpdateCombinedTemplateRequestResponse,
  HttpCreateOrganizationGraphicMarkRequest,
  HttpCreateOrganizationGraphicMarkResponse,
  HttpCreateOrganizationRequest,
  HttpCreateOrganizationResponse,
  HttpCreateOrganizationRoleRequest,
  HttpCreateOrganizationRoleResponse,
  HttpCreateTemplateContextStateRequest,
  HttpCreateTemplateContextStateResponse,
  HttpDeleteAllDocumentsListsRequest,
  HttpDeleteAllDocumentsListsResponse,
  HttpDeleteCombinedTemplateRequest,
  HttpDeleteCombinedTemplateResponse,
  HttpDeleteDataElementRequest,
  HttpDeleteDataElementResponse,
  HttpDeleteDictionaryListRequest,
  HttpDeleteDictionaryListResponse,
  HttpDeleteDistributionRequest,
  HttpDeleteDistributionResponse,
  HttpDeleteDocumentCollectionConfigRequest,
  HttpDeleteDocumentCollectionConfigResponse,
  HttpDeleteDocumentRequest,
  HttpDeleteDocumentResponse,
  HttpDeleteMyAccountPetitionRequest,
  HttpDeleteMyAccountPetitionResponse,
  HttpDeleteMyUserProfileDocumentLabelResponse,
  HttpDeleteMyUserProfileDocumentListsRequest,
  HttpDeleteOrganizationGraphicMarkRequest,
  HttpDeleteOrganizationGraphicMarkResponse,
  HttpDeleteOrganizationPetitionRequest,
  HttpDeleteOrganizationPetitionResponse,
  HttpDeleteOrganizationRoleRequest,
  HttpDeleteOrganizationRoleResponse,
  HttpDeleteTemplateContextStateRequest,
  HttpDeleteTemplateContextStateResponse,
  HttpDisableDistributionRequest,
  HttpDisableDistributionResponse,
  HttpDocumentCollectionInstanceViewRequest,
  HttpDocumentCollectionInstanceViewResponse,
  HttpDocumentGenerationListRequest,
  HttpDocumentGenerationListResponse,
  HttpDocumentGenerationStatusRequest,
  HttpDocumentGenerationStatusResponse,
  HttpDocumentViewRequest,
  HttpDocumentViewResponse,
  HttpExecuteDataLinkRequest,
  HttpExecuteDataLinkResponse,
  HttpForgotPasswordRequest,
  HttpForgotPasswordResponse,
  HttpGetActionLogEntriesListRequest,
  HttpGetActionLogEntriesListResponse,
  HttpGetActionLogEntryRequest,
  HttpGetActionLogEntryResponse,
  HttpGetAllDocumentTypesRequest,
  HttpGetAllDocumentTypesResponse,
  HttpGetAllOrganizationRolesRequest,
  HttpGetAllOrganizationRolesResponse,
  HttpGetBundleConfigListRequest,
  HttpGetBundleConfigListResponse,
  HttpGetBundleConfigRequest,
  HttpGetBundleConfigResponse,
  HttpGetCollectionActionLogEntriesListRequest,
  HttpGetCollectionActionLogEntriesListResponse,
  HttpGetCollectionConfigAffectedEntitiesRequest,
  HttpGetCollectionConfigAffectedEntitiesResponse,
  HttpGetCombinedTemplateDefinitionRequest,
  HttpGetCombinedTemplateDefinitionResponse,
  HttpGetCombinedTemplateListRequest,
  HttpGetCombinedTemplateListResponse,
  HttpGetCombinedTemplateRequest,
  HttpGetCombinedTemplateResponse,
  HttpGetCurrentOrganizationRequest,
  HttpGetDataElementRequest,
  HttpGetDataElementResponse,
  HttpGetDataLinkConfigDetailsRequest,
  HttpGetDataLinkConfigDetailsResponse,
  HttpGetDeferredDistributionListRequest,
  HttpGetDeferredDistributionListResponse,
  HttpGetDeletedDocumentListRequest,
  HttpGetDeletedDocumentListResponse,
  HttpGetDictionaryListRequest,
  HttpGetDictionaryListResponse,
  HttpGetDistributionDetailsRequest,
  HttpGetDistributionImplementationsRequest,
  HttpGetDistributionImplementationsResponse,
  HttpGetDistributionResponse,
  HttpGetDistributionsRequest,
  HttpGetDistributionsResponse,
  HttpGetDocumentByGenerationTokenRequest,
  HttpGetDocumentByIdentifierRequest,
  HttpGetDocumentByIdentifierResponse,
  HttpGetDocumentCollectionConfigListRequest,
  HttpGetDocumentCollectionConfigListResponse,
  HttpGetDocumentCollectionConfigRequest,
  HttpGetDocumentCollectionConfigResponse,
  HttpGetDocumentCollectionFolderMergedContextRequest,
  HttpGetDocumentCollectionFolderMergedContextResponse,
  HttpGetDocumentCollectionInstanceDetailsRequest,
  HttpGetDocumentCollectionInstanceDetailsResponse,
  HttpGetDocumentCollectionInstanceListRequest,
  HttpGetDocumentCollectionInstanceListResponse,
  HttpGetDocumentCollectionInstanceRequest,
  HttpGetDocumentCollectionInstanceResponse,
  HttpGetDocumentCollectionInstanceViewRequest,
  HttpGetDocumentCollectionInstanceViewResponse,
  HttpGetDocumentGroupListRequest,
  HttpGetDocumentGroupListResponse,
  HttpGetDocumentGroupRequest,
  HttpGetDocumentGroupResponse,
  HttpGetDocumentHistoryRequest,
  HttpGetDocumentHistoryResponse,
  HttpGetDocumentLabelsDocumentsCountRequest,
  HttpGetDocumentLabelsDocumentsCountResponse,
  HttpGetDocumentLabelsRequest,
  HttpGetDocumentLabelsResponse,
  HttpGetDocumentRequest,
  HttpGetDocumentResponse,
  HttpGetDocumentSummaryInfoRequest,
  HttpGetDocumentSummaryInfoResponse,
  HttpGetDocumentTypeListRequest,
  HttpGetDocumentTypeListResponse,
  HttpGetDocumentTypeRequest,
  HttpGetDocumentTypeResponse,
  HttpGetDocumentTypeVersionRequest,
  HttpGetDocumentTypeVersionResponse,
  HttpGetDocumentTypesIntersectionContextRequest,
  HttpGetDocumentTypesIntersectionContextResponse,
  HttpGetDocumentTypesMergedContextRequest,
  HttpGetDocumentTypesMergedContextResponse,
  HttpGetDocumentTypesWithCommonFieldsRequest,
  HttpGetDocumentTypesWithCommonFieldsResponse,
  HttpGetDocumentVersionGenerationContextRequest,
  HttpGetDocumentVersionGenerationContextResponse,
  HttpGetDocumentVersionsRequest,
  HttpGetDocumentVersionsResponse,
  HttpGetDocumentsByGenerationTokenRequest,
  HttpGetDocumentsByGenerationTokenResponse,
  HttpGetDocumentsRequest,
  HttpGetDocumentsResponse,
  HttpGetDocumentsWithListsRequest,
  HttpGetDocumentsWithListsResponse,
  HttpGetGenerationStatusDocumentRequest,
  HttpGetGenerationStatusDocumentResponse,
  HttpGetHistoryActionLogEntriesListRequest,
  HttpGetHistoryActionLogEntriesListResponse,
  HttpGetInputMasksRequest,
  HttpGetInputMasksResponse,
  HttpGetLastDocumentRequest,
  HttpGetListOfRecentDocumentRequest,
  HttpGetListOfRecentDocumentResponse,
  HttpGetMyUserProfileDocumentListsRequest,
  HttpGetMyUserProfileDocumentListsResponse,
  HttpGetMyUserProfileRequest,
  HttpGetMyUserProfileResponse,
  HttpGetNotificationsRequest,
  HttpGetNotificationsResponse,
  HttpGetOrganizationAdminInfoRequest,
  HttpGetOrganizationAdminInfoResponse,
  HttpGetOrganizationByIdResponse,
  HttpGetOrganizationDocumentCollectionStatsRequest,
  HttpGetOrganizationDocumentCollectionStatsResponse,
  HttpGetOrganizationDocumentGroupsRequest,
  HttpGetOrganizationDocumentGroupsResponse,
  HttpGetOrganizationGraphicMarkRequest,
  HttpGetOrganizationGraphicMarkResponse,
  HttpGetOrganizationGraphicMarksRequest,
  HttpGetOrganizationGraphicMarksResponse,
  HttpGetOrganizationListFromUserRequest,
  HttpGetOrganizationListFromUserResponse,
  HttpGetOrganizationRoleRequest,
  HttpGetOrganizationRoleResponse,
  HttpGetOrganizationRolesRequest,
  HttpGetOrganizationRolesResponse,
  HttpGetOrganizationUserRequest,
  HttpGetOrganizationUserResponse,
  HttpGetOutputFormatsRequest,
  HttpGetOutputFormatsResponse,
  HttpGetPaginatedOrganizationDataElementsRequest,
  HttpGetPaginatedOrganizationDataElementsResponse,
  HttpGetPaginatedOrganizationDictionaryListsRequest,
  HttpGetPaginatedOrganizationDictionaryListsResponse,
  HttpGetTaskListRequest,
  HttpGetTaskListResponse,
  HttpGetTaskRequest,
  HttpGetTaskResponse,
  HttpGetTemplateContextSampleResponse,
  HttpGetTemplateContextStateRequest,
  HttpGetTemplateContextStateResponse,
  HttpGetTemplateInfoRequest,
  HttpGetTemplateInfoResponse,
  HttpGetTemplateListRequest,
  HttpGetTemplateListResponse,
  HttpGetTemplateListWithoutPaginationRequest,
  HttpGetTemplateListWithoutPaginationResponse,
  HttpGetTemplateVersionListRequest,
  HttpGetTemplateVersionListResponse,
  HttpGetUserDocumentTypeSubscriptionsRequest,
  HttpGetUserDocumentTypeSubscriptionsResponse,
  HttpGetUserTemplatesSubscriptionsRequest,
  HttpGetUserTemplatesSubscriptionsResponse,
  HttpGetWordAsPdfRequest,
  HttpGetWordAsPdfResponse,
  HttpHardDeleteDocumentRequest,
  HttpHardDeleteDocumentResponse,
  HttpImpersonateUserRequest,
  HttpImpersonateUserResponse,
  HttpIsAccountDeleteProcessStartedRequest,
  HttpIsAccountDeleteProcessStartedResponse,
  HttpIsOrganizationDeleteProcessStartedRequest,
  HttpIsOrganizationDeleteProcessStartedResponse,
  HttpLeaveOrganizationRequest,
  HttpLeaveOrganizationResponse,
  HttpListAllOrganizationUsersRequest,
  HttpListAllOrganizationUsersResponse,
  HttpListUsersRequest,
  HttpListUsersResponse,
  HttpLoginGetOrganizationListRequest,
  HttpLoginGetOrganizationListResponse,
  HttpLoginRequest,
  HttpLoginResponse,
  HttpLogoutRequest,
  HttpLogoutResponse,
  HttpMarkNotificationsAsReadRequest,
  HttpMarkNotificationsAsReadResponse,
  HttpOrganizationExistsRequest,
  HttpOrganizationExistsResponse,
  HttpPostDocumentTypeRequest,
  HttpPostDocumentTypeResponse,
  HttpReassignDocumentCollectionInstanceRequest,
  HttpReassignDocumentCollectionInstanceResponse,
  HttpReassignTasksRequest,
  HttpReassignTasksResponse,
  HttpRemoveDocumentGroupRequest,
  HttpRemoveDocumentGroupResponse,
  HttpRemoveDocumentLabelRequest,
  HttpRemoveDocumentLabelResponse,
  HttpRemoveNotificationsRequest,
  HttpRemoveNotificationsResponse,
  HttpRenewTokenRequest,
  HttpRenewTokenResponse,
  HttpResendAccountActivationInvitationRequest,
  HttpResendAccountActivationInvitationResponse,
  HttpRestoreDocumentRequest,
  HttpRestoreDocumentResponse,
  HttpRestoreDocumentVersionRequest,
  HttpRestoreDocumentVersionResponse,
  HttpSaveDataElementRequest,
  HttpSaveDataElementResponse,
  HttpSaveDistributionRequest,
  HttpSaveDistributionResponse,
  HttpSaveDocumentLabelsRequest as HttpSaveDocumentListsRequest,
  HttpSaveDocumentLabelsResponse as HttpSaveDocumentListsResponse,
  HttpSaveDocumentRequest,
  HttpSaveDocumentResponse,
  HttpSaveTemplateContextStateRequest,
  HttpSaveTemplateContextStateResponse,
  HttpSearchRequest,
  HttpSearchResponse,
  HttpSearchSuggestionsRequest,
  HttpSearchSuggestionsResponse,
  HttpSetUserActivationStateRequest,
  HttpSetUserActivationStateResponse,
  HttpShareDocumentRequest,
  HttpShareDocumentResponse,
  HttpSwitchOrganizationRequest,
  HttpSwitchOrganizationResponse,
  HttpUpdateDataElementRequest,
  HttpUpdateDataElementResponse,
  HttpUpdateDictionaryListRequest,
  HttpUpdateDictionaryListResponse,
  HttpUpdateDocumentCollectionConfigRequest,
  HttpUpdateDocumentCollectionConfigResponse,
  HttpUpdateDocumentGroupRequest,
  HttpUpdateDocumentGroupResponse,
  HttpUpdateDocumentTypeSubscriptionsRequest,
  HttpUpdateDocumentTypeSubscriptionsResponse,
  HttpUpdateMyUserProfileDocumentListsRequest,
  HttpUpdateMyUserProfileDocumentListsResponse,
  HttpUpdateMyUserProfileRequest,
  HttpUpdateOrganizationGraphicMarkRequest,
  HttpUpdateOrganizationGraphicMarkResponse,
  HttpUpdateOrganizationRoleRequest,
  HttpUpdateOrganizationRoleResponse,
  HttpUpdateOrganizationUserRequest,
  HttpUpdateOrganizationUserResponse,
  HttpUpdateTemplateRequest,
  HttpUpdateTemplateResponse,
  HttpUpdateTemplateSubscriptionRequest,
  HttpUpdateTemplateSubscriptionResponse,
  HttpUserActivateOrganizationRequest,
  HttpUserActivateOrganizationResponse,
  HttpUserPublicProfileRequest,
  HttpUserPublicProfileResponse,
  HttpVerifyPasswordTokenRequest,
  HttpVerifyPasswordTokenResponse,
  HttpVerifyTokenRequest,
  HttpVerifyTokenResponse,
} from "@prodoctivity/types/model/api-gateway";
import {
  ActivityCollectionSearch,
  ActivityHistorySearch,
  ActivitySearch,
  RecentDocumentSearch,
} from "./interfaces/activities/ActivitySearch";

import type {
  OrganizationUserLanguage,
  ParametersObject,
  UpdateTemplateDefinitionCommand,
} from "@prodoctivity/shared/src/index-types";
import type { DistributionConfiguration, EmailRecipient } from "@prodoctivity/types";
import jwtDecode from "jwt-decode";
import { AccountUserToken } from "./context";
import { GenerationSearch } from "./interfaces/generations/GenerationSearch";

const TempLanguageLocalStorageKey = "temp_language";

function base64ToBlob(base64: string | Record<string, string>) {
  const binaryString = window.atob(base64 as string);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], {
    type: MimeTypes.MsWordDocument,
  });
}

export type Services = {
  user: AccountUserToken | undefined;
  baseUrl: string;
  token: string | undefined;
  getTempLanguage(): OrganizationUserLanguage | undefined;
  setTempLanguage(lang: OrganizationUserLanguage | undefined): void;
  authenticate(
    user: string,
    password: string,
    withGoogle?: boolean
  ): Promise<HttpLoginResponse["payload"] | undefined>;
  logout(): Promise<void>;
  saveToken(token: string): void;
  renewToken(token: string): Promise<HttpRenewTokenResponse["payload"] | undefined>;
  userOrganizations(): Promise<HttpGetOrganizationListFromUserResponse["payload"]>;
  switchOrganization(
    organizationId: OrganizationId
  ): Promise<HttpSwitchOrganizationResponse["payload"]>;
  fetchTemplateList(
    list: HttpGetTemplateListRequest["queryParameters"]["list"],
    isContentLibrary: boolean | undefined,
    pageNumber?: number,
    rowsPerPage?: "15" | "30" | "100",
    nameFilter?: string,
    documentGroupId?: string
  ): Promise<HttpGetTemplateListResponse["payload"]>;
  fetchTemplateListWithoutPagination(
    documentGroupId?: string
  ): Promise<HttpGetTemplateListWithoutPaginationResponse["payload"]>;
  fetchTemplateInfo(templateVersionId: string): Promise<HttpGetTemplateInfoResponse["payload"]>;
  getCombinedTemplateList(
    pageNumber: number,
    rowsPerPage: HttpGetCombinedTemplateListRequest["queryParameters"]["rowsPerPage"],
    nameFilter?: string
  ): Promise<HttpGetCombinedTemplateListResponse["payload"]>;
  getCombinedTemplate(id: string): Promise<HttpGetCombinedTemplateResponse["payload"]>;
  getCombinedTemplateDefinition(
    id: string
  ): Promise<HttpGetCombinedTemplateDefinitionResponse["payload"]>;
  deleteCombinedTemplate(id: string): Promise<HttpDeleteCombinedTemplateResponse["payload"]>;
  createOrUpdateCombinedTemplate(
    combinedTemplate: HttpCreateOrUpdateCombinedTemplateRequest["payload"]
  ): Promise<HttpCreateOrUpdateCombinedTemplateRequestResponse["payload"]>;
  executeDataLink(
    dataLinkId: string,
    dataLinkConfigId: string,
    inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
  ): Promise<HttpExecuteDataLinkResponse["payload"]>;
  getDataLinkConfig(
    datalinkConfigId: string,
    datalinkConfigVersionId: string | undefined
  ): Promise<HttpGetDataLinkConfigDetailsResponse["payload"]>;
  forgotPassword(email: string): Promise<HttpForgotPasswordResponse["payload"] | undefined>;
  addUserToOrganization(
    name: string,
    lastName: string,
    email: string,
    organizationId: string
  ): Promise<HttpAddUserToOrganizationResponse["payload"]>;
  leaveOrganization(organizationId: string): Promise<HttpLeaveOrganizationResponse["payload"]>;
  resendAccountActivationInvitation(
    name: string,
    lastName: string,
    email: string
  ): Promise<HttpResendAccountActivationInvitationResponse["payload"]>;

  createOrganization(
    firstName: string,
    lastName: string,
    password: string,
    email: string,
    phone: string,
    organizationId: string,
    organizationName: string,
    language: OrganizationUserLanguage,
    keywords: OrganizationInitBundles[],
    isEnrolledForMarketing?: boolean
  ): Promise<HttpCreateOrganizationResponse["payload"]>;

  deleteOrganizationPetition(
    reason: string,
    deleteType: DeleteOrganizationAccountType,
    organizationId: string
  ): Promise<HttpDeleteOrganizationPetitionResponse["payload"]>;
  deleteMyAccountPetition(
    reason: string,
    email: string
  ): Promise<HttpDeleteMyAccountPetitionResponse["payload"]>;
  isDeletionProcessInitiated(): Promise<HttpIsOrganizationDeleteProcessStartedResponse["payload"]>;
  isDeletionAccountProcessInitiated(): Promise<
    HttpIsAccountDeleteProcessStartedResponse["payload"]
  >;
  organizationExists(organizationId: string): Promise<HttpOrganizationExistsResponse["payload"]>;
  userActivateOrganization(otp: string): Promise<{ jwtToken: string }>;
  changePassword(password: string, newPassword: string): Promise<void>;
  adminChangePassword(username: string, password: string): Promise<void>;
  activateForgotPasswordOTP(email: string, token: string, password: string): Promise<void>;
  verifyToken(token: string, email?: string): Promise<HttpVerifyTokenResponse["payload"]>;
  verifyPasswordToken(
    token: string,
    email?: string
  ): Promise<HttpVerifyPasswordTokenResponse["payload"]>;
  activateJoinOrganization(
    email: string,
    token: string
  ): Promise<HttpActivateJoinOrganizationResponse["payload"]>;
  generateDocumentsSync(req: HttpAppSyncDocumentGenerationRequest["payload"]): Promise<{
    docs: Record<string, Blob>;
    documentId: string | undefined;
    documentVersionId: string | undefined;
  }>;
  listUsers(
    pageNumber: number,
    pageLength: HttpListUsersRequest["queryParameters"]["rowsPerPage"],
    nameFilter?: string
  ): Promise<HttpListUsersResponse["payload"]>;

  listAllOrganizationUsers(): Promise<HttpListAllOrganizationUsersResponse["payload"]>;
  setUserActivationState(username: string, state: boolean): Promise<void>;
  loginGetOrganizationList(
    email: string,
    password?: string
  ): Promise<HttpLoginGetOrganizationListResponse["payload"]>;

  fetchOrganization(): Promise<HttpGetOrganizationByIdResponse["payload"]>;
  fetchOrganizationAdminInfo(): Promise<HttpGetOrganizationAdminInfoResponse["payload"]>;
  messageEventHandlers: Record<
    FromServerWsMessage["type"],
    Array<(msg: FromServerWsMessage) => void>
  >;
  getNotifications(pageNumber: number): Promise<HttpGetNotificationsResponse["payload"]>;
  markNotificationAsRead(
    notificationIdList: Array<string>
  ): Promise<HttpMarkNotificationsAsReadResponse["payload"]>;
  removeNotifications(
    notificationIdList: Array<string>
  ): Promise<HttpRemoveNotificationsResponse["payload"]>;
  shareDocument(
    documentId: string,
    documentVersionId: string,
    from: EmailRecipient,
    recipients: EmailRecipient[],
    subject: string,
    body: string
  ): Promise<HttpShareDocumentResponse["payload"]>;
  contactHelp(
    from: EmailRecipient,
    phoneNumber: string,
    body: string
  ): Promise<HttpContactHelpResponse["payload"]>;
  getMyUserProfile(): Promise<HttpGetMyUserProfileResponse["payload"]>;
  updateUserProfile(user: HttpUpdateMyUserProfileRequest["payload"]): Promise<void>;
  updateMyUserProfileDocumentLabels(
    oldDocumentLabel: string | undefined,
    newDocumentLabel: string | undefined
  ): Promise<HttpUpdateMyUserProfileDocumentListsResponse["payload"]>;
  deleteAllDocumentsLists(
    documentList: string
  ): Promise<HttpDeleteAllDocumentsListsResponse["payload"]>;
  deleteMyUserProfileDocumentLabel(
    documentLabel: string
  ): Promise<HttpDeleteMyUserProfileDocumentLabelResponse["payload"]>;
  getMyUserProfileDocumentLabels(): Promise<HttpGetMyUserProfileDocumentListsResponse["payload"]>;
  getDocumentLabelsDocumentsCount(
    documentLabel: string,
    isFavorites: boolean | undefined
  ): Promise<HttpGetDocumentLabelsDocumentsCountResponse["payload"]>;
  updateOrganizationUser(user: HttpUpdateOrganizationUserRequest["payload"]): Promise<void>;
  getListOfRecentDocument(
    recentDocuments: RecentDocumentSearch
  ): Promise<HttpGetListOfRecentDocumentResponse["payload"]>;
  getActionLogs(search: ActivitySearch): Promise<HttpGetActionLogEntriesListResponse["payload"]>;
  getCollectionActionLogs(
    search: ActivityCollectionSearch,
    instanceId: string
  ): Promise<HttpGetCollectionActionLogEntriesListResponse["payload"]>;
  getHistoryActionLogs(
    search: ActivityHistorySearch,
    documentId: string
  ): Promise<HttpGetHistoryActionLogEntriesListResponse["payload"]>;
  getActionEntry(id: string): Promise<HttpGetActionLogEntryResponse["payload"] | undefined>;
  getUserPublicProfile(username: string): Promise<HttpUserPublicProfileResponse["payload"]>;
  getTemplateVersionList(
    templateId: string
  ): Promise<HttpGetTemplateVersionListResponse["payload"]>;
  getAllDocumentGroups(
    isContentLibrary: boolean | undefined
  ): Promise<HttpGetOrganizationDocumentGroupsResponse["payload"]>;
  getDocumentGroupList(
    pageNumber: HttpGetDocumentGroupListRequest["queryParameters"]["pageNumber"],
    pageLength: HttpGetDocumentGroupListRequest["queryParameters"]["rowsPerPage"],
    filter?: string
  ): Promise<HttpGetDocumentGroupListResponse["payload"]>;
  saveDocumentGroup(
    documentGroup: HttpCreateDocumentGroupRequest["payload"]
  ): Promise<HttpCreateDocumentGroupResponse["payload"]>;
  getDocumentCollectionInstanceDetails(
    instanceId: string
  ): Promise<HttpGetDocumentCollectionInstanceDetailsResponse["payload"] | { instance: undefined }>;
  removeDocumentGroup(
    documentGroupId: string,
    newDocumentGroupId: string
  ): Promise<HttpRemoveDocumentGroupResponse["payload"]>;
  getDocumentGroup(documentGroupId: string): Promise<HttpGetDocumentGroupResponse["payload"]>;
  updateDocumentGroup(documentGroup: {
    documentGroupId: string;
    name: string;
    isContentLibrary: boolean;
    description: string;
    roleToNotify: string | undefined;
  }): Promise<HttpUpdateDocumentGroupResponse["payload"]>;
  getDocumentTypeInfo(documentTypeId: string): Promise<HttpGetDocumentTypeResponse["payload"]>;
  getDocumentTypeVersionInfo(
    documentTypeId: string,
    documentTypeVersionId: string
  ): Promise<HttpGetDocumentTypeVersionResponse["payload"]>;
  getDocumentTypeList(
    pageNumber: HttpGetDocumentTypeListRequest["queryParameters"]["pageNumber"],
    rowsPerPage: HttpGetDocumentTypeListRequest["queryParameters"]["rowsPerPage"],
    documentType: string | undefined,
    documentGroupId: string | undefined
  ): Promise<HttpGetDocumentTypeListResponse["payload"]>;
  getDocumentLabels(documentId: string): Promise<HttpGetDocumentLabelsResponse["payload"]>;
  addDocumentToDocumentList(
    documentId: string,
    listName: string,
    isFavorites: boolean,
    order: number | undefined
  ): Promise<HttpSaveDocumentListsResponse["payload"]>;
  removeDocumentFromDocumentList(
    documentId: string,
    documentLabel: string,
    isFavorites: boolean
  ): Promise<HttpRemoveDocumentLabelResponse["payload"]>;
  getAllDocumentTypes(): Promise<HttpGetAllDocumentTypesResponse["payload"]>;
  registerDocumentView(
    documentId: string,
    documentVersionId: string
  ): Promise<HttpDocumentViewResponse["payload"]>;
  viewDocumentCollectionInstance(
    instanceId: string
  ): Promise<HttpDocumentCollectionInstanceViewResponse["payload"]>;

  getDocumentCollectionInstanceView(
    pageNumber: number,
    rowsPerPage: HttpGetDocumentCollectionInstanceViewRequest["queryParameters"]["rowsPerPage"]
  ): Promise<HttpGetDocumentCollectionInstanceViewResponse["payload"]>;
  impersonateUser(
    routeParams: HttpImpersonateUserRequest["routeParams"]
  ): Promise<HttpImpersonateUserResponse["payload"]>;
  saveDocumentType(
    documentType: SaveDocumentType
  ): Promise<HttpPostDocumentTypeResponse["payload"]>;
  getDocumentByIdentifier(
    documentTypeId: string,
    documentIdentifier: string
  ): Promise<
    | HttpGetDocumentByIdentifierResponse["payload"]
    | { documentId: undefined; documentVersionId: undefined; data: undefined }
  >;
  getDocument(
    documentId: string,
    documentVersionId?: string
  ): Promise<HttpGetDocumentResponse["payload"] | { document: undefined }>;
  getWordAsPdf(
    wordDataUri: string,
    documentVersionId: string | undefined
  ): Promise<HttpGetWordAsPdfResponse["payload"] | { pdfDataUri: undefined }>;
  getLastDocument(
    documentId: string,
    documentVersionId?: string
  ): Promise<HttpGetDocumentResponse["payload"] | { document: undefined }>;
  getDocumentVersions(documentId: string): Promise<HttpGetDocumentVersionsResponse["payload"]>;
  getDocumentHistory(
    documentId: string,
    rowsPerPage: HttpGetDocumentHistoryRequest["queryParameters"]["rowsPerPage"],
    pageNumber: number
  ): Promise<HttpGetDocumentHistoryResponse["payload"]>;
  getDocumentVersionGenerationContext(
    documentId: string,
    documentVersionId: string
  ): Promise<HttpGetDocumentVersionGenerationContextResponse["payload"]>;
  getDocumentByGenerationToken(params: {
    generationToken: string;
    documentId: string;
  }): Promise<HttpGetDocumentResponse["payload"]>;
  getDocumentsByGenerationToken(params: {
    generationToken: string;
  }): Promise<HttpGetDocumentsByGenerationTokenResponse["payload"]>;
  getDocumentTypesMergedContext(
    documentTypeIdList: Array<string>
  ): Promise<HttpGetDocumentTypesMergedContextResponse["payload"]>;
  getDocumentCollectionFolderMergedContext(
    masterDocumentTypeId: string,
    documentTypeIdList: Array<string>
  ): Promise<HttpGetDocumentCollectionFolderMergedContextResponse["payload"]>;
  intersectionContextFields(
    documentTypesId: Array<string>
  ): Promise<HttpGetDocumentTypesIntersectionContextResponse["payload"]>;
  getDocumentTypesWithCommonFields(
    dataElements: HttpGetDocumentTypesWithCommonFieldsRequest["payload"]["dataElements"]
  ): Promise<HttpGetDocumentTypesWithCommonFieldsResponse["payload"]>;
  saveDocument(
    document: HttpSaveDocumentRequest["payload"]
  ): Promise<HttpSaveDocumentResponse["payload"]>;
  restoreDocumentVersion(
    documentId: HttpRestoreDocumentVersionRequest["routeParams"]["documentId"],
    documentVersionId: HttpRestoreDocumentVersionRequest["routeParams"]["documentId"]
  ): Promise<HttpRestoreDocumentVersionResponse["payload"]>;
  getAllOrganizationRoles(): Promise<HttpGetAllOrganizationRolesResponse["payload"]>;
  getOrganizationRoles(
    pageNumber: number,
    rowsPerPage: HttpGetOrganizationRolesRequest["queryParameters"]["rowsPerPage"],
    nameFilter: string
  ): Promise<HttpGetOrganizationRolesResponse["payload"]>;
  getOrganizationRole(roleId: string): Promise<HttpGetOrganizationRoleResponse["payload"]>;
  createOrganizationRole(
    payload: {
      name: string;
      users: Array<string>;
    } & OrganizationPermissionPart
  ): Promise<HttpCreateOrganizationRoleResponse["payload"]>;
  updateOrganizationRole(
    params: {
      roleId: string;
      name: string;
      users: Array<string>;
    } & OrganizationPermissionPart
  ): Promise<HttpUpdateOrganizationRoleResponse["payload"]>;
  deleteOrganizationRole(roleId: string): Promise<HttpDeleteOrganizationRoleResponse["payload"]>;
  createOrganizationGraphicMark(
    payload: {
      name: string;
      imageDataURI: string | undefined;
    } & GraphicMark
  ): Promise<HttpCreateOrganizationGraphicMarkResponse["payload"]>;
  updateOrganizationGraphicMark(
    params: {
      graphicMarkId: string;
      name: string;
      imageDataURI: string | undefined;
    } & GraphicMark
  ): Promise<HttpUpdateOrganizationGraphicMarkResponse["payload"]>;
  deleteOrganizationGraphicMark(
    graphicMarkId: string
  ): Promise<HttpDeleteOrganizationGraphicMarkResponse["payload"]>;
  allOrganizationGraphicMarks(): Promise<HttpAllGetOrganizationGraphicMarkResponse["payload"]>;
  getOrganizationGraphicMark(
    graphicMarkId: string
  ): Promise<HttpGetOrganizationGraphicMarkResponse["payload"]>;
  getOrganizationGraphicMarks(
    rowsPerPage: HttpGetOrganizationGraphicMarksRequest["queryParameters"]["rowsPerPage"],
    pageNumber: HttpGetOrganizationGraphicMarksRequest["queryParameters"]["pageNumber"],
    nameFilter: HttpGetOrganizationGraphicMarksRequest["queryParameters"]["nameFilter"]
  ): Promise<HttpGetOrganizationGraphicMarksResponse["payload"]>;
  deleteDocument(
    documentId: string,
    documentVersionId: string | undefined
  ): Promise<HttpDeleteDocumentResponse["payload"]>;
  getOrganizationUser(username: string): Promise<HttpGetOrganizationUserResponse["payload"]>;
  getGenerationStatus(
    generationToken: HttpDocumentGenerationStatusRequest["routeParams"]["generationToken"],
    pageNumber: HttpDocumentGenerationStatusRequest["queryParameters"]["pageNumber"],
    rowsPerPage: HttpDocumentGenerationStatusRequest["queryParameters"]["rowsPerPage"]
  ): Promise<HttpDocumentGenerationStatusResponse["payload"]>;
  getGenerationStatusDocument(
    generationToken: string,
    documentKey: string,
    fileKey: string
  ): Promise<HttpGetGenerationStatusDocumentResponse["payload"]>;

  getTemplateContextSampleRequest(
    templateVersionIds: string[]
  ): Promise<HttpGetTemplateContextSampleResponse["payload"]>;
  search(
    fieldsConditions: SearchQueryRequest["fields"],
    pageNumber: number | undefined,
    rowsPerPage: "15" | "50" | "100" | undefined,
    query: string | undefined,
    documentTypesIdList: string[] | undefined,
    sortDirection: "asc" | "desc" | undefined,
    sortField: string | undefined,
    includeApproximateResults: boolean | undefined
  ): Promise<HttpSearchResponse["payload"]>;
  searchSuggestions(
    fieldsConditions: SearchQueryRequest["fields"],
    query?: string,
    documentTypesVersionId?: string[]
  ): Promise<HttpSearchSuggestionsResponse["payload"]>;
  getDistributionImplementations(): Promise<HttpGetDistributionImplementationsResponse["payload"]>;
  getDeletedDocumentList(
    pageNumber: number,
    rowsPerPage: "15" | "30" | "50" | "100" | "500",
    sortField?: "documentType" | "name" | "deletedBy" | "deletedAt",
    sortDirection?: "ASC" | "DESC",
    documentTypeIdList?: string[],
    deletedByFilter?: string,
    dateStart?: number,
    dateEnd?: number
  ): Promise<HttpGetDeletedDocumentListResponse["payload"]>;
  hardDeleteDocument(
    deletedDocumentList: RestoreDocumentRequest["deletedDocumentList"]
  ): Promise<HttpHardDeleteDocumentResponse["payload"]>;
  restoreDeletedDocument(
    deletedDocumentList: RestoreDocumentRequest["deletedDocumentList"]
  ): Promise<HttpHardDeleteDocumentResponse["payload"]>;
  getDistributions(pageNumber: number): Promise<HttpGetDistributionsResponse["payload"]>;
  getDistribution(id: string): Promise<HttpGetDistributionResponse["payload"]>;
  saveDistribution(
    config: DistributionConfiguration
  ): Promise<HttpSaveDistributionResponse["payload"]>;
  disableDistribution(distributionId: string): Promise<HttpDisableDistributionResponse["payload"]>;
  deleteDistribution(distributionId: string): Promise<HttpDeleteDistributionResponse["payload"]>;
  getGenerations(search: GenerationSearch): Promise<HttpDocumentGenerationListResponse["payload"]>;
  getPaginatedOrganizationDataElements(
    pageNumber: HttpGetPaginatedOrganizationDataElementsRequest["queryParameters"]["pageNumber"],
    rowsPerPage: HttpGetPaginatedOrganizationDataElementsRequest["queryParameters"]["rowsPerPage"],
    filter?: string,
    resultsAreClassDataElements?: boolean
  ): Promise<HttpGetPaginatedOrganizationDataElementsResponse["payload"]>;
  getDataElement(
    dataElementName: string
  ): Promise<HttpGetDataElementResponse["payload"] | { dataElement: undefined }>;
  saveDataElement(
    dataElement: HttpSaveDataElementRequest["payload"]
  ): Promise<HttpSaveDataElementResponse["payload"]>;
  updateDataElement(
    dataElement: HttpUpdateDataElementRequest["payload"]
  ): Promise<HttpUpdateDataElementResponse["payload"]>;
  deleteDataElement(dataElementName: string): Promise<HttpDeleteDataElementResponse["payload"]>;
  getOutputFormats(): Promise<HttpGetOutputFormatsResponse["payload"]>;
  getInputMasks(): Promise<HttpGetInputMasksResponse["payload"]>;
  getPaginatedOrganizationDictionaryLists(
    pageNumber: HttpGetPaginatedOrganizationDictionaryListsRequest["queryParameters"]["pageNumber"],
    rowsPerPage: HttpGetPaginatedOrganizationDictionaryListsRequest["queryParameters"]["rowsPerPage"],
    filter?: string
  ): Promise<HttpGetPaginatedOrganizationDictionaryListsResponse["payload"]>;
  getDictionaryList(dictionaryListName: string): Promise<HttpGetDictionaryListResponse["payload"]>;
  createDictionaryList(
    dictionaryList: HttpCreateDictionaryListRequest["payload"]
  ): Promise<HttpCreateDictionaryListResponse["payload"]>;
  updateDictionaryList(
    dictionaryList: HttpUpdateDictionaryListRequest["payload"]
  ): Promise<HttpUpdateDictionaryListResponse["payload"]>;
  deleteDictionaryList(
    dictionaryListName: string
  ): Promise<HttpDeleteDictionaryListResponse["payload"]>;
  updateTemplateDefinition(
    templateVersionId: string,
    payload: UpdateTemplateDefinitionCommand
  ): Promise<HttpUpdateTemplateResponse["payload"]>;
  getWorkflowTaskDetails(workflowTaskId: string): Promise<any>;
  getUserWorkflowTasks(username: string): Promise<any>;
  getWorkflowProfiles(): Promise<any[]>;
  getWorkflowTaskList(): Promise<any[]>;

  getDocuments(
    documentTypeIdList: string[] | undefined,
    updatedBy: string | undefined,
    dateStart: number | undefined,
    dateEnd: number | undefined,
    pageNumber: number,
    rowsPerPage: HttpGetDocumentsRequest["queryParameters"]["rowsPerPage"],
    sortField: HttpGetDocumentsRequest["queryParameters"]["sortField"],
    sortDirection: HttpGetDocumentsRequest["queryParameters"]["sortDirection"]
  ): Promise<HttpGetDocumentsResponse["payload"]>;

  getDocumentsWithLists(
    documentTypeIdList: string[] | undefined,
    documentLists: string[] | undefined,
    includeFavorites: boolean | undefined,
    dateStart: number | undefined,
    dateEnd: number | undefined,
    pageNumber: number,
    rowsPerPage: HttpGetDocumentsWithListsRequest["queryParameters"]["rowsPerPage"],
    sortField: HttpGetDocumentsWithListsRequest["queryParameters"]["sortField"],
    sortDirection: HttpGetDocumentsWithListsRequest["queryParameters"]["sortDirection"]
  ): Promise<HttpGetDocumentsWithListsResponse["payload"]>;

  getDocumentCollectionConfigList(
    pageNumber: number,
    rowsPerPage: HttpGetDocumentCollectionConfigListRequest["queryParameters"]["rowsPerPage"],
    filter?: string
  ): Promise<HttpGetDocumentCollectionConfigListResponse["payload"]>;
  getDocumentCollectionConfig(
    id: string
  ): Promise<HttpGetDocumentCollectionConfigResponse["payload"]>;

  getDocumentCollectionInstanceList(
    pageNumber: number,
    rowsPerPage: HttpGetDocumentCollectionInstanceListRequest["queryParameters"]["rowsPerPage"],
    nameFilter?: string,
    assignedTo?: string
  ): Promise<HttpGetDocumentCollectionInstanceListResponse["payload"]>;

  getDocumentCollection(
    id: string
  ): Promise<
    | HttpGetDocumentCollectionInstanceResponse["payload"]
    | { collection: undefined; keys: DocumentCollectionInstance<number>["keys"] }
  >;

  updateDocumentCollectionAssigned(
    id: string,
    assignedTo?: string
  ): Promise<HttpReassignDocumentCollectionInstanceResponse["payload"] | { collection: undefined }>;

  createDocumentCollectionConfig(
    config: DocumentCollectionConfig<string>
  ): Promise<HttpCreateDocumentCollectionConfigResponse["payload"]>;

  updateDocumentCollectionConfig(params: {
    id: string;
    config: DocumentCollectionConfig<string>;
  }): Promise<HttpUpdateDocumentCollectionConfigResponse["payload"]>;

  getCollectionConfigAffectedEntities(
    id: string
  ): Promise<HttpGetCollectionConfigAffectedEntitiesResponse["payload"]>;

  deleteDocumentCollectionConfig(
    id: string
  ): Promise<HttpDeleteDocumentCollectionConfigResponse["payload"]>;

  getBundleConfigList(
    pageNumber: number,
    rowsPerPage: HttpGetBundleConfigListRequest["queryParameters"]["rowsPerPage"],
    languageFilter: HttpGetBundleConfigListRequest["queryParameters"]["languageFilter"],
    nameFilter?: string
  ): Promise<HttpGetBundleConfigListResponse["payload"]>;

  getBundleConfig(
    identifier: HttpGetBundleConfigRequest["routeParams"]["identifier"]
  ): Promise<HttpGetBundleConfigResponse["payload"]>;

  postApplyBundle(params: {
    identifier: BundleConfig["identifier"];
    inputData?: ParametersObject;
  }): Promise<HttpApplyBundleResponse["payload"]>;

  getTasks(
    pageNumber: number,
    rowsPerPage: HttpGetTaskListRequest["queryParameters"]["rowsPerPage"],
    ignoreUserFilter: HttpGetTaskListRequest["queryParameters"]["ignoreUserFilter"]
  ): Promise<HttpGetTaskListResponse["payload"]>;

  getTask(id: string): Promise<HttpGetTaskResponse["payload"]>;

  reassignTasks(
    taskIdList: string[],
    username: string
  ): Promise<HttpReassignTasksResponse["payload"]>;
  getTemplateContextState(taskId: string): Promise<HttpGetTemplateContextStateResponse["payload"]>;

  createTemplateContextState(
    contextInfo: TemplateContextHeader,
    context: ParametersObject
  ): Promise<HttpCreateTemplateContextStateResponse["payload"]>;

  saveTemplateContextState(
    taskId: string,
    context: ParametersObject
  ): Promise<HttpSaveTemplateContextStateResponse["payload"]>;

  deleteTemplateContextState(
    taskId: string
  ): Promise<HttpDeleteTemplateContextStateResponse["payload"]>;
  getUserTemplatesSubscriptions(
    pageNumber: number,
    rowsPerPage: HttpGetUserTemplatesSubscriptionsRequest["queryParameters"]["rowsPerPage"]
  ): Promise<HttpGetUserTemplatesSubscriptionsResponse["payload"]>;
  updateTemplateSubscription(
    subscription: HttpUpdateTemplateSubscriptionRequest["payload"]
  ): Promise<void>;

  getUserDocumentTypeSubscriptions(
    pageNumber: number,
    rowsPerPage: HttpGetUserDocumentTypeSubscriptionsRequest["queryParameters"]["rowsPerPage"]
  ): Promise<HttpGetUserDocumentTypeSubscriptionsResponse["payload"]>;
  updateDocumentTypeSubscriptions(
    subscription: HttpUpdateDocumentTypeSubscriptionsRequest["payload"]
  ): Promise<void>;
  getDeferredDistributionList(
    pageNumber: HttpGetDeferredDistributionListRequest["queryParameters"]["pageNumber"],
    rowsPerPage: HttpGetDeferredDistributionListRequest["queryParameters"]["rowsPerPage"]
  ): Promise<HttpGetDeferredDistributionListResponse["payload"]>;
  getOrganizationDocumentCollectionStats: () => Promise<
    GetOrganizationCollectionStatsResponse["payload"]
  >;
  getDocumentSummaryInfo(
    documentId: string,
    documentVersionId?: string
  ): Promise<HttpGetDocumentSummaryInfoResponse["payload"]>;
};
const getHttpRequest: (
  location: () => string,
  navigate: (loc: string) => void
) => typeof rawHttpRequest = () => {
  return (baseUrl, req, options) => {
    return rawHttpRequest(baseUrl, req, {
      onForbidden() {
        //navigate(`/login?returnTo?${encodeURIComponent(location())}`);
      },
      responseType: "json",
      ...(options || {}),
    });
  };
};

export function servicesBuilder(
  baseUrl: string,
  token: string | undefined,
  setToken: (token: string | undefined) => void,
  messageEventHandlers: Record<
    FromServerWsMessage["type"],
    Array<(msg: FromServerWsMessage) => void>
  >,
  location: () => string,
  navigate: (loc: string) => void,
  changeLanguage: (lang: string) => void
): Services {
  const httpRequest = getHttpRequest(location, navigate);

  const user: AccountUserToken | undefined = (() => {
    if (token) {
      const rawUser = jwtDecode<AppUserAccountContext>(token);
      if (rawUser.aud !== "app-user" || rawUser.iss !== "ProDoctivity") {
        return undefined;
      }

      return {
        token: token,
        email: rawUser.sub,
        username: rawUser.sub,
        organizationId: rawUser.organizationId,
        organizationName: (rawUser as any).organizationName as string,
        permissions: rawUser.permissions,
        expires: rawUser.exp,
      };
    } else {
      return undefined;
    }
  })();

  const saveToken = (token: string | undefined) => {
    if (localStorage) {
      if (typeof token === "undefined") {
        localStorage.removeItem("token");
      } else {
        localStorage.setItem("token", token);
      }
    }
    setToken(token);
  };

  async function getMyUserProfile(
    newToken?: string
  ): Promise<HttpGetMyUserProfileResponse["payload"]> {
    try {
      const { data } = await httpRequest<HttpGetMyUserProfileRequest, HttpGetMyUserProfileResponse>(
        baseUrl,
        {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/my-profile",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${newToken || token}`,
          },
        }
      );

      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  return {
    user,
    baseUrl,
    token,
    messageEventHandlers,
    getTempLanguage() {
      if (window.localStorage) {
        const l = window.localStorage.getItem(TempLanguageLocalStorageKey);
        if (!l) {
          return undefined;
        }
        return OrganizationUserLanguage$Schema.parse(l);
      }
      return OrganizationUserLanguage$Schema.parse("");
    },
    setTempLanguage(lang) {
      if (window.localStorage) {
        if (lang === undefined) {
          window.localStorage.removeItem(TempLanguageLocalStorageKey);
        } else {
          window.localStorage.setItem(
            TempLanguageLocalStorageKey,
            OrganizationUserLanguage$Schema.parse(lang)
          );
        }
      }
    },
    async logout() {
      try {
        if (token) {
          await httpRequest<HttpLogoutRequest, HttpLogoutResponse>(baseUrl, {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: "/user/logout",
            routeParams: {},
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          });
        }
      } finally {
        if (localStorage) {
          localStorage.removeItem("token");
        }
        saveToken(undefined);
      }
    },
    saveToken,
    async authenticate(
      user,
      password,
      withGoogle
    ): Promise<HttpLoginResponse["payload"] | undefined> {
      const { data } = await httpRequest<HttpLoginRequest, HttpLoginResponse>(baseUrl, {
        method: "POST",
        multiParts: {},
        queryParameters: {},
        route: "/users/login",
        routeParams: {},
        payload: {
          username: user.toLocaleLowerCase(),
          password,
          provider: withGoogle ? "google" : undefined,
        },
        headers: {},
      });

      if (data) {
        saveToken(data.token);
        const profile = await getMyUserProfile(data.token);
        changeLanguage(profile.language);
      }

      return data;
    },
    async renewToken(token) {
      try {
        const response = await httpRequest<HttpRenewTokenRequest, HttpRenewTokenResponse>(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: `/token/renew`,
          routeParams: {},
          payload: {
            token,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        if (data) {
          saveToken(data.token);
          const profile = await getMyUserProfile(data.token);
          changeLanguage(profile.language);
        }
        return data;
      } catch (err) {
        console.log(err);
        return undefined;
      }
    },
    async userOrganizations() {
      try {
        const { data } = await httpRequest<
          HttpGetOrganizationListFromUserRequest,
          HttpGetOrganizationListFromUserResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/users/my-organizations",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    //here
    async organizationExists(organizationId) {
      try {
        const { data } = await httpRequest<
          HttpOrganizationExistsRequest,
          HttpOrganizationExistsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/organization/:organizationId/exists",
          routeParams: { organizationId },
          payload: {},
          headers: {},
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async switchOrganization(organizationId: OrganizationId) {
      try {
        const { data } = await httpRequest<
          HttpSwitchOrganizationRequest,
          HttpSwitchOrganizationResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/organization/:organizationId/switch",
          routeParams: {
            organizationId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        saveToken(data.token);
        const profile = await getMyUserProfile(data.token);
        changeLanguage(profile.language);

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async fetchTemplateList(
      list,
      isContentLibrary,
      pageNumber,
      rowsPerPage,
      nameFilter,
      documentGroupId
    ) {
      const { data } = await httpRequest<HttpGetTemplateListRequest, HttpGetTemplateListResponse>(
        baseUrl,
        {
          method: "GET",
          route: "/app/templates/",
          multiParts: {},
          queryParameters: {
            pageNumber: pageNumber || 0,
            rowsPerPage: rowsPerPage || "15",
            documentGroupId,
            isContentLibrary: isContentLibrary,
            list,
            nameFilter: nameFilter,
          },
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
    async fetchTemplateListWithoutPagination(documentGroupId?: string) {
      const { data } = await httpRequest<
        HttpGetTemplateListWithoutPaginationRequest,
        HttpGetTemplateListWithoutPaginationResponse
      >(baseUrl, {
        method: "GET",
        route: "/app/templates/all",
        multiParts: {},
        routeParams: {},
        queryParameters: {
          documentGroupId,
        },
        payload: {},
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return data;
    },

    async getDeletedDocumentList(
      pageNumber,
      rowsPerPage,
      sortField,
      sortDirection,
      documentTypeIdList,
      deletedByFilter,
      dateStart,
      dateEnd
    ) {
      const { data } = await httpRequest<
        HttpGetDeletedDocumentListRequest,
        HttpGetDeletedDocumentListResponse
      >(baseUrl, {
        method: "GET",
        route: "/app/deleted-documents",
        multiParts: {},
        queryParameters: {
          pageNumber: pageNumber || 0,
          rowsPerPage: rowsPerPage || "15",
          sortField,
          sortDirection,
          documentTypeIdList,
          deletedByFilter,
          dateStart,
          dateEnd,
        },
        routeParams: {},
        payload: {},
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    async fetchTemplateInfo(templateVersionId) {
      const { data } = await httpRequest<HttpGetTemplateInfoRequest, HttpGetTemplateInfoResponse>(
        baseUrl,
        {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/template-versions/:templateVersionId/info",
          routeParams: { templateVersionId },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    },

    async getCombinedTemplateList(pageNumber, rowsPerPage, nameFilter) {
      const { data } = await httpRequest<
        HttpGetCombinedTemplateListRequest,
        HttpGetCombinedTemplateListResponse
      >(baseUrl, {
        method: "GET",
        multiParts: {},
        queryParameters: { pageNumber, rowsPerPage, nameFilter },
        route: "/app/combined-templates",
        routeParams: {},
        payload: {},
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    async getCombinedTemplate(id) {
      try {
        const { data } = await httpRequest<
          HttpGetCombinedTemplateRequest,
          HttpGetCombinedTemplateResponse
        >(baseUrl, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          method: "GET",
          multiParts: {},
          payload: {},
          queryParameters: {},
          route: "/app/combined-templates/:id",
          routeParams: {
            id,
          },
        });
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async getDocumentSummaryInfo(documentId, documentVersionId) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentSummaryInfoRequest,
          HttpGetDocumentSummaryInfoResponse
        >(baseUrl, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          method: "GET",
          multiParts: {},
          payload: {},
          queryParameters: {
            documentVersionId: documentVersionId || undefined,
          },
          route: "/app/document/:documentId/summary",
          routeParams: {
            documentId: documentId,
          },
        });
        return data;
      } catch (error) {
        throw error;
      }
    },

    async getCombinedTemplateDefinition(id) {
      try {
        const { data } = await httpRequest<
          HttpGetCombinedTemplateDefinitionRequest,
          HttpGetCombinedTemplateDefinitionResponse
        >(baseUrl, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          method: "GET",
          multiParts: {},
          payload: {},
          queryParameters: {},
          route: "/app/combined-templates/:id/templates-merged-definition",
          routeParams: {
            id,
          },
        });
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async deleteCombinedTemplate(id) {
      try {
        const { data } = await httpRequest<
          HttpDeleteCombinedTemplateRequest,
          HttpDeleteCombinedTemplateResponse
        >(baseUrl, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          method: "DELETE",
          multiParts: {},
          payload: {},
          queryParameters: {},
          route: "/app/combined-templates/:id",
          routeParams: {
            id,
          },
        });
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async createOrUpdateCombinedTemplate(combinedTemplate) {
      try {
        const { data } = await httpRequest<
          HttpCreateOrUpdateCombinedTemplateRequest,
          HttpCreateOrUpdateCombinedTemplateRequestResponse
        >(baseUrl, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          method: "POST",
          multiParts: {},
          payload: combinedTemplate,
          queryParameters: {},
          route: "/app/combined-templates",
          routeParams: {},
        });
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async getDataLinkConfig(datalinkConfigId, _datalinkConfigVersionId) {
      try {
        const getDatalinkConfigDetailResponse = await httpRequest<
          HttpGetDataLinkConfigDetailsRequest,
          HttpGetDataLinkConfigDetailsResponse
        >(baseUrl, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          method: "GET",
          multiParts: {},
          payload: {},
          queryParameters: {},
          route: "/datalinks/:datalinkId",
          routeParams: {
            datalinkId: datalinkConfigId,
          },
        });
        return getDatalinkConfigDetailResponse.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async executeDataLink(
      dataLinkId: string,
      dataLinkConfigVersionId: string,
      inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
    ): Promise<HttpExecuteDataLinkResponse["payload"]> {
      try {
        const { data } = await httpRequest<HttpExecuteDataLinkRequest, HttpExecuteDataLinkResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: "/datalinks/:datalinkId/:datalinkVersionId/execute",
            routeParams: {
              datalinkId: dataLinkId,
              datalinkVersionId: dataLinkConfigVersionId,
            },
            payload: {
              inputParameters: inputParameters,
            },
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async forgotPassword(
      email: string
    ): Promise<HttpForgotPasswordResponse["payload"] | undefined> {
      const { data } = await httpRequest<HttpForgotPasswordRequest, HttpForgotPasswordResponse>(
        baseUrl,
        {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/login/forgot-password",
          routeParams: {},
          payload: {
            email: email,
          },
          headers: {},
        }
      );

      return data;
    },

    async changePassword(password: string, newPassword: string) {
      try {
        await httpRequest<HttpChangePasswordRequest, HttpChangePasswordResponse>(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/login/change-password",
          routeParams: {},
          payload: {
            password: password,
            newPassword: newPassword,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async impersonateUser(routeParams: HttpImpersonateUserRequest["routeParams"]) {
      try {
        const { data } = await httpRequest<HttpImpersonateUserRequest, HttpImpersonateUserResponse>(
          baseUrl,
          {
            method: "GET",
            multiParts: {},
            queryParameters: {},
            route: "/sysadmin/organization/:organizationId/impersonate-user/:username",
            routeParams: {
              organizationId: routeParams.organizationId,
              username: routeParams.username,
            },
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async adminChangePassword(username: string, password: string) {
      try {
        await httpRequest<HttpAdminChangePasswordRequest, HttpAdminChangePasswordResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: "/users/:username/change-password",
            routeParams: {
              username: username,
            },
            payload: {
              password: password,
            },
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async activateForgotPasswordOTP(email: string, token: string, password: string) {
      try {
        await httpRequest<
          HttpActivateForgotPasswordOTPRequest,
          HttpActivateForgotPasswordOTPResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/login/forgot-password/token/:token",
          routeParams: {
            token,
          },
          payload: {
            email,
            password,
          },
          headers: {},
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async verifyToken(token: string, email: string) {
      try {
        const { data } = await httpRequest<HttpVerifyTokenRequest, HttpVerifyTokenResponse>(
          baseUrl,
          {
            method: "GET",
            multiParts: {},
            queryParameters: {
              email,
            },
            route: "/app/verify-token/:token",
            routeParams: { token: token },
            payload: {},
            headers: {},
          }
        );

        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async verifyPasswordToken(token: string, email: string) {
      try {
        const { data } = await httpRequest<
          HttpVerifyPasswordTokenRequest,
          HttpVerifyPasswordTokenResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            email,
          },
          route: "/app/verify-password-token/:token",
          routeParams: { token: token },
          payload: {},
          headers: {},
        });

        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async activateJoinOrganization(email: string, token: string) {
      try {
        const payload = {
          email: email,
        };

        const { data } = await httpRequest<
          HttpActivateJoinOrganizationRequest,
          HttpActivateJoinOrganizationResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/join-organization/token/:token",
          routeParams: { token: token },
          payload: payload,
          headers: {},
        });

        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async addUserToOrganization(name: string, lastName: string, email: string) {
      try {
        const { data } = await httpRequest<
          HttpAddUserToOrganizationRequest,
          HttpAddUserToOrganizationResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/app/organization/users",
          routeParams: {},
          payload: {
            firstName: name,
            lastName: lastName,
            email: email,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async leaveOrganization(organizationId: string) {
      try {
        const { data } = await httpRequest<
          HttpLeaveOrganizationRequest,
          HttpLeaveOrganizationResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/app/organization/:organizationId/leave",
          routeParams: {
            organizationId: organizationId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async resendAccountActivationInvitation(name: string, lastName: string, email: string) {
      try {
        const { data } = await httpRequest<
          HttpResendAccountActivationInvitationRequest,
          HttpResendAccountActivationInvitationResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/resend/account-activation",
          routeParams: {},
          payload: {
            firstName: name,
            lastName: lastName,
            email: email,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async createOrganization(
      firstName: string,
      lastName: string,
      password: string,
      email: string,
      phone: string,
      organizationId: string,
      organizationName: string,
      language: OrganizationUserLanguage,
      keywords: OrganizationInitBundles[],
      isEnrolledForMarketing: boolean
    ) {
      try {
        const { data } = await httpRequest<
          HttpCreateOrganizationRequest,
          HttpCreateOrganizationResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/organization",
          routeParams: {},
          payload: {
            keywords: keywords,
            techContact: {
              firstName: firstName,
              lastName: lastName,
              email: email.toLocaleLowerCase(),
              phone: phone,
            },
            adminContact: {
              email: email.toLocaleLowerCase(),
              firstName: firstName,
              lastName: lastName,
              phone: phone,
              isEnrolledForMarketing: isEnrolledForMarketing,
            },
            organizationId: organizationId,
            adminPassword: password,
            organizationName: organizationName,
            language: language,
          },
          headers: {},
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async deleteOrganizationPetition(
      reason: string,
      deleteType: DeleteOrganizationAccountType,
      organizationId: string
    ) {
      try {
        const { data } = await httpRequest<
          HttpDeleteOrganizationPetitionRequest,
          HttpDeleteOrganizationPetitionResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/delete-organization-petition",
          routeParams: {},
          payload: {
            deleteType: deleteType,
            reason: reason,
            organizationId: organizationId,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async deleteMyAccountPetition(reason: string, email: string) {
      try {
        const { data } = await httpRequest<
          HttpDeleteMyAccountPetitionRequest,
          HttpDeleteMyAccountPetitionResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/delete-my-account-petition",
          routeParams: {},
          payload: {
            reason: reason,
            email: email,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async isDeletionProcessInitiated() {
      try {
        const { data } = await httpRequest<
          HttpIsOrganizationDeleteProcessStartedRequest,
          HttpIsOrganizationDeleteProcessStartedResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/check-organization-delete-process-started",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async isDeletionAccountProcessInitiated() {
      try {
        const { data } = await httpRequest<
          HttpIsAccountDeleteProcessStartedRequest,
          HttpIsAccountDeleteProcessStartedResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/check-account-delete-process-started",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async loginGetOrganizationList(email: string, password: string) {
      try {
        const { data } = await httpRequest<
          HttpLoginGetOrganizationListRequest,
          HttpLoginGetOrganizationListResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/users/login-organizations",
          routeParams: {},
          payload: {
            username: email,
            password: password,
          },
          headers: {},
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async listUsers(pageNumber, pageLength, nameFilter) {
      try {
        const { data } = await httpRequest<HttpListUsersRequest, HttpListUsersResponse>(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber,
            rowsPerPage: pageLength,
            nameFilter,
          },
          route: "/users",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async listAllOrganizationUsers() {
      try {
        const { data } = await httpRequest<
          HttpListAllOrganizationUsersRequest,
          HttpListAllOrganizationUsersResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/organization-users",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async setUserActivationState(username: string, state: boolean) {
      try {
        await httpRequest<HttpSetUserActivationStateRequest, HttpSetUserActivationStateResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: "/user/:username/set-user-state",
            routeParams: {
              username: username,
            },
            payload: {
              state,
            },
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        return;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentLabels(documentId: string) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentLabelsRequest,
          HttpGetDocumentLabelsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-lists/:documentId",
          routeParams: {
            documentId: documentId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async addDocumentToDocumentList(
      documentId: string,
      listName: string,
      isFavorites: boolean,
      order: number | undefined
    ) {
      try {
        const { data } = await httpRequest<
          HttpSaveDocumentListsRequest,
          HttpSaveDocumentListsResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-lists/:documentId",
          routeParams: {
            documentId,
          },
          payload: {
            isFavorites,
            listName,
            order,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async removeDocumentFromDocumentList(
      documentId: string,
      documentLabel: string,
      isFavorites: boolean
    ) {
      try {
        const { data } = await httpRequest<
          HttpRemoveDocumentLabelRequest,
          HttpRemoveDocumentLabelResponse
        >(baseUrl, {
          method: "DELETE",
          multiParts: {},
          queryParameters: {
            documentLabel: documentLabel,
            isFavorites: isFavorites ? "true" : undefined,
          },
          route: "/app/document-lists/:documentId",
          routeParams: {
            documentId: documentId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async getAllDocumentTypes(): Promise<HttpGetAllDocumentTypesResponse["payload"]> {
      try {
        const { data } = await httpRequest<
          HttpGetAllDocumentTypesRequest,
          HttpGetAllDocumentTypesResponse
        >(baseUrl, {
          method: "GET",
          route: "/app/document-types/all",
          multiParts: {},
          queryParameters: {},
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return {
          documentTypes: data.documentTypes,
          pageNumber: data.pageNumber,
          pageLength: data.pageLength,
          requestedPageLength: data.requestedPageLength,
        };
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async getDocuments(
      documentTypeId,
      updatedBy,
      dateStart,
      dateEnd,
      pageNumber,
      rowsPerPage,
      sortField,
      sortDirection
    ): Promise<HttpGetDocumentsResponse["payload"]> {
      try {
        const { data } = await httpRequest<HttpGetDocumentsRequest, HttpGetDocumentsResponse>(
          baseUrl,
          {
            method: "GET",
            route: "/documents",
            multiParts: {},
            queryParameters: {
              documentTypeIdList: documentTypeId,
              updatedBy: updatedBy,
              dateStart: dateStart,
              dateEnd: dateEnd,
              pageNumber: pageNumber || 0,
              rowsPerPage: rowsPerPage || "15",
              sortField,
              sortDirection,
            },
            routeParams: {},
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return {
          documents: data.documents,
          pageNumber: data.pageNumber,
          pageLength: data.pageLength,
          requestedPageLength: data.requestedPageLength,
        };
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async getDocumentsWithLists(
      documentTypeId,
      documentLists,
      includeFavorites,
      dateStart,
      dateEnd,
      pageNumber,
      rowsPerPage,
      sortField,
      sortDirection
    ): Promise<HttpGetDocumentsWithListsResponse["payload"]> {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentsWithListsRequest,
          HttpGetDocumentsWithListsResponse
        >(baseUrl, {
          method: "GET",
          route: "/app/documents-lists",
          multiParts: {},
          queryParameters: {
            documentTypeIdList: documentTypeId,
            documentLists: documentLists,
            includeFavorites,
            dateStart: dateStart,
            dateEnd: dateEnd,
            pageNumber: pageNumber || 0,
            rowsPerPage: rowsPerPage || "15",
            sortField,
            sortDirection,
          },
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return {
          documents: data.documents,
          pageNumber: data.pageNumber,
          pageLength: data.pageLength,
          requestedPageLength: data.requestedPageLength,
        };
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async generateDocumentsSync(payload) {
      const { data } = await httpRequest<
        HttpAppSyncDocumentGenerationRequest,
        HttpAppSyncDocumentGenerationResponse
      >(baseUrl, {
        method: "POST",
        route: "/app/documents/generate-sync",
        multiParts: {},
        queryParameters: {},
        headers: {
          authorization: `Bearer ${token}`,
        },
        routeParams: {},
        payload,
      });
      const blobsRecord: Record<string, Blob> = {};

      Object.keys(data.contents).forEach((key) => {
        if (key !== "result-report") {
          blobsRecord[key] = base64ToBlob(data.contents[key]);
        }
      });

      return {
        docs: blobsRecord,
        documentId: data.documentId,
        documentVersionId: data.documentVersionId,
      };
    },

    async userActivateOrganization(otp) {
      try {
        const { data } = await httpRequest<
          HttpUserActivateOrganizationRequest,
          HttpUserActivateOrganizationResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/organization/:otp/user-activate",
          routeParams: { otp },
          payload: {},
          headers: {},
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async fetchOrganization() {
      try {
        const { data } = await httpRequest<
          HttpGetCurrentOrganizationRequest,
          HttpGetOrganizationByIdResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/organization",
          routeParams: {},
          payload: {},
          headers: { authorization: `Bearer ${token}` },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async fetchOrganizationAdminInfo() {
      try {
        const { data } = await httpRequest<
          HttpGetOrganizationAdminInfoRequest,
          HttpGetOrganizationAdminInfoResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/organization-admin-info",
          routeParams: {},
          payload: {},
          headers: { authorization: `Bearer ${token}` },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getNotifications(pageNumber: number): Promise<HttpGetNotificationsResponse["payload"]> {
      try {
        const { data } = await httpRequest<
          HttpGetNotificationsRequest,
          HttpGetNotificationsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber,
          },
          route: "/notifications",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async markNotificationAsRead(
      notificationIdList: Array<string>
    ): Promise<HttpMarkNotificationsAsReadResponse["payload"]> {
      try {
        const { data } = await httpRequest<
          HttpMarkNotificationsAsReadRequest,
          HttpMarkNotificationsAsReadResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: `/notifications/mark-as-read`,
          routeParams: {},
          payload: { notificationIdList },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async removeNotifications(
      notificationIdList: Array<string>
    ): Promise<HttpRemoveNotificationsResponse["payload"]> {
      try {
        const { data } = await httpRequest<
          HttpRemoveNotificationsRequest,
          HttpRemoveNotificationsResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: `/notifications/remove`,
          routeParams: {},
          payload: { notificationIdList },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async shareDocument(
      documentId: string,
      documentVersionId: string,
      from: EmailRecipient,
      recipients: EmailRecipient[],
      subject: string,
      body: string
    ): Promise<HttpShareDocumentResponse["payload"]> {
      try {
        const { data } = await httpRequest<HttpShareDocumentRequest, HttpShareDocumentResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: `/app/document/:documentId/:documentVersionId/share`,
            routeParams: { documentId, documentVersionId },
            payload: { recipients, subject, body, from },
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async contactHelp(
      from: EmailRecipient,
      phoneNumber: string,
      body: string
    ): Promise<HttpContactHelpResponse["payload"]> {
      try {
        const { data } = await httpRequest<HttpContactHelpRequest, HttpContactHelpResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: `/app/support/share`,
            routeParams: {},
            payload: { phoneNumber, body, from },
            headers: {},
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    getMyUserProfile: () => getMyUserProfile(),
    async getListOfRecentDocument(recentDocuments: RecentDocumentSearch) {
      try {
        const { data } = await httpRequest<
          HttpGetListOfRecentDocumentRequest,
          HttpGetListOfRecentDocumentResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber: recentDocuments.pageNumber,
            rowsPerPage: recentDocuments.rowsPerPage,
            fromDate: recentDocuments.fromDate?.getTime(),
            toDate: recentDocuments.toDate?.getTime(),
            sort: recentDocuments.sort,
          },
          route: "/app/recent-document",
          routeParams: {},
          payload: {},
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        throw err;
      }
    },

    async getDocumentCollectionInstanceDetails(instanceId) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentCollectionInstanceDetailsRequest,
          HttpGetDocumentCollectionInstanceDetailsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-collection/:instanceId/summary",
          routeParams: {
            instanceId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err: any) {
        console.log(err);
        if (err?.response?.status === 404) {
          return {
            instance: undefined,
          };
        }
        throw err;
      }
    },
    async getActionLogs(search: ActivitySearch) {
      try {
        const { data } = await httpRequest<
          HttpGetActionLogEntriesListRequest,
          HttpGetActionLogEntriesListResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber: search.pageNumber,
            rowsPerPage: search.rowsPerPage,
            fromDate: search.fromDate?.getTime(),
            toDate: search.toDate?.getTime(),
            createdBy: search.createdBy,
            sort: search.sort,
            entryTypes: search.entryTypes.map((e) => {
              return e.code;
            }),
          },
          route: "/action-logs",
          routeParams: {},
          payload: {},
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        throw err;
      }
    },
    async getCollectionActionLogs(search: ActivityCollectionSearch, instanceId: string) {
      try {
        const { data } = await httpRequest<
          HttpGetCollectionActionLogEntriesListRequest,
          HttpGetCollectionActionLogEntriesListResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber: search.pageNumber,
            rowsPerPage: search.rowsPerPage,
            sort: search.sort,
          },
          route: "/app/collection/:instanceId/action-logs",
          routeParams: {
            instanceId: instanceId,
          },
          payload: {},
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        throw err;
      }
    },
    async getHistoryActionLogs(search: ActivityHistorySearch, documentId: string) {
      try {
        const { data } = await httpRequest<
          HttpGetHistoryActionLogEntriesListRequest,
          HttpGetHistoryActionLogEntriesListResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber: search.pageNumber,
            rowsPerPage: search.rowsPerPage,
            sort: search.sort,
          },
          route: "/app/document/:documentId/action-logs",
          routeParams: {
            documentId: documentId,
          },
          payload: {},
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        throw err;
      }
    },
    async getActionEntry(id) {
      try {
        const { data } = await httpRequest<
          HttpGetActionLogEntryRequest,
          HttpGetActionLogEntryResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/action-logs/:id",
          routeParams: {
            id,
          },
          payload: {},
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async registerDocumentView(documentId, documentVersionId) {
      try {
        const { data } = await httpRequest<HttpDocumentViewRequest, HttpDocumentViewResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: "/app/document/:documentId/versions/:documentVersionId/view",
            routeParams: {
              documentId,
              documentVersionId,
            },
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async viewDocumentCollectionInstance(instanceId) {
      try {
        const { data } = await httpRequest<
          HttpDocumentCollectionInstanceViewRequest,
          HttpDocumentCollectionInstanceViewResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-collection/:instanceId/view",
          routeParams: {
            instanceId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async getDocumentCollectionInstanceView(pageNumber, rowsPerPage) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentCollectionInstanceViewRequest,
          HttpGetDocumentCollectionInstanceViewResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber,
            rowsPerPage,
          },
          route: "/app/documents-collection-view",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async getUserPublicProfile(username) {
      try {
        const { data } = await httpRequest<
          HttpUserPublicProfileRequest,
          HttpUserPublicProfileResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/user/public-profile/:username",
          routeParams: {
            username,
          },
          payload: {},
          headers: {
            "content-type": "application-json",
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateOrganizationUser(payload) {
      try {
        await httpRequest<HttpUpdateOrganizationUserRequest, HttpUpdateOrganizationUserResponse>(
          baseUrl,
          {
            payload,
            method: "PUT",
            multiParts: {},
            queryParameters: {},
            route: "/app/organization/users",
            routeParams: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateUserProfile(payload) {
      try {
        await httpRequest<HttpUpdateMyUserProfileRequest, HttpGetMyUserProfileResponse>(baseUrl, {
          payload,
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/app/my-profile",
          routeParams: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentLabelsDocumentsCount(
      documentLabels: string,
      isFavorites: boolean | undefined
    ) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentLabelsDocumentsCountRequest,
          HttpGetDocumentLabelsDocumentsCountResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: { isFavorites },
          route: "/app/documents-labels-counts/:label",
          routeParams: {
            label: documentLabels,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getMyUserProfileDocumentLabels() {
      try {
        const { data } = await httpRequest<
          HttpGetMyUserProfileDocumentListsRequest,
          HttpGetMyUserProfileDocumentListsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/my-profile/document-lists",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getTemplateVersionList(templateVersionId) {
      try {
        const { data } = await httpRequest<
          HttpGetTemplateVersionListRequest,
          HttpGetTemplateVersionListResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/template-versions/:templateVersionId/versions",
          routeParams: {
            templateVersionId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateMyUserProfileDocumentLabels(oldDocumentLabel, newDocumentLabel) {
      try {
        await httpRequest<
          HttpUpdateMyUserProfileDocumentListsRequest,
          HttpUpdateMyUserProfileDocumentListsResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/app/my-profile/document-lists",
          routeParams: {},
          payload: {
            oldDocumentLabel: oldDocumentLabel,
            newDocumentLabel: newDocumentLabel,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return {};
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async deleteAllDocumentsLists(documentList) {
      try {
        await httpRequest<HttpDeleteAllDocumentsListsRequest, HttpDeleteAllDocumentsListsResponse>(
          baseUrl,
          {
            method: "PUT",
            multiParts: {},
            queryParameters: {},
            route: "/app/document-lists/:",
            routeParams: {},
            payload: { documentList: documentList },
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return {};
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async deleteMyUserProfileDocumentLabel(documentLabel) {
      try {
        await httpRequest<
          HttpDeleteMyUserProfileDocumentListsRequest,
          HttpDeleteMyUserProfileDocumentLabelResponse
        >(baseUrl, {
          method: "DELETE",
          multiParts: {},
          queryParameters: { documentLabel },
          route: "/app/my-profile/document-lists",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return {};
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async getAllDocumentGroups(isContentLibrary?: boolean) {
      try {
        const { data } = await httpRequest<
          HttpGetOrganizationDocumentGroupsRequest,
          HttpGetOrganizationDocumentGroupsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: { isContentLibrary: isContentLibrary },
          route: "/document-groups/all",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentGroupList(pageNumber, rowsPerPage, filter?) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentGroupListRequest,
          HttpGetDocumentGroupListResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber,
            rowsPerPage,
            filter,
          },
          route: "/document-groups/",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async saveDocumentGroup(documentGroup: HttpCreateDocumentGroupRequest["payload"]) {
      try {
        const { data } = await httpRequest<
          HttpCreateDocumentGroupRequest,
          HttpCreateDocumentGroupResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/document-groups/",
          routeParams: {},
          payload: documentGroup,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async removeDocumentGroup(documentGroupId: string, newDocumentGroupId: string) {
      try {
        const { data } = await httpRequest<
          HttpRemoveDocumentGroupRequest,
          HttpRemoveDocumentGroupResponse
        >(baseUrl, {
          method: "DELETE",
          multiParts: {},
          queryParameters: { newDocumentGroupId: newDocumentGroupId },
          route: "/document-groups/:documentGroupId",
          routeParams: { documentGroupId: documentGroupId },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentGroup(documentGroupId: string) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentGroupRequest,
          HttpGetDocumentGroupResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/document-group/:documentGroupId",
          routeParams: {
            documentGroupId: documentGroupId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async updateDocumentGroup(documentGroup: {
      documentGroupId: string;
      name: string;
      isContentLibrary: boolean;
      description: string;
      roleToNotify: string | undefined;
      configurationWorkflowId?: string;
      generationWorkflowId?: string;
    }) {
      try {
        const { data } = await httpRequest<
          HttpUpdateDocumentGroupRequest,
          HttpUpdateDocumentGroupResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/document-groups/:documentGroupId",
          routeParams: {
            documentGroupId: documentGroup.documentGroupId,
          },
          payload: {
            name: documentGroup.name,
            isContentLibrary: documentGroup.isContentLibrary,
            description: documentGroup.description,
            roleToNotify: documentGroup.roleToNotify,
            configurationWorkflowId: documentGroup.configurationWorkflowId,
            generationWorkflowId: documentGroup.generationWorkflowId,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentTypeInfo(documentTypeId: string) {
      try {
        const { data } = await httpRequest<HttpGetDocumentTypeRequest, HttpGetDocumentTypeResponse>(
          baseUrl,
          {
            method: "GET",
            multiParts: {},
            queryParameters: {},
            route: "/app/document-types/:documentTypeId",
            routeParams: {
              documentTypeId: documentTypeId,
            },
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentTypeVersionInfo(_documentTypeId: string, documentTypeVersionId: string) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentTypeVersionRequest,
          HttpGetDocumentTypeVersionResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-types/:documentTypeVersionId/version",
          routeParams: {
            documentTypeVersionId: documentTypeVersionId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async search(
      fieldsConditions,
      pageNumber: number,
      rowsPerPage: "15" | "50" | "100",
      query: string,
      documentTypeIds,
      sortDirection,
      sortField,
      includeApproximateResults
    ) {
      try {
        const { data } = await httpRequest<HttpSearchRequest, HttpSearchResponse>(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {
            query,
            pageNumber,
            rowsPerPage,
          },
          route: `/search`,
          routeParams: {},
          payload: {
            fields: fieldsConditions,
            documentTypeIds,
            sortDirection,
            sortField,
            includeApproximateResults,
            dateFrom: undefined,
            dateTo: undefined,
            username: undefined,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async searchSuggestions(fieldsConditions, query: string, documentTypeIds) {
      try {
        const { data } = await httpRequest<
          HttpSearchSuggestionsRequest,
          HttpSearchSuggestionsResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {
            query,
          },
          route: `/search-suggestions`,
          routeParams: {},
          payload: {
            fields: fieldsConditions,
            documentTypeIds,
            sortDirection: undefined,
            sortField: undefined,

            includeApproximateResults: false,
            dateFrom: undefined,
            dateTo: undefined,
            username: undefined,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentTypeList(pageNumber, rowsPerPage, filter, documentGroupId) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentTypeListRequest,
          HttpGetDocumentTypeListResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            filter,
            documentGroupId,
            pageNumber,
            rowsPerPage,
          },
          route: "/document-types/",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async saveDocumentType(
      documentType: SaveDocumentType
    ): Promise<HttpPostDocumentTypeResponse["payload"]> {
      try {
        const { data } = await httpRequest<
          HttpPostDocumentTypeRequest,
          HttpPostDocumentTypeResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/document-types/",
          routeParams: {},
          payload: documentType,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentByIdentifier(
      documentTypeId: string,
      documentIdentifier: string
    ): Promise<
      | HttpGetDocumentByIdentifierResponse["payload"]
      | { documentId: undefined; documentVersionId: undefined; data: undefined }
    > {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentByIdentifierRequest,
          HttpGetDocumentByIdentifierResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/documents/:documentTypeId/identifier/:documentIdentifier",
          routeParams: {
            documentTypeId,
            documentIdentifier,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err: any) {
        if (err?.response?.status === 404) {
          return {
            documentId: undefined,
            documentVersionId: undefined,
            data: undefined,
          };
        }

        console.log(err);
        throw err;
      }
    },
    async getDocument(
      documentId: string,
      documentVersionId: string
    ): Promise<HttpGetDocumentResponse["payload"] | { document: undefined }> {
      try {
        const { data } = await httpRequest<HttpGetDocumentRequest, HttpGetDocumentResponse>(
          baseUrl,
          {
            method: "GET",
            multiParts: {},
            queryParameters: {},
            route: "/documents/:documentId/versions/:documentVersionId",
            routeParams: {
              documentId: documentId,
              documentVersionId: documentVersionId,
            },
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        // HACK: Sanitize movement. Please remove when done
        if (data && data.document && data.document.data) {
          data.document.data = fixContextPayload(data.document.data, false);
        }

        return data;
      } catch (err: any) {
        if (err?.response?.status === 404 || err?.response?.status === 400) {
          return {
            document: undefined,
          };
        }

        console.log(err);
        throw err;
      }
    },
    async getWordAsPdf(
      wordDataUri: string,
      documentVersionId: string | undefined
    ): Promise<HttpGetWordAsPdfResponse["payload"] | { pdfDataUri: undefined }> {
      try {
        const { data } = await httpRequest<HttpGetWordAsPdfRequest, HttpGetWordAsPdfResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {
              documentVersionId,
            },
            route: "/app/convert-word-to-pdf",
            routeParams: {},
            payload: { wordDataUri },
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err: any) {
        if (err?.response?.status === 404 || err?.response?.status === 400) {
          return {
            pdfDataUri: undefined,
          };
        }

        console.log(err);
        throw err;
      }
    },
    async getLastDocument(
      documentId: string
    ): Promise<HttpGetDocumentResponse["payload"] | { document: undefined }> {
      try {
        const { data } = await httpRequest<HttpGetLastDocumentRequest, HttpGetDocumentResponse>(
          baseUrl,
          {
            method: "GET",
            multiParts: {},
            queryParameters: {},
            route: "/documents/:documentId",
            routeParams: {
              documentId: documentId,
            },
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        // HACK: Sanitize movement. Please remove when done
        if (data && data.document && data.document.data) {
          data.document.data = fixContextPayload(data.document.data, false);
        }

        return data;
      } catch (err: any) {
        if (err?.response?.status === 404) {
          return {
            document: undefined,
          };
        }

        throw err;
      }
    },
    async getDocumentVersions(
      documentId: string
    ): Promise<HttpGetDocumentVersionsResponse["payload"]> {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentVersionsRequest,
          HttpGetDocumentVersionsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/documents/:documentId/versions-list",
          routeParams: {
            documentId: documentId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentHistory(
      documentId: string,
      rowsPerPage,
      pageNumber
    ): Promise<HttpGetDocumentHistoryResponse["payload"]> {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentHistoryRequest,
          HttpGetDocumentHistoryResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage,
          },
          route: "/app/documents/:documentId/history",
          routeParams: {
            documentId: documentId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentVersionGenerationContext(
      documentId: string,
      documentVersionId: string
    ): Promise<HttpGetDocumentVersionGenerationContextResponse["payload"]> {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentVersionGenerationContextRequest,
          HttpGetDocumentVersionGenerationContextResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-version-generation-context/:documentId/versions/:documentVersionId",
          routeParams: {
            documentId: documentId,
            documentVersionId: documentVersionId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentTypesMergedContext(documentTypeIdList: Array<string>) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentTypesMergedContextRequest,
          HttpGetDocumentTypesMergedContextResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            documentTypeIdList,
          },
          route: "/document-types-merged-context",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentCollectionFolderMergedContext(
      masterDocumentTypeId: string,
      documentTypeIdList: Array<string>
    ) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentCollectionFolderMergedContextRequest,
          HttpGetDocumentCollectionFolderMergedContextResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            masterDocumentTypeId,
            documentTypeIdList,
          },
          route: "/app/document-collection-folder-merged-context",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async intersectionContextFields(documentTypeIds: Array<string>) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentTypesIntersectionContextRequest,
          HttpGetDocumentTypesIntersectionContextResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            documentTypeIds,
          },
          route: "/document-types-intersection-context",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async getDocumentTypesWithCommonFields(dataElements) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentTypesWithCommonFieldsRequest,
          HttpGetDocumentTypesWithCommonFieldsResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/document-types/with-common-fields",
          routeParams: {},
          payload: { dataElements },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async saveDocument(
      document: HttpSaveDocumentRequest["payload"]
    ): Promise<HttpSaveDocumentResponse["payload"]> {
      try {
        const { data } = await httpRequest<HttpSaveDocumentRequest, HttpSaveDocumentResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: "/app/documents",
            routeParams: {},
            payload: document,
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async restoreDocumentVersion(
      documentId: HttpRestoreDocumentVersionRequest["routeParams"]["documentId"],
      documentVersionId: HttpRestoreDocumentVersionRequest["routeParams"]["documentVersionId"]
    ): Promise<HttpRestoreDocumentVersionResponse["payload"]> {
      try {
        const { data } = await httpRequest<
          HttpRestoreDocumentVersionRequest,
          HttpRestoreDocumentVersionResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/app/documents/:documentId/restore-document-version/:documentVersionId",
          routeParams: { documentId, documentVersionId },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async deleteDocument(documentId: string, documentVersionId: string | undefined) {
      try {
        const { data } = await httpRequest<HttpDeleteDocumentRequest, HttpDeleteDocumentResponse>(
          baseUrl,
          {
            method: "DELETE",
            multiParts: {},
            queryParameters: { documentVersionId },
            route: "/app/documents/:documentId",
            routeParams: { documentId },
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async restoreDeletedDocument(payload) {
      try {
        const { data } = await httpRequest<HttpRestoreDocumentRequest, HttpRestoreDocumentResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: "/app/deleted-documents/restore",
            routeParams: {},
            payload: { deletedDocumentList: payload },
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async hardDeleteDocument(payload) {
      try {
        const { data } = await httpRequest<
          HttpHardDeleteDocumentRequest,
          HttpHardDeleteDocumentResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/app/deleted-documents/hard-delete",
          routeParams: {},
          payload: { deletedDocumentList: payload },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async getAllOrganizationRoles() {
      try {
        const { data } = await httpRequest<
          HttpGetAllOrganizationRolesRequest,
          HttpGetAllOrganizationRolesResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/all-roles",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getOrganizationRoles(pageNumber, rowsPerPage, nameFilter) {
      try {
        const { data } = await httpRequest<
          HttpGetOrganizationRolesRequest,
          HttpGetOrganizationRolesResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: { pageNumber, rowsPerPage, nameFilter },
          route: "/app/roles",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getOrganizationRole(roleId) {
      try {
        const { data } = await httpRequest<
          HttpGetOrganizationRoleRequest,
          HttpGetOrganizationRoleResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/roles/:roleId",
          routeParams: { roleId },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async createOrganizationRole(payload) {
      try {
        const { data } = await httpRequest<
          HttpCreateOrganizationRoleRequest,
          HttpCreateOrganizationRoleResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/app/roles",
          routeParams: {},
          payload: payload,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateOrganizationRole(params) {
      try {
        const { data } = await httpRequest<
          HttpUpdateOrganizationRoleRequest,
          HttpUpdateOrganizationRoleResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/app/roles/:roleId",
          routeParams: { roleId: params.roleId },
          payload: {
            name: params.name,
            users: params.users,
            allowOnlyOwnedDocuments: params.allowOnlyOwnedDocuments,
            globalPermissions: params.globalPermissions,
            documentTypePermissions: params.documentTypePermissions,
            templatePermissions: params.templatePermissions,
            collectionPermissions: params.collectionPermissions,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async deleteOrganizationRole(roleId) {
      try {
        const { data } = await httpRequest<
          HttpDeleteOrganizationRoleRequest,
          HttpDeleteOrganizationRoleResponse
        >(baseUrl, {
          method: "DELETE",
          multiParts: {},
          queryParameters: {},
          route: "/app/roles/:roleId",
          routeParams: { roleId: roleId },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async createOrganizationGraphicMark(payload) {
      try {
        const { data } = await httpRequest<
          HttpCreateOrganizationGraphicMarkRequest,
          HttpCreateOrganizationGraphicMarkResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/app/signatures/graphic-mark",
          routeParams: {},
          payload: payload,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateOrganizationGraphicMark(params) {
      try {
        const { data } = await httpRequest<
          HttpUpdateOrganizationGraphicMarkRequest,
          HttpUpdateOrganizationGraphicMarkResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/app/signatures/graphic-mark/:graphicMarkId",
          routeParams: { graphicMarkId: params.graphicMarkId },
          payload: {
            name: params.name,
            imageDataURI: params.imageDataURI,
            customMessage: params.customMessage,
            date: params.date,
            distinguishedName: params.distinguishedName,
            email: params.email,
            logo: params.logo,
            url: params.url,
            waterMark: params.waterMark,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async deleteOrganizationGraphicMark(graphicMarkId) {
      try {
        const { data } = await httpRequest<
          HttpDeleteOrganizationGraphicMarkRequest,
          HttpDeleteOrganizationGraphicMarkResponse
        >(baseUrl, {
          method: "DELETE",
          multiParts: {},
          queryParameters: {},
          route: "/app/signatures/graphic-mark/:graphicMarkId",
          routeParams: { graphicMarkId: graphicMarkId },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getOrganizationGraphicMarks(rowsPerPage, pageNumber, nameFilter) {
      try {
        const { data } = await httpRequest<
          HttpGetOrganizationGraphicMarksRequest,
          HttpGetOrganizationGraphicMarksResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            rowsPerPage: rowsPerPage,
            pageNumber: pageNumber,
            nameFilter: nameFilter,
          },
          route: "/app/signatures/graphic-mark/",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async allOrganizationGraphicMarks() {
      try {
        const { data } = await httpRequest<
          HttpAllGetOrganizationGraphicMarkRequest,
          HttpAllGetOrganizationGraphicMarkResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/signatures/graphic-mark/all",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getOrganizationGraphicMark(graphicMarkId) {
      try {
        const { data } = await httpRequest<
          HttpGetOrganizationGraphicMarkRequest,
          HttpGetOrganizationGraphicMarkResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/signatures/graphic-mark/:graphicMarkId",
          routeParams: {
            graphicMarkId: graphicMarkId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getOrganizationUser(username) {
      try {
        const { data } = await httpRequest<
          HttpGetOrganizationUserRequest,
          HttpGetOrganizationUserResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/organization/users/:username",
          routeParams: {
            username,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getUserTemplatesSubscriptions(pageNumber, rowsPerPage) {
      try {
        const { data } = await httpRequest<
          HttpGetUserTemplatesSubscriptionsRequest,
          HttpGetUserTemplatesSubscriptionsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber: pageNumber || 0,
            rowsPerPage: rowsPerPage || "15",
          },
          route: "/app/templates/my-subscriptions",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getUserDocumentTypeSubscriptions(pageNumber, rowsPerPage) {
      try {
        const { data } = await httpRequest<
          HttpGetUserDocumentTypeSubscriptionsRequest,
          HttpGetUserDocumentTypeSubscriptionsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber: pageNumber || 0,
            rowsPerPage: rowsPerPage || "15",
          },
          route: "/app/document-types/get-subscriptions",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateDocumentTypeSubscriptions(payload) {
      try {
        await httpRequest<
          HttpUpdateDocumentTypeSubscriptionsRequest,
          HttpUpdateDocumentTypeSubscriptionsResponse
        >(baseUrl, {
          payload,
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-types/update-subscriptions",
          routeParams: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateTemplateSubscription(payload) {
      try {
        await httpRequest<
          HttpUpdateTemplateSubscriptionRequest,
          HttpUpdateTemplateSubscriptionResponse
        >(baseUrl, {
          payload,
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/app/templates/update-template-subscription",
          routeParams: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getGenerationStatus(generationToken, pageNumber, rowsPerPage) {
      const { data } = await httpRequest<
        HttpDocumentGenerationStatusRequest,
        HttpDocumentGenerationStatusResponse
      >(baseUrl, {
        method: "GET",
        multiParts: {},
        queryParameters: {
          pageNumber: pageNumber,
          rowsPerPage: rowsPerPage,
        },
        route: "/generation/:generationToken/status",
        routeParams: {
          generationToken: generationToken,
        },
        payload: {},
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    async getGenerationStatusDocument(generationToken, documentKey, fileKey) {
      const { data } = await httpRequest<
        HttpGetGenerationStatusDocumentRequest,
        HttpGetGenerationStatusDocumentResponse
      >(baseUrl, {
        method: "GET",
        multiParts: {},
        queryParameters: {},
        route: "/generation/:generationToken/documents/:documentKey/file/:fileKey",
        routeParams: { generationToken, documentKey, fileKey },
        payload: {},
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    async getGenerations(search: GenerationSearch) {
      try {
        const { data } = await httpRequest<
          HttpDocumentGenerationListRequest,
          HttpDocumentGenerationListResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber: search.pageNumber,
            rowsPerPage: search.rowsPerPage,
            fromDate: search.fromDate?.getTime(),
            toDate: search.toDate?.getTime(),
            createdBy: search.createdBy,
            sort: search.sort,
          },
          route: "/generations",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getTemplateContextSampleRequest(templateVersionIds: string[]) {
      try {
        const { data } = await httpRequest<
          HttpAppGetTemplateContextSampleRequest,
          HttpGetTemplateContextSampleResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/app/templates/sample-request/",
          routeParams: {},
          payload: { templateVersionIds },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getPaginatedOrganizationDataElements(
      pageNumber,
      rowsPerPage,
      filter,
      resultsAreClassDataElements
    ) {
      try {
        const { data } = await httpRequest<
          HttpGetPaginatedOrganizationDataElementsRequest,
          HttpGetPaginatedOrganizationDataElementsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber,
            rowsPerPage,
            filter,
            resultsAreClassDataElements,
          },
          route: "/data-elements/",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getPaginatedOrganizationDictionaryLists(pageNumber, rowsPerPage, filter?) {
      try {
        const { data } = await httpRequest<
          HttpGetPaginatedOrganizationDictionaryListsRequest,
          HttpGetPaginatedOrganizationDictionaryListsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber,
            rowsPerPage,
            filter,
          },
          route: "/dictionary-lists/",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDataElement(dataElementName: string) {
      try {
        const { data } = await httpRequest<HttpGetDataElementRequest, HttpGetDataElementResponse>(
          baseUrl,
          {
            method: "GET",
            multiParts: {},
            queryParameters: {},
            route: "/data-elements/:dataElementName",
            routeParams: { dataElementName },
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err: any) {
        if (err?.response?.status === 404) {
          return {
            dataElement: undefined,
          };
        }

        console.log(err);
        throw err;
      }
    },
    async saveDataElement(dataElement: HttpSaveDataElementRequest["payload"]) {
      try {
        const { data } = await httpRequest<HttpSaveDataElementRequest, HttpSaveDataElementResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: "/data-elements/",
            routeParams: {},
            payload: dataElement,
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateDataElement(dataElement: HttpUpdateDataElementRequest["payload"]) {
      try {
        const { data } = await httpRequest<
          HttpUpdateDataElementRequest,
          HttpUpdateDataElementResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/data-elements/",
          routeParams: {},
          payload: dataElement,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async deleteDataElement(dataElementName: string) {
      try {
        const { data } = await httpRequest<
          HttpDeleteDataElementRequest,
          HttpDeleteDataElementResponse
        >(baseUrl, {
          method: "DELETE",
          multiParts: {},
          queryParameters: {},
          route: "/data-elements/:dataElementName",
          routeParams: { dataElementName },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDictionaryList(dictionaryListName: string) {
      try {
        const { data } = await httpRequest<
          HttpGetDictionaryListRequest,
          HttpGetDictionaryListResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/dictionary-lists/:dictionaryListName",
          routeParams: { dictionaryListName },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getOutputFormats() {
      try {
        const { data } = await httpRequest<
          HttpGetOutputFormatsRequest,
          HttpGetOutputFormatsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/templates/output-formats",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async createDictionaryList(dictionaryList: HttpCreateDictionaryListRequest["payload"]) {
      try {
        const { data } = await httpRequest<
          HttpCreateDictionaryListRequest,
          HttpCreateDictionaryListResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/dictionary-lists/",
          routeParams: {},
          payload: dictionaryList,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateDictionaryList(dictionaryList: HttpUpdateDictionaryListRequest["payload"]) {
      try {
        const { data } = await httpRequest<
          HttpUpdateDictionaryListRequest,
          HttpUpdateDictionaryListResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/dictionary-lists/",
          routeParams: {},
          payload: dictionaryList,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getInputMasks() {
      try {
        const { data } = await httpRequest<HttpGetInputMasksRequest, HttpGetInputMasksResponse>(
          baseUrl,
          {
            method: "GET",
            multiParts: {},
            queryParameters: {},
            route: "/templates/input-masks",
            routeParams: {},
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async deleteDictionaryList(dictionaryListName: string) {
      try {
        const { data } = await httpRequest<
          HttpDeleteDictionaryListRequest,
          HttpDeleteDictionaryListResponse
        >(baseUrl, {
          method: "DELETE",
          multiParts: {},
          queryParameters: {},
          route: "/dictionary-lists/:dictionaryListName",
          routeParams: { dictionaryListName },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentByGenerationToken(params: { generationToken: string; documentId: string }) {
      const { generationToken, documentId } = params;
      const { data } = await httpRequest<
        HttpGetDocumentByGenerationTokenRequest,
        HttpGetDocumentResponse
      >(baseUrl, {
        method: "GET",
        multiParts: {},
        queryParameters: {},
        route: "/documents-by-generation-token/:generationToken/document/:documentId",
        routeParams: { generationToken, documentId },
        payload: {},
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    async getDocumentsByGenerationToken(params: { generationToken: string }) {
      const { generationToken } = params;
      const { data } = await httpRequest<
        HttpGetDocumentsByGenerationTokenRequest,
        HttpGetDocumentsByGenerationTokenResponse
      >(baseUrl, {
        method: "GET",
        multiParts: {},
        queryParameters: {
          pageNumber: 0,
          rowsPerPage: "100",
        },
        route: "/documents-by-generation-token/:generationToken",
        routeParams: { generationToken },
        payload: {},
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    async getDistributionImplementations() {
      const { data } = await httpRequest<
        HttpGetDistributionImplementationsRequest,
        HttpGetDistributionImplementationsResponse
      >(baseUrl, {
        method: "GET",
        multiParts: {},
        queryParameters: {},
        route: "/distributions/implementations",
        routeParams: {},
        payload: {},
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    async getDistributions(pageNumber) {
      const { data } = await httpRequest<HttpGetDistributionsRequest, HttpGetDistributionsResponse>(
        baseUrl,
        {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber,
          },
          route: "/distributions",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
    async getDistribution(id: string) {
      const { data } = await httpRequest<
        HttpGetDistributionDetailsRequest,
        HttpGetDistributionResponse
      >(baseUrl, {
        method: "GET",
        multiParts: {},
        queryParameters: {},
        route: "/distributions/:distributionId",
        routeParams: { distributionId: id },
        payload: {},
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    async saveDistribution(config) {
      try {
        const { data } = await httpRequest<
          HttpSaveDistributionRequest,
          HttpSaveDistributionResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: `/distributions`,
          routeParams: {},
          payload: {
            distributionId: config.distributionId,
            implementationId: config.implementationId,
            inputParameters: config.inputParameters,
            label: config.label,
            name: config.name,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async disableDistribution(distributionId) {
      try {
        const { data } = await httpRequest<
          HttpDisableDistributionRequest,
          HttpDisableDistributionResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: `/app/distributions/:distributionId:/disable`,
          routeParams: { distributionId },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async deleteDistribution(distributionId) {
      try {
        const { data } = await httpRequest<
          HttpDeleteDistributionRequest,
          HttpDeleteDistributionResponse
        >(baseUrl, {
          method: "DELETE",
          multiParts: {},
          queryParameters: {},
          route: `/distributions/:distributionId`,
          routeParams: { distributionId },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async getDocumentCollectionConfigList(pageNumber, rowsPerPage, nameFilter) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentCollectionConfigListRequest,
          HttpGetDocumentCollectionConfigListResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: { pageNumber, rowsPerPage, nameFilter },
          route: `/app/document-collection-config`,
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentCollectionConfig(id) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentCollectionConfigRequest,
          HttpGetDocumentCollectionConfigResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-collection-config/:id",
          routeParams: {
            id,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async createDocumentCollectionConfig(config) {
      try {
        const { data } = await httpRequest<
          HttpCreateDocumentCollectionConfigRequest,
          HttpCreateDocumentCollectionConfigResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-collection-config",
          routeParams: {},
          payload: {
            config,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateDocumentCollectionConfig(params) {
      try {
        const { data } = await httpRequest<
          HttpUpdateDocumentCollectionConfigRequest,
          HttpUpdateDocumentCollectionConfigResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-collection-config/:id",
          routeParams: { id: params.id },
          payload: {
            config: params.config,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getCollectionConfigAffectedEntities(id) {
      try {
        const { data } = await httpRequest<
          HttpGetCollectionConfigAffectedEntitiesRequest,
          HttpGetCollectionConfigAffectedEntitiesResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-collection-config/:id/affected-entities",
          routeParams: { id },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async deleteDocumentCollectionConfig(id) {
      try {
        const { data } = await httpRequest<
          HttpDeleteDocumentCollectionConfigRequest,
          HttpDeleteDocumentCollectionConfigResponse
        >(baseUrl, {
          method: "DELETE",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-collection-config/:id",
          routeParams: { id },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentCollectionInstanceList(pageNumber, rowsPerPage, nameFilter, assignedTo) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentCollectionInstanceListRequest,
          HttpGetDocumentCollectionInstanceListResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber,
            rowsPerPage,
            nameFilter,
            assignedTo: assignedTo,
          },
          route: "/app/document-collection",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getBundleConfigList(pageNumber, rowsPerPage, languageFilter, nameFilter) {
      try {
        const { data } = await httpRequest<
          HttpGetBundleConfigListRequest,
          HttpGetBundleConfigListResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {
            pageNumber,
            rowsPerPage,
            languageFilter,
            nameFilter,
          },
          route: "/app/bundles-config",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getBundleConfig(identifier) {
      try {
        const { data } = await httpRequest<HttpGetBundleConfigRequest, HttpGetBundleConfigResponse>(
          baseUrl,
          {
            method: "GET",
            multiParts: {},
            queryParameters: {},
            route: "/app/bundles-config/:identifier",
            routeParams: { identifier },
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async postApplyBundle(params) {
      try {
        const { data } = await httpRequest<HttpApplyBundleRequest, HttpApplyBundleResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: "/app/bundles-config/:identifier/apply",
            routeParams: {
              identifier: params.identifier,
            },
            payload: {
              inputData: params.inputData,
            },
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDocumentCollection(id) {
      try {
        const { data } = await httpRequest<
          HttpGetDocumentCollectionInstanceRequest,
          HttpGetDocumentCollectionInstanceResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-collection/:id",
          routeParams: { id },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err: any) {
        if (err.response.status === 404) {
          return { collection: undefined, keys: [] };
        }
        console.log(err);
        throw err;
      }
    },
    async updateDocumentCollectionAssigned(id, assignedTo) {
      try {
        const { data } = await httpRequest<
          HttpReassignDocumentCollectionInstanceRequest,
          HttpReassignDocumentCollectionInstanceResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/app/document-collection/:id/reassign",
          routeParams: { id },
          payload: {
            assignedTo: assignedTo,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err: any) {
        if (err.response.status === 404) {
          return { collection: undefined };
        }
        console.log(err);
        throw err;
      }
    },
    async updateTemplateDefinition(templateVersionId, payload) {
      try {
        const { data } = await httpRequest<HttpUpdateTemplateRequest, HttpUpdateTemplateResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: `/app/templates/:templateVersionId/update`,
            routeParams: { templateVersionId },
            payload,
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getWorkflowTaskDetails(workflowTaskId) {
      throw new Error(`Error retrieving info for task ${workflowTaskId}: method not implemented`);
    },
    async getUserWorkflowTasks(username) {
      throw new Error(`Error retrieving info for user ${username}: method not implemented`);
    },
    async getWorkflowProfiles() {
      throw new Error(`Error retrieving workflow profiles: method not implemented`);
    },
    async getWorkflowTaskList() {
      throw new Error(`Error retrieving workflow task list: method not implemented`);
    },
    async createTemplateContextState(contextInfo: TemplateContextHeader, context) {
      try {
        const { data } = await httpRequest<
          HttpCreateTemplateContextStateRequest,
          HttpCreateTemplateContextStateResponse
        >(baseUrl, {
          method: "POST",
          multiParts: {},
          queryParameters: {},
          route: "/app/task-template-context-state",
          routeParams: {},
          payload: {
            ...contextInfo,
            context,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getTemplateContextState(taskId) {
      try {
        const { data } = await httpRequest<
          HttpGetTemplateContextStateRequest,
          HttpGetTemplateContextStateResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/task-template-context-state/:id",
          routeParams: {
            id: taskId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async saveTemplateContextState(taskId, context) {
      try {
        const { data } = await httpRequest<
          HttpSaveTemplateContextStateRequest,
          HttpSaveTemplateContextStateResponse
        >(baseUrl, {
          method: "PUT",
          multiParts: {},
          queryParameters: {},
          route: "/app/task-template-context-state/:id",
          routeParams: {
            id: taskId,
          },
          payload: {
            context,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async deleteTemplateContextState(taskId) {
      try {
        const { data } = await httpRequest<
          HttpDeleteTemplateContextStateRequest,
          HttpDeleteTemplateContextStateResponse
        >(baseUrl, {
          method: "DELETE",
          multiParts: {},
          queryParameters: {},
          route: "/app/task-template-context-state/:id",
          routeParams: {
            id: taskId,
          },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getDeferredDistributionList(pageNumber, rowsPerPage) {
      try {
        const { data } = await httpRequest<
          HttpGetDeferredDistributionListRequest,
          HttpGetDeferredDistributionListResponse
        >(baseUrl, {
          method: "GET",
          headers: {
            authorization: `Bearer ${token}`,
          },
          multiParts: {},
          payload: {},
          queryParameters: {
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage,
          },
          route: "/distributions/deferred",
          routeParams: {},
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getTasks(pageNumber, rowsPerPage, ignoreUserFilter) {
      try {
        const { data } = await httpRequest<HttpGetTaskListRequest, HttpGetTaskListResponse>(
          baseUrl,
          {
            method: "GET",
            multiParts: {},
            queryParameters: {
              pageNumber,
              rowsPerPage,
              ignoreUserFilter,
            },
            route: "/app/tasks",
            routeParams: {},
            payload: {},
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getTask(id) {
      try {
        const { data } = await httpRequest<HttpGetTaskRequest, HttpGetTaskResponse>(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/app/tasks/:id",
          routeParams: { id },
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async reassignTasks(
      taskIdList: Array<string>,
      username: string
    ): Promise<HttpReassignTasksResponse["payload"]> {
      try {
        const { data } = await httpRequest<HttpReassignTasksRequest, HttpReassignTasksResponse>(
          baseUrl,
          {
            method: "POST",
            multiParts: {},
            queryParameters: {},
            route: `/tasks/reassign`,
            routeParams: {},
            payload: { taskIdList, username },
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        return data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async getOrganizationDocumentCollectionStats() {
      try {
        const { data } = await httpRequest<
          HttpGetOrganizationDocumentCollectionStatsRequest,
          HttpGetOrganizationDocumentCollectionStatsResponse
        >(baseUrl, {
          method: "GET",
          multiParts: {},
          queryParameters: {},
          route: "/organization/stats/collection",
          routeParams: {},
          payload: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return data.payload;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
  };
}
