import {
  Box,
  Button,
  ComboBox,
  ComboBoxTags,
  DataElementInput,
  DeviceTypeProvider,
  Divider,
  Layer,
  Popover,
  PubSubEventManager,
  Sheet,
  StringTemplateBuilder,
  Switch,
  Text,
  TextField,
  TrashSvgIcon,
  VariablePartSheet,
  overlayPanelZIndex,
  popupZIndex,
  useColors,
} from "@prodoctivity/design-system";
import type {
  DataElement,
  StringTemplatePart,
  TemplateContextDefinition,
  TemplateSection,
  TemplateVersionContextMapping,
  TemplateWizardDefinition,
  TemplateWizardField,
} from "@prodoctivity/shared/src/index-types";
import { Dispatch, FunctionComponent, SetStateAction, useCallback } from "react";
import { DraggableBox, DraggableBoxProps } from "../../../../components/DraggableBox";

import { BoxWithRef } from "@prodoctivity/design-system/components/Box";
import { NavigationButtons } from "@prodoctivity/prodoctivity-form-v5";
import { getContextField } from "@prodoctivity/shared";
import { TemplateEditWizardEvent } from "../../../../components/TemplateEditWizard/hooks";
import { EditSvgIcon } from "../../../../svg/EditSvgIcon";
import { noop } from "../../../../utils";
import { DataLinkSettingSheet } from "./DesignerSheet/DataLinkSettingSheet";
import { FieldRelevanceSheet } from "./DesignerSheet/FieldRelevanceSheet";
import { SidePanel } from "./SidePanel";
import { useFormDesigner } from "./hooks";

export type Option = { value: string; subtext?: string; label: string };
type Props = {
  dataElements: Array<DataElement>;
  dataLinkMappings?: TemplateVersionContextMapping["datalinks"];
  nameConfig: StringTemplatePart[];
  updateNameConfig(nameConfig: StringTemplatePart[]): void;
  identifierCollisionForcesNewVersion: boolean;
  setIdentifierCollisionForcesNewVersion: (value: boolean) => void;
  identifierConfig: StringTemplatePart[] | undefined;
  setIdentifierConfig: (nameConfig: StringTemplatePart[]) => void;
  contextDefinition: TemplateContextDefinition;
  wizardDefinition?: TemplateWizardDefinition;
  onWizardDefinitionChange: (wizardDefinition: TemplateWizardDefinition) => void;
  i18n: (key: string) => string;
  onCancel(): void;
  formDesignerEventManager: PubSubEventManager<TemplateEditWizardEvent>;
  mode: "edit-document-type" | undefined;
  isContinueButtonDisabled: boolean;
  setIsContinueButtonDisabled: Dispatch<SetStateAction<boolean>>;
};

export const FormDesigner: FunctionComponent<Props> = ({
  dataElements,
  nameConfig,
  updateNameConfig,
  identifierCollisionForcesNewVersion,
  setIdentifierCollisionForcesNewVersion,
  identifierConfig,
  setIdentifierConfig,
  contextDefinition,
  wizardDefinition,
  onWizardDefinitionChange,
  dataLinkMappings,
  i18n,
  onCancel,
  formDesignerEventManager,
  mode,
  isContinueButtonDisabled,
  setIsContinueButtonDisabled,
}) => {
  const { colors } = useColors();
  const {
    resources,
    moment,
    setState,

    advancedMode,
    anchorRefItems,

    allSections,

    identifierConfigOptions,
    identifierConfigsSelected,
    onSelectIdentifierConfig,

    addNewSection,
    setEditableSection,
    getContextValueAsString,
    toColumnControlSize,
    moveAllToAnotherSection,
    moveFieldBetweenSections,
    moveItemInSectionByKey,
    moveFieldToSection,
    moveSection,
    updateControlSize,
    handleOnKeyDown,
    setWizardDefinition,
    onDeleteSectionClick,
    sectionIsDuplicated,
    originalSectionLabel,
    pages,
    sectionDeleteActive,

    sectionLabelEdited,
    showDataLinkSheet,
    showDistributionSheet,
    showFieldRelevanceSheet,

    eventManager,
    clickedPart,
    currentVariable,
    rawSetCurrentVariableValue,
    initialState,
    showVariablePartSheet,
    getFieldDataType,
    variableList,
    setShowVariablePartSheet,
    onCloseSheet,
    onShowSheet,
    handlePartClicked,
    handleVariableAdded,
    getFieldLabel,
    getNewVariableData,
    advancedModeChecked,
  } = useFormDesigner({
    nameConfig,
    dataElements,
    identifierConfig,
    setIdentifierConfig,
    contextDefinition,
    wizardDefinition,
    onWizardDefinitionChange,
    updateNameConfig,
    isContinueButtonDisabled,
    setIsContinueButtonDisabled,
  });

  const drawFieldDraggable = useCallback(
    (
      section: TemplateSection,
      field: TemplateWizardField
    ): DraggableBoxProps["itemsToDrag"][number] => {
      if (!field.isRecord) {
        const contextField = getContextField(contextDefinition, field.key);
        const column = toColumnControlSize(field.properties?.controlSize || "Large");

        return {
          key: field.key,
          column: column,
          type: "field",
          content: (
            <Box
              data-id={field.key}
              // padding={2}
              paddingX={2}
              width={"100%"}
              display="flex"
              minHeight={65}
              // marginBottom={4}
              dangerouslySetInlineStyle={{
                __style: {
                  cursor: "move",
                },
              }}
            >
              <Box width="100%">
                {contextField && (
                  <DataElementInput
                    componentBreakpoint="small"
                    instanceFullPath={contextField.fullPath}
                    contextField={contextField}
                    disabled={true}
                    typeValue={{
                      dataType: contextField.properties.dataType,
                      value: undefined,
                    }}
                    moment={moment}
                    onChange={noop}
                    getContextValueAsString={getContextValueAsString}
                    resources={resources}
                    purpose="edit-template"
                  />
                )}

                {advancedMode && (
                  <Box column={1} display="flex">
                    <Box marginEnd={2}>
                      <Button
                        color={field.properties?.controlSize === "Small" ? undefined : "blue"}
                        onClick={() => updateControlSize(section, field, "Small")}
                        text={"S"}
                      />
                    </Box>
                    <Box marginEnd={2}>
                      <Button
                        color={field.properties?.controlSize === "Normal" ? undefined : "blue"}
                        onClick={() => updateControlSize(section, field, "Normal")}
                        text={"M"}
                      />
                    </Box>
                    <Box marginEnd={2}>
                      <Button
                        color={field.properties?.controlSize === "Large" ? undefined : "blue"}
                        onClick={() => updateControlSize(section, field, "Large")}
                        text={"L"}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          ),
        };
      }

      return {
        key: field.key,
        column: 12,
        type: "field",
        content: (
          <Box width={"91%"} paddingY={4} marginStart={9}>
            <Text size="400" weight="bold" color={colors.black600}>
              {field.label}
            </Text>
            <Box marginTop={3} color={colors.neutral200} borderRadius={6} borderStyle="lg">
              <DraggableBox
                key={field.key}
                onItemMove={moveItemInSectionByKey}
                itemsToDrag={field.fields.map((item) => drawFieldDraggable(section, item))}
                type="field"
                itemKey={field.key}
                onItemMoveToSection={moveFieldToSection}
                onMoveSection={moveSection}
              />
            </Box>
          </Box>
        ),
      };
    },
    [
      advancedMode,
      colors.black600,
      colors.neutral200,
      contextDefinition,
      getContextValueAsString,
      moment,
      moveItemInSectionByKey,
      resources,
      updateControlSize,
      toColumnControlSize,
      moveFieldToSection,
      moveSection,
    ]
  );

  const drawSectionDraggable = useCallback(
    (section: TemplateSection): DraggableBoxProps["itemsToDrag"][number] => {
      return {
        key: section.key,
        column: 12,
        type: "section",
        content: (
          <Box
            display="flex"
            direction="column"
            flex="grow"
            marginBottom={4}
            dangerouslySetInlineStyle={{
              __style: {
                cursor: "move",
              },
            }}
          >
            <Box
              display="flex"
              direction="row"
              flex="grow"
              width={"100%"}
              height={50}
              gap={2}
              alignItems="center"
            >
              {originalSectionLabel === section.label ? (
                <Box width={"100%"}>
                  <TextField
                    placeholder={resources.name}
                    id="name"
                    value={sectionLabelEdited}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        sectionKeyEdited: section.key,
                        sectionLabelEdited: e.value,
                      }));
                    }}
                    onKeyDown={handleOnKeyDown}
                  />
                  {sectionIsDuplicated(section.label) && (
                    <Text size="100" color={colors.error}>
                      {resources.duplicated}
                    </Text>
                  )}
                </Box>
              ) : (
                <Box display="flex" direction="row" flex="grow" alignItems="center">
                  <Text weight="bold" size="400" color={colors.black900}>
                    {section.label}
                  </Text>
                </Box>
              )}

              <Box
                color={
                  originalSectionLabel === section.label ? colors.neutral900 : colors.primary100
                }
                display="flex"
                alignItems="center"
                padding={1}
                onClickCapture={() => setEditableSection(section.key, section.label)}
              >
                <EditSvgIcon width={20} height={20} />
              </Box>
              <Box color={colors.primary100} display="flex" alignItems="center" paddingX={1}>
                <BoxWithRef ref={(ref) => (anchorRefItems.current[section.key] = ref)}>
                  <TrashSvgIcon
                    width={20}
                    height={24}
                    onClick={() => onDeleteSectionClick(section.key)}
                  />
                </BoxWithRef>
                {sectionDeleteActive === section.key && (
                  <Layer>
                    <Popover
                      anchor={anchorRefItems.current[section.key]}
                      onDismiss={noop}
                      idealDirection="down"
                      positionRelativeToAnchor={false}
                      size={"flexible"}
                      color="white"
                      role="menu"
                    >
                      <Box width={300} padding={2} color={colors.white}>
                        <ComboBox
                          id="moveTo"
                          placeholder={resources.moveTo}
                          label=""
                          accessibilityClearButtonLabel={resources.clear}
                          noResultText={resources.noResultsFound}
                          onSelect={(e) => {
                            moveAllToAnotherSection(section.key, e.item.value);
                          }}
                          size="lg"
                          options={(allSections || [])
                            .filter((s) => s.key !== section.key)
                            .map((s) => {
                              return { value: s.key, label: s.label };
                            })}
                        />
                      </Box>
                    </Popover>
                  </Layer>
                )}
              </Box>
            </Box>

            <Box width={"100%"} borderRadius={6} borderStyle="lg" padding={2} color={colors.white}>
              <DraggableBox
                key={section.key}
                onItemMove={moveItemInSectionByKey}
                itemsToDrag={section.fields.map((item) => drawFieldDraggable(section, item))}
                type="section"
                itemKey={section.key}
                onItemMoveToSection={moveFieldToSection}
                onMoveSection={moveSection}
              />
            </Box>
          </Box>
        ),
      };
    },
    [
      resources,
      handleOnKeyDown,
      sectionIsDuplicated,
      colors,
      anchorRefItems,
      allSections,
      moveItemInSectionByKey,
      moveFieldToSection,
      moveSection,
      setState,
      setEditableSection,
      onDeleteSectionClick,
      moveAllToAnotherSection,
      drawFieldDraggable,
      originalSectionLabel,
      sectionDeleteActive,
      sectionLabelEdited,
    ]
  );

  return (
    <Box direction="column" display="flex" flex="grow" width={"100%"}>
      <Box
        display="flex"
        alignItems="center"
        direction="row"
        minHeight={65}
        padding={4}
        color={colors.white}
        gap={4}
      >
        <Box display="flex" direction="column" flex="shrink">
          <Box flex="grow">
            <Text color={colors.neutral900} weight="bold">
              {resources.documentCollection.identityFormula}
            </Text>
          </Box>
          <Box flex="grow">
            <Text
              inline={true}
              size="100"
              color={
                !initialState.parts.filter((p) => p.type === "variable").length
                  ? colors.error
                  : colors.subtle
              }
            >
              {resources.documentCollection.identityFormulaVariablesRequired}
            </Text>
          </Box>
        </Box>

        <Box display="flex" flex="grow">
          {wizardDefinition && (
            <Box column={9}>
              <StringTemplateBuilder
                warningLabel={resources.documentCollection.YouHaveRepeatedVariables}
                eventManager={eventManager}
                inline={true}
                onChange={(e) => updateNameConfig(e.parts)}
                initialState={initialState}
                onPartClicked={handlePartClicked}
                onVariableAdded={handleVariableAdded}
                getNewVariableData={getNewVariableData}
                getFieldLabel={getFieldLabel}
              />

              <DeviceTypeProvider deviceType="mobile">
                {showVariablePartSheet && (
                  <Layer zIndex={popupZIndex}>
                    <VariablePartSheet
                      onDismiss={() => {
                        setShowVariablePartSheet(false);
                      }}
                      getFieldLabel={getFieldLabel}
                      getFieldDataType={getFieldDataType}
                      resources={resources}
                      moment={moment}
                      variableList={variableList}
                      currentPart={clickedPart}
                      currentVariable={currentVariable}
                      rawSetCurrentVariableValue={rawSetCurrentVariableValue}
                      showVariableDetails={false}
                      allowAddNew={false}
                      eventManager={eventManager}
                    />
                  </Layer>
                )}
              </DeviceTypeProvider>
            </Box>
          )}
        </Box>
        <Box display="flex" flex="shrink" direction="column" gap={2}>
          <Text weight={advancedMode ? "bold" : "normal"}> {resources.advancedMode}</Text>
          <Box display="flex" justifyContent="end">
            <Switch
              id="designer_advanced_mode"
              onChange={advancedModeChecked}
              switched={advancedMode}
            />
          </Box>
        </Box>
      </Box>

      <Box display="flex" paddingX={4} paddingBottom={3} color={colors.white} gap={4}>
        <Box display="flex" alignItems="center" height={"100%"}>
          <Text color={colors.neutral900} weight="bold">
            {resources.identifierConfig}
          </Text>
        </Box>
        <Box flex="grow">
          <ComboBoxTags
            id={`document_type_identifier_config`}
            placeholder={resources.fields}
            label=""
            accessibilityClearButtonLabel={resources.clear}
            noResultText="There's no more"
            onSelect={onSelectIdentifierConfig}
            size="lg"
            options={identifierConfigOptions}
            selected={identifierConfigsSelected}
          />
        </Box>

        {identifierConfigsSelected.length > 0 && (
          <Box display="flex" flex="shrink" direction="column" gap={2}>
            <Text> {resources.identifierCollisionForcesNewVersion}</Text>
            <Box display="flex" justifyContent="end">
              <Switch
                id="document_type_collision_forces_new_version"
                onChange={(arg) => setIdentifierCollisionForcesNewVersion(arg.value)}
                switched={identifierCollisionForcesNewVersion}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box borderStyle="lg" borderRadius={6}></Box>
      <Box direction="row" display="flex" height={"100%"}>
        <Box column={3} color={colors.white}>
          <SidePanel
            pages={pages}
            moveFieldBetweenSections={moveFieldBetweenSections}
            addNewSection={addNewSection}
          />
        </Box>
        <Box>
          <Divider direction="vertical"></Divider>
        </Box>
        <Box column={9} color={colors.neutral300}>
          <Box direction="row" display="flex" height={57} paddingX={4}>
            <Box width={"100%"} display="flex" alignItems="center">
              <Text color={colors.subtle}>{resources.formDesigner.formPreview}</Text>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="end" gap={4} width={"100%"}>
              <Box paddingX={2}>
                <Button
                  color={"gray"}
                  onClick={() => onShowSheet("field")}
                  text={resources.formDesigner.fieldRelevance}
                />
              </Box>
              <Box paddingX={2}>
                <Button
                  color={"gray"}
                  onClick={() => onShowSheet("connector")}
                  text={resources.dataLinks}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  color={"gray"}
                  onClick={() => onShowSheet("distribution")}
                  text={resources.distribution}
                />
              </Box>
            </Box>
          </Box>
          <Box borderStyle="lg" borderRadius={6} marginBottom={6}></Box>
          <Box marginStart={12} marginEnd={12}>
            <Box marginStart={12} marginBottom={12} marginEnd={12}>
              <DraggableBox
                onItemMove={moveItemInSectionByKey}
                itemsToDrag={allSections.map(drawSectionDraggable)}
                type="root"
                itemKey=""
                onItemMoveToSection={moveFieldToSection}
                onMoveSection={moveSection}
              />

              {mode !== "edit-document-type" && (
                <NavigationButtons
                  finishButtonLabel={undefined}
                  formHasErrors={false}
                  hasBackButton={false}
                  hasNextButton={true}
                  i18n={i18n}
                  cancel={onCancel}
                  next={() => {
                    formDesignerEventManager.publish({
                      direction: "next",
                    });
                  }}
                  prev={noop}
                  summaryMode={false}
                />
              )}
            </Box>
          </Box>
        </Box>
        {showFieldRelevanceSheet && (
          <Layer zIndex={overlayPanelZIndex}>
            <FieldRelevanceSheet
              contextDefinition={contextDefinition}
              wizardDefinition={wizardDefinition}
              updateTemplateWizardDefinition={setWizardDefinition}
              onCloseSheet={onCloseSheet}
            ></FieldRelevanceSheet>
          </Layer>
        )}

        {showDataLinkSheet && (
          <Layer zIndex={overlayPanelZIndex}>
            <DataLinkSettingSheet
              contextDefinition={contextDefinition}
              wizardDefinition={wizardDefinition}
              dataLinkMappings={dataLinkMappings ?? []}
              updateTemplateWizardDefinition={function (
                templateWizardDefinition: TemplateWizardDefinition
              ): void {
                console.log(templateWizardDefinition);
              }}
              onCloseSheet={onCloseSheet}
            ></DataLinkSettingSheet>
          </Layer>
        )}

        {showDistributionSheet && (
          <Layer zIndex={overlayPanelZIndex}>
            <Sheet size="default" onDismiss={onCloseSheet} heading={""} />
          </Layer>
        )}
      </Box>
    </Box>
  );
};
