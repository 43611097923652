import type { HttpGetTaskListRequest, HttpGetTaskListResponse } from "@prodoctivity/types";
import { useCallback, useMemo, useState } from "react";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { BreadCrumbEntry } from "../BreadCrumb";
import { usePaginatedDataEndpoint } from "../hooks";

export function useHomeTasks() {
  const {
    currentPage,
    paginatedData: workflowTaskList,
    isLoading,
    refetch,
    totalRowCount,
  } = usePaginatedDataEndpoint<
    HttpGetTaskListResponse["payload"],
    HttpGetTaskListRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "6",
    "",
    (services, currentPage, rowsPerPage) => {
      return services.getTasks(currentPage, rowsPerPage, false);
    },
    "home_tasks_list"
  );

  return {
    currentPage,
    totalRowCount,
    workflowTaskList: workflowTaskList?.list ?? [],
    isLoading,
    refetch,
  };
}

export function useAllHomeTasks() {
  const { user, reassignTasks } = useServices();
  const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);
  const [adminMode, setAdminMode] = useState<boolean>(false);
  const [allTasksChecked, setAllTasksChecked] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string>();
  const {
    currentPage,
    paginatedData: tasks,
    isLoading,
    refetch,
    totalRowCount,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    filter,
    setFilter,
  } = usePaginatedDataEndpoint<
    HttpGetTaskListResponse["payload"],
    HttpGetTaskListRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "6",
    "",
    (services, currentPage, rowsPerPage) => {
      return services.getTasks(currentPage, rowsPerPage, adminMode);
    },
    adminMode ? `home_all_tasks_list_admin` : `home_all_tasks_list`
  );
  const { resources } = useAppTranslation();
  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      {
        type: "text",
        name: resources.tasks,
      },
    ];
  }, [resources.home, resources.tasks]);

  const selectTask = useCallback(
    (id: string) => {
      setSelectedTaskIds((selectedTaskIds) => {
        const newIds = [...selectedTaskIds];
        if (!newIds.includes(id)) {
          return [...newIds, id];
        }
        return newIds;
      });
    },
    [setSelectedTaskIds]
  );

  const deselectTask = useCallback(
    (id: string) => {
      setSelectedTaskIds((selectedTaskIds) => {
        const newIds = [...selectedTaskIds];
        return newIds.filter((e) => e !== id);
      });
    },
    [setSelectedTaskIds]
  );

  const toggleSelect = useCallback(
    (id: string, checked: boolean) => {
      if (checked) {
        selectTask(id);
      } else {
        deselectTask(id);
      }
    },
    [selectTask, deselectTask]
  );

  const toggleAll = useCallback(
    (ids: string[], checked: boolean) => {
      if (checked) {
        ids.forEach((id) => selectTask(id));
      } else {
        ids.forEach((id) => deselectTask(id));
      }
      setAllTasksChecked(checked);
    },
    [selectTask, deselectTask, setAllTasksChecked]
  );

  const toggleAdminMode = useCallback(
    (checked: boolean) => {
      setAdminMode(checked);
    },
    [setAdminMode]
  );

  const handleSelectUser = useCallback(
    (userId?: string) => {
      setSelectedUser(userId);
    },
    [setSelectedUser]
  );

  const handleReassignTasks = useCallback(async () => {
    if (selectedUser && selectedTaskIds.length > 0) {
      await reassignTasks(selectedTaskIds, selectedUser);
      refetch();
    }
    setSelectedUser(undefined);
  }, [selectedUser, selectedTaskIds, reassignTasks, refetch]);

  const pageLengthOptions: Array<typeof rowsPerPage> = ["6", "15", "30"];

  return {
    currentPage,
    totalRowCount,
    tasks: tasks?.list ?? [],
    isLoading,
    refetch,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    filter,
    setFilter,
    pageLengthOptions,
    breadCrumbEntries,
    selectedTaskIds,
    toggleSelect,
    toggleAll,
    allTasksChecked,
    selectedUser,
    handleSelectUser,
    handleReassignTasks,
    displayAdminSwitch: user && user.permissions.indexOf("organization-admin") >= 0,
    adminMode,
    toggleAdminMode,
  };
}
