import {
  Box,
  BoxWithRef,
  DropdownWithIcons,
  Icon,
  TapArea,
  TextField,
  useColors,
  useDesignBreakpoint,
  useEscapeKey,
} from "@prodoctivity/design-system";
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useMemo, useState } from "react";
import { DocumentSearchFilter, SearchFieldOperator } from "../../link-templates";

import { DocIconsSvg } from "../../svg/DocumentIconsSvg";
import { DocumentIconsSvg } from "../../svg/DocumentTypeIconsSvg";
import { TemplateIconSvg } from "../../svg/TemplateIconSvg";
import { XIconSvg } from "../../svg/XIconSvg";
import { useOutsideClick } from "../../utils";
import { ViewsToSelect } from "./ViewsToSelect";
import { useOpenSearch } from "./hooks";

type Props = {
  urlFieldsToCompare: DocumentSearchFilter;
  setSearchClicked?: Dispatch<SetStateAction<boolean>>;
  searchClicked?: boolean;
};

export const OpenSearch: FunctionComponent<Props> = ({
  setSearchClicked,
  searchClicked,
  urlFieldsToCompare,
}) => {
  const {
    documentTypesVersionIdList,
    // breakpoint,
    onQueryInputChanged,
    componentId,
    isOpen,
    queryValue,
    resources,
    handleOpenSearchOptionClick,
    closePopup,
    openSearchPopup,
    isDocumentSearchOpen,
    isSuggestionsOpen,
    toggleSearchPopup,
    idSelected,
    searchResults,
    buildSearchUrl,
    isLoadingSearch,
    documentTypesSelected,
    setDocumentTypesSelected,
    newSetFilters,
    filterByFields,
    onKeyPress,
    isLoadingDocTypes,
    clearFilter,
    textSearchNodeRef,
    closeSuggestions,
    searchBarNodeRef,
    documentCollectionList,
    isLoadingDocumentCollections,
    templateList,
    isLoadingTemplates,
    urlState,
    dispatch,
  } = useOpenSearch();
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();

  useEscapeKey(closePopup);

  useOutsideClick(searchBarNodeRef, () => {
    if (setSearchClicked && searchClicked && !isOpen) {
      setSearchClicked(false);
    }
  });

  useMemo(() => {
    if (urlState && urlState.query === null) {
      dispatch({
        type: "clear-query-value",
        value: "",
      });
    }
  }, [dispatch, urlState]);

  const closeSuggestionsOnBlur = useCallback(() => {
    setTimeout(closeSuggestions, 200);
  }, [closeSuggestions]);

  const setSearchType = useCallback(
    (value: typeof idSelected) => {
      switch (value) {
        case "document":
          dispatch({
            type: "toggle-document-search",
          });
          break;
        case "collection":
          dispatch({
            type: "toggle-collections-search",
          });
          break;
        case "template":
          dispatch({
            type: "toggle-templates-search",
          });
          break;
        default:
          break;
      }
    },
    [dispatch]
  );

  const handleTap = useCallback(() => {
    if (!isOpen) {
      toggleSearchPopup();
      handleOpenSearchOptionClick("advancedSearch");
    } else if (isDocumentSearchOpen) {
      handleOpenSearchOptionClick("all");
    } else {
      handleOpenSearchOptionClick("advancedSearch");
    }
  }, [handleOpenSearchOptionClick, isDocumentSearchOpen, isOpen, toggleSearchPopup]);

  const isMobile = breakpoint === "small";

  const [prevUrlState, setPrevUrlState] = useState<DocumentSearchFilter>();

  useMemo(() => {
    if (urlFieldsToCompare !== prevUrlState) {
      function isSearchFieldOperator(value: string): value is SearchFieldOperator {
        return [
          "eq",
          "lt",
          "lte",
          "gt",
          "gte",
          "neq",
          "contains",
          "ends-with",
          "starts-with",
        ].includes(value);
      }
      setPrevUrlState(urlFieldsToCompare);
      const fieldsInUrlAndSearch = filterByFields.map((field) => {
        const coincidence = urlFieldsToCompare.fields.find((item) => item.fld === field.field.name);
        field.value = coincidence ? coincidence.val : "";
        const updatedOperator =
          coincidence && isSearchFieldOperator(coincidence.op) ? coincidence.op : "eq";
        field.equalityOperator = updatedOperator;
        return field;
      });
      newSetFilters(fieldsInUrlAndSearch);
    }
  }, [filterByFields, newSetFilters, prevUrlState, urlFieldsToCompare]);

  const optionsIcons = useMemo(() => {
    const result = [
      {
        value: "document",
        label: resources.documents,
        icon: (
          <Box>
            <DocIconsSvg width={38} height={38} />
          </Box>
        ),
      },
      {
        value: "collection",
        label: resources.collections,
        icon: (
          <Box>
            <DocumentIconsSvg width={38} height={38} />
          </Box>
        ),
      },
      {
        value: "template",
        label: resources.templates,
        icon: (
          <Box>
            <TemplateIconSvg width={38} height={38} color={colors.primary} />
          </Box>
        ),
      },
    ];
    return result;
  }, [resources, colors]);

  const isTablet = breakpoint === "large";
  return (
    <BoxWithRef
      ref={searchBarNodeRef}
      alignItems="center"
      justifyContent="center"
      maxWidth={600}
      minWidth={180}
      height={36}
      width={!isMobile ? "100%" : "100%"}
      marginStart={isMobile ? 2 : 12}
      marginEnd={isMobile ? 2 : 12}
      position="relative"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="between"
        color={colors.neutral300}
        height={36}
        borderRadius={12}
        width={isTablet ? "100%" : undefined}
      >
        <DropdownWithIcons
          id="search_type_options"
          value={idSelected}
          onChange={setSearchType}
          items={optionsIcons}
        />
        <Box
          display="flex"
          marginStart={1}
          justifyContent="center"
          alignItems="center"
          width={32}
          height={32}
        >
          <TapArea onTap={toggleSearchPopup}>
            <Box display="flex" justifyContent="center" alignItems="center" width={32} height={32}>
              <Icon color={colors.neutral900} icon="search" accessibilityLabel={resources.search} />
            </Box>
          </TapArea>
        </Box>
        <Box
          marginStart={-1}
          width={"100%"}
          display="flex"
          alignItems="center"
          height={36}
          position="relative"
        >
          <Box width={"100%"} display="flex" alignItems="center" justifyContent="between">
            <Box width={"100%"}>
              <TextField
                ref={textSearchNodeRef}
                type="text"
                id={`${componentId}_search`}
                mode={"unstyled"}
                value={queryValue}
                onChange={onQueryInputChanged}
                placeholder={resources.searchFor + idSelected}
                onFocus={openSearchPopup}
                onBlur={closeSuggestionsOnBlur}
                onKeyDown={onKeyPress}
                autoComplete={"off"}
                mobileInputMode="text"
                mobileEnterKeyHint={"search"}
              />
            </Box>
            {queryValue && (
              <Box
                display="flex"
                marginStart={1}
                justifyContent="center"
                alignItems="center"
                width={32}
                height={32}
              >
                <TapArea onTap={clearFilter}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={32}
                    height={32}
                  >
                    <XIconSvg />
                  </Box>
                </TapArea>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          marginEnd={1}
          justifyContent="center"
          alignItems="center"
          width={32}
          height={32}
        >
          <TapArea onTap={handleTap}>
            <Box display="flex" justifyContent="center" alignItems="center" width={32} height={32}>
              <Icon
                color={colors.neutral900}
                icon="sliders"
                accessibilityLabel={resources.search}
              />
            </Box>
          </TapArea>
        </Box>
      </Box>
      {isOpen && (
        <Box width={"100%"} position="relative">
          <Box
            position="fixed"
            color={colors.neutral500}
            height="100vh"
            width="100vw"
            left
            marginTop={3}
            opacity={0.4}
            onClickCapture={() => {
              closePopup();
              if (setSearchClicked) {
                setSearchClicked(false);
              }
            }}
          ></Box>
          <Box
            position={isMobile ? "fixed" : "relative"}
            color={isMobile ? colors.white : undefined}
            height={isMobile ? "fit-content" : undefined}
            width={isMobile ? "100vw" : "100%"}
            left={isMobile ? true : false}
          >
            <ViewsToSelect
              isDocumentSearchOpen={isDocumentSearchOpen}
              isSuggestionsOpen={isSuggestionsOpen}
              closePopup={closePopup}
              setSearchClicked={setSearchClicked}
              documentTypesVersionIdList={documentTypesVersionIdList}
              idSelected={idSelected}
              searchResults={searchResults}
              isLoadingSearch={isLoadingSearch}
              buildSearchUrl={buildSearchUrl}
              documentTypesSelected={documentTypesSelected}
              setDocumentTypesSelected={setDocumentTypesSelected}
              newSetFilters={newSetFilters}
              filterByFields={filterByFields}
              handlerEnterKeyPress={onKeyPress}
              isLoadingDocTypes={isLoadingDocTypes}
              queryValue={queryValue}
              isMobile={isMobile}
              searchBarNodeRef={searchBarNodeRef}
              documentCollectionList={documentCollectionList}
              isLoadingDocumentCollections={isLoadingDocumentCollections}
              templateList={templateList}
              isLoadingTemplates={isLoadingTemplates}
            />
          </Box>
        </Box>
      )}
    </BoxWithRef>
  );
};
