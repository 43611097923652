import { Box, useColors, useDesignBreakpoint } from "@prodoctivity/design-system";
import { FunctionComponent, ReactNode } from "react";
import { BreadCrumb, BreadCrumbEntry } from "../BreadCrumb";

import { useSettingsPage } from "../../hooks/useSettingsPage";

type Props = {
  fixedHeight?: boolean;
  breadCrumbEntries?: BreadCrumbEntry[];
  breadCrumbExtraComponent?: JSX.Element;
  children?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  hideBackButton?: boolean;
};

export const Page: FunctionComponent<Props> = ({
  fixedHeight,
  breadCrumbEntries,
  breadCrumbExtraComponent,
  left,
  right,
  hideBackButton,
  children,
}) => {
  const { colors } = useColors();

  const { breakpoint } = useDesignBreakpoint();
  const pageContext = useSettingsPage();

  const leftSide = left ? left : pageContext.leftSideBar ? pageContext.leftSideBar : null;
  const content = pageContext.topComponent ? (
    <Box display="flex" direction="column" alignItems="center" flex="grow">
      {pageContext.topComponent}
      <Box display="flex" direction="column" justifyContent="center" flex="grow">
        {children}
      </Box>
    </Box>
  ) : (
    children
  );

  return (
    <Box
      display="flex"
      direction="column"
      flex="grow"
      width={"100%"}
      minHeight={"100%"}
      height={fixedHeight ? "100%" : undefined}
      maxHeight={fixedHeight ? "100%" : undefined}
    >
      {breadCrumbEntries && (
        <Box color={colors.white} display="flex" direction="column" flex="shrink">
          <BreadCrumb
            entries={breakpoint === "small" ? [] : breadCrumbEntries}
            extraComponent={breadCrumbExtraComponent}
            hideBackButton={hideBackButton}
          />
        </Box>
      )}
      <Box display="flex" direction="column" flex="grow">
        {leftSide ? (
          <Box display="flex" direction="row" flex="grow">
            {breakpoint !== "small" ? (
              <Box
                display="flex"
                direction="column"
                flex="grow"
                width={pageContext.isOpen ? "22%" : "auto"}
                overflow={fixedHeight ? "auto" : undefined}
                minHeight={"100%"}
                maxHeight={fixedHeight ? "100%" : undefined}
              >
                {leftSide}
              </Box>
            ) : undefined}
            <Box
              display="flex"
              direction="column"
              flex="grow"
              width={pageContext.isOpen ? "78%" : "100%"}
              overflow={fixedHeight ? "auto" : undefined}
              minHeight={"100%"}
              maxHeight={fixedHeight ? "100%" : undefined}
            >
              {content}
            </Box>
          </Box>
        ) : (
          content
        )}
        {right ? right : null}
      </Box>
    </Box>
  );
};
