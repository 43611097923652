import {
  Box,
  BoxWithRef,
  Divider,
  Icon,
  IconType,
  Image,
  Link,
  Mask,
  Popover,
  Skeleton,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";

import md5 from "md5";

import { useUserPublicProfile } from "../../hooks";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { useSettings } from "../../hooks/useSettings";
import { organizationLinkTemplates } from "../../link-templates";
import { DeletedDocumentSvgIcon } from "../../svg/DeletedDocumentSvgIcon";
import { ListSvg } from "../../svg/ListSvg";
import { OrganizationLink } from "../OrganizationLink";
import { usePlanSetting } from "../../hooks/usePlanSetting";

function defaultImageUrl(email: string) {
  return `https://www.gravatar.com/avatar/${md5(email.trim().toLowerCase())}.jpg?s=44&d=404`;
}

type Props = {
  name: string;
  email: string;
  imageDataURI?: string;
  forceOpen?: boolean;
};

function Avatar({ name, email, imageDataURI, forceOpen }: Props) {
  const { colors } = useColors();
  const [open, setOpen] = useState(false);
  const { resources } = useAppTranslation();
  const { disableSignUp } = useSettings();
  const { user } = useServices();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const upgradePlanSetting = usePlanSetting();
  const onClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const isAdmin = useMemo(() => {
    return (user?.permissions || []).includes("organization-admin");
  }, [user]);

  const language = useCallback(() => {
    switch (resources._locale) {
      case "en":
        return resources.languages.english;
      case "es":
        return resources.languages.spanish;
      case "pt":
        return resources.languages.portuguese;
      case "nl":
        return resources.languages.dutch;
      default:
        break;
    }
  }, [
    resources._locale,
    resources.languages.dutch,
    resources.languages.portuguese,
    resources.languages.spanish,
    resources.languages.english,
  ]);

  const topMenuItems = useMemo(() => {
    const pricingEntries = [
      ...(disableSignUp || !isAdmin || upgradePlanSetting?.upgradePlanBehavior === "blocked"
        ? ([] as const)
        : ([
            {
              label: resources.upgradePlan,
              icon: "crown",
              link: organizationLinkTemplates.upgradePlan(),
              type: "link",
              linkText: resources.upgradePlan,
            },
          ] as const)),
    ];

    const r: Array<
      {
        label: string;
        icon: IconType | JSX.Element;
      } & (
        | {
            type: "link";
            link: string;
            linkText: string;
          }
        | {
            type: "raw-link";
            link: string;
            linkText: string;
          }
        | {
            type: "element";
            element: JSX.Element;
          }
      )
    > = [
      {
        label: resources.myProfile,
        icon: "user-large",
        link: organizationLinkTemplates.account(),
        type: "link",
        linkText: resources.account,
      },
      ...pricingEntries,
      {
        label: resources.activities.activities,
        icon: "ballot-check",
        link: organizationLinkTemplates.activityList(),
        type: "link",
        linkText: resources.activities.activities,
      },
      {
        label: resources.taskHistory,
        icon: "ballot-check",
        link: organizationLinkTemplates.tasks(),
        type: "link",
        linkText: resources.taskHistory,
      },
      {
        label: resources.recentSearches,
        icon: "clock-rotate-left",
        link: "/",
        type: "link",
        linkText: resources.recentSearches,
      },
      {
        label: resources.browseDocument.browseDocuments,
        icon: "doc-view",
        link: organizationLinkTemplates.viewDocuments(),
        type: "link",
        linkText: resources.browseDocument.browseDocuments,
      },
      {
        label: resources.myLists,
        icon: <ListSvg width={24} height={24} color={colors.neutral500} />,
        link: organizationLinkTemplates.viewMyLists(),
        type: "link",
        linkText: resources.myLists,
      },
      {
        label: resources.documentCollection.documentCollections,
        icon: "doc-filter",
        link: organizationLinkTemplates.documentCollectionList({
          pageNumber: 0,
          rowsPerPage: "15",
          filter: "",
          assignedTo: "",
        }),
        type: "link",
        linkText: resources.documentCollection.documentCollections,
      },
      {
        label: resources.documentRecovery,
        icon: "trash",
        link: organizationLinkTemplates.documentRecovery(),
        type: "link",
        linkText: resources.documentRecovery,
      },
      {
        label: resources.generations,
        icon: "generated-doc",
        link: organizationLinkTemplates.documentGenerationStatusList(),
        type: "link",
        linkText: resources.generations,
      },
      {
        label: resources.language,
        icon: "timeline",
        type: "link",
        link: organizationLinkTemplates.account(),
        linkText: `${resources.language}: ${language()}`,
      },
      {
        icon: "alarm",
        label: "",
        type: "element",
        element: <Divider />,
      },
      ...(isAdmin
        ? [
            {
              label: resources.settings,
              icon: "screwdriver-wrench",
              type: "link",
              link: organizationLinkTemplates.settings(),
              linkText: resources.settings,
            } as const,
          ]
        : []),
      {
        label: resources.signOut,
        icon: "power-off",
        type: "raw-link",
        link: organizationLinkTemplates.logout(),
        linkText: resources.signOut,
      },
    ];

    return r;
  }, [
    colors.neutral500,
    isAdmin,
    language,
    disableSignUp,
    resources.account,
    resources.activities.activities,
    resources.browseDocument.browseDocuments,
    resources.documentCollection.documentCollections,
    resources.documentRecovery,
    resources.generations,
    resources.language,
    resources.myLists,
    resources.myProfile,
    resources.recentSearches,
    resources.settings,
    resources.signOut,
    resources.taskHistory,
    resources.upgradePlan,
    upgradePlanSetting?.upgradePlanBehavior,
  ]);

  return (
    <BoxWithRef
      ref={anchorRef}
      onClickCapture={onClick}
      aria-label={resources.account}
      aria-roledescription="button"
      dangerouslySetInlineStyle={{
        __style: {
          cursor: "pointer",
        },
      }}
    >
      <AvatarImage email={email} name={name} imageDataURI={imageDataURI} />
      {(open || forceOpen) && (
        <Popover
          anchor={anchorRef.current}
          onDismiss={() => setOpen(false)}
          idealDirection="down"
          positionRelativeToAnchor={true}
          size="xl"
          shouldFocus={true}
          color="white"
          role="menu"
        >
          <Box display="flex" direction="column">
            <Box padding={5}>
              <Box display="flex" direction="column">
                <Box
                  display="flex"
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                  gap={6}
                >
                  <Box width={40}>
                    <AvatarImage size={48} email={email} name={name} imageDataURI={imageDataURI} />
                  </Box>
                  <Box display="flex" direction="column" maxWidth={200}>
                    <Text weight="bold">{name}</Text>
                    <Text title={email} overflow="ellipsis">
                      {email}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box padding={5}>
              <Box display="flex" gap={5} direction="column">
                {topMenuItems.map((item, index) => {
                  return item.type === "link" ? (
                    <OrganizationLink key={`${item.link}_${index}`} to={item.link}>
                      <Box
                        display="flex"
                        key={`${index}_${item.label}`}
                        direction="row"
                        alignItems="center"
                        gap={4}
                        justifyContent="start"
                      >
                        <Box width={266}>
                          <Box
                            padding={2}
                            rounding={2}
                            datatype="menu-item-entry"
                            hoverColor={colors.primaryHover0}
                          >
                            <Box
                              display="flex"
                              direction="row"
                              alignItems="center"
                              justifyContent="start"
                              gap={4}
                            >
                              {typeof item.icon === "string" ? (
                                item.icon === "trash" ? (
                                  <DeletedDocumentSvgIcon
                                    color={colors.neutral500}
                                    width={20}
                                    height={23}
                                  />
                                ) : (
                                  <Icon
                                    color={colors.neutral500}
                                    size="sm"
                                    icon={item.icon}
                                    accessibilityLabel={item.label}
                                  />
                                )
                              ) : (
                                item.icon
                              )}

                              <Box display="flex" direction="column">
                                <Text
                                  customClassName="text-bold-on-hover text-subtle-on-hover"
                                  color={colors.subtle}
                                >
                                  {item.linkText}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </OrganizationLink>
                  ) : item.type === "raw-link" ? (
                    <Link key={`${item.link}_${index}`} underline="none" href={item.link}>
                      <Box
                        display="flex"
                        key={`${index}_${item.label}`}
                        direction="row"
                        alignItems="center"
                        gap={4}
                        justifyContent="start"
                      >
                        <Box width={266}>
                          <Box
                            padding={2}
                            rounding={2}
                            datatype="menu-item-entry"
                            hoverColor={colors.primaryHover0}
                          >
                            <Box
                              display="flex"
                              direction="row"
                              alignItems="center"
                              justifyContent="start"
                            >
                              <Box display="flex" direction="column" marginEnd={4}>
                                {typeof item.icon === "string" ? (
                                  <Icon
                                    color={colors.neutral500}
                                    size="sm"
                                    icon={item.icon}
                                    accessibilityLabel={item.label}
                                  />
                                ) : (
                                  item.icon
                                )}
                              </Box>
                              <Box display="flex" direction="column">
                                <Text
                                  customClassName="text-bold-on-hover text-subtle-on-hover"
                                  color={colors.subtle}
                                >
                                  {item.linkText}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Link>
                  ) : (
                    <Box
                      display="flex"
                      key={`${index}_${item.label}`}
                      direction="row"
                      alignItems="center"
                      gap={4}
                      justifyContent="start"
                    >
                      <Box width={266}>{item.element}</Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Popover>
      )}
    </BoxWithRef>
  );
}

type AvatarImageProps = {
  size?: 32 | 48 | 62;
  imageDataURI?: string;
  email: string;
  name: string;
};

export const AvatarImage: FunctionComponent<AvatarImageProps> = ({
  email,
  name,
  imageDataURI,
  size = 32,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const [withGravatar, setWithGravatar] = useState(true);

  return (
    <Mask rounding={"circle"} height={size} width={size}>
      {(withGravatar && email) || imageDataURI ? (
        <Image
          alt={resources.avatar}
          color="transparent"
          fit="cover"
          naturalHeight={size}
          naturalWidth={size}
          src={imageDataURI ? imageDataURI : defaultImageUrl(email)}
          onError={() => setWithGravatar(false)}
        />
      ) : (
        <Box
          color={colors.primary}
          height={"100%"}
          minWidth={size}
          maxWidth={size}
          direction="column"
          display="flex"
          justifyContent="center"
        >
          <Box
            display="flex"
            flex="grow"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Text size="200" weight="bold" inline={true} color={colors.secondary}>
              {name
                ?.split(" ")
                .filter((word) => word.trim())
                .map((word) => word.trim().charAt(0))
                .slice(0, 2)
                .join(" ")
                .toLocaleUpperCase()}
            </Text>
          </Box>
        </Box>
      )}
    </Mask>
  );
};

type PublicAvatarImageProps = {
  username: string;
  size?: 32 | 48 | 62;
};
export function PublicAvatarImage({ username, size = 48 }: PublicAvatarImageProps) {
  const { colors } = useColors();
  const { isLoading, data } = useUserPublicProfile(username);
  const { resources } = useAppTranslation();

  if (isLoading) {
    return <Skeleton width={50} height={50} rounded={true} />;
  }
  if (!data || !data.avatarImageDataURI) {
    const name = data
      ? `${data.lastName ? `${data.firstName} ${data.lastName}` : data.firstName}`
      : username;
    return (
      <Mask rounding={"circle"} height={size} width={size}>
        <Box
          color={colors.primary}
          height={"100%"}
          minWidth={size}
          maxWidth={size}
          direction="column"
          display="flex"
          justifyContent="center"
        >
          <Box
            display="flex"
            flex="grow"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Text size="200" weight="bold" inline={true} color={colors.secondary}>
              {name
                ?.split(" ")
                .filter((word) => word.trim())
                .map((word) => word.trim().charAt(0))
                .slice(0, 2)
                .join(" ")
                .toLocaleUpperCase()}
            </Text>
          </Box>
        </Box>
      </Mask>
    );
  }
  return (
    <Mask rounding={"circle"} height={size} width={size}>
      <Image
        alt={resources.avatar}
        color="transparent"
        fit="cover"
        naturalHeight={size}
        naturalWidth={size}
        src={data.avatarImageDataURI}
      />
    </Mask>
  );
}

export { Avatar };
