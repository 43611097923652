import {
  Box,
  Button,
  DeleteDocumentModal,
  Divider,
  Icon,
  TapArea,
  Text,
  Tooltip,
  useColors,
} from "@prodoctivity/design-system";

import { formatFileSize } from "@prodoctivity/shared";
import type { EcmDocument } from "@prodoctivity/types";
import { FunctionComponent, useState } from "react";
import { useCopyToClipboard } from "usehooks-ts";
import { useTemplateInfo } from "../../hooks";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../link-templates";
import { useHasClipboard } from "../../utils";
import { PublicAvatarImage } from "../Avatar/Avatar";
import { FancyDateTime } from "../Display/FancyDateTime";
import { OrganizationLink } from "../OrganizationLink";
import { PublicProfile } from "../Profile/Public/PublicProfile";

type Props = {
  document: EcmDocument;
  contentType: string;
  canDeleteDocument: boolean;
  deleteDocument: () => void;
};

export function ViewerDocumentProperties({
  document,
  contentType,
  canDeleteDocument,
  deleteDocument,
}: Props) {
  const { colors } = useColors();
  const { data: templateInfo } = useTemplateInfo(document.templateVersionId);
  const { resources } = useAppTranslation();
  const { data: hasClipboard } = useHasClipboard();
  const templateData = templateInfo ? templateInfo.info : undefined;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  if (!document) return null;

  const {
    name,
    documentId,
    createdAt,
    createdBy,
    updatedAt,
    expirationDate,
    documentTypeName,
    documentTypeId,
    templateId,
  } = document;

  return (
    <Box display="flex" direction="column" flex="grow" width={"100%"} padding={3}>
      {name && (
        <Box paddingY={2}>
          <Box paddingY={1}>
            <Text weight="bold" size="200">
              {resources.name}:
            </Text>
          </Box>
          <Box hoverColor={colors.neutral400} display="flex">
            <Text size="200">{name}</Text>
            {hasClipboard && <CopyIdToClipboardComponent valueToCopy={documentId} label={name} />}
          </Box>
        </Box>
      )}

      {createdAt && (
        <Box paddingY={2}>
          <Box paddingY={1}>
            <Text weight="bold" size="200">
              {resources.createdAt}:
            </Text>
          </Box>
          <Box hoverColor={colors.neutral400}>
            <FancyDateTime size="200" value={createdAt} showTime={true} />
          </Box>
        </Box>
      )}

      {createdBy && (
        <Box paddingY={2} display="flex" direction="column">
          <Box paddingRight={3}>
            <Text weight="bold" size="200">
              {resources.createdBy}:
            </Text>
          </Box>
          <Box hoverColor={colors.neutral400}>
            <Box display="flex" alignItems="center">
              <Box>
                <PublicAvatarImage size={32} username={createdBy} />
              </Box>
              <Box padding={2}>
                <PublicProfile username={createdBy} size="200" />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {new Date(updatedAt).toLocaleString() !== new Date(createdAt).toLocaleString() && (
        <Box paddingY={2}>
          <Box paddingY={1}>
            <Text weight="bold" size="200">
              {resources.lastUpdated}:
            </Text>
          </Box>
          <Box hoverColor={colors.neutral400}>
            <FancyDateTime size="200" value={updatedAt} showTime={true} />
          </Box>
        </Box>
      )}

      {expirationDate && (
        <Box paddingY={2}>
          <Box paddingY={1}>
            <Text weight="bold" size="200">
              {resources.expired}:
            </Text>
          </Box>
          <Box hoverColor={colors.neutral400}>
            {<FancyDateTime size="200" value={expirationDate} showTime={true} />}
          </Box>
        </Box>
      )}

      {documentTypeId && (
        <Box paddingY={2}>
          <Box paddingY={1}>
            <Text weight="bold" size="200">
              {resources.documentTypes.documentType}:
            </Text>
          </Box>
          <Box hoverColor={colors.neutral400} display="flex">
            <Text size="200">{documentTypeName}</Text>
            {hasClipboard && (
              <CopyIdToClipboardComponent
                valueToCopy={documentTypeId}
                label={resources.documentTypes.documentType}
              />
            )}
          </Box>
        </Box>
      )}

      {contentType && (
        <Box paddingY={2}>
          <Box paddingY={1}>
            <Text weight="bold" size="200">
              {resources.FileType}:
            </Text>
          </Box>
          <Box hoverColor={colors.neutral400}>
            <Text size="200">{contentType}</Text>
          </Box>
        </Box>
      )}

      {templateId && templateData && (
        <Box paddingY={2} borderStyle="sm">
          <Box paddingY={3}>
            <Box paddingX={2}>
              <Text weight="bold" size="200">
                {resources.template}
              </Text>
            </Box>
          </Box>
          <Divider />
          <Box display="flex" justifyContent="end">
            <OrganizationLink
              to={organizationLinkTemplates.templateVersionDetailPage(
                templateData?.templateVersionId
              )}
            >
              <Text size="300" weight="bold">
                {resources.details}
              </Text>
            </OrganizationLink>
          </Box>
          <Box padding={3}>
            <Box paddingY={1}>
              <Text weight="bold" size="200">
                {resources.generationStatus.templateName}:
              </Text>
            </Box>
            <Box hoverColor={colors.neutral400} display="flex">
              <Text size="200">{templateData?.informationDefinition.name}</Text>
              {hasClipboard && (
                <CopyIdToClipboardComponent
                  valueToCopy={templateId}
                  label={resources.generationStatus.templateName}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}

      {!!document.storage && (
        <Box paddingY={2}>
          <Box paddingY={1}>
            <Text weight="bold" size="200">
              {resources.storageSize}:
            </Text>
          </Box>
          <Box hoverColor={colors.neutral400}>
            <Text size="200">{formatFileSize(document.storage)}</Text>
          </Box>
        </Box>
      )}

      {!!document.totalStorage && (
        <Box paddingY={2}>
          <Box paddingY={1}>
            <Text weight="bold" size="200">
              {resources.totalSize}:
            </Text>
          </Box>
          <Box hoverColor={colors.neutral400}>
            <Text size="200">{formatFileSize(document.totalStorage)}</Text>
          </Box>
        </Box>
      )}
      <Box display="flex" direction="column" flex="grow" />

      {canDeleteDocument && (
        <Box display="flex" justifyContent="center">
          <Button
            color="red"
            fullWidth={true}
            text={resources.delete_}
            iconEnd="trash-can"
            onClick={() => setShowDeleteModal(true)}
          />
        </Box>
      )}

      <DeleteDocumentModal
        show={showDeleteModal}
        onDismiss={() => setShowDeleteModal(false)}
        deleteDocument={deleteDocument}
        resources={resources}
      />
    </Box>
  );
}

const CopyIdToClipboardComponent: FunctionComponent<{ valueToCopy: string; label: string }> = ({
  valueToCopy,
  label,
}) => {
  const [_value, copyToClipboard] = useCopyToClipboard();
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  return (
    <Box paddingX={2}>
      <TapArea onTap={() => copyToClipboard(valueToCopy)}>
        <Tooltip text={resources.copyIdToClipboard}>
          <Icon accessibilityLabel={label} icon="circle-info" size={"sm"} color={colors.subtle} />
        </Tooltip>
      </TapArea>
    </Box>
  );
};
