import { TextField as GestaltTextField, TextFieldProps as RawTextFieldProps } from "gestalt";
import { Ref, forwardRef } from "react";

export type TextFieldProps = RawTextFieldProps & {
  mode?: "default" | "unstyled" | "summary";
  maxLength?: { characterCount: number; errorAccessibilityLabel: string };
};
export const TextField = forwardRef(
  ({ mode = "default", ...props }: TextFieldProps, ref?: Ref<HTMLInputElement>) => {
    if (mode === "unstyled") {
      return (
        <div className={"prodoctivity-unstyled-field"}>
          <GestaltTextField ref={ref} {...props} />
        </div>
      );
    }

    return <GestaltTextField ref={ref} {...props} />;
  }
);
