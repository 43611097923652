import { FunctionComponent, useCallback, useRef, useState } from "react";
import { Box, BoxWithRef } from "../Box";
import { Popover, modalZIndex } from "../layout";

import { useTranslation } from "react-i18next";
import { isIconSelected } from "../../utils";
import { useColors } from "../ColorSchemeProvider";
import { TapArea } from "../TapArea";
import { Text } from "../Text";
import { DynamicIcon } from "./DynamicIcon";

type Props = {
  collapsibleMenuIcon: "hamburger" | "verticalEllipsis";
  handleFunctionsBundle: ((parametersObject?: any) => void)[];
  srcAreImages: (() => boolean) | undefined;
  menuLabels: string[];
} & (
  | {
      menuHasIcons: false;
    }
  | {
      menuHasIcons: true;
      // has the list of icon names that will be used by the <DynamicIcon> component
      iconsToRender: string[];
    }
) &
  (
    | {
        bundleNeedsParameters: false;
      }
    | {
        bundleNeedsParameters: true;
        // This is the entire list of parameters that will be passed to the functions for them to destructure and use only what they need
        parametersSet: any;
        // This tells the component which functions need parameters
        parameterizedFunctionIndex: number[];
      }
  );

export const PopoverMenu: FunctionComponent<Props> = ({
  handleFunctionsBundle,
  srcAreImages,
  menuLabels,
  collapsibleMenuIcon,
  ...rest
}) => {
  const { colors } = useColors();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [btnClicked, setBtnClicked] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const onClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const i18n = useCallback(
    (key: string) => {
      const value = t(key);
      if (typeof value === "undefined") {
        console.error(`Key "${key}" not defined in i18n resource files`);
        return key;
      }

      return value;
    },
    [t]
  );

  return (
    <>
      <BoxWithRef
        ref={anchorRef}
        onClickCapture={onClick}
        width={40}
        height={32}
        color={isIconSelected(colors, open, true)}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius={4}
        borderColor={
          isIconSelected(colors, open, false) !== undefined
            ? isIconSelected(colors, open, false)
            : colors.neutral50
        }
      >
        <TapArea
          onTap={() => {
            setBtnClicked(!btnClicked);
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <DynamicIcon btnClicked={open} iconName={collapsibleMenuIcon}></DynamicIcon>
            {open && (
              <Box zIndex={modalZIndex}>
                <Popover
                  anchor={anchorRef.current}
                  onDismiss={() => setOpen(false)}
                  idealDirection="down"
                  positionRelativeToAnchor={true}
                  size="sm"
                  shouldFocus={true}
                  color="white"
                  role="menu"
                >
                  <Box paddingX={2} paddingY={2} width={346} color={colors.white}>
                    <Box display="flex" direction="column" gap={2}>
                      {menuLabels.map((btnName, index) => {
                        if (
                          btnName === "collapseSidePanel" &&
                          srcAreImages !== undefined &&
                          !srcAreImages()
                        ) {
                          return null;
                        }
                        return (
                          <Box
                            key={`${btnName}_${index}`}
                            padding={2}
                            rounding={2}
                            datatype="menu-item-entry"
                            color={colors.neutral50}
                            onClickCapture={() => {
                              // Determines if the function needs parameters by comparing their index with the array of indexes
                              // passed as prop by the developer who knows
                              if (
                                rest.bundleNeedsParameters &&
                                rest.parameterizedFunctionIndex.includes(index)
                              ) {
                                handleFunctionsBundle[index](rest.parametersSet);
                              } else {
                                handleFunctionsBundle[index]();
                              }
                            }}
                            onPointerEnter={() => setHoveredIndex(index)}
                            onPointerLeave={() => setHoveredIndex(null)}
                          >
                            <Box
                              display="flex"
                              direction="row"
                              alignItems="center"
                              justifyContent="start"
                            >
                              <Box display="flex" direction="column">
                                <TapArea rounding="pill">
                                  <Box display="flex" alignItems="center" gap={1}>
                                    {rest.menuHasIcons ? (
                                      <DynamicIcon
                                        btnClicked={hoveredIndex === index}
                                        iconName={rest.iconsToRender[index]}
                                      ></DynamicIcon>
                                    ) : null}
                                    <Box paddingLeft={2}>
                                      <Text
                                        customClassName="text-medium-on-hover text-subtle-on-hover"
                                        color={colors.subtle}
                                      >
                                        {i18n(menuLabels[index])}
                                      </Text>
                                    </Box>
                                  </Box>
                                </TapArea>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Popover>
              </Box>
            )}
          </Box>
        </TapArea>
      </BoxWithRef>
    </>
  );
};
