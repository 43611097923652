import { Spinner } from "@prodoctivity/design-system";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { OrganizationNavigate } from "../../components/OrganizationNavigate";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { PageNotFound } from "../StandAloneViewer/PageNotFound";

function OpenTaskPage() {
  const { getTask } = useServices();
  const { taskId } = useParams();

  const { resources } = useAppTranslation();

  const fetchGetTask = useCallback(() => {
    if (!taskId) {
      return undefined;
    }
    return getTask(taskId);
  }, [getTask, taskId]);

  const { isLoading, data } = useOrganizationQuery(`tasks/${taskId}`, fetchGetTask, {
    staleTime: 0,
    cacheTime: 0,
  });

  if (isLoading) {
    return <Spinner show={true} />;
  }

  if (!data) {
    return <PageNotFound message={resources.pageNotFound.taskNotFound} />;
  }

  if (
    data.task.taskType === "template-context-state" &&
    data.task.contextType === "combined-template"
  ) {
    return (
      <OrganizationNavigate
        to={organizationLinkTemplates.generateCombinedTemplate(
          data.task.combinedTemplateId,
          taskId || ""
        )}
      />
    );
  }

  if (data.task.taskType === "template-context-state" && data.task.contextType === "template") {
    return (
      <OrganizationNavigate
        to={organizationLinkTemplates.templateVersionGeneratePage(
          data.task.templateVersionId,
          taskId || ""
        )}
      />
    );
  }

  if (data.task.taskType === "document-collection-task") {
    return (
      <OrganizationNavigate
        to={organizationLinkTemplates.documentCollection(data.task.instanceId)}
      />
    );
  }

  return <OrganizationNavigate to={organizationLinkTemplates.tasks()} />;
}

export default OpenTaskPage;
