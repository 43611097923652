import {
  Box,
  Center,
  DatePicker,
  DocumentViewer,
  FormControllerProvider,
  PubSubEventManager,
  SelectList,
  Spinner,
  Text,
  TextArea,
  useColors,
} from "@prodoctivity/design-system";
import { GenerationWizardBoundary, NavigationButtons } from "@prodoctivity/prodoctivity-form-v5";
import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";
import { TemplateEditWizardEvent, useTemplateEditWizard } from "./hooks";

import type { UpdateTemplateDefinitionCommand } from "@prodoctivity/shared/src/index-types";
import { useMutation } from "@tanstack/react-query";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../link-templates";
import { FormDesigner } from "../../pages/Settings/DocumentType/FormDesigner/index";
import { Services } from "../../services";
import { emptyArray, noop } from "../../utils";
import { LookupDocument } from "../LookupDocument/LookupDocument";
import { useDocumentLookupState } from "../LookupDocument/hook";
import { StepTabs } from "../StepTabs";

const publishOnly = true;

type Props = {
  selectedTabIndex: number;
  template: Awaited<ReturnType<Services["fetchTemplateInfo"]>>;
  status: "idle" | "generating";
  eventManager: PubSubEventManager<TemplateEditWizardEvent>;
  setSelectedTabIndex(v: number): void;
  onFinish(
    templateVersionId: string,
    documentTypeSent: UpdateTemplateDefinitionCommand
  ): Promise<void>;
  isLoading: boolean;
};

export const TemplateEditWizard: FunctionComponent<Props> = ({
  selectedTabIndex,
  template,
  status,
  eventManager,
  setSelectedTabIndex,
  onFinish,
  isLoading,
}) => {
  const { resources, moment } = useAppTranslation();
  const {
    state,
    pdfResponse,
    execDataLink,
    generateDocument,
    onWizardDefinitionChange,
    i18n,
    onFormValuesChange,
    onTypeChange,
    handleChangeDate,
    handleChangeDateTo,
    onCommentChange,
    publishComment,
    nameConfig,
    setNameConfig,
    identifierConfig,
    setIdentifierConfig,
    identifierCollisionForcesNewVersion,
    setIdentifierCollisionForcesNewVersion,
    getTemplateContextSample,
  } = useTemplateEditWizard(
    template,
    selectedTabIndex,
    eventManager,
    setSelectedTabIndex,
    onFinish
  );
  const organizationNavigate = useOrganizationNavigate();

  const {
    extractedContext,
    documentLoadingState,
    setDocumentSearchOpen,
    setDocumentLoadingState,
    isDocumentSearchOpen,
  } = useDocumentLookupState(undefined);

  const onCancel = useCallback(() => {
    organizationNavigate(organizationLinkTemplates.home());
  }, [organizationNavigate]);

  const stepTabsContent = useMemo(() => {
    return [
      {
        title: resources.editTemplatePage.formDesigner,
        content:
          template.info && state.wizardDefinition ? (
            <Box display="flex" direction="column" flex="grow" gap={2}>
              <FormDesigner
                dataElements={emptyArray}
                contextDefinition={template.info?.contextDefinition}
                dataLinkMappings={template.info.dataLinkMappings}
                wizardDefinition={state.wizardDefinition}
                onWizardDefinitionChange={onWizardDefinitionChange}
                nameConfig={nameConfig}
                updateNameConfig={setNameConfig}
                identifierConfig={identifierConfig}
                setIdentifierConfig={setIdentifierConfig}
                identifierCollisionForcesNewVersion={identifierCollisionForcesNewVersion}
                setIdentifierCollisionForcesNewVersion={setIdentifierCollisionForcesNewVersion}
                onCancel={onCancel}
                i18n={i18n}
                formDesignerEventManager={eventManager}
                mode={undefined}
                isContinueButtonDisabled={false}
                setIsContinueButtonDisabled={noop}
              />
            </Box>
          ) : (
            <Spinner accessibilityLabel={""} show={false}></Spinner>
          ),
      },
      {
        title: resources.editTemplatePage.formTesting,
        content:
          !documentLoadingState.isLoading && template?.info && state.wizardDefinition ? (
            <FormControllerProvider
              contextDefinition={template.info.contextDefinition}
              wizardDefinition={state.wizardDefinition}
              dataLinkMappings={[]}
              initialContext={extractedContext}
              executeDataLink={undefined}
              moment={moment}
            >
              <LookupDocument
                onChange={setDocumentLoadingState}
                setDocumentSearchOpen={setDocumentSearchOpen}
                isDocumentSearchOpen={isDocumentSearchOpen}
              />
              <GenerationWizardBoundary
                generating={status === "generating"}
                generateOnFinish={false}
                templateVersionId={template.info.templateVersionId}
                dataLinkMappings={template.info.dataLinkMappings || []}
                DocumentViewerComponentImplementation={DocumentViewer}
                onFormValuesChange={onFormValuesChange}
                executeDataLink={execDataLink}
                generateDocument={generateDocument}
                documentBinary={pdfResponse?.pdfDataUri}
                navigate={organizationNavigate}
                connectors={[]}
                connectorDataLinks={[]}
                dataLinks={[]}
                i18n={i18n}
                onFireConnector={() =>
                  new Promise((resolve) =>
                    resolve({
                      formValues: null,
                      formErrors: null,
                      groupValues: null,
                    })
                  )
                }
                isConfigured={true}
                moment={moment}
                onCancel={() => {
                  eventManager.publish({
                    direction: "prev",
                  });
                }}
                cancelLabel={resources.editTemplate}
                resources={resources}
                onAfterPreview={() => {
                  eventManager.publish({
                    direction: "next",
                  });
                }}
                getTemplateContextSample={getTemplateContextSample}
                onSearch={setDocumentSearchOpen}
              />
            </FormControllerProvider>
          ) : (
            <Spinner accessibilityLabel={resources.loading} show={true}></Spinner>
          ),
      },
      // {
      //   title: resources.editTemplatePage.documentPreview,

      //   content: state.templateBlob ? (
      //     <Box width="100%" height="50vw" borderStyle="shadow" margin="auto">
      //       <DocumentViewer
      //         src={[state.templateBlob]}
      //         height="50vw"
      //         mimeType="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      //         strategy="normal"
      //       />
      //     </Box>
      //   ) : (
      //     <Spinner accessibilityLabel={""} show={true}></Spinner>
      //   ),
      // },
      {
        title: resources.publish,

        content: (
          <PublishContent
            isLoading={isLoading}
            onTypeChange={onTypeChange}
            onCommentChange={onCommentChange}
            publishType={state.publishType}
            eventManager={eventManager}
            fromDate={state.fromDate}
            toDate={state.toDate}
            handleChangeDate={handleChangeDate}
            handleChangeDateTo={handleChangeDateTo}
            publishComment={publishComment}
            i18n={i18n}
            onCancel={onCancel}
          />
        ),
      },
    ];
  }, [
    resources,
    template.info,
    state.wizardDefinition,
    state.publishType,
    state.fromDate,
    state.toDate,
    onWizardDefinitionChange,
    nameConfig,
    setNameConfig,
    identifierConfig,
    setIdentifierConfig,
    identifierCollisionForcesNewVersion,
    setIdentifierCollisionForcesNewVersion,
    onCancel,
    i18n,
    eventManager,
    documentLoadingState,
    extractedContext,
    moment,
    setDocumentLoadingState,
    setDocumentSearchOpen,
    isDocumentSearchOpen,
    status,
    onFormValuesChange,
    execDataLink,
    generateDocument,
    pdfResponse?.pdfDataUri,
    organizationNavigate,
    getTemplateContextSample,
    isLoading,
    onTypeChange,
    onCommentChange,
    handleChangeDate,
    handleChangeDateTo,
    publishComment,
  ]);

  return (
    <Box display="flex" direction="column" flex="grow" width={"100%"}>
      <StepTabs selectedTabIndex={selectedTabIndex} tabs={stepTabsContent} />
    </Box>
  );
};

const PublishContent: FunctionComponent<{
  onTypeChange: ({ value }: { value: string }) => void;
  handleChangeDate: (value: any) => void;
  handleChangeDateTo: (value: any) => void;
  onCommentChange: ({ value }: { value: string }) => void;
  onCancel: () => void;
  i18n: (key: string) => string;
  isLoading: boolean;
  publishType: string;
  eventManager: PubSubEventManager<TemplateEditWizardEvent>;
  fromDate: number | undefined;
  toDate: number | undefined;
  publishComment: string;
}> = ({
  onTypeChange,
  onCommentChange,
  onCancel,
  i18n,
  handleChangeDate,
  handleChangeDateTo,
  isLoading,
  publishType,
  eventManager,
  publishComment,
  fromDate,
  toDate,
}) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  const [isSaving, setIsSaving] = useState(false);

  const publish = useCallback(async () => {
    setIsSaving(true);
    eventManager.publish({
      direction: "next",
    });
  }, [eventManager]);

  const { isLoading: isPublishing, mutate: mutatePublish } = useMutation(publish, {
    onSuccess() {
      setIsSaving(false);
    },
    onError() {
      setIsSaving(false);
    },
  });

  const endDateInput = useRef(null);
  const startDateInput = useRef(null);

  const options = useMemo(() => {
    if (publishOnly) {
      return [{ label: resources.editTemplatePage.publishingNow, value: "1" }];
    }

    return [
      { label: resources.editTemplatePage.publishingNow, value: "1" },
      {
        label: resources.editTemplatePage.publishingAtDate,
        value: "2",
      },
      {
        label: resources.editTemplatePage.publishingDateRange,
        value: "3",
      },
    ];
  }, [resources]);

  return (
    <Center direction="column">
      <Box
        margin={12}
        display="flex"
        direction="column"
        padding={8}
        alignSelf="center"
        width={"50%"}
        color={colors.white}
        borderRadius={12}
        dangerouslySetInlineStyle={{
          __style: {
            borderRight: "1px solid #C7C7C8",
          },
        }}
      >
        <Box marginBottom={4}>
          <Text>{resources.editTemplatePage.publishingType} </Text>
          <SelectList
            id={""}
            onChange={onTypeChange}
            options={options}
            value={publishType}
            disabled={isLoading}
          ></SelectList>
        </Box>
        {!publishOnly && (
          <>
            <Box display="flex" gap={2} direction="row">
              {publishType === "2" || publishType === "3" ? (
                <DatePicker
                  resources={resources}
                  id="fromDate"
                  label={resources.from}
                  placeholder={resources.from}
                  nextRef={endDateInput}
                  onChange={handleChangeDate}
                  rangeSelector="start"
                  ref={startDateInput}
                  value={fromDate ? new Date(fromDate) : undefined}
                  disabled={isLoading}
                  dataType="Date"
                />
              ) : null}
              {publishType === "3" ? (
                <DatePicker
                  resources={resources}
                  id="toDate"
                  label={resources.to}
                  placeholder={resources.to}
                  nextRef={startDateInput}
                  onChange={handleChangeDateTo}
                  rangeSelector="end"
                  ref={endDateInput}
                  value={toDate ? new Date(toDate) : undefined}
                  disabled={isLoading}
                  dataType="Date"
                />
              ) : null}
            </Box>
          </>
        )}
        <Text>{resources.editTemplatePage.comment}</Text>
        <TextArea
          id={"publish_comment"}
          onChange={onCommentChange}
          value={publishComment}
          disabled={isLoading}
        ></TextArea>
        {isLoading || isPublishing || isSaving ? (
          <Spinner show={true} />
        ) : (
          <NavigationButtons
            finishButtonLabel={resources.finish}
            finish={mutatePublish}
            isFinishButtonDisabled={!publishType}
            isLoading={isLoading || isPublishing || isSaving}
            formHasErrors={false}
            hasBackButton={false}
            hasNextButton={false}
            i18n={i18n}
            cancel={onCancel}
            next={noop}
            prev={() => {
              eventManager.publish({
                direction: "prev",
              });
            }}
            summaryMode={false}
          />
        )}
      </Box>
    </Center>
  );
};
