import {
  Box,
  ComboBox,
  DatePicker,
  IconButton,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";
import { FunctionComponent, SyntheticEvent } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { BrowseDocumentLabelsFilter } from "../../../link-templates";
import { XIconSvg } from "../../../svg/XIconSvg";

export type DocumentLabelsFiltersProps = {
  filter: BrowseDocumentLabelsFilter;
  refetch(): void;
  onSelectDocumentType: ({
    item,
  }: {
    event: SyntheticEvent<HTMLInputElement, Event>;
    item: ComboBoxItemType;
  }) => void;
  documentTypeHandleClick: (index: number) => void;
  clearAll: () => void;
  handleFilterChange: (
    filterType: keyof BrowseDocumentLabelsFilter,
    value: {
      value?: string | number | undefined;
    }
  ) => void;
  handleComboBoxClear: () => void;
  handleSetDocumentTypeComboBoxInputValue: (value: string) => void;
  documentTypeComboBoxInputValue: string;
  documentTypeList: {
    label: string;
    value: string;
  }[];
  selectedDocumentTypes: ComboBoxItemType[];
  isBreakpointHd: boolean;
  isSmallOrMediumBreakpoint: boolean;
};

export const DocumentLabelsFilters: FunctionComponent<DocumentLabelsFiltersProps> = ({
  filter,
  refetch,
  onSelectDocumentType,
  documentTypeHandleClick,
  clearAll,
  handleFilterChange,
  handleComboBoxClear,
  handleSetDocumentTypeComboBoxInputValue,
  documentTypeComboBoxInputValue,
  selectedDocumentTypes,
  documentTypeList,
  isBreakpointHd,
  isSmallOrMediumBreakpoint,
}) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  return (
    <Box>
      <Box paddingY={5} display="flex" direction={isBreakpointHd ? "row" : "column"}>
        <Box display="flex" width={isBreakpointHd ? "70%" : "100%"}>
          <Box width={isBreakpointHd ? "100%" : "70%"} display="flex" flex="grow">
            <Box width={"100%"}>
              <ComboBox
                id="documentType"
                onClear={handleComboBoxClear}
                label=""
                inputValue={documentTypeComboBoxInputValue}
                onChange={(event) => handleSetDocumentTypeComboBoxInputValue(event.value as string)}
                accessibilityClearButtonLabel={resources.clear}
                noResultText={resources.dataDictionary.noItems}
                onSelect={onSelectDocumentType}
                size="lg"
                options={documentTypeList}
                placeholder={resources.documentTypes.documentTypeName}
              />
            </Box>
          </Box>
        </Box>
        <Box padding={isBreakpointHd ? undefined : 2} />
        <Box display="flex" width={isBreakpointHd ? "30%" : "100%"} gap={1}>
          <Box flexValue={1} paddingX={isBreakpointHd ? 1 : undefined}>
            <DatePicker
              resources={resources}
              id="startDate"
              placeholder={resources.from}
              value={filter.dateStart ? new Date(filter.dateStart) : undefined}
              onChange={(value) => {
                const dateValue = value.value;
                const timestamp = dateValue ? new Date(dateValue).getTime() : undefined;
                handleFilterChange("dateStart", { value: timestamp });
              }}
              dataType="Date"
            />
          </Box>
          <Box flexValue={1} paddingX={isBreakpointHd ? 1 : undefined}>
            <DatePicker
              resources={resources}
              id="dateEnd"
              value={filter.dateEnd ? new Date(filter.dateEnd) : undefined}
              placeholder={resources.to}
              onChange={(value) => {
                const dateValue = value.value;
                const timestamp = dateValue ? new Date(dateValue).getTime() : undefined;

                handleFilterChange("dateEnd", { value: timestamp });
              }}
              dataType="Date"
            />
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              accessibilityLabel={resources.refresh}
              onClick={refetch}
              icon="arrows-rotate"
            />
          </Box>
        </Box>
      </Box>
      <Box display={"flex"} padding={2} justifyContent={"between"} alignItems={"center"}>
        <Box display="flex" flex="grow" direction="row" alignItems="center">
          <Box display="flex" marginEnd={isSmallOrMediumBreakpoint ? 1 : 3}>
            <Text>{resources.filters}: </Text>
          </Box>
          <Box display="flex" wrap={true}>
            {selectedDocumentTypes.map((dt, index) => (
              <Box
                display="flex"
                alignItems="center"
                height={"auto"}
                width={"fit-content"}
                key={index}
                paddingX={isSmallOrMediumBreakpoint ? 2 : 4}
                paddingY={isSmallOrMediumBreakpoint ? 1 : 2}
                margin={1}
                color={colors.white}
                borderRadius={4}
                onClickCapture={() => documentTypeHandleClick(index)}
                dangerouslySetInlineStyle={{
                  __style: {
                    cursor: "pointer",
                  },
                }}
              >
                <Text color={colors.neutral900}>{dt.label}</Text>

                <Box smMarginStart={isSmallOrMediumBreakpoint ? 1 : 3}>
                  <XIconSvg />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box display="flex" flex="shrink">
          <TapArea onTap={clearAll} accessibilityLabel={resources.clearAll}>
            <Text color={colors.primary}>{resources.clearAll}</Text>
          </TapArea>
        </Box>
      </Box>
    </Box>
  );
};
