import {
  Box,
  BoxWithRef,
  Button,
  Icon,
  Layer,
  Modal,
  Popover,
  SearchSvgIcon,
  Skeleton,
  Table,
  TapArea,
  Text,
  TextField,
  Tooltip,
  VerticalEllipseMenuSvg,
  modalZIndex,
  popupZIndex,
  useColors,
} from "@prodoctivity/design-system";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";
import type { EcmDocument } from "@prodoctivity/types";
import { useCallback, useMemo, useRef, useState } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { LookUpDocumentSvg } from "../../svg/LookUpDocumentSvg";
import { FancyDateTime } from "../Display/FancyDateTime";
import { useLookupDocument } from "./hook";
import { ChevronDownIcon } from "../../svg/ChevronDownIcon";

export type StateProps = {
  context: ParametersObject | undefined;
  isLoading: boolean;
};

export type LookUpDocumentProps = {
  onChange?: (n: StateProps) => void;
  setDocumentSearchOpen: (n: boolean) => void;
  isDocumentSearchOpen: boolean;
};
type PropsDocumentSearchPopover = LookUpDocumentProps & {
  children: React.ReactNode;
  setValue: (n: string) => void;
  updateContext: () => void;
  selectedDocumentInfo: { documentId: string; documentVersionId: string };
  documentResponse:
    | {
        document: EcmDocument;
      }
    | {
        document: undefined;
      }
    | undefined;
  setSelectedDocumentInfo: (n: { documentId: string; documentVersionId: string }) => void;
  loading: boolean;
  setDocumentTypeIdSelected: (n: string) => void;
};

type DocumentInfoTableProps = {
  tableOptions: {
    value: string;
  }[];
  data: {
    name: string;
    documentId: string;
    documentVersionId: string;
    documentTypeName: string;
    date: number;
  }[];
  setSelectedDocumentInfo: (n: { documentId: string; documentVersionId: string }) => void;
  selectedDocumentInfo: { documentId: string; documentVersionId: string };
};

export function LookupDocument({
  isDocumentSearchOpen,
  setDocumentSearchOpen,
  onChange,
}: LookUpDocumentProps) {
  const {
    data,
    value,
    setValue,
    setSelectedDocumentInfo,
    documentTypeIdSelected,
    tableOptions,
    handleSearchSelection,
    resources,
    colors,
    selectedDocumentInfo,
    updateContext,
    isLoading,
    documentResponse,
    documentTypeData,
    loading,
    setDocumentTypeIdSelected,
  } = useLookupDocument({
    onChange,
    isDocumentSearchOpen,
    setDocumentSearchOpen,
  });

  const dropdownOptions = useMemo(() => {
    const values: {
      value: string;
      label: string;
    }[] = [
      {
        value: resources.all,
        label: resources.all,
      },
    ];
    documentTypeData?.documentTypes.forEach((dt) => {
      const value = {
        value: dt.documentTypeVersionId ? dt.documentTypeVersionId : dt.documentTypeId,
        label: dt.name,
      };
      values.push(value);
    });

    return values;
  }, [documentTypeData, resources]);

  return (
    <DocumentSearchPopover
      children={
        <>
          {isDocumentSearchOpen && (
            <Box height={"400px"}>
              <Box display="flex" direction="column" gap={2} position="relative">
                <Box
                  width={"100%"}
                  display="flex"
                  alignItems="center"
                  justifyContent="between"
                  borderStyle="sm"
                  borderRadius={12}
                  padding={1}
                >
                  <Box display="flex" flex="grow" alignItems="center">
                    <DocumentTypeFilterDropdown
                      value={documentTypeIdSelected}
                      onChange={handleSearchSelection}
                      items={dropdownOptions}
                    />
                    <Box flex="grow">
                      <TextField
                        id={"Look_up_document"}
                        value={value}
                        onChange={(e) => {
                          setValue(e.value);
                        }}
                        placeholder={resources.search}
                        mode={"unstyled"}
                        autoComplete={"off"}
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={32}
                    height={32}
                    position="absolute"
                    right
                  >
                    <Icon
                      color={colors.neutral900}
                      icon="search"
                      accessibilityLabel={resources.search}
                    />
                  </Box>
                </Box>
                {data.length === 0 && !isLoading && (
                  <Box
                    maxHeight={350}
                    height={350}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                  >
                    <LookUpDocumentSvg width={300} height={250} />
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      direction="column"
                    >
                      <Text size="600" color={colors.neutral900}>
                        {resources.searchForDocuments}
                      </Text>
                      <Text size="300" color={colors.neutral700}>
                        {resources.byNameOrByTypeOfDocument}
                      </Text>
                    </Box>
                  </Box>
                )}
                {!data && isLoading && <Skeleton show={isLoading}></Skeleton>}

                {data.length > 0 && !isLoading && (
                  <DocumentInfoTable
                    tableOptions={tableOptions}
                    data={data}
                    setSelectedDocumentInfo={setSelectedDocumentInfo}
                    selectedDocumentInfo={selectedDocumentInfo}
                  />
                )}
              </Box>
            </Box>
          )}
        </>
      }
      isDocumentSearchOpen={isDocumentSearchOpen}
      setDocumentSearchOpen={setDocumentSearchOpen}
      setValue={setValue}
      updateContext={updateContext}
      selectedDocumentInfo={selectedDocumentInfo}
      documentResponse={documentResponse}
      setSelectedDocumentInfo={setSelectedDocumentInfo}
      loading={loading}
      setDocumentTypeIdSelected={setDocumentTypeIdSelected}
    />
  );
}

const DocumentInfoTable = ({
  tableOptions,
  data,
  setSelectedDocumentInfo,
  selectedDocumentInfo,
}: DocumentInfoTableProps) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  return (
    <Table accessibilityLabel={resources.search}>
      <Table.Header>
        <Table.Row>
          {tableOptions.map((i, idx) => {
            return <Table.HeaderCell key={idx}>{i.value}</Table.HeaderCell>;
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data?.map((d, idx) => {
          const disableColors =
            selectedDocumentInfo.documentVersionId === d.documentVersionId
              ? colors.neutral900
              : undefined;
          return (
            <Table.Row key={idx} hoverStyle={"gray"}>
              <Table.Cell>
                <Box width={200}>
                  <TapArea
                    onTap={() => {
                      setSelectedDocumentInfo({
                        documentId: d.documentId,
                        documentVersionId: d.documentVersionId,
                      });
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Text
                        size="100"
                        overflow="ellipsis"
                        title={d.documentTypeName}
                        color={disableColors}
                      >
                        {d.documentTypeName}
                      </Text>
                    </Box>
                  </TapArea>
                </Box>
              </Table.Cell>

              <Table.Cell>
                <Box width={300}>
                  <TapArea
                    onTap={() => {
                      setSelectedDocumentInfo({
                        documentId: d.documentId,
                        documentVersionId: d.documentVersionId,
                      });
                    }}
                  >
                    <Box>
                      <Text size="100" overflow="ellipsis" title={d.name} color={disableColors}>
                        {d.name}
                      </Text>
                    </Box>
                  </TapArea>
                </Box>
              </Table.Cell>
              <Table.Cell>
                <Box width={100}>
                  <TapArea
                    onTap={() => {
                      setSelectedDocumentInfo({
                        documentId: d.documentId,
                        documentVersionId: d.documentVersionId,
                      });
                    }}
                  >
                    <FancyDateTime size="100" value={d.date} color={disableColors} />
                  </TapArea>
                </Box>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

const DocumentSearchPopover = ({
  children,
  setValue,
  isDocumentSearchOpen,
  setDocumentSearchOpen,
  updateContext,
  documentResponse,
  setSelectedDocumentInfo,
  loading,
  setDocumentTypeIdSelected,
}: PropsDocumentSearchPopover) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const resetDocumentSelection = useCallback(() => {
    setValue("");
    setDocumentSearchOpen(false);
    setSelectedDocumentInfo({ documentId: "", documentVersionId: "" });
    setDocumentTypeIdSelected(resources.all);
  }, [
    setValue,
    setDocumentSearchOpen,
    setSelectedDocumentInfo,
    setDocumentTypeIdSelected,
    resources.all,
  ]);
  const isButtonDisabled = documentResponse?.document === undefined;

  return (
    <>
      {isDocumentSearchOpen && (
        <Layer zIndex={modalZIndex}>
          <Modal
            accessibilityModalLabel={resources.delete_}
            heading={
              <Box top right width={"100%"} padding={6} position="absolute">
                <Text size="400" color={colors.secondary}>
                  {resources.selectFromDocument}
                </Text>
              </Box>
            }
            size="md"
            onDismiss={resetDocumentSelection}
            footer={
              <Box width={"100%"} display="flex" gap={2} justifyContent="end" alignItems="end">
                <Button
                  text={!loading ? resources.replace : resources.loading}
                  onClick={updateContext}
                  disabled={isButtonDisabled}
                />

                <Button color="gray" text={resources.cancel} onClick={resetDocumentSelection} />
              </Box>
            }
          >
            {children}
          </Modal>
        </Layer>
      )}
    </>
  );
};

export const LookupDocumentButton = ({ onchange }: { onchange: (n: boolean) => void }) => {
  const [show, setShow] = useState(false);
  const [colorHovers, setColors] = useState(false);
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  return (
    <Box position="relative">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        direction="column"
        position="absolute"
        zIndex={popupZIndex}
        right={true}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <TapArea
            onTap={() => {
              setShow(!show);
              setColors(!colorHovers);
            }}
          >
            <Text title={resources.open}>
              <VerticalEllipseMenuSvg color={colorHovers ? colors.primary : undefined} />
            </Text>
          </TapArea>
        </Box>

        {show && (
          <Box
            color={colors.white}
            display="flex"
            direction="column"
            alignItems="center"
            justifyContent="center"
            hoverColor={colors.neutral700}
            borderRadius={4}
          >
            <TapArea
              onTap={() => {
                setShow(false);
                onchange(true);
                setColors(!colorHovers);
              }}
            >
              <Tooltip text={resources.copyFromDocument}>
                <SearchSvgIcon color={colors.white} width={32} height={32} />
              </Tooltip>
            </TapArea>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const DocumentTypeFilterDropdown = ({
  value,
  onChange,
  items,
}: {
  value: string | undefined;
  onChange(value: string | undefined): void;
  items: Array<{
    value: string;
    label: string;
  }>;
}) => {
  const [open, setOpen] = useState(false);
  const { colors } = useColors();
  const anchorRef = useRef(null);
  const { resources } = useAppTranslation();
  const [filterValue, setFilterValue] = useState("");

  const selected: { value: string; label: string } | undefined = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    const found = (items || []).find((item) => item.value === value);

    if (!found) {
      return undefined;
    }

    return {
      value: found.value,
      label: found.label,
    };
  }, [items, value]);

  const itemsFiltered = useMemo(() => {
    return items.filter((item) => {
      return item.label.toLowerCase().includes(filterValue.toLowerCase());
    });
  }, [items, filterValue]);

  const selectedAll = selected && selected.label === resources.all;

  return (
    <Box>
      <BoxWithRef ref={anchorRef}>
        <TapArea onTap={() => setOpen((prevVal) => !prevVal)}>
          <Box
            hoverColor={colors.neutral400}
            width={200}
            padding={1}
            borderRadius={4}
            color={selectedAll ? colors.neutral200 : colors.neutral400}
            display="flex"
            alignItems="center"
            justifyContent="between"
          >
            <Text overflow="ellipsis">{selected ? selected.label : resources.all}</Text>

            <ChevronDownIcon />
          </Box>
        </TapArea>
      </BoxWithRef>

      {open && (
        <Layer zIndex={popupZIndex}>
          <Popover
            anchor={anchorRef.current}
            onDismiss={() => {
              setFilterValue("");
              setOpen(false);
            }}
            idealDirection="down"
            positionRelativeToAnchor={true}
            shouldFocus={true}
            color="white"
            size={"flexible"}
          >
            <Box padding={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="between"
                borderStyle="sm"
                borderRadius={12}
                paddingX={2}
              >
                <Box display="flex" flex="grow">
                  <Box flex="grow">
                    <TextField
                      id={"search_look_up_document_type"}
                      value={filterValue}
                      onChange={(e) => {
                        setFilterValue(e.value);
                      }}
                      placeholder={resources.searchForDocumentType}
                      mode={"unstyled"}
                      autoComplete={"off"}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={20}
                  height={20}
                  position="absolute"
                  right
                  marginEnd={5}
                >
                  <Icon icon="search" accessibilityLabel={resources.search} />
                </Box>
              </Box>
              {itemsFiltered.map((item) => (
                <Box key={item.value} marginTop={1}>
                  <TapArea>
                    <Box
                      hoverColor={colors.neutral600}
                      display="flex"
                      padding={2}
                      flex="grow"
                      alignItems="center"
                      width={250}
                      onClickCapture={() => {
                        onChange(item.value);
                        setOpen(false);
                      }}
                    >
                      <Text overflow="ellipsis">{item.label}</Text>
                    </Box>
                  </TapArea>
                </Box>
              ))}
            </Box>
          </Popover>
        </Layer>
      )}
    </Box>
  );
};
