import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, BoxWithRef } from "../Box";
import { Layer, Popover } from "../layout";
import { FourDirections } from "gestalt";
import { SearchSvgIcon } from "../../svg/SearchSvgIcon";
import { useColors } from "../ColorSchemeProvider";
import { Icon } from "../Icon";
import { TapArea } from "../TapArea";
import { Text } from "../Text";
import { TextField } from "../TextField";
import { ChevronDownSolid } from "./ChevronDownSolid";

export type CustomComboBoxOption = {
  subtext: string | undefined;
  label: string;
  value: string;
  disabled?: boolean | undefined;
};

type Props = {
  getI18n(key: string): string;
  selectedValue?: CustomComboBoxOption;
  options: Array<CustomComboBoxOption>;
  onSelect: (value: string) => void;
  placeholder: string | undefined;
  direction: FourDirections | undefined;
  onClear: (() => void) | undefined;
};

export const CustomComboBox: FunctionComponent<Props> = ({
  getI18n,
  selectedValue,
  onSelect,
  options,
  placeholder,
  direction,
  onClear,
}) => {
  const { colors } = useColors();
  const [open, setOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [filter, setFilter] = useState("");
  const onFilterChange = useCallback((newFilter: string) => {
    setFilter(newFilter);
  }, []);

  const anchorRef = useRef(null);
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const listItemRefs = useRef<Array<HTMLDivElement | null>>([]);

  const openSearch = useCallback((value: boolean) => {
    if (!value) {
      return setSearchFilter(false);
    }
    setSearchFilter(true);
    setTimeout(() => {
      searchFieldRef.current?.focus();
    }, 0);
  }, []);

  const filteredOptions = useMemo(() => {
    return (options || []).filter((opt) =>
      filter && opt ? opt.label.toLowerCase().includes(filter.toLowerCase()) : true
    );
  }, [options, filter]);

  const handleKeyDown = useCallback(
    (key: string) => {
      const maxIndex = options.length - 1;

      if (key === "Enter" && onClear) {
        onClear();
        setFocusedIndex(-1);
        return;
      }

      if (focusedIndex === 0 && key === "ArrowUp") {
        setSearchFilter((prev) => !prev);
        setFocusedIndex(-1);
        return;
      }

      switch (key) {
        case "ArrowDown":
          setFocusedIndex((prevIndex) => (prevIndex < maxIndex ? prevIndex + 1 : prevIndex));
          break;
        case "ArrowUp":
          setFocusedIndex((prevIndex) => (prevIndex > -1 ? prevIndex - 1 : prevIndex));
          break;
      }
    },
    [focusedIndex, onClear, options.length]
  );

  useEffect(() => {
    if (focusedIndex === -1 && searchFieldRef.current) {
      searchFieldRef.current.focus();
    } else {
      listItemRefs.current[focusedIndex]?.focus();
    }
  }, [focusedIndex]);

  return (
    <Box height="100%" width="100%">
      <BoxWithRef ref={anchorRef}>
        {!searchFilter ? (
          <TapArea
            onTap={() => {
              setOpen(true);
              onFilterChange("");
              openSearch(true);
            }}
          >
            <Box
              color={colors.white}
              display="flex"
              paddingLeft={4}
              paddingRight={1}
              paddingY={2}
              borderRadius={12}
              borderStyle="lg"
              borderColor={colors.neutral700}
              justifyContent={"between"}
              alignItems="center"
              width={"100%"}
              minWidth={300}
              height={40}
              gap={2}
            >
              {selectedValue !== undefined ? (
                <Box display="flex" justifyContent={"between"} alignItems="center" width={"100%"}>
                  <Text size="200" overflow="ellipsis">
                    {selectedValue.label}
                  </Text>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <TapArea
                      onTap={() => {
                        if (onClear) onClear();
                        setFocusedIndex(-1);
                      }}
                    >
                      <Box
                        width={40}
                        height={32}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Icon
                          icon={"close"}
                          size={"sm"}
                          color={colors.black900}
                          accessibilityLabel={getI18n("clear")}
                        />
                      </Box>
                    </TapArea>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" justifyContent={"end"} alignItems="center" width={"100%"}>
                  <Box
                    width={32}
                    height={32}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    paddingBottom={1}
                  >
                    <ChevronDownSolid color={colors.black600} width={32} height={32} />
                  </Box>
                </Box>
              )}
            </Box>
          </TapArea>
        ) : (
          <Box
            color={colors.white}
            display="flex"
            paddingLeft={2}
            paddingRight={2}
            paddingY={2}
            borderRadius={12}
            borderStyle="lg"
            borderColor={colors.neutral700}
            justifyContent={selectedValue ? "between" : "start"}
            alignItems="center"
            width={"100%"}
            height={40}
            minWidth={300}
          >
            <Box flex="grow">
              <TextField
                autoComplete="off"
                onFocus={() => setOpen(true)}
                id={"document-collection-search"}
                onChange={({ value }) => {
                  setOpen(true);
                  onFilterChange(value);
                }}
                ref={searchFieldRef}
                onKeyDown={(e) => {
                  if (e.event.key === "ArrowDown") {
                    e.event.preventDefault();
                  }
                  handleKeyDown(e.event.key);
                }}
                mode="unstyled"
                placeholder={placeholder ? placeholder : getI18n("search")}
                value={filter}
                onBlur={() => openSearch(false)}
              />
            </Box>

            <Box display="flex" alignItems="center" justifyContent="center">
              <SearchSvgIcon width={28} height={28} />
            </Box>
          </Box>
        )}
      </BoxWithRef>

      {open && (
        <Layer>
          <Popover
            accessibilityLabel="Icon"
            anchor={anchorRef.current}
            idealDirection={direction ? direction : "down"}
            onDismiss={() => {
              setOpen(false);
              openSearch(false);
              setFocusedIndex(-1);
            }}
            positionRelativeToAnchor={false}
            size="xl"
            // showDismissButton={true}
            color="white"
            role="menu"
          >
            <Box minWidth={300} color={colors.white}>
              <Box height={"fit-content"} maxHeight={300} overflow="auto">
                <Box margin={1}>
                  <Box display="flex" direction="column">
                    {filteredOptions.length > 0 ? (
                      filteredOptions.map((item, index) => (
                        <TapArea
                          key={item.value}
                          onTap={() => {
                            if (onClear) {
                              // to prevent dependent fields (targetValue) to throw error by reading old values based on previous selection
                              onClear();
                            }
                            setOpen(false);
                            openSearch(false);
                            onSelect(item.value);
                          }}
                        >
                          <BoxWithRef
                            display="flex"
                            paddingX={3}
                            tabIndex={index}
                            paddingY={1}
                            onKeyDown={(e) => {
                              if (
                                (focusedIndex === -1 ||
                                  focusedIndex === 0 ||
                                  focusedIndex === filteredOptions.length - 1) &&
                                e.key !== "Enter"
                              ) {
                                e.preventDefault();
                              }
                              handleKeyDown(e.key);
                            }}
                            justifyContent="center"
                            direction="column"
                            gap={1}
                            hoverColor={colors.neutral400}
                            borderRadius={12}
                            borderColor={colors.white}
                            height={item.subtext ? 52 : 42}
                            ref={(ref) => (listItemRefs.current[index] = ref)}
                            color={focusedIndex === index ? colors.neutral400 : undefined}
                          >
                            <Text size="200" overflow="ellipsis">
                              {item.label}
                            </Text>
                            {item.subtext && (
                              <Text size="100" color={colors.neutral700} overflow="ellipsis">
                                {item.subtext}
                              </Text>
                            )}
                          </BoxWithRef>
                        </TapArea>
                      ))
                    ) : (
                      <Box display="flex" paddingX={3} paddingY={1} alignItems="center" height={42}>
                        <Text size="200" overflow="ellipsis" color={colors.neutral700}>
                          {getI18n("noResultsFound")}
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Popover>
        </Layer>
      )}
    </Box>
  );
};
