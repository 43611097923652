import { Box, Divider, Text, useColors } from "@prodoctivity/design-system";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { DocumentCollectionState, useStructureStep } from "../hooks";
import { DocumentTreeItem, FolderTreeItem } from "./Components/FolderTreeItem";

import { ComboBoxProps } from "@prodoctivity/design-system/components/ComboBox";
import { areArraysEqual } from "@prodoctivity/shared";
import type { DocumentTypeResume } from "@prodoctivity/types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { AddFolderIconSvg } from "../../../../svg/DocumentCollection/AddFolderIconSvg";
import { SettingsSvg } from "../../../../svg/SettingsSvg";
import { TableSvgIcon } from "../../../../svg/TableSvgIcon";
import { emptyArray } from "../../../../utils";
import { TreeItemSettings } from "./Components/TreeItemSettings";
import { nameConfigContextToString } from "./utils";

type Props = {
  isLoading: boolean;
  formState: DocumentCollectionState;
  documentTypes: DocumentTypeResume[];
  documentTypesOptions: ComboBoxProps["options"];
  setFormState: Dispatch<SetStateAction<DocumentCollectionState>>;
  setShowMainSaveButton: Dispatch<SetStateAction<boolean>>;
};

export const StructureStep: FunctionComponent<Props> = ({
  formState,
  documentTypes,
  documentTypesOptions,
  setFormState,
  setShowMainSaveButton,
}) => {
  const { resources, moment } = useAppTranslation();
  const {
    rootTreeItem,
    configItem,
    docTypesAvailableToAdd,
    setConfigItem,
    onConfigTreeItem,
    onRootConfig,
    onCancelConfig,
    onSaveConfig,
    dropPosition,
    onNewFolder,
    addDocumentTypeToFolder,
    removeSelectedItem,
    handleDragStart,
    handleDragOver,
    handleDrop,
  } = useStructureStep(formState, documentTypesOptions, setFormState);

  const { colors } = useColors();

  return (
    <Box display="flex" direction="column" flex="grow" padding={2} width={"100%"}>
      <Box display="flex" flex="grow" gap={3}>
        <Box
          display="flex"
          flexValue={1}
          direction="column"
          flex="grow"
          borderRadius={4}
          borderStyle="lg"
        >
          <Box
            height={48}
            display="flex"
            alignItems="center"
            justifyContent="between"
            color={colors.neutral200}
            paddingX={3}
          >
            <Box display="flex" alignItems="center">
              <TableSvgIcon />
              <Text weight="bold" color={colors.subtle}>
                {formState.name}
              </Text>
            </Box>
            <Box marginEnd={5} display="flex" alignItems="center">
              <AddFolderIconSvg
                height={26}
                width={26}
                color={colors.subtle}
                onClick={() => onNewFolder([])}
              />
              <SettingsSvg color={colors.subtle} onClick={onRootConfig} />
            </Box>
          </Box>
          <Divider />
          <Box display="flex" direction="column" flex="shrink" overflow="auto">
            {formState.treeStructure.folders.map((f, i) => {
              return (
                <FolderTreeItem
                  key={`${nameConfigContextToString(moment, f.nameConfig)}`}
                  resources={resources}
                  currIndex={i}
                  parentLength={
                    formState.treeStructure.folders.length +
                    formState.treeStructure.documentTypes.length
                  }
                  folder={f}
                  folderPath={emptyArray}
                  dropPosition={dropPosition}
                  documentTypes={documentTypes}
                  selectedItem={configItem?.originalItem}
                  rootTreeItem={rootTreeItem}
                  docTypesAvailableToAdd={docTypesAvailableToAdd}
                  addDocumentTypeToFolder={addDocumentTypeToFolder}
                  onConfigTreeItem={onConfigTreeItem}
                  onNewFolder={onNewFolder}
                  removeSelectedItem={removeSelectedItem}
                  handleDragStart={handleDragStart}
                  handleDragOver={handleDragOver}
                  handleDrop={handleDrop}
                  setShowMainSaveButton={setShowMainSaveButton}
                />
              );
            })}
            {formState.treeStructure.documentTypes.map((d, i) => {
              return (
                <DocumentTreeItem
                  key={`${d.documentTypeId}`}
                  currIndex={i}
                  parentLength={formState.treeStructure.documentTypes.length}
                  documentType={d}
                  folderPath={emptyArray}
                  documentTypes={documentTypes}
                  dropPosition={dropPosition}
                  selectedItem={configItem?.originalItem}
                  rootTreeItem={rootTreeItem}
                  removeSelectedItem={removeSelectedItem}
                  onConfigTreeItem={onConfigTreeItem}
                  handleDragStart={handleDragStart}
                  handleDragOver={handleDragOver}
                  handleDrop={handleDrop}
                  setShowMainSaveButton={setShowMainSaveButton}
                />
              );
            })}
            <Box
              minHeight={35}
              display="flex"
              direction="column"
              flex="shrink"
              borderStyle="raisedTopShadow"
              width={"100%"}
              paddingLeft={4}
              justifyContent="center"
              onDrop={handleDrop}
              onDragOver={(e) => handleDragOver(e, rootTreeItem)}
              color={
                dropPosition && areArraysEqual(rootTreeItem.path, dropPosition?.path)
                  ? colors.primary100
                  : undefined
              }
            >
              <Text color={colors.subtle}>{resources.documentCollection.dragToMainLevel}</Text>
            </Box>
            <Box display="flex" direction="column" flex="grow" />
          </Box>
        </Box>
        <Box display="flex" flexValue={1} direction="column" borderRadius={4} flex="grow">
          {configItem?.updatedItem && (
            <TreeItemSettings
              rootInfo={
                configItem.isRootFolder
                  ? {
                      isRoot: true,
                      trackingIssuesDueDateHours: formState.trackingIssuesDueDateHours,
                    }
                  : { isRoot: false }
              }
              resources={resources}
              treeItem={configItem?.updatedItem}
              documentTypes={documentTypes}
              formState={formState}
              setConfigItem={setConfigItem}
              onCancelConfig={onCancelConfig}
              onSaveConfig={onSaveConfig}
              documentTypesOptions={documentTypesOptions}
              setShowMainSaveButton={setShowMainSaveButton}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
