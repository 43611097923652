import {
  Box,
  Button,
  ComboBox,
  Divider,
  SearchField,
  TapArea,
  Text,
  Tooltip,
  useColors,
} from "@prodoctivity/design-system";
import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";
import type {
  HttpGetBundleConfigListRequest,
  HttpGetBundleConfigListResponse,
} from "@prodoctivity/types";
import { FunctionComponent, useCallback } from "react";

import { FancyDateTime } from "../../../components/Display/FancyDateTime";
import { Page } from "../../../components/Layout/Page";
import { Pagination } from "../../../components/Layout/Pagination";
import { SearchComponentWrapper } from "../../../components/SearchComponentWrapper";
import { usePaginatedDataEndpoint } from "../../../components/hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../link-templates";
import { CircleCheckSvgIcon } from "../../../svg/CircleCheckSvgIcon";
import { noop } from "../../../utils";
import { BundleIcon } from "./BundleIcon";
import { useBundleConfigList } from "./hooks";

export const BundleConfigListPage: FunctionComponent = () => {
  const { resources, breakpoint, breadCrumbEntries, language, languageOptions, onSelectLanguage } =
    useBundleConfigList();
  const {
    paginatedData,
    setFilter,
    currentPage,
    totalRowCount,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    refetch,
  } = usePaginatedDataEndpoint<
    {
      bundleList: HttpGetBundleConfigListResponse["payload"]["bundleList"];
    },
    "15" | "30" | "50",
    string
  >(
    "15",
    "",
    useCallback(
      (services, currentPage, rowsPerPage, filter) => {
        return services.getBundleConfigList(
          currentPage,
          rowsPerPage,
          language.value as HttpGetBundleConfigListRequest["queryParameters"]["languageFilter"],
          filter
        );
      },
      [language]
    ),
    `settings_bundle_config_list_${language.value}`
  );

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <SearchComponentWrapper
          componentHeaderText={resources.bundle.bundles}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="bundle-config-list-search"
          searchFieldLabel={resources.search}
          setFilter={setFilter}
          omitSearch={true}
        />
        <Box paddingX={3} display="flex" direction="column">
          <Pagination<typeof rowsPerPage>
            id="bundle_config_list_pagination_top"
            rowsLabel={`${resources.bundle.bundles}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.bundleList.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={[rowsPerPage]}
            totalRowCount={totalRowCount}
            extraComponent={
              <Box display="flex" gap={3} flex="grow">
                <SearchField
                  marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                  marginEnd={2}
                  accessibilityLabel={resources.bundle.bundles}
                  id="bundle_config_list_filter"
                  onChange={({ value }) => {
                    setFilter(value);
                  }}
                />
                <ComboBox
                  id="bundleConfigLanguage"
                  label=""
                  inputValue={language?.label}
                  noResultText={resources.dataDictionary.noItems}
                  onSelect={onSelectLanguage}
                  size="lg"
                  options={languageOptions}
                  placeholder={resources.language}
                />
              </Box>
            }
          />
        </Box>
        <Box paddingX={5} marginTop={4}>
          <Box display="flex" direction="column" gap={3}>
            {(paginatedData?.bundleList || []).map((bundleConfig, idx) => (
              <Box key={idx}>
                <BundleConfigCard
                  resources={resources}
                  languageOptions={languageOptions}
                  bundleConfig={bundleConfig}
                />
                {idx < (paginatedData?.bundleList || []).length && (
                  <Box marginTop={4}>
                    <Divider />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

type BundleConfigCardProps = {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  languageOptions: Array<ComboBoxItemType>;
  bundleConfig: HttpGetBundleConfigListResponse["payload"]["bundleList"][0];
};
const BundleConfigCard = ({ resources, languageOptions, bundleConfig }: BundleConfigCardProps) => {
  const organizationNavigate = useOrganizationNavigate();
  const { colors } = useColors();
  return (
    <TapArea
      onTap={() =>
        organizationNavigate(organizationLinkTemplates.bundleConfigView(bundleConfig.identifier))
      }
    >
      <Box color={colors.white} minHeight={150} display="flex" padding={2}>
        <Box display="flex" direction="column" width={"13%"} justifyContent="center">
          <Box alignSelf="center">
            <BundleIcon bundleIdentifier={bundleConfig.identifier} />
          </Box>
        </Box>
        <Box alignItems="center" display="flex" width={"100%"} paddingX={3}>
          <Box display="flex" direction="column" gap={1}>
            <Box display="flex" gap={1} alignItems="center">
              <Text weight="bold">{bundleConfig.name}</Text>
              {bundleConfig.lastVersionApplied !== undefined && (
                <Tooltip text={resources.applied}>
                  <CircleCheckSvgIcon width={14} height={14} />
                </Tooltip>
              )}
            </Box>
            <Box display="flex" gap={1}>
              <FancyDateTime
                value={bundleConfig.versionDate}
                color={colors.primary400}
                size="200"
                showTime={true}
              />
              <Text color={colors.primary400} size="200">
                - {languageOptions.find((l) => l.value === bundleConfig.language)?.label}
              </Text>
            </Box>
            <Text>{bundleConfig.description}</Text>
          </Box>
        </Box>
        <Box
          right
          width={"15%"}
          direction="column"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width={100} height={34}>
            <Button color={"gray"} text={resources.view} onClick={noop} />
          </Box>
          {bundleConfig.lastVersionApplied &&
            bundleConfig.lastVersionApplied < bundleConfig.versionNumber && (
              <Box marginTop={3}>
                <Text size="200" weight="bold" color={colors.primary}>
                  {resources.updateAvailable}
                </Text>
              </Box>
            )}
        </Box>
      </Box>
    </TapArea>
  );
};

export default BundleConfigListPage;
