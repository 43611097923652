import { OrganizationUserLanguage$Schema } from "@prodoctivity/shared";
import type { GlobalPermissionType } from "@prodoctivity/shared/src/index-types";
import { createContext } from "react";
import { Services } from "./services";

export type AccountUserToken = {
  token: string;
  email: string;
  username: string;
  organizationId: string;
  organizationName: string;
  permissions: GlobalPermissionType[];
  expires: number;
};

export type AccountUser = AccountUserToken & {
  organizationName: string;
  firstName: string;
  lastName: string;
  positionRole: string;
};

const nullService: Services = {
  messageEventHandlers: {
    "send-notifications-to-user": [],
  },
  user: {
    email: "",
    organizationId: "",
    organizationName: "",
    token: "",
    username: "",
    permissions: [],
    expires: 0,
  },
  baseUrl: "",
  token: undefined,
  getTempLanguage() {
    return OrganizationUserLanguage$Schema.parse("");
  },
  setTempLanguage() {
    throw new Error();
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async logout() {},
  saveToken() {
    throw new Error();
  },
  authenticate() {
    throw new Error();
  },
  addUserToOrganization() {
    throw new Error();
  },
  shareDocument() {
    throw new Error();
  },
  contactHelp() {
    throw new Error();
  },
  leaveOrganization() {
    throw new Error();
  },
  renewToken() {
    throw new Error();
  },
  userOrganizations() {
    throw new Error();
  },
  switchOrganization() {
    throw new Error();
  },
  fetchTemplateInfo() {
    throw new Error();
  },
  getDocumentSummaryInfo() {
    throw new Error();
  },
  getCombinedTemplateList() {
    throw new Error();
  },
  getCombinedTemplate() {
    throw new Error();
  },
  getCombinedTemplateDefinition() {
    throw new Error();
  },
  deleteCombinedTemplate() {
    throw new Error();
  },
  createOrUpdateCombinedTemplate() {
    throw new Error();
  },
  fetchTemplateList() {
    throw new Error();
  },
  fetchTemplateListWithoutPagination() {
    throw new Error();
  },
  getDataLinkConfig() {
    throw new Error();
  },
  impersonateUser() {
    throw new Error();
  },
  executeDataLink() {
    throw new Error();
  },
  forgotPassword() {
    throw new Error();
  },
  changePassword() {
    throw new Error();
  },
  adminChangePassword() {
    throw new Error();
  },
  generateDocumentsSync() {
    throw new Error();
  },
  createOrganization() {
    throw new Error();
  },
  deleteOrganizationPetition() {
    throw new Error();
  },
  deleteMyAccountPetition() {
    throw new Error();
  },
  isDeletionProcessInitiated() {
    throw new Error();
  },
  isDeletionAccountProcessInitiated() {
    throw new Error();
  },
  organizationExists() {
    throw new Error();
  },
  loginGetOrganizationList() {
    throw new Error();
  },
  verifyToken() {
    throw new Error();
  },
  verifyPasswordToken() {
    throw new Error();
  },
  activateJoinOrganization() {
    throw new Error();
  },
  activateForgotPasswordOTP() {
    throw new Error();
  },
  userActivateOrganization() {
    throw new Error();
  },
  resendAccountActivationInvitation() {
    throw new Error();
  },
  fetchOrganization() {
    throw new Error();
  },
  fetchOrganizationAdminInfo() {
    throw new Error();
  },
  getNotifications() {
    throw new Error();
  },
  markNotificationAsRead() {
    throw new Error();
  },
  removeNotifications() {
    throw new Error();
  },
  getMyUserProfile() {
    throw new Error();
  },
  getListOfRecentDocument() {
    throw new Error();
  },
  getMyUserProfileDocumentLabels() {
    throw new Error();
  },
  updateMyUserProfileDocumentLabels() {
    throw new Error();
  },
  deleteMyUserProfileDocumentLabel() {
    throw new Error();
  },
  getActionLogs() {
    throw new Error();
  },
  getCollectionActionLogs() {
    throw new Error();
  },
  getHistoryActionLogs() {
    throw new Error();
  },
  getActionEntry() {
    throw new Error();
  },
  getDocuments() {
    throw new Error();
  },
  getDocumentsWithLists() {
    throw new Error();
  },
  getDistributionImplementations() {
    throw new Error();
  },
  getDistribution() {
    throw new Error();
  },
  getDistributions() {
    throw new Error();
  },
  async getDeferredDistributionList() {
    throw new Error();
  },
  saveDistribution() {
    throw new Error();
  },
  disableDistribution() {
    throw new Error();
  },
  deleteDistribution() {
    throw new Error();
  },
  getUserPublicProfile() {
    throw new Error();
  },
  updateUserProfile() {
    throw new Error();
  },
  updateOrganizationUser() {
    throw new Error();
  },
  getTemplateVersionList() {
    throw new Error();
  },
  getAllDocumentGroups() {
    throw new Error();
  },
  getDocumentGroupList() {
    throw new Error();
  },
  getLastDocument() {
    throw new Error();
  },
  saveDocumentGroup() {
    throw new Error();
  },
  removeDocumentGroup() {
    throw new Error();
  },
  getDocumentTypeInfo() {
    throw new Error();
  },
  getDocumentTypeVersionInfo() {
    throw new Error();
  },
  search() {
    throw new Error();
  },
  searchSuggestions() {
    throw new Error();
  },
  getDocumentTypesWithCommonFields() {
    throw new Error();
  },
  getDocumentTypeList() {
    throw new Error();
  },
  getAllDocumentTypes() {
    throw new Error();
  },
  saveDocumentType() {
    throw new Error();
  },
  getDocumentTypesMergedContext() {
    throw new Error();
  },
  getDocumentCollectionFolderMergedContext() {
    throw new Error();
  },
  intersectionContextFields() {
    throw new Error();
  },
  saveDocument() {
    throw new Error();
  },
  restoreDocumentVersion() {
    throw new Error();
  },
  deleteDocument() {
    throw new Error();
  },
  getDocument() {
    throw new Error();
  },
  getWordAsPdf() {
    throw new Error();
  },
  getDocumentByIdentifier() {
    throw new Error();
  },
  getDocumentVersions() {
    throw new Error();
  },
  getDocumentHistory() {
    throw new Error();
  },
  getDocumentVersionGenerationContext() {
    throw new Error();
  },
  getAllOrganizationRoles() {
    throw new Error();
  },
  getOrganizationRoles() {
    throw new Error();
  },
  getOrganizationRole() {
    throw new Error();
  },
  createOrganizationRole() {
    throw new Error();
  },
  updateOrganizationRole() {
    throw new Error();
  },
  deleteOrganizationRole() {
    throw new Error();
  },
  createOrganizationGraphicMark() {
    throw new Error();
  },
  updateOrganizationGraphicMark() {
    throw new Error();
  },
  deleteOrganizationGraphicMark() {
    throw new Error();
  },
  allOrganizationGraphicMarks() {
    throw new Error();
  },
  getOrganizationGraphicMarks() {
    throw new Error();
  },
  getOrganizationGraphicMark() {
    throw new Error();
  },
  getOrganizationUser() {
    throw new Error();
  },
  getGenerationStatus() {
    throw new Error();
  },

  getGenerationStatusDocument() {
    throw new Error();
  },
  getTemplateContextSampleRequest() {
    throw new Error();
  },
  getGenerations() {
    throw new Error();
  },
  getPaginatedOrganizationDataElements() {
    throw new Error();
  },

  getDocumentByGenerationToken() {
    throw new Error();
  },
  getDocumentsByGenerationToken() {
    throw new Error();
  },
  getDataElement() {
    throw new Error();
  },
  saveDataElement() {
    throw new Error();
  },
  updateDataElement() {
    throw new Error();
  },
  deleteDataElement() {
    throw new Error();
  },
  getOutputFormats() {
    throw new Error();
  },
  getInputMasks() {
    throw new Error();
  },
  getPaginatedOrganizationDictionaryLists() {
    throw new Error();
  },
  getDictionaryList() {
    throw new Error();
  },
  createDictionaryList() {
    throw new Error();
  },
  updateDictionaryList() {
    throw new Error();
  },
  deleteDictionaryList() {
    throw new Error();
  },
  listUsers() {
    throw new Error();
  },
  listAllOrganizationUsers() {
    throw new Error();
  },
  setUserActivationState() {
    throw new Error();
  },

  getDocumentCollectionConfig() {
    throw new Error();
  },
  createDocumentCollectionConfig() {
    throw new Error();
  },
  updateDocumentCollectionConfig() {
    throw new Error();
  },
  getCollectionConfigAffectedEntities() {
    throw new Error();
  },
  deleteDocumentCollectionConfig() {
    throw new Error();
  },
  getDocumentCollectionInstanceList() {
    throw new Error();
  },
  getDocumentCollection() {
    throw new Error();
  },
  updateDocumentCollectionAssigned() {
    throw new Error();
  },
  getBundleConfigList() {
    throw new Error();
  },
  getBundleConfig() {
    throw new Error();
  },
  postApplyBundle() {
    throw new Error();
  },
  getWorkflowTaskDetails() {
    throw new Error();
  },
  getUserWorkflowTasks() {
    throw new Error();
  },
  getDocumentGroup() {
    throw new Error();
  },
  registerDocumentView() {
    throw new Error();
  },
  viewDocumentCollectionInstance() {
    throw new Error();
  },
  updateDocumentGroup() {
    throw new Error();
  },
  getDocumentCollectionConfigList() {
    throw new Error();
  },
  getWorkflowProfiles() {
    throw new Error();
  },
  getWorkflowTaskList() {
    throw new Error();
  },
  createTemplateContextState() {
    throw new Error();
  },
  deleteTemplateContextState() {
    throw new Error();
  },
  getTasks() {
    throw new Error();
  },
  getTask() {
    throw new Error();
  },
  getTemplateContextState() {
    throw new Error();
  },
  saveTemplateContextState() {
    throw new Error();
  },
  getUserTemplatesSubscriptions() {
    throw new Error();
  },
  updateTemplateSubscription() {
    throw new Error();
  },
  getUserDocumentTypeSubscriptions() {
    throw new Error();
  },
  updateDocumentTypeSubscriptions() {
    throw new Error();
  },
  updateTemplateDefinition() {
    throw new Error();
  },
  reassignTasks() {
    throw new Error();
  },
  getDeletedDocumentList() {
    throw new Error();
  },
  hardDeleteDocument() {
    throw new Error();
  },
  restoreDeletedDocument() {
    throw new Error();
  },
  getOrganizationDocumentCollectionStats() {
    throw new Error();
  },
  getDocumentCollectionInstanceDetails() {
    throw new Error();
  },

  addDocumentToDocumentList() {
    throw new Error();
  },
  getDocumentLabels() {
    throw new Error();
  },
  removeDocumentFromDocumentList() {
    throw new Error();
  },
  getDocumentLabelsDocumentsCount() {
    throw new Error();
  },
  getDocumentCollectionInstanceView() {
    throw new Error();
  },

  deleteAllDocumentsLists() {
    throw new Error();
  },
};
export function extendNullService(...parts: Partial<Services>[]): Services {
  return parts.reduce((acc: Services, next) => {
    return { ...acc, ...next };
  }, nullService);
}

export const ServicesContext = createContext<Services>(nullService);

export type Settings = {
  environmentIsLoading: boolean;
  wsGatewayApiURL: string | undefined;
  disableSignUp: boolean;
  googleClientId: string | undefined;
  dynamsoftLicense: string;
  textControlUrl: string | undefined;
  fluencyAuthorDownloadLink: string;
  configuratorVersion: string;
  configuratorDate: string;
  contactInfo: {
    FBPage: string;
    IGPage: string;
    XPage: string;
  };
  sizeCap: string;
};

const nullSettings: Settings = {
  environmentIsLoading: true,
  wsGatewayApiURL: undefined,
  disableSignUp: true,
  googleClientId: undefined,
  textControlUrl: undefined,
  fluencyAuthorDownloadLink:
    "https://prodoctivitycloud-public.s3.amazonaws.com/files/ProDoctivityFluencyAuthor.zip",
  configuratorVersion: "1.0.0",
  configuratorDate: "2023-05-13",
  dynamsoftLicense: "",
  contactInfo: {
    FBPage: "",
    IGPage: "",
    XPage: "",
  },
  sizeCap: "",
};

export const SettingsContext = createContext<Settings>(nullSettings);

export type SettingsPageCtx = {
  isOpen: boolean;
  leftSideBar: JSX.Element | undefined;
  topComponent: JSX.Element | undefined;
};

const nullSettingsPage: SettingsPageCtx = {
  isOpen: true,
  leftSideBar: undefined,
  topComponent: undefined,
};

export const SettingsPageContext = createContext<SettingsPageCtx>(nullSettingsPage);
