import {
  Box,
  Button,
  Divider,
  Spinner,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, type DragEvent } from "react";

import { createUuid } from "@prodoctivity/shared";
import type { MimeType, ParametersObject } from "@prodoctivity/shared/src/index-types";
import type { BreadCrumbEntry } from "../../../components/BreadCrumb";

import type { DocumentTypeInfo, DocumentTypeResume } from "@prodoctivity/types";
import { Page } from "../../../components/Layout/Page";
import { StateProps } from "../../../components/LookupDocument/LookupDocument";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { useDocumentWordToPdf } from "../../../components/hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useSettings } from "../../../hooks/useSettings";
import { organizationLinkTemplates } from "../../../link-templates";
import { Services } from "../../../services";
import { CircleInfoSvgIcon } from "../../../svg/CircleInfoSvgIcon";
import { ImportEmptySvgIcon } from "../../../svg/ImportEmptySvgIcon";
import { Indexing } from "./Indexing";
import { ScannerServiceStatus } from "./components/Scan";
import { ScannerPanel } from "./components/ScannerPanel";
import { ScannerImage } from "./components/utils/decode-image";
import { useImportDocument } from "./hooks";

type Props = {
  mode: "scan" | "import" | "all";
};

const IndexDocumentPage: FunctionComponent<Props> = ({ mode }) => {
  const { colors } = useColors();
  const {
    STEPS,
    resources,
    breakpoint,

    isLoading,
    isRefetching,
    selectedTabIndex,
    documentTypes,

    identifierConfig,
    mutateSaveDocument,
    onPrev,
    onNext,
    onClearAll,
    execDataLink,
    handleFileUpload,
    clickFileInput,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    onFormValuesChange,
    setDocumentWithSameIdentifier,
    onDocumentTypeSelect,
    disableComponents,
    disableSave,
    state,
    setState,
    fileInputRef,
    acceptedFormats,
    documentLoadingState,
    setDocumentLoadingState,
    isDocumentSearchOpen,
    setDocumentSearchOpen,
    extractedContext,
    receivePage,
    onScanServiceReady,
    onDeviceChange,
    onDevicesReady,
    onCancelCaptureImages,
  } = useImportDocument(mode);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      {
        type: "text",
        name: mode === "import" ? resources.importADocument : resources.scanADocument,
      },
    ];
  }, [resources.home, resources.importADocument, resources.scanADocument, mode]);

  const wordToPdfKey = useMemo(() => {
    return `index-document/${state.files.length ? createUuid() : undefined}`;
  }, [state.files]);
  const wordToPdfHookRef = useDocumentWordToPdf(wordToPdfKey);

  const onClear = useCallback(() => {
    onClearAll();
    wordToPdfHookRef.setWordDataUri();
  }, [onClearAll, wordToPdfHookRef]);

  const screenHasContent =
    (mode === "import" && state.files.length > 0) ||
    (mode === "scan" && state.filenames.length > 0);

  const renderTextButton = useCallback(() => {
    return selectedTabIndex > STEPS.CaptureAndReview
      ? screenHasContent
        ? resources.save
        : resources.continue
      : resources.continue;
  }, [STEPS.CaptureAndReview, resources, screenHasContent, selectedTabIndex]);

  if (isLoading || isRefetching) {
    return (
      <Page breadCrumbEntries={breadCrumbEntries}>
        <Spinner show={true} accessibilityLabel={resources.loading}></Spinner>
      </Page>
    );
  }

  const isVideoFormat = state.files.map((f) => f.file.type).includes("video/mp4");
  const isAudioFormat = state.files.map((f) => f.file.type).includes("audio/mpeg");
  const audioFormat = isAudioFormat ? "audio/mpeg" : undefined;

  const displayCaptureContainer =
    (mode === "import" && state.files.length <= 0) ||
    (mode === "scan" && state.filenames.length <= 0);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column" minWidth={360} flex="grow">
        <input
          name="importInput"
          type="file"
          accept={acceptedFormats}
          multiple={true}
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        <ImportHeader
          acceptedFormats={acceptedFormats}
          clickFileInput={clickFileInput}
          files={state.files}
          handleDragLeave={handleDragLeave}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          isDragging={state.isDragging}
          mode={mode}
          onClear={onClear}
          screenHasContent={screenHasContent}
        />
        <Box display="flex" direction="column" flex="grow">
          {displayCaptureContainer ? (
            <CaptureContainerByCurrentMode
              acceptedFormats={acceptedFormats}
              clickFileInput={clickFileInput}
              devices={state.device}
              handleDragLeave={handleDragLeave}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              isDragging={state.isDragging}
              mode={mode}
              onDeviceChange={onDeviceChange}
              onDevicesReady={onDevicesReady}
              onScanServiceReady={onScanServiceReady}
              receivePage={receivePage}
              scanner={state.scanner}
              serviceState={state.serviceState}
              onCancelCaptureImages={onCancelCaptureImages}
            />
          ) : (
            <IndexContainer
              audioFormat={audioFormat}
              context={state.context}
              disableComponents={disableComponents}
              documentLoadingState={documentLoadingState}
              documentTypeSelected={state.documentTypeSelected}
              documentTypes={documentTypes}
              execDataLink={execDataLink}
              extractedContext={extractedContext}
              files={state.files}
              isDocumentSearchOpen={isDocumentSearchOpen}
              isVideoFormat={isVideoFormat}
              loadingForm={state.loadingForm}
              mimeTypeSelected={state.mimeTypeSelected || "application/pdf"}
              onDocumentTypeSelect={onDocumentTypeSelect}
              onFormValuesChange={onFormValuesChange}
              setDocumentLoadingState={setDocumentLoadingState}
              setDocumentSearchOpen={setDocumentSearchOpen}
              setDocumentWithSameIdentifier={setDocumentWithSameIdentifier}
              wordToPdfHookRef={wordToPdfHookRef}
            />
          )}
        </Box>
        {state.toastMessage && (
          <NotificationMessage
            type={state.toastMessage.type}
            message={state.toastMessage.message}
            position="bottom-left"
            handleDismiss={() => setState({ ...state, toastMessage: undefined })}
          />
        )}
        <Box color={colors.neutral300} borderStyle="lg" borderRadius={4} display="flex">
          {state.documentTypeSelected && identifierConfig && state.documentWithSameIdentifier && (
            <Box width={"100%"} paddingX={2}>
              <Text align="center" color={colors.secondary}>
                {(state.documentTypeSelected.identifierCollisionForcesNewVersion
                  ? resources.formDesigner.createNewVersionIfSave
                  : resources.formDesigner.createNewVersionOrDocument
                ).replace("{{identifierConfig}}", identifierConfig)}
              </Text>
              <Divider />
            </Box>
          )}
          <Box
            display="flex"
            direction="row"
            flex="grow"
            alignItems="center"
            justifyContent="end"
            gap={4}
            padding={2}
          >
            {mode === "all" && selectedTabIndex > STEPS.CaptureAndReview && (
              <Button
                color={"transparent"}
                disabled={disableComponents}
                onClick={onPrev}
                text={resources.previousStep}
              />
            )}

            {(selectedTabIndex > STEPS.CaptureAndReview || !state.documentWithSameIdentifier) && (
              <Button
                onClick={onNext}
                color={"gray"}
                disabled={disableSave}
                text={renderTextButton()}
              />
            )}
            {selectedTabIndex === STEPS.Index && state.documentWithSameIdentifier && (
              <Box
                display="flex"
                width={breakpoint === "medium" ? 450 : 650}
                justifyContent={
                  !state.documentTypeSelected?.identifierCollisionForcesNewVersion
                    ? "between"
                    : "end"
                }
                alignItems="center"
                gap={2}
                direction={breakpoint === "small" ? "column" : "row"}
              >
                {!state.documentTypeSelected?.identifierCollisionForcesNewVersion && (
                  <Box display="flex">
                    <Button
                      onClick={() => mutateSaveDocument(false)}
                      color={"gray"}
                      disabled={disableComponents}
                      text={resources.newDocument}
                    />
                  </Box>
                )}
                <Box display="flex">
                  <Button
                    onClick={() => mutateSaveDocument(true)}
                    color={"blue"}
                    disabled={disableComponents}
                    text={resources.updateDocument}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

const IndexContainer: FunctionComponent<{
  isVideoFormat: boolean;
  audioFormat: "audio/mpeg" | undefined;
  documentTypes: DocumentTypeResume[];
  documentTypeSelected: DocumentTypeInfo | undefined;
  mimeTypeSelected: MimeType;
  context: ParametersObject;
  extractedContext: ParametersObject | undefined;
  disableComponents: boolean;
  files: Array<{
    file: File;
    type: MimeType | undefined;
  }>;
  isDocumentSearchOpen: boolean;
  setDocumentSearchOpen(n: boolean): void;
  documentLoadingState: StateProps;
  setDocumentLoadingState(n: StateProps): void;
  loadingForm: boolean;
  execDataLink: Services["executeDataLink"];
  onFormValuesChange(val: ParametersObject): void;
  onDocumentTypeSelect(docType: DocumentTypeResume): void;
  setDocumentWithSameIdentifier(documentVersionId: string | undefined): void;
  wordToPdfHookRef:
    | undefined
    | {
        pdfDataUri: string | undefined;
        setWordDataUri: (wordDataUri?: string | undefined) => void;
      };
}> = ({
  documentTypes,
  documentTypeSelected,
  mimeTypeSelected,
  context,
  extractedContext,
  files,
  disableComponents,
  isDocumentSearchOpen,
  setDocumentSearchOpen,
  documentLoadingState,
  setDocumentLoadingState,
  loadingForm,
  execDataLink,
  onFormValuesChange,
  onDocumentTypeSelect,
  setDocumentWithSameIdentifier,
  wordToPdfHookRef,
}) => {
  return (
    <Indexing
      documentTypes={documentTypes}
      documentTypeSelected={documentTypeSelected}
      context={context}
      files={files}
      mimeType={mimeTypeSelected}
      disabled={disableComponents}
      loadingForm={loadingForm}
      executeDataLink={execDataLink}
      onDocumentTypeSelect={onDocumentTypeSelect}
      onFormValuesChange={onFormValuesChange}
      setDocumentWithSameIdentifier={setDocumentWithSameIdentifier}
      wordToPdfHookRef={wordToPdfHookRef}
      documentLoadingState={documentLoadingState}
      setDocumentLoadingState={setDocumentLoadingState}
      isDocumentSearchOpen={isDocumentSearchOpen}
      setDocumentSearchOpen={setDocumentSearchOpen}
      extractedContext={extractedContext}
    />
  );
};

const CaptureContainerByCurrentMode: FunctionComponent<{
  mode: Props["mode"];
  acceptedFormats: string;
  clickFileInput(): void;
  handleDragLeave(): void;
  handleDragOver: (event: undefined | React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (event: undefined | React.DragEvent<HTMLDivElement>) => void;
  isDragging: boolean;
  onDevicesReady(devices: string[]): void;
  onDeviceChange(device: string): void;
  devices: string[];
  scanner: string;
  serviceState: ScannerServiceStatus;
  receivePage(scannedImage: ScannerImage): void;
  onScanServiceReady(st: ScannerServiceStatus): void;
  onCancelCaptureImages: () => void;
}> = ({
  mode,
  acceptedFormats,
  clickFileInput,
  handleDrop,
  handleDragOver,
  handleDragLeave,
  isDragging,
  onDevicesReady,
  onDeviceChange,
  devices,
  scanner,
  serviceState,
  receivePage,
  onScanServiceReady,
  onCancelCaptureImages,
}) => {
  if (mode === "import") {
    return (
      <DragAndDropArea
        acceptedFormats={acceptedFormats}
        clickFileInput={clickFileInput}
        handleDragLeave={handleDragLeave}
        handleDragOver={handleDragOver}
        handleDrop={handleDrop}
        isDragging={isDragging}
      />
    );
  }
  return (
    <ScannerPanel
      onReceivePage={receivePage}
      serviceState={serviceState}
      setScanServiceState={onScanServiceReady}
      setDevice={onDeviceChange}
      currentDevice={scanner}
      devicesReady={onDevicesReady}
      devices={devices}
      cancelCaptureImages={onCancelCaptureImages}
    />
  );
};

const ImportHeader: FunctionComponent<{
  mode: Props["mode"];
  files: Array<{
    file: File;
    type: MimeType | undefined;
  }>;
  acceptedFormats: string;
  isDragging: boolean;
  screenHasContent: boolean;
  handleDragOver: (event: undefined | React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (event: undefined | React.DragEvent<HTMLDivElement>) => void;
  handleDragLeave(): void;
  clickFileInput(): void;
  onClear(): void;
}> = ({
  mode,
  files,
  acceptedFormats,
  isDragging,
  screenHasContent,
  handleDragOver,
  handleDrop,
  handleDragLeave,
  clickFileInput,
  onClear,
}) => {
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const { colors } = useColors();

  const isMediumOrSmallBreakpoint = breakpoint === "medium" || breakpoint === "small";

  return (
    <Box display="flex" direction="column" flex="shrink">
      <Box
        onDragEnter={handleDragOver}
        onDragOver={handleDragOver}
        padding={3}
        alignItems="center"
        justifyContent="between"
        display="flex"
        direction={isMediumOrSmallBreakpoint ? "column" : "row"}
      >
        {mode === "import" ? (
          <Box
            height={30}
            alignItems="center"
            color={colors.white}
            display="flex"
            onClickCapture={clickFileInput}
            marginTop={isMediumOrSmallBreakpoint ? 8 : undefined}
            flex="grow"
          >
            <CircleInfoSvgIcon type="exclamation-inverted" color={colors.black900} />
            <Box>
              {breakpoint === "small" || breakpoint === "medium" ? (
                <Text color={colors.subtle} size="200">
                  {resources.indexDocument.clickHereAndUploadYourFile}
                </Text>
              ) : (
                <Text color={colors.subtle} size="200">
                  {resources.indexDocument.captureAndReviewDesc}
                </Text>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            height={30}
            alignItems="center"
            color={colors.white}
            display="flex"
            flex="grow"
          ></Box>
        )}
        <Box marginTop={isMediumOrSmallBreakpoint ? 2 : undefined}>
          {screenHasContent && (
            <TapArea onTap={onClear}>
              <Text color={colors.primary}>{resources.goBack}</Text>
            </TapArea>
          )}
        </Box>
      </Box>
      {isDragging && files.length > 0 && (
        <DragAndDropArea
          acceptedFormats={acceptedFormats}
          clickFileInput={clickFileInput}
          handleDragLeave={handleDragLeave}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          isDragging={isDragging}
        />
      )}
    </Box>
  );
};

const DragAndDropArea: FunctionComponent<{
  isDragging: boolean;
  acceptedFormats: string;
  clickFileInput: () => void;
  handleDragOver: (event?: DragEvent<HTMLDivElement> | undefined) => void;
  handleDragLeave: () => void;
  handleDrop: (event?: DragEvent<HTMLDivElement> | undefined) => void;
}> = ({
  isDragging,
  acceptedFormats,
  clickFileInput,
  handleDragOver,
  handleDragLeave,
  handleDrop,
}) => {
  const { sizeCap } = useSettings();
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();

  return (
    <Box marginBottom={3} paddingX={3} marginTop={5}>
      <Text weight="bold" size="400">
        {resources.uploadFiles}
      </Text>
      <Box
        padding={4}
        marginTop={2}
        height="auto"
        width={"100%"}
        onClickCapture={clickFileInput}
        dangerouslySetInlineStyle={{
          __style: {
            cursor: "pointer",
            border: `dashed 2px ${isDragging ? "#3366CC" : "#8e8e8e"}`,
          },
        }}
        onDragEnter={handleDragOver}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        color={isDragging ? colors.primaryHover0 : undefined}
      >
        <Box marginTop={12} display="flex" justifyContent="center" alignItems="center">
          <ImportEmptySvgIcon />
        </Box>
        <Box marginTop={2} display="flex" justifyContent="center" alignItems="center" gap={1}>
          {breakpoint === "small" || breakpoint === "medium" ? (
            <Text>{resources.dashboard.clickHereTo}</Text>
          ) : (
            <Text>{resources.documentCollection.dragAndDropYourFilesHereOr}</Text>
          )}

          <Box>
            <Text color={colors.primary}> {resources.chooseFile}</Text>
          </Box>
        </Box>
        <Box marginTop={2} display="flex" justifyContent="center" alignItems="center">
          <Text color={colors.subtle} size="200">
            {resources.maxFileSize.replace("{fileSizeCap}", sizeCap)}
          </Text>
        </Box>
        <Box
          marginTop={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          wrap={breakpoint === "small" ? true : false}
        >
          <Text align="center">Formats: </Text>
          {breakpoint === "small" ? (
            acceptedFormats.split(",").map((formats) => (
              <Text key={formats} align="center">
                {`${formats}, `}
              </Text>
            ))
          ) : (
            <Text align="center">{acceptedFormats}</Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default IndexDocumentPage;
