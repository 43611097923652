"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildTemplateVersionInfoXml = void 0;
const logical_1 = require("../logical");
const utils_1 = require("../utils");
const Expression_1 = require("../logical/Expression");
const newLine = "\n";
function indent(n) {
    return (0, utils_1.range)(n)
        .map((_) => "  ")
        .join("");
}
function escapeValue(val) {
    return val
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&apos;");
}
function buildTemplateVersionInfoXml(templateVersion, tryAutoFix) {
    return `<DocumentGeneration xmlns="http://documentgeneration.prodoctivity.com/2011/">
${indent(1)}${getWizardConfig(templateVersion, tryAutoFix)}
${indent(1)}<DataConnectors></DataConnectors>
${indent(1)}${getTagDefinition(templateVersion, tryAutoFix)}
${indent(1)}${getDataDefinition(templateVersion)}
${indent(1)}${getInformationDefinition(templateVersion)}
${indent(1)}<Dispositions />
</DocumentGeneration>`;
}
exports.buildTemplateVersionInfoXml = buildTemplateVersionInfoXml;
function getDefaultPageAndSectionName(wizardDefinition) {
    let defaultPageName = wizardDefinition.defaultPageName;
    let foundPage = wizardDefinition.pages.find((page) => page.label === defaultPageName);
    if (!foundPage) {
        if (!wizardDefinition.pages || !wizardDefinition.pages.length) {
            throw new Error("Invalid template");
        }
        defaultPageName = wizardDefinition.pages[0].label;
        foundPage = wizardDefinition.pages[0];
    }
    let defaultSectionName = wizardDefinition.defaultSectionName;
    let foundSection = foundPage.sections.find((section) => section.label === defaultSectionName);
    if (!foundSection) {
        if (!foundPage.sections || !foundPage.sections.length) {
            throw new Error("Invalid Template");
        }
        defaultSectionName = foundPage.sections[0].label;
        foundSection = foundPage.sections[0];
    }
    return { defaultPageName, defaultSectionName };
}
function getWizardConfig(templateVersion, tryAutoFix) {
    const { wizardDefinition } = templateVersion;
    const { defaultPageName, defaultSectionName } = getDefaultPageAndSectionName(wizardDefinition);
    return `<WizardConfig defaultPageName="${escapeValue(defaultPageName)}" defaultSectionName="${escapeValue(defaultSectionName)}">
${indent(2)}${wizardDefinition.pages.map((page) => getWizardPage(page)).join("\n" + indent(2))}
${indent(2)}<Dependencies>${wizardDefinition.dependencies && wizardDefinition.dependencies.length
        ? `
${indent(3)}${wizardDefinition.dependencies
            .map((dependency) => getWizardDependency(3, dependency, tryAutoFix))
            .join("\n" + indent(3))}
${indent(2)}`
        : ""}</Dependencies>
${indent(1)}</WizardConfig>
`;
}
function getWizardDependency(ind, dependency, tryAutoFix) {
    return `<Dependency>
${indent(ind + 1)}<Action>${escapeValue(dependency.name)}</Action>
${indent(ind + 1)}${(0, logical_1.convertToXmlExpression)((0, logical_1.convertTreeFormLogicalExpressionToExpressionObject)(dependency.expression, tryAutoFix))}
${indent(ind + 1)}<Items>
${indent(ind + 2)}${(dependency.items || [])
        .map((item) => {
        return `<Item key="${escapeValue(item.key)}" type="${getDependencyItemType(item.type)}" action="${getDependencyAction(item.action)}" />`;
    })
        .join("\n" + indent(ind + 2))}
${indent(ind + 1)}</Items>
${indent(ind)}</Dependency>`;
}
function getDependencyItemType(val) {
    switch (val) {
        case "page":
            return "Page";
        case "section":
            return "Panel";
        case "field":
            return "Field";
        case "record":
            return "Record";
        default:
            (0, utils_1.shouldNever)(val);
            throw new Error("Cannot happen");
    }
}
function getDependencyAction(val) {
    switch (val) {
        case "hide":
            return "Hide";
        case "disable":
            return "Disable";
        default:
            (0, utils_1.shouldNever)(val);
            throw new Error("Cannot happen");
    }
}
function getWizardPage(page) {
    return `<Page key="${escapeValue(page.key)}" label="${escapeValue(page.label)}" description="${escapeValue(page.description)}">
${indent(3)}${getProperties(3, page.properties)}
${indent(3)}${page.sections.map((section) => getWizardSection(section)).join("\n" + indent(3))}
${indent(2)}</Page>`;
}
function getWizardSection(section) {
    return `<Panel key="${escapeValue(section.key)}" label="${section.label}" description="${(section.description || "")
        .split("\n")
        .join("&#xA;")}">
${indent(4)}${getProperties(3, section.properties)}
${indent(4)}${section.fields.map((field) => getWizardField(4, field)).join("\n" + indent(4))}
${indent(3)}</Panel>`;
}
function getWizardField(ind, field) {
    if (field.isRecord) {
        return `<Field key="${escapeValue(field.key)}" isRecord="${getBoolean(field.isRecord)}" isDuplicate="${getBoolean(field.isDuplicated)}" label="${escapeValue(field.label)}">
${indent(ind + 1)}${(field.fields || [])
            .map((childField) => getWizardField(ind + 1, childField))
            .join("\n" + indent(ind + 1))}
${indent(ind)}</Field>`;
    }
    return `<Field key="${escapeValue(field.key)}" isRecord="${getBoolean(field.isRecord)}" isDuplicate="${getBoolean(field.isDuplicated)}" label="${escapeValue(field.label)}"></Field>`;
}
function getBoolean(val) {
    if (val) {
        return "True";
    }
    return "False";
}
function getProperties(ind, properties) {
    if (!properties) {
        return `<Properties></Properties>`;
    }
    const keys = Object.keys(properties);
    if (!keys.length) {
        return `<Properties></Properties>`;
    }
    return `<Properties>
${indent(ind + 1)}${keys
        .map((key) => {
        const propertyXmlName = getPropertyXmlTagName(key);
        if (key === "leftExpression" || key === "rightExpression") {
            const logicalExpressionResult = logical_1.LogicalExpression$Schema.safeParse(properties[key]);
            if (!logicalExpressionResult.success) {
                console.log(`Invalid parsed logical expression`);
                return "";
            }
            return getPropertyTagRaw(ind, propertyXmlName, (0, logical_1.convertToXmlExpression)((0, logical_1.convertTreeFormLogicalExpressionToExpressionObject)(logicalExpressionResult.data)));
        }
        if (properties[key] === undefined && (key === "minOccurs" || key === "maxOccurs")) {
            return "";
        }
        return getPropertyTag(ind, propertyXmlName, properties[key]);
    })
        .join("\n" + indent(ind + 1))}
${indent(ind)}</Properties>`;
}
function getPropertyTagRaw(_ind, tagName, val) {
    return `<${tagName}>${val}</${tagName}>`;
}
function getPropertyTag(ind, tagName, val) {
    if (val === undefined) {
        console.log(`${tagName} is undefined`);
        console.log(new Error().stack);
    }
    return `<${tagName}>${getValueStr(ind, val)}</${tagName}>`;
}
function getPropertyXmlTagName(fieldName) {
    return (0, utils_1.toPascalCase)(fieldName);
}
function getValueStr(ind, val) {
    if (val === null || val === undefined) {
        return "";
    }
    else {
        if (Array.isArray(val)) {
            return val
                .map((v) => {
                if (typeof v === "object") {
                    return getProperties(ind, v);
                }
                return getValueStr(ind, v);
            })
                .join(",");
        }
        if (typeof val === "string") {
            return escapeValue(val);
        }
        if (typeof val === "number") {
            return val.toString();
        }
        if (typeof val === "boolean") {
            return getBoolean(val);
        }
        console.log("Error");
        console.log(val);
        console.log(new Error().stack);
        throw new Error("Invalid value str type");
    }
}
function getInformationDefinition(templateVersion) {
    const { informationDefinition } = templateVersion;
    const saveToECM = informationDefinition.saveToECM === false ? "false" : "true";
    return `<Information>
${indent(2)}<Name>${escapeValue(informationDefinition.name)}</Name>
${indent(2)}<Description>${escapeValue(informationDefinition.description)}</Description>
${indent(2)}<Language>${escapeValue(informationDefinition.language)}</Language>
${indent(2)}<DocumentGroupId>${escapeValue(informationDefinition.documentGroupId)}</DocumentGroupId>
${indent(2)}<SaveToECM>${saveToECM}</SaveToECM>
${indent(2)}${getProperties(2, informationDefinition.properties)}
${indent(2)}<ReferenceFields>${informationDefinition.referenceFields && informationDefinition.referenceFields.length
        ? `${indent(3)}${(informationDefinition.referenceFields || [])
            .map((rfield) => `<ReferenceField>${escapeValue(rfield)}</ReferenceField>`)
            .join("\n" + indent(3))}
${indent(2)}`
        : ""}</ReferenceFields>
${indent(2)}<DocumentsProtectionHandling>
${indent(3)}<ProtectionType>${getProtectionType(informationDefinition.documentProtectionHandling.protectionType)}</ProtectionType>
${indent(3)}<Password>${escapeValue(informationDefinition.documentProtectionHandling.password)}</Password>
${indent(2)}</DocumentsProtectionHandling>
${indent(1)}</Information>`;
}
function getProtectionType(protectionType) {
    switch (protectionType) {
        case "none":
            return "None";
        case "allow-only-revisions":
            return "AllowOnlyRevisions";
        case "allow-only-comments":
            return "AllowOnlyComments";
        case "read-only":
            return "ReadOnly";
        default:
            return "None";
    }
}
function getDataDefinition(templateVersion) {
    const { contextDefinition } = templateVersion;
    return `<DataDefinition>
${indent(2)}${(contextDefinition.fields || [])
        .map((contextField) => getContextField(2, contextField))
        .join("\n" + indent(2))}
${indent(2)}${(contextDefinition.records || [])
        .map((contextRecord) => getContextRecord(2, contextRecord))
        .join("\n" + indent(2))}
${indent(1)}</DataDefinition>`;
}
function getContextField(ind, contextField) {
    return `<Field name="${escapeValue(contextField.name)}">
${indent(ind + 1)}${getContextFieldProperties(ind + 1, contextField.properties)}
${indent(ind)}</Field>`;
}
function getContextRecord(ind, contextRecord) {
    return `<Record name="${escapeValue(contextRecord.name)}">
${indent(ind + 1)}${getProperties(ind + 1, contextRecord.properties)}
${indent(ind + 1)}${(contextRecord.fields || [])
        .map((contextField) => getContextField(ind + 1, contextField))
        .join("\n" + indent(ind + 1))}
${contextRecord.records && contextRecord.records.length
        ? `${indent(ind + 1)}${(contextRecord.records || [])
            .map((contextRecord) => getContextRecord(ind + 1, contextRecord))
            .join("\n" + indent(ind + 1))}
`
        : ""}${indent(ind)}</Record>`;
}
function getContextFieldProperties(ind, properties) {
    if (!properties) {
        return `<Properties></Properties>`;
    }
    let result = `<Properties>
`;
    switch (properties.dataType) {
        case "Alphanumeric": {
            if (properties.minOccurs !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "MinOccurs", properties.minOccurs || 0)}` +
                        newLine;
            }
            if (properties.maxOccurs !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "MaxOccurs", properties.maxOccurs)}` +
                        newLine;
            }
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "Description", properties.description)}` +
                    newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "Instructions", properties.instructions)}` +
                    newLine;
            result += `${indent(ind + 1)}${getPropertyTag(ind + 1, "Label", properties.label)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "HumanName", properties.humanName)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "DataType", properties.dataType)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "InputType", properties.inputType === "Default" ? "TextBox" : properties.inputType)}` + newLine;
            switch (properties.inputType) {
                case "TextBox":
                case "Default": {
                    if (properties.autoCompleteValues !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "AutoCompleteValues", properties.autoCompleteValues)}` + newLine;
                    }
                    if (properties.inputMask !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "InputMask", properties.inputMask)}` +
                                newLine;
                    }
                    if (properties.inputMaskStoreFixedChars !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "InputMaskStoreFixedChars", properties.inputMaskStoreFixedChars)}` + newLine;
                    }
                    if (properties.maxLength !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "MaxLength", properties.maxLength)}` +
                                newLine;
                    }
                    break;
                }
                case "TextArea": {
                    if (properties.maxLength !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "MaxLength", properties.maxLength)}` +
                                newLine;
                    }
                    if (properties.lineCount !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "LineCount", properties.lineCount)}` +
                                newLine;
                    }
                    break;
                }
                case "Checkbox":
                case "Radio":
                case "Dropdown":
                case "CheckBoxList":
                case "DropDownList":
                case "RadioButtonList": {
                    if (properties.listHasLabels !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "ListHasLabels", properties.listHasLabels)}` + newLine;
                    }
                    if (properties.parentListSource !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "ParentListSource", properties.parentListSource)}` + newLine;
                    }
                    result +=
                        `${indent(ind + 1)}<ValueList>
${indent(ind + 2)}${properties.valueList
                            .map((valueItem) => {
                            return `<pvalue><value>${escapeValue(valueItem.value)}</value>${valueItem.parentValue
                                ? `<parentvalue>${escapeValue(valueItem.parentValue)}</parentvalue>`
                                : ""}<label>${escapeValue(valueItem.label)}</label></pvalue>`;
                        })
                            .join("\n" + indent(ind + 2))}
${indent(ind + 1)}</ValueList>` + newLine;
                    if (properties.dictionaryListId !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "DictionaryListId", properties.dictionaryListId)}` + newLine;
                    }
                    break;
                }
                default:
                    (0, utils_1.shouldNever)(properties);
                    throw new Error("Cannot happen");
            }
            if (properties.defaultValue !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "DefaultValue", properties.defaultValue)}` +
                        newLine;
            }
            if (properties.sampleValue !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "SampleValue", properties.sampleValue)}` +
                        newLine;
            }
            if (properties.saveToECM !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "SaveToECM", properties.saveToECM || false)}` + newLine;
            }
            if (properties.isUnique !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "IsUnique", properties.isUnique || false)}` +
                        newLine;
            }
            break;
        }
        case "Numeric":
        case "Currency": {
            if (properties.minOccurs !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "MinOccurs", properties.minOccurs || 0)}` +
                        newLine;
            }
            if (properties.maxOccurs !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "MaxOccurs", properties.maxOccurs)}` +
                        newLine;
            }
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "Description", properties.description)}` +
                    newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "Instructions", properties.instructions)}` +
                    newLine;
            result += `${indent(ind + 1)}${getPropertyTag(ind + 1, "Label", properties.label)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "HumanName", properties.humanName)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "DataType", properties.dataType)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "InputType", properties.inputType === "Default" ? "TextBox" : properties.inputType)}` + newLine;
            switch (properties.inputType) {
                case "TextBox":
                case "Default": {
                    if (properties.maxValue !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "MaxValue", properties.maxValue)}` +
                                newLine;
                    }
                    if (properties.minValue !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "MinValue", properties.minValue)}` +
                                newLine;
                    }
                    if (properties.isCalculated !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "IsCalculated", properties.isCalculated)}` + newLine;
                        if (properties.isCalculated) {
                            result +=
                                `${indent(ind + 1)}<CalculatedField>
${indent(ind + 2)}${getCalculatedField(ind + 2, properties.calculatedField)}
${indent(ind + 1)}</CalculatedField>` + newLine;
                        }
                    }
                    break;
                }
                default:
                    (0, utils_1.shouldNever)(properties);
                    throw new Error("Cannot happen");
            }
            if (properties.defaultValue !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "DefaultValue", properties.defaultValue)}` +
                        newLine;
            }
            if (properties.sampleValue !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "SampleValue", properties.sampleValue || null)}` + newLine;
            }
            if (properties.saveToECM !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "SaveToECM", properties.saveToECM || false)}` + newLine;
            }
            if (properties.isUnique !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "IsUnique", properties.isUnique || false)}` +
                        newLine;
            }
            break;
        }
        case "Date":
        case "DateTime":
        case "Time": {
            if (properties.minOccurs !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "MinOccurs", properties.minOccurs || 0)}` +
                        newLine;
            }
            if (properties.maxOccurs !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "MaxOccurs", properties.maxOccurs)}` +
                        newLine;
            }
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "Description", properties.description)}` +
                    newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "Instructions", properties.instructions)}` +
                    newLine;
            result += `${indent(ind + 1)}${getPropertyTag(ind + 1, "Label", properties.label)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "HumanName", properties.humanName)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "DataType", properties.dataType)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "InputType", properties.inputType === "Default"
                    ? properties.dataType === "Time"
                        ? "TimePicker"
                        : "DateTimePicker"
                    : properties.inputType)}` + newLine;
            switch (properties.inputType) {
                case "DateTimePicker":
                case "TimePicker":
                case "Default": {
                    if (properties.maxValue !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "MaxValue", properties.maxValue)}` +
                                newLine;
                    }
                    if (properties.minValue !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "MinValue", properties.minValue)}` +
                                newLine;
                    }
                    if (properties.isSystemDate !== undefined) {
                        result +=
                            `${indent(ind + 1)}${getPropertyTag(ind + 1, "IsSystemDate", properties.isSystemDate)}` + newLine;
                    }
                    break;
                }
                default:
                    (0, utils_1.shouldNever)(properties);
                    throw new Error("Cannot happen");
            }
            if (properties.dataType !== "Time") {
                if (properties.dateMinMaxType !== undefined) {
                    result +=
                        `${indent(ind + 1)}${getPropertyTag(ind + 1, "DateMinMaxType", properties.dateMinMaxType)}` + newLine;
                }
            }
            if (properties.defaultValue !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "DefaultValue", properties.defaultValue)}` +
                        newLine;
            }
            if (properties.sampleValue !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "SampleValue", properties.sampleValue || null)}` + newLine;
            }
            if (properties.saveToECM !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "SaveToECM", properties.saveToECM || false)}` + newLine;
            }
            if (properties.isUnique !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "IsUnique", properties.isUnique || false)}` +
                        newLine;
            }
            break;
        }
        case "Logical": {
            if (properties.minOccurs !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "MinOccurs", properties.minOccurs || 0)}` +
                        newLine;
            }
            if (properties.maxOccurs !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "MaxOccurs", properties.maxOccurs)}` +
                        newLine;
            }
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "Description", properties.description)}` +
                    newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "Instructions", properties.instructions)}` +
                    newLine;
            result += `${indent(ind + 1)}${getPropertyTag(ind + 1, "Label", properties.label)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "HumanName", properties.humanName)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "DataType", properties.dataType)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "InputType", properties.inputType === "Default" ? "Checkbox" : properties.inputType)}` + newLine;
            switch (properties.inputType) {
                case "Default":
                case "Checkbox":
                case "Switch": {
                    break;
                }
                default:
                    (0, utils_1.shouldNever)(properties);
                    throw new Error("Cannot happen");
            }
            if (properties.defaultValue !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "DefaultValue", properties.defaultValue || false)}` + newLine;
            }
            if (properties.sampleValue !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "SampleValue", properties.sampleValue || false)}` + newLine;
            }
            if (properties.saveToECM !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "SaveToECM", properties.saveToECM || false)}` + newLine;
            }
            if (properties.isUnique !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "IsUnique", properties.isUnique || false)}` +
                        newLine;
            }
            break;
        }
        case "Image": {
            if (properties.minOccurs !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "MinOccurs", properties.minOccurs || 0)}` +
                        newLine;
            }
            if (properties.maxOccurs !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "MaxOccurs", properties.maxOccurs)}` +
                        newLine;
            }
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "Description", properties.description)}` +
                    newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "Instructions", properties.instructions)}` +
                    newLine;
            result += `${indent(ind + 1)}${getPropertyTag(ind + 1, "Label", properties.label)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "HumanName", properties.humanName)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "DataType", properties.dataType)}` + newLine;
            result +=
                `${indent(ind + 1)}${getPropertyTag(ind + 1, "InputType", properties.inputType === "Default" ? "ImageUpload" : properties.inputType)}` + newLine;
            switch (properties.inputType) {
                case "ImageUpload":
                case "Signature":
                case "Default": {
                    break;
                }
                default:
                    (0, utils_1.shouldNever)(properties);
                    throw new Error("Cannot happen");
            }
            if (properties.defaultValue !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "DefaultValue", properties.defaultValue)}` +
                        newLine;
            }
            if (properties.sampleValue !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "SampleValue", properties.sampleValue)}` +
                        newLine;
            }
            if (properties.saveToECM !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "SaveToECM", properties.saveToECM || false)}` + newLine;
            }
            if (properties.isUnique !== undefined) {
                result +=
                    `${indent(ind + 1)}${getPropertyTag(ind + 1, "IsUnique", properties.isUnique || false)}` +
                        newLine;
            }
            break;
        }
        default:
            (0, utils_1.shouldNever)(properties);
            throw new Error("Cannot happen");
    }
    if (properties.dictionaryDataElementId !== undefined) {
        result +=
            `${indent(ind + 1)}${getPropertyTag(ind + 1, "DictionaryDataElementId", properties.dictionaryDataElementId)}` + newLine;
    }
    result += `${indent(ind)}</Properties>`;
    return result;
}
function getCalculatedField(ind, calculatedField) {
    let result = `<CalculatedField>
${indent(ind + 1)}<ExpressionType>${calculatedField.type}</ExpressionType>
${indent(ind + 1)}<DataType>${calculatedField.dataType}</DataType>`;
    switch (calculatedField.type) {
        case "FixedValue": {
            result += `${indent(ind + 1)}<FixedValueType>${calculatedField.fixedValueType}</FixedValueType>`;
            result += `${indent(ind + 1)}<FixedValue>${calculatedField.fixedValue}</FixedValue>`;
            break;
        }
        case "ContextValue": {
            result += `${indent(ind + 1)}<ContextFullPath>${calculatedField.contextFullPath}</ContextFullPath>`;
            if (calculatedField.contextWhere) {
                result += `${indent(ind + 1)}<ContextFilterPath>${calculatedField.contextFilterPath}</ContextFilterPath>`;
                result += `${indent(ind + 1)}<ContextFilter>${calculatedField.contextWhere}</ContextFilter>`;
            }
            break;
        }
        case "Composite": {
            result += `${indent(ind + 1)}<Operator>${calculatedField.operator}</Operator>`;
            result += `${indent(ind + 1)}<Summary>${calculatedField.summary}</Summary>`;
            result += `${indent(ind + 1)}<CompositeExpressionList>${(calculatedField.expressions || [])
                .map((expression) => {
                return getCalculatedField(ind + 1, expression);
            })
                .join("\n" + indent(ind + 1))}</CompositeExpressionList>`;
            break;
        }
        default:
            (0, utils_1.shouldNever)(calculatedField);
            throw new Error("Cannot happen");
    }
    result += `${indent(ind)}</CalculatedField>`;
    return result;
}
function getTagDefinition(templateVersion, tryAutoFix) {
    const { tagDefinition } = templateVersion;
    const tagKeys = tagDefinition.tagList ? Object.keys(tagDefinition.tagList) : [];
    return `<TagDefinition>
${indent(2)}<TagList>${tagKeys.length > 0
        ? `
${indent(3)}${tagKeys
            .map((tagKey) => {
            const documentTag = tagDefinition.tagList[tagKey];
            return getDocumentTag(3, documentTag, tryAutoFix);
        })
            .join("\n" + indent(3))}`
        : ""}
  </TagList>
  <RecordSpreadsheetMappingList>
    ${Object.entries(tagDefinition.recordSpreadsheetMappingList || {})
        .map(([key, val]) => {
        return `<RecordSpreadsheetMapping>
      <TagId>${key}</TagId>
      <FieldMappings>
        ${(val.fieldMappings || [])
            .map((fieldMapping) => {
            return `<FieldMapping>
          <SourceField>${fieldMapping.sourceField}</SourceField>
          <TargetSeries>${fieldMapping.targetSeries}</TargetSeries>
        </FieldMapping>`;
        })
            .join("\n")}
      </FieldMappings>
      <BypassCategoryDescription>${val.bypassCategoryDescription}</BypassCategoryDescription>
  </RecordSpreadsheetMapping>`;
    })
        .join("\n")}
  </RecordSpreadsheetMappingList>
${indent(1)}</TagDefinition>`;
}
function getDocumentTag(ind, documentTag, tryAutoFix) {
    return `<DocumentGenerationTag>
${indent(ind + 1)}<ContentControlId>${escapeValue(documentTag.contentControlId)}</ContentControlId>
${indent(ind + 1)}<TagId>${escapeValue(documentTag.tagId)}</TagId>
${indent(ind + 1)}<WordContentControlId>${escapeValue(documentTag.wordContentControlId)}</WordContentControlId>
${indent(ind + 1)}<TagType>${escapeValue(documentTag.tagType)}</TagType>
${indent(ind + 1)}${getDocumentTagProperties(ind + 1, documentTag, tryAutoFix)}
${indent(ind)}</DocumentGenerationTag>`;
}
function getDocumentTagProperties(ind, documentTag, tryAutoFix) {
    switch (documentTag.tagType) {
        case "Expression":
            return getExpressionBlockProperties(ind, documentTag.expressionBlockProperties);
        case "Conditional":
            return getConditionalBlockProperties(ind, documentTag.conditionalBlockProperties, tryAutoFix);
        case "Group":
            return getGroupBlockProperties(ind, documentTag.groupBlockProperties);
        case "Include":
            return getIncludeBlockProperties(ind, documentTag.includeBlockProperties);
        case "Chart":
            return "";
        default:
            (0, utils_1.shouldNever)(documentTag);
            throw new Error("Cannot happen");
    }
    return "";
}
function getExpressionBlockProperties(ind, expressionBlockProperties) {
    let result = `<ExpressionBlockProperties>
`;
    Object.keys(expressionBlockProperties)
        .filter((key) => key !== "dataType" && key !== "inputType")
        .forEach((key) => {
        const val = expressionBlockProperties[key];
        if (val === undefined || val === null) {
            return;
        }
        const tagName = (0, utils_1.toPascalCase)(key);
        if (key === "textReplacementPattern") {
            result += `${indent(ind + 1)}<${tagName}>${getTextReplacementPattern(ind + 1, val, expressionBlockProperties)}</${tagName}>`;
        }
        else {
            result += `${indent(ind + 1)}<${tagName}>${getValueStr(ind + 1, val)}</${tagName}>
`;
        }
    });
    result += `${indent(ind)}</ExpressionBlockProperties>`;
    return result;
}
function getTextReplacementPattern(_ind, val, expressionBlockProperties) {
    if (expressionBlockProperties["textReplacementCustomizedReplaceType"] === "advanced") {
        if (Array.isArray(val) &&
            val.length &&
            Object.prototype.hasOwnProperty.call(val[0], "output")) {
            const patterns = val;
            return `<customizedAdvanced>
  ${patterns
                .map((pat) => {
                return `<CustomizedSimpleEntry>
    <Expression>${(0, logical_1.convertToXmlExpression)((0, logical_1.convertTreeFormLogicalExpressionToExpressionObject)(pat.expression, true))}</Expression>
    <Output>${escapeValue(pat.output)}</Output>
  </CustomizedSimpleEntry>`;
            })
                .join("")}
  </customizedAdvanced>`;
        }
    }
    else if (expressionBlockProperties["textReplacementCustomizedReplaceType"] === "dictionary") {
        if (Array.isArray(val)) {
            const patterns = val;
            return `<customizedDictionary>
  ${patterns
                .map((pat) => {
                return `<Entry>
    <Key>${escapeValue(pat.key)}</Key>
    <Value>${escapeValue(pat.value)}</Value>
  </Entry>`;
            })
                .join("")}
  </customizedDictionary>`;
        }
    }
    if (typeof val === "string") {
        return val;
    }
    return "";
}
function getConditionalBlockProperties(ind, conditionalBlockProperties, tryAutoFix) {
    let result = `<ConditionalBlockProperties>
`;
    result += `${indent(ind + 1)}<IsParagraph>${getBoolean(conditionalBlockProperties.isParagraph)}</IsParagraph>
`;
    result += `${indent(ind + 1)}<ExpressionList>
${indent(ind + 2)}<ExpressionList>
${indent(ind + 3)}${(conditionalBlockProperties.expressionList || [])
        .map((expr) => {
        const expression = (0, logical_1.convertTreeFormLogicalExpressionToExpressionObject)(expr, tryAutoFix);
        if (typeof expression.target === "undefined" && tryAutoFix) {
            console.log(`Attempting to fix malformed expression type 3`);
            return (0, logical_1.convertToXmlExpression)(new Expression_1.Expression());
        }
        return (0, logical_1.convertToXmlExpression)(expression);
    })
        .join("\n" + indent(ind + 3))}
${indent(ind + 2)}</ExpressionList>
${indent(ind + 1)}</ExpressionList>`;
    result += `${indent(ind)}</ConditionalBlockProperties>`;
    return result;
}
function getGroupBlockProperties(ind, groupBlockProperties) {
    let result = `<GroupBlockProperties>`;
    Object.keys(groupBlockProperties)
        .filter((key) => key !== "dataType" && key !== "inputType")
        .forEach((key) => {
        const val = groupBlockProperties[key];
        if (val === undefined || val === null) {
            return;
        }
        const tagName = (0, utils_1.toPascalCase)(key);
        if (key === "visibleFilter") {
            result += `${indent(ind + 1)}<${tagName}>${getVisibleFilter(ind + 1, val)}</${tagName}>`;
        }
        else {
            result += `${indent(ind + 1)}<${tagName}>${escapeValue(getValueStr(ind + 1, val))}</${tagName}>`;
        }
    });
    result += `${indent(ind)}</GroupBlockProperties>`;
    return result;
}
function getVisibleFilter(ind, val) {
    if (typeof val === "object") {
        const expression = val;
        return (0, logical_1.convertToXmlExpression)((0, logical_1.convertTreeFormLogicalExpressionToExpressionObject)(expression, true));
    }
    else if (typeof val === "string") {
        return val;
    }
    return "";
}
function getIncludeBlockProperties(ind, includeBlockProperties) {
    let result = `<IncludeBlockProperties>`;
    Object.keys(includeBlockProperties)
        .filter((key) => key !== "dataType" && key !== "inputType")
        .forEach((key) => {
        const val = includeBlockProperties[key];
        if (val === undefined || val === null) {
            return;
        }
        const tagName = (0, utils_1.toPascalCase)(key);
        result += `
${indent(ind + 1)}<${tagName}>${getValueStr(ind + 1, val)}</${tagName}>`;
    });
    result += `${indent(ind)}</IncludeBlockProperties>`;
    return result;
}
