import { useDesignBreakpoint, useFormController } from "@prodoctivity/design-system";
import {
  MimeTypes,
  MimeTypesArray,
  evaluateStringTemplateList,
  fixContextPayload,
  getBase64FromBlob,
  mimeTypeToExtension,
  parseDataURI,
  toMimeType,
} from "@prodoctivity/shared";
import type { MimeType, ParametersObject } from "@prodoctivity/shared/src/index-types";
import type {
  DocumentTypeInfo,
  DocumentTypeResume,
  HttpExecuteDataLinkRequest,
  HttpSaveDocumentRequest,
} from "@prodoctivity/types";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { fileSizeCap } from "../../../config";

import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDocumentLookupState } from "../../../components/LookupDocument/hook";
import { useAllDocumentTypes } from "../../../components/hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useServices } from "../../../hooks/useServices";
import { Services } from "../../../services";
import { ScannerServiceStatus } from "./components/Scan";
import { ScannerImage } from "./components/utils/decode-image";

export type State = {
  documentTypeSelected?: DocumentTypeInfo;
  documentWithSameIdentifier?: string;
  context: ParametersObject;
  errors?: { [key: string]: string };
  loadingForm: boolean;
  isDragging: boolean;
  mimeTypeSelected?: MimeType;
  files: Array<{
    file: File;
    type: MimeType | undefined;
  }>;
  filenames: Array<string>;
  toastMessage?: {
    type: "error" | "success" | "warn";
    message: string;
  };
  scanner: string;
  device: Array<string>;
  serviceState: ScannerServiceStatus;
};

const STEPS = {
  CaptureAndReview: 0,
  Index: 1,
};

const originalFormats = MimeTypesArray.map(mimeTypeToExtension).join(",");
let acceptedFormats: string = originalFormats;
const firstItemInArray = 0;

export const useImportDocument = (mode: "scan" | "import" | "all") => {
  const { resources, moment } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { saveDocument, getDocumentTypeInfo, executeDataLink } = useServices();
  const [selectedTabIndex, setTabIndex] = useState<number>(0);
  const { breakpoint } = useDesignBreakpoint();
  const { state: previousConfiguration } = useLocation();
  const [state, setState] = useState<State>({
    documentTypeSelected: previousConfiguration?.documentTypeInfo,
    //HACK: Sanitize movement. Please remove when done.
    context: fixContextPayload(previousConfiguration?.generationContext || {}, false),
    files: [],
    filenames: [],
    loadingForm: false,
    isDragging: false,
    device: [],
    scanner: "",
    serviceState: "Loading",
  });

  const {
    extractedContext,
    documentLoadingState,
    setDocumentLoadingState,
    isDocumentSearchOpen,
    setDocumentSearchOpen,
  } = useDocumentLookupState(state.documentTypeSelected?.contextDefinition.fields);
  const identifierConfig = useMemo(() => {
    if (Object.keys(state.context).length > 0 && state.documentTypeSelected) {
      if (state.documentTypeSelected.identifierConfig) {
        return evaluateStringTemplateList(
          moment,
          state.documentTypeSelected.identifierConfig,
          state.context
        ).result;
      }
    }
    return undefined;
  }, [state.context, state.documentTypeSelected, moment]);

  const onFormValuesChange = useCallback((values: ParametersObject) => {
    setState((prev) => ({ ...prev, context: values }));
  }, []);

  const setDocumentWithSameIdentifier = useCallback((documentVersionId?: string) => {
    setState((prev) => ({ ...prev, documentWithSameIdentifier: documentVersionId }));
  }, []);

  const onDocumentTypeSelect = useCallback(
    (documentType: DocumentTypeResume) => {
      setState((prev) => ({ ...prev, loadingForm: true }));
      getDocumentTypeInfo(documentType.documentTypeId).then((resp) => {
        setState((prev) => ({
          ...prev,
          loadingForm: false,
          documentTypeSelected: resp.documentType,
        }));
      });
    },
    [getDocumentTypeInfo]
  );

  const execDataLink: Services["executeDataLink"] = useCallback(
    (
      dataLinkId: string,
      dataLinkConfigVersionId: string,
      inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
    ) => {
      return executeDataLink(dataLinkId, dataLinkConfigVersionId, inputParameters);
    },
    [executeDataLink]
  );

  const fetchPostSaveDocument = useCallback(
    async (createNewVersion?: boolean) => {
      if (!state.documentTypeSelected || !state.documentTypeSelected.documentTypeVersionId) {
        return undefined;
      }

      const promises = state.files.map((file) => getBase64FromBlob(file.file));

      const documents: Array<string> = await Promise.all(promises);

      if (documents.length === 0 || !state.context) {
        return undefined;
      }
      const document: HttpSaveDocumentRequest["payload"] = {
        documentTypeId: state.documentTypeSelected.documentTypeId,
        contentType: state.mimeTypeSelected as MimeType,
        data: extractedContext || state.context,
        documents: documents.reduce((arr: string[], next) => {
          const p = parseDataURI(next);

          if (p) {
            arr.push(next);
          }
          return arr;
        }, []),
        originMethod: "Imported",
        parentDocumentVersionId: undefined,
        mustUpdateBinaries: !!state.documentWithSameIdentifier,
        createNewVersion,
      };
      return await saveDocument(document);
    },
    [
      extractedContext,
      saveDocument,
      state.context,
      state.documentTypeSelected,
      state.documentWithSameIdentifier,
      state.files,
      state.mimeTypeSelected,
    ]
  );

  const {
    mutate: mutateSaveDocument,
    isLoading: isSaving,
    isSuccess: documentSaved,
  } = useMutation(fetchPostSaveDocument, {
    onSuccess(document) {
      if (!document) {
        return;
      }
      setState((prev) => ({
        ...prev,
        toastMessage: { type: "success", message: resources.success },
      }));
      setTimeout(() => {
        onClearAll();
        organizationNavigate("/");
      }, 3000);
    },
    onError(ex: { code: string; response: { data: { errors: Array<{ message: string }> } } }) {
      const toastError: State["toastMessage"] = {
        type: "error",
        message: resources.errorOccurred,
      };
      const errorResponse = ex.response?.data?.errors[0]?.message;
      if (errorResponse) {
        toastError.message = errorResponse;
      } else if (ex.code === "ECONNABORTED") {
        toastError.message = resources.exceededTimeOut;
      }

      setState((prev) => ({ ...prev, toastMessage: toastError }));
      console.warn("errorOnSaveDocument", ex);
    },
  });

  const onPrev = useCallback(() => {
    if (selectedTabIndex === STEPS.CaptureAndReview) {
      return;
    }
    setTabIndex(selectedTabIndex - 1);
    setState((prev) => ({ ...prev, documentWithSameIdentifier: undefined }));
  }, [selectedTabIndex]);

  const onNext = useCallback(async () => {
    if (selectedTabIndex === STEPS.Index) {
      if (!state.documentTypeSelected || !state.documentTypeSelected.documentTypeVersionId) {
        return;
      }
      if (!state.documentWithSameIdentifier) {
        mutateSaveDocument(undefined);
      }
      return;
    }
  }, [
    mutateSaveDocument,
    selectedTabIndex,
    state.documentTypeSelected,
    state.documentWithSameIdentifier,
  ]);

  const { allDocumentTypes: documentTypeData, isLoading, isRefetching } = useAllDocumentTypes();

  const documentTypes = useMemo(() => {
    return documentTypeData?.documentTypes || [];
  }, [documentTypeData]);

  const handleFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        let mimeType = state.mimeTypeSelected;
        const files = event.target.files;
        const filesArrayRaw: Array<{
          file: File;
          type: MimeType | undefined;
        }> = [];

        for (let i = 0; i < files.length; i++) {
          const file = files.item(i);
          if (file?.type) {
            const allMimeType = documentTypes.flatMap((va) => va.acceptedMimeTypeList);
            const foundMimeType = allMimeType.find((mimeType) => mimeType === file.type);
            if (!foundMimeType) {
              setState((prev) => ({
                ...prev,
                toastMessage: {
                  type: "error",
                  message: resources.documentTypeFormatDoesntExist,
                },
              }));
              event.target.value = "";
              return;
            }
          }
          if (!mimeType) {
            const mType = toMimeType(file?.type || "");

            if (!mType) {
              setState((prev) => ({
                ...prev,
                toastMessage: {
                  type: "error",
                  message: `${resources.dataDictionary.invalidFile}: ${file?.type}`,
                },
              }));
              return;
            }
            mimeType = mType;
          }
          if (file?.type === mimeType) {
            filesArrayRaw.push({
              file,
              type: mimeType,
            });
          }
        }

        const filesArray: Array<{
          file: File;
          type: MimeType | undefined;
        }> = filesArrayRaw.reduce(
          (
            acc: Array<{
              file: File;
              type: MimeType | undefined;
            }>,
            next
          ) => {
            if (next) {
              const mType = toMimeType(next?.type || "");
              if (typeof mType !== "undefined") {
                acc.push({
                  file: next.file,
                  type: mType,
                });
              }
            }

            return acc;
          },
          []
        );

        const fileMaxSize = Number(fileSizeCap) * 1000 * 1000;

        const fileSizeExceeded = filesArray.some((file) => file.file.size > fileMaxSize);
        if (fileSizeExceeded) {
          setState((prev) => ({
            ...prev,
            toastMessage: {
              type: "error",
              message: `File size exceeds the maximum limit of ${fileSizeCap}MB`,
            },
          }));
          return;
        }

        acceptedFormats = mimeType || acceptedFormats;
        setState((prev) => ({
          ...prev,
          mimeTypeSelected: mimeType as MimeType,
          files: mimeType?.includes("image") ? [...prev.files, ...filesArray] : [filesArray[0]],
        }));
      }

      setTabIndex(STEPS.Index);
    },
    [
      state.mimeTypeSelected,
      documentTypes,
      resources.documentTypeFormatDoesntExist,
      resources.dataDictionary.invalidFile,
    ]
  );

  const clickFileInput = useCallback(() => {
    if (
      fileInputRef &&
      fileInputRef.current &&
      (!state.mimeTypeSelected || state.mimeTypeSelected?.includes("image"))
    )
      fileInputRef.current.click();
  }, [state.mimeTypeSelected]);

  const handleDragOver = useCallback((event?: React.DragEvent<HTMLDivElement>) => {
    event?.preventDefault();
    setState((prev) => ({ ...prev, isDragging: true }));
  }, []);

  const handleDragLeave = useCallback(() => {
    setState((prev) => ({ ...prev, isDragging: false }));
  }, []);

  const handleDrop = useCallback(
    (event?: React.DragEvent<HTMLDivElement>) => {
      if (event) {
        event.preventDefault();

        const { files } = event.dataTransfer;

        let mimeType = state.mimeTypeSelected;

        const filesArrayRaw: Array<{
          file: File;
          type: MimeType | undefined;
        }> = [];

        for (let i = 0; i < files.length; i++) {
          const file = files.item(i);
          if (!mimeType) {
            mimeType = file?.type as MimeType;
          }
          if (file?.type === mimeType) {
            filesArrayRaw.push({
              file,
              type: mimeType,
            });
          }
        }

        const filesArray: Array<{
          file: File;
          type: MimeType | undefined;
        }> = filesArrayRaw.reduce(
          (
            acc: Array<{
              file: File;
              type: MimeType | undefined;
            }>,
            next
          ) => {
            if (next) {
              const mType = toMimeType(next?.type || "");
              if (typeof mType !== "undefined") {
                acc.push({
                  file: next.file,
                  type: mType,
                });
              }
            }

            return acc;
          },
          []
        );

        acceptedFormats = mimeType || acceptedFormats;
        setState((prev) => ({
          ...prev,
          mimeTypeSelected: mimeType,
          isDragging: false,
          files: [...prev.files, ...filesArray],
        }));
      }
    },
    [state.mimeTypeSelected]
  );

  const onClearAll = useCallback(() => {
    acceptedFormats = originalFormats;
    setState((prev) => ({ ...prev, files: [], mimeTypeSelected: undefined, filenames: [] }));
  }, []);

  useEffect(() => {
    onClearAll();
  }, [mode, onClearAll]);

  const disableComponents = useMemo(() => {
    return documentSaved || isSaving || state.loadingForm || state.files.length === 0;
  }, [documentSaved, isSaving, state]);

  const disableSave = disableComponents || !state.documentTypeSelected;

  const receivePage = useCallback(
    (page: ScannerImage) => {
      setState((prev) => {
        const newState = { ...prev, files: [...prev.files] };
        if (prev.filenames.includes(page.name)) {
          return newState;
        }
        const file: State["files"][0] = {
          file: new File([page.blob], ""),
          type: MimeTypes.ImageJpeg,
        };
        newState.filenames.push(page.name);
        newState.files.push(file);
        newState.mimeTypeSelected = "image/jpeg";
        return newState;
      });
      setTabIndex(STEPS.Index);
    },
    [setTabIndex]
  );

  const onDevicesReady = useCallback((devices: string[]) => {
    setState((prev) => {
      const newState = {
        ...prev,
        device: devices,
        scanner: devices[firstItemInArray],
      };
      return newState;
    });
  }, []);

  const onScanServiceReady = useCallback((newState: ScannerServiceStatus) => {
    setState((prev) => ({ ...prev, serviceState: newState }));
  }, []);

  const onDeviceChange = useCallback((selectedDevice: string) => {
    setState((prev) => ({ ...prev, scanner: selectedDevice }));
  }, []);

  const onCancelCaptureImages = () => {
    setState((prev) => {
      return {
        ...prev,
        toastMessage: {
          message: "ImageAcquiredCanceled",
          type: "warn",
        },
      };
    });
  };

  return {
    STEPS,
    resources,
    breakpoint,
    fileInputRef,
    organizationNavigate,

    isLoading,
    isRefetching,
    selectedTabIndex,
    acceptedFormats,
    documentTypes,

    identifierConfig,
    state,
    setState,
    mutateSaveDocument,
    onPrev,
    onNext,
    onClearAll,
    execDataLink,
    handleFileUpload,
    clickFileInput,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    onFormValuesChange,
    setDocumentWithSameIdentifier,
    onDocumentTypeSelect,
    disableComponents,
    disableSave,
    documentLoadingState,
    setDocumentLoadingState,
    isDocumentSearchOpen,
    setDocumentSearchOpen,
    extractedContext,
    receivePage,
    onScanServiceReady,
    onDeviceChange,
    onDevicesReady,
    onCancelCaptureImages,
  };
};

type Props = {
  documentTypeSelected: DocumentTypeInfo;
  setDocumentWithSameIdentifier: (documentVersionId?: string) => void;
};

export const useIndexingFormWrapper = ({
  documentTypeSelected,
  setDocumentWithSameIdentifier,
}: Props) => {
  const { getDocumentByIdentifier } = useServices();
  const { resources, moment } = useAppTranslation();
  const { t: i18n } = useTranslation();
  const { context, setContext } = useFormController();

  const [showUseData, setShowUseData] = useState<boolean>(false);

  const identifierConfig = useMemo(() => {
    if (context) {
      if (documentTypeSelected.identifierConfig) {
        return evaluateStringTemplateList(moment, documentTypeSelected.identifierConfig, context)
          .result;
      }
    }
    return undefined;
  }, [context, documentTypeSelected, moment]);

  const fetchDocumentByIdentifier = useCallback(async () => {
    if (documentTypeSelected && identifierConfig) {
      return await getDocumentByIdentifier(documentTypeSelected.documentTypeId, identifierConfig);
    }
    return {
      documentId: undefined,
      documentVersionId: undefined,
      data: undefined,
    };
  }, [documentTypeSelected, identifierConfig, getDocumentByIdentifier]);

  const {
    data: documentByIdentifier,
    isLoading,
    isRefetching,
  } = useOrganizationQuery(
    ["document", documentTypeSelected?.documentTypeId || "", "identifier", identifierConfig || ""],
    fetchDocumentByIdentifier,
    {
      onSuccess(data) {
        if (data.documentId) {
          setDocumentWithSameIdentifier(data.documentVersionId);
          setShowUseData(true);
        } else {
          setDocumentWithSameIdentifier(undefined);
          setShowUseData(false);
        }
      },
      onError() {
        setDocumentWithSameIdentifier(undefined);
        setShowUseData(false);
      },
    }
  );

  const onClickUseDocumentData = useCallback(() => {
    if (documentByIdentifier?.documentId) {
      setContext((prev) => ({ ...prev, ...documentByIdentifier.data }));
      setDocumentWithSameIdentifier(documentByIdentifier.documentVersionId);
    }
    setShowUseData(false);
  }, [documentByIdentifier, setContext, setDocumentWithSameIdentifier]);

  return {
    i18n,
    resources,
    moment,

    isLoading: isLoading || isRefetching,
    identifierConfig,
    showUseData,
    setShowUseData,
    onClickUseDocumentData,
  };
};
