import {
  Box,
  BoxWithRef,
  Divider,
  DynamicIconButton,
  PopoverMenu,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { buildEventEmitter } from "@prodoctivity/shared";
import type { DashboardConfigRow } from "@prodoctivity/types";
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { SmallRefreshButton } from "../../components/SmallRefreshButton";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { DashboardElementImplementation } from "./hooks";

export type DashboardItemEvents = {
  refresh: Record<string, never>;
};

export const DashboardItemContext = createContext<{
  isSidebarComponent: boolean;
  eventEmitter:
    | undefined
    | ReturnType<typeof buildEventEmitter<DashboardItemEvents, keyof DashboardItemEvents>>;
}>({
  isSidebarComponent: false,
  eventEmitter: undefined,
});

type Props = {
  row: DashboardConfigRow;
  column: DashboardElementImplementation[];
  rowItem: DashboardElementImplementation;
  index: number;
  colIndex: number;
  subIndex: number;
  newColumnId: number | null;
  setNewColumnId: Dispatch<SetStateAction<number | null>>;
  allowEditionMode: number | boolean;
  handleMoveColumnItemVertically: (
    move: "up" | "down",
    rowIndex: number,
    colIndex: number,
    itemIndex: number
  ) => void;
  mainContentFunctionsBundle: ((parametersObject?: any) => void)[];
  mainContentEllipsisOptions: string[];
  handleOpenListOfWidgets: ({
    rowIndex,
    colIndex,
    addToSidebar,
  }: {
    rowIndex: number | null;
    colIndex: number | null;
    addToSidebar: boolean;
  }) => void;
};

export const DashboardConfigColumnElement: FunctionComponent<Props> = ({
  row,
  column,
  rowItem,
  index,
  colIndex,
  subIndex,
  newColumnId,
  setNewColumnId,
  allowEditionMode,
  handleMoveColumnItemVertically,
  mainContentFunctionsBundle,
  mainContentEllipsisOptions,
  handleOpenListOfWidgets,
}) => {
  const { colors } = useColors();
  const { Component } = rowItem;
  const columnRef = useRef<HTMLDivElement | null>(null);
  const { resources } = useAppTranslation();

  const dashboardItemEventEmitter = buildEventEmitter<
    DashboardItemEvents,
    keyof DashboardItemEvents
  >();

  const itemContextValue = useMemo(() => {
    return {
      isSidebarComponent: false,
      eventEmitter: dashboardItemEventEmitter,
    };
  }, [dashboardItemEventEmitter]);

  const emitRefetch = useCallback(() => {
    dashboardItemEventEmitter.emit("refresh", {});
  }, [dashboardItemEventEmitter]);

  useEffect(() => {
    if (subIndex === newColumnId && columnRef.current) {
      columnRef.current.scrollIntoView({ behavior: "smooth" });
      setNewColumnId(null);
    }
  }, [subIndex, newColumnId, setNewColumnId]);

  return (
    <BoxWithRef
      ref={columnRef}
      width={"100%"}
      height={"100%"}
      display="flex"
      direction="column"
      marginBottom={4}
      key={`Row#${index}_Column#${colIndex}_${rowItem.elementType}_#${subIndex}`}
      color={colors.white}
      borderRadius={12}
      dangerouslySetInlineStyle={{
        __style: {
          borderBottom: "1px solid transparent",
          // borderBottomLeftRadius: "16px",
          // borderBottomRightRadius: "16px",
          boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.12)",
        },
      }}
      overflow="hidden"
      marginStart={1}
    >
      <DashboardItemContext.Provider value={itemContextValue}>
        <Box
          width={"100%"}
          height={60}
          color={colors.neutral200}
          display="flex"
          direction="column"
          justifyContent="between"
        >
          <Box
            width={"100%"}
            height={60}
            alignItems="center"
            display="flex"
            justifyContent="between"
            paddingRight={2}
            paddingLeft={3}
          >
            <Box display="flex" gap={4} justifyContent="start" alignItems="center" flex="grow">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={"fit-content"}
                height={"fit-content"}
              >
                {rowItem.icon}
              </Box>
              <Box height={"100%"}>
                <Text weight="bold">{rowItem.contextDefinition.fields[0].humanName}</Text>
              </Box>
              <Box display="flex" flex="grow">
                <Box margin={1} />
              </Box>
              {rowItem.allowsRefresh && (
                <Box display="flex" flex="shrink">
                  <SmallRefreshButton
                    accessibilityLabel={resources.refresh}
                    refetch={emitRefetch}
                    marginTop={0}
                  />
                  <Box margin={1} />
                </Box>
              )}
            </Box>

            {!allowEditionMode || rowItem.elementType === "placeholder" ? null : (
              <Box display="flex">
                <Box
                  width={"fit-content"}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <DynamicIconButton
                    buttonName={"moveDown"}
                    handleFunction={() => {
                      if (column.length === 1 || subIndex === column.length - 1) return;
                      handleMoveColumnItemVertically("down", index, colIndex, subIndex);
                    }}
                    statefulCondition={undefined}
                    selected={undefined}
                  ></DynamicIconButton>
                </Box>

                <Box
                  width={"fit-content"}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <DynamicIconButton
                    buttonName={"moveUp"}
                    handleFunction={() => {
                      if (column.length === 1 || subIndex === 0) return;
                      handleMoveColumnItemVertically("up", index, colIndex, subIndex);
                    }}
                    statefulCondition={undefined}
                    selected={undefined}
                  ></DynamicIconButton>
                </Box>
                <PopoverMenu
                  handleFunctionsBundle={mainContentFunctionsBundle}
                  menuLabels={mainContentEllipsisOptions}
                  collapsibleMenuIcon="verticalEllipsis"
                  menuHasIcons={false}
                  srcAreImages={undefined}
                  bundleNeedsParameters={true}
                  parametersSet={{
                    columnCount: row.columnCount,
                    rowIndex: index,
                    colIndex: colIndex,
                    itemIndex: subIndex,
                  }}
                  parameterizedFunctionIndex={[0, 1, 2]}
                />
              </Box>
            )}
          </Box>
          {/* <Divider direction="horizontal" size={1}></Divider> */}
          <Divider color={colors.primary} size={2} direction="horizontal"></Divider>
        </Box>
        <Box
          height={"100%"}
          onClickCapture={() => {
            if (rowItem.elementType !== "placeholder") return;
            handleOpenListOfWidgets({
              rowIndex: index,
              colIndex: colIndex,
              addToSidebar: false,
            });
          }}
        >
          <Component />
        </Box>
      </DashboardItemContext.Provider>
    </BoxWithRef>
  );
};
