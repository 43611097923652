import {
  Box,
  BoxWithRef,
  Button,
  Divider,
  DocumentViewerDigitalSignatureItem,
  DocumentViewerEvents,
  Flex,
  IconButton,
  Label,
  PubSubEventManager,
  PubSubEventManagerCallback,
  RadioGroup,
  SelectList,
  Spinner,
  Text,
  TextArea,
  TextField,
  WhatsThis,
  useColors,
  useSubscribe,
} from "@prodoctivity/design-system";
import { MimeTypes, createDataURIFromBase64, parseDataURI } from "@prodoctivity/shared";
import type {
  generatePKCS12fileSignature,
  signPDFDocumentP12Signature,
  toBase64Signature,
  verifyPKCS12fileSignature,
} from "@prodoctivity/signature-tools/src/signature";
import type { EcmDocument } from "@prodoctivity/types";
import { toPng } from "html-to-image";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useScript } from "usehooks-ts";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { DataDictionaryTabs } from "../../pages/Settings/DataDictionary/DataElements/DataDictionaryTabs";
import { GraphicMarkRenderWidget } from "../../pages/Settings/GraphicMarks/GraphicMarkBuilder/GraphicMark";
import { FancyDateTime } from "../Display/FancyDateTime";
import { usePaginatedDataEndpoint } from "../hooks";
import { ViewerErrorModal } from "./ViewerErrorModal";

export type ViewerSignatureComponentProps = {
  document: EcmDocument | undefined;
  eventManager: PubSubEventManager<DocumentViewerEvents>;
  goToLastVersionCallback: () => void;
  defaultOpenTab?: "pkcs12Generation" | "signature" | undefined;
};

declare const prodoctivitySignPDFDocumentP12: signPDFDocumentP12Signature | undefined;
declare const prodoctivityVerifyPKCS12file: verifyPKCS12fileSignature | undefined;
declare const prodoctivityGeneratePKCS12file: generatePKCS12fileSignature | undefined;
declare const prodoctivityUTF8toBase64: toBase64Signature | undefined;

export function ViewerSignatureComponent(props: ViewerSignatureComponentProps) {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { saveDocument, getWordAsPdf } = useServices();
  const navigateTo = useOrganizationNavigate();

  const signatureClientScriptStatus = useScript("/signature-resources/signature.min.js", {
    removeOnUnmount: true,
  });

  const [openSignatureTabsArray, setOpenSignatureTabsArray] = useState<
    Array<"general" | "input" | "output">
  >(
    props.defaultOpenTab
      ? props.defaultOpenTab === "pkcs12Generation"
        ? ["general"]
        : ["input"]
      : []
  );

  const setOpenSignatureTab = useCallback(
    (tab: "general" | "input" | "output", value: boolean) => {
      if (value) {
        if (!openSignatureTabsArray.includes(tab)) {
          setOpenSignatureTabsArray([...openSignatureTabsArray, tab]);
        }
      } else {
        setOpenSignatureTabsArray(openSignatureTabsArray.filter((item) => item !== tab));
      }
    },
    [openSignatureTabsArray]
  );

  const openSignatureTabs = useMemo(() => {
    const result = new Set<"general" | "input" | "output">(openSignatureTabsArray);

    return result;
  }, [openSignatureTabsArray]);

  const [selectedSignatureMethod, setSelectedSignatureMethod] = useState<
    "local_PKCS12_PFX" | "oneshot" | "signbox"
  >("local_PKCS12_PFX");
  const { document } = props;

  const updateDocumentCallback = useCallback(
    async (newDocumentBase64: string) => {
      if (!document) {
        throw new Error("Document is not set.");
      }

      const saveDocumentResult = await saveDocument({
        data: document.data,
        documents: [`data:application/pdf;base64,${newDocumentBase64}`],
        documentTypeId: document.documentTypeId,
        originMethod: "Imported",
        parentDocumentVersionId: document.documentVersionId,
        contentType: "application/pdf",
        mustUpdateBinaries: true,
      });

      navigateTo(
        organizationLinkTemplates.documentIdAndVersion(
          saveDocumentResult.documentId,
          saveDocumentResult.documentVersionId,
          "info"
        )
      );
    },
    [document, navigateTo, saveDocument]
  );

  const saveDocumentAsPdf = useCallback(async () => {
    if (
      props.document &&
      props.document.mimeType === MimeTypes.MsWordDocument &&
      props.document.binaries.length > 0
    ) {
      const wordData = props.document.binaries[0];
      const wordDataUri = createDataURIFromBase64(wordData, MimeTypes.MsWordDocument);

      try {
        const conversionResult = await getWordAsPdf(wordDataUri, props.document.documentVersionId);

        if (!conversionResult.pdfDataUri) {
          throw new Error("Error during pdf conversion process.");
        }

        const documentAsPdf = parseDataURI(conversionResult.pdfDataUri);
        if (!documentAsPdf) {
          throw new Error("Error parsing converted pdf document data uri.");
        }

        if (documentAsPdf.contentType !== MimeTypes.PdfDocument) {
          throw new Error(
            `Returned data uri for PDF conversion has invalid content type: '${documentAsPdf.contentType}'.`
          );
        }

        await updateDocumentCallback(documentAsPdf.base64Data);
      } catch (e) {
        console.error(`${e}`);
        throw e;
      }
    } else {
      console.error("No word document loaded.");
    }
  }, [getWordAsPdf, props.document, updateDocumentCallback]);

  useEffect(() => {
    if (signatureClientScriptStatus === "ready") {
      if (
        typeof prodoctivitySignPDFDocumentP12 !== "undefined" &&
        typeof prodoctivityVerifyPKCS12file !== "undefined" &&
        typeof prodoctivityGeneratePKCS12file !== "undefined" &&
        typeof prodoctivityUTF8toBase64 !== "undefined"
      ) {
        console.info("Signature library is loaded.");
      } else {
        console.error("Signature library could not be loaded.");
      }
    }
  }, [signatureClientScriptStatus]);

  const [convertButtonIsEnabled, setConvertButtonIsEnabled] = useState<boolean>(true);

  if (!props.document || props.document.mimeType !== "application/pdf") {
    return (
      <Box margin={4} padding={2} borderStyle="sm" borderRadius={4}>
        <Text weight="bold" color={colors.error} align="center" size="300">
          {resources.documentViewerSignatureWidget.notice}
        </Text>
        <Text size="200">{resources.documentViewerSignatureWidget.documentMustBeConverted}</Text>
        <Box display="flex" direction="column" flex="grow" justifyContent="center" gap={2}>
          <Button
            disabled={!convertButtonIsEnabled}
            type="button"
            onClick={(_e) => {
              setConvertButtonIsEnabled(false);
              setTimeout(() => {
                saveDocumentAsPdf();
              }, 500);
            }}
            text={resources.documentViewerSignatureWidget.clickHere}
          />
          <Spinner show={!convertButtonIsEnabled} />
        </Box>
      </Box>
    );
  }

  if (!props.eventManager) {
    <Box margin={4} padding={2} borderStyle="sm" borderRadius={4}>
      <Text weight="bold" color={colors.error} align="center" size="300">
        {resources.documentViewerSignatureWidget.notice}
      </Text>
      <Text size="200">This feature is currently unavailable.</Text>
    </Box>;
  }

  if (signatureClientScriptStatus !== "ready") {
    return (
      <Box padding={4}>
        <Spinner show={true} size="md" />
      </Box>
    );
  } else {
    return (
      <Box padding={2} gap={2} display="flex" direction="column">
        <DataDictionaryTabs
          key={"sign-documents-tabs"}
          tabSummary={[resources.generatePkcs12File, resources.signThisDocument]}
          openTabs={openSignatureTabs}
          setOpenTab={setOpenSignatureTab}
          tabs={[
            {
              title: "",
              content: (
                <Box display="flex" direction="column" gap={2} padding={2}>
                  <LocalGeneratePKCS12Widget
                    PKCS12GenerationFunction={prodoctivityGeneratePKCS12file}
                    toBase64={prodoctivityUTF8toBase64}
                  />
                </Box>
              ),
            },
            {
              title: "",
              content: (
                <Box display="flex" direction="column" gap={2} padding={0}>
                  <Box
                    margin={1}
                    padding={1}
                    borderStyle="sm"
                    borderRadius={4}
                    justifyContent="center"
                    display="visuallyHidden"
                  >
                    <RadioGroup
                      id={"radio-group-signature-type"}
                      legend={"Please select signature method"}
                      legendDisplay={"hidden"}
                      direction="row"
                    >
                      <RadioGroup.RadioButton
                        id="radio-option-local"
                        label="P12"
                        onChange={(_event) => {
                          setSelectedSignatureMethod("local_PKCS12_PFX");
                        }}
                        checked={selectedSignatureMethod === "local_PKCS12_PFX"}
                        value="local_PKCS12_PFX"
                      />

                      <RadioGroup.RadioButton
                        id="radio-option-oneshot"
                        label="OneShot"
                        onChange={(_event) => {
                          setSelectedSignatureMethod("oneshot");
                        }}
                        checked={selectedSignatureMethod === "oneshot"}
                        disabled={true}
                        value="oneshot"
                      />
                      <RadioGroup.RadioButton
                        id="radio-option-signbox"
                        label="SignBox"
                        onChange={(_event) => {
                          setSelectedSignatureMethod("signbox");
                        }}
                        disabled={true}
                        checked={selectedSignatureMethod === "signbox"}
                        value="signbox"
                      />
                    </RadioGroup>
                  </Box>

                  <Box>
                    {selectedSignatureMethod === "local_PKCS12_PFX" && (
                      <LocalSignatureWidget
                        document={props.document}
                        PKCS12VerificationFunction={prodoctivityVerifyPKCS12file}
                        PKCS12GenerationFunction={prodoctivityGeneratePKCS12file}
                        toBase64={prodoctivityUTF8toBase64}
                        updateDocumentCallback={updateDocumentCallback}
                        eventManager={props.eventManager}
                      />
                    )}
                    {selectedSignatureMethod === "oneshot" && <OneShotSignatureWidget />}
                    {selectedSignatureMethod === "signbox" && <SignBoxSignatureWidget />}
                  </Box>
                </Box>
              ),
            },
          ]}
        />

        <ViewerSignatureListWidget document={props.document} eventManager={props.eventManager} />
      </Box>
    );
  }
}

type LocalP12GenerationProps = {
  PKCS12GenerationFunction: generatePKCS12fileSignature | undefined;
  toBase64: toBase64Signature | undefined;
};

type LocalSignatureWidgetProps = {
  document: EcmDocument | undefined;
  PKCS12VerificationFunction: verifyPKCS12fileSignature | undefined;
  PKCS12GenerationFunction: generatePKCS12fileSignature | undefined;
  toBase64: toBase64Signature | undefined;
  eventManager: ViewerSignatureComponentProps["eventManager"];
  updateDocumentCallback: (base64Data: string) => void;
};

type PKCS12ConfigurationOptions = {
  appendToLastPage: boolean;
  signaturePageIndex: number;
  name: string;
  contactInfo: string;
  locationInfo: string;
  reason: string;
  x: number;
  y: number;
  height: number;
  width: number;
};

export type CertificateData = {
  name: string;
  location: string;
  contact: string;
  mail: string;
  DN: string;
};

function LocalGeneratePKCS12Widget(props: LocalP12GenerationProps) {
  const [certificateChain, setCertificateChain] = useState<string | undefined>(undefined);
  const { resources } = useAppTranslation();
  const [privateKey, setPrivateKey] = useState<string | undefined>(undefined);
  const [passphrase, setPassPhrase] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const passphraseIsValid = useMemo(() => {
    return passphrase.length > 6;
  }, [passphrase]);

  const certificateInputRef = useRef<HTMLInputElement>(null);
  const pkInputRef = useRef<HTMLInputElement>(null);

  const generationIsPossible = useMemo(() => {
    if (
      certificateChain &&
      privateKey &&
      passphraseIsValid &&
      props.PKCS12GenerationFunction !== undefined
    ) {
      return true;
    }
    return false;
  }, [props, certificateChain, passphraseIsValid, privateKey]);

  const { colors } = useColors();

  const generateKeyFile = useCallback(async () => {
    if (
      certificateChain &&
      privateKey &&
      passphraseIsValid &&
      props.PKCS12GenerationFunction !== undefined &&
      props.toBase64 !== undefined
    ) {
      const generationResult = await props.PKCS12GenerationFunction(
        props.toBase64(certificateChain),
        props.toBase64(privateKey),
        passphrase
      );

      return generationResult;
    }
  }, [props, certificateChain, passphrase, passphraseIsValid, privateKey]);

  const certificateInputChanged = useCallback(() => {
    const inputControl = certificateInputRef.current;
    if (inputControl && inputControl.files && inputControl.files.length) {
      const keyFile = inputControl.files[0];
      const reader = new FileReader();

      reader.onloadend = async (_event) => {
        if (reader.result && typeof reader.result === "string") {
          const parsedResult = reader.result.replace(/data:.*\/.*;base64,/, "");
          setCertificateChain(parsedResult);
          console.info("Certificate was loaded successfully.");
        } else {
          console.error("Error reading key File.");
        }
      };
      reader.readAsDataURL(keyFile);
    }
  }, []);

  const pkInputChanged = useCallback(() => {
    const inputControl = pkInputRef.current;
    if (inputControl && inputControl.files && inputControl.files.length) {
      const keyFile = inputControl.files[0];
      const reader = new FileReader();

      reader.onloadend = async (_event) => {
        if (reader.result && typeof reader.result === "string") {
          const parsedResult = reader.result.replace(/data:.*\/.*;base64,/, "");
          setPrivateKey(parsedResult);
          console.info("Private Key was loaded successfully.");
        } else {
          console.error("Error reading key File.");
        }
      };
      reader.readAsDataURL(keyFile);
    }
  }, []);

  if (props.PKCS12GenerationFunction && props.toBase64) {
    return (
      <>
        <Box display="flex" direction="column">
          <Box margin={1} padding={1} display="flex" flex="grow" direction="column" gap={2}>
            <Flex direction="row" gap={1} alignItems="center">
              <Text color={colors.primary400}>
                {resources.documentViewerSignatureWidget.whatIsAKeyFile}
              </Text>
              <WhatsThis
                title="PKCS12/PFX"
                colorBundle={colors}
                size="sm"
                clickContent={
                  <Text>
                    {resources.documentViewerSignatureWidget.pleaseSelectYourKeyFileExplanation}
                    <Divider />
                    {resources.documentViewerSignatureWidget.pleaseSelectYourKeyFileWarning}
                  </Text>
                }
              />
            </Flex>
          </Box>
          <input
            style={{ display: "none" }}
            type="file"
            accept=".pem,.key,.cert"
            id="input-get-certificate"
            title="Certificate"
            ref={certificateInputRef}
            onChange={certificateInputChanged}
          />
          <input
            style={{ display: "none" }}
            type="file"
            id="input-get-pk"
            title="Private Key"
            accept=".pem,.key"
            ref={pkInputRef}
            onChange={pkInputChanged}
          />

          <Box marginBottom={1}>
            <Flex direction="row">
              <Label htmlFor="txt-get-certificate">
                <Text color={colors.primary400}>{resources.certificate}</Text>
              </Label>
              <IconButton
                icon="add-note"
                onClick={() => certificateInputRef.current?.click()}
                accessibilityLabel="Upload Certificate file"
              />
            </Flex>

            <TextArea
              id="txt-get-certificate"
              value={certificateChain}
              onChange={(e) => setCertificateChain(e.value)}
            />
          </Box>

          <Box marginBottom={1}>
            <Flex direction="row">
              <Label htmlFor="txt-get-pk">
                <Text color={colors.primary400}>{resources.privateKey}</Text>
              </Label>
              <IconButton
                icon="add-note"
                onClick={() => pkInputRef.current?.click()}
                accessibilityLabel="Upload Private Key"
              />
            </Flex>

            <TextArea value={privateKey} id="txt-get-pk" onChange={(e) => setPrivateKey(e.value)} />
          </Box>

          <Box marginBottom={2}>
            <Label htmlFor="text-passphrase">
              <Text color={colors.primary400}>{resources.passphrase}</Text>
            </Label>
            <TextField
              type="password"
              value={passphrase}
              hasError={!passphraseIsValid && passphrase.length > 0}
              errorMessage={
                !passphraseIsValid && passphrase.length > 0 ? resources.passphraseLimit : undefined
              }
              size="sm"
              onChange={(e) => setPassPhrase(e.value)}
              id="text-passphrase"
            />
          </Box>
          <Button
            text={resources.downloadP12KeyFile}
            disabled={!generationIsPossible}
            onClick={async () => {
              const generationResult = await generateKeyFile();
              console.info(generationResult);

              if (generationResult?.result) {
                const data = Uint8Array.from(atob(generationResult.p12FileAsBase64), (c) =>
                  c.charCodeAt(0)
                );
                const url = window.URL.createObjectURL(new Blob([data]));
                const fileName = `keyfile.p12`;

                const anchorElement: HTMLAnchorElement = document.createElement("a");
                anchorElement.href = url;
                anchorElement.innerText = fileName;
                anchorElement.target = "_blank";
                anchorElement.setAttribute("download", fileName);
                anchorElement.click();
                anchorElement.remove();
              } else {
                if (generationResult?.result === false) {
                  setShowErrorModal(true);
                }
              }
            }}
          />
        </Box>
        <ViewerErrorModal
          show={showErrorModal}
          onDismiss={() => setShowErrorModal(false)}
          errorMessage={
            resources.documentViewerSignatureWidget.pleaseEnsureCertificateFileAndPrivateKey
          }
        />
      </>
    );
  } else {
    <Spinner show={true} size="md" />;
  }
}

function LocalSignatureWidget(props: LocalSignatureWidgetProps) {
  const { document, PKCS12VerificationFunction, eventManager, updateDocumentCallback } = props;
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  const [keyFileDataBase64, setKeyFileBase64] = useState<string | undefined>(
    localStorage.getItem("currentKeyFile") ?? undefined
  );
  const [signatureImageDataURI, setSignatureImageDataURI] = useState<string | undefined>(undefined);
  const [keyFilePassPhrase, setKeyFilePassPhrase] = useState<string>("");
  const [keyFileStatus, setKeyFileStatus] = useState<
    { isValid: boolean; errorMessage: string } | undefined
  >(undefined);
  const [certificateData, setCertificateData] = useState<CertificateData | undefined>(undefined);
  const [rectangleData, setRectangleData] = useState<
    { x: number; y: number; height: number; width: number; pageIndex: number } | undefined
  >(undefined);

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const fileInputRef = useRef<HTMLInputElement>(null);
  const boxElementRef = useRef<HTMLDivElement>(null);

  const cleanup = useCallback(() => {
    setKeyFileBase64(undefined);
    setKeyFilePassPhrase("");
    setCertificateData(undefined);
    setRectangleData(undefined);
    eventManager.publish({ type: "clear-measurement-annotation" });
    setSignatureImageDataURI(undefined);
    localStorage.removeItem("currentKeyFile");
  }, [eventManager]);

  const onKeyFileInputChanged = useCallback(() => {
    const inputControl = fileInputRef.current;
    if (inputControl && inputControl.files && inputControl.files.length) {
      const keyFile = inputControl.files[0];
      const reader = new FileReader();

      reader.onloadend = async (_event) => {
        if (
          reader.result &&
          typeof reader.result === "string" &&
          document &&
          document.binaries[0]
        ) {
          const parsedResult = reader.result.replace(/data:.*\/.*;base64,/, "");
          setKeyFileBase64(parsedResult);
          localStorage.setItem("currentKeyFile", parsedResult);
          console.info("Key File was loaded successfully.");
        } else {
          console.error("Error reading key File.");
        }
      };
      reader.readAsDataURL(keyFile);
    } else {
      cleanup();
    }
  }, [document, cleanup]);

  const validateKeyFile = useCallback(
    async (passphrase: string, keyFileDataBase64: string) => {
      if (!PKCS12VerificationFunction) {
        console.error("PKCS12 verification function is unavailable.");
        return false;
      }

      const certificateData = await PKCS12VerificationFunction(keyFileDataBase64, passphrase);
      if (certificateData.length > 0) {
        setCertificateData(certificateData[0]);
        return true;
      } else {
        setCertificateData(undefined);
        return false;
      }
    },
    [PKCS12VerificationFunction]
  );

  const updateSignatureRectangle = useCallback(
    (x: number, y: number, height: number, width: number, pageIndex: number) => {
      setRectangleData({ x, y, height, width, pageIndex });
    },
    [setRectangleData]
  );

  const signPKCS12Callback = useCallback(
    (
      options: PKCS12ConfigurationOptions,
      keyFileBase64Data: string,
      documentBase64Data: string,
      signatureImageDataURI: string,
      _documentName: string
    ) => {
      if (!prodoctivitySignPDFDocumentP12) {
        throw new Error("Function 'prodoctivitySignPDFDocumentP12' is not defined.");
      }

      if (!prodoctivityVerifyPKCS12file) {
        throw new Error("Function 'prodoctivityVerifyPKCS12file' is not defined.");
      }

      const imageData = parseDataURI(signatureImageDataURI);
      if (!imageData) {
        throw new Error("Cannot parse signature image data for signature.");
      }

      const signedFileMaybe = prodoctivitySignPDFDocumentP12(
        documentBase64Data,
        keyFileBase64Data,
        {
          contactInfo: options.contactInfo,
          location: options.locationInfo,
          name: options.name,
          pageIndex: options.appendToLastPage ? "AppendAtEnd" : options.signaturePageIndex,
          reason: options.reason,
          rectangle: {
            x: options.x,
            y: options.y,
            borderColor: { blue: 0, green: 0, red: 255, type: "RGB" },
            borderOpacity: 1,
            borderWidth: 1,
            height: options.height,
            width: options.width,
          },
          signatureImageData: {
            base64Data: imageData.base64Data,
            dataType: imageData.contentType,
          },
        },
        keyFilePassPhrase
      );

      signedFileMaybe
        .then((file) => {
          updateDocumentCallback(file);
        })
        .catch((error) => {
          console.error("Signature has failed!");
          console.error(error);
        });
    },
    [updateDocumentCallback, keyFilePassPhrase]
  );

  const [documentIsBeingSigned, setDocumentIsBeingSigned] = useState<boolean>(false);

  const eventManagerCallback: PubSubEventManagerCallback<DocumentViewerEvents> = useCallback(
    (ev) => {
      switch (ev.type) {
        case "rectangular-annotation-created": {
          const { x, y, height, width, pageIndex } = ev.value;
          updateSignatureRectangle(x, y, height, width, pageIndex);
          break;
        }
        default:
          break;
      }
    },
    [updateSignatureRectangle]
  );

  useSubscribe(eventManager, eventManagerCallback);

  return (
    <>
      <Box>
        <Box
          margin={1}
          padding={2}
          borderStyle="sm"
          borderRadius={4}
          display="flex"
          flex="grow"
          direction="column"
          gap={2}
        >
          <Flex direction="row" gap={1} alignItems="center">
            {keyFileDataBase64 && (
              <Text color={colors.primary400}>
                <Label htmlFor="key-file-input">
                  {resources.documentViewerSignatureWidget.pleaseSelectYourKeyFileAlternate}
                </Label>
              </Text>
            )}
            {!keyFileDataBase64 && (
              <Text color={colors.primary400}>
                <Label htmlFor="key-file-input">
                  {resources.documentViewerSignatureWidget.pleaseSelectYourKeyFile}
                </Label>
              </Text>
            )}
            <WhatsThis
              title="PKCS12/PFX"
              colorBundle={colors}
              size="sm"
              clickContent={
                <Text>
                  {resources.documentViewerSignatureWidget.pleaseSelectYourKeyFileExplanation}
                  <Divider />
                  {resources.documentViewerSignatureWidget.pleaseSelectYourKeyFileWarning}
                </Text>
              }
            />
          </Flex>

          {keyFileDataBase64 &&
            localStorage.getItem("currentKeyFile") &&
            fileInputRef.current &&
            (!fileInputRef.current.files || fileInputRef.current.files.length === 0) && (
              <Box display="flex" direction="row" alignContent="center" alignItems="center" gap={1}>
                <Text size="100">
                  (
                  {
                    resources.documentViewerSignatureWidget
                      .pleaseSelectYourKeyFileLocalStorageLoaded
                  }
                  )
                </Text>
                <IconButton
                  onClick={() => {
                    cleanup();
                  }}
                  icon="trash"
                  accessibilityLabel={
                    resources.documentViewerSignatureWidget.pleaseSelectYourKeyFileAlternate
                  }
                />
              </Box>
            )}

          {keyFileDataBase64 &&
            fileInputRef.current &&
            fileInputRef.current.files &&
            fileInputRef.current.files.length > 0 && (
              <Flex direction="row" gap={2} alignItems="start">
                <Text weight="bold"> {fileInputRef.current.files[0].type}: </Text>
                <Text> {fileInputRef.current.files[0].name}</Text>
                <IconButton
                  onClick={() => {
                    cleanup();
                  }}
                  icon="trash"
                  accessibilityLabel={
                    resources.documentViewerSignatureWidget.pleaseSelectYourKeyFileAlternate
                  }
                />
              </Flex>
            )}

          {certificateData && (
            <Flex direction="column" alignItems="start">
              <Flex direction="row" gap={1} alignItems="start">
                <Text weight="bold">CN:</Text>
                <Text> {certificateData.name}</Text>
              </Flex>

              <Flex direction="row" gap={1} alignItems="start">
                <Text weight="bold">LOCATION:</Text>
                <Text> {certificateData.location}</Text>
              </Flex>

              <Flex direction="row" gap={1} alignItems="start">
                <Text weight="bold">CONTACT:</Text>
                <Text> {certificateData.contact}</Text>
              </Flex>
            </Flex>
          )}

          <input
            style={{ display: "none" }}
            type="file"
            accept=".p12,.pfx"
            onChange={onKeyFileInputChanged}
            ref={fileInputRef}
            title="Signature Key File"
            id="key-file-input"
            width={"50%"}
          />

          <Box display="flex" flex="grow" direction="column" gap={2} hidden={!keyFileDataBase64}>
            <Text color={colors.primary400}>
              <Label htmlFor="passphrase">
                {resources.documentViewerSignatureWidget.pleaseTypeKeyFilePassPhrase}
              </Label>
            </Text>

            <TextField
              hasError={keyFileStatus && !keyFileStatus.isValid && keyFilePassPhrase.length > 0}
              errorMessage={
                keyFileStatus && !keyFileStatus.isValid && keyFilePassPhrase.length > 0
                  ? keyFileStatus.errorMessage
                  : undefined
              }
              size="sm"
              type="password"
              id="passphrase"
              value={keyFilePassPhrase}
              disabled={!keyFileDataBase64}
              onChange={async (e) => {
                if (keyFileDataBase64) {
                  setKeyFilePassPhrase(e.value);
                  setCertificateData(undefined);
                  try {
                    const result = await validateKeyFile(e.value, keyFileDataBase64);
                    setKeyFileStatus({
                      isValid: result,
                      errorMessage: !result
                        ? resources.documentViewerSignatureWidget.errorMessageInvalidPassphrase
                        : "",
                    });
                    if (!result) {
                      setErrorMessage(
                        resources.documentViewerSignatureWidget.errorMessageInvalidPassphrase
                      );
                    }
                  } catch (e) {
                    const errorMessage = `${e}`;
                    const errorToShow = errorMessage.includes("password")
                      ? resources.documentViewerSignatureWidget.errorMessageInvalidPassphrase
                      : resources.documentViewerSignatureWidget.errorMessageInvalidKeyfile;
                    setKeyFileStatus({
                      isValid: false,
                      errorMessage: errorToShow,
                    });
                    setErrorMessage(errorToShow);
                    if (
                      errorToShow ===
                      resources.documentViewerSignatureWidget.errorMessageInvalidKeyfile
                    ) {
                      setShowErrorModal(true);
                    }
                  }
                }
              }}
            />
          </Box>
        </Box>
        <Box
          margin={1}
          padding={2}
          borderStyle="sm"
          borderRadius={4}
          display="flex"
          flex="grow"
          direction="column"
          gap={2}
          hidden={!certificateData || !keyFileStatus?.isValid}
        >
          <Text color={colors.primary400}>
            <Label htmlFor="selectListGraphicMark">
              {resources.documentViewerSignatureWidget.pleaseSelectYourSignatureImageFile}
            </Label>
          </Text>

          <Box width="100%">
            {certificateData && (
              <GraphicMarkWidget
                onImageGenerated={(dataUri: string) => {
                  setSignatureImageDataURI(dataUri);
                  eventManager.publish({
                    type: "toggle-measurement-mode",
                    value: { toolSelected: true },
                  });
                }}
                certificateData={certificateData}
                onSelectedMarkChanged={() => {
                  setRectangleData(undefined);
                  eventManager.publish({ type: "clear-measurement-annotation" });
                  setSignatureImageDataURI(undefined);
                }}
              />
            )}
          </Box>

          {signatureImageDataURI && (
            <Box
              display="flex"
              direction="column"
              alignContent="center"
              alignItems="center"
              maxWidth={400}
              maxHeight={200}
              overflow="auto"
            >
              <Box padding={2}>
                <img
                  src={signatureImageDataURI}
                  alt="signature"
                  height="100%"
                  width="100%"
                  style={{ objectFit: "contain" }}
                />
              </Box>
            </Box>
          )}

          {signatureImageDataURI && (
            <Box display="flex" flex="grow" direction="column">
              {rectangleData === undefined && (
                <Text color={colors.primary400}>
                  {resources.documentViewerSignatureWidget.createRectangleAnnotation}
                </Text>
              )}
              {rectangleData && (
                <Box display="flex" flex="grow" justifyContent="end" direction="row" gap={2}>
                  <Button
                    text={resources.cancel}
                    color="transparent"
                    onClick={() => {
                      setRectangleData(undefined);
                      eventManager.publish({
                        type: "clear-measurement-annotation",
                      });
                    }}
                    accessibilityLabel={resources.cancel}
                  />
                  <Button
                    disabled={documentIsBeingSigned}
                    text={resources.signThisDocument}
                    onClick={() => {
                      if (!certificateData || !rectangleData || !keyFileDataBase64 || !document) {
                        console.error("Insufficient data for document signature.");
                        return;
                      }

                      setDocumentIsBeingSigned(true);

                      setTimeout(() => {
                        signPKCS12Callback(
                          {
                            appendToLastPage: false,
                            contactInfo: certificateData.contact,
                            height: rectangleData.height,
                            locationInfo: certificateData.location,
                            name: certificateData.name,
                            reason: "",
                            signaturePageIndex: rectangleData.pageIndex,
                            width: rectangleData.width,
                            x: rectangleData.x,
                            y: rectangleData.y,
                          },
                          keyFileDataBase64,
                          document.binaries[0],
                          signatureImageDataURI,
                          document.name
                        );
                      }, 500);
                    }}
                  />
                  <Spinner size="md" show={documentIsBeingSigned} />
                </Box>
              )}
              <BoxWithRef ref={boxElementRef} id="signature-result-list" marginTop={2} />
            </Box>
          )}
        </Box>
      </Box>
      <ViewerErrorModal
        show={showErrorModal}
        onDismiss={() => setShowErrorModal(false)}
        errorMessage={errorMessage}
      />
    </>
  );
}

function OneShotSignatureWidget() {
  return <Box />;
}

type SignBoxSignatureWidgetProps = Record<string, never>;

function SignBoxSignatureWidget(_props: SignBoxSignatureWidgetProps) {
  const { colors } = useColors();

  return (
    <Box
      margin={1}
      padding={2}
      borderStyle="sm"
      borderRadius={4}
      display="flex"
      flex="grow"
      direction="column"
      gap={2}
    >
      <Label htmlFor="txt-signbox-username">
        <Text color={colors.primary400}>Username</Text>
      </Label>
      <TextField
        size="md"
        type="text"
        value="1234"
        id="txt-signbox-username"
        onChange={(e) => {
          console.info(e.value);
        }}
      />
      <Label htmlFor="txt-signbox-password">
        <Text color={colors.primary400}>Password</Text>
      </Label>
      <TextField
        size="md"
        type="password"
        value="1234"
        id="txt-signbox-password"
        onChange={(e) => {
          console.info(e.value);
        }}
      />
      <Label htmlFor="txt-signbox-PIN">
        <Text color={colors.primary400}>PIN</Text>
      </Label>
      <TextField
        size="md"
        type="password"
        value="1234"
        id="txt-signbox-PIN"
        onChange={(e) => {
          console.info(e.value);
        }}
      />
    </Box>
  );
}

type ViewerSignatureListWidgetProps = {
  document: EcmDocument | undefined;
  eventManager: PubSubEventManager<DocumentViewerEvents>;
};

function ViewerSignatureListWidget(props: ViewerSignatureListWidgetProps) {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { eventManager } = props;
  const [signatures, setSignatures] = useState<DocumentViewerDigitalSignatureItem[] | undefined>(
    undefined
  );

  const eventManagerCallback: PubSubEventManagerCallback<DocumentViewerEvents> = useCallback(
    (ev) => {
      switch (ev.type) {
        case "document-signatures-loaded":
          if (ev.value === undefined) {
            /*
              if ev.value is undefined, document viewer has not yet loaded properly. Delay 1s and retry.
              Documents that have no signatures simply return an empty array.
            */
            setTimeout(() => {
              console.info("Delayed signature request.");
              eventManager.publish({ type: "request-loaded-document-signatures" });
            }, 1000);
          } else {
            setSignatures(ev.value.signatures);
          }
          break;
      }
    },
    [eventManager]
  );

  useSubscribe(eventManager, eventManagerCallback);

  useEffect(() => {
    setTimeout(() => {
      console.info("Initial document signature request.");
      eventManager.publish({ type: "request-loaded-document-signatures" });
    }, 1000);
  }, [eventManager]);

  if (!signatures) {
    return (
      <Box rounding={2} color={colors.white} borderStyle="sm" overflow="auto">
        <Spinner show={true} />
      </Box>
    );
  }

  return (
    <Box>
      {signatures.length ? (
        <Box rounding={2} color={colors.white} borderStyle="sm" overflow="auto">
          <Box padding={2}>
            <Text weight="bold">{resources.signaturesSignedBy}</Text>
          </Box>
          {signatures.map((signature, idx) => (
            <Box
              paddingX={4}
              paddingY={2}
              key={`${props.document?.documentVersionId}_${idx}`}
              hoverColor={colors.neutral800}
              color={idx % 2 === 0 ? colors.neutral400 : colors.white}
            >
              <Box display="flex" direction="column" flex="grow">
                <Text>{signature.signer}</Text>
                {signature.signingTime !== undefined && (
                  <FancyDateTime value={signature.signingTime.getTime()} showTime={true} />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box rounding={2} padding={5} color={colors.white} borderStyle="sm">
          <Text align="center"> {resources.signaturesNoSignaturesPresentInDocument} </Text>
        </Box>
      )}
    </Box>
  );
}

type GraphicMarkWidgetProps = {
  certificateData: CertificateData;
  onImageGenerated: (dataUri: string) => void;
  onSelectedMarkChanged: () => void;
};

function GraphicMarkWidget({
  onImageGenerated,
  onSelectedMarkChanged,
  certificateData,
}: GraphicMarkWidgetProps) {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const [selectedMarkId, setSelectedMarkId] = useState<string | undefined>(undefined);
  const [renderingIsDone, setRenderingIsDone] = useState<boolean>(true);
  const { paginatedData } = usePaginatedDataEndpoint<
    { graphicMarkList: { id: string; name: string; customMessage: string }[] },
    "15" | "30" | "50",
    string | undefined
  >(
    "15",
    "",
    (services, currentPage, rowsPerPage, filter) => {
      return services.getOrganizationGraphicMarks(rowsPerPage, currentPage, filter);
    },
    "signature_widget_graphic_mark_list"
  );

  const renderCallback = useCallback(async () => {
    const element = document.getElementById("graphic-mark-component-id") as HTMLDivElement;
    if (element) {
      try {
        element.hidden = false;
        const png = await toPng(element);
        onImageGenerated(png);
        element.hidden = true;
        setRenderingIsDone(true);
      } catch (e) {
        console.error(`Error generating graphic mark image from DOM element: ${e}.`);
        console.error(e);
        throw e;
      }
    } else {
      console.error("Error generating graphic mark image from DOM element: component not found.");
    }
  }, [onImageGenerated]);

  if (paginatedData) {
    if (paginatedData.graphicMarkList.length === 0) {
      return (
        <Box>
          <Text color={colors.error} align="center">
            {resources.documentViewerSignatureWidget.noValidSignatureMarks}
          </Text>
        </Box>
      );
    } else {
      return (
        <Box display="flex" gap={2} direction="column">
          <SelectList
            id="selectListGraphicMark"
            onChange={(e) => {
              onSelectedMarkChanged();
              setSelectedMarkId(e.value);
              setRenderingIsDone(false);
            }}
            options={paginatedData.graphicMarkList.map((mark) => {
              return { label: mark.name, value: mark.id };
            })}
            disabled={paginatedData.graphicMarkList.length === 0}
            value={selectedMarkId}
          />

          {selectedMarkId && (
            <Box overflow="auto">
              <Spinner show={!renderingIsDone} />
              <GraphicMarkRenderWidget
                certificateData={certificateData}
                graphicMarkId={selectedMarkId}
                componentId="graphic-mark-component-id"
                onElementRendered={renderCallback}
              />
            </Box>
          )}
        </Box>
      );
    }
  } else {
    return (
      <Box>
        <Spinner show={true} />
      </Box>
    );
  }
}
