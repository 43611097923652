import { Box, Button, Skeleton, Text, useColors } from "@prodoctivity/design-system";
import type {
  DocumentCollectionTask,
  TemplateContextStateHeader,
  WithStringId,
} from "@prodoctivity/types";
import { useCombinedTemplateInfo, useTemplateInfo } from "../../hooks";

import { FunctionComponent } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../link-templates";
import { GenerationTaskSvg } from "../../svg/Tasks/GenerationTaskSvg";
import { ReviewTaskSvg } from "../../svg/Tasks/ReviewTaskSvg";
import { RoundIndicator } from "../../svg/Tasks/RoundIndicator";
import { SignTaskSvg } from "../../svg/Tasks/SignTaskSvg";
import { PublicProfile } from "../Profile/Public/PublicProfile";

type TaskListProps = {
  tasks: WithStringId<TemplateContextStateHeader<number> | DocumentCollectionTask<number>>[];
};
export const TaskList: FunctionComponent<TaskListProps> = ({ tasks }) => {
  const organizationNavigate = useOrganizationNavigate();
  return (
    <Box display="flex" direction="column">
      {(tasks ?? []).map((task) => {
        return (
          <TaskEntry
            key={task.id}
            task={task}
            onClick={() => {
              organizationNavigate(organizationLinkTemplates.taskDetail(task.id));
            }}
          />
        );
      })}
    </Box>
  );
};

type TaskEntryProps = {
  task: TemplateContextStateHeader<number> | DocumentCollectionTask<number>;
  onClick: () => void;
};

const TaskEntry: FunctionComponent<TaskEntryProps> = ({ task, onClick }) => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();
  return (
    <Box
      paddingX={4}
      paddingY={4}
      display="flex"
      flex="grow"
      alignItems="center"
      justifyContent="between"
      wrap={true}
      minHeight={100}
    >
      <Box onClickCapture={onClick} display="flex" direction="column" width={"100%"}>
        {/* Icon and Label Start */}
        {task.taskType === "template-context-state" && task.contextType === "template" && (
          <TemplateEntry task={task} />
        )}
        {task.taskType === "template-context-state" && task.contextType === "combined-template" && (
          <CombinedTemplateEntry task={task} />
        )}
        {task.taskType === "document-collection-task" && (
          <TaskItemEntry
            title={task.instanceName}
            subTitle={task.configName}
            iconType="document-qa"
            username={task.username}
          />
        )}
        {/* Icon and Label End */}

        <Box
          onClickCapture={onClick}
          display="flex"
          alignItems="center"
          justifyContent="end"
          marginTop={1}
          gap={2}
        >
          <Box display="flex" alignItems="center" gap={2}>
            {task.expirationDate > Date.now() ? (
              <RoundIndicator color="green" />
            ) : (
              <RoundIndicator color="red" />
            )}
            <Text size="100" weight="normal" color={colors.subtle}>
              {task.expirationDate > Date.now() ? resources.expires : resources.expired}{" "}
              {moment(task.expirationDate).fromNow()}
            </Text>
          </Box>
          {/* Open Button Start */}
          <Box>
            <Button
              onClick={onClick}
              text={resources.open}
              iconEnd="directional-arrow-right"
              color={"gray"}
              size="sm"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const TemplateEntry: FunctionComponent<{ task: TemplateContextStateHeader<number> }> = ({
  task,
}) => {
  const { data: template, isLoading } = useTemplateInfo(
    task.contextType === "template" ? task.templateVersionId : ""
  );

  return (
    <Skeleton show={isLoading}>
      <TaskItemEntry
        title={template?.info?.informationDefinition.name || ""}
        iconType="document-creation"
        username={task.username}
      />
    </Skeleton>
  );
};

const CombinedTemplateEntry: FunctionComponent<{ task: TemplateContextStateHeader<number> }> = ({
  task,
}) => {
  const { data: combinedTemplateResponse, isLoading } = useCombinedTemplateInfo(
    task.contextType === "combined-template" ? task.combinedTemplateId : ""
  );
  return (
    <Skeleton show={isLoading}>
      <TaskItemEntry
        title={combinedTemplateResponse?.combinedTemplate?.name || ""}
        iconType="document-creation"
        username={task.username}
      />
    </Skeleton>
  );
};

const TaskItemEntry: FunctionComponent<{
  title: string;
  subTitle?: string;
  username?: string;
  iconType: string;
}> = ({ title, subTitle, username, iconType }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  // Icon and Label Start
  return (
    <Box display="flex" gap={3} direction="row" alignItems="center">
      <Box>{getIconByType(iconType)}</Box>
      <Box>
        <Box>
          <Text size="200" weight="normal">
            {title}
          </Text>
        </Box>
        {subTitle && (
          <Box>
            <Text size="100" weight="normal" color={colors.subtle}>
              {subTitle}
            </Text>
          </Box>
        )}
        {username ? (
          <Box display="inlineBlock">
            <Text size="100" weight="normal" color={colors.subtle}>
              {resources.assignedTo}:{" "}
              <Box display="inlineBlock">
                <PublicProfile size="100" username={username} />
              </Box>
            </Text>
          </Box>
        ) : (
          <Box>
            <Text size="100" weight="normal" color={colors.subtle}>
              {resources.unassigned}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
  // Icon and Label End
};

export const getIconByType = (taskType: string) => {
  switch (taskType) {
    case "document-creation":
      return <GenerationTaskSvg />;
    case "document-qa":
      return <ReviewTaskSvg />;
    case "document-approval":
      return <SignTaskSvg />;
    default:
      return <GenerationTaskSvg />;
  }
};
