import {
  Box,
  ButtonLink,
  ColorConfig,
  Divider,
  Tabs,
  TabsProps,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, ReactNode, useMemo } from "react";

import { CircleInfoSvgIcon } from "../svg/CircleInfoSvgIcon";

type StepTabsTab = {
  title: string;
  description?: string | ReactNode;
  content: ReactNode;
};
type StepTabsProps = {
  tabs: StepTabsTab[];
  selectedTabIndex: number;
  color?: ColorConfig;
  onTabClick?: (index: number) => void;
  extraComponent?: JSX.Element;
};
export const StepTabs: FunctionComponent<StepTabsProps> = ({
  color,
  tabs,
  selectedTabIndex,
  onTabClick,
  extraComponent,
}) => {
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const breakPointSmallDuo = breakpoint === "medium" || breakpoint === "small";

  const tabSettings: TabsProps["tabs"] = useMemo(() => {
    return tabs.map((tab) => {
      const t: TabsProps["tabs"][number] = {
        href: "",
        text: tab.title,
        id: tab.title,
      };

      return t;
    });
  }, [tabs]);

  return (
    <Box display="flex" direction="column" flex="grow">
      <Box width={"100%"} display="flex" direction="column">
        {breakpoint === "small" || breakpoint === "medium" ? (
          <>
            <Box borderStyle={"lg"} width={"100%"}>
              <Box height={50} paddingX={6} paddingY={2} display="flex" alignItems="center">
                <ButtonLink
                  href=""
                  color="blue"
                  onClick={(args) => {
                    args.event.preventDefault();
                    if (onTabClick) onTabClick(selectedTabIndex);
                  }}
                  text={tabs[selectedTabIndex].title}
                />
              </Box>
            </Box>
            {extraComponent ? extraComponent : null}
          </>
        ) : (
          <>
            <Box display="flex" direction="row" flex="grow" paddingX={2}>
              <Tabs
                activeTabIndex={selectedTabIndex}
                onChange={(args) => {
                  args.event.preventDefault();
                  //if (onTabClick) { onTabClick(args.activeTabIndex);}
                }}
                tabs={tabSettings}
              />
              {extraComponent ? extraComponent : null}
            </Box>

            <Divider direction="horizontal" />
          </>
        )}
      </Box>
      {typeof tabs[selectedTabIndex].description === "string" && (
        <Box alignItems="center" paddingX={4} display="flex">
          {breakPointSmallDuo ? undefined : (
            <CircleInfoSvgIcon type="exclamation-inverted" color={colors.black900} />
          )}
          <Box>
            <Text color={colors.subtle} size="200">
              {tabs[selectedTabIndex].description}
            </Text>
          </Box>
        </Box>
      )}

      <Box color={color} display="flex" direction="column" width={"100%"} flex="grow">
        {tabs[selectedTabIndex].content}
      </Box>
    </Box>
  );
};
