"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputType$Schema = exports.contextFieldToDataElement = exports.contextFieldToDataElementSync = exports.getCultureLanguageName = exports.visitTemplateWizardField = exports.getSectionName = exports.getListTypes = exports.dictionaryDataTypes = exports.SignPosition = exports.MinusSign = exports.DigitGroupSeparator = exports.DataElementFinalizer = exports.DataElementOrderType = exports.DataElementPenultimateSeparator = exports.DataElementListSeparator = void 0;
const utils_1 = require("./utils");
const zod_1 = require("zod");
const fluency_shared_1 = require("./fluency-shared");
var DataElementListSeparator;
(function (DataElementListSeparator) {
    DataElementListSeparator[DataElementListSeparator["None"] = 0] = "None";
    DataElementListSeparator[DataElementListSeparator["Comma"] = 1] = "Comma";
    DataElementListSeparator[DataElementListSeparator["Semicolon"] = 2] = "Semicolon";
    DataElementListSeparator[DataElementListSeparator["Whitespace"] = 3] = "Whitespace";
    DataElementListSeparator[DataElementListSeparator["And"] = 4] = "And";
    DataElementListSeparator[DataElementListSeparator["Or"] = 5] = "Or";
    DataElementListSeparator[DataElementListSeparator["Paragraph"] = 6] = "Paragraph";
})(DataElementListSeparator || (exports.DataElementListSeparator = DataElementListSeparator = {}));
var DataElementPenultimateSeparator;
(function (DataElementPenultimateSeparator) {
    DataElementPenultimateSeparator[DataElementPenultimateSeparator["None"] = 0] = "None";
    DataElementPenultimateSeparator[DataElementPenultimateSeparator["Comma"] = 1] = "Comma";
    DataElementPenultimateSeparator[DataElementPenultimateSeparator["Semicolon"] = 2] = "Semicolon";
    DataElementPenultimateSeparator[DataElementPenultimateSeparator["Whitespace"] = 3] = "Whitespace";
    DataElementPenultimateSeparator[DataElementPenultimateSeparator["And"] = 4] = "And";
    DataElementPenultimateSeparator[DataElementPenultimateSeparator["Or"] = 5] = "Or";
})(DataElementPenultimateSeparator || (exports.DataElementPenultimateSeparator = DataElementPenultimateSeparator = {}));
var DataElementOrderType;
(function (DataElementOrderType) {
    DataElementOrderType[DataElementOrderType["None"] = 0] = "None";
    DataElementOrderType[DataElementOrderType["Ascending"] = 1] = "Ascending";
    DataElementOrderType[DataElementOrderType["Descending"] = 2] = "Descending";
})(DataElementOrderType || (exports.DataElementOrderType = DataElementOrderType = {}));
var DataElementFinalizer;
(function (DataElementFinalizer) {
    DataElementFinalizer[DataElementFinalizer["None"] = 0] = "None";
    DataElementFinalizer[DataElementFinalizer["Semicolon"] = 1] = "Semicolon";
    DataElementFinalizer[DataElementFinalizer["Whitespace"] = 2] = "Whitespace";
    DataElementFinalizer[DataElementFinalizer["Period"] = 3] = "Period";
})(DataElementFinalizer || (exports.DataElementFinalizer = DataElementFinalizer = {}));
var DigitGroupSeparator;
(function (DigitGroupSeparator) {
    DigitGroupSeparator["None"] = "None";
    DigitGroupSeparator["Comma"] = "Comma";
    DigitGroupSeparator["Period"] = "Period";
})(DigitGroupSeparator || (exports.DigitGroupSeparator = DigitGroupSeparator = {}));
var MinusSign;
(function (MinusSign) {
    MinusSign["Minus"] = "Minus";
    MinusSign["Parentheses"] = "Parentheses";
})(MinusSign || (exports.MinusSign = MinusSign = {}));
var SignPosition;
(function (SignPosition) {
    SignPosition[SignPosition["Left"] = 0] = "Left";
    SignPosition[SignPosition["Right"] = 1] = "Right";
})(SignPosition || (exports.SignPosition = SignPosition = {}));
exports.dictionaryDataTypes = [
    "None",
    "Alphanumeric",
    "IntegerNumber",
    "DecimalNumber",
    "DateTime",
    "Date",
    "Time",
    "Boolean",
    "Image",
    "ElectronicSignature",
    "List",
];
function getListTypes() {
    return ["None", "Gender", "CurrencyType"];
}
exports.getListTypes = getListTypes;
function getSectionName(wizardDefinition, fullPath) {
    for (const page of wizardDefinition.pages) {
        for (const section of page.sections) {
            if (visitTemplateWizardField(section.fields, (fld) => fld.key === fullPath)) {
                return section.label;
            }
        }
    }
    return "General";
}
exports.getSectionName = getSectionName;
function visitTemplateWizardField(fields, pred) {
    for (const field of fields) {
        if (pred(field)) {
            return true;
        }
        if (field.isRecord) {
            if (visitTemplateWizardField(field.fields || [], pred)) {
                return true;
            }
        }
    }
    return false;
}
exports.visitTemplateWizardField = visitTemplateWizardField;
function getCultureLanguageName(language) {
    switch (language) {
        case "English":
            return "en";
        case "Spanish":
            return "es";
        case "Portuguese":
            return "pt";
        default:
            return "en";
    }
}
exports.getCultureLanguageName = getCultureLanguageName;
function contextFieldToDataElementSync(cultureLanguageName, sectionName, contextField) {
    const { properties } = contextField;
    const dictionaryDataType = (0, utils_1.toDictionaryDataType)(properties.dataType, undefined, (0, fluency_shared_1.isValueListContextField)(properties) ? properties.dictionaryListId : undefined, (0, fluency_shared_1.isValueListContextField)(properties) ? properties.valueList : undefined);
    const result = Object.assign(Object.assign({ name: contextField.name, unique: contextField.properties.isUnique || false, cultureLanguageName,
        sectionName, isReferenceField: false, readOnly: false, dictionaryListName: undefined, alternativeQuestions: [], input: {
            controlLayout: "Horizontal",
            controlSize: "Normal",
        }, output: {
            outputFormat: "",
            conditionalStyles: [],
            dataElementFinalizer: undefined,
            dataElementListSeparator: undefined,
            dataElementOrderType: undefined,
            dataElementPenultimateSeparator: undefined,
        } }, properties), { dictionaryDataType });
    return result;
}
exports.contextFieldToDataElementSync = contextFieldToDataElementSync;
function contextFieldToDataElement(cultureLanguageName, sectionName, getDictionaryList, contextField) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = contextFieldToDataElementSync(cultureLanguageName, sectionName, contextField);
        switch (result.dataType) {
            case "Alphanumeric": {
                switch (result.inputType) {
                    case "TextBox":
                    case "Default":
                    case "TextArea": {
                        break;
                    }
                    case "Checkbox":
                    case "Radio":
                    case "Dropdown":
                    case "CheckBoxList":
                    case "DropDownList":
                    case "RadioButtonList": {
                        if (result.dictionaryListId) {
                            const dl = yield getDictionaryList(result.dictionaryListId);
                            if (dl) {
                                result.dictionaryListName = dl.listSchema.name;
                            }
                        }
                        break;
                    }
                    default:
                        (0, utils_1.shouldNever)(result);
                        throw new Error("Cannot Happen");
                }
                break;
            }
            case "Numeric":
            case "Date":
            case "Logical":
            case "Currency":
            case "Time":
            case "DateTime":
            case "Image": {
                break;
            }
            default:
                (0, utils_1.shouldNever)(result);
                throw new Error("Cannot Happen");
        }
        return result;
    });
}
exports.contextFieldToDataElement = contextFieldToDataElement;
exports.InputType$Schema = zod_1.z.enum([
    "TextBox",
    "TextArea",
    "Checkbox",
    "Radio",
    "Dropdown",
    "CheckBoxList",
    "DropDownList",
    "RadioButtonList",
    "Switch",
    "TimePicker",
    "DateTimePicker",
    "Signature",
    "ImageUpload",
]);
