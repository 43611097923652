import type {
  DataType,
  StringTemplateParameterSource,
  StringTemplateParameterValue,
  StringTemplatePart,
  VariablePart,
} from "@prodoctivity/shared/src/index-types";
import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";
import { Button, ButtonWithRef } from "../Button/Button";
import { Dropdown, DropdownItem } from "../Dropdown";

import { shouldNever } from "@prodoctivity/shared";
import { ComboBoxItemType } from "gestalt";
import momentType from "moment";
import { Box } from "../Box";
import { Checkbox } from "../Checkbox";
import { useColors } from "../ColorSchemeProvider";
import { ComboBox } from "../ComboBox";
import { Heading } from "../Heading";
import { Label } from "../Label";
import { OverlayPanel } from "../OverlayPanel";
import { Text } from "../Text";
import { TextField } from "../TextField";
import { StringTemplateBuilderEventManager } from "./StringTemplateBuilderEventManager";

type VariablePartSheetProps = {
  currentPart?: VariablePartSheetCurrentPart;
  eventManager: StringTemplateBuilderEventManager;
  variableList: StringTemplateParameterValue[];
  showVariableDetails?: boolean;
  allowAddNew?: boolean;
  currentVariable: StringTemplateParameterValue | undefined;
  rawSetCurrentVariableValue: (value: StringTemplateParameterValue | undefined) => void;
  resources: {
    cancel: string;
    clear: string;
    context: string;
    convertValues: string;
    createNewVariable: string;
    currency: string;
    currencyFormat: string;
    dateFormat: string;
    global: string;
    instance: string;
    newVariable: string;
    newVariableDescription: string;
    noResultsFound: string;
    originalFormat: string;
    save: string;
    saveVariable: string;
    selectSource: string;
    source: string;
    text: string;
    typeToChooseVariable: string;
    variableDetails: string;
    variableList: string;
    variableName: string;
    variables: string;
  };
  onDismiss: () => void;
  getFieldLabel(fldName: string): string;
  getFieldDataType(source: StringTemplateParameterValue["source"], name: string): DataType;
  moment: typeof momentType;
};

export type VariablePartSheetCurrentPart = {
  parameterKey: string;
  lineIndex: number;
  partIndex: number;
  part: StringTemplatePart;
};

function isValidSource(src: string): src is StringTemplateParameterSource {
  return ["fixed", "context", "global-parameter", "instance-parameter", "env"].includes(src);
}

export const VariablePartSheet: FunctionComponent<VariablePartSheetProps> = ({
  onDismiss,
  getFieldLabel,
  getFieldDataType,
  resources,
  moment,
  variableList,
  currentPart,
  currentVariable,
  rawSetCurrentVariableValue,
  showVariableDetails = true,
  allowAddNew = true,
  eventManager,
}) => {
  const { colors } = useColors();
  const options = useMemo<Array<{ label: string; value: string; subtext: string }>>(() => {
    return variableList.map((variable) => {
      return {
        label: variable.value,
        value: variable.value,
        subtext: variable.source,
      };
    });
  }, [variableList]);

  const comboBoxSelectedValue = useMemo(
    () =>
      currentPart?.part.type === "variable"
        ? { label: currentPart.part.name, value: currentPart.part.name }
        : { label: "", value: "" },
    [currentPart]
  );

  const part: StringTemplatePart | undefined = useMemo(() => {
    if (currentVariable) {
      const dataType = getFieldDataType(currentVariable.source, currentVariable.value);
      const tempFilter =
        currentPart?.part.type === "variable" ? currentPart.part.filter : undefined;
      const tempPart: VariablePart = {
        type: "variable",
        dataType: dataType,
        name: currentVariable.value,
      };

      if (
        (tempPart.dataType === "Alphanumeric" && tempFilter?.type === "map-value") ||
        (tempPart.dataType === "Currency" && tempFilter?.type === "currency-format") ||
        (tempPart.dataType === "Date" && tempFilter?.type === "date-format") ||
        (tempPart.dataType === "DateTime" && tempFilter?.type === "date-format")
      ) {
        tempPart.filter = tempFilter;
      }

      return tempPart;
    }
    return currentPart?.part;
  }, [currentVariable, getFieldDataType, currentPart?.part]);

  const setCurrentVariable = useCallback(
    (current: StringTemplateParameterValue | undefined) => {
      rawSetCurrentVariableValue(current);
      if (current && currentPart && currentPart.part) {
        const part = currentPart.part;
        if (part.type !== "variable") {
          return;
        }
        const dataType = getFieldDataType(current.source, current.value);
        if (part.dataType !== dataType) {
          eventManager.publish({
            type: "edit-variable-part",
            lineIndex: currentPart.lineIndex,
            partIndex: currentPart.partIndex,
            part: {
              type: "variable",
              dataType,
              name: current.value,
              filter: undefined,
            },
          });
        } else {
          eventManager.publish({
            type: "edit-variable-part",
            lineIndex: currentPart.lineIndex,
            partIndex: currentPart.partIndex,
            part: {
              ...part,
              name: current.value,
            },
          });
        }
      }
    },
    [currentPart, eventManager, getFieldDataType, rawSetCurrentVariableValue]
  );
  const [typeAheadOptions, setTypeAheadOptions] = useState(options);
  const [selected, setSelected] = useState<ComboBoxItemType | undefined>(
    part
      ? {
          label: part?.type === "text" ? part.value : getFieldLabel(part?.name),
          value: part?.type === "text" ? part.value : part?.name,
          subtext: part?.type === "text" ? resources.text : part?.name,
        }
      : undefined
  );

  const [inputValue, setInputValue] = useState<string>(selected?.label || "");

  const [sourceDropDownOpen, setSourceDropDownOpen] = React.useState(false);

  const anchorRef = React.useRef<HTMLButtonElement | null>(null);
  const onSourceSelect: (args: {
    event: React.SyntheticEvent<HTMLInputElement, Event> | React.KeyboardEvent<HTMLInputElement>;
    item: ComboBoxItemType;
  }) => void = useCallback(
    ({ item }) => {
      if (isValidSource(item.value)) {
        if (currentVariable) {
          setCurrentVariable({
            ...currentVariable,
            source: item.value,
          });
        }
        setSourceDropDownOpen(false);
        setCurrentVariable(undefined);
      }
    },
    [currentVariable, setCurrentVariable]
  );

  const handleOnChange: (args: {
    event: React.SyntheticEvent<HTMLInputElement, Event>;
    value: string;
  }) => void = ({ value }) => {
    if (value) {
      const filteredOptions = options.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      );
      if (allowAddNew) {
        filteredOptions.push({
          value: "_new",
          label: resources.newVariable,
          subtext: resources.newVariableDescription,
        });
      }
      setTypeAheadOptions(filteredOptions);
    } else {
      const modOptions = [...options];

      if (allowAddNew) {
        modOptions.push({
          value: "_new",
          label: resources.newVariable,
          subtext: resources.newVariableDescription,
        });
      }
      setSelected(undefined);

      setTypeAheadOptions(modOptions);
    }
    setInputValue(value);
  };

  const handleSelect = (item: ComboBoxItemType) => {
    if (item.value === "_new") {
      setTypeAheadOptions(options);
      setSelected(undefined);
      const newVar: StringTemplateParameterValue = { value: inputValue, source: "context" };
      setCurrentVariable(newVar);
      setInputValue(newVar.value);
    } else {
      setTypeAheadOptions(options);
      setSelected(item);
      const currentVariable = variableList.find((v) => v.value === item.value);
      setCurrentVariable(currentVariable);
      setInputValue(item.label);
    }
  };

  const getSourceLabel = (source: string) => {
    switch (source) {
      case "context":
        return resources.context;
      case "global-parameter":
        return resources.global;
      case "instance-parameter":
        return resources.instance;
      default:
        return resources.context;
    }
  };

  const onVariablePartChange = useCallback(
    (part: VariablePart) => {
      if (currentPart) {
        eventManager.publish({
          type: "edit-variable-part",
          lineIndex: currentPart.lineIndex,
          partIndex: currentPart.partIndex,
          part,
        });
      }
    },
    [currentPart, eventManager]
  );

  return (
    <OverlayPanel
      onDismiss={() => {
        onDismiss();
        setCurrentVariable(undefined);
      }}
      size="lg"
      accessibilityLabel=""
    >
      <Box color={colors.white} display="flex" direction="column" height={"100%"} padding={4}>
        <Box display="flex">
          <Box display="flex" flex="grow">
            <Heading size="400" color={colors.black600}>
              {resources.variableList}
            </Heading>
          </Box>
          <Box display="flex" flex="shrink">
            {/* <TapArea onTap={onDismiss}>
              <IconButton icon="x" accessibilityLabel={resources.cancel} />
            </TapArea> */}
          </Box>
        </Box>
        <Box display="flex" direction="column" padding={2}>
          <Box marginBottom={2}>
            <Text size="200" color={colors.subtle} weight="bold">
              {resources.typeToChooseVariable}
            </Text>
          </Box>
          <ComboBox
            accessibilityClearButtonLabel={resources.clear}
            label={selected?.label ?? resources.variables}
            labelDisplay="hidden"
            id="variable-typeahead"
            noResultText={allowAddNew ? resources.createNewVariable : resources.noResultsFound}
            options={[...typeAheadOptions]}
            onBlur={() => {
              setTypeAheadOptions(typeAheadOptions);
            }}
            onClear={() => {
              setSelected(undefined);
              setTypeAheadOptions(typeAheadOptions);
              setInputValue("");
            }}
            selectedOption={selected}
            placeholder={selected?.value || resources.variableName}
            onChange={handleOnChange}
            onSelect={({ item }) => {
              handleSelect(item);
              setInputValue(item.value);
            }}
            inputValue={inputValue}
            size="lg"
          />
          {currentPart &&
            currentPart.part &&
            currentPart.part.type === "variable" &&
            part?.type === "variable" && (
              <VariablePartFilterConfig
                part={part}
                resources={resources}
                onChange={onVariablePartChange}
                moment={moment}
              />
            )}
        </Box>
        {showVariableDetails && currentVariable && (
          <Box display="flex" direction="column" marginTop={8} padding={2}>
            <Box paddingY={4}>
              <Text size="300" color={colors.primary} weight="bold">
                {resources.variableDetails}
              </Text>
            </Box>
            <Box borderStyle="sm" padding={2}>
              <Box paddingY={4}>
                <Label htmlFor="variableNameField">
                  <Text size="200" color={colors.subtle} weight="bold">
                    {resources.variableName}
                  </Text>
                </Label>
              </Box>
              <TextField
                id="variableNameField"
                onChange={({ value }) => {
                  if (currentVariable && isValidSource(currentVariable.source)) {
                    setCurrentVariable({
                      value: value,
                      source: currentVariable.source,
                    });
                  }
                }}
                value={currentVariable.value ?? ""}
              />
            </Box>
            <Box borderStyle="sm" padding={2} marginTop={2}>
              <Box display="flex" direction="column">
                <Box paddingY={4}>
                  <Label htmlFor="variableSourceField">
                    <Text size="200" color={colors.subtle} weight="bold">
                      {resources.source}
                    </Text>
                  </Label>
                </Box>
                <Box display="flex" direction="row" alignItems="center">
                  <Box paddingX={4}>
                    <Text size="300">{resources.selectSource} </Text>
                  </Box>
                  <Box>
                    <ButtonWithRef
                      accessibilityLabel={resources.source}
                      accessibilityControls="source-dropdown-button"
                      accessibilityExpanded={sourceDropDownOpen}
                      accessibilityHaspopup
                      iconEnd="arrow-down"
                      onClick={() => setSourceDropDownOpen((prevVal) => !prevVal)}
                      ref={anchorRef}
                      selected={sourceDropDownOpen}
                      size="lg"
                      text={getSourceLabel(currentVariable.source)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {sourceDropDownOpen && (
              <Dropdown
                anchor={anchorRef.current ?? undefined}
                id="variableSourceField"
                onDismiss={() => {
                  setSourceDropDownOpen(false);
                }}
              >
                {["context", "global-parameter", "instance-parameter"].map((source) => {
                  return (
                    <DropdownItem
                      key={`key-${source}`}
                      onSelect={onSourceSelect}
                      option={{
                        label: getSourceLabel(source),
                        value: source,
                        // subtext: `Distribution value comes from ${source}`,
                      }}
                    />
                  );
                })}
              </Dropdown>
            )}
          </Box>
        )}
        <Box display="flex" alignItems="center" justifyContent="between" gap={4}>
          <Button
            accessibilityLabel={resources.saveVariable}
            text={resources.save}
            color="blue"
            onClick={() => {
              onDismiss();
            }}
          />
          <Button
            accessibilityLabel={resources.cancel}
            text={resources.cancel}
            color="gray"
            onClick={() => {
              handleSelect(comboBoxSelectedValue);
              onDismiss();
            }}
          />
        </Box>
      </Box>
    </OverlayPanel>
  );
};

const dateFormatOptions = [
  "",
  "MMM",
  "YYYY",
  "MMM, YYYY",
  "YYYY-MM-DD",
  "DD-MM-YYYY",
  "DD/MM/YYYY",
  "MM/DD/YYYY",
  "dddd, MMMM Do YYYY",
  "MMMM Do YYYY",
  "MMM D, YYYY",
  "ddd, hA",
  "MMMM YYYY",
  "YYYY/MM/DD",
];

const dateTimeFormatOptions = [
  ...dateFormatOptions,
  "YYYY-MM-DD HH:mm:ss",
  "DD/MM/YYYY HH:mm:ss",
  "MM/DD/YYYY HH:mm:ss",
  "HH:mm:ss",
  "hh:mm:ss a",
  "HH:mm",
  "hh:mm a",
  "dddd, MMMM Do YYYY, h:mm:ss a",
  "DD-MM-YYYY HH:mm:ss",
  "YYYY/MM/DD HH:mm:ss",
];

type VariablePartFilterConfigProps = {
  part: VariablePart;
  resources: {
    convertValues: string;
    currency: string;
    currencyFormat: string;
    originalFormat: string;
    dateFormat: string;
    source: string;
  };
  onChange(part: VariablePart): void;
  moment: typeof momentType;
};

const VariablePartFilterConfig: FunctionComponent<VariablePartFilterConfigProps> = ({
  part,
  resources,
  onChange,
  moment,
}) => {
  const [sourceDropDownOpen, setSourceDropDownOpen] = useState(false);
  const [currentFormat, setCurrentFormat] = useState<string | undefined>(
    part.filter && part.filter.type === "date-format" ? part.filter.value : undefined
  );

  const setCurrentFormatValue = useCallback(
    (value: string | undefined) => {
      setCurrentFormat(value);
    },
    [setCurrentFormat]
  );
  const setSourceDropDownOpenValue = useCallback(
    (value: boolean) => {
      setSourceDropDownOpen(value);
    },
    [setSourceDropDownOpen]
  );
  switch (part.dataType) {
    case "Alphanumeric":
      return (
        <VariablePartAlphanumericFilter part={part} resources={resources} onChange={onChange} />
      );
    case "Currency":
      return <VariablePartCurrencyFilter part={part} resources={resources} onChange={onChange} />;
    case "Date":
      return (
        <VariablePartDateFormatFilter
          formatOptions={dateFormatOptions}
          part={part}
          resources={resources}
          onChange={onChange}
          moment={moment}
          sourceDropDownOpen={sourceDropDownOpen}
          setSourceDropDownOpenValue={setSourceDropDownOpenValue}
          currentFormat={currentFormat}
          setCurrentFormatValue={setCurrentFormatValue}
        />
      );
    case "DateTime":
      return (
        <VariablePartDateFormatFilter
          formatOptions={dateTimeFormatOptions}
          part={part}
          resources={resources}
          onChange={onChange}
          moment={moment}
          setSourceDropDownOpenValue={setSourceDropDownOpenValue}
          sourceDropDownOpen={sourceDropDownOpen}
          currentFormat={currentFormat}
          setCurrentFormatValue={setCurrentFormatValue}
        />
      );
    case "Image":
      return null;
    case "Logical":
      return null;
    case "Numeric":
      return null;
    case "Time":
      return null;
    default:
      shouldNever(part);
      return null;
  }
};

type VariablePartDateFormatFilterProps = {
  formatOptions: string[];
  part: VariablePart;
  resources: { originalFormat: string; dateFormat: string; source: string };
  onChange(part: VariablePart): void;
  moment: typeof momentType;
  sourceDropDownOpen: boolean;
  setSourceDropDownOpenValue: (value: boolean) => void;
  currentFormat: string | undefined;
  setCurrentFormatValue: (value: string | undefined) => void;
};

const VariablePartDateFormatFilter: FunctionComponent<VariablePartDateFormatFilterProps> = ({
  formatOptions,
  part,
  resources,
  onChange,
  moment,
  sourceDropDownOpen,
  setSourceDropDownOpenValue,
  currentFormat,
  setCurrentFormatValue,
}) => {
  const { colors } = useColors();
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const getFormatLabel = useCallback(
    (source: string | undefined) => {
      return source === "" || source === undefined ? resources.originalFormat : source;
    },
    [resources.originalFormat]
  );
  const onSourceSelect: (args: {
    event: React.SyntheticEvent<HTMLInputElement, Event> | React.KeyboardEvent<HTMLInputElement>;
    item: ComboBoxItemType;
  }) => void = useCallback(
    ({ item }) => {
      const newFormat = item.value ? item.value : undefined;
      setCurrentFormatValue(newFormat);
      if (part.dataType === "Date" || part.dataType === "DateTime") {
        if (newFormat !== undefined) {
          onChange({
            ...part,
            filter: {
              type: "date-format",
              value: newFormat,
            },
          });
        } else {
          onChange({ ...part, filter: undefined });
        }
      }
    },
    [onChange, part, setCurrentFormatValue]
  );
  return (
    <Box display="flex" direction="column">
      <Box margin={2} />
      <Box>
        <Box display="flex" direction="column">
          <Box marginBottom={2}>
            <Text size="200" color={colors.subtle} weight="bold">
              {resources.dateFormat}
            </Text>
          </Box>
          <Box>
            <ButtonWithRef
              // fullWidth={true}
              accessibilityLabel={resources.source}
              accessibilityControls="source-dropdown-button"
              accessibilityExpanded={sourceDropDownOpen}
              accessibilityHaspopup
              iconEnd="arrow-down"
              onClick={() => setSourceDropDownOpenValue(!sourceDropDownOpen)}
              ref={anchorRef}
              selected={sourceDropDownOpen}
              size="md"
              text={getFormatLabel(currentFormat)}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        {sourceDropDownOpen && (
          <Dropdown
            anchor={anchorRef.current ?? undefined}
            id="variableSourceField"
            onDismiss={() => {
              setSourceDropDownOpenValue(false);
            }}
          >
            {formatOptions.map((source) => {
              return (
                <DropdownItem
                  key={`key-${source}`}
                  onSelect={(item) => {
                    onSourceSelect(item);
                    setSourceDropDownOpenValue(false);
                  }}
                  option={{
                    label: getFormatLabel(source),
                    value: source,
                    subtext: moment().format(source),
                  }}
                />
              );
            })}
          </Dropdown>
        )}
      </Box>
    </Box>
  );
};

/**Variable Part Currency**/

type VariablePartCurrencyFilterProps = {
  part: VariablePart;
  resources: { currency: string; currencyFormat: string };
  onChange(part: VariablePart): void;
};

const VariablePartCurrencyFilter: FunctionComponent<VariablePartCurrencyFilterProps> = ({
  part,
  resources,
  onChange,
}) => {
  const { colors } = useColors();
  const [check, setCheck] = useState(!(part.filter && part.filter.type === "currency-format"));
  const onSourceSelect = useCallback(() => {
    setCheck((prevCheck) => {
      const newCheck = !prevCheck;

      if (
        part.dataType === "Currency" &&
        (part.filter === undefined || part.filter.type === "currency-format")
      ) {
        if (newCheck) {
          onChange({ ...part, filter: undefined });
        } else {
          onChange({
            ...part,
            filter: {
              type: "currency-format",
            },
          });
        }
      }

      return newCheck;
    });
  }, [onChange, part]);

  return (
    <Box display="flex" direction="column">
      <Box margin={2} />
      <Box>
        <Box display="flex" gap={2} direction="row" alignItems="center">
          <Checkbox id={resources.currency} checked={check} size={"sm"} onChange={onSourceSelect} />
          <Text size="200" color={colors.subtle} weight="bold">
            {resources.currencyFormat}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

/**Variable Part Alphanumeric**/

type VariablePartAlphanumericFilterProps = {
  part: VariablePart;
  onChange(part: VariablePart): void;
  resources: { convertValues: string; currency: string; currencyFormat: string };
};

const VariablePartAlphanumericFilter: FunctionComponent<VariablePartAlphanumericFilterProps> = ({
  part,
  resources,
  onChange,
}) => {
  const { colors } = useColors();
  const [check, setCheck] = useState(!part.filter);
  const onSourceSelect = useCallback(() => {
    setCheck((prevCheck) => {
      const newCheck = !prevCheck;

      if (
        part.dataType === "Alphanumeric" &&
        (part.filter === undefined || part.filter.type === "map-value")
      ) {
        if (newCheck) {
          onChange({ ...part, filter: undefined });
        } else {
          onChange({
            ...part,
            filter: {
              type: "map-value",
              inputs: [],
              defaultOutput: "def",
            },
          });
        }
      }

      return newCheck;
    });
  }, [onChange, part]);

  return (
    <Box display="flex" direction="column">
      <Box margin={2} />
      <Box>
        <Box display="flex" gap={2} direction="row" alignItems="center">
          <Checkbox id={resources.currency} checked={check} size={"sm"} onChange={onSourceSelect} />
          <Text size="200" color={colors.subtle} weight="bold">
            {resources.convertValues}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
