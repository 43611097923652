import { FunctionComponent, PropsWithChildren, useMemo } from "react";

import { isValueListContextField } from "@prodoctivity/shared";
import type { ContextFieldProperties } from "@prodoctivity/shared/src/index-types";
import type { DataElementInputProps } from "../dictionary";
import { Box } from "./Box";
import { useColors } from "./ColorSchemeProvider";
import { MarkdownViewer } from "./Markdown/MarkdownViewer";
import { Text } from "./Text";
import { WhatsThis } from "./WhatsThis";

type Props = {
  properties: ContextFieldProperties;
  id: string;
  focused: boolean;
  purpose?: DataElementInputProps["purpose"];
  getDynamicValue?(text: string): string;
};

function renderDefinition(definition: string) {
  return (
    <span>
      {(definition || "").split("\n").map((d, i) => (
        <div key={i}>{d}</div>
      ))}
    </span>
  );
}

export const FormItem: FunctionComponent<PropsWithChildren<Props>> = ({
  properties,
  id,
  focused,
  purpose,
  getDynamicValue,
  children,
}) => {
  const { colors } = useColors();
  const { activeDescriptionText, activeLabelText } = useMemo(() => {
    if (!getDynamicValue) {
      return { activeDescriptionText: properties.description, activeLabelText: properties.label };
    }

    return {
      activeDescriptionText: getDynamicValue(properties.description),
      activeLabelText: getDynamicValue(properties.label),
    };
  }, [getDynamicValue, properties.description, properties.label]);

  const required = !!properties.minOccurs && properties.minOccurs > 0;

  return (
    <Box
      key={id}
      about={focused ? "focused" : undefined}
      minHeight={properties.dataType !== "Logical" ? "50px" : "80px"}
      minWidth="210px"
      position="relative"
    >
      <Box display="flex" direction="column">
        <Box display="flex" flex="grow" direction="row" gap={1}>
          {purpose === "edit-template" && (
            <Box display="flex" direction="column">
              <Box display="flex" flex="grow" />
              <Box width={25} height={34}></Box>
              <Box margin={1} />
            </Box>
          )}
          <Text weight="bold" size="200">
            {activeLabelText}
          </Text>
          {required ? <Text color={colors.error}> *</Text> : null}
          {properties.instructions && (focused || forceDisplayHelp(properties)) && (
            <WhatsThis
              title={properties.instructions}
              color={colors.neutral900}
              colorBundle={colors}
              iconType="info"
              size="xs"
            >
              {renderDefinition(properties.instructions)}
            </WhatsThis>
          )}
        </Box>
        {!!properties.description && (
          <Box display="flex" direction="row">
            {purpose === "edit-template" && (
              <Box display="flex" direction="column">
                <Box display="flex" flex="grow" />
                <Box width={25} height={34}></Box>
                <Box margin={1} />
              </Box>
            )}
            <Box display="flex" direction="column">
              <Box display="flex" direction="row">
                <MarkdownViewer markdownText={activeDescriptionText} size="sm" />
              </Box>
            </Box>
          </Box>
        )}
        <Box direction="row" flex="grow">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

function forceDisplayHelp(properties: ContextFieldProperties): boolean {
  switch (properties.dataType) {
    case "Alphanumeric":
      return isValueListContextField(properties);
    case "Image":
      return true;
    case "Logical":
      return true;
    default:
      return false;
  }
}
