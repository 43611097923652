import { useDesignBreakpoint } from "@prodoctivity/design-system";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useCallback, useMemo, useState } from "react";
import type { EmptyObject, GraphicMark } from "@prodoctivity/types";
import { useParams } from "react-router-dom";
import { useServices } from "../../../../hooks/useServices";
import { useOrganizationQuery } from "../../../../hooks/useOrganizationQuery";
import { ToastMessageType } from "../../../../components/NotificationMessage";
import { useMutation } from "@tanstack/react-query";
import { organizationLinkTemplates } from "../../../../link-templates";
import { BreadCrumbEntry } from "../../../../components/BreadCrumb";
import { useOrganizationNavigate } from "../../../../hooks/useOrganizationNavigate";
import { usePaginatedDataEndpoint } from "../../../../components/hooks";

export type GraphicMarkStateForm = {
  id?: string;
  name: string;
  image: string;
  customMessage?: string;
  distinguishedName: boolean;
  url: boolean;
  logo: boolean;
  email: boolean;
  date: boolean;
  waterMark: boolean;
  errors?: { [key: string]: string };
  toastMessage?: ToastMessageType;
};

export const useGraphicMarkBuilder = (isUpdating: boolean) => {
  const { graphicMarkId } = useParams();
  const organizationNavigate = useOrganizationNavigate();
  const { resources, moment } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const {
    getOrganizationGraphicMark,
    createOrganizationGraphicMark,
    updateOrganizationGraphicMark,
  } = useServices();
  const breadcrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.graphicMarks,
        url: organizationLinkTemplates.graphicMarkList(),
      },
      { type: "text", name: `${isUpdating ? resources.edit : resources.addNew}` },
    ];
  }, [isUpdating, resources]);

  const fetchGraphicMark = useCallback(async () => {
    if (!graphicMarkId) {
      return { graphicMark: undefined };
    }
    return await getOrganizationGraphicMark(graphicMarkId);
  }, [graphicMarkId, getOrganizationGraphicMark]);

  const [formState, setFormState] = useState<GraphicMarkStateForm>({
    name: "",
    customMessage: "",
    image: "",
    date: false,
    distinguishedName: false,
    email: false,
    logo: false,
    url: false,
    waterMark: false,
  });

  const { isLoading } = useOrganizationQuery(`graphic-mark/${graphicMarkId}`, fetchGraphicMark, {
    onSuccess: (data) => {
      if (data.graphicMark) {
        setFormState((prev) => ({
          ...prev,
          id: data.graphicMark.id,
          name: data.graphicMark.name,
          customMessage: data.graphicMark.customMessage,
          image: data.graphicMark.image || "",
          distinguishedName: data.graphicMark.distinguishedName || false,
          url: data.graphicMark.url || false,
          logo: data.graphicMark.logo || false,
          email: data.graphicMark.email || false,
          date: data.graphicMark.date || false,
          waterMark: data.graphicMark.waterMark || false,
        }));
      }
    },
    refetchOnMount: true,
  });

  const handlePicChange = useCallback((image: string) => {
    setFormState((prevState) => {
      if (!prevState) {
        return prevState;
      }
      return { ...prevState, image: image };
    });
  }, []);

  const clearPicture = useCallback(() => {
    setFormState((prevState) => {
      if (!prevState) {
        return prevState;
      }
      return { ...prevState, image: "" };
    });
  }, []);

  const isSaveButtonDisabled = useMemo(() => {
    if (!formState.name) {
      return true;
    }
    return false;
  }, [formState]);
  const fetchCreateOrUpdateGraphicMark = useCallback(
    async (
      params: { id?: string; name: string; imageDataURI: string | undefined } & GraphicMark
    ) => {
      const { id, ...payload } = params;
      if (!id) {
        return await createOrganizationGraphicMark(payload);
      }
      return await updateOrganizationGraphicMark({ graphicMarkId: id, ...payload });
    },
    [updateOrganizationGraphicMark, createOrganizationGraphicMark]
  );
  const { mutate: mutateGraphic, isLoading: isMutating } = useMutation(
    fetchCreateOrUpdateGraphicMark,
    {
      onSuccess: (data) => {
        if (data.id) {
          setFormState((prev) => ({
            ...prev,
            toastMessage: { type: "success", message: resources.success },
          }));
          setTimeout(() => {
            organizationNavigate(organizationLinkTemplates.graphicMarkList());
          }, 2000);
        }
      },
      onError: (error: {
        response: { data: { errors: Array<{ message: string; errorCode: string }> } };
      }) => {
        if (error.response.data.errors.length > 0) {
          if (error.response.data.errors[0].errorCode === "exists") {
            setFormState((prev) => ({
              ...prev,
              toastMessage: {
                type: "error",
                message: resources.alreadyExists,
              },
            }));
          } else if (error.response.data.errors[0].errorCode === "forbidden") {
            setFormState((prev) => ({
              ...prev,
              toastMessage: {
                type: "error",
                message: resources.signUpPage.forbidden,
              },
            }));
          } else {
            setFormState((prev) => ({
              ...prev,
              toastMessage: { type: "error", message: error.response.data.errors[0].message },
            }));
          }
        }
      },
    }
  );

  const handleSaveChanges = useCallback(() => {
    if (isSaveButtonDisabled || !formState.name) {
      return { id: undefined };
    }

    mutateGraphic({
      id: formState.id,
      name: formState.name,
      imageDataURI: formState.logo ? (formState.image ? formState.image : undefined) : undefined,
      customMessage: formState.customMessage,
      date: formState.date,
      distinguishedName: formState.distinguishedName,
      email: formState.email,
      logo: formState.logo,
      url: formState.url,
      waterMark: formState.waterMark,
    });
  }, [
    formState.customMessage,
    formState.date,
    formState.distinguishedName,
    formState.email,
    formState.id,
    formState.image,
    formState.logo,
    formState.name,
    formState.url,
    formState.waterMark,
    isSaveButtonDisabled,
    mutateGraphic,
  ]);

  return {
    resources,
    moment,
    breakpoint,
    fetchGraphicMark,
    mutateGraphic,
    handlePicChange,
    isMutating,
    formState,
    setFormState,
    handleSaveChanges,
    isLoading,
    breadcrumbEntries,
    clearPicture,
  };
};

export const useGraphicMarkList = () => {
  const { deleteOrganizationGraphicMark } = useServices();
  const { resources } = useAppTranslation();
  const [toastMessage, setToastMessage] = useState<ToastMessageType | undefined>(undefined);

  const {
    paginatedData,
    setFilter,
    isLoading,
    currentPage,
    totalRowCount,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    refetch,
  } = usePaginatedDataEndpoint<
    { graphicMarkList: { id: string; name: string; customMessage: string }[] },
    "15" | "30" | "50",
    string | undefined
  >(
    "15",
    "",
    (services, currentPage, rowsPerPage, filter) => {
      return services.getOrganizationGraphicMarks(rowsPerPage, currentPage, filter);
    },
    "manage_graphic_mark_list"
  );
  const { mutate: mutateDeleteGraphicMark, isLoading: isDeleting } = useMutation(
    deleteOrganizationGraphicMark,
    {
      onSuccess: useCallback(
        (data: EmptyObject) => {
          if (data) {
            setToastMessage({ type: "success", message: resources.success });
            refetch();
          }
        },
        [refetch, resources.success]
      ),
      onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
        if (error.response.data.errors.length > 0) {
          setToastMessage({ type: "error", message: error.response.data.errors[0].message });
        }
      },
    }
  );
  return {
    paginatedData,
    setFilter,
    isLoading,
    currentPage,
    totalRowCount,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    refetch,
    mutateDeleteGraphicMark,
    isDeleting,
    toastMessage,
    setToastMessage,
  };
};
