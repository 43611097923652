import {
  Box,
  Text,
  Tooltip,
  calculateColor,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";

import { shouldNever } from "@prodoctivity/shared";
import type { ActionLogEntry } from "@prodoctivity/types";
import { FunctionComponent } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../link-templates";
import { EyeSvg } from "../../svg/EyeSvg";
import { OrganizationNavLink } from "../OrganizationLink";
import { AdminChangeUserPasswordEntry } from "./entries/AdminChangeUserPasswordEntry";
import { DocumentActionEntry } from "./entries/DocumentActionEntry";
import { DocumentCollectionEntry } from "./entries/DocumentCollectionEntry";
import { DocumentGeneratedEntry } from "./entries/DocumentGeneratedEntry";
import { DocumentViewEntry } from "./entries/DocumentViewEntry";
import { OrganizationCreatedEntry } from "./entries/OrganizationCreatedEntry";
import { UserPasswordUpdatedEntry } from "./entries/PasswordUpdatedEntry";
import { TemplateUpdatedEntry } from "./entries/TemplateUpdatedEntry";
import { UserCreatedEntry } from "./entries/UserCreatedEntry";

type Props = {
  entry: ActionLogEntry;
  fullScreen?: boolean;
  fullWidth?: boolean;
};

export const Activity: FunctionComponent<Props> = ({ entry, fullScreen, fullWidth }) => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();
  const fromNow = moment(entry.createdAt).fromNow();
  const fullDate = moment(entry.createdAt).format(resources._dateTimeFormat);

  const { breakpoint } = useDesignBreakpoint();
  const disable = breakpoint === "medium" || breakpoint === "small";

  if (fullWidth) {
    const textContent = <Text color={colors.black900}>{fromNow}</Text>;
    return (
      <Box display="flex" direction="column" flex="shrink" width={"100%"} paddingY={1}>
        <Box paddingX={2} display="flex" direction="row" flex="grow">
          <Box display="flex" flex="grow">
            <ActivityContent entry={entry} fullScreen={fullScreen} fullWidth={fullWidth} />
          </Box>

          <Box display="flex" direction="row" flex="shrink">
            {!disable && (
              <Tooltip text={fullDate}>
                {fullScreen ? (
                  textContent
                ) : (
                  <OrganizationNavLink to={organizationLinkTemplates.activities(entry.id)}>
                    {textContent}
                  </OrganizationNavLink>
                )}
              </Tooltip>
            )}

            {!fullScreen && (
              <Box marginStart={5}>
                <OrganizationNavLink to={organizationLinkTemplates.activities(entry.id)}>
                  <EyeSvg color={calculateColor(colors.primary)}></EyeSvg>
                </OrganizationNavLink>
              </Box>
            )}
          </Box>
        </Box>

        {!fullScreen && <Box borderStyle="sm" width={"100%"} height={1} marginTop={6} />}
      </Box>
    );
  }
  return <ActivityContent entry={entry} fullScreen={fullScreen} fullWidth={fullWidth} />;
};

const ActivityContent: FunctionComponent<Props> = ({ entry, fullScreen, fullWidth }) => {
  switch (entry.entryType) {
    case "organization-created":
      return (
        <OrganizationCreatedEntry
          id={entry.id}
          fullScreen={fullScreen}
          fullWidth={fullWidth}
          entry={entry}
        />
      );
    case "user-created":
      return (
        <UserCreatedEntry
          id={entry.id}
          entry={entry}
          fullScreen={fullScreen}
          fullWidth={fullWidth}
        />
      );
    case "document-generated":
      return (
        <DocumentGeneratedEntry
          id={entry.id}
          entry={entry}
          entryCount={entry.entryCount || 0}
          fullScreen={fullScreen}
          fullWidth={fullWidth}
        />
      );
    case "template-updated":
      return (
        <TemplateUpdatedEntry
          id={entry.id}
          entry={entry}
          fullScreen={fullScreen}
          fullWidth={fullWidth}
        />
      );
    case "document-collection-assigned":
      return (
        <DocumentCollectionEntry
          id={entry.id}
          entry={entry}
          fullScreen={fullScreen}
          fullWidth={fullWidth}
        />
      );
    case "document-saved":
    case "document-deleted":
    case "document-restored":
    case "document-permanently-deleted":
      return (
        <DocumentActionEntry
          id={entry.id}
          entry={entry}
          entryCount={entry.entryCount || 0}
          fullScreen={fullScreen}
          fullWidth={fullWidth}
        />
      );
    case "user-password-updated":
      return (
        <UserPasswordUpdatedEntry
          id={entry.id}
          entry={entry}
          fullScreen={fullScreen}
          fullWidth={fullWidth}
        />
      );
    case "admin-change-user-password":
      return (
        <AdminChangeUserPasswordEntry
          id={entry.id}
          entry={entry}
          fullScreen={fullScreen}
          fullWidth={fullWidth}
        />
      );
    case "document-view":
    case "document-shared":
      return (
        <DocumentViewEntry
          id={entry.id}
          entry={entry}
          fullScreen={fullScreen}
          fullWidth={fullWidth}
        />
      );
    default:
      shouldNever(entry);
      return null;
  }
};
