import moment from "moment";
import { useMemo } from "react";
import { useAppTranslation } from "./useAppTranslation";

export const useLanguages = () => {
  const { resources } = useAppTranslation();
  const languages = useMemo(() => {
    return [
      { value: "en", label: resources.languages.english },
      { value: "es", label: resources.languages.spanish },
      { value: "nl", label: resources.languages.dutch },
      { value: "pt", label: resources.languages.portuguese },
    ];
  }, [
    resources.languages.dutch,
    resources.languages.english,
    resources.languages.portuguese,
    resources.languages.spanish,
  ]);

  return { resources, moment, languages };
};
