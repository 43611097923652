import { Box, Checkbox, Text } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { DocumentTypesInitialState } from "./hooks";
import type { DocumentTypeSubscriptionType } from "@prodoctivity/types";

type Props = {
  currentDocumentTypeSubscriptions: DocumentTypesInitialState;
  handleDocTypeCheckBoxOnChange: (
    checkBoxCase: string,
    documentTypeId: string,
    subscriptionType: DocumentTypeSubscriptionType
  ) => void;
  documentTypeIsMutating: boolean;
  uniqueDocumentTypeId: string;
};

export const DocumentTypeInternalRows: FunctionComponent<Props> = ({
  currentDocumentTypeSubscriptions,
  handleDocTypeCheckBoxOnChange,
  documentTypeIsMutating,
  uniqueDocumentTypeId,
}) => {
  return (
    <>
      {currentDocumentTypeSubscriptions
        .filter((subscription) => subscription.documentTypeId === uniqueDocumentTypeId)
        .map((subscription, i) => {
          return (
            <Box
              key={i}
              display="flex"
              alignItems="center"
              width={"100%"}
              height={48}
              marginStart={-6}
              marginEnd={-6}
            >
              <Box width={"58.5%"}>
                <Text></Text>
              </Box>
              <Box width={"20%"}>
                <Text size="200">{subscription.subscriptionType.toUpperCase()}</Text>
              </Box>
              <Box width={"14%"} paddingX={1}>
                <Checkbox
                  id={`subscriptionCheckbox_${
                    subscription.documentTypeId
                  }_${subscription.sendToWeb.toString()}`}
                  checked={subscription.sendToWeb}
                  onChange={() =>
                    handleDocTypeCheckBoxOnChange(
                      "sendToWeb",
                      subscription.documentTypeId,
                      subscription.subscriptionType
                    )
                  }
                  disabled={documentTypeIsMutating}
                />
              </Box>
              <Box width={"6%"} paddingX={1}>
                <Checkbox
                  id={`subscriptionCheckbox_${
                    subscription.documentTypeId
                  }_${subscription.sendToMobile.toString()}`}
                  checked={subscription.sendToMobile}
                  onChange={() =>
                    handleDocTypeCheckBoxOnChange(
                      "sendToMobile",
                      subscription.documentTypeId,
                      subscription.subscriptionType
                    )
                  }
                  disabled={documentTypeIsMutating}
                />
              </Box>
            </Box>
          );
        })}
    </>
  );
};
