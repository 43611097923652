import {
  EditVariableCommand,
  VariablePartSheetCurrentPart,
  useColors,
  usePubSub,
} from "@prodoctivity/design-system";
import { valueTypeToFormValue } from "@prodoctivity/prodoctivity-form-v5";
import {
  Expression,
  areArraysEqual,
  areObjectsEqual,
  convertToTreeForm,
  convertTreeFormLogicalExpressionToExpressionObject,
  deepCopy,
  formValueToValueType,
  isContextDefinitionMergeError,
  linearizeRecordHolder,
} from "@prodoctivity/shared";
import type {
  ContextField,
  DataType,
  DocumentCollectionFolderSortType,
  DocumentCollectionIcon,
  ExpressionDataInput,
  InputControlValueType,
  LogicalExpression,
  StringTemplateParameterValue,
  StringTemplatePart,
  TemplateContextRecordHolder,
} from "@prodoctivity/shared/src/index-types";
import type {
  CloseConfig,
  DocumentCollectionConfig,
  DocumentCollectionConfigFolder,
  DocumentCollectionConfigRelationship,
  DocumentCollectionConfigRelationshipDocumentType,
  DocumentCollectionConfigValidation,
  DocumentTypeResume,
} from "@prodoctivity/types";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import {
  addItemAtPath,
  existMultipleInstancesOfDocumentType,
  folderContainsDocumentTypes,
  getDocumentTypesFromFolders,
  moveElementByPath,
  nameConfigContextToString,
  removeItemAtPath,
  updateElementByPath,
  updateFoldersDocumentTypes,
} from "./Steps/utils";

import { ComboBoxItemType, ComboBoxProps } from "@prodoctivity/design-system/components/ComboBox";
import { resourcesAdapters } from "@prodoctivity/fluency-components";
import { initializeNullSourceSummaries } from "@prodoctivity/prodoctivity-form-v5/src/components/Dependencies/RowComponent/hooks";
import { arrayToMap } from "@prodoctivity/shared";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useAllDocumentTypes } from "../../../components/hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useServices } from "../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../link-templates";

export const defaultLogicalExpression: LogicalExpression = {
  isNegative: false,
  operatorType: "equals",
  source: "",
  target: "",
  summaryFunction: "valueOf",
};

export const useDocumentCollectionList = ({
  resources,
  refetch,
}: {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  refetch: () => void;
}) => {
  const [deleteInfo, setDeleteInfo] = useState<
    | {
        id: string;
        name: string;
        documentTypeIdList: string[];
      }
    | undefined
  >(undefined);
  const [toastMessage, setToastMessage] = useState<
    | {
        type: "error" | "success" | "warn";
        message: string;
      }
    | undefined
  >(undefined);

  const {
    getCollectionConfigAffectedEntities,
    deleteDocumentCollectionConfig,
    user: thisUser,
  } = useServices();

  const canDeleteDocumentCollection = useMemo(() => {
    return thisUser ? thisUser.permissions.indexOf("delete-document-collection") >= 0 : false;
  }, [thisUser]);

  const { mutate: mutateDeleteCollection } = useMutation(deleteDocumentCollectionConfig, {
    onSuccess: useCallback(() => {
      setDeleteInfo(undefined);
      refetch();
      setToastMessage({ type: "success", message: resources.successfullyDeleted });
      setTimeout(() => setToastMessage(undefined), 5000);
    }, [refetch, resources.successfullyDeleted]),
    onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
      if (error.response.data.errors.length > 0) {
        setToastMessage({ type: "error", message: error.response.data.errors[0].message });
        setTimeout(() => setToastMessage(undefined), 5000);
      }
    },
  });

  const fetchAffectedEntities = useCallback(async () => {
    if (!deleteInfo || !canDeleteDocumentCollection) {
      return {
        documentsAffected: undefined,
        documentCollectionsAffected: undefined,
      };
    }
    return await getCollectionConfigAffectedEntities(deleteInfo.id);
  }, [deleteInfo, canDeleteDocumentCollection, getCollectionConfigAffectedEntities]);

  const { data: affectedEntitiesResponse } = useOrganizationQuery(
    `/document-collection-config/${deleteInfo?.id}`,
    fetchAffectedEntities
  );

  return {
    canDeleteDocumentCollection,
    deleteInfo,
    setDeleteInfo,
    toastMessage,
    setToastMessage,
    affectedEntitiesResponse,
    mutateDeleteCollection,
  };
};

export type DocumentCollectionState = {
  name?: string;
  masterDocumentTypeId?: string;
  documentTypeIdList: Array<string>;

  nameConfig: StringTemplatePart[];
  iconKey: DocumentCollectionIcon;
  color?: string;
  defaultFolderSort: DocumentCollectionFolderSortType;

  relationships: Array<DocumentCollectionConfigRelationship<string>>;

  treeStructure: {
    folders: Array<DocumentCollectionConfigFolder<string>>;
    documentTypes: DocumentCollectionConfigFolder<string>["documentTypes"];
  };

  trackingIssuesDueDateHours: number | undefined;
  isTrackable: boolean;

  closeConfig?: DocumentCollectionConfigFolder<string>["closeConfig"];

  errors?: { [key: string]: string };
  toastMessage?: {
    type: "error" | "success" | "warn";
    message: string;
  };
};

const STEPS = {
  Conformation: 0,
  DisplaySettings: 1,
  Relationships: 2,
  Structure: 3,
  Validation: 4,
};

export const useDocumentCollectionManagement = () => {
  const { resources } = useAppTranslation();
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const organizationNavigate = useOrganizationNavigate();
  const [showMainSaveButton, setShowMainSaveButton] = useState<boolean>(true);
  const [formState, setFormState] = useState<DocumentCollectionState>({
    defaultFolderSort: {
      type: "document-date",
      direction: "desc",
    },
    documentTypeIdList: [],
    nameConfig: [],
    iconKey: "default",
    relationships: [],
    treeStructure: { folders: [], documentTypes: [] },
    isTrackable: false,
    trackingIssuesDueDateHours: undefined,
    closeConfig: undefined,
  });
  const [showConfirmationModal, setShowModal] = useState<boolean>(false);

  const {
    getDocumentTypeInfo,
    getDocumentCollectionConfig,
    createDocumentCollectionConfig,
    updateDocumentCollectionConfig,
    getCollectionConfigAffectedEntities,
  } = useServices();

  const { id } = useParams();

  useEffect(() => {
    if (formState.toastMessage) {
      setTimeout(() => setFormState((prev) => ({ ...prev, toastMessage: undefined })), 5000);
    }
  }, [formState.toastMessage]);

  const {
    isLoading: isMutating,
    isSuccess,
    mutate,
  } = useMutation(createDocumentCollectionConfig, {
    onSuccess: () => {
      setFormState((prev) => ({
        ...prev,
        toastMessage: { type: "success", message: resources.success },
      }));
      setTimeout(() => {
        organizationNavigate(organizationLinkTemplates.manageDocumentCollections());
      }, 2000);
    },
    onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
      if (error.response.data.errors.length > 0) {
        setFormState((prev) => ({
          ...prev,
          toastMessage: { type: "error", message: error.response.data.errors[0].message },
        }));
      }
    },
  });

  const {
    isLoading: isMutatingPUT,
    isSuccess: isSuccessPUT,
    mutate: mutatePUT,
  } = useMutation(updateDocumentCollectionConfig, {
    onSuccess: () => {
      setFormState((prev) => ({
        ...prev,
        toastMessage: {
          type: "success",
          message: resources.userManagementAndRoles.saveMessages.successfullySaved,
        },
      }));
      setTimeout(() => {
        organizationNavigate(organizationLinkTemplates.manageDocumentCollections());
      }, 2000);
    },
    onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
      if (error.response.data.errors.length > 0) {
        setFormState((prev) => ({
          ...prev,
          toastMessage: { type: "error", message: error.response.data.errors[0].message },
        }));
      }
    },
  });

  const fetchCollectionConfig = useCallback(async () => {
    if (!id)
      return {
        config: undefined,
      };
    const configResponse = await getDocumentCollectionConfig(id);

    return configResponse;
  }, [id, getDocumentCollectionConfig]);

  const { data: collectionConfigResponse, isLoading: isLoadingConfig } = useOrganizationQuery(
    `document-collection-config/${id}`,
    fetchCollectionConfig,
    {
      refetchOnMount: true,
      onSuccess: (data) => {
        if (!data || !data.config) return;
        setConfig(data.config);
      },

      cacheTime: 0,
      staleTime: 0,
    }
  );

  const fetchAffectedEntities = useCallback(async () => {
    if (!id) {
      return {
        documentsAffected: undefined,
        documentCollectionsAffected: undefined,
      };
    }
    return await getCollectionConfigAffectedEntities(id);
  }, [id, getCollectionConfigAffectedEntities]);

  const { data: affectedEntitiesResponse } = useOrganizationQuery(
    `/document-collection-config/${id}`,
    fetchAffectedEntities
  );

  const setConfig = useCallback((config?: DocumentCollectionConfig<string>) => {
    if (!config) return;
    setFormState((prev) => {
      const newState: DocumentCollectionState = deepCopy({
        name: config.name,
        masterDocumentTypeId: config.masterDocumentTypeId,
        documentTypeIdList: config.documentTypeIdList,
        nameConfig: config.nameConfig,
        iconKey: config.iconKey,
        color: config.color,
        defaultFolderSort: config.defaultFolderSort,
        relationships: config.relationships,
        treeStructure: {
          folders: config.folders,
          documentTypes: config.documentTypes,
        },
        isTrackable: config.isTrackable,
        trackingIssuesDueDateHours: config.trackingIssuesDueDateHours,
        closeConfig: config.closeConfig,
      });
      return { ...prev, ...newState };
    });
  }, []);

  const undoAllChanges = useCallback(() => {
    if (!collectionConfigResponse || !collectionConfigResponse.config) return;
    setConfig(collectionConfigResponse.config);
  }, [collectionConfigResponse, setConfig]);

  const [selectedTabIndex, setTabIndex] = useState<number>(0);

  const prevStep = useCallback(() => {
    setTabIndex((prev) => {
      if (prev === STEPS.Conformation) {
        return prev;
      }
      return prev - 1;
    });
  }, []);

  const nextIsDisable = useMemo(() => {
    if (selectedTabIndex === STEPS.Conformation) {
      return !formState.name || !formState.masterDocumentTypeId;
    } else if (selectedTabIndex === STEPS.DisplaySettings) {
      return !formState.nameConfig.filter((p) => p.type === "variable").length;
    } else if (selectedTabIndex === STEPS.Relationships) {
      return (
        formState.relationships.length === 0 ||
        formState.relationships.some((r) => r.sourceField === "") ||
        formState.relationships.filter(
          (r) =>
            r.documentTypes.length === 0 ||
            r.documentTypes.some((d) => d.documentTypeId === "" || d.targetField === "")
        ).length > 0
      );
    } else if (selectedTabIndex === STEPS.Structure) {
      return !formState.masterDocumentTypeId;
    }
    return false;
  }, [formState, selectedTabIndex]);

  const saveDocumentCollection = useCallback(() => {
    if (!formState.masterDocumentTypeId) {
      return;
    }
    const config: DocumentCollectionConfig<string> = {
      name: formState.name || "",
      nameConfig: formState.nameConfig,
      iconKey: formState.iconKey,
      color: formState.color,
      defaultFolderSort: {
        type: formState.defaultFolderSort.type || "document-date",
        direction: formState.defaultFolderSort.direction || "desc",
      },
      folders: formState.treeStructure.folders,
      documentTypeIdList: formState.documentTypeIdList,
      masterDocumentTypeId: formState.masterDocumentTypeId,
      documentTypes: formState.treeStructure.documentTypes,
      relationships: formState.relationships,
      validations: [],
      isTrackable: formState.isTrackable,
      trackingIssuesDueDateHours: formState.trackingIssuesDueDateHours || 0,
      closeConfig: formState.closeConfig,
    };

    if (id) {
      mutatePUT({ id, config });
    } else {
      mutate(config);
    }
  }, [formState, id, mutate, mutatePUT]);

  const nextStep = useCallback(() => {
    const errors: DocumentCollectionState["errors"] = {};
    const emptyErrors: DocumentCollectionState["errors"] = {};
    let treeStructure = formState.treeStructure;
    if (selectedTabIndex === STEPS.Conformation) {
      if (!formState.name) {
        errors["name"] = resources.required;
      }
      if (!formState.masterDocumentTypeId) {
        errors["masterDocumentTypeId"] = resources.required;
      }
    } else if (selectedTabIndex === STEPS.DisplaySettings) {
      if (!formState.nameConfig.filter((p) => p.type === "variable").length) {
        setFormState((prev) => ({
          ...prev,
          toastMessage: {
            type: "error",
            message: resources.documentCollection.documentCollectionVariablesRequired,
          },
        }));
        return;
      }
    } else if (selectedTabIndex === STEPS.Relationships) {
      if (!formState.masterDocumentTypeId) {
        return;
      }
      if (formState.relationships.length === 0) {
        setFormState((prev) => ({
          ...prev,
          toastMessage: {
            type: "error",
            message: resources.documentCollection.relationRequired,
          },
        }));
        return;
      }
      if (formState.relationships.some((r) => r.sourceField === "")) {
        setFormState((prev) => ({
          ...prev,
          toastMessage: {
            type: "error",
            message: resources.documentCollection.relationshipWithEmptySource,
          },
        }));
        return;
      }
      if (
        formState.relationships.filter(
          (r) =>
            r.documentTypes.length === 0 ||
            r.documentTypes.some((d) => d.documentTypeId === "" || d.targetField === "")
        ).length > 0
      ) {
        setFormState((prev) => ({
          ...prev,
          toastMessage: {
            type: "error",
            message: resources.documentCollection.relationshipWithEmptyDoctypeOrTarget,
          },
        }));
        return;
      }
      const documentTypeIdList: Array<string> = [formState.masterDocumentTypeId];
      formState.relationships.forEach((r) =>
        documentTypeIdList.push(...r.documentTypes.map((d) => d.documentTypeId))
      );

      treeStructure = updateFoldersDocumentTypes(
        treeStructure.documentTypes,
        treeStructure.folders,
        documentTypeIdList
      );
      setFormState((prev) => ({ ...prev, treeStructure, errors: errors, documentTypeIdList }));

      setTabIndex((prev) => prev + 1);
      return;
    } else if (selectedTabIndex === STEPS.Structure) {
      if (!formState.masterDocumentTypeId) {
        return;
      }

      if (
        affectedEntitiesResponse?.documentsAffected !== undefined &&
        affectedEntitiesResponse?.documentsAffected > 0
      ) {
        setShowModal(true);
        return;
      }

      saveDocumentCollection();
      return;
    }

    setFormState((prev) => ({ ...prev, treeStructure, errors: errors }));
    if (!areObjectsEqual(errors, emptyErrors)) {
      return;
    }

    setTabIndex((prev) => {
      if (prev === STEPS.Validation) {
        return prev;
      }
      return prev + 1;
    });
  }, [formState, selectedTabIndex, resources, affectedEntitiesResponse, saveDocumentCollection]);

  const { allDocumentTypes: documentTypesResponse, isLoading: loadingDocTypes } =
    useAllDocumentTypes();

  const fetchMasterDocumentType = useCallback(async () => {
    if (!formState.masterDocumentTypeId) {
      return { documentType: undefined };
    }
    const masterDocTypeResponse = await getDocumentTypeInfo(formState.masterDocumentTypeId);

    const fields = masterDocTypeResponse.documentType.contextDefinition.fields.map(
      (keyword) => keyword.name
    );

    setFormState((prev) => {
      let nameConfig = prev.nameConfig.filter(
        (part) => part.type === "text" || (part.type === "variable" && fields.includes(part.name))
      );

      if (nameConfig.length === 0) {
        nameConfig = masterDocTypeResponse.documentType.nameConfig;
      }

      const relationships = prev.relationships.filter((r) => fields.includes(r.sourceField));

      return { ...prev, relationships, nameConfig };
    });

    return masterDocTypeResponse;
  }, [formState.masterDocumentTypeId, getDocumentTypeInfo]);

  const { data: documentTypeInfoResponse, isLoading: isLoadingDocType } = useOrganizationQuery(
    `document-collection-config/document-types/${formState.masterDocumentTypeId}`,
    fetchMasterDocumentType,
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const documentTypesOptions = useMemo(() => {
    return (documentTypesResponse?.documentTypes || [])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((d) => {
        return { label: d.name, subtext: undefined, value: d.documentTypeId };
      });
  }, [documentTypesResponse]);

  const masterSourceFields = useMemo(() => {
    if (!documentTypeInfoResponse?.documentType) {
      return [];
    }
    return documentTypeInfoResponse.documentType.contextDefinition.fields.map((keyword) => {
      return {
        label: keyword.properties.label,
        value: keyword.name,
        dataType: keyword.properties.dataType,
      };
    });
  }, [documentTypeInfoResponse]);

  const { eventManager } = usePubSub<EditVariableCommand>();

  return {
    resources,
    id,
    isLoading: loadingDocTypes || isLoadingDocType || isLoadingConfig,
    isMutating: isMutating || isMutatingPUT,
    isSuccess: isSuccess || isSuccessPUT,
    formState,
    setFormState,
    documentTypeInfoResponse,
    masterSourceFields,
    masterDocumentType: documentTypeInfoResponse?.documentType,
    allDocumentTypes: documentTypesResponse?.documentTypes || [],
    documentTypesOptions,
    selectedTabIndex,
    prevStep,
    nextStep,
    showConfirmationModal,
    setShowModal,
    affectedEntitiesResponse,
    saveDocumentCollection,
    undoAllChanges,
    nextIsDisable,
    STEPS,
    eventManager,
    visibleModal,
    setVisibleModal,
    showMainSaveButton,
    setShowMainSaveButton,
  };
};

export const useRelationshipStep = (
  formState: DocumentCollectionState,
  setFormState: Dispatch<SetStateAction<DocumentCollectionState>>,
  documentTypesOptions: ComboBoxProps["options"],
  masterSourceFields: Array<{ label: string; value: string; dataType: DataType }>
) => {
  const documentTypeListAvailable = useMemo(() => {
    const docTypeIdsUsed: Array<string> = [];
    formState.relationships.forEach((r) => {
      docTypeIdsUsed.push(...r.documentTypes.map((d) => d.documentTypeId));
    });
    return documentTypesOptions.filter(
      (dt) => !docTypeIdsUsed.includes(dt.value) && dt.value !== formState.masterDocumentTypeId
    );
  }, [documentTypesOptions, formState]);

  const sourceFieldsAvailable = useMemo(() => {
    const fieldsUsed: Array<string> = formState.relationships.map((r) => r.sourceField);
    return masterSourceFields.filter((dt) => !fieldsUsed.includes(dt.value));
  }, [masterSourceFields, formState]);

  const onNewRelation = useCallback(() => {
    if (sourceFieldsAvailable.length === 0) return;
    setFormState((prev) => {
      const newState = { ...prev };
      newState.relationships.push({
        sourceField: sourceFieldsAvailable[0].value,
        documentTypes: [],
      });
      return newState;
    });
  }, [setFormState, sourceFieldsAvailable]);

  const onRemoveRelationship = useCallback(
    (indexRemoved?: number) => {
      if (indexRemoved !== undefined) {
        setFormState((prev) => {
          const newRelationships = prev.relationships.filter((_r, i) => indexRemoved !== i);
          return { ...prev, relationships: newRelationships };
        });
      }
    },
    [setFormState]
  );

  return {
    documentTypeListAvailable,
    sourceFieldsAvailable,
    onNewRelation,
    onRemoveRelationship,
  };
};

export const useRelationshipRow = ({
  relationIndex,
  isNew,
  formState,
  documentTypeListAvailable,
  masterSourceFields,
  relation,
  setFormState,
}: {
  relationIndex: number;
  isNew: boolean;
  formState: DocumentCollectionState;
  documentTypeListAvailable: ComboBoxProps["options"];
  masterSourceFields: Array<{ label: string; value: string; dataType: DataType }>;
  relation: DocumentCollectionConfigRelationship<string>;
  setFormState: Dispatch<SetStateAction<DocumentCollectionState>>;
}) => {
  const [open, setOpen] = useState<boolean>(isNew);

  const onNew = useCallback(() => {
    setFormState((prev) => {
      const newState = { ...prev };

      if (documentTypeListAvailable.length > 0) {
        newState.relationships[relationIndex].documentTypes.push({
          documentTypeId: documentTypeListAvailable[0].value,
          targetField: "",
        });
      }
      return newState;
    });
  }, [relationIndex, documentTypeListAvailable, setFormState]);

  const filteredFields = useMemo(() => {
    const fieldsUsed = formState.relationships.map((r) => r.sourceField);
    return masterSourceFields.filter((field) => {
      if (field.value === relation?.sourceField) {
        return true;
      }
      return !fieldsUsed.includes(field.value);
    });
  }, [formState.relationships, masterSourceFields, relation?.sourceField]);

  const currentSourceField = useMemo(() => {
    return filteredFields.find((dt) => dt.value === relation?.sourceField);
  }, [filteredFields, relation]);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const onChangeSourceField = useCallback(
    (sourceField: string) => {
      setFormState((prev) => {
        const newState = { ...prev };

        if (relationIndex >= 0 && prev.relationships[relationIndex]) {
          newState.relationships[relationIndex].sourceField = sourceField;
        }
        return newState;
      });
    },
    [relationIndex, setFormState]
  );

  const onChangeDocumentType = useCallback(
    (documentTypeId: string, index: number) => {
      setFormState((prev) => {
        const newState = { ...prev };

        if (index !== undefined && newState.relationships[relationIndex].documentTypes[index]) {
          newState.relationships[relationIndex].documentTypes[index].documentTypeId =
            documentTypeId;
        }
        if (newState.masterDocumentTypeId) {
          const documentTypeIdList: Array<string> = [newState.masterDocumentTypeId];
          newState.relationships.forEach((r) =>
            documentTypeIdList.push(...r.documentTypes.map((d) => d.documentTypeId))
          );
          newState.documentTypeIdList = documentTypeIdList;
        }
        return newState;
      });
    },
    [relationIndex, setFormState]
  );

  const onChangeTargetField = useCallback(
    (index: number, targetField?: string) => {
      setFormState((prev) => {
        const newState = { ...prev };

        if (index !== undefined && newState.relationships[relationIndex].documentTypes[index]) {
          newState.relationships[relationIndex].documentTypes[index].targetField =
            targetField || "";
        }

        return newState;
      });
    },
    [relationIndex, setFormState]
  );

  const onRemoveDocTypeRelation = useCallback(
    (indexRemoved?: number) => {
      if (indexRemoved !== undefined) {
        setFormState((prev) => {
          const newState = { ...prev };
          newState.relationships[relationIndex].documentTypes = newState.relationships[
            relationIndex
          ].documentTypes.filter((_r, i) => indexRemoved !== i);
          return newState;
        });
      }
    },
    [setFormState, relationIndex]
  );

  return {
    filteredFields,
    currentSourceField,
    onChangeDocumentType,
    onChangeTargetField,
    onRemoveDocTypeRelation,
    onChangeSourceField,
    open,
    onNew,
    setOpen,
    onCancel,
  };
};

export const useDocumentTypeRelation = ({
  docTypeIndex,
  isNew,
  formState,
  relation,
  documentTypesOptions,
  docTypeRelation,
  onChangeTargetField,
}: {
  docTypeIndex: number;
  isNew: boolean;
  formState: DocumentCollectionState;
  relation: DocumentCollectionConfigRelationship<string>;
  documentTypesOptions: ComboBoxProps["options"];
  docTypeRelation: DocumentCollectionConfigRelationshipDocumentType<string>;
  onChangeTargetField: (index: number, targetField?: string) => void;
}) => {
  const { getDocumentTypeInfo } = useServices();
  const [open, setOpen] = useState<boolean>(isNew);
  const [openExpression, setOpenExpression] = useState<boolean>(false);
  const currentDocType = useMemo(() => {
    return documentTypesOptions.find((dt) => dt.value === docTypeRelation.documentTypeId);
  }, [documentTypesOptions, docTypeRelation.documentTypeId]);

  const fetchDocumentType = useCallback(async () => {
    if (!docTypeRelation.documentTypeId) {
      return {
        documentType: undefined,
      };
    }
    return await getDocumentTypeInfo(docTypeRelation.documentTypeId);
  }, [docTypeRelation.documentTypeId, getDocumentTypeInfo]);

  const { data: documentTypeInfoResponse, isLoading: isLoadingDocType } = useOrganizationQuery(
    `document-collection-config/document-types/${docTypeRelation.documentTypeId}`,
    fetchDocumentType,
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const targetFields = useMemo(() => {
    if (!documentTypeInfoResponse?.documentType) {
      // onChangeTargetField(docTypeIndex, undefined);
      return [];
    }
    const fields = documentTypeInfoResponse.documentType.contextDefinition.fields.map((keyword) => {
      return {
        label: keyword.properties.label,
        value: keyword.name,
        dataType: keyword.properties.dataType,
      };
    });

    if (!fields.findIndex((f) => f.value === docTypeRelation.targetField)) {
      onChangeTargetField(docTypeIndex, fields[0].value);
    }

    return fields;
  }, [docTypeIndex, docTypeRelation.targetField, documentTypeInfoResponse, onChangeTargetField]);

  const currentTargetField = useMemo(() => {
    return targetFields.find((dt) => dt.value === docTypeRelation?.targetField);
  }, [targetFields, docTypeRelation]);

  const onRemoveExpression = useCallback(() => {
    setOpenExpression(false);
  }, []);

  const [currentDocTypeLabel, setCurrentDocTypeLabel] = useState(
    currentDocType ? currentDocType.label : ""
  );

  const docTypesExcludingUsed = useMemo(() => {
    const documentTypesUsed = relation.documentTypes.map((dt) => dt.documentTypeId);
    formState.relationships.forEach((r) => {
      r.documentTypes.forEach((dt) => documentTypesUsed.push(dt.documentTypeId));
    });
    return documentTypesOptions.filter(
      (dt) => dt.value !== formState.masterDocumentTypeId && !documentTypesUsed.includes(dt.value)
    );
  }, [documentTypesOptions, relation, formState]);

  const docTypesFilteredByInput = useMemo(() => {
    return docTypesExcludingUsed.filter((dt) =>
      currentDocTypeLabel
        ? dt.label.toLowerCase().includes(currentDocTypeLabel.toLowerCase())
        : true
    );
  }, [docTypesExcludingUsed, currentDocTypeLabel]);
  const [currentTargetFieldLabel, setCurrentTargetFieldLabel] = useState(
    currentTargetField ? currentTargetField.label : ""
  );

  const targetFieldsFilteredByInput = useMemo(() => {
    return targetFields.filter((field) =>
      currentTargetFieldLabel
        ? field.label.toLowerCase().includes(currentTargetFieldLabel.toLowerCase())
        : true
    );
  }, [targetFields, currentTargetFieldLabel]);

  useEffect(() => {
    setCurrentTargetFieldLabel(currentTargetField?.label || "");
  }, [currentTargetField?.label]);

  return {
    documentType: documentTypeInfoResponse?.documentType,
    currentTargetFieldLabel,
    setCurrentTargetFieldLabel,
    currentDocTypeLabel,
    setCurrentDocTypeLabel,
    docTypesFilteredByInput,
    currentDocType,
    currentTargetField,
    targetFieldsFilteredByInput,
    targetFields,
    isLoadingDocType,
    open,
    setOpen,
    openExpression,
    setOpenExpression,
    onRemoveExpression,
  };
};

export type TreeItem =
  | { type: "folder"; path: Array<string>; item: DocumentCollectionConfigFolder<string> }
  | {
      type: "documentType";
      path: Array<string>;
      item: DocumentCollectionConfigFolder<string>["documentTypes"][0];
    };

export const useStructureStep = (
  formState: DocumentCollectionState,
  documentTypesOptions: ComboBoxProps["options"],
  setFormState: Dispatch<SetStateAction<DocumentCollectionState>>
) => {
  const { resources, moment } = useAppTranslation();
  const [configItem, setConfigItem] = useState<
    { isRootFolder?: boolean; originalItem: TreeItem; updatedItem: TreeItem } | undefined
  >(undefined);
  const [draggedItem, setDraggedItem] = useState<TreeItem | undefined>(undefined);
  const [dropPosition, setDropPosition] = useState<({ isBefore: boolean } & TreeItem) | undefined>(
    undefined
  );

  const docTypesAvailableToAdd = useMemo(() => {
    if (!configItem) {
      return [];
    }
    const originalItem = configItem.originalItem;
    if (originalItem.type !== "folder") {
      return [];
    }
    const documentTypeIdListUsed = originalItem.item.documentTypes.map((d) => d.documentTypeId);

    return documentTypesOptions.filter(
      (option) =>
        formState.documentTypeIdList.includes(option.value) &&
        !documentTypeIdListUsed.includes(option.value)
    );
  }, [formState.documentTypeIdList, configItem, documentTypesOptions]);

  const onCancelConfig = useCallback(() => {
    setConfigItem(undefined);
  }, []);

  const handleDragStart = useCallback(
    (item: TreeItem) => {
      setDraggedItem(item);
      onCancelConfig();
    },
    [onCancelConfig]
  );

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>, item?: TreeItem) => {
    e.preventDefault();

    if (!item) {
      setDropPosition(undefined);
      return;
    }
    const mouseY = e.clientY;
    const element = e.currentTarget;
    const elementRect = element.getBoundingClientRect();
    const isBefore = mouseY < elementRect.top + elementRect.height / 2;
    setDropPosition({ isBefore, ...item });
  }, []);

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (draggedItem && dropPosition) {
        const dropPath = dropPosition.path;
        const dragPath = draggedItem.path.concat(
          draggedItem.type === "documentType"
            ? []
            : [nameConfigContextToString(moment, draggedItem.item.nameConfig)]
        );
        if (dropPath.length > 0 && areObjectsEqual(dropPath, dragPath)) {
          setDraggedItem(undefined);
          setDropPosition(undefined);
          return;
        }

        if (draggedItem.type === "documentType" && dropPosition.type === "folder") {
          const documentTypeIdList = dropPosition.item.documentTypes.map((dt) => dt.documentTypeId);
          const documentTypeId = draggedItem.item.documentTypeId;

          if (documentTypeIdList.includes(documentTypeId)) {
            setDraggedItem(undefined);
            setDropPosition(undefined);
            return;
          }
        }

        const newTreeStructure: DocumentCollectionConfigFolder<string> = {
          nameConfig: [],
          pinned: false,
          defaultFolderSort: formState.defaultFolderSort,
          iconKey: formState.iconKey,
          color: formState.color,
          validations: [],
          category: undefined,
          isTrackable: false,

          //TODO: @Daniel check this
          showSummary: undefined,
          showOnEmpty: undefined,

          ...formState.treeStructure,
        };
        moveElementByPath(moment, newTreeStructure, draggedItem, dropPosition);

        setFormState((prev) => ({
          ...prev,
          treeStructure: {
            folders: newTreeStructure.folders,
            documentTypes: newTreeStructure.documentTypes,
          },
        }));
        setDraggedItem(undefined);
        setDropPosition(undefined);
      }
      setDraggedItem(undefined);
      setDropPosition(undefined);
    },
    [formState, setFormState, draggedItem, dropPosition, moment]
  );

  const onNewFolder = useCallback(
    (path: Array<string>) => {
      setFormState((prev) => {
        const newTreeStructure = { ...prev.treeStructure };

        const newFolder: TreeItem = {
          type: "folder",
          path: [],
          item: {
            pinned: false,
            nameConfig: [
              {
                type: "text",
                value: resources.newFolder,
              },
            ],
            iconKey: "folder",
            documentTypes: [],
            folders: [],
            validations: [],
            defaultFolderSort: {
              type: "document-date",
              direction: "desc",
            },
            category: undefined,
            isTrackable: false,
            color: undefined,
            showOnEmpty: undefined,
            showSummary: undefined,
          },
        };

        addItemAtPath(moment, newFolder, newTreeStructure.folders, path);
        return { ...prev, treeStructure: newTreeStructure };
      });
      onCancelConfig();
    },
    [setFormState, onCancelConfig, resources.newFolder, moment]
  );

  const addDocumentTypeToFolder = useCallback(
    (documentTypeId: string) => {
      if (!configItem || configItem.originalItem.type !== "folder") return;

      setFormState((prev) => {
        const newTreeStructure = { ...prev.treeStructure };

        const newDocumentType: TreeItem = {
          type: "documentType",
          path: [],
          item: {
            iconKey: "document",
            documentTypeId,
            nameConfig: undefined,
            color: undefined,
            pinned: false,
            required: false,
            ignoredFieldsInMatch: undefined,
            includeExpression: undefined,
            requiredCondition: undefined,
          },
        };

        const targetPath = [
          ...configItem.originalItem.path,
          nameConfigContextToString(moment, configItem.originalItem.item.nameConfig || []),
        ];
        addItemAtPath(moment, newDocumentType, newTreeStructure.folders, targetPath);

        return { ...prev, treeStructure: newTreeStructure };
      });
      onCancelConfig();
    },
    [configItem, setFormState, onCancelConfig, moment]
  );

  const rootTreeItem = useMemo(() => {
    const result: TreeItem = {
      type: "folder",
      path: [],
      item: {
        nameConfig: formState.nameConfig,
        pinned: false,
        defaultFolderSort: formState.defaultFolderSort,
        iconKey: formState.iconKey,
        color: formState.color,
        folders: formState.treeStructure.folders,
        documentTypes: formState.treeStructure.documentTypes,
        validations: [],
        category: undefined,
        isTrackable: formState.isTrackable,
        closeConfig: formState.closeConfig,
        showOnEmpty: undefined,
        showSummary: undefined,
      },
    };

    return result;
  }, [
    formState.color,
    formState.defaultFolderSort,
    formState.iconKey,
    formState.nameConfig,
    formState.treeStructure.documentTypes,
    formState.treeStructure.folders,
    formState.isTrackable,
    formState.closeConfig,
  ]);

  const removeSelectedItem = useCallback(() => {
    if (!configItem) {
      return;
    }

    const originalItem = configItem.originalItem;

    if (originalItem.type === "documentType" && rootTreeItem.type === "folder") {
      const existMultipleInstances = existMultipleInstancesOfDocumentType(
        originalItem.item.documentTypeId,
        rootTreeItem.item
      );

      if (!existMultipleInstances) {
        return;
      }

      setFormState((prev) => {
        const newTreeStructure = { ...prev.treeStructure };

        if (!originalItem.path.length) {
          newTreeStructure.documentTypes = newTreeStructure.documentTypes.filter(
            (dt) => dt.documentTypeId !== originalItem.item.documentTypeId
          );
        } else {
          newTreeStructure.folders = removeItemAtPath(
            moment,
            originalItem,
            newTreeStructure.folders
          );
        }
        return { ...prev, treeStructure: newTreeStructure };
      });
      onCancelConfig();
    } else if (configItem.originalItem.type === "folder") {
      const folderContainsDocuments = folderContainsDocumentTypes(configItem.originalItem.item);

      if (folderContainsDocuments) {
        return;
      }

      setFormState((prev) => {
        const newTreeStructure = { ...prev.treeStructure };

        newTreeStructure.folders = removeItemAtPath(moment, originalItem, newTreeStructure.folders);

        return { ...prev, treeStructure: newTreeStructure };
      });
      onCancelConfig();
    }
  }, [configItem, rootTreeItem, setFormState, onCancelConfig, moment]);

  const onRootConfig = useCallback(() => {
    setConfigItem({
      isRootFolder: true,
      originalItem: deepCopy(rootTreeItem),
      updatedItem: deepCopy(rootTreeItem),
    });
  }, [rootTreeItem]);

  const onConfigTreeItem = useCallback((item: TreeItem) => {
    setConfigItem({ originalItem: deepCopy(item), updatedItem: deepCopy(item) });
  }, []);

  const onSaveConfig = useCallback(
    (trackingIssuesDueDateHours: number | undefined) => {
      if (!configItem) return;

      setFormState((prev) => {
        if (!configItem) {
          return prev;
        }
        const newState = { ...prev };
        if (configItem.isRootFolder) {
          if (configItem.updatedItem.type === "folder") {
            newState.color = configItem.updatedItem.item.color;
            newState.iconKey = configItem.updatedItem.item.iconKey || "default";
            newState.defaultFolderSort = configItem.updatedItem.item.defaultFolderSort || {
              type: "document-date",
              direction: "desc",
            };
            newState.isTrackable = configItem.updatedItem.item.isTrackable;
            newState.trackingIssuesDueDateHours = trackingIssuesDueDateHours;
            newState.closeConfig = configItem.updatedItem.item.closeConfig;
          }
        } else {
          const newTreeStructure: DocumentCollectionConfigFolder<string> = {
            pinned: false,
            nameConfig: [],
            iconKey: newState.iconKey,
            color: newState.color,
            validations: [],
            category: undefined,
            isTrackable: false,

            defaultFolderSort: newState.defaultFolderSort,

            //TODO: @Daniel check this
            showOnEmpty: undefined,
            showSummary: undefined,

            ...newState.treeStructure,
          };
          updateElementByPath(
            moment,
            newTreeStructure,
            configItem.originalItem,
            configItem.updatedItem
          );
          newState.treeStructure = {
            folders: newTreeStructure.folders,
            documentTypes: newTreeStructure.documentTypes,
          };
        }

        return newState;
      });
      onCancelConfig();
    },
    [moment, configItem, setFormState, onCancelConfig]
  );

  return {
    rootTreeItem,
    configItem,
    docTypesAvailableToAdd,
    setConfigItem,
    onConfigTreeItem,
    onRootConfig,
    onCancelConfig,
    onSaveConfig,
    draggedItem,
    dropPosition,
    onNewFolder,
    addDocumentTypeToFolder,
    removeSelectedItem,
    handleDragStart,
    handleDragOver,
    handleDrop,
  };
};

type FolderTreeItemProps = {
  folder: DocumentCollectionConfigFolder<string>;
  folderPath: Array<string>;
  dropPosition?: { isBefore: boolean } & TreeItem;
  selectedItem?: TreeItem;
};
export const useFolderTreeItem = ({
  folder,
  folderPath,
  dropPosition,
  selectedItem,
}: FolderTreeItemProps) => {
  const { moment } = useAppTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const paddingLeft = useMemo(() => folderPath.length * 30, [folderPath]);

  const strNameConfig = useMemo(
    () => nameConfigContextToString(moment, folder.nameConfig),
    [folder, moment]
  );
  const currFolderPath = useMemo(() => [...folderPath, strNameConfig], [folderPath, strNameConfig]);

  const dragItem = useMemo(() => {
    const result: TreeItem = { type: "folder", item: folder, path: folderPath };
    return result;
  }, [folder, folderPath]);

  const dragTo = useMemo(() => {
    const result: TreeItem = { type: "folder", item: folder, path: [...folderPath, strNameConfig] };
    return result;
  }, [folder, folderPath, strNameConfig]);

  const isSelected = useMemo(() => {
    if (!selectedItem) {
      setShowPopover(false);
      return false;
    }
    const selected = areObjectsEqual(selectedItem, dragItem);

    if (!selected) {
      setShowPopover(false);
    }

    return selected;
  }, [selectedItem, dragItem]);

  const { colors } = useColors();

  const boxColor = useMemo(() => {
    if (isSelected || (dropPosition && areArraysEqual(dragTo.path, dropPosition?.path))) {
      return colors.primary100;
    }
    return !open && folderPath.length === 0 ? colors.white : colors.neutral300;
  }, [
    isSelected,
    dropPosition,
    dragTo.path,
    open,
    folderPath.length,
    colors.white,
    colors.neutral300,
    colors.primary100,
  ]);

  return {
    showPopover,
    setShowPopover,
    open,
    setOpen,
    isSelected,
    boxColor,
    strNameConfig,
    currFolderPath,
    paddingLeft,
    dragItem,
    dragTo,
  };
};

type DocumentTreeItemProps = {
  documentType: DocumentCollectionConfigFolder<string>["documentTypes"][0];
  documentTypes: DocumentTypeResume[];
  folderPath: Array<string>;
  dropPosition?: { isBefore: boolean } & TreeItem;
  selectedItem?: TreeItem;
  rootTreeItem: TreeItem;
};
export const useDocumentTreeItem = ({
  documentType,
  documentTypes,
  folderPath,
  dropPosition,
  selectedItem,
  rootTreeItem,
}: DocumentTreeItemProps) => {
  const { colors } = useColors();
  const documentTypeInfo = useMemo(() => {
    return documentTypes.find((d) => d.documentTypeId === documentType.documentTypeId);
  }, [documentType, documentTypes]);

  const paddingLeft = useMemo(() => folderPath.length * 30, [folderPath]);

  const dragItem = useMemo(() => {
    const result: TreeItem = { type: "documentType", item: documentType, path: folderPath };
    return result;
  }, [documentType, folderPath]);

  const { isBefore: _isBefore, ...dropItem } = dropPosition || {};

  const isSelected = useMemo(() => {
    if (!selectedItem) return false;
    return areObjectsEqual(selectedItem, dragItem);
  }, [selectedItem, dragItem]);

  const boxColor = useMemo(() => {
    return areObjectsEqual(dragItem, dropItem) || isSelected
      ? colors.primary100
      : colors.neutral300;
  }, [colors.neutral300, colors.primary100, dragItem, dropItem, isSelected]);

  const existMultipleInstances = useMemo(() => {
    if (!isSelected || rootTreeItem.type !== "folder") return true;
    const exist = existMultipleInstancesOfDocumentType(
      documentType.documentTypeId,
      rootTreeItem.item
    );
    return exist;
  }, [isSelected, rootTreeItem, documentType]);

  return {
    documentTypeInfo,
    existMultipleInstances,
    paddingLeft,
    dragItem,
    dropItem,
    boxColor,
    isSelected,
  };
};

export const useIconOptions = (resources: ReturnType<typeof useAppTranslation>["resources"]) => {
  const iconOptions = useMemo(() => {
    return [
      {
        //General
        groupLabel: resources.general,
        icons: [
          {
            value: "default",
            label: resources.default,
          },
          {
            value: "folder",
            label: resources.folder,
          },
          {
            value: "document",
            label: resources.document,
          },
        ],
      },
      {
        // Financial Industry
        groupLabel: resources.documentCollection.financialIndustry.financialIndustry,
        icons: [
          {
            value: "bank",
            label: resources.documentCollection.financialIndustry.bank,
          },
          {
            value: "bar-chart",
            label: resources.documentCollection.financialIndustry.barChart,
          },
          {
            value: "calculator",
            label: resources.documentCollection.financialIndustry.calculator,
          },
          {
            value: "coins-stack",
            label: resources.documentCollection.financialIndustry.coinsStack,
          },
          {
            value: "credit-card",
            label: resources.documentCollection.financialIndustry.creditCard,
          },
          {
            value: "financial-document",
            label: resources.documentCollection.financialIndustry.financialDocument,
          },
          {
            value: "loan",
            label: resources.documentCollection.financialIndustry.loan,
          },
          {
            value: "piggy-bank",
            label: resources.documentCollection.financialIndustry.piggyBank,
          },
          {
            value: "safe",
            label: resources.documentCollection.financialIndustry.safe,
          },
          {
            value: "stock-market",
            label: resources.documentCollection.financialIndustry.stockMarket,
          },
          {
            value: "wallet",
            label: resources.documentCollection.financialIndustry.wallet,
          },
        ],
      },
      //Law Firms
      {
        groupLabel: resources.documentCollection.lawFirms.lawFirms,
        icons: [
          {
            value: "gavel",
            label: resources.documentCollection.lawFirms.gavel,
          },
          {
            value: "legal-scale",
            label: resources.documentCollection.lawFirms.legalScale,
          },
          {
            value: "contract",
            label: resources.documentCollection.lawFirms.contract,
          },
          {
            value: "law-book",
            label: resources.documentCollection.lawFirms.lawBook,
          },
          {
            value: "handcuffs",
            label: resources.documentCollection.lawFirms.handcuffs,
          },
          {
            value: "law-badge",
            label: resources.documentCollection.lawFirms.lawBadge,
          },
          {
            value: "legal-briefcase",
            label: resources.documentCollection.lawFirms.legalBriefcase,
          },
          {
            value: "witness-stand",
            label: resources.documentCollection.lawFirms.witnessStand,
          },
          {
            value: "prison-cell",
            label: resources.documentCollection.lawFirms.prisonCell,
          },
          {
            value: "document-signature",
            label: resources.documentCollection.lawFirms.documentSignature,
          },
        ],
      },
    ] as Array<{
      groupLabel?: string;
      icons: Array<{ label: string; value: DocumentCollectionIcon }>;
    }>;
  }, [resources]);

  return {
    iconOptions,
  };
};

export const useTreeItemSettings = (
  treeItem: TreeItem,
  documentTypes: DocumentTypeResume[],
  rootInfo:
    | { isRoot: false }
    | {
        isRoot: true;
        trackingIssuesDueDateHours: number | undefined;
      },
  setConfigItem: Dispatch<
    SetStateAction<
      | {
          originalItem: TreeItem;
          updatedItem: TreeItem;
        }
      | undefined
    >
  >,
  documentTypesOptions: ComboBoxItemType[],
  masterDocumentTypeId: string | undefined
) => {
  const { resources, moment } = useAppTranslation();
  const { getDocumentTypesMergedContext, getDocumentCollectionFolderMergedContext } = useServices();
  const [showVariablePartSheet, setShowVariablePartSheet] = useState(false);
  const [trackingIssuesDueDateHours, setTrackingIssuesDueDateHours] = useState(
    treeItem.type === "folder" && rootInfo.isRoot === true
      ? rootInfo.trackingIssuesDueDateHours
      : undefined
  );

  const changeTrackingIssuesDueDateHours = useCallback(
    (value: number | undefined) => {
      setTrackingIssuesDueDateHours(value);
    },
    [setTrackingIssuesDueDateHours]
  );
  const [saveChangesState, setSaveChangesState] = useState(false);
  const [isFolderChangesButtonDisabled, setIsFolderChangesButtonDisabled] = useState(false);

  const [isTrackableError, setIsTrackableError] = useState(false);
  //Category section
  const [categoryError, setCategoryError] = useState(false);

  const categoryNameIfNotExist = useMemo(() => {
    if (treeItem.type === "folder") {
      const nameConfigString = nameConfigContextToString(moment, treeItem.item.nameConfig)
        .trim()
        .replace(/\s+/g, "-")
        .toLocaleLowerCase();

      return nameConfigString;
    }
    return "";
  }, [moment, treeItem.type, treeItem.item.nameConfig]);

  const onCategoryCheckBoxClick = (check: boolean) => {
    if (!check) {
      setSaveChangesState(false);
    }
    setConfigItem((prev) => {
      if (!prev || prev.updatedItem.type !== "folder") return prev;

      const updatedItem = { ...prev.updatedItem };

      updatedItem.item.category = check ? categoryNameIfNotExist : undefined;

      return {
        ...prev,
        updatedItem: updatedItem,
      };
    });
  };

  //end section

  const [clickedPart, setClickedPart] = useState<VariablePartSheetCurrentPart>();
  const setClickedPartValue = useCallback(
    (value: VariablePartSheetCurrentPart) => {
      setClickedPart(value);
    },
    [setClickedPart]
  );

  const [currentVariable, rawSetCurrentVariable] = useState<
    StringTemplateParameterValue | undefined
  >();

  const rawSetCurrentVariableValue = useCallback(
    (value: StringTemplateParameterValue | undefined) => {
      rawSetCurrentVariable(value);
    },
    [rawSetCurrentVariable]
  );
  const handlePartClicked = useCallback(
    (args: {
      lineIndex: number;
      partIndex: number;
      parameterKey: string;
      part: StringTemplatePart;
    }) => {
      setClickedPartValue({
        lineIndex: args.lineIndex,
        parameterKey: args.parameterKey,
        partIndex: args.partIndex,
        part: args.part,
      });
      setShowVariablePartSheet(true);
    },
    [setClickedPartValue]
  );

  const handleVariableAdded = useCallback(
    (_args: { lineIndex: number; partIndex: number; name: string; dataType: DataType }) => {
      setShowVariablePartSheet(false);
    },
    []
  );

  const initialState = useMemo(() => {
    return { parts: treeItem.item.nameConfig || [] };
  }, [treeItem]);

  const { eventManager } = usePubSub<EditVariableCommand>();

  const folderSortOptions = useMemo((): Array<{
    label: string;
    value: DocumentCollectionFolderSortType["type"];
  }> => {
    return [
      {
        value: "document-date",
        label: resources.documentDate,
      },
      {
        value: "creation-date",
        label: resources.creationDate,
      },
      {
        value: "folder-name",
        label: resources.documentCollection.folderName,
      },
      {
        value: "update-date",
        label: resources.updateDate,
      },
    ];
  }, [resources]);

  const documentTypesId = useMemo(() => {
    if (treeItem.type === "documentType") return [treeItem.item.documentTypeId];
    const documentTypesId = getDocumentTypesFromFolders(treeItem.item);

    return documentTypesId;
  }, [treeItem]);

  const folderDocumentTypesOptions = useMemo(() => {
    const result = documentTypesOptions.filter((dt) => documentTypesId.includes(dt.value));
    return result;
  }, [documentTypesId, documentTypesOptions]);

  const folderDocumentTypeOptions = useMemo(() => {
    return documentTypes
      .filter((dt) => documentTypesId.includes(dt.documentTypeId))
      .map((dt) => ({ value: dt.documentTypeId, label: dt.name }));
  }, [documentTypesId, documentTypes]);

  const fetchMergedContext = useCallback(async () => {
    if (documentTypesId.length === 0) {
      return {
        mergedContextDefinition: undefined,
      };
    }
    return getDocumentTypesMergedContext(documentTypesId).catch(() => {
      return undefined;
    });
  }, [documentTypesId, getDocumentTypesMergedContext]);

  const {
    isLoading,
    data: mergedContextResponse,
    isFetching,
  } = useOrganizationQuery(
    `/document-collection-config/document-types-merged-context/${documentTypesId.join(
      ","
    )}/${nameConfigContextToString(moment, treeItem.item.nameConfig || [])}`,
    fetchMergedContext,
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const fetchFolderMergedContext = useCallback(async () => {
    if (documentTypesId.length === 0 || !masterDocumentTypeId) {
      return {
        mergedContextDefinition: undefined,
      };
    }
    return getDocumentCollectionFolderMergedContext(masterDocumentTypeId, documentTypesId).catch(
      () => {
        return undefined;
      }
    );
  }, [documentTypesId, getDocumentCollectionFolderMergedContext, masterDocumentTypeId]);

  const {
    // isLoadingFolderContext,
    data: mergedFolderContextResponse,
    // isFetchingFolderContext,
  } = useOrganizationQuery(
    [
      `document-collection-config`,
      `document-collection-folder-merged-context`,
      `${documentTypesId.join(",")}`,
    ],
    fetchFolderMergedContext,
    {
      staleTime: 10 * 60 * 1000,
    }
  );

  const folderMergedContextFieldsForFilterBuilder = useMemo(() => {
    if (
      !mergedFolderContextResponse ||
      mergedFolderContextResponse.mergedContextDefinition === undefined ||
      isContextDefinitionMergeError(mergedFolderContextResponse.mergedContextDefinition)
    ) {
      const empty: TemplateContextRecordHolder = {
        fields: [],
        records: [],
      };
      return empty;
    }
    return mergedFolderContextResponse.mergedContextDefinition;
  }, [mergedFolderContextResponse]);

  const folderMergedContextFields = useMemo(() => {
    if (!mergedContextResponse || !mergedContextResponse.mergedContextDefinition) return [];
    return mergedContextResponse.mergedContextDefinition.fields;
  }, [mergedContextResponse]);

  const variableList = useMemo(() => {
    if (!mergedContextResponse || !mergedContextResponse.mergedContextDefinition) return [];
    return mergedContextResponse.mergedContextDefinition.fields.map((field) => {
      const t: StringTemplateParameterValue = {
        source: "fixed",
        value: field.name,
      };
      return t;
    });
  }, [mergedContextResponse]);

  const getFieldLabel = useCallback(
    (fldName: string) => {
      const found = (mergedContextResponse?.mergedContextDefinition?.fields || []).find(
        (fld) => fld.name === fldName
      );
      if (found) {
        return found.properties.label;
      }
      return fldName;
    },
    [mergedContextResponse?.mergedContextDefinition]
  );

  const getFieldDataType: (
    source: StringTemplateParameterValue["source"],
    name: string
  ) => DataType = useCallback(
    (_source, name) => {
      const found = (mergedContextResponse?.mergedContextDefinition?.fields || []).find(
        (fld) => fld.name === name
      );
      if (found) {
        return found.properties.dataType;
      }
      return "Alphanumeric";
    },
    [mergedContextResponse?.mergedContextDefinition]
  );

  const getNewVariableData = useCallback(() => {
    const found = (mergedContextResponse?.mergedContextDefinition?.fields || [])[0];
    if (found) {
      return {
        name: found.name,
        dataType: found.properties.dataType,
      };
    } else {
      throw new Error("Cannot happen");
    }
  }, [mergedContextResponse?.mergedContextDefinition]);

  const createOnExpressionChangeHandler = useCallback(
    (
      type: "documentType" | "folder",
      property: "requiredCondition" | "includeExpression" | "validations",
      index?: number
    ) => {
      return (_name: string, expression: ExpressionDataInput) => {
        const validExpression: Expression = new Expression(expression);
        const expr: LogicalExpression = convertToTreeForm(validExpression);

        setConfigItem((prev) => {
          if (!prev) return prev;
          const updatedItem = { ...prev.updatedItem };
          if (updatedItem.type === type) {
            if (updatedItem.type === "folder" && index !== undefined) {
              updatedItem.item.validations[index] = {
                ...updatedItem.item.validations[index],
                expression: expr,
              };
            } else if (updatedItem.type === "documentType" && property !== "validations") {
              updatedItem.item[property] = expr;
            }
          }
          return {
            ...prev,
            updatedItem: updatedItem,
          };
        });
      };
    },
    [setConfigItem]
  );

  const onRequiredConditionChange = useMemo(() => {
    return createOnExpressionChangeHandler("documentType", "requiredCondition");
  }, [createOnExpressionChangeHandler]);

  const onIncludeExpressionChange = useMemo(() => {
    return createOnExpressionChangeHandler("documentType", "includeExpression");
  }, [createOnExpressionChangeHandler]);

  const onSingleValidationFolderChange = useCallback(
    (index: number) => createOnExpressionChangeHandler("folder", "validations", index),
    [createOnExpressionChangeHandler]
  );

  const createDeleteHandler = useCallback(
    (
      type: "documentType" | "folder",
      property: "requiredCondition" | "includeExpression" | "validations",
      index?: number
    ) => {
      return () => {
        setConfigItem((prev) => {
          if (!prev) return prev;
          const updatedItem = { ...prev.updatedItem };
          if (updatedItem.type === type) {
            if (updatedItem.type === "folder" && index !== undefined) {
              updatedItem.item.validations.splice(index, 1);
            } else if (updatedItem.type === "documentType" && property !== "validations") {
              updatedItem.item[property] = undefined;
            }
          }
          return {
            ...prev,
            updatedItem: updatedItem,
          };
        });
      };
    },
    [setConfigItem]
  );

  const deleteRequiredCondition = createDeleteHandler("documentType", "requiredCondition");
  const deleteIncludeExpression = createDeleteHandler("documentType", "includeExpression");
  const deleteFolderValidation = (index: number) =>
    createDeleteHandler("folder", "validations", index);

  const tI18n = useCallback(
    (k: string) => {
      const d: Record<string, unknown> = resources;
      const spl = k.split(".");
      const lastKey = spl[spl.length - 1];
      const container = spl.slice(0, spl.length - 1).reduce((x: any, next) => {
        return x[next];
      }, d);
      const r: string = container[lastKey] || lastKey;

      return r;
    },
    [resources]
  );

  // HACK: OMG
  const expressionsValidator = useCallback(
    (obj: { name: string; expression: LogicalExpression }[]): string[] => {
      const messages: string[] = [];
      obj.forEach((element) => {
        const expression = convertTreeFormLogicalExpressionToExpressionObject(
          element.expression
        ).toString(tI18n, resourcesAdapters, new Map<string, string>());
        const isInvalid = expression.includes(tI18n("filterBuilder.invalidExpression"));
        if (isInvalid) {
          messages.push(`${tI18n("filterBuilder.invalidExpression")} [${element.name}]`);
        }
      });
      return messages;
    },
    [tI18n]
  );

  const isAnyExpressionInvalidOnCurrentItem = useMemo(() => {
    const errorMessages: string[] = [];
    if (treeItem.type === "folder" && treeItem.item.validations) {
      const expressionsArray = treeItem.item.validations.map((v) => ({
        name: v.name,
        expression: v.expression,
      }));
      errorMessages.push(...expressionsValidator(expressionsArray));
    } else if (treeItem.type === "documentType") {
      if (treeItem.item.requiredCondition) {
        errorMessages.push(
          ...expressionsValidator([
            { name: `${resources.required}`, expression: treeItem.item.requiredCondition },
          ])
        );
      }
      if (treeItem.item.includeExpression) {
        errorMessages.push(
          ...expressionsValidator([
            { name: `${resources.includeDocumentIf}`, expression: treeItem.item.includeExpression },
          ])
        );
      }
    }
    return errorMessages;
  }, [expressionsValidator, resources.includeDocumentIf, resources.required, treeItem]);

  const fieldKeyLabelMap: Map<string, string> = useMemo(() => {
    if (!mergedContextResponse || !mergedContextResponse.mergedContextDefinition) {
      return new Map<string, string>();
    }
    const linear = linearizeRecordHolder(mergedContextResponse.mergedContextDefinition);
    return arrayToMap(
      linear,
      (item) => item.fullPath,
      (item) => item.humanName
    );
  }, [mergedContextResponse]);

  const getConditionText = useCallback(
    (dependencyConditions: ExpressionDataInput) => {
      try {
        const conditionText = new Expression(dependencyConditions).toString(
          tI18n,
          resourcesAdapters,
          fieldKeyLabelMap
        );
        return conditionText;
      } catch (e) {
        console.warn("Failed to parse expression text");
      }
    },
    [tI18n, fieldKeyLabelMap]
  );

  const logicalExpressionToExpressionDataInput = useCallback((expression: LogicalExpression) => {
    const exp = convertTreeFormLogicalExpressionToExpressionObject(expression, true);
    const initializedExpression = initializeNullSourceSummaries(exp);
    return initializedExpression.toDataInput();
  }, []);

  const createNewFolderValidation = useCallback(() => {
    if (treeItem.type === "folder") {
      const validations: DocumentCollectionConfigValidation[] = treeItem.item.validations
        ? treeItem.item.validations.slice(0)
        : [];
      validations.push({
        name: `Validation ${validations.length + 1}`,
        expression: defaultLogicalExpression,
        description: "",
      });
      setConfigItem((prev) => {
        if (!prev) return prev;
        const updatedItem = { ...prev.updatedItem };
        if (updatedItem.type === "folder") {
          updatedItem.item.validations = validations;
        }
        return {
          ...prev,
          updatedItem: updatedItem,
        };
      });
    }
  }, [setConfigItem, treeItem.item, treeItem.type]);

  // END OF REGION

  return {
    isLoading: isLoading || isFetching,
    folderSortOptions,
    folderDocumentTypeOptions,
    folderMergedContextFields,
    initialState,
    variableList,
    getFieldLabel,
    getFieldDataType,
    getNewVariableData,
    showVariablePartSheet,
    setShowVariablePartSheet,
    clickedPart,
    currentVariable,
    rawSetCurrentVariableValue,
    eventManager,
    handlePartClicked,
    handleVariableAdded,
    onCategoryCheckBoxClick,
    categoryError,
    setCategoryError,
    saveChangesState,
    setSaveChangesState,
    isTrackableError,
    setIsTrackableError,
    trackingIssuesDueDateHours,
    changeTrackingIssuesDueDateHours,
    mergedContextDefinition: mergedContextResponse
      ? mergedContextResponse.mergedContextDefinition
      : undefined,
    onRequiredConditionChange,
    onIncludeExpressionChange,
    tI18n,
    getConditionText,
    logicalExpressionToExpressionDataInput,
    deleteRequiredCondition,
    deleteIncludeExpression,
    deleteFolderValidation,
    onSingleValidationFolderChange,
    createNewFolderValidation,
    folderDocumentTypesOptions,
    folderMergedContextFieldsForFilterBuilder,
    isFolderChangesButtonDisabled,
    setIsFolderChangesButtonDisabled,
    isAnyExpressionInvalidOnCurrentItem,
  };
};

export const useCloseConfig = (
  folderDocumentTypeOptions: {
    label: string;
    value: string;
  }[],
  folderMergedContextFields: ContextField[],
  setConfigItem: Dispatch<
    SetStateAction<
      | {
          originalItem: TreeItem;
          updatedItem: TreeItem;
        }
      | undefined
    >
  >,
  closeConfig?: CloseConfig
) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { moment, resources } = useAppTranslation();

  const closeConfigOptions = useMemo((): Array<{
    value: CloseConfig["type"] | "none";
    label: string;
  }> => {
    return [
      {
        label: resources.dataTypeValues.none,
        value: "none",
      },
      {
        value: "document-type-exists",
        label: resources.documentCollection.closeConfig.documentTypeExists,
      },
      {
        value: "field-value",
        label: resources.documentCollection.closeConfig.fieldValue,
      },
      {
        value: "logical-expression",
        label: resources.documentCollection.closeConfig.logicalExpression,
      },
      {
        value: "no-missing-required-documents",
        label: resources.documentCollection.closeConfig.noMissingRequiredDocuments,
      },
    ];
  }, [resources]);

  const selectedDocumentType = useMemo(() => {
    if (!closeConfig || closeConfig.type !== "document-type-exists") {
      return undefined;
    }
    return folderDocumentTypeOptions.find((dt) => dt.value === closeConfig.documentTypeId);
  }, [folderDocumentTypeOptions, closeConfig]);

  const fieldsOptions = useMemo(() => {
    return folderMergedContextFields.map((f) => ({ value: f.name, label: f.properties.label }));
  }, [folderMergedContextFields]);

  const dataElementInfo = useMemo(() => {
    if (!closeConfig || closeConfig.type !== "field-value") {
      return undefined;
    }

    const contextField = folderMergedContextFields.find((f) => closeConfig.fieldName === f.name);
    if (!contextField) {
      return undefined;
    }
    const val = closeConfig.dataTypeValue.value;
    return {
      contextField,
      typeValue: formValueToValueType(
        moment,
        contextField.properties,
        val === null
          ? []
          : Array.isArray(val)
          ? val.reduce((acc: Array<string | number>, next) => {
              if (typeof next !== "object") {
                acc.push(next);
              }
              return acc;
            }, [])
          : [val]
      ),
    };
  }, [closeConfig, folderMergedContextFields, moment]);

  const setCloseConfig = useCallback(
    (modCloseConfig?: CloseConfig) =>
      setConfigItem((prev) => {
        if (!prev || prev.updatedItem.type !== "folder") return prev;
        const updatedItem = { ...prev.updatedItem };
        updatedItem.item.closeConfig = modCloseConfig;
        return {
          ...prev,
          updatedItem: updatedItem,
        };
      }),
    [setConfigItem]
  );

  const onSelectType = useCallback(
    (typeSelected: CloseConfig["type"]) => {
      if (typeSelected === "no-missing-required-documents") {
        setCloseConfig({ type: "no-missing-required-documents" });
      } else if (typeSelected === "document-type-exists") {
        setCloseConfig({
          type: "document-type-exists",
          documentTypeId: folderDocumentTypeOptions[0].value,
        });
      } else if (typeSelected === "field-value") {
        const fld = folderMergedContextFields[0];
        if (fld) {
          const val = fld.properties.sampleValue || null;
          const typeValue = formValueToValueType(
            moment,
            fld.properties,
            val === null
              ? []
              : Array.isArray(val)
              ? val.reduce((acc: Array<string | number>, next) => {
                  if (typeof next !== "object") {
                    acc.push(next);
                  }
                  return acc;
                }, [])
              : [val]
          );
          setCloseConfig({
            type: "field-value",
            fieldName: fld.name,
            dataTypeValue: valueTypeToFormValue(typeValue, moment),
          });
        }
      } else if (typeSelected === "logical-expression") {
        const fld = folderMergedContextFields[0];
        if (fld) {
          setCloseConfig({
            type: "logical-expression",
            expression: {
              isNegative: false,
              source: fld.name,
              operatorType: "equals",
              targetFixedValue: Array.isArray(fld.properties.sampleValue)
                ? fld.properties.sampleValue[0]
                : "",
            },
          });
        }
      } else {
        setCloseConfig(undefined);
      }
    },
    [setCloseConfig, folderDocumentTypeOptions, folderMergedContextFields, moment]
  );

  const onSelectDocumentType = useCallback(
    (documentTypeId: string) => {
      setCloseConfig({
        type: "document-type-exists",
        documentTypeId,
      });
    },
    [setCloseConfig]
  );

  const onChangeFieldName = useCallback(
    (fieldName: string) => {
      if (closeConfig && closeConfig.type === "field-value") {
        setCloseConfig({
          type: "field-value",
          fieldName,
          dataTypeValue: closeConfig.dataTypeValue,
        });
      }
    },
    [setCloseConfig, closeConfig]
  );

  const onChangeFieldValue = useCallback(
    (typeValue: InputControlValueType) => {
      if (closeConfig && closeConfig.type === "field-value") {
        setCloseConfig({
          type: "field-value",
          fieldName: closeConfig.fieldName,
          dataTypeValue: valueTypeToFormValue(typeValue, moment),
        });
      }
    },
    [closeConfig, moment, setCloseConfig]
  );

  const onExpressionChange = useCallback(
    (expression: LogicalExpression) => {
      if (closeConfig && closeConfig.type === "logical-expression") {
        setCloseConfig({
          type: "logical-expression",
          expression,
        });
      }
    },
    [setCloseConfig, closeConfig]
  );

  return {
    moment,
    dataElementInfo,
    fieldsOptions,
    isExpanded,
    setIsExpanded,
    closeConfigOptions,
    selectedDocumentType,
    onSelectType,
    onSelectDocumentType,
    onChangeFieldName,
    onChangeFieldValue,
    onExpressionChange,
  };
};
