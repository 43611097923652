import { Box, DatePickerProps, SelectList, Text, useColors } from "@prodoctivity/design-system";
import { resourcesAdapters } from "@prodoctivity/fluency-components";
import {
  arrayToMap,
  convertToTreeForm,
  convertTreeFormLogicalExpressionToExpressionObject,
  getContextField,
  getContextRecord,
} from "@prodoctivity/shared";
import type {
  ContextField,
  ContextRecord,
  DataElement,
  DependencyItem,
  ExpressionDataInput,
  LogicalExpression,
  TemplateContextRecordHolder,
  TemplateDependency,
} from "@prodoctivity/shared/src/index-types";
import type { TemplateFieldRecordUI } from "@prodoctivity/types";
import type momentType from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { DATETIME_24_FORMAT, DATE_FORMAT, TIME_24_FORMAT } from "../../_lib/date-formats";
import { Expression } from "../logical";
import { DataType } from "../logical/model/fluency";
import { dependencyExplorer } from "../logical/utils/utils";

export type Option = { value: string; subtext?: string; label: string };

export type DependencyRowProps = {
  dependency: TemplateDependency;
  fields: TemplateFieldRecordUI[];
  index: number;
  onDependencyUpdate: (dependency: TemplateDependency) => void;
  i18n: (key: any) => string;
  moment: typeof momentType;
  resources: DatePickerProps["resources"] & {
    dataTypeValues: {
      and: string;
      none: string;
    };
    clear: string;
    contains: string;
    custom: string;
    endsWith: string;
    equals: string;
    filterBuilder: {
      addAnExpressionHere: string;
      addDependency: string;
      alwaysFalse: string;
      alwaysTrue: string;
      averageOf: string;
      clickHereToTurnToNegativeAnd: string;
      clickHereToTurnToNegativeOr: string;
      clickHereToTurnToPositiveAnd: string;
      clickHereToTurnToPositiveOr: string;
      clickToFilterFunnel: string;
      condition: string;
      countOf: string;
      createDependenciesDescription: string;
      dependencyName: string;
      dependencyNotCreated: string;
      dependencySelection: string;
      exclude: string;
      falseF: string;
      helpMode: string;
      ifThisExpressionIs: string;
      include: string;
      invalidExpression: string;
      is: string;
      isNot: string;
      maximumOf: string;
      minimumOf: string;
      negativeF: string;
      not: string;
      positiveF: string;
      sumOf: string;
      theSelectedTextInTheDocument: string;
      then: string;
      trueF: string;
      turnYourExpression: string;
      valueOfLabel: string;
      whereCaps: string;
    };
    greaterThan: string;
    greaterThanOrEquals: string;
    lessThan: string;
    lessThanOrEquals: string;
    notEquals: string;
    or: string;
    remove: string;
    startsWith: string;
    summary: string;
  };
  formFieldList: (ContextField | ContextRecord)[];
  contextDefinition: TemplateContextRecordHolder;
  recordList: ContextRecord[];
};

export type DataSource = DataElement & { key: string };

export type DependencyRowState = {
  conditionText?: string;
  dependencyName: string;
};

export function initializeNullSourceSummaries(expression: Expression) {
  if (expression.expressionList.length > 0) {
    expression.expressionList.forEach((exp) => {
      if (exp.sourceSummary === null || exp.sourceSummary === undefined) {
        exp.sourceSummary = "valueOf";
      }
      initializeNullSourceSummaries(exp);
    });
  }
  if (expression.sourceSummary === null || expression.sourceSummary === undefined) {
    expression.sourceSummary = "valueOf";
  }
  return expression;
}

export const useDependenciesRow = ({
  dependency,
  fields,
  index,
  onDependencyUpdate,
  i18n,
  moment,
  resources,
  formFieldList,
  contextDefinition,
  recordList,
}: DependencyRowProps) => {
  /* ---------------------------- #Common Hooks area---------------------------- */

  const { colors } = useColors();
  const defaultDependency: TemplateDependency = useMemo(() => {
    const defaultDependency: TemplateDependency =
      dependency && dependency !== null
        ? dependency
        : {
            name: "",
            expression: {
              isNegative: false,
              operatorType: "equals",
              summaryFunction: "valueOf",
              source: formFieldList[0].fullPath,
              target: "",
            },
            items: [],
          };

    return defaultDependency;
  }, [dependency, formFieldList]);

  const fixConditionValue = useCallback(
    (conditions: JSON) => {
      const includeOnFix = ["Date", "DateTime", "Time"];
      const dataElementsType = (fields || [])
        .map((x) => {
          return { name: x.name, dataType: x.dataType };
        })
        .filter((x) => includeOnFix.includes(x.dataType));
      const dictionaryFormat = {
        [DataType.Date]: DATE_FORMAT,
        [DataType.DateTime]: DATETIME_24_FORMAT,
        [DataType.Time]: TIME_24_FORMAT,
      };

      const fixDateExpressionValue = (dataElementsType: any, conditions: any) => {
        const currentDatatype = dataElementsType.filter(
          (x: any) => x.name === conditions.sourceField
        );
        let value = conditions.targetValue;

        if (currentDatatype.length > 0) {
          const format = dictionaryFormat[currentDatatype[0].dataType];
          if (format === TIME_24_FORMAT && !moment(conditions.targetValue).isValid()) {
            value = `2000-01-01 ${conditions.targetValue}`;
          }
          conditions.targetValue = moment(value).parseZone().format(format);
        }

        if (conditions.expressionList.length > 0) {
          for (let i = 0; i < conditions.expressionList.length; i++) {
            fixDateExpressionValue(dataElementsType, conditions.expressionList[i]);
          }
        }
        return conditions;
      };
      if (conditions) {
        return fixDateExpressionValue(dataElementsType, conditions);
      }

      return conditions;
    },
    [fields, moment]
  );

  const setConditionText = useCallback(
    (dependencyConditions: ExpressionDataInput) => {
      try {
        const conditions = dependencyExplorer(moment, dependencyConditions);
        const expressionData: ExpressionDataInput = fixConditionValue(
          conditions
        ) as ExpressionDataInput;

        const fieldKeyLabelMap = arrayToMap(
          fields,
          (fld) => fld.fullPath,
          (fld) => fld.humanName
        );

        const conditionText = new Expression(expressionData).toString(
          i18n,
          resourcesAdapters,
          fieldKeyLabelMap
        );
        return conditionText;
      } catch (e) {
        console.warn("Failed to parse expression text");
      }
    },
    [fields, fixConditionValue, i18n, moment]
  );

  const setInitialStateFromProps: () => DependencyRowState = useCallback(() => {
    const initialState: DependencyRowState = {
      conditionText: "No condition",
      dependencyName: defaultDependency.name,
    };

    const expression = convertTreeFormLogicalExpressionToExpressionObject(
      defaultDependency.expression,
      true
    );
    const initializedExpression = initializeNullSourceSummaries(expression);
    const expressionDataInput = initializedExpression.toDataInput();
    initialState.conditionText = setConditionText(expressionDataInput);

    const result: DependencyRowState = {
      ...initialState,
    };

    return result;
  }, [defaultDependency, setConditionText]);

  const [state, setState] = useState<DependencyRowState>(setInitialStateFromProps);
  const debouncedDependencyNameGetter = useDebounceCallback(() => state.dependencyName, 500, {
    leading: true,
  });
  const debouncedDependencyName = debouncedDependencyNameGetter() || "";

  useEffect(() => {
    if (defaultDependency.name !== debouncedDependencyName && onDependencyUpdate) {
      const dependency: TemplateDependency = { ...defaultDependency };
      dependency.name = debouncedDependencyName;
      onDependencyUpdate(dependency);
    }
  }, [debouncedDependencyName, defaultDependency, onDependencyUpdate]);

  const transferDataElement = useCallback(
    (selectedItems: Option[]) => {
      const dependencyItems: DependencyItem[] = selectedItems.map((item) => {
        const isRecord = !!getContextRecord(contextDefinition, item.value);
        return {
          type: isRecord ? "record" : "field",
          key: item.value,
          action: "hide",
        };
      });

      const updatedDependency: TemplateDependency = {
        ...defaultDependency,
        items: dependencyItems,
      };

      onDependencyUpdate(updatedDependency);
    },
    [contextDefinition, defaultDependency, onDependencyUpdate]
  );

  const [conditionalIfBoolean, setConditionalIfBoolean] = useState(true);
  const [conditionalIfIncludes, setConditionalIfIncludes] = useState(true);

  const getConditionsContextualIf = useCallback(() => {
    const contextualIf = (
      <Box display="flex" alignItems="center" justifyContent="center" gap={4}>
        <Box height={"100%"} display="flex">
          <Text>{i18n("filterBuilder.ifThisExpressionIs")}</Text>
        </Box>
        <SelectList
          id={`${index}-contextualIf`}
          value={conditionalIfBoolean ? "true" : "false"}
          onChange={(event) => {
            if (event.value !== "true" && event.value !== "false") return;
            setConditionalIfBoolean(event.value === "true" ? true : false);
          }}
          options={[
            { label: "trueF", value: "true" },
            { label: "falseF", value: "false" },
          ].map((option) => ({
            label: i18n(`filterBuilder.${option.label}`),
            value: option.value,
          }))}
        ></SelectList>
        <Text>{i18n("filterBuilder.then")}</Text>
        <SelectList
          id={`${index}-contextualIf`}
          value={conditionalIfIncludes ? "true" : "false"}
          onChange={(event) => {
            if (event.value !== "true" && event.value !== "false") return;
            setConditionalIfIncludes(event.value === "true" ? true : false);
          }}
          options={[
            { label: "include", value: "true" },
            { label: "exclude", value: "false" },
          ].map((option) => ({
            label: i18n(`filterBuilder.${option.label}`),
            value: i18n(option.value),
          }))}
        ></SelectList>
        <Text>{i18n("filterBuilder.theSelectedTextInTheDocument")}</Text>
      </Box>
    );
    const contextualValue = () => {
      return conditionalIfBoolean !== conditionalIfIncludes;
    };
    return { node: contextualIf, value: contextualValue };
  }, [conditionalIfBoolean, conditionalIfIncludes, i18n, index]);

  const onExpressionChange = useCallback(
    (_name: string, expression: ExpressionDataInput) => {
      const validExpression: Expression = new Expression(expression);
      const newDependency: TemplateDependency = { ...defaultDependency };
      const expr: LogicalExpression = convertToTreeForm(validExpression);
      newDependency.expression = expr;

      setState((prevState) => ({
        ...prevState,
        conditionText: setConditionText(expression),
      }));

      if (onDependencyUpdate) {
        onDependencyUpdate(newDependency);
      }
    },
    [defaultDependency, onDependencyUpdate, setConditionText]
  );

  const onTextInputChange = useCallback((value: string) => {
    setState((prevState) => ({
      ...prevState,
      dependencyName: value,
    }));
  }, []);

  const { expression: logicalExpression } = defaultDependency;

  const expression = useMemo(() => {
    const result = convertTreeFormLogicalExpressionToExpressionObject(logicalExpression, true);
    const initializedExpression = initializeNullSourceSummaries(result);
    return initializedExpression;
  }, [logicalExpression]);

  const expressionDataInput = useMemo(() => {
    return expression.toDataInput();
  }, [expression]);

  const optionsForComboBoxTags = useMemo(() => {
    const recordNames = dependency.items
      .filter((field) => {
        return recordList.find((record) => field.key === record.fullPath);
      })
      .map((field) => {
        return field.key;
      });

    const listWithoutSelectedItems = formFieldList.filter((field) => {
      return !dependency.items.find((item) => item.key === field.fullPath);
    });
    const hideInnerFieldsIfRecordIsSelected = listWithoutSelectedItems.filter((item) => {
      return !recordNames.find((recordName) => item.fullPath.startsWith(`${recordName}/`));
    });
    const result = hideInnerFieldsIfRecordIsSelected.map((item) => {
      const isRecord = getContextRecord(contextDefinition, item.fullPath);
      return {
        label: isRecord ? `${isRecord.properties.label} [Record]` : item.properties.label,
        value: item.fullPath,
        subtext: item.fullPath,
      };
    });
    return result;
  }, [contextDefinition, dependency.items, formFieldList, recordList]);

  const selectedDataElement: Option[] = useMemo(() => {
    const recordNames = dependency.items
      .filter((field) => {
        return recordList.find((record) => field.key === record.fullPath);
      })
      .map((field) => {
        return field.key;
      });

    const result = dependency.items
      .filter((item) => {
        return !recordNames.find((recordName) => item.key.startsWith(`${recordName}/`));
      })
      .map((item) => {
        const isRecord = getContextRecord(contextDefinition, item.key);
        const isField = getContextField(contextDefinition, item.key);
        const getItemHumanName = isRecord
          ? `${isRecord.properties.label} [Record]`
          : isField
          ? isField.properties.label
          : item.key;
        return { label: getItemHumanName, value: item.key, subtext: undefined };
      });
    return result;
  }, [contextDefinition, dependency.items, recordList]);

  return {
    defaultDependency,
    onDependencyUpdate,
    index,
    resources,
    expression,
    expressionDataInput,
    optionsForComboBoxTags,
    selectedDataElement,
    transferDataElement,
    getConditionsContextualIf,
    onExpressionChange,
    onTextInputChange,
    state,
    setState,
    i18n,
    colors,
  };
};
